import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import VueSweetalert2 from 'vue-sweetalert2';
import VueAwesomePaginate from "vue-awesome-paginate";
import VueQRCodeComponent from 'vue-qrcode-component';
import Flicking from "@egjs/vue3-flicking";
import "@egjs/vue3-flicking/dist/flicking.css";

const options = {
  confirmButtonColor: '#1a418c',
  cancelButtonColor: '#ff7674',
};

import 'sweetalert2/dist/sweetalert2.min.css';
import { FontAwesomeIcon } from './plugins/font-awesome'
import Toaster from "@incuca/vue3-toaster";
import "vue-awesome-paginate/dist/style.css";
import VueApexCharts from 'vue3-apexcharts'

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const app = createApp(App)
  .use(router)
  .use(VueSweetalert2, options)
  .use(Toaster)
  .use(store)
  .use(VueAwesomePaginate)
  .use(VueApexCharts)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component('qr-code', VueQRCodeComponent)
  .component("Flicking", Flicking);


var firebaseConfig = {
  apiKey: "AIzaSyB5nYEPnM4WP1xsRMykZWFBV905GS1qauY",
  authDomain: "wisdom-guide-dev.firebaseapp.com",
  projectId: "wisdom-guide-dev",
  storageBucket: "wisdom-guide-dev.appspot.com",
  messagingSenderId: "1060001451485",
  appId: "1:1060001451485:web:be5924b0e7ee1efbdf7a34",
  measurementId: "G-LNVG6KJ125"
}

const firebaseApp = initializeApp(firebaseConfig); // Initialize Firebase
const messaging = getMessaging(firebaseApp); // Initialize messaging

// Request notification permission and get token
try {
  getToken(messaging).then(token => {
    if (token) {
      localStorage.setItem('fcm', token);
      console.log('FCM Token:', token);
    } else {
      console.log('Token not available');
    }
  }).catch(error => {
    console.error('Error getting FCM token:', error);
  });
} catch (error) {
  // console.log('Error', error);
}

// Notification.requestPermission()
//   .then(permission => {
//     if (permission === 'granted') {
//       return getToken(messaging);
//     } else {
//       console.log('Notification permission denied');
//       return null;
//     }
//   })
//   .then(token => {
//     if (token) {
//       alert(token);
//       localStorage.setItem('fcm', token);
//       console.log('FCM Token:', token);
//     }
//   })
//   .catch(error => {
//     console.error('Error getting FCM token:', error);
//   });
app.mount("#app");

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}
