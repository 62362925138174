<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">സി ആർ ഇ സെന്റർ വിവരങ്ങൾ</h3>
        </div>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section pb-2" v-if="!loading_page">
        <div class="card pl-2 pr-2 mt-1">
            <div class="listed-detail mt-1">
                <h3 class="text-center mt-1">{{requestData.center_name}}</h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-1">
                <li>
                    <strong>Status</strong>
                    <span :class="requestData.approve_status == 'Pending Approval'?'text-warning':'text-success'">{{requestData.approve_status}}</span>
                </li>
                <li v-if="requestData.zone || requestData.unit">
                    <strong>Level</strong>
                    <span class="text-primary">{{requestData.user_level == 3?requestData.zone.name:requestData.unit.name}} {{requestData.user_level == 3?'Zone':' Unit'}}</span>
                </li>
                <li>
                    <strong>Adress</strong>
                    <span class="text-primary">{{requestData.center_adress}}</span>
                </li>
                <li>
                    <strong>Centre Type</strong>
                    <span class="text-primary">{{requestData.center_type}}</span>
                </li>
                <li>
                    <strong>No Of Rooms</strong>
                    <span class="text-primary">{{requestData.no_of_rooms}}</span>
                </li>
                <li>
                    <strong>No Of Benches</strong>
                    <span class="text-primary">{{requestData.no_of_benches}}</span>
                </li>
                <li>
                    <strong>No Of Desks</strong>
                    <span class="text-primary">{{requestData.no_of_desks}}</span>
                </li>
                <li>
                    <strong>No Of Chairs</strong>
                    <span class="text-primary">{{requestData.no_of_chairs}}</span>
                </li>
                <li>
                    <strong>No Of Estimated Registrations</strong>
                    <span class="text-primary">{{requestData.no_of_estimated_registrations}}</span>
                </li>
                <li>
                    <strong>Office Room</strong>
                    <span class="text-primary">{{requestData.office_room == 'Y'?'Yes':'No'}}</span>
                </li>
                <li>
                    <strong>Library</strong>
                    <span class="text-primary">{{requestData.library == 'Y'?'Yes':'No'}}</span>
                </li>
                <li>
                    <strong>Toilet</strong>
                    <span class="text-primary">{{requestData.toilet == 'Y'?'Yes':'No'}}</span>
                </li>
                <li>
                    <strong>Rest Area</strong>
                    <span class="text-primary">{{requestData.rest_area == 'Y'?'Yes':'No'}}</span>
                </li>
                <li>
                    <strong>Projector</strong>
                    <span class="text-primary">{{requestData.projector == 'Y'?'Yes':'No'}}</span>
                </li>
                <li>
                    <strong>Board</strong>
                    <span class="text-primary">{{requestData.board == 'Y'?'Yes':'No'}}</span>
                </li>
                <li>
                    <strong>Fan Ac</strong>
                    <span class="text-primary">{{requestData.fan_ac == 'Y'?'Yes':'No'}}</span>
                </li>
                <li>
                    <strong>Drinking Water</strong>
                    <span class="text-primary">{{requestData.drinking_water == 'Y'?'Yes':'No'}}</span>
                </li>

                <li>
                    <strong>Org Cordinator</strong>
                    <span class="text-primary">{{requestData.org_member?requestData.org_member.name:''}}</span>
                </li>
                <li>
                    <strong>Youth Cordinator</strong>
                    <span class="text-primary">{{requestData.youth_member?requestData.youth_member.name:''}}</span>
                </li>
                <li>
                    <strong>Student Cordinator</strong>
                    <span class="text-primary">{{requestData.student_member?requestData.student_member.name:''}}</span>
                </li>
                <li>
                    <strong>Added By</strong>
                    <span class="text-primary">{{requestData.added_by_name}}</span>
                </li>
                <li>
                    <strong>Updated By</strong>
                    <span class="text-primary">{{requestData.updated_by_name}}</span>
                </li>
            </ul>
        </div>
        <div class="transparent pt-2">
            <button type="button" @click="getActionModel" v-if="permissions.action" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading">
                <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                <span>Action</span>
            </button>
        </div>
    </div>
    
        <!-- actionModel -->
        <div class="modal fade action-sheet" id="actionModel" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Action</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <div class="form-group basic">
                                <label class="label" for="stay"><span>Comments</span></label>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <textarea class="form-control" rows="5" name="action_comments" v-model="action_comments">
                                                                    </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="permissions.action">
                                <button type="button" @click="approve()" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Approve</span></button>
                            </div>
                        </div>
        
                    </div>
                </div>
            </div>
        </div>
        <!-- actionModel -->
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import * as yup from "yup";
import UserService from "../../services/user.service";
import $ from "jquery";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null
    },
    components: {
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({});
        return {
            loading: false,
            schema,
            user_level: "",
            unit: [],
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            requestData:[],
            action_comments:'',
            permissions: {
                edit: true
            }
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        childLength() {
            return parseInt(this.formData.lessthan_16_count);
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            if (this.unit_id == this.currentUser.unit_id || this.currentUser.user_level == 4) {
                this.editable = true;
            } else {
                this.editable = false;
            }

            this.$root.updateParent("സി ആർ ഇ സെന്റർ");
            if (this.$route.params.id) {
                this.getCreCenterData(this.$route.params.id);
            }
        }

        window.onpopstate = function() {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        handleRegistration(data) {
            console.log(data);
            this.loading = true;
            const formData_ = new FormData();
            if (this.editable_id) {
                formData_.append('editable_id', this.editable_id);
            }

            let user_level = this.formData.change_level;
            let user_level_id = 0;
            if (user_level == 1) {
                user_level_id = 0;
            } else if (user_level == 2) {
                user_level_id = this.currentUser.district_id;
            } else if (user_level == 3) {
                user_level_id = this.currentUser.zone_id;
            } else if (user_level == 4) {
                user_level_id = this.currentUser.unit_id;
            }

            formData_.append('user_level', user_level);
            formData_.append('user_level_id', user_level_id);

            formData_.append('center_level', this.formData.change_level);
            formData_.append('center_name', this.formData.center_name);
            formData_.append('center_adress', this.formData.center_adress);
            formData_.append('center_type', this.formData.center_type);

            formData_.append('no_of_rooms', this.formData.no_of_rooms);
            formData_.append('no_of_benches', this.formData.no_of_benches);
            formData_.append('no_of_desks', this.formData.no_of_desks);
            formData_.append('no_of_chairs', this.formData.no_of_chairs);
            formData_.append('no_of_estimated_registrations', this.formData.no_of_estimated_registrations);

            formData_.append('office_room', this.formData.office_room);
            formData_.append('library', this.formData.library);
            formData_.append('toilet', this.formData.toilet);
            formData_.append('rest_area', this.formData.rest_area);
            formData_.append('projector', this.formData.projector);
            formData_.append('board', this.formData.board);
            formData_.append('fan_ac', this.formData.fan_ac);
            formData_.append('drinking_water', this.formData.drinking_water);

            formData_.append('org_cordinator', this.org_member.id);
            formData_.append('youth_cordinator', this.youth_member.id);
            formData_.append('student_cordinator', this.formData.student_cordinator);

            UserService.authPostRequest("store-cre-center", formData_).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getCreCenterData(id) {
            this.loading_list = true;
            let data = {
                id: id
            }
            UserService.authPostRequest('show-cre-center', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.requestData = response.data.data.center;
                        this.permissions = response.data.data.permissions;
                        this.loading_list = false;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                        this.loading_list = false;
                }
            );
        },
        getActionModel(){
            $('#actionModel').modal('show');
        },
        approve() {
            this.loading = true;
            let data = {
                id: this.requestData.id,
                comments: this.action_comments
            }
            UserService.authPostRequest('update-cre-center-status', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#actionModel').modal('hide');
                        this.getCreCenterData(this.requestData.id);
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}

.custom-file-upload {
    position: relative;
    display: flex;
    width: 50%;
    height: 100px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #060606;
}
</style>
