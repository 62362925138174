<template>
  <div id="app">
    <div id="appCapsule">
      <app-header v-if="this.$route.name != 'OTP'" :page_header="page_header" :minutes="minutes" :seconds="seconds">
      </app-header>
      <router-view :key="$route.path" />
    </div>

    <app-footer-menu
      v-if="this.$route.name != 'EPR' && this.$route.name != 'OTP' && this.$route.name != 'Login' && this.$route.name != 'Welcome' && this.$route.name != 'Register' && this.$route.name != 'UserLogin' && this.$route.name != 'Conference Registration' && this.$route.name != 'PER' && this.$route.name != 'EVBD' && this.$route.name != 'Donation'">
    </app-footer-menu>
    <div class="appFooter" v-else>
      <div class="footer-title">
        Copyright © Wisdom It Wing 2024. All Rights Reserved.
      </div>
      Wisdom Islamic Organizations
    </div>
    <!-- <div class="appFooter">
      <div class="footer-title">
        Copyright © Finapp 2021. All Rights Reserved.
      </div>
      Bootstrap 5 based mobile template.
    </div> -->
    <app-side-menu></app-side-menu>
  </div>
</template>

<script type="module">
import AppHeader from './views/layouts/AppHeader.vue';
import AppFooterMenu from './views/layouts/AppFooterMenu.vue';
import AppSideMenu from './views/layouts/AppSideMenu.vue';
export default {
  components: {
    AppHeader,
    AppFooterMenu,
    AppSideMenu
  },
  data() {
    return {
      'page_header': null,
      'minutes': null,
      'seconds': null,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  mounted() {
    this.page_header = null;
    this.minutes = null;
    this.seconds = null;
  },
  methods: {
    updateParent(variable) {
      this.page_header = variable;
    },
    updateMinutes(variable) {
      this.minutes = variable;
    },
    updateSeconds(variable) {
      this.seconds = variable;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      location.replace('/');
    }
  },
};
</script>

