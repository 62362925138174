<template>
    <div class="section mt-2 text-center" v-if="this.$route.params.code">
        <div class="card">
            <div class="card-body pt-0 pb-0">
                <div class="icon-wrapper">
                    <ion-icon name="qr-code-outline"></ion-icon>
                </div>
                <div class="text-center">
                    <p class="mal-text text-primary">അറ്റന്റൻസ് മാർക്ക് ആയില്ലെങ്കിൽ പ്രൊഫൈൽ പേജിൽ ഉള്ള Q R Scanner ഉപയോഗിച്ച് സ്കാൻ ചെയ്യുക.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="section mt-2 text-center" v-if="!this.$route.params.code">
        <div class="card">
            <div class="card-body pt-0 pb-0">
                <div class="icon-wrapper">
                    <ion-icon name="qr-code-outline"></ion-icon>
                </div>
                <div class="text-center">
                    <p class="mal-text text-danger">ഇതിൽ കാണുന്ന ക്യാമറ ഉപയോഗിച്ച് ക്യൂ ആർ കോഡ് സ്കാൻ
                        ചെയ്യുക.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="section mt-2" v-if="!this.$route.params.code">
        <div class="stream">
            <qr-stream @decode="onDecode" class="mb">
                <div style="color: red;" class="frame"></div>
            </qr-stream>
        </div>
    </div>
    <div class="modal fade action-sheet" id="addAttendanceModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">QR Scanner</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <form>
                            <div class="text-center">
                                <p class="mal-text text-primary">ഈ നമ്പറിന് കീഴിലുള്ള മെമ്പർമാരുടെ അറ്റന്റൻസ്
                                    ടിക്ക് ചെയ്തു രേഖപ്പെടുത്തുക.</p>
                            </div>
                            <div class="form-check mb-1" v-for="member in this.members" v-bind:key="member.id">
                                <input type="checkbox" checked class="form-check-input" :id="'customCheckb' + member.id"
                                    v-bind:value="member.id" v-model="members_attendance">
                                <label class="form-check-label" :for="'customCheckb' + member.id">{{
                                    member.name
                                }}</label>
                            </div>
                            <div class="form-group basic">
                                <button type="button" class="btn btn-primary btn-lg col-6" :disabled="loading"
                                    @click="action">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Mark Attendance</span></button>
                                <button type="button" class="btn btn-secondary btn-lg col-6"
                                    @click="closeModel">Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { QrStream } from 'vue3-qr-reader';
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import UserService from "../../services/user.service";
import $ from 'jquery';
export default {
    components: {
        QrStream,
        AlertModel,
        DialogModel
    },
    data() {
        return {
            list: [],
            members: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            decoded_code: '',
            members_attendance: []
        };
    },
    mounted() {
        if(this.$route.params.code){
            this.decoded_code = this.$route.params.code;
            this.onDecode(this.decoded_code)
        }
    },

    methods: {
        onDecode(data) {
            if (data) {
                this.decoded_code = data;
                this.selfAttendance(data);
            }
        },
        refreshPage() {
            localStorage.removeItem("user");
            location.replace('/');
        },
        selfAttendance(type) {
            let multiple_members = null;
            let multiple_flag = false;
            if (this.members.length) {
                if (this.members_attendance.length) {
                    multiple_members = this.members_attendance;
                    multiple_flag = true;
                } else {
                    this.alert_type = 'Warning';
                    this.alert_message = 'പേരിന്റെ നേരെ അറ്റൻഡൻസ് ടിക്ക് ചെയ്തു രേഖപ്പെടുത്തുക.';
                    this.$refs.alert_model.openModel();
                    return 0;
                }
            }
            let data = {
                code: type,
                multiple_members: multiple_members,
                multiple_flag: multiple_flag,
                type: 'meeting',
                admin_meeting_id:this.$route.params.id?this.$route.params.id:null
            }
            UserService.authPostRequest('self-attendance', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAttendanceModel').modal('hide');
                        if (response.data.data.meeting) {
                            this.list = response.data.data.meeting;
                            this.alert_type = 'Success';
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.decoded_code = '';
                            this.members_attendance = [];
                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        } else {
                            this.members = response.data.data.members;
                            $('#addAttendanceModel').modal('show');
                        }
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },
        closeModel() {
            $('#addAttendanceModel').modal('hide');
        },
        action() {
            this.selfAttendance(this.decoded_code);
        }
    },
};
</script>
<style scoped>
.accordion-button {
    font-weight: 700 !important;
}

.imaged {
    width: 60px !important;
}

.btn-primary {
    width: 100%;
}

.icon-1 {
    color: #1a418c !important;
    font-size: 40px;
}

.btn-lg {
    width: 48% !important;
}

.btn-secondary {
    left: 4% !important;
}
</style>