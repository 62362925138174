<template>
    <div class="section text-center mt-2 pt-2" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="section-title mal-text">C. ജോലി</div>
        <div class="card">
            <div class="card-body">
                <Form @submit="storeBasicInfo" :validation-schema="schema">
                    <div class="row">
                        <div class="form-check mb-1 col-6">
                            <input type="checkbox" :disabled="disable_all" class="form-check-input"
                                name="study_checkbox" id="customCheckb2" @change="getChildList('study_check', $event)"
                                v-model="study_check">
                            <label class="form-check-label mal-text" for="customCheckb2">പഠിക്കുന്നു</label>
                        </div>
                        <div class="form-check mb-1 col-6">
                            <input type="checkbox" :disabled="disable_all" class="form-check-input" name="job_checkbox"
                                id="customCheckb1" @change="getChildList('job_check', $event)" v-model="job_check">
                            <label class="form-check-label mal-text" for="customCheckb1">ജോലി ചെയ്യുന്നു</label>
                        </div>
                        <div class="form-check mb-1 col-6">
                            <input type="checkbox" :disabled="disable_all" class="form-check-input"
                                name="pension_checkbox" id="customCheckb3"
                                @change="getChildList('pension_check', $event)" v-model="pension_check">
                            <label class="form-check-label mal-text" for="customCheckb3">വിരമിച്ചു</label>
                        </div>
                        <div class="form-check mb-1 col-6">
                            <input type="checkbox" :disabled="disable_all" class="form-check-input"
                                name="no_job_checkbox" id="customCheckb4" @change="getChildList('no_job_check', $event)"
                                v-model="no_job_check">
                            <label class="form-check-label mal-text" for="customCheckb4"> ജോലി ഇല്ല</label>
                        </div>
                        <div class="form-check mb-1 col-6">
                            <input type="checkbox" :disabled="disable_all" class="form-check-input"
                                name="house_wife_checkbox" @change="getChildList('house_wife_check', $event)"
                                id="customCheckb5" v-model="house_wife_check">
                            <label class="form-check-label mal-text" for="customCheckb5">House Wife</label>
                        </div>
                        <div class="form-check mb-1 col-6">
                            <input type="checkbox" :disabled="disable_all" class="form-check-input"
                                name="balavedi_checkbox" @change="getChildList('balavedi_check', $event)"
                                id="customCheckb6" v-model="balavedi_check">
                            <label class="form-check-label mal-text" for="customCheckb6">ബാലവേദി -
                                പഠിക്കുന്നില്ല</label>
                        </div>
                    </div>
                    <div v-if="currenty_study_active">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">എവിടെയാണ് പഠിക്കുന്നത്</label>
                                <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                    v-model="studyCountry" name="country" id="select4">
                                    <option v-for="item in country_list" :value="item.id" v-bind:key="item.id">
                                        {{ item.name_mal }}</option>
                                </Field>
                            </div>
                            <ErrorMessage name="plustwo_stream" class="error-feedback" />
                        </div>
                        <div class="form-group boxed" v-if="studyCountry == '91'">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">സംസ്ഥാനം</label>
                                <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                    v-model="studyState" name="state" id="select4">
                                    <option v-for="item in state_list" :value="item.id" v-bind:key="item.id">
                                        {{ item.name_mal }}</option>
                                </Field>
                            </div>
                            <ErrorMessage name="state" class="error-feedback" />
                        </div>
                        <div class="form-group boxed" v-if="studyCountry == '91' && studyState == '94'">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">ജില്ല</label>
                                <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                    v-model="studyDistrict" name="district" id="select4">
                                    <option v-for="item in district_list" :value="item.id" v-bind:key="item.id">
                                        {{ item.name_mal }}</option>
                                </Field>
                            </div>
                            <ErrorMessage name="district" class="error-feedback" />
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">എന്താണ് പഠിക്കുന്നത് ?</label>
                                <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                    v-model="studyLevel" name="what_study" id="select4"
                                    @change="getChildList('what_study', $event)">
                                    <option v-for="item in what_study_list" :value="item.id" v-bind:key="item.id">
                                        {{ item.name_mal }}</option>
                                </Field>
                            </div>
                            <ErrorMessage name="what_study" class="error-feedback" />
                        </div>
                        <div v-if="plustwo_active">
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">പ്ലസ് ടു</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                        v-model="studySubLevel" name="plustwo" id="select4">
                                        <option v-for="item in plustwo_list" :value="item.id" v-bind:key="item.id">
                                            {{ item.name_mal }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="plustwo" class="error-feedback" />
                            </div>
                        </div>
                        <div v-if="degree_active">
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">ഏതു തരം ക്യാംപസ്</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                        v-model="studyCampus" name="campus" id="select4">
                                        <option v-for="item in campus_list" :value="item.id" v-bind:key="item.id">
                                            {{ item.name_mal }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="campus" class="error-feedback" />
                            </div>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">പഠിക്കുന്ന കോഴ്സ്</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                        v-model="studyCourse" name="course"
                                        @change="getChildList('study_course', $event)" id="select4">
                                        <option v-for="item in course_list" :value="item.id" v-bind:key="item.id">
                                            {{ item.name_mal }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course" class="error-feedback" />
                            </div>
                            <div class="form-group boxed" v-if="study_course_stream">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">സ്ട്രീം</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                        v-model="studyCourseStream" name="course_stream" id="select4">
                                        <option v-for="item in course_stream_list" :value="item.id"
                                            v-bind:key="item.id">
                                            {{ item.name_mal }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_stream" class="error-feedback" />
                            </div>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">കോളേജ്/ക്യാമ്പസിന്റെ പേര്</label>
                                    <Field :disabled="disable_all" name="institution" type="text" class="form-control"
                                        id="institution" placeholder="" v-model="institution" />
                                </div>
                                <ErrorMessage name="institution" class="error-feedback" />
                            </div>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">പഠിക്കുന്ന വർഷം</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                        v-model="studyYear" name="study_year" id="select4">
                                        <option v-for="item in study_year_list" :value="item.id" v-bind:key="item.id">
                                            {{ item.name_mal }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="study_year" class="error-feedback" />
                            </div>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">കോഴ്‌സ് പൂർത്തിയാക്കുന്ന വർഷം</label>
                                    <Field :disabled="disable_all" name="course_completing_year" type="text"
                                        class="form-control" id="course_completing_year" placeholder=""
                                        v-model="course_completing_year" />
                                </div>
                                <ErrorMessage name="course_completing_year" class="error-feedback" />
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">താങ്കളുടെ കോഴ്സ് മുകളിലെ ലിസ്റ്റിൽ
                                    ഇല്ലെങ്കിലോ കോഴ്‌സുമായി ബന്ധപ്പെട്ട മറ്റു വിവരങ്ങളോ നൽകുക</label>
                                <Field :disabled="disable_all" name="educationOthers" type="text" class="form-control"
                                    id="educationOthers" placeholder="" v-model="educationOthers" />
                            </div>
                            <ErrorMessage name="educationOthers" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group boxed" v-if="job_active">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">Job Category</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                v-model="jobMainCategory" name="jobMainCategory" id="select4"
                                @change="getChildList('jobLevel1', $event)">
                                <option v-for="item in job_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name_mal }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="job" class="error-feedback" />
                        <div class="form-group boxed" v-if="jobLevel2">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">Job</label>
                                <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                    v-model="job" name="job" id="select4" @change="getChildList('jobLevel2', $event)">
                                    <option v-for="item in jobLevel2List" :value="item.id" v-bind:key="item.id">
                                        {{ item.name_mal }}</option>
                                </Field>
                            </div>
                            <ErrorMessage name="job" class="error-feedback" />
                            <div class="form-group boxed" v-if="jobLevel3">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">Job Category</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                        v-model="jobCategory" name="jobCategory" id="select4"
                                        @change="getChildList('jobLevel3', $event)">
                                        <option v-for="item in jobLevel3List" :value="item.id" v-bind:key="item.id">
                                            {{ item.name_mal }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="job" class="error-feedback" />
                                <div class="form-group boxed" v-if="jobLevel4">
                                    <div class="input-wrapper">
                                        <label class="label mal-text" for="text4b">Job Sub Category</label>
                                        <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                            v-model="jobSubCategory" name="jobSubCategory" id="select4"
                                            @change="getChildList('jobLevel4', $event)">
                                            <option v-for="item in jobLevel4List" :value="item.id" v-bind:key="item.id">
                                                {{ item.name_mal }}</option>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="job" class="error-feedback" />
                                    <div class="form-group boxed" v-if="jobLevel5">
                                        <div class="input-wrapper">
                                            <label class="label mal-text" for="text4b">Job Divisions</label>
                                            <Field :disabled="disable_all" as="select"
                                                class="form-control custom-select" v-model="jobSubDivisions"
                                                name="jobSubDivisions" id="select4"
                                                @change="getChildList('jobLevel5', $event)">
                                                <option v-for="item in jobLevel5List" :value="item.id"
                                                    v-bind:key="item.id">
                                                    {{ item.name_mal }}</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="job" class="error-feedback" />
                                        <div class="form-group boxed" v-if="jobLevel6">
                                            <div class="input-wrapper">
                                                <label class="label mal-text" for="text4b">Job Sub Divisions</label>
                                                <Field :disabled="disable_all" as="select"
                                                    class="form-control custom-select" v-model="jobSubDivisions1"
                                                    name="jobSubDivisions1" id="select4"
                                                    @change="getChildList('jobLevel5', $event)">
                                                    <option v-for="item in jobLevel6List" :value="item.id"
                                                        v-bind:key="item.id">
                                                        {{ item.name_mal }}</option>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="jobSubDivisions1" class="error-feedback" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">താങ്കളുടെ ജോലി മുകളിലുള്ള ലിസ്റ്റിൽ
                                    ഇല്ലെങ്കിലോ ജോലിയുമായി ബന്ധപ്പെട്ട മറ്റു വിവരങ്ങളോ നൽകുക</label>
                                <Field :disabled="disable_all" name="jobOthers" type="text" class="form-control"
                                    id="jobOthers" placeholder="" v-model="jobOthers" />
                            </div>
                            <ErrorMessage name="jobOthers" class="error-feedback" />
                        </div>
                    </div>
                    <div class="transparent pt-3" v-if="(currentUser.id == profile.user_id) || !disable_all">
                        <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save & Next</span>
                        </button>
                    </div>
                    <div class="transparent pt-3" v-if="(currentUser.id != profile.user_id) && disable_all">
                        <button type="button" @click="goToNextPage" class="btn btn-primary btn-block btn-lg"
                            :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Next</span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
export default {
    name: 'Basic Info',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            // job: yup.string().required("ജോലി സെലക്ട് ചെയ്യുക!"),
        });

        return {
            loading: false,
            loading_list: false,
            message: "",
            schema,

            study_check: false,
            job_check: false,
            plustwo_stream: '',
            what_study: '',
            country: '',
            state: '',
            district: '',
            plustwo: '',
            pension_check: false,
            balavedi_check: false,
            campus: '',
            course: '',
            study_year: '',
            coolie_type: '',
            public_salary: '',
            private_salary: '',
            construction: '',
            hotel_cook: '',
            workshop: '',
            driver: '',
            fisherman: '',
            professional: '',
            doctor: '',
            alopathy: '',
            engineer: '',
            pharmasist: '',
            it_professional: '',
            programmer: '',
            db_admin: '',
            teacher: '',
            media_person: '',
            masjid_activity: '',
            trainer: '',
            accountant: '',
            office_job: '',
            private_other_job: '',
            kerala_gov: '',
            kerala_gov_cat: '',
            central_gov: '',
            central_gov_cat: '',
            trade: '',
            contract: '',
            kerala_semi_gov: '',
            central_semi_gov: '',
            central_gov_company: '',
            kerala_gov_company: '',
            general_education_type: '',
            general_education_school_type: '',

            jobMainCategory: '',
            job: '',
            jobCategory: '',
            jobSubCategory: '',
            jobSubDivisions: '',

            studyCountry: '',
            studyState: '',
            studyDistrict: '',
            studyLevel: '',
            studySubLevel: '',
            studyCampus: '',
            studyCourse: '',
            studyCourseStream: '',
            studyYear: '',

            study_course_stream: false,

            dropDown: [{
                '88': {
                    'div_name': 'ok'
                },
                '156': {
                    'div_name': 'ok'
                },
                '157': {
                    'div_name': 'ok'
                },
                '158': {
                    'div_name': 'ok'
                },
                '159': {
                    'div_name': 'ok'
                },
                '160': {
                    'div_name': 'ok'
                },
                '161': {
                    'div_name': 'ok'
                },
                '162': {
                    'div_name': 'ok'
                },
                '164': {
                    'div_name': 'ok'
                },
                '165': {
                    'div_name': 'ok'
                },
                '166': {
                    'div_name': 'ok'
                },
                '167': {
                    'div_name': 'ok'
                },
                '555': {
                    'div_name': 'ok'
                },
            }],

            jobLevel2List: [],
            jobLevel3List: [],
            jobLevel4List: [],
            jobLevel5List: [],
            jobLevel6List: [],

            jobLevel1: false,
            jobLevel2: false,
            jobLevel3: false,
            jobLevel4: false,
            jobLevel5: false,
            jobLevel6: false,
            jobSubDivisions1: '',

            job_list: [],
            what_study_list: [],
            country_list: [],
            state_list: [],
            district_list: [],
            plustwo_stream_list: [],
            campus_list: [],
            course_list: [],
            course_stream_list: [],
            study_year_list: [],
            coolie_type_list: [],
            public_salary_list: [],
            private_salary_list: [],
            construction_list: [],
            hotel_cook_list: [],
            workshop_list: [],
            driver_list: [],
            fisherman_list: [],
            professional_list: [],
            doctor_list: [],
            alopathy_list: [],
            engineer_list: [],
            pharmasist_list: [],
            it_professional_list: [],
            programmer_list: [],
            db_admin_list: [],
            teacher_list: [],
            media_person_list: [],
            masjid_activity_list: [],
            trainer_list: [],
            accountant_list: [],
            office_job_list: [],
            kerala_gov_list: [],
            kerala_gov_cat_list: [],
            central_gov_list: [],
            central_gov_cat_list: [],
            trade_list: [],
            contract_list: [],
            kerala_semi_gov_list: [],
            central_semi_gov_list: [],
            central_gov_company_list: [],
            kerala_gov_company_list: [],
            general_education_type_list: [],
            general_education_school_type_list: [],
            profile: [],
            house_wife_check: false,
            currenty_study_active: false,
            job_active: false,
            special_active: false,
            plustwo_active: false,
            degree_active: false,
            coolie_type_active: false,
            private_salary_active: false,
            public_salary_active: false,
            construction_active: false,
            hotel_cook_active: false,
            workshop_active: false,
            driver_active: false,
            fisherman_active: false,
            professional_active: false,
            doctor_active: false,
            alopathy_active: false,
            engineer_active: false,
            pharmasist_active: false,
            it_professional_active: false,
            programmer_active: false,
            db_admin_active: false,
            teacher_active: false,
            media_person_active: false,
            masjid_activity_active: false,
            trainer_active: false,
            accountant_active: false,
            office_job_active: false,
            private_other_job_active: false,
            kerala_gov_active: false,
            kerala_gov_cat_active: false,
            central_gov_active: false,
            central_gov_cat_active: false,
            trade_active: false,
            contract_active: false,
            disable_all: false,
            central_semi_gov_active: false,
            kerala_semi_gov_active: false,
            central_gov_company_active: false,
            kerala_gov_company_active: false,
            general_education_type_active: false,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.loading_list = true;
        this.getComboboxValues('JOB');
        this.getProfile();
        this.$root.updateParent("ബയോ ഡാറ്റ");
    },
    methods: {
        getProfile() {
            var data = {
                id: this.$route.params.id
            }
            UserService.authPostRequest('get-profile', data).then(
                (response) => {
                    this.profile = response.data.data.list;
                    if (this.profile.user_id != this.currentUser.id) {
                        if (response.data.data.edit_permission) {
                            this.disable_all = false;
                        } else {
                            this.disable_all = true;
                        }

                        this.$root.updateParent(this.profile.name + " യുടെ ബയോ ഡാറ്റ");
                    }
                    this.plustwo_stream = this.profile.job;
                    this.what_study = this.profile.job;
                    this.country = this.profile.job;
                    this.state = this.profile.job;
                    this.district = this.profile.job;
                    this.plustwo = this.profile.job;
                    this.campus = this.profile.job;
                    this.course = this.profile.job;
                    this.study_year = this.profile.job;


                    this.jobMainCategory = this.profile.jobMainCategory;
                    if (this.profile.studyOrJob == 'Job' || this.profile.studyOrJob == 'Pension') {
                        if (this.profile.studyOrJob == 'Job') {
                            this.job_check = true;
                        } else {
                            this.pension_check = true;
                        }
                        this.getComboboxValues('JOB_MAIN_CATEGORY');
                        this.job_active = true;
                        if (this.jobMainCategory == '157') {
                            this.getComboboxValues('COOLIE_TYPES', null, 2);
                            this.coolie_type_active = true;
                        } else if (this.jobMainCategory == '158') {
                            this.getComboboxValues('MONTHLY_SALARY_PRIVATE', null, 2);
                            if (this.profile.job == '184') {
                                this.getComboboxValues('construction_field', null, 3);
                            }
                            if (this.profile.job == '195') {
                                this.getComboboxValues('hotel_cook_field', null, 3);
                            }
                            if (this.profile.job == '199') {
                                this.getComboboxValues('workshop_industries', null, 3);
                            }
                            if (this.profile.job == '189') {
                                this.getComboboxValues('driver', null, 3);
                            }
                            if (this.profile.job == '192') {
                                this.getComboboxValues('fisheries_field', null, 3);
                            }
                            if (this.profile.job == '419') {
                                this.getComboboxValues('professional', null, 3);
                                if (this.profile.jobCategory == '415') {
                                    this.getComboboxValues('doctor', null, 4);
                                    if (this.profile.jobSubCategory == '434') {
                                        this.getComboboxValues('allopathy', null, 5);
                                    }
                                }
                                if (this.profile.jobCategory == '417') {
                                    this.getComboboxValues('engineer', null, 4);
                                    this.engineer_active = true;
                                }
                                if (this.profile.jobCategory == '420') {
                                    this.getComboboxValues('IT_Professional', null, 4);
                                    if (this.profile.jobSubCategory == '562') {
                                        this.getComboboxValues('Programmer', null, 5);
                                    }
                                    if (this.profile.jobSubCategory == '563') {
                                        this.getComboboxValues('Database_Admin', null, 5);
                                    }
                                }
                            }
                            if (this.profile.job == '427') {
                                this.getComboboxValues('teacher', null, 3);
                            }
                            if (this.profile.job == '428') {
                                this.getComboboxValues('media_person', null, 3);
                            }
                            if (this.profile.job == '429') {
                                this.getComboboxValues('mosque_care', null, 3);
                            }
                            if (this.profile.job == '430') {
                                this.getComboboxValues('Trainer', null, 3);
                            }
                            if (this.profile.job == '431') {
                                this.getComboboxValues('Accountant', null, 3);
                            }
                            if (this.profile.job == '432') {
                                this.getComboboxValues('Office_Jobs', null, 3);
                            }
                            if (this.profile.job == '433') {
                                this.profile.job == '433' ? this.private_other_job_active = true : this.private_other_job_active = false;
                            }
                        } else if (this.profile.jobMainCategory == '159') {
                            this.getComboboxValues('MONTHLY_SALARY_GOVERNMENT', null, 2);
                            this.public_salary_active = true;
                            if (this.profile.job == '572') {
                                this.getComboboxValues('Kerala_Government', null, 3);
                                this.getComboboxValues('Catagory_gov_gob', null, 4);
                                this.getComboboxValues('School Job_Type', null, 5);
                                this.getComboboxValues('School_Type', null, 6);
                            }
                            if (this.profile.job == '573') {
                                this.getComboboxValues('central_government', null, 3);
                                this.getComboboxValues('Catagory_gov_gob', null, 4);

                            }
                            if (this.profile.job == '593') {
                                this.getComboboxValues('Kerala_Half_Government', null, 3);
                            }
                            if (this.profile.job == '592') {
                                this.getComboboxValues('Kerala_Government_company', null, 3);
                            }
                            if (this.profile.job == '595') {
                                this.getComboboxValues('Central_Half_Government', null, 3);
                            }
                            if (this.profile.job == '594') {
                                this.getComboboxValues('Central_Government_company', null, 3);
                            }
                        } else if (this.profile.jobMainCategory == '160') {
                            this.getComboboxValues('BUSINESS_PRODUCTION', null, 2);
                        } else if (this.profile.jobMainCategory == '165') {
                            this.getComboboxValues('CONTRACT', null, 2);
                        }
                    }

                    if (this.profile.studyOrJob == 'Study') {
                        this.study_check = true;
                        this.currenty_study_active = true;
                        this.getComboboxValues('COUNTRY');
                        this.getComboboxValues('DISTRICT_KERALA');
                        this.getComboboxValues('STATES_INDIA');
                        this.getComboboxValues('STUDYING_LEVEL');
                        if (this.profile.studyLevel == '347') {
                            this.getComboboxValues('PLUS_TWO_STREAM');
                            this.plustwo_active = true;
                        }
                        else if (this.profile.studyLevel >= '348') {
                            if (this.profile.studyLevel == '350') {
                                this.getComboboxValues('PG');
                            } else if (this.profile.studyLevel == '992') {
                                this.getComboboxValues('PG_PROFESSIONAL_COURSE');
                            } else if (this.profile.studyLevel == '351') {
                                this.getComboboxValues('PROFESSIONAL_COURSE');
                            }
                            else if (this.profile.studyLevel == '341') {
                                this.getComboboxValues('DIPLOMA_COURSE');
                            } else {
                                this.getComboboxValues('STUDYING_COURSE');
                            }
                            this.getComboboxValues('CAMPUS_TYPE');

                            this.getComboboxValues('STUDYING_YEAR');
                            this.degree_active = true;

                            if (this.profile.studyCourse == '364') {
                                this.getComboboxValues('MEDICAL_COURSES');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '83') {
                                this.getComboboxValues('ENGINEERING_COURSES');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '365') {
                                this.getComboboxValues('PARAMEDICAL_COURSES');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '395') {
                                this.getComboboxValues('ARCHITECTURE');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '398') {
                                this.getComboboxValues('MANAGEMENT');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '993') {
                                this.getComboboxValues('MEDICAL_PG');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '994') {
                                this.getComboboxValues('ENGINEERING_PG');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '995') {
                                this.getComboboxValues('PARAMEDICAL_PG_COURSES');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '395') {
                                this.getComboboxValues('ARCHITECTURE');
                                this.study_course_stream = true;
                            }
                            if (this.profile.studyCourse == '398') {
                                this.getComboboxValues('MANAGEMENT');
                                this.study_course_stream = true;
                            }


                        }

                    }
                    if (this.profile.studyOrJob == 'NoJob') {
                        this.no_job_check = true;
                    }
                    if (this.profile.studyOrJob == 'HouseWife') {
                        this.house_wife_check = true;
                    }
                    if (this.profile.studyOrJob == 'BalavediNoJob') {
                        this.balavedi_check = true;
                    }

                    this.job = this.profile.job;
                    this.jobCategory = this.profile.jobCategory;
                    this.jobSubCategory = this.profile.jobSubCategory;
                    this.jobSubDivisions = this.profile.jobSubDevisions;
                    this.jobSubDivisions1 = this.profile.jobSubDivisions1;

                    this.studyCountry = this.profile.studyCountry;
                    this.studyState = this.profile.studyState;
                    this.studyDistrict = this.profile.studyDistrict;
                    this.studyLevel = this.profile.studyLevel;
                    this.studySubLevel = this.profile.studySubLevel;
                    this.studyCampus = this.profile.studyCampus;
                    this.studyCourse = this.profile.studyCourse;
                    this.studyYear = this.profile.studyYear;
                    this.jobOthers = this.profile.jobOthers;
                    this.studyCourseStream = this.profile.study_course_stream;
                    this.educationOthers = this.profile.educationOthers;
                    this.course_completing_year = this.profile.course_completing_year;
                    this.institution = this.profile.institution;
                    this.loading_list = false;
                },
            );

        },
        storeBasicInfo(data) {
            data.id = this.$route.params.id;
            data.user_id = this.currentUser.id;
            data.jobCheck = this.job_check;
            data.noJobCheck = this.no_job_check;
            data.houseWifeCheck = this.house_wife_check
            data.balavediCheck = this.balavedi_check
            data.pensionCheck = this.pension_check;
            data.jobMainCategory = this.jobMainCategory;
            data.job = this.job;
            data.jobCategory = this.jobCategory;
            data.jobSubCategory = this.jobSubCategory;
            data.jobSubDivisions = this.jobSubDivisions;
            data.jobSubDivisions1 = this.jobSubDivisions1;

            data.studyCheck = this.study_check;
            data.studyCountry = this.studyCountry;
            data.studyState = this.studyState;
            data.studyDistrict = this.studyDistrict;
            data.studyLevel = this.studyLevel;
            data.studySubLevel = this.studySubLevel;
            data.studyCampus = this.studyCampus;
            data.studyCourse = this.studyCourse;
            data.studyYear = this.studyYear;

            this.loading = true;

            UserService.authPostRequest('store-profile', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        // this.$swal('സേവ് ചെയ്തു', '', 'seccess');
                        this.loading = false;
                        this.$router.push('/bio-data/language-info/' + this.$route.params.id);
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToNextPage() {
            this.$router.push('/bio-data/language-info/' + this.$route.params.id);
        },
        getChildList(field, event) {
            switch (field) {
                case 'no_job_check':
                    this.no_job_check = true;
                    this.job_check = false;
                    this.study_check = false;
                    this.pension_check = false;
                    this.currenty_study_active = false;
                    this.job_active = false;
                    this.house_wife_check = false;
                    this.balavedi_check = false;
                    break;
                case 'house_wife_check':
                    this.house_wife_check = true;
                    this.no_job_check = false;
                    this.job_check = false;
                    this.study_check = false;
                    this.pension_check = false;
                    this.currenty_study_active = false;
                    this.job_active = false;
                    this.balavedi_check = false;
                    break;
                case 'balavedi_check':
                    this.balavedi_check = true;
                    this.no_job_check = false;
                    this.job_check = false;
                    this.study_check = false;
                    this.pension_check = false;
                    this.currenty_study_active = false;
                    this.job_active = false;
                    this.house_wife_check = false;
                    break;
                case 'job_check':
                    this.job_check = true;
                    this.no_job_check = false;
                    this.study_check = false;
                    this.pension_check = false;
                    this.currenty_study_active = false;
                    this.job_active = true;
                    this.house_wife_check = false;
                    this.balavedi_check = false;
                    this.getComboboxValues('JOB_MAIN_CATEGORY');
                    break;
                case 'pension_check':
                    this.pension_check = true;
                    this.no_job_check = false;
                    this.study_check = false;
                    this.job_check = false;
                    this.currenty_study_active = false;
                    this.job_active = true;
                    this.house_wife_check = false;
                    this.balavedi_check = false;
                    this.getComboboxValues('JOB_MAIN_CATEGORY');
                    break;

                case 'study_check':
                    this.currenty_study_active = true;
                    this.job_active = false;
                    this.pension_check = false;
                    this.no_job_check = false;
                    this.study_check = true;
                    this.job_check = false;
                    this.trade_active = false;
                    this.house_wife_check = false;
                    this.balavedi_check = false;
                    this.getComboboxValues('COUNTRY');
                    this.getComboboxValues('DISTRICT_KERALA');
                    this.getComboboxValues('STATES_INDIA');
                    this.getComboboxValues('STUDYING_LEVEL');
                    break;

                case 'jobLevel1':
                    this.jobLevel3 = false;
                    this.jobLevel4 = false;
                    this.jobLevel5 = false;
                    this.jobLevel6 = false;
                    this.job = '';
                    this.jobCategory = '';
                    this.jobSubCategory = '';
                    this.jobSubDivisions = '';
                    this.jobSubDivisions1 = '';
                    if (event.target.value == 157) {
                        this.getComboboxValues('COOLIE_TYPES', null, 2);
                        this.coolie_type_active = true;
                    } else if (event.target.value == 158) {
                        this.getComboboxValues('MONTHLY_SALARY_PRIVATE', null, 2);
                        this.private_salary_active = true;
                    } else if (event.target.value == 159) {
                        this.getComboboxValues('MONTHLY_SALARY_GOVERNMENT', null, 2);
                        this.public_salary_active = true;
                    } else if (event.target.value == 160) {
                        this.getComboboxValues('BUSINESS_PRODUCTION', null, 2);
                        this.trade_active = true;
                    } else if (event.target.value == 165) {
                        this.getComboboxValues('CONTRACT', null, 2);
                        this.contract_active = true;
                    }
                    break;
                case 'jobLevel2':
                    this.jobLevel4 = false;
                    this.jobLevel5 = false;
                    this.jobLevel6 = false;
                    this.jobCategory = '';
                    this.jobSubCategory = '';
                    this.jobSubDivisions = '';
                    this.jobSubDivisions1 = '';
                    if (event.target.value == 184) {
                        this.getComboboxValues('construction_field', null, 3);

                    }
                    if (event.target.value == 195) {
                        this.getComboboxValues('hotel_cook_field', null, 3);

                    }
                    if (event.target.value == 199) {
                        this.getComboboxValues('workshop_industries', null, 3);

                    }
                    if (event.target.value == 189) {
                        this.getComboboxValues('driver', null, 3);

                    }
                    if (event.target.value == 192) {
                        this.getComboboxValues('fisheries_field', null, 3);

                    }
                    if (event.target.value == 419) {
                        this.getComboboxValues('professional', null, 3);

                    }
                    if (event.target.value == 427) {
                        this.getComboboxValues('teacher', null, 3);

                    }
                    if (event.target.value == 428) {
                        this.getComboboxValues('media_person', null, 3);

                    }
                    if (event.target.value == 429) {
                        this.getComboboxValues('mosque_care', null, 3);

                    }
                    if (event.target.value == 430) {
                        this.getComboboxValues('Trainer', null, 3);

                    }
                    if (event.target.value == 431) {
                        this.getComboboxValues('Accountant', null, 3);

                    }
                    if (event.target.value == 432) {
                        this.getComboboxValues('Office_Jobs', null, 3);

                    }

                    if (event.target.value == 572) {
                        this.getComboboxValues('Kerala_Government', null, 3);
                        this.getComboboxValues('Catagory_gov_gob', null, 4);
                    }
                    if (event.target.value == 573) {
                        this.getComboboxValues('central_government', null, 3);
                        this.getComboboxValues('Catagory_gov_gob', null, 4);
                    }
                    if (event.target.value == 593) {
                        this.getComboboxValues('Kerala_Half_Government', null, 3);
                    }
                    if (event.target.value == 592) {
                        this.getComboboxValues('Kerala_Government_company', null, 3);
                    }
                    if (event.target.value == 595) {
                        this.getComboboxValues('Central_Half_Government', null, 3);
                    }
                    if (event.target.value == 594) {
                        this.getComboboxValues('Central_Government_company', null, 3);
                    }

                    break;
                case 'jobLevel3':
                    // this.jobLevel5 = false;
                    // this.jobLevel6 = false;
                    this.jobSubCategory = '';
                    this.jobSubDivisions = '';
                    this.jobSubDivisions1 = '';
                    if (event.target.value == 415) {
                        this.getComboboxValues('doctor', null, 4);
                    }
                    if (event.target.value == 417) {
                        this.getComboboxValues('engineer', null, 4);
                    }
                    if (event.target.value == 420) {
                        this.getComboboxValues('IT_Professional', null, 4);
                    }
                    if (event.target.value == 434) {
                        this.getComboboxValues('allopathy', null, 4);
                    }
                    if (event.target.value == 315) {
                        this.getComboboxValues('School Job_Type', null, 5);
                        this.getComboboxValues('School_Type', null, 6);
                    }
                    if (event.target.value == 317) {
                        this.getComboboxValues('College_Job_Type', null, 5);
                    }
                    if ((event.target.value != 315 && event.target.value != 317) && this.job == 572) {
                        this.getComboboxValues('Department_Job_Type', null, 5);
                    }
                    break;
                case 'jobLevel4':
                    this.jobSubDivisions = '';
                    this.jobSubDivisions1 = '';
                    // this.jobLevel6 = false;
                    if (event.target.value == 434) {
                        this.getComboboxValues('allopathy', null, 5);
                    }
                    if (event.target.value == 562) {
                        this.getComboboxValues('Programmer', null, 5);
                    }
                    if (event.target.value == 563) {
                        this.getComboboxValues('Database_Admin', null, 5);
                    }
                    break;
                // case 'job':
                //     this.coolie_type_active = false;
                //     this.private_salary_active = false;
                //     this.public_salary_active = false;
                //     this.contract_active = false;
                //     this.trade_active = false;
                //     this.coolie_type_active = false;
                //     if (event.target.value == 156) {
                //         this.getComboboxValues('STUDYING_LEVEL');
                //         this.currenty_study_active = true;
                //     } else if (event.target.value == 157) {
                //         this.getComboboxValues('COOLIE_TYPES');
                //         this.coolie_type_active = true;
                //     } else if (event.target.value == 158) {
                //         this.getComboboxValues('MONTHLY_SALARY_PRIVATE');
                //         this.private_salary_active = true;
                //     } else if (event.target.value == 159) {
                //         this.getComboboxValues('MONTHLY_SALARY_GOVERNMENT');
                //         this.public_salary_active = true;
                //     } else if (event.target.value == 160) {
                //         this.getComboboxValues('BUSINESS_PRODUCTION');
                //         this.trade_active = true;
                //     } else if (event.target.value == 165) {
                //         this.getComboboxValues('CONTRACT');
                //         this.contract_active = true;
                //     }
                //     break;
                case 'what_study':
                    this.plustwo_active = false;
                    this.degree_active = false;
                    if (event.target.value == 347) {
                        this.getComboboxValues('PLUS_TWO_STREAM');
                        event.target.value == 347 ? this.plustwo_active = true : this.plustwo_active = false;
                    }
                    else if (event.target.value >= 348) {
                        if (event.target.value == 992) {
                            this.getComboboxValues('CAMPUS_TYPE');
                            this.getComboboxValues('PG_PROFESSIONAL_COURSE');
                            this.getComboboxValues('STUDYING_YEAR');
                            event.target.value == 992 ? this.degree_active = true : this.degree_active = false;
                        } else if (event.target.value == 350) {
                            this.getComboboxValues('CAMPUS_TYPE');
                            this.getComboboxValues('PG');
                            this.getComboboxValues('STUDYING_YEAR');
                            event.target.value == 350 ? this.degree_active = true : this.degree_active = false;
                        } else if (event.target.value == 351) {
                            this.getComboboxValues('CAMPUS_TYPE');
                            this.getComboboxValues('PROFESSIONAL_COURSE');
                            this.getComboboxValues('STUDYING_YEAR');
                            event.target.value == 351 ? this.degree_active = true : this.degree_active = false;
                        }
                        else if (event.target.value == 349) {
                            this.getComboboxValues('CAMPUS_TYPE');
                            this.getComboboxValues('DIPLOMA_COURSE');
                            this.getComboboxValues('STUDYING_YEAR');
                            event.target.value == 349 ? this.degree_active = true : this.degree_active = false;
                        }

                        else {
                            this.getComboboxValues('CAMPUS_TYPE');
                            this.getComboboxValues('STUDYING_COURSE');
                            this.getComboboxValues('STUDYING_YEAR');
                            event.target.value >= 348 ? this.degree_active = true : this.degree_active = false;
                        }
                    }
                    break;
                case 'study_course':
                    if (event.target.value == 364) {
                        this.getComboboxValues('MEDICAL_COURSES');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 83) {
                        this.getComboboxValues('ENGINEERING_COURSES');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 365) {
                        this.getComboboxValues('PARAMEDICAL_COURSES');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 395) {
                        this.getComboboxValues('ARCHITECTURE');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 398) {
                        this.getComboboxValues('MANAGEMENT');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 993) {
                        this.getComboboxValues('MEDICAL_PG');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 994) {
                        this.getComboboxValues('ENGINEERING_PG');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 995) {
                        this.getComboboxValues('PARAMEDICAL_PG_COURSES');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 395) {
                        this.getComboboxValues('ARCHITECTURE');
                        this.study_course_stream = true;
                    }
                    if (event.target.value == 398) {
                        this.getComboboxValues('MANAGEMENT');
                        this.study_course_stream = true;
                    }

                    break;
                // case 'private_salary':
                //     this.construction_active = false;
                //     this.hotel_cook_active = false;
                //     this.workshop_active = false;
                //     this.driver_active = false;
                //     this.fisherman_active = false;
                //     this.professional_active = false;
                //     this.teacher_active = false;
                //     this.media_person_active = false;
                //     this.masjid_activity_active = false;
                //     this.trainer_active = false;
                //     this.accountant_active = false;
                //     this.office_job_active = false;
                //     this.private_other_job_active = false;
                //     if (event.target.value == 184) {
                //         this.getComboboxValues('construction_field');
                //         event.target.value == 184 ? this.construction_active = true : this.construction_active = false;
                //     }
                //     if (event.target.value == 195) {
                //         this.getComboboxValues('hotel_cook_field');
                //         event.target.value == 195 ? this.hotel_cook_active = true : this.hotel_cook_active = false;
                //     }
                //     if (event.target.value == 199) {
                //         this.getComboboxValues('workshop_industries');
                //         event.target.value == 199 ? this.workshop_active = true : this.workshop_active = false;
                //     }
                //     if (event.target.value == 189) {
                //         this.getComboboxValues('driver');
                //         event.target.value == 189 ? this.driver_active = true : this.driver_active = false;
                //     }
                //     if (event.target.value == 192) {
                //         this.getComboboxValues('fisheries_field');
                //         event.target.value == 192 ? this.fisherman_active = true : this.fisherman_active = false;
                //     }
                //     if (event.target.value == 419) {
                //         this.getComboboxValues('professional');
                //         event.target.value == 419 ? this.professional_active = true : this.professional_active = false;
                //     }
                //     if (event.target.value == 427) {
                //         this.getComboboxValues('teacher');
                //         event.target.value == 427 ? this.teacher_active = true : this.teacher_active = false;
                //     }
                //     if (event.target.value == 428) {
                //         this.getComboboxValues('media_person');
                //         event.target.value == 428 ? this.media_person_active = true : this.media_person_active = false;
                //     }
                //     if (event.target.value == 429) {
                //         this.getComboboxValues('mosque_care');
                //         event.target.value == 429 ? this.masjid_activity_active = true : this.masjid_activity_active = false;
                //     }
                //     if (event.target.value == 430) {
                //         this.getComboboxValues('Trainer');
                //         event.target.value == 430 ? this.trainer_active = true : this.trainer_active = false;
                //     }
                //     if (event.target.value == 431) {
                //         this.getComboboxValues('Accountant');
                //         event.target.value == 431 ? this.accountant_active = true : this.accountant_active = false;
                //     }
                //     if (event.target.value == 432) {
                //         this.getComboboxValues('Office_Jobs');
                //         event.target.value == 432 ? this.office_job_active = true : this.office_job_active = false;
                //     }
                //     if (event.target.value == 433) {
                //         event.target.value == 433 ? this.private_other_job_active = true : this.private_other_job_active = false;
                //     }
                //     break;
                // case 'professional':
                //     this.doctor_active = false;
                //     this.engineer_active = false;
                //     this.it_professional_active = false;
                //     if (event.target.value == 415) {
                //         this.getComboboxValues('doctor');
                //         event.target.value == 415 ? this.doctor_active = true : this.doctor_active = false;
                //     }
                //     if (event.target.value == 417) {
                //         this.getComboboxValues('engineer');
                //         event.target.value == 417 ? this.engineer_active = true : this.engineer_active = false;
                //     }
                //     if (event.target.value == 420) {
                //         this.getComboboxValues('IT_Professional');
                //         event.target.value == 420 ? this.it_professional_active = true : this.it_professional_active = false;
                //     }
                //     break;
                // case 'doctor':
                //     this.alopathy_active = false;
                //     if (event.target.value == 434) {
                //         this.getComboboxValues('allopathy');
                //         event.target.value == 434 ? this.alopathy_active = true : this.alopathy_active = false;
                //     }
                //     break;
                // case 'it_professional':
                //     this.programmer_active = false;
                //     this.db_admin_active = false;
                //     if (event.target.value == 562) {
                //         this.getComboboxValues('Programmer');
                //         event.target.value == 562 ? this.programmer_active = true : this.programmer_active = false;
                //     }
                //     if (event.target.value == 563) {
                //         this.getComboboxValues('Database_Admin');
                //         event.target.value == 563 ? this.db_admin_active = true : this.db_admin_active = false;
                //     }
                //     break;
                // case 'public_salary':
                //     this.kerala_gov_active = false;
                //     this.kerala_gov_cat_active = false;
                //     this.central_gov_active = false;
                //     this.central_gov_cat_active = false;
                //     this.central_semi_gov_active = false;
                //     this.kerala_semi_gov_active = false;
                //     this.central_gov_company_active = false;
                //     this.kerala_gov_company_active = false;
                //     if (event.target.value == 572) {
                //         this.getComboboxValues('Kerala_Government');
                //         this.getComboboxValues('Catagory_gov_gob');
                //         event.target.value == 572 ? this.kerala_gov_active = true : this.kerala_gov_active = false;
                //         event.target.value == 572 ? this.kerala_gov_cat_active = true : this.kerala_gov_cat_active = false;
                //     }
                //     if (event.target.value == 573) {
                //         this.getComboboxValues('central_government');
                //         this.getComboboxValues('Catagory_gov_gob');
                //         event.target.value == 573 ? this.central_gov_active = true : this.central_gov_active = false;
                //         event.target.value == 573 ? this.central_gov_cat_active = true : this.central_gov_cat_active = false;
                //     }
                //     if (event.target.value == 593) {
                //         this.getComboboxValues('Kerala_Half_Government');
                //         this.kerala_semi_gov_active = true;
                //     }
                //     if (event.target.value == 592) {
                //         this.getComboboxValues('Kerala_Government_company');
                //         this.kerala_gov_company_active = true;
                //     }
                //     if (event.target.value == 595) {
                //         this.getComboboxValues('Central_Half_Government');
                //         this.central_semi_gov_active = true;
                //     }
                //     if (event.target.value == 594) {
                //         this.getComboboxValues('Central_Government_company');
                //         this.central_gov_company_active = true;
                //     }
                //     break;
                // case 'general_education':
                //     console.log(event.target.value);
                //     this.general_education_type = false;
                //     if (event.target.value == 315) {
                //         this.getComboboxValues('School Job_Type');
                //         this.getComboboxValues('School_Type');

                //         this.general_education_type = true;
                //     }
                //     break;
                default:
                    break;
            }
        },
        getComboboxValues(code, id = null, level = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    if (level == 2) {
                        this.jobLevel2List = response.data.data.list.options;
                        this.jobLevel2 = true;
                        // this.jobLevel3 = false;
                        // this.jobLevel4 = false;
                        // this.jobLevel5 = false;
                        // this.jobLevel6 = false;
                    }
                    if (level == 3) {
                        this.jobLevel3List = response.data.data.list.options;
                        this.jobLevel3 = true;
                        // this.jobLevel4 = false;
                        // this.jobLevel5 = false;
                        // this.jobLevel6 = false;
                    }
                    if (level == 4) {
                        this.jobLevel4List = response.data.data.list.options;
                        this.jobLevel4 = true;
                        // this.jobLevel5 = false;
                        // this.jobLevel6 = false;
                    }
                    if (level == 5) {
                        this.jobLevel5List = response.data.data.list.options;
                        this.jobLevel5 = true;
                        // this.jobLevel6 = false;
                    }
                    if (level == 6) {
                        this.jobLevel6List = response.data.data.list.options;
                        this.jobLevel6 = true;
                    }
                    if (code == 'JOB_MAIN_CATEGORY') {
                        this.job_list = response.data.data.list.options;
                    }
                    if (code == 'COUNTRY') {
                        this.country_list = response.data.data.list.options;

                    }

                    if (code == 'STATES_INDIA') {

                        this.state_list = response.data.data.list.options;
                    }

                    if (code == 'DISTRICT_KERALA') {

                        this.district_list = response.data.data.list.options;
                    }

                    if (code == 'STUDYING_LEVEL') {

                        this.what_study_list = response.data.data.list.options;
                    }

                    if (code == 'PLUS_TWO_STREAM') {

                        this.plustwo_list = response.data.data.list.options;
                    }

                    if (code == 'CAMPUS_TYPE') {

                        this.campus_list = response.data.data.list.options;
                    }

                    if (code == 'STUDYING_COURSE') {

                        this.course_list = response.data.data.list.options;
                    }
                    if (code == 'PROFESSIONAL_COURSE') {

                        this.course_list = response.data.data.list.options;
                    }

                    if (code == 'PG') {

                        this.course_list = response.data.data.list.options;
                    }
                    if (code == 'PG_PROFESSIONAL_COURSE') {
                        this.course_list = response.data.data.list.options;
                    }

                    if (code == 'DIPLOMA_COURSE') {

                        this.course_list = response.data.data.list.options;
                    }

                    if (code == 'STUDYING_YEAR') {

                        this.study_year_list = response.data.data.list.options;
                    }

                    if (code == 'MEDICAL_COURSES') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'ENGINEERING_COURSES') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'PARAMEDICAL_COURSES') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'ARCHITECTURE') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'MANAGEMENT') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'MEDICAL_PG') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'ENGINEERING_PG') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'PARAMEDICAL_PG_COURSES') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'ARCHITECTURE') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                    if (code == 'MANAGEMENT') {
                        this.course_stream_list = response.data.data.list.options;
                    }
                },
            );
        }
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

.label {
    font-size: 12px !important;
}

.form-control {
    height: 30px !important;
}

.mobile-field {
    margin-top: 23px;
}

.hr-line {
    height: 8px !important;
    background-color: #1a418c;
    opacity: 75%;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1 !important;
}

.listview-title {
    color: #1a418c;
    padding: 1px 0px;
    font-size: 17px;
    font-weight: 900;
}

.section-title {
    color: #1a418c;
    padding: 1px 0%;
    font-size: 17px;
    font-weight: 900;
}
</style>