<template>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section inset mt-1 sticky" v-if="!loading && exam_started">
        <div class="wide-block" style="background-color: aquamarine;">
            <ul class="listview flush transparent simple-listview no-space">
                <li>
                    <strong class="text-primary mal-text">ഉത്തരം നൽകിയത്</strong>
                    <span><b>{{answerStatus}}/{{answers_list.length}}</b></span>
                </li>
            </ul>
        </div>
    </div>
    <div class="section inset mt-1" v-if="!loading && !exam_started && start && responseData.members.length == 0">
        <div class="wide-block pt-1">
            <div class="listed-detail mt-0 text-center">
                <h3 class="text-center text-danger mal-text" style="font-size: 14px">
                    {{ responseData.message }}
                </h3>
            </div>
        </div>
    </div>
    <div class="section inset mt-1" v-if="!loading && !exam_started && start && responseData.members.length > 0">
        <div class="wide-block pt-1">
            <div class="listed-detail mt-0 text-center">
                <h3 class="text-center text-primary mal-text" style="font-size: 14px">
                    താങ്കളുടെ മൊബൈൽ നമ്പറിന് കീഴിലുള്ള മെമ്പറെ പരീക്ഷ എഴുതുന്നതിനായി സെലക്ട് ചെയ്യുക
                </h3>
            </div>
            <ul class="listview image-listview">
                <li v-for="member in responseData.members" v-bind:key="member.id">
                    <a href="javascript:void(0)" @click="selectMember(member)" class="item" :disabled="member.disabled">
                <img src="assets/img/sample/avatar/avatar1.png" alt="image" class="image" />
                <div class="in">
                  <div>
                    <header>{{ member.reg_number }}</header>
                    <span class="text-primary">{{ member.name }}</span>
                    <footer :class="member.exam_color">{{ member.exam_status }}</footer>
                  </div>
                  <span class="text-primary"><ion-icon :name="member.icon" style="font-size: 35px;"></ion-icon></span>
                </div>
              </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="section inset mt-1" v-if="!loading && !start">
        <div class="wide-block pt-1">
            <div class="listed-detail mt-0 text-center">
                <h3 class="text-center mal-text" style="font-size: 14px">
                    പരീക്ഷ എഴുതുന്ന ആളുടെ വിവരങ്ങൾ
                </h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-1">
                <!-- <li>
              <strong class="text-primary text-mal">രജിസ്റ്റർ നമ്പർ</strong>
              <span>{{ selected_member.reg_number }}</span>
            </li> -->
                <li>
                    <strong class="text-primary mal-text">പേര്</strong>
                    <span>{{ selected_member.name }}</span>
                </li>
                <li>
                    <strong class="text-primary mal-text">സ്റ്റാറ്റസ്</strong>
                    <span :class="selected_member.exam_color">{{ selected_member.exam_status }}</span>
                </li>
                <li v-if="selected_member.exam_status == 'പരീക്ഷ എഴുതി' && responseData.certificate_download">
                    <strong class="text-primary mal-text">സർട്ടിഫിക്കറ്റ്</strong>
                    <span class="text-primary"><a :href="'https://guide.msoftit.com/print-exam-certificate-download/'+selected_member.id" class="text-danger"> Download </a></span>
                </li>
                <!-- <li v-if="selected_member.exam_status == 'പരീക്ഷ എഴുതി'">
                    <strong class="text-primary mal-text">ഉത്തരം നൽകിയത്</strong>
                    <span class="text-primary">{{answerStatus}}/{{answers_list.length}}</span>
                </li> -->
                <li v-if="selected_member.mark">
                    <strong class="text-primary mal-text">മാർക്ക്</strong>
                    <span v-html="selected_member.mark"></span>
                </li>
                <li v-if="!exam_started && this.responseData.members.length > 1">
                    <button type="button" class="btn btn-outline-primary me-1 mal-text btn-lg col-12" @click="openMemberModel" style="padding: 50px;">
                മറ്റു മെമ്പർമാരെ സെലക്ട് ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക.
                <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon>
              </button>
                </li>
            </ul>
        </div>
    </div>

    <div class="section mt-1" v-if="!loading && responseData.exam_end && !start">
        <div class="card bg-white mb-2">
            <div class="card-header mal-text text-danger text-center">
              ക്ഷമിക്കണം. പരീക്ഷ എഴുതാനുള്ള സമയം അവസാനിച്ചു.
            </div>
        </div>
    </div>
    <div class="section mt-1" v-if="!loading && !exam_started && !start && !selected_member.exam_done && !responseData.exam_end">
        <div class="card text-primary bg-white mb-2">
            <div class="card-header mal-text text-center">
                പരീക്ഷ തുടങ്ങുന്നതിനു മുൻപ് താഴെ കൊടുത്തിരിക്കുന്നത് ശ്രദ്ധിച്ചു വായിക്കുക
            </div>
            <div class="card-body">
                <p class="card-text" v-html="this.description">
    
                </p>
            </div>
        </div>
        <button type="button" class="btn btn-primary me-1 mal-text btn-lg mb-3 col-12" @click="startExam" style="padding: 30px;">
          പരീക്ഷ തുടങ്ങാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക
          <ion-icon name="chevron-forward-outline" style="font-size: 40px;"></ion-icon>
        </button>
    </div>
    <div class="modal fade action-sheet" id="chooseMemberModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mal-text text-danger">
                        താങ്കളുടെ മൊബൈൽ നമ്പറിന് കീഴിലുള്ള മെമ്പറെ പരീക്ഷ എഴുതുന്നതിനായി സെലക്ട് ചെയ്യുക
                    </h5>
                </div>
                <div class="modal-body">
                    <ul class="listview image-listview">
                        <li v-for="member in responseData.members" v-bind:key="member.id">
                            <a href="javascript:void(0)" @click="selectMember(member)" class="item">
                    <img src="assets/img/sample/avatar/avatar1.png" alt="image" class="image" />
                    <div class="in">
                      <div>
                        <header>{{ member.reg_number }}</header>
                        <span class="text-primary">{{ member.name }}</span>
                        <footer>Done</footer>
                      </div>
                      <span class="text-primary"><ion-icon name="arrow-forward-outline"></ion-icon></span>
                    </div>
                  </a>
                        </li>
                    </ul>
                    <ul class="action-button-list">
                        <!-- <li class="action-divider"></li> -->
                        <li>
                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal" @click="closeModel">
                    <span> <ion-icon name="close-outline"></ion-icon> Cancel </span>
                  </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Exam Start -->
    <div class="section mt-1" v-if="!loading && exam_started && !start && !selected_member.exam_done">
        <Form @submit="updateAttempt" :validation-schema="schema">
            <div v-for="(questians, index) in selected_questians" v-bind:key="questians.id">
                <div class="listed-detail mt-1 page-title mb-1" v-if="index == 0">
                    <h3 class="text-center mt-1 custom-title">Section {{questians.q_section}}</h3>
                    <p class="text-danger mal-text text-center pl-1 pr-1" v-html="this.responseData.section_a_text">

                    </p>
                </div>
                <div class="listed-detail mt-1 page-title mb-1 mal-text" v-else-if="selected_questians[index-1].q_section != questians.q_section">
                    <h3 class="text-center mt-1 custom-title">Section {{questians.q_section}}</h3>
                    <p class="text-danger mal-text text-center pl-1 pr-1" v-if="questians.q_section == 'B'" v-html="this.responseData.section_b_text"></p>
                    <p class="text-danger mal-text text-center pl-1 pr-1" v-if="questians.q_section == 'C'" v-html="this.responseData.section_c_text"></p>
                </div>
                <div class="card bg-white mb-2">
                    <div class="card-header mal-text">
                        ചോദ്യം #{{ index + 1 }}
                        <div class="spinner-grow text-danger" role="status" v-if="!answers_list[index].a"></div>
                        <div class="iconbox text-success" style="font-size: 30px" v-if="answers_list[index].a">
                            <ion-icon name="checkmark-circle" role="img" class="md hydrated" aria-label="checkmark circle"></ion-icon>
                        </div>
                    </div>
                    <div class="card-body" v-if="questians.q_section == 'A'">
                        <h5 class="card-title mal-text">{{ questians.q_text }}</h5>
                        <div class="card-text">
                            <div class="form-check mb-1">
                                <Field type="radio" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionA-' + questians.id" value="A" @change="updateAttempt('update')" />
                                <label class="form-check-label mal-text" :for="'optionA-' + questians.id">{{ questians.option_A }}</label>
                            </div>
                            <div class="form-check mb-1">
                                <Field type="radio" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionB-' + questians.id" value="B" @change="updateAttempt('update')" />
                                <label class="form-check-label mal-text" :for="'optionB-' + questians.id">{{ questians.option_B }}</label>
                            </div>
                            <div class="form-check mb-1">
                                <Field type="radio" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionC-' + questians.id" value="C" @change="updateAttempt('update')" />
                                <label class="form-check-label mal-text" :for="'optionC-' + questians.id">{{ questians.option_C }}</label>
                            </div>
                            <div class="form-check mb-1">
                                <Field type="radio" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionD-' + questians.id" value="D" @change="updateAttempt('update')" />
                                <label class="form-check-label mal-text" :for="'optionD-' + questians.id">{{ questians.option_D }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="card-body" v-if="questians.q_section == 'B'">
                        <h5 class="card-title mal-text">{{ questians.q_text }}</h5>
                        <div class="card-text">
                            <div class="form-check mb-1">
                                <Field type="checkbox" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionA-' + questians.id" value="A" @change="updateAttempt('update')" />
                                <label class="form-check-label mal-text" :for="'optionA-' + questians.id">{{ questians.option_A }}</label>
                            </div>
                            <div class="form-check mb-1">
                                <Field type="checkbox" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionB-' + questians.id" value="B" @change="updateAttempt('update')" />
                                <label class="form-check-label mal-text" :for="'optionB-' + questians.id">{{ questians.option_B }}</label>
                            </div>
                            <div class="form-check mb-1">
                                <Field type="checkbox" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionC-' + questians.id" value="C" @change="updateAttempt('update')" />
                                <label class="form-check-label mal-text" :for="'optionC-' + questians.id">{{ questians.option_C }}</label>
                            </div>
                            <div class="form-check mb-1">
                                <Field type="checkbox" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionD-' + questians.id" value="D" @change="updateAttempt('update')" />
                                <label class="form-check-label mal-text" :for="'optionD-' + questians.id">{{ questians.option_D }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="card-body" v-if="questians.q_section == 'C'">
                        <h5 class="card-title mal-text">{{ questians.q_text }}</h5>
                        <div class="card-text">
                            <div class="form-check mb-1">
                                <Field type="number" class="form-control" id="name"
                                v-model="answers_list[index].a" :name="'questian' + questians.id" @change="updateAttempt('update')"  placeholder="Enter Answer" autocomplete="off" />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    
        <button type="button" class="btn btn-success me-1 mal-text btn-lg mb-3 col-12" @click="updateattempConfirm('submit')" style="padding: 30px;">
          Submit ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക
          <ion-icon name="chevron-forward-outline" style="font-size: 40px;"></ion-icon>
        </button>
    </div>
    
    <div class="section mt-1" v-if="!loading && exam_result">
        <Form @submit="updateAttempt" :validation-schema="schema">
            <div v-for="(questians, index) in selected_questians" v-bind:key="questians.id">
                <div class="listed-detail mt-1 page-title mb-1" v-if="index == 0">
                    <h3 class="text-center mt-1 custom-title">Section {{questians.q_section}}</h3>
                    <p class="text-danger mal-text text-center pl-1 pr-1">{{ responseData.section_a_text }} 
                    </p>
                </div>
                <div class="listed-detail mt-1 page-title mb-1" v-else-if="selected_questians[index-1].q_section != questians.q_section">
                    <h3 class="text-center mt-1 custom-title">Section {{questians.q_section}}</h3>
                    <p class="text-danger mal-text text-center pl-1 pr-1" v-if="questians.q_section == 'B'">{{ responseData.section_b_text }} </p>
                    <p class="text-danger mal-text text-center pl-1 pr-1" v-if="questians.q_section == 'C'">{{ responseData.section_c_text }} </p>
                </div>
                <div class="card bg-white mb-2">
                    <div class="card-header mal-text text-primary">
                        ചോദ്യം #{{ index+ 1 }}
                        <div class="iconbox text-success" style="font-size: 30px" v-if="answers_r_list[index].r == 'Y'">
                            <ion-icon name="checkmark-circle" role="img" class="md hydrated" aria-label="checkmark circle"></ion-icon>
                        </div>
                        <div class="iconbox text-danger" style="font-size: 30px" v-if="answers_r_list[index].r == 'N'">
                            <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="checkmark circle"></ion-icon>
                        </div>
                    </div>
                    <div class="card-body" v-if="questians.q_section == 'A'">
                        <h5 class="card-title mal-text">{{ questians.q_text }}</h5>
                        <div class="card-text">
                            <div class="form-check mb-1">
                                A.&nbsp;
                                <Field type="radio" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionA-' + questians.id" :disabled="true" value="A" />
                                <label class="form-check-label" :for="'optionA-' + questians.id">{{ questians.option_A }}</label>
                            </div>
                            <div class="form-check mb-1">
                                B.&nbsp;
                                <Field type="radio" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionB-' + questians.id" value="B" :disabled="true" />
                                <label class="form-check-label" :for="'optionB-' + questians.id">{{ questians.option_B }}</label>
                            </div>
                            <div class="form-check mb-1">
                                C.&nbsp;
                                <Field type="radio" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionC-' + questians.id" value="C" :disabled="true" />
                                <label class="form-check-label" :for="'optionC-' + questians.id">{{ questians.option_C }}</label>
                            </div>
                            <div class="form-check mb-1">
                                D.&nbsp;
                                <Field type="radio" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionD-' + questians.id" value="D" :disabled="true" />
                                <label class="form-check-label" :for="'optionD-' + questians.id">{{ questians.option_D }}</label>
                            </div>
                        </div>
                        <div class="alert alert-outline-danger mb-1" role="alert" v-if="answers_r_list[index].r == 'N'">
                            {{ answers_r_list[index].m }}
                        </div>
                        <div class="alert alert-outline-success mb-1" role="alert" v-if="answers_r_list[index].r == 'Y'">
                            {{ answers_r_list[index].m }}
                        </div>
                    </div>
                    <div class="card-body" v-if="questians.q_section == 'B'">
                        <h5 class="card-title mal-text">{{ questians.q_text }}</h5>
                        <div class="card-text">
                            <div class="form-check mb-1">
                                A.&nbsp;
                                <Field type="checkbox" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionA-' + questians.id" :disabled="true" value="A" />
                                <label class="form-check-label" :for="'optionA-' + questians.id">{{ questians.option_A }}</label>
                            </div>
                            <div class="form-check mb-1">
                                B.&nbsp;
                                <Field type="checkbox" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionB-' + questians.id" value="B" :disabled="true" />
                                <label class="form-check-label" :for="'optionB-' + questians.id">{{ questians.option_B }}</label>
                            </div>
                            <div class="form-check mb-1">
                                C.&nbsp;
                                <Field type="checkbox" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionC-' + questians.id" value="C" :disabled="true" />
                                <label class="form-check-label" :for="'optionC-' + questians.id">{{ questians.option_C }}</label>
                            </div>
                            <div class="form-check mb-1">
                                D.&nbsp;
                                <Field type="checkbox" class="form-check-input" v-model="answers_list[index].a" :name="'questian' + questians.id" :id="'optionD-' + questians.id" value="D" :disabled="true" />
                                <label class="form-check-label" :for="'optionD-' + questians.id">{{ questians.option_D }}</label>
                            </div>
                        </div>
                        <div class="alert alert-outline-danger mb-1" role="alert" v-if="answers_r_list[index].r == 'N'">
                            {{ answers_r_list[index].m }}
                        </div>
                        <div class="alert alert-outline-success mb-1" role="alert" v-if="answers_r_list[index].r == 'Y'">
                            {{ answers_r_list[index].m }}
                        </div>
                    </div>
                    <div class="card-body" v-if="questians.q_section == 'C'">
                        <h5 class="card-title mal-text">{{ questians.q_text }}</h5>
                        <div class="card-text">
                            <div class="form-check mb-1">
                                <Field type="text" class="form-control" id="name" readonly
                                v-model="answers_list[index].a" :name="'questian' + questians.id" placeholder="" autocomplete="off" />
                                <div class="alert alert-outline-danger mb-1 mt-1" role="alert" v-if="answers_r_list[index].r == 'N'">
                                    {{ answers_r_list[index].m }}
                                </div>
                                <div class="alert alert-outline-success mb-1 mt-1" role="alert" v-if="answers_r_list[index].r == 'Y'">
                                    {{ answers_r_list[index].m }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    </div>
    
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model">
    </alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" :yes_button="dialog_yes_button" :no_button="dialog_no_button" ref="dialog_model">
    </dialog-model>
</template>

<script>
import $ from "jquery";
import { Form, Field } from "vee-validate";
import UserService from "../../../services/user.service";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
export default {
    name: "Exam",
    components: {
        AlertModel,
        DialogModel,
        Form,
        Field,
    },
    data() {
        return {
            loading: false,
            responseData: {
                exam: [],
                members: [],
            },
            description: [],
            selected_member: [],
            selected_exam: [],
            selected_questians: [],
            answers_list: [],
            answers_r_list: [],
            exam_started: false,
            start: true,
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            dialog_yes_button: "",
            dialog_no_button: "",
            countDown: 30,
            exam_result: false,
            section_old:'D'

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        splitArray(desc) {
            return desc.split('|');
        },
        answerStatus() {
            let count = 0;
            this.answers_list.forEach(element => {
                console.log(element);
                if(element.a != null && element.a != ""){
                    count ++;
                }
            });
            return count;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        }
        this.$root.updateParent("Loading...");

        this.getMembers();
        window.onpopstate = function() {
            //   if ($("body").hasClass("modal-open")) {
            //     location.reload();
            //   }
            this.backAction();
        };
    },
    methods: {
        startTimer(duration) {
            var timer = duration,
                minutes, seconds;
            let parent = this;
            setInterval(function() {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                parent.$root.updateMinutes(minutes);
                parent.$root.updateSeconds(seconds);
                if (minutes == 0 && seconds == 0) {
                    parent.updateAttempt('time_over_submit');

                }
                if (--timer < 0) {
                    timer = duration;
                }
            }, 1000);
        },
        backAction() {
            this.dialog_action_params = {};
            this.dialog_message = "ശരിക്കും ബാക്കിലേക്ക് പോകണോ?";
            this.dialog_action = "backActionConfirm";
            this.$refs.dialog_model.openModel();
        },
        backActionConfirm() {
            alert("back");
        },
        selectMember(member) {
            this.loading = true;
            this.start = false;
            this.selected_member = member;
            if (member.exam_done) {
                this.selected_questians = member.questians;
                this.answers_list = member.questians_r;
                this.answers_r_list = member.questians_a;
                this.exam_result = true;
            }
            this.loading = false;
        },
        closeMemberModel() {
            this.loading = false;
            $("#chooseMemberModel").modal("hide");
        },
        openMemberModel() {
            this.loading = true;
            this.start = true;
            this.loading = false;
            this.selected_questians = [];
            this.answers_list = [];
            this.answers_r_list = [];
            this.exam_result = false;
            // $("#chooseMemberModel").modal("show");
        },
        startExam() {
            this.dialog_action_params = {};
            this.dialog_message = this.selected_exam.exam_start_text;
            this.dialog_action = "startExamConfirm";
            this.dialog_yes_button = "തുടങ്ങുക";
            this.dialog_no_button = "വേണ്ട";
            this.$refs.dialog_model.openModel();
        },
        startExamConfirm() {
            this.loading = true;
            this.storeAttempt();
            //   this.getQuestians();
            this.exam_started = true;
        },
        getMembers() {
            this.loading = true;
            let data = {
                exam_id: this.$route.params.exam,
            };
            UserService.authPostRequest(`get-exam-members`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.responseData = response.data.data;
                        this.selected_exam = this.responseData.exam;
                        this.description = response.data.data.description;
                        if (response.data.data.live_exam) {
                            this.selected_questians = response.data.data.live_exam.questians;
                            this.answers_list = response.data.data.live_exam.answers_list;
                            this.selected_member = response.data.data.live_exam.member;
                            if (response.data.data.live_exam.bal_time > 0) {
                                var fiveMinutes = response.data.data.live_exam.bal_time;
                                this.startTimer(fiveMinutes);
                            }
                            this.exam_started = true;
                            this.start = false;
                            this.loading = false;
                        } else {
                            if (this.responseData.members.length == 1) {
                                this.selected_member = this.responseData.members[0];
                                this.exam_started = false;
                                this.start = false;
                                this.loading = false;
                                if (this.responseData.members[0].exam_done) {
                                    this.selected_questians = this.responseData.members[0].questians;
                                    this.answers_list = this.responseData.members[0].questians_r;
                                    this.answers_r_list = this.responseData.members[0].questians_a;
                                    this.exam_result = true;
                                }
                            } else {
                                this.exam_started = false;
                                this.start = true;
                                this.loading = false;
                            }
                        }
                        this.$root.updateParent(this.responseData.exam.name);
                    } else {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.responseData = response.data.data;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = "Warning";
                    this.alert_message = (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                }
            );
        },
        getQuestians() {
            this.loading = true;
            let data = {
                exam_id: this.$route.params.exam,
                exam_registration_id: this.selected_member.id,
            };
            UserService.authPostRequest(`get-exam-questians`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.responseData = response.data.data;
                        this.loading = false;
                    } else {
                        this.responseData = response.data.data;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        storeAttempt() {
            this.loading = true;
            let data = {
                exam_id: this.$route.params.exam,
                exam_registration_id: this.selected_member.id,
            };
            UserService.authPostRequest(`store-attempt`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.responseData = response.data.data;
                        console.log(this.responseData);
                        this.selected_questians = response.data.data.questians;
                        this.answers_list = response.data.data.answers_list;
                        var fiveMinutes = this.selected_exam.max_time * 60;
                        this.startTimer(fiveMinutes);
                        this.loading = false;
                    } else {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.responseData = response.data.data;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = "Warning";
                    this.alert_message = (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                }
            );
        },
        updateattempConfirm(type) {
            this.dialog_action_params = type;
            this.dialog_message = this.selected_exam.exam_submit_text;
            this.dialog_action = "updateAttempt";
            this.dialog_yes_button = "സബ്മിറ്റ്";
            this.dialog_no_button = "വേണ്ട";
            this.$refs.dialog_model.openModel();
        },
        updateAttempt(type) {
            if (type != "update") {
                this.loading = true;
            }

            let data = {
                exam_id: this.$route.params.exam,
                exam_registration_id: this.selected_member.id,
                answers_list: this.answers_list,
                type: type,
            };
            UserService.authPostRequest(`update-attempt`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.responseData = response.data.data;
                        if (type == "submit") {
                            this.alert_type = "Success";
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            setTimeout(function() {
                                location.reload();
                            }, 4000);
                        } else if (type == 'time_over_submit') {
                            this.alert_type = "Success";
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            setTimeout(function() {
                                location.reload();
                            }, 3000);
                        }
                        this.loading = false;
                    } else {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.responseData = response.data.data;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = "Warning";
                    this.alert_message = (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                }
            );
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.searchbox .form-control {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #bdbdd5;
    height: 36px;
    padding: 0 10px 0 10px;
}

.form-check {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    min-height: auto;
    height: auto;
    padding-bottom: 5px;
}

.input-group .input-group-text {
    background: transparent;
    border: 0;
    border: 1px solid #1a418c;
    /* border-top: 1px solid #1a418c; */
    border-radius: 5px 5px 5px 5px;
    font-weight: 400;
    color: #27173e;
    height: auto;
    width: auto;
    padding: 0px 10px 0px 10px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.card .card-header {
    background: transparent;
    color: #27173e;
    font-weight: 500;
    font-size: 17px;
    padding: 5px 10px;
    border-bottom: 1px solid #dcdce9;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.spinner-grow {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: 0.75s linear infinite spinner-grow;
}

.btn-lg {
    height: 40px;
    padding: 3px 0px;
    font-size: 14px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1;
}

.fixed-div{
    position: fixed;
    z-index: 100;
    width: 100%;
}
.fixed-card{
    border-radius: 0px;
}

.sticky {
    width: 100%;
    z-index: 100;
    position: fixed;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
}
.mal-text{
    font-size: 20px !important;
}
</style>
