<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">{{ page_title }}</h3>
        </div>
    </div>
    <div class="section">
        <div class="row mt-1">
            <div class="col-12">
                <div class="stat-box">
                    <div class="title mal-text">Total</div>
                    <div class="value text-primary">{{ member_lists.total ? member_lists.total : '00' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div id="search">
        <form class="search-form" v-if="$route.params.sub_level == 1">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>District</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_district" name="filter_district" id="select4" @change="getMembersList(1, $event.target.value,filter_zone,filter_unit,filter_fees,filter_user_type)">
                    <option value>All</option>
                    <option v-for="district in district_list" v-bind:key="district.id" v-bind:value="district.id">
                        {{ district.name }}
                    </option>
                </select>
                <i class="input-icon"><ion-icon name="filter-outline"></ion-icon></i>
            </div>
        </form>
        <form class="search-form" v-if="$route.params.sub_level == 2">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>Zone</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_zone" name="filter_zone" id="select4" @change="getMembersList(1, filter_district,$event.target.value,filter_unit,filter_fees,filter_user_type)">
                    <option value>All</option>
                    <option v-for="zone in zone_list" v-bind:key="zone.id" v-bind:value="zone.id">
                        {{ zone.name }}
                    </option>
                </select>
                <i class="input-icon"><ion-icon name="filter-outline"></ion-icon></i>
            </div>
        </form>
        <form class="search-form" v-if="$route.params.sub_level == 3">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>Unit</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_unit" name="filter_unit" id="select4" @change="getMembersList(1,filter_district,filter_zone,$event.target.value,filter_fees,filter_user_type)">
                    <option value>All</option>
                    <option v-for="unit in unit_list" v-bind:key="unit.id" v-bind:value="unit.id">
                        {{ unit.name }}
                    </option>
                </select>
                <i class="input-icon"><ion-icon name="filter-outline"></ion-icon></i>
            </div>
        </form>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും ചേർത്തിട്ടില്ല
        </h4>
    </div>
    
    <ul class="listview image-listview inset text mt-2" v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id" :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                    <span class="sl-no">{{ (member_lists.from + index) }}</span>
                    <div class="avatar-section">
                        <a href="#">
                            <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                            <span class="button verified" v-if="member_list.payment_status == 'Paid' && permissions.fees">
                                <ion-icon name="checkmark-outline"></ion-icon>
                            </span>
                            <span class="button not-verified" v-if="member_list.payment_status == 'Pending' && permissions.fees">
                                <ion-icon name="close-outline"></ion-icon>
                            </span>
                        </a>
            </div>
            <div class="in">
                <div class="min-width-50">
                    <!-- <header>User</header> -->
                    <b> {{ member_list.name }} </b>
                    <footer class="text-primary">{{ member_list.mobile }}
                    </footer>
                </div>
                <div class="card-button dropdown">
                    <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal" :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                            </button>
                </div>
    
                <!-- Default Action Sheet Inset -->
                <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Action</h5>
                            </div>
                            <div class="modal-body">
                                <ul class="action-button-list">
    
                                    <li>
                                        <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                    <span>
                                                        <ion-icon name="call-outline"></ion-icon> Call
                                                    </span>
                                                </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="viewDirectory(member_list)" class="btn btn-list" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="eye-outline"></ion-icon> View
                                                    </span>
                                        </a>
                                    </li>
                                    <li class="action-divider"></li>
                                    <li>
                                        <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="close-outline"></ion-icon> Cancel
                                                    </span>
                                                </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- * Default Action Sheet Inset -->
            </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total" :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page" :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false"
        />
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Nerpatham Report',
    components: {
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            user_level_id:'',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            count_total: 0,
            count_target: 0,
            level: [],
            nerpatham_target: '',
            target_title: 'ടാർഗറ്റ്',
            page_title: 'റിപ്പോർട്ട്',
            series: [0, 0],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                colors: ['#64b167', '#db7491'],
                labels: ['Target', 'Total'],
                responsive: [{
                    breakpoint: 100,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            responseData: {
                permissions: []
            },
            district_list : [],
            zone_list : [],
            unit_list : [],

            filter_district:'',
            filter_zone:'',
            filter_unit:'',
            filter_fees:'',
            filter_user_type:''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.count_target - this.count_total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.user_level_id = parseInt(this.$route.params.id);
            this.getMembersList();
            this.$root.updateParent('Loading...');
            if(this.user_level == 1){
                this.getDistrictList();
            }else if(this.user_level == 2){
                this.getZoneList(this.user_level_id);
            }else if(this.user_level == 3){
                this.getUnitList(this.user_level_id);
            }
            
        }

        window.onpopstate = function() {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getMembersList(page = 1, district = null,zone = null,unit = null,fees = null,user_type = null) {
            this.loading_list = true;
            if(zone || unit){
                user_type = '';
                this.filter_user_type = '';
            }
            let data = {
                user_level: this.user_level,
                user_level_id: parseInt(this.$route.params.id),
                exam_type: this.$route.params.exam,
                district_id: district?district:this.filter_district,
                zone_id: zone?zone:this.filter_zone,
                unit_id:unit?unit:this.filter_unit,
                fees_status:fees?fees:this.filter_fees,
                user_type: user_type?user_type:this.filter_user_type
            }
            
            UserService.authPostRequest(`get-directory-report?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.permissions = response.data.data.permissions;
                        this.member_lists = response.data.data.list;
                        this.level = response.data.data.level;
                        this.count_target = response.data.data.count_target;
                        this.count_total = response.data.data.count_total;
                        this.level = response.data.data.level;
                        this.target_title = response.data.data.target_title;
                        this.page_title = response.data.data.page_title;
                        this.loading_list = false;
                        this.$root.updateParent('Directory Report');
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'DISTRICT_KERALA':
                            this.district_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getDistrictList() {
            this.loading_list = true;
            let data = {
            }
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    if (error)
                        this.loading_list = true;
                    this.district_list = [];
                }
            );
        },
        getZoneList(district_id) {
            this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    if (error)
                        this.loading_list = true;
                    this.zone_list = [];
                }
            );
        },
        getUnitList(zone_id) {
            this.loading_list = true;
            let data = {
                zone_id: zone_id
            }
            UserService.authPostRequest(`get-unit-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.unit_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    if (error)
                        this.loading_list = true;
                    this.zone_list = [];
                }
            );
        },
        viewDirectory(directory){
            this.$router.push('/directory/youth-directory-form/'+directory.id);
        }
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: auto;
}

.left {
    margin-left: 20px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 0px 16px;
    position: relative;
    z-index: 1;
}
</style>