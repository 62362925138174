<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3 page-title">
            <h3 class="text-center mt-2 mal-text custom-title">റിലീഫ് രെജിസ്ട്രേഷൻ</h3>
        </div>
    </div>
    <div class="section pt-0 mt-1 pb-2">
        <div class="card">
            <div class="card-body">
                <Form @submit="storeData" :validation-schema="schema">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                കുടുംബ നാഥന്റെ പേര്
                                <span class="text-danger">*</span>
                            </label>
                            <Field name="family_leader_name" type="text" class="form-control"
                                v-model="formData.family_leader_name" placeholder="പേര്" autocomplete="off" />
                            <ErrorMessage name="family_leader_name" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary mal-text" for="text4b">
                                കുടുംബ നാഥൻ പുരുഷനാണോ അതോ സ്ത്രീയാണോ
                                <span class="text-danger">*</span>
                            </label>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Male" name="family_leader_sex"
                                    id="family_leader_sex_1" v-model="formData.family_leader_sex" />
                                <label class="form-check-label" for="family_leader_sex_1">പുരുഷൻ &nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Female" name="family_leader_sex"
                                    id="family_leader_sex_2" v-model="formData.family_leader_sex" />
                                <label class="form-check-label" for="family_leader_sex_2">സ്ത്രീ</label>
                            </div>
                            <br />
                            <ErrorMessage name="family_leader_sex" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                കുടുംബ നാഥന്റെ വയസ്സ്
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="number" class="form-control" name="family_leader_age"
                                v-model="formData.family_leader_age" placeholder="00" autocomplete="off" />
                            <ErrorMessage name="family_leader_age" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                കുടുംബ നാഥന്റെ വീട്ട്‌ പേര്
                            </label>
                            <Field type="text" class="form-control" name="family_leader_family_name"
                                v-model="formData.family_leader_house_name" placeholder="വീട്ട്‌ പേര്"
                                autocomplete="off" />
                            <ErrorMessage name="family_leader_family_name" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                കുടുംബത്തെ നേരിട്ട് ബന്ധപ്പെടാൻ കഴിയുന്ന ഫോൺ നമ്പർ<span class="text-danger">*</span>
                            </label>
                            <Field type="number" class="form-control" name="family_leader_mobile"
                                v-model="formData.family_leader_mobile" placeholder="ഫോൺ നമ്പർ" autocomplete="off" />
                            <ErrorMessage name="family_leader_mobile" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                ഇപ്പൊൾ താമസിക്കുന്ന സ്ഥലം
                            </label>
                            <Field type="text" class="form-control" name="place" v-model="formData.place"
                                placeholder="താമസിക്കുന്ന സ്ഥലം" autocomplete="off" />
                            <ErrorMessage name="place" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                പഞ്ചായത്ത് / മുനിസിപ്പാലിറ്റി
                            </label>
                            <Field type="text" class="form-control" name="panjayath" v-model="formData.panjayath"
                                placeholder="പഞ്ചായത്ത് / മുനിസിപ്പാലിറ്റി" autocomplete="off" />
                            <ErrorMessage name="panjayath" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                കുടുംബത്തെ ലിസ്റ്റില് ഉൾപ്പെടുത്താൻ ഉള്ള ഏറ്റവും പ്രധാനപ്പെട്ട കാരണങ്ങൾ
                                <span class="text-danger">*</span>
                            </label>
                            <div class="form-check mb-1 col-12" v-for="(item, index) in relief_reason_list"
                                :value="item.id" v-bind:key="item.id" :set="(id = 'customCheckb' + (index + 1))">
                                <input :disabled="disable_all" name="relief_reason" type="checkbox"
                                    class="form-check-input" v-bind:id="id" v-bind:value="item.id"
                                    v-model="formData.relief_reason" />
                                <label class="form-check-label" v-bind:for="id">{{
                    item.name_mal
                }}</label>
                            </div>
                            <ErrorMessage name="relief_reason" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary mal-text" for="text4b">
                                ഈ കുടുംബം സകാതിന്റെ അവകാശികളിൽ ഉൾപ്പെടുന്നുണ്ടോ ?
                                <span class="text-danger">*</span>
                            </label>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Yes" name="zakath" id="zakath_1"
                                    v-model="formData.zakath" />
                                <label class="form-check-label" for="zakath_1">ഉണ്ട് &nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="No" name="zakath" id="zakath_2"
                                    v-model="formData.zakath" />
                                <label class="form-check-label" for="zakath_2">ഇല്ല</label>
                            </div>
                            <ErrorMessage name="zakath" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                കുടുംബത്തിൽ താമസിക്കുന്ന ആകെ ആളുകളുടെ എണ്ണം
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="number" class="form-control" name="total_count" v-model="formData.total_count"
                                placeholder="എണ്ണം" autocomplete="off" />
                            <ErrorMessage name="total_count" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                കുടുംബത്തിൽ ഉള്ള 16 വയസ്സിൽ താഴെ ഉള്ള കുട്ടികളുടെ എണ്ണം
                            </label>
                            <Field type="number" @input="getChild(null, 1)" class="form-control" name="lessthan_16_count"
                                v-model="formData.lessthan_16_count" placeholder="എണ്ണം" autocomplete="off" />
                            <ErrorMessage name="lessthan_16_count" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                പിതാവ് മരണപ്പെട്ടു കുട്ടികളുടെ എണ്ണം (16 വയസ്സിൽ താഴേ)
                            </label>
                            <Field type="number" class="form-control" name="yatheem_count"
                                v-model="formData.yatheem_count" placeholder="എണ്ണം" autocomplete="off" />
                            <ErrorMessage name="yatheem_count" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                ഭർത്താവ് മരണപ്പെട്ട വിധവകളുടെ എണ്ണം
                            </label>
                            <Field type="number" class="form-control" name="widow_count" v-model="formData.widow_count"
                                placeholder="എണ്ണം" autocomplete="off" />
                            <ErrorMessage name="widow_count" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                കുടുംബത്തിന് ഈ വർഷം നൽകാൻ കഴിയുന്ന സഹായങ്ങൾ
                            </label>
                            <div class="form-check mb-1 col-12" v-for="(item, index) in relief_type_list"
                                :value="item.id" v-bind:key="item.id" :set="(id = 'ScustomCheckb' + (index + 1))">
                                <input :disabled="disable_all" name="relief_types[]" @change="getChild(item.item_value)"
                                    type="checkbox" class="form-check-input" v-bind:id="id" v-bind:value="item.id"
                                    v-model="formData.relief_types" />
                                <label class="form-check-label" v-bind:for="id">{{
                    item.name_mal
                }}</label>
                            </div>
                            <ErrorMessage name="relief_types" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic"
                        v-if="formData.lessthan_16_count && children_enable && (old_child_count == formData.lessthan_16_count)">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                16 വയസ്സിൽ താഴെ ഉള്ള കുട്ടികളുടെ വിവരങ്ങൾ
                            </label>
                            <div v-for="index in childLength" :key="index" class="card text-white mb-2"
                                style="border: 1px solid blue; margin-top: 10px">
                                <div class="card-body" style="padding: 5px">
                                    <div class="row">

                                        <div class="col-12">
                                            <Field type="text" class="form-control" :name="'child_name[' + (index - 1) + ']'"
                                                v-model="formData.child[index - 1].name" placeholder="പേര്"
                                                autocomplete="off" />
                                        </div>
                                        <div class="col-6 pt-2">
                                            <div class="form-check form-check-inline">
                                                <input type="radio" class="form-check-input" value="Boy"
                                                    :name="'child_sex[' + (index - 1) + ']'" :id="'child_sex_1_' + (index - 1)"
                                                    v-model="formData.child[index - 1].sex" />
                                                <label class="form-check-label" :for="'child_sex_1_' + (index - 1)">Boy
                                                    &nbsp;</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input type="radio" class="form-check-input" value="Girl"
                                                    :name="'child_sex[' + (index - 1) + ']'" :id="'child_sex_2_' + (index - 1)"
                                                    v-model="formData.child[index - 1].sex" />
                                                <label class="form-check-label"
                                                    :for="'child_sex_2_' + (index - 1)">Girl</label>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <Field type="number" class="form-control" :name="'child_age[' + (index - 1) + ']'"
                                                v-model="formData.child[index - 1].age" placeholder="വയസ്സ് "
                                                autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group basic" v-if="editable_id">
                        <div class="input-wrapper">
                            <label class="label text-primary mal-text" for="text4b">
                                ഈ ഫാമിലിയെ ഈ വർഷത്തെ ലിസ്റ്റിൽ ഉൾപെടുത്തണോ?
                                <span class="text-danger">*</span>
                            </label>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Yes" name="this_year_reg"
                                    id="this_year_reg_1" v-model="formData.this_year_reg" />
                                <label class="form-check-label" for="this_year_reg_1">വേണം&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="No" name="this_year_reg"
                                    id="this_year_reg_2" v-model="formData.this_year_reg" />
                                <label class="form-check-label" for="this_year_reg_2">വേണ്ട</label>
                            </div>
                            <ErrorMessage name="this_year_reg" class="error-feedback" />
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Save</span>
                    </button>
                </Form>
            </div>
        </div>
    </div>

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import $ from "jquery";

import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null,
        permissions: Object,
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            family_leader_name: yup
                .string()
                .matches(nameRegex, "പേര് ഇംഗീഷിൽ ടൈപ്പ് ചെയ്യുക!")
                .required("ഫിൽ ചെയ്യുക!"),
            family_leader_mobile: yup.string().required("ഫിൽ ചെയ്യുക!"),
            // family_leader_sex: yup.string().required("ഫിൽ ചെയ്യുക!"),
            family_leader_age: yup.string().required("ഫിൽ ചെയ്യുക!"),
            // relief_reason: yup.array().required("ഫിൽ ചെയ്യുക!"),
            // zakath: yup.string().required("ഫിൽ ചെയ്യുക!"),
            total_count: yup.string().required("ഫിൽ ചെയ്യുക!"),
            // relief_types: yup.array().required("ഫിൽ ചെയ്യുക!"),
        });
        return {
            loading: false,
            schema,
            user_level: "",
            unit: [],
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            relief_reason_list: [],
            relief_type_list: [],
            children_enable: false,
            formData: {
                family_leader_name: "",
                family_leader_sex: "",
                family_leader_age: "",
                family_leader_house_name: "",
                family_leader_mobile: "",
                place: "",
                panjayath: "",
                relief_reason: [],
                zakath: "",
                this_year_reg: "",
                total_count: 0,
                lessthan_16_count: 0,
                yatheem_count: 0,
                widow_count: 0,
                relief_types: [],
                child: [],
            },
            reliefFamily: [],
            old_child_count: 0,
            editable_id: ''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        childLength() {
            return parseInt(this.formData.lessthan_16_count);
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            if (this.unit_id == this.currentUser.unit_id || this.currentUser.user_level == 4) {
                this.editable = true;
            } else {
                this.editable = false;
            }
            this.$root.updateParent("Relief Registration");
            if (this.$route.params.family_id) {
                this.getReliefFamily(this.$route.params.family_id);
            }
        }
        this.getComboboxValues("RELIEF_REASON");
        this.getComboboxValues("RELIEF_TYPE");
        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "RELIEF_REASON":
                            this.relief_reason_list = response.data.data.list.options;
                            break;
                        case "RELIEF_TYPE":
                            this.relief_type_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        checkStudent() {
            let stud_items = false;
            this.formData.relief_types.forEach(element => {
                this.relief_type_list.forEach(element_ => {
                    if (element_.id == element) {
                        if (element_.item_value == 'student') {
                            stud_items = true;
                        }
                    }
                });
            });

            return stud_items;
        },
        getChild(type = null, count_inc = null) {
            if (type == "student") {
                if (!this.formData.lessthan_16_count) {
                    this.formData.relief_types.pop();
                    this.alert_type = "Warning";
                    this.alert_message = "16 വയസ്സിനു താഴെ ഉള്ള കുട്ടികളുടെ എണ്ണം നൽകുക.";
                    this.$refs.alert_model.openModel();
                } else {
                    if (this.formData.child.length > 0) {
                        for (let i = (this.formData.child.length + 1); i <= parseInt(this.formData.lessthan_16_count); i++) {
                            this.formData.child.push({
                                name: '',
                                age: '',
                                sex: '',
                                editable_id: null
                            });
                        }
                    } else {
                        for (let i = 1; i <= parseInt(this.formData.lessthan_16_count); i++) {
                            this.formData.child.push({
                                name: '',
                                age: '',
                                sex: '',
                                editable_id: null
                            });
                        }
                    }

                    this.old_child_count = parseInt(this.formData.lessthan_16_count);
                }
            } else if (count_inc && this.children_enable) {
                let count = this.formData.child.length;
                for (let i = (count + 1); i <= parseInt(this.formData.lessthan_16_count); i++) {
                    this.formData.child.push({
                        name: '',
                        age: '',
                        sex: '',
                        editable_id: null
                    });
                }
                this.old_child_count = parseInt(this.formData.lessthan_16_count);
            }
            if (this.checkStudent()) {
                this.children_enable = true;
            } else {
                this.children_enable = false;
            }

            console.log(this.formData.child);

        },
        storeData() {
            let data = this.formData;
            data.unit_id = parseInt(this.$route.params.id);
            data.user_level = parseInt(this.$route.params.sub_level);
            data.editable_id = this.editable_id;
            let child_validation = false;
            if (data.lessthan_16_count > 0) {
                data.child.forEach(element => {
                    if (element.name == '' || element.age == '' || element.sex == '') {
                        child_validation = true;
                    }
                });
                if (child_validation) {
                    this.alert_type = 'Warning';
                    this.alert_message = '16 വയസ്സിൽ താഴെ ഉള്ള കുട്ടികളുടെ വിവരങ്ങൾ പൂർണ്ണമായി നൽകുക.';
                    this.$refs.alert_model.openModel();
                    this.loading = false;
                    return false;
                }
            }

            UserService.addAdmin('store-relief-family', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 2000);

                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getReliefFamily(id) {
            let data = {
                id: id
            }
            UserService.authPostRequest('get-relief-family', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.reliefFamily = response.data.data.list;
                        this.formData.family_leader_name = this.reliefFamily.family_head_name;
                        this.formData.family_leader_sex = this.reliefFamily.family_head_gender;
                        this.formData.family_leader_age = this.reliefFamily.family_head_age;
                        this.formData.family_leader_house_name = this.reliefFamily.family_head_surname;
                        this.formData.family_leader_mobile = this.reliefFamily.family_contact;
                        this.formData.place = this.reliefFamily.family_place;
                        this.formData.panjayath = this.reliefFamily.family_panchayath;
                        this.formData.zakath = this.reliefFamily.zakath_eligible;
                        this.formData.total_count = this.reliefFamily.family_members_count;
                        this.formData.lessthan_16_count = this.reliefFamily.boys_count;
                        this.formData.yatheem_count = this.reliefFamily.orphan_count;
                        this.formData.widow_count = this.reliefFamily.widow_count;
                        this.editable_id = this.reliefFamily.id;
                        if (this.reliefFamily.family_reason_to_include) {
                            this.formData.relief_reason = JSON.parse(this.reliefFamily.family_reason_to_include);
                        }
                        if (this.reliefFamily.relief_types) {
                            this.formData.relief_types = JSON.parse(this.reliefFamily.relief_types);
                            if (response.data.data.child.length > 0) {
                                response.data.data.child.forEach(element => {
                                    this.formData.child.push({
                                        name: element.child_name,
                                        age: element.child_age,
                                        sex: element.child_sex,
                                        editable_id: element.id
                                    });
                                });
                                this.old_child_count = response.data.data.child.length;
                                this.children_enable = true;
                            }
                        }
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}
</style>
