<template>
    <div class="dashboard-container">
        <div class="dashboard">
            <!-- Header Section -->
            <div class="header-section">
                <div class="action-buttons">
                    <button class="button primary-button">LAJNA MEMBER</button>
                    <button class="button outline-button">ABOUT ME</button>
                </div>
            </div>

            <!-- Profile Section -->
            <div class="profile-section">
                <div class="user-profile">
                    <div class="profile-image">
                        <img src="https://via.placeholder.com/80" alt="" />
                    </div>
                    <div class="profile-info">
                        <h2>Mohammed Jamal K</h2>
                        <p>Wisdom: State Executive Member</p>
                    </div>
                </div>
            </div>

            <!-- Action Buttons -->
            <div class="action-section">
                <button class="button rewards-button">REWARDS</button>
                <router-link to="/lajna/lajna-page" class="button lajna-button">GO TO LAJNA PAGE</router-link>
            </div>

            <!-- Information Box -->
            <div class="info-box">
                <p>
                    ലജ്‌ന അംഗങ്ങൾക്ക് മാത്രമുള്ള വീഡിയോ, ഓഡിയോ തുടങ്ങിയ സൗകര്യങ്ങൾ ലഭിക്കാനുള്ള പേജ്. ഈ സൗകര്യങ്ങൾ
                    ലഭിക്കാൻ ലജ്‌ന സംസ്ഥാന സമിതിയുമായി ബന്ധപ്പെടുക.
                </p>
            </div>

            <!-- Footer Section -->
            <div class="footer-section">
                <div class="bottom-buttons">
                    <button class="button outline-button">CHANGE PIN</button>
                    <button class="button outline-button">LOG OUT</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dashboard'
}
</script>

<style scoped>
.dashboard-container {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.dashboard {
    width: 100%;
    max-width: 400px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

.header-section {
    padding: 1.25rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #eee;
}

.action-buttons {
    display: flex;
    gap: 0.75rem;
}

.button {
    flex: 1;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.primary-button {
    background-color: #c1c400;
    color: white;
}

.primary-button:hover {
    background-color: #b1b300;
    transform: translateY(-1px);
}

.outline-button {
    background-color: white;
    color: #1565c0;
    border: 1px solid #e0e4e8;
}

.outline-button:hover {
    background-color: #f8f9fa;
    transform: translateY(-1px);
}

.profile-section {
    padding: 1.25rem;
}

.user-profile {
    background-color: white;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 1px solid #eee;
}

.profile-image {
    position: relative;
}

.profile-image img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid #1e88e5;
    padding: 3px;
    background-color: white;
}

.profile-info h2 {
    margin: 0;
    font-size: 1rem;
    color: #333;
    font-weight: 600;
}

.profile-info p {
    margin: 0.25rem 0 0;
    font-size: 0.86rem;
    color: #666;
}

.action-section {
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.rewards-button {
    background-color: #c1c400;
    color: white;
}

.lajna-button {
    background-color: #4caf50;
    color: white;
    text-align: center !important;
}

.info-box {
    margin: 1.25rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #555;
}

.footer-section {
    padding: 1.25rem;
    border-top: 1px solid #eee;
}

.bottom-buttons {
    display: flex;
    gap: 0.75rem;
}

/* Hover Effects */
.button:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.rewards-button:hover {
    background-color: #b1b300;
}

.lajna-button:hover {
    background-color: #43a047;
}

.user-profile:hover {
    transform: translateY(-2px);
    transition: transform 0.2s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
</style>