<template>
    <div class="section pt-2 mt-1" v-if="$route.params.member_id">
        <router-link :to="'/org/4/' + member.unit_id">
            <a href="javascript:void(0)">
                <button class="btn btn-primary report-btn mal-text">
                    <ion-icon name="arrow-undo-outline"></ion-icon>
                    മെമ്പേഴ്‌സ് ലിസ്റ്റിലേക്ക് തിരിച്ചു പോകാൻ ഇവിടെ അമർത്തുക
                </button>
            </a>
        </router-link>
    </div>
    <div class="section mt-2 mb-2" v-if="profiles.length > 1 && !user_id">
        <div class="section-title mal-text" v-if="!$route.params.member_id">നിങ്ങളുടെ മൊബൈൽ നമ്പറിന്റെ കീഴിലുള്ള
            മെമ്പർമാർ.</div>
        <div class="section-title mal-text" v-if="$route.params.member_id">
            {{ this.member.name }} യുടെ മൊബൈൽ നമ്പറിന്റെ കീഴിലുള്ള മെമ്പർമാർ.</div>
    </div>
    <div class="section text-center mt-2 pt-2" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <!-- App Capsule -->
    <!-- <ul class="listview image-listview inset text mt-2 pt-2" v-if="profiles.length > 1 && !user_id">
        <li v-for="item in profiles" :value="item.id" v-bind:key="item.id">
            <a href="javascript:void(0)" class="item" @click="selectProfile(item.id)">
                <div class="in mal-text">
                    <div class="main-text">
                        <b class="main-text">{{ item.name }}</b>
                    </div>
                </div>
            </a>
        </li>
    </ul> -->

    <div class="section mt-2 mb-2" v-if="!loading_list && profiles.length > 1 && !user_id">
        <div class="goals">
            <!-- item -->
            <div class="item" v-for="item in profiles" :value="item.id" v-bind:key="item.id">
                <a href="javascript:void(0)" @click="selectProfile(item.id)">
                    <div class="in">
                        <div>
                            <h4>{{ item.name }}</h4>
                            <p>ബയോ ഡാറ്റ ഫിൽ ചെയ്യാൻ/എഡിറ്റ് ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക </p>
                        </div>
                        <div v-if="item.fillStatus == 100" class="success_label"></div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" :class="item.fillStatus == 100 ? 'bg-success' : 'bg-primary'"
                            role="progressbar" :style="{ width: item.fillStatus + '%' }" aria-valuenow="85"
                            aria-valuemin="0" aria-valuemax="100">{{ item.fillStatus }}%
                        </div>
                    </div>
                </a>
            </div>

            <!-- * item -->
        </div>
    </div>

    <div class="section mt-2 mb-2" v-if="profiles.length == 0 && !loading_list">
        <div class="section-title mal-text">നിങ്ങളുടെ നമ്പർ കരട് ലിസ്റ്റിൽ ചേർത്തിട്ടില്ല.</div>
    </div>

    <div class="section mt-2" v-if="(profiles.length == 1 || user_id) && fill_balance.length > 0">
        <div class="section-title">ഫിൽ ചെയ്യാൻ ബാക്കിയുള്ള ഡാറ്റകൾ.</div>
        <div class="card">
            <div class="card-body">
                <span class="badge badge-danger" v-for="item in fill_balance" v-bind:key="item">{{ item }}</span>
            </div>
        </div>
    </div>
    <div class="section mt-2 mb-2"
        v-if="!loading_list && currentUser.id == this.profile.user_id && (currentUser.wing != 4 && currentUser.wing != 6)">
        <Form @submit="storeBasicInfo" enctype='multipart/form-data'>
            <div class="form-group boxed">
                <div class="input-wrapper">
                    <label class="label mal-text" for="text4b">പ്രൊഫൈൽ ഫോട്ടോ</label>
                    <div class="custom-file-upload" id="fileUpload1">
                        <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg" @change="uploadImage($event)"/>
                        <label for="fileuploadInput">
                            <span>
                                <strong>
                                    <ion-icon name="arrow-up-circle-outline"></ion-icon>
                                    <i>Upload</i>
                                </strong>
                            </span>
                        </label>
                        <label v-if="profile_image" for="fileuploadInput" class="file-uploaded"
                            :style="{ 'background-image': 'url(' + profile_image + ')' }">
                        </label>
                    </div>
                </div>
            </div>
            <div class="transparent file-upload-button" v-if="profile_image_save">
                <button class="btn btn-primary btn-block btn-lg file-upload-button_" :disabled="loading">
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                    <span>Save</span>
                </button>
            </div>
        </Form>
    </div>
    <ul class="listview image-listview inset text mt-2 pt-2"
        v-if="(profiles.length == 1 && loading_list) || (user_id && !loading_list)">
        <li>
            <router-link :to="'/bio-data/basic-info/' + user_id">
                <a href="javascript:void(0)" class="item">
                    <div class="in mal-text">
                        <div class="main-text">
                            A.
                            <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                            <!-- <header>എ</header> -->
                            <b class="main-text">പേരും മേൽവിലാസവും</b>
                            <!-- <footer class="progress">
                                <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="100"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </footer> -->
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
        <li>
            <router-link :to="'/bio-data/education-info/' + user_id">
                <a href="javascript:void(0)" class="item">
                    <div class="in mal-text">
                        <div class="main-text">
                            B.
                            <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                            <!-- <header>എ</header> -->
                            <b class="main-text">വിദ്യാഭ്യാസ യോഗ്യത</b>
                            <!-- <footer>Total members : 100</footer> -->
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
        <li>
            <router-link :to="'/bio-data/job-info/' + user_id">
                <a href="javascript:void(0)" class="item">
                    <div class="in mal-text">
                        <div class="main-text">
                            C.
                            <b class="main-text">ജോലി/പഠനം </b>
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
        <li>
            <router-link :to="'/bio-data/language-info/' + user_id">
                <a href="javascript:void(0)" class="item">
                    <div class="in mal-text">
                        <div class="main-text">
                            D.
                            <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                            <!-- <header>എ</header> -->
                            <b class="main-text">കൈകാര്യം ചെയ്യുന്ന ഭാഷകൾ</b>
                            <!-- <footer>Total members : 100</footer> -->
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
        <li>
            <router-link :to="'/bio-data/contribute-info/' + user_id">
                <a href="javascript:void(0)" class="item">
                    <div class="in mal-text">
                        <div class="main-text">
                            E.
                            <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                            <!-- <header>എ</header> -->
                            <b class="main-text"> മറ്റു കഴിവുകൾ / അഭിരുചികൾ</b>
                            <!-- <footer>Total members : 100</footer> -->
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
        <li>
            <router-link :to="'/bio-data/disability-info/' + user_id">
                <a href="javascript:void(0)" class="item">
                    <div class="in mal-text">
                        <div class="main-text">
                            F.
                            <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                            <!-- <header>എ</header> -->
                            <b class="main-text">ശാരീരീരിക വെല്ലുവിളികൾ </b>
                            <!-- <footer>Total members : 100</footer> -->
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
    </ul>

    <!-- <ul class="listview image-listview inset text mt-2 pt-2" v-if="currentUser.user_level != 1">
        <li>
            <a href="javascript:void(0)" class="item">
                <div class="in mal-text">
                    <div class="main-text">
                        <b class="main-text">അടുത്ത ദിവസങ്ങളിൽ അപ്ഡേറ്റ് ചെയ്യുന്നതാണ്...</b>
                    </div>
                </div>
            </a>
        </li>
    </ul> -->
    <!-- * App Capsule -->
</template>
<script>
import { Form } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
export default {
    name: 'Basic Info',
    components: {
        Form
    },
    data() {
        const schema = yup.object().shape({
            vehicle_status: yup.string().required("വാഹനങ്ങൾ സെലക്ട് ചെയ്യുക!"),
        });
        return {
            profiles: [],
            profile: [],
            user_id: null,
            loading_list: false,
            loading: false,
            fill_balance: [],
            member: [],
            schema,
            profile_image: null,
            profile_file: null,
            profile_image_save: false,
            profile_image_uploaded: false
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.loading_list = true;
        this.getProfiles();

        this.$root.updateParent("ബയോ ഡാറ്റ");
    },
    methods: {
        uploadImage(event) {
            var file = event.target.files[0];
            this.profile_file = file;
            this.profile_image = URL.createObjectURL(file);
            this.profile_image_save = true;
        },
        getProfiles() {
            var data = {
                user_id: this.currentUser.id,
                member_id: this.$route.params.member_id
            }
            UserService.authPostRequest('get-profiles', data).then(
                (response) => {
                    this.profiles = response.data.data.list;
                    if (this.profiles.length == 1) {
                        this.user_id = this.profiles[0].id;
                        this.profile = this.profiles[0];
                        if (this.profiles[0].fields_pending)
                            this.fill_balance = (this.profiles[0].fields_pending.split(','));
                        this.fill_balance.splice(-1);
                        if (this.profile.avatar != 'member.jpg' && this.profile.avatar != null) {
                            this.profile_image = 'https://guide.msoftit.com/' + this.profile.avatar;
                        }

                    }
                    if (response.data.data.member) {
                        this.member = response.data.data.member;
                        this.$root.updateParent(this.member.name + " യുടെ ബയോ ഡാറ്റ");
                    }
                    this.loading_list = false;
                },
            );
        },
        selectProfile(id) {
            this.user_id = id;
            var item = [];
            this.profiles.forEach(element => {
                if (element.id == this.user_id) {
                    item = element;
                    this.profile = element;
                    if (this.profile.avatar != 'member.jpg' && this.profile.avatar != null) {
                        this.profile_image = 'https://guide.msoftit.com/' + this.item.avatar;
                    }
                }
            });
            this.fill_balance = (item.fields_pending.split(','));
            this.fill_balance.splice(-1);
            // var item = this.profiles.find(element => element.id = this.user_id);
        },
        storeBasicInfo() {
            const formData = new FormData();
            if (this.profile_file) {
                formData.append('avatar', this.profile_file);
            }
            formData.append('submit_type', 'profile_pic');
            formData.append('id', this.user_id);
            formData.append('user_id', this.currentUser.id);
            this.loading = true;
            UserService.authPostRequest('store-profile', formData).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.$swal('സേവ് ചെയ്തു', '', 'seccess');
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.main-text {
    font-size: 18px;
}

.badge-danger {
    background-color: #ef96ae !important;
    color: black;
    margin-left: 5px;
}

.listview>li a:after {
    content: "\f3d1" !important;
    font-family: "Ionicons" !important;
    font-size: 18px !important;
    position: absolute !important;
    right: 16px !important;
    height: 18px !important;
    top: 50% !important;
    margin-top: -9px !important;
    line-height: 1em !important;
    color: #A9ABAD !important;
    opacity: 0.6 !important;
}

.listview-title {
    color: #1a418c;
    padding: 1px 0px;
    font-size: 17px;
    font-weight: 900;
}

.section-title {
    color: #1a418c;
    padding: 1px 0%;
    font-size: 17px;
    font-weight: 900;
}

.custom-file-upload {
    width: 50%;
    height: 150px;
}

.file-upload-button {
    width: 50%;
}

.file-upload-button_ {
    height: 26px;
}
</style>