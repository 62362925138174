<template>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section mb-2 mt-1">
        <div class="transactions">
            <a v-for="event in event_list" v-bind:key="event.name" :href="'/#/call-follow-up/'+event.id" class="item">
                <div class="detail">
                    <div class="spinner-grow text-danger" role="status" v-if="event.featured"></div>
                    <img  v-else :src="event.icon ? event.icon : 'assets/img/telephone.png'" alt="img" class="image-block imaged w48">
                    <div>
                        <strong class="text-primary">{{ event.name }}</strong>
                        <p class="mal-text">{{ event.description ? event.description : 'പേജിലേക്ക് പോകാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക'
                        }}</p>
                        
                    </div>
                </div>
                <div class="right">
                    
                    <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                </div>
            </a>
        </div>
    </div>
    <!-- <div class="section pt-1" v-if="!loading_page">
        <a v-for="event in event_list" v-bind:key="event.name" :href="event.url">
            <button type="button" class="btn btn-outline-primary me-1 mal-text btn-lg col-12 mb-1 bg-white">
                {{ event.name }}
                <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon>
            </button>
        </a>
    </div> -->

    <!-- <ul class="listview image-listview inset mt-1" v-if="!loading">
        <li v-for="event in event_list" v-bind:key="event.name">
            <a :href="event.url" class="item">
                <div class="in">
                    <div>
                        <header>{{ event.date }}</header>
                        <b class="text-primary">{{ event.name }}</b>
                        <footer>{{ event.place }}</footer>
                    </div>
                    <ion-icon name="chevron-forward-outline" style="font-size: 60px"></ion-icon>
                </div>
            </a>
        </li>
    </ul> -->
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import $ from "jquery";
import UserService from "../../services/user.service";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null,
    },
    components: {
        AlertModel,
        DialogModel,
    },
    data() {
        return {
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],

            loading: false,
            event_list: [],
            permissions: [],
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        }
        this.$root.updateParent("Call Campaigns");
        this.getEvents();
        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },

    methods: {
        getEvents() {
            this.loading = true;
            let data = {
            };
            UserService.authPostRequest(`get-call-campigns`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.event_list = response.data.data.list;
                        this.permissions = response.data.data.permissions;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.transactions .item {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 5px 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price {
    font-size: 50px;
}

.spinner-grow{
    width: 48px;
    height: 48px;
    margin-right: 16px;
}
</style>
  