<template>
    <div class="section text-center mt-2 mb-2" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="section-title mal-text">B. വിദ്യാഭ്യാസ യോഗ്യത</div>
        <div class="card">
            <div class="card-body">
                <Form @submit="storeBasicInfo" :validation-schema="schema">
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">ഭൗതിക വിദ്യാഭ്യാസ യോഗ്യത (ഏറ്റവും അവസാനം
                                പൂർത്തിയാക്കിയ കോഴ്സ്)</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="qualification"
                                name="qualification" id="select4"
                                @change="getComboboxValues('PLUS_TWO_STREAM', $event, 'plustwo')">
                                <option v-for="item in qualification_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="qualification" class="error-feedback" />
                    </div>
                    <div class="form-group boxed" v-if="plusetwo_active">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">പ്ലസ് ടു വിഷയം</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="plustwo_stream"
                                name="plustwo_stream" id="select4">
                                <option v-for="item in plustwo_stream_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="plustwo_stream" class="error-feedback" />
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">മതപരമായ വിദ്യാഭ്യാസ യോഗ്യത (ഏറ്റവും അവസാനം
                                പൂർത്തിയാക്കിയ കോഴ്സ്)</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="islamic_qualification"
                                name="islamic_qualification" id="select4"
                                @change="getComboboxValues('RELIGIOUS_SPECIAL_DEGREE', $event, 'islamic')">
                                <option v-for="item in islamic_qualification_list" :value="item.id"
                                    v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="islamic_qualification" class="error-feedback" />
                    </div>
                    <div class="form-group boxed" v-if="special_active">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">ബിരുദം</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="islamic_special"
                                name="islamic_special" id="select4">
                                <option v-for="item in islamic_special_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="islamic_special" class="error-feedback" />
                    </div>

                    <div class="transparent pt-3" v-if="currentUser.id == profile.user_id">
                        <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save & Next</span>
                        </button>
                    </div>
                    <div class="transparent pt-3" v-if="currentUser.id != profile.user_id">
                        <button type="button" @click="goToNextPage" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Next</span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
export default {
    name: 'Basic Info',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            qualification: yup.string().required("ഭൗതിക വിദ്യാഭാസം സെലക്ട് ചെയ്യുക!"),
            islamic_qualification: yup.string().required("മതപരമായ വിദ്യാഭാസം സെലക്ട് ചെയ്യുക!"),
        });

        return {
            loading: false,
            message: "",
            schema,
            qualification: '',
            islamic_qualification: '',
            plustwo_stream: '',
            islamic_special: '',
            loading_list:false,
            disable_all:false,
            profile: [],

            qualification_list: [],
            plustwo_stream_list: [],
            islamic_qualification_list: [],
            islamic_special_list: [],
            plusetwo_active: false,
            special_active: false,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.getComboboxValues('EDUCATIONAL_QUALIFICATION');
        this.getComboboxValues('RELIGIOUS_EDUCATIONAL_QUALIFICATION');
        this.loading_list = true;
        this.getProfile();
        this.$root.updateParent("ബയോ ഡാറ്റ");
    },
    methods: {
        getProfile() {
            var data = {
                id: this.$route.params.id
            }
            UserService.authPostRequest('get-profile', data).then(
                (response) => {
                    this.profile = response.data.data.list;
                    if(this.profile.user_id != this.currentUser.id){
                        this.disable_all = true;
                        this.$root.updateParent(this.profile.name+" യുടെ ബയോ ഡാറ്റ");
                    }
                    this.qualification = this.profile.educationQualification ? this.profile.educationQualification : '';
                    if (this.profile.educationQualificationSubCategory) {
                        this.plusetwo_active = true;
                        this.getComboboxValues('PLUS_TWO_STREAM',null, 'plustwo')
                        this.plustwo_stream = this.profile.educationQualificationSubCategory;
                    } else {
                        this.plustwo_stream = '';
                    }

                    this.islamic_qualification = this.profile.islamicEducationQualification ? this.profile.islamicEducationQualification : '';
                    if (this.profile.islamicEducationQualificationSubCategory) {
                        this.special_active = true;
                        this.getComboboxValues('RELIGIOUS_SPECIAL_DEGREE', null, 'islamic');
                        this.islamic_special = this.profile.islamicEducationQualificationSubCategory;
                    } else {
                        this.islamic_special = '';
                    }

                    this.loading_list = false;
                },
            );
        },
        storeBasicInfo(data) {
            data.id = this.$route.params.id;
            data.user_id = this.currentUser.id;
            this.loading = true;
            UserService.authPostRequest('store-profile', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        // this.$swal('സേവ് ചെയ്തു', '', 'seccess');
                        this.loading = false;
                        this.$router.push('/bio-data/job-info/'+this.$route.params.id);
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToNextPage(){
            this.$router.push('/bio-data/job-info/' + this.$route.params.id);
        },
        getComboboxValues(code, id = null, type) {
            var data = {
                code: code,
                type_id: id
            }
            if (!id) {
                UserService.getCombobox(data).then(
                    (response) => {
                        switch (code) {
                            case 'EDUCATIONAL_QUALIFICATION':
                                this.qualification_list = response.data.data.list.options;
                                break;
                            case 'RELIGIOUS_EDUCATIONAL_QUALIFICATION':
                                this.islamic_qualification_list = response.data.data.list.options;
                                break;
                            default:
                                break;
                        }
                    },
                );
            } else {
                if (type == 'islamic') {

                    if (id.target.value == 155) {
                        this.special_active = true;
                    } else {
                        this.special_active = false;
                    }
                }
                if (type == 'plustwo') {
                    if (id.target.value == 120) {
                        this.plusetwo_active = true;
                    } else {
                        this.plusetwo_active = false;
                    }
                }
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'PLUS_TWO_STREAM':
                            this.plustwo_stream_list = response.data.data.list.options;
                            break;
                        case 'RELIGIOUS_SPECIAL_DEGREE':
                            this.islamic_special_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }

                },
            );
        }
    },
};
</script>
<style scoped>.section-heading {
    padding: 10px 10px 0px 10px;
}

.input-info {
    color: red !important;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 5px;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.font-dark {
    font-weight: 500;
}

.total-size {
    font-size: 18px;
}

.report-btn-success {
    width: 100%;
}

.icon-size20 {
    font-size: 20px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #1a418c;
    opacity: 100%;
}

.btn-accept-reject {
    height: 50px;
}
</style>