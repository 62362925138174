<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">വിസ്ഡം ഗൈഡിൽ മാറ്റം വരുത്താനുള്ള/ചേർക്കാനുള്ള അപേക്ഷ</h3>
        </div>
    </div>
    <div class="section pt-0 mt-1 pb-2">
        <div class="card">
            <div class="card-body">
                <Form @submit="handleRegistration" :validation-schema="schema">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="for_what">
                                                                            ഈ അപേക്ഷ എന്തിന് വേണ്ടി ?
                                                                            <span class="text-danger">*</span>
                                                                        </label>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="!permissions.edit" class="form-check-input" value="1" name="for_what" id="for_what_1" v-model="formData.for_what" />
                                <label class="form-check-label" for="for_what_1">ഭരവഹിയെ മാറ്റാൻ &nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="!permissions.edit" class="form-check-input" value="2" name="for_what" id="for_what_2" v-model="formData.for_what" />
                                <label class="form-check-label" for="for_what_2">വകുപ്പ് മാറ്റാൻ &nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline" v-if="formData.change_level == '4'">
                                <input type="radio" :disabled="!permissions.edit" class="form-check-input" value="3" name="for_what" id="for_what_3" v-model="formData.for_what" />
                                <label class="form-check-label" for="for_what_3">മെമ്പർഷിപ്പ് ഒഴിവാക്കാൻ &nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline" v-if="formData.change_level == '4'">
                                <input type="radio" :disabled="!permissions.edit" class="form-check-input" value="4" name="for_what" id="for_what_4" v-model="formData.for_what" />
                                <label class="form-check-label" for="for_what_4">പുതിയ മെമ്പർഷിപ് ചേർക്കാൻ  &nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="!permissions.edit" class="form-check-input" value="5" name="for_what" id="for_what_5" v-model="formData.for_what" />
                                <label class="form-check-label" for="for_what_5">മറ്റ് കാര്യങ്ങൾക്ക് &nbsp;</label>
                            </div>
    
                            <ErrorMessage name="for_what" class="error-feedback" />
                        </div>
                        <textarea v-if="formData.for_what == 5" :disabled="!permissions.edit" class="form-control" rows="5" name="for_what_other" v-model="formData.for_what_other">
                                                                            </textarea>
                        <ErrorMessage name="for_what_other" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary mal-text" for="change_level">
                                        എവിടെയാണ് മാറ്റം വരേണ്ടത് ?
                                        <span class="text-danger">*</span>
                                    </label>
                            <div class="form-check form-check-inline" v-if="currentUser.user_level <= 4">
                                <input type="radio" class="form-check-input" value="4" name="change_level" id="change_level_1" v-model="formData.change_level" @change="changeUserLevel" />
                                <label class="form-check-label" for="change_level_1">ശാഖാ[{{ profile.unit }}]&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline" v-if="currentUser.user_level <= 3">
                                <input type="radio" class="form-check-input" value="3" name="change_level" id="change_level_2" v-model="formData.change_level" @change="changeUserLevel" />
                                <label class="form-check-label" for="change_level_2">മണ്ഡലം[{{ profile.zone }}]&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline" v-if="currentUser.user_level <= 2">
                                <input type="radio" class="form-check-input" value="2" name="change_level" id="change_level_3" v-model="formData.change_level" @change="changeUserLevel" />
                                <label class="form-check-label" for="change_level_3">ജില്ല[{{ profile.district }}]&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline" v-if="currentUser.user_level == 1">
                                <input type="radio" class="form-check-input" value="1" name="change_level" id="change_level_4" v-model="formData.change_level" @change="changeUserLevel" />
                                <label class="form-check-label" for="change_level_4">സ്റ്റേറ്റ് &nbsp;</label>
                            </div>
                            <br />
                            <ErrorMessage name="change_level" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="meeting_date">
                                തീരുമാനിച്ച യോഗത്തിന്റെ തിയ്യതി
                                                                                    <span class="text-danger">*</span>
                                                                                </label>
                            <Field type="date" :disabled="!permissions.edit" class="form-control" name="meeting_date" v-model="formData.meeting_date" autocomplete="off" />
                            <ErrorMessage name="meeting_date" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="minutes_number">
                                                                                    തീരുമാനത്തിന്റെ ക്രമ നമ്പർ
                                                                                    <span class="text-danger">*</span>
                                                                                </label>
                            <Field type="text" :disabled="!permissions.edit" class="form-control" name="minutes_number" v-model="formData.minutes_number" placeholder="00" autocomplete="off" />
                            <ErrorMessage name="minutes_number" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                                                                    തീരുമാനത്തിന്റെ ഫോട്ടോ കഴിയുമെങ്കിൽ ഇവിടെ ചേർക്കുക
                                                                                </label>
                            <div class="custom-file-upload mt-1" id="fileUpload1">
                                <input type="file" :disabled="!permissions.edit" id="fileuploadInput" @change="uploadImage($event)" />
                                <label for="fileuploadInput">
                                                                                        <span>
                                                                                            <strong>
                                                                                                <ion-icon name="arrow-up-circle-outline"></ion-icon>
                                                                                                <i>Upload</i>
                                                                                            </strong>
                                                                                        </span>
                                                                                    </label>
                                <label v-if="profile_image" for="fileuploadInput" class="file-uploaded" :style="{ 'background-image': 'url(' + profile_image + ')' }">
                                                                                    </label>
                            </div>
                            <!-- <Field type="file" class="form-control" name="shop_photo" v-model="formData.shop_photo" placeholder="Shop Photo" autocomplete="off" /> -->
                            <ErrorMessage name="shop_photo" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic" v-if="formData.for_what == '1' || formData.for_what == '2' || formData.for_what == '3'">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="receipt_number">
                                                                                    ഒഴിവാക്കേണ്ട ആൾ
                                                                                </label>
                            <v-select label="text" :disabled="!permissions.edit" class="mt-1" :filterable="true" v-model="member" :value="member" :options="member_list" @input="getTextList($event.target.value,'old')" @change="getDepartments(member.member.id,member.user_level)">
                                <template>Type to search...
</template>

<template>
    <div class="d-center">
        {{ member.name }}</div>
</template>
                        </v-select>
                        <ErrorMessage name="member" class="error-feedback" />
                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == '2' && member">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="receipt_number">
                            ഒഴിവാക്കേണ്ട വകുപ്പ്
                        </label>
                        <div class="form-check mb-1 col-12" v-for="(item, index) in old_department_list"  v-bind:key="item.departmentid" :set="(id = 'ScustomCheckb' + (index + 1))">
                            <input :disabled="!permissions.edit" name="old_department[]" type="checkbox" :value="item.department.id" class="form-check-input" v-bind:id="id"  v-model="formData.old_department" @change="changeOld"/>
                            <label class="form-check-label" v-bind:for="id">{{
                                    item.department.name_mal
                                }}</label>
                        </div>
                        <ErrorMessage name="place" class="error-feedback" />
                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == '1' && member">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="receipt_number">
                            ഒഴിവാക്കേണ്ട സ്ഥാനം
                        </label>
                        <div class="form-check mb-1 col-12" v-for="(item, index) in old_duty" :value="item.user_sublevel" v-bind:key="item.user_sublevel" :set="(id = 'SDcustomCheckb' + (index + 1))">
                            <input :disabled="!permissions.edit" name="old_duty[]" type="checkbox" class="form-check-input" v-bind:id="id" v-bind:value="item.user_sublevel" v-model="formData.old_duty" @change="changeOld"/>
                            <label class="form-check-label" v-bind:for="id">{{
                                      item.name
                                    }}</label>
                        </div>
                        <!-- <div class="form-check mb-1 col-12" v-for="(item, index) in old_duty" :value="item.user_sublevel" v-bind:key="item.user_sublevel" :set="(id = 'SScustomCheckb' + (index + 1))">
                            <input :disabled="disable_all" name="old_duty[]" type="checkbox" :value="item.user_sublevel" class="form-check-input" v-bind:id="id"  v-model="formData.old_duty" />
                            <label class="form-check-label" v-bind:for="id">{{
                                    item.name
                                }}</label>
                        </div> -->
                        <ErrorMessage name="panjayath" class="error-feedback" />
                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == '1' || formData.for_what == '2'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="receipt_number">
                            ഈ സ്ഥാനത്തേക്ക് പുതുതായി തിരഞ്ഞെടുത്ത ആൾ
                        </label>
                        <v-select label="text" :disabled="!permissions.edit" class="mt-1" :filterable="true" v-model="new_member" :value="new_member" :options="new_member_list" @input="getTextList($event.target.value,'new')">
<template>
     Type to search...
</template>

<template>
    <div class="d-center">
        {{ new_member.name }}</div>
</template>
                        </v-select>
                        <ErrorMessage name="new_member" class="error-feedback" />
                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == '2' && member">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="receipt_number">
                            പുതിയ ആൾക്ക് നൽകേണ്ട വകുപ്പ്
                        </label>
                        <div class="form-check mb-1 col-12" v-for="(item, index) in new_department_list"  v-bind:key="item.department.id" :set="(id = 'SwcustomCheckb' + (index + 1))">
                            <input :disabled="true" name="new_department[]" type="checkbox" :value="item.department.id" class="form-check-input" v-bind:id="id" v-model="formData.new_department" />
                            <label class="form-check-label" v-bind:for="id">{{
                                    item.department.name_mal
                                }}</label>
                        </div>
                        <ErrorMessage name="place" class="error-feedback" />
                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == '1' && member">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="receipt_number">
                            പുതിയ ആൾക്ക് നൽകേണ്ട സ്ഥാനം.
                        </label>
                        <div class="form-check mb-1 col-12" v-for="(item, index) in new_duty" :value="item.user_sublevel" v-bind:key="item.user_sublevel" :set="(id = 'SDNcustomCheckb' + (index + 1))">
                            <input :disabled="true" name="new_duty[]" type="checkbox" class="form-check-input" v-bind:id="id" v-bind:value="item.user_sublevel" v-model="formData.new_duty" />
                            <label class="form-check-label" v-bind:for="id">{{
                                      item.name
                                    }}</label>
                        </div>
                        <!-- <div class="form-check mb-1 col-12" v-for="(item, index) in new_duty" v-bind:key="item.id" :set="(id = 'SSwcustomCheckb' + (index + 1))">
                            <input :disabled="disable_all" name="new_duty[]" type="checkbox" :value="item.user_sublevel" class="form-check-input" v-bind:id="id" v-model="formData.new_duty" />
                            <label class="form-check-label" v-bind:for="id">{{
                                    item.name
                                }}</label>
                        </div> -->
                        <ErrorMessage name="panjayath" class="error-feedback" />
                    </div>
                </div>
                <div v-if="formData.for_what == '4'">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="Name">Name <span
                                class="text-danger">*</span></label>
                            <Field name="name" type="text" class="form-control" id="name"
                                v-model="formData.name" placeholder="Enter Name" autocomplete="off" />
                        </div>
                        <ErrorMessage name="name" class="error-feedback" />
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="code">Code <span class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="code" name="code"
                                        v-model="formData.code">
                                        <option value="" :disabled="true" selected>#</option>
                                        <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                            v-bind:value="code.item_value">
                                            {{ code.name }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="code" class="error-feedback" />
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="mobile">Mobile <span
                                            class="text-danger">*</span></label>
                                    <Field type="number" class="form-control" id="mobile" name="mobile"
                                        placeholder="Mobile" v-model="formData.mobile"
                                        oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="mobile" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="country_code_whatsapp">Code</label>
                                    <Field as="select" class="form-control custom-select" id="country_code_whatsapp" name="country_code_whatsapp"
                                        v-model="formData.country_code_whatsapp" @change="onChangeCountryCode($event)">
                                        <option value="" :disabled="true" selected>#</option>
                                        <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                            v-bind:value="code.item_value">
                                            {{ code.name }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="code" class="error-feedback" />
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="whatsapp">Whatsapp </label>
                                    <Field type="number" class="form-control" id="whatsapp" name="whatsapp"
                                        placeholder="whatsapp" v-model="formData.whatsapp"
                                        oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="whatsapp" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="wing">Wing</label>
                                <Field as="select" class="form-control custom-select" v-model="formData.wing" name="wing"
                                    id="select4">
                                    <option value>---Select---</option>
                                    <option value="1">Wisdom</option>
                                    <option value="2">Youth</option>
                                    <option value="3">Students</option>
                                    <option value="4">Women</option>
                                    <option value="5">Balavedi</option>
                                    <option value="6">Girls</option>

                                </Field>
                            </div>
                        <ErrorMessage name="wing" class="error-feedback" />
                    </div>
                </div>
                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading" v-if="permissions.edit">
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                    <span>Save</span>
                </button>
            </Form>
        </div>
    </div>
</div>

<div class="section text-center" v-if="loading_list">
    <div class="spinner-border text-secondary" role="status"></div>
</div>

<alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
<dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import $ from "jquery";
import vSelect from 'vue-select'
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null
    },
    components: {
        Form,
        Field,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({
            meeting_date: yup.string().required("ഫിൽ ചെയ്യുക!"),
            minutes_number: yup.string().required("ഫിൽ ചെയ്യുക!"),
            name: yup.string().when('for_what', {
                is: value => value && value == 4,
                then: yup
                    .string()
                    .required("ഫിൽ ചെയ്യുക")
            }),
        });
        return {
            loading: false,
            schema,
            user_level: "",
            unit: [],
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            relief_reason_list: [],
            relief_type_list: [],
            children_enable: false,
            formData: {
                for_what: '',
                for_what_other: '',
                change_level: this.$route.params.sub_level,
                minutes_number: '',
                old_department: [],
                new_department: [],
                old_duty: [],
                new_duty: [],
                remarks: '',
                comments: '',
                name: '',
                code: '',
                mobile: '',
                whatsapp: '',
                country_code_whatsapp: '',
                wing: ''
            },
            reliefFamily: [],
            old_child_count: 0,
            editable_id: '',
            profile_image: null,
            profile_file: null,
            profile_image_save: false,
            profile_image_uploaded: false,
            member_list: [],
            member: [],
            new_member_list: [],
            new_member: [],
            old_department_list: [],
            new_department_list: [],
            old_duty: [],
            new_duty: [],
            requestData: [],
            country_code_list: [],
            profile: [],
            permissions: {
                edit: true
            }
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        childLength() {
            return parseInt(this.formData.lessthan_16_count);
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            if (this.unit_id == this.currentUser.unit_id || this.currentUser.user_level == 4) {
                this.editable = true;
            } else {
                this.editable = false;
            }
            this.$root.updateParent("Request Form");
            this.getComboboxValues('COUNTRY_CODE');
            if (this.$route.params.request_id) {
                this.getRequest(this.$route.params.request_id);
            }
            this.profile = JSON.parse(localStorage.getItem("my_profile"));
        }

        window.onpopstate = function() {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        changeOld() {
            this.formData.new_department = this.formData.old_department;
            this.formData.new_duty = this.formData.old_duty;
        },
        uploadImage(event) {
            var file = event.target.files[0];
            this.profile_file = file;
            this.profile_image = URL.createObjectURL(file);
            this.profile_image_save = true;
        },
        getDepartments(member_id, user_level) {
            if (this.formData.for_what == '4' || this.formData.for_what == '3') {
                return 0;
            }
            this.message = '';
            let data = {
                member_id: member_id,
                user_level: user_level
            }
            UserService.authPostRequest('get-department-by-member', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.old_department_list = response.data.data.departments;
                        this.old_duty = response.data.data.duty;

                        this.new_department_list = response.data.data.departments;
                        this.new_duty = response.data.data.duty;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, textbox = null) {
            let type = '';
            if (textbox == 'old') {
                type = 'executive';
            } else {
                type = 'members';
                if (this.formData.for_what == '1') {
                    this.formData.old_duty.forEach(element => {
                        if (element <= 5) {
                            type = 'executive';
                            this.new_member_list = [];
                            this.new_member = [];
                        }
                    });
                    this.formData.old_duty.forEach(element => {
                        if (element == 6) {
                            type = 'members';
                            this.new_member_list = [];
                            this.new_member = [];
                        }
                    });
                } else if (this.formData.for_what == '2') {
                    type = 'executive';
                }

            }

            if (this.formData.for_what == '3') {
                type = 'members';
            }
            let user_level = this.formData.change_level;
            let user_level_id = 0;
            if (user_level == 1) {
                user_level_id = 0;
            } else if (user_level == 2) {
                user_level_id = this.currentUser.district_id;
            } else if (user_level == 3) {
                user_level_id = this.currentUser.zone_id;
            } else if (user_level == 4) {
                user_level_id = this.currentUser.unit_id;
            }

            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                user_level_id: user_level_id,
                user_level: user_level,
                wing: this.currentUser.wing,
            }
            UserService.authPostRequest('get-members-executives-by-id', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        if (textbox == 'old') {
                            this.member_list = response.data.data.list;
                        } else {
                            this.new_member_list = response.data.data.list;
                        }

                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "RELIEF_REASON":
                            this.relief_reason_list = response.data.data.list.options;
                            break;
                        case "RELIEF_TYPE":
                            this.relief_type_list = response.data.data.list.options;
                            break;
                        case "COUNTRY_CODE":
                            this.country_code_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        checkStudent() {
            let stud_items = false;
            this.formData.relief_types.forEach(element => {
                this.relief_type_list.forEach(element_ => {
                    if (element_.id == element) {
                        if (element_.item_value == 'student') {
                            stud_items = true;
                        }
                    }
                });
            });

            return stud_items;
        },
        getChild(type = null, count_inc = null) {
            if (type == "student") {
                if (!this.formData.lessthan_16_count) {
                    this.formData.relief_types.pop();
                    this.alert_type = "Warning";
                    this.alert_message = "16 വയസ്സിനു താഴെ ഉള്ള കുട്ടികളുടെ എണ്ണം നൽകുക.";
                    this.$refs.alert_model.openModel();
                } else {
                    if (this.formData.child.length > 0) {
                        for (let i = (this.formData.child.length + 1); i <= parseInt(this.formData.lessthan_16_count); i++) {
                            this.formData.child.push({
                                name: '',
                                age: '',
                                sex: '',
                                editable_id: null
                            });
                        }
                    } else {
                        for (let i = 1; i <= parseInt(this.formData.lessthan_16_count); i++) {
                            this.formData.child.push({
                                name: '',
                                age: '',
                                sex: '',
                                editable_id: null
                            });
                        }
                    }

                    this.old_child_count = parseInt(this.formData.lessthan_16_count);
                }
            } else if (count_inc && this.children_enable) {
                let count = this.formData.child.length;
                for (let i = (count + 1); i <= parseInt(this.formData.lessthan_16_count); i++) {
                    this.formData.child.push({
                        name: '',
                        age: '',
                        sex: '',
                        editable_id: null
                    });
                }
                this.old_child_count = parseInt(this.formData.lessthan_16_count);
            }
            if (this.checkStudent()) {
                this.children_enable = true;
            } else {
                this.children_enable = false;
            }

            console.log(this.formData.child);

        },
        handleRegistration() {
            if (!this.formData.for_what) {
                this.alert_type = "Warning";
                this.alert_message = 'അപേക്ഷ എന്തിനു വേണ്ടി എന്ന് സെലക്ട് ചെയ്യുക';
                this.$refs.alert_model.openModel();
                return false;
            }
            if ((this.formData.for_what == "1" || this.formData.for_what == "2")) {
                if (!this.member.member_id) {
                    this.alert_type = "Warning";
                    this.alert_message = 'ഒഴിവാക്കേണ്ട ആളെ സെലക്ട് ചെയ്യുക';
                    this.$refs.alert_model.openModel();
                    return false;
                }
                if (!this.new_member.member_id) {
                    this.alert_type = "Warning";
                    this.alert_message = 'പുതുതായി ചേർക്കേണ്ട ആളെ സെലക്ട് ചെയ്യുക';
                    this.$refs.alert_model.openModel();
                    return false;
                }
                if (!this.formData.old_duty.length && this.formData.for_what == "1") {
                    this.alert_type = "Warning";
                    this.alert_message = 'ഒഴിവാക്കേണ്ട സ്ഥാനം സെലക്ട് ചെയ്യുക';
                    this.$refs.alert_model.openModel();
                    return false;
                }
                if (!this.formData.new_duty.length && this.formData.for_what == "1") {
                    this.alert_type = "Warning";
                    this.alert_message = 'പുതുതായി ചേർക്കേണ്ട സ്ഥാനം സെലക്ട് ചെയ്യുക';
                    this.$refs.alert_model.openModel();
                    return false;
                }
                if (!this.formData.old_department.length && this.formData.for_what == "2") {
                    this.alert_type = "Warning";
                    this.alert_message = 'ഒഴിവാക്കേണ്ട വകുപ്പുകൾ സെലക്ട് ചെയ്യുക';
                    this.$refs.alert_model.openModel();
                    return false;
                }
                if (!this.formData.new_department.length && this.formData.for_what == "2") {
                    this.alert_type = "Warning";
                    this.alert_message = 'പുതുതായി ചേർക്കേണ്ട വകുപ്പുകൾ സെലക്ട് ചെയ്യുക';
                    this.$refs.alert_model.openModel();
                    return false;
                }
            }
            this.loading = true;
            const formData_ = new FormData();
            if (this.profile_file) {
                formData_.append('minutes_image', this.profile_file);
            }
            if (this.editable_id) {
                formData_.append('editable_id', this.editable_id);
            }

            let user_level = this.formData.change_level;
            let user_level_id = 0;
            if (user_level == 1) {
                user_level_id = 0;
            } else if (user_level == 2) {
                user_level_id = this.currentUser.district_id;
            } else if (user_level == 3) {
                user_level_id = this.currentUser.zone_id;
            } else if (user_level == 4) {
                user_level_id = this.currentUser.unit_id;
            }

            formData_.append('user_level', user_level);
            formData_.append('user_level_id', user_level_id);
            formData_.append('for_what', this.formData.for_what);
            formData_.append('for_what_other', this.formData.for_what_other);
            formData_.append('meeting_date', this.formData.meeting_date);
            formData_.append('minutes_number', this.formData.minutes_number);
            formData_.append('member', this.member.member_id);
            formData_.append('old_department', JSON.stringify(this.formData.old_department));
            formData_.append('old_duty', JSON.stringify(this.formData.old_duty));
            formData_.append('new_member', this.new_member.member_id);
            formData_.append('new_department', JSON.stringify(this.formData.new_department));
            formData_.append('new_duty', JSON.stringify(this.formData.new_duty));
            formData_.append('remarks', this.formData.remarks);
            formData_.append('comments', this.formData.comments);

            formData_.append('name', this.formData.name);
            formData_.append('country_code', this.formData.code);
            formData_.append('mobile', this.formData.mobile);
            formData_.append('country_code_whatsapp', this.formData.country_code_whatsapp);
            formData_.append('whatsapp', this.formData.whatsapp);
            formData_.append('wing', this.formData.wing);

            UserService.authPostRequest("store-request", formData_).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.formData = {
                            for_what: '',
                            for_what_other: '',
                            change_level: '',
                            minutes_number: '',
                            old_department: [],
                            new_department: [],
                            old_duty: [],
                            new_duty: [],
                            remarks: '',
                            comments: '',
                        };
                        this.member = [];
                        this.new_member = [];
                        // setTimeout(() => {
                        //     location.replace('/org/'+parseInt(this.$route.params.sub_level)+'/'+parseInt(this.$route.params.id)+'/request-list');
                        // }, 1500);
                        this.loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeUserLevel() {

            this.formData.old_department = [];
            this.formData.new_department = [];
            this.formData.old_duty = [];
            this.formData.new_duty = [];
            this.formData.member = [];
            this.formData.new_member = [];

            this.new_department_list = [];
            this.old_department_list = [];
            this.new_duty = [];
            this.old_duty = [];

            this.member_list = [];
            this.new_member_list = [];

        },
        getRequest(id) {
            let data = {
                id: id
            }
            UserService.authPostRequest('get-request', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.requestData = response.data.data.list;
                        this.permissions = response.data.data.permissions;
                        this.formData.for_what = this.requestData.request_sub_type;
                        this.formData.meeting_date = this.requestData.meeting_date;
                        this.formData.minutes_number = this.requestData.minutes_number;
                        if (this.requestData.minutes_image) {
                            this.profile_image = 'https://guide.msoftit.com/' + this.requestData.minutes_image;
                        }
                        if (this.requestData.member_to_change) {
                            this.member = {
                                id: this.requestData.member_to_change.member_id,
                                member_id: this.requestData.member_to_change.member_id,
                                text: this.requestData.member_to_change.member.name,
                                mobile: this.requestData.member_to_change.member.mobile,
                                country_code: this.requestData.member_to_change.member.country_code
                            }
                            this.member_list.push(this.member);
                        }
                        if (this.formData.for_what == 1 || this.formData.for_what == 2) {
                            this.getDepartments(this.requestData.member_to_change.member_id, this.requestData.user_level);
                        }

                        if (this.requestData.new_member_to_change) {
                            this.new_member = {
                                id: this.requestData.new_member_to_change.member_id,
                                member_id: this.requestData.new_member_to_change.member_id,
                                text: this.requestData.new_member_to_change.member.name,
                                mobile: this.requestData.new_member_to_change.member.mobile,
                                country_code: this.requestData.new_member_to_change.member.country_code
                            }
                            this.new_member_list.push(this.new_member);
                        }

                        this.editable_id = this.requestData.id;
                        if (this.requestData.departments_to_change) {
                            this.formData.old_department = JSON.parse(this.requestData.departments_to_change);
                        }
                        if (this.requestData.new_departments_to_change) {
                            this.formData.new_department = JSON.parse(this.requestData.new_departments_to_change);
                        }
                        if (this.requestData.designations_to_change) {
                            this.formData.old_duty = JSON.parse(this.requestData.designations_to_change);
                            console.log(this.formData.old_duty);
                        }
                        if (this.requestData.new_designations_to_change) {
                            this.formData.new_duty = JSON.parse(this.requestData.new_designations_to_change);
                        }

                        // this.formData.family_leader_house_name = this.reliefFamily.family_head_surname;
                        // this.formData.family_leader_mobile = this.reliefFamily.family_contact;
                        // this.formData.place = this.reliefFamily.family_place;
                        // this.formData.panjayath = this.reliefFamily.family_panchayath;
                        // this.formData.zakath = this.reliefFamily.zakath_eligible;
                        // this.formData.total_count = this.reliefFamily.family_members_count;
                        // this.formData.lessthan_16_count = this.reliefFamily.boys_count;
                        // this.formData.yatheem_count = this.reliefFamily.orphan_count;
                        // this.formData.widow_count = this.reliefFamily.widow_count;
                        // this.editable_id = this.reliefFamily.id;
                        // if (this.reliefFamily.family_reason_to_include) {
                        //     this.formData.relief_reason = JSON.parse(this.reliefFamily.family_reason_to_include);
                        // }
                        // if (this.reliefFamily.relief_types) {
                        //     this.formData.relief_types = JSON.parse(this.reliefFamily.relief_types);
                        //     if (response.data.data.child.length > 0) {
                        //         response.data.data.child.forEach(element => {
                        //             this.formData.child.push({
                        //                 name: element.child_name,
                        //                 age: element.child_age,
                        //                 sex: element.child_sex,
                        //                 editable_id: element.id
                        //             });
                        //         });
                        //         this.old_child_count = response.data.data.child.length;
                        //         this.children_enable = true;
                        //     }
                        // }
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}

.custom-file-upload {
    position: relative;
    display: flex;
    width: 50%;
    height: 100px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #060606;
}
</style>
