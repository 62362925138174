<template>
  <div class="section text-center mt-1" v-if="loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="section pt-1" v-if="!loading">
    <div class="wallet-card">
      <div class="balance">
        <div class="left">
          <span class="title">Car Parking</span>
          <h1 class="total text-mal">#{{ selected_parking.srl_num }} {{ selected_parking.name }}<br>{{ selected_parking.location }}</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="section mt-1" v-if="!loading">
    <div class="wallet-card">
      <div class="row">
        <div class="col-5">
          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label" for="code">Type <span class="text-danger">*</span></label>
              <select as="select" class="form-control custom-select" id="code" name="code" v-model="code">
                <option value="" :disabled="true" selected>---</option>
                <option value="CAR" selected>CAR</option>
                <option value="BUS">BUS</option>
                <option value="Other">Other</option>
                <!-- <option v-for="code in responseData.district_code_list" v-bind:key="code.code" v-bind:value="code.code">
                  {{ code.code }}</option> -->
              </select>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="form-group basic">
            <div class="input-wrapper">
              <label class="label" for="number">Number <span class="text-danger">*</span></label>
              <input type="number" class="form-control" id="number" name="number" placeholder="Number" v-model="number"
                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
            <span class="error-feedback">{{ code_validation }}</span>
          </div>
        </div>
      </div>
      <div class="row wallet-footer">
        <div class="col-6 item">
          <a href="javascript:void(0)" @click="updateParkingDetails('minus')" :disabled="l_btn_loading">
            <div class="icon-wrapper bg-success item-button">
              <span v-show="l_btn_loading" class="spinner-border spinner-border-sm"></span>
              Left
              <ion-icon name="remove-outline" role="img" class="md hydrated" aria-label="arrow down outline"></ion-icon>
            </div>
          </a>
        </div>
        <div class="col-6 item">
          <a href="javascript:void(0)" @click="updateParkingDetails('plus')" :disabled="p_btn_loading">
            <div class="icon-wrapper bg-danger item-button">
              <span v-show="p_btn_loading" class="spinner-border spinner-border-sm"></span>
              Parked
              <ion-icon name="add-outline" role="img" class="md hydrated" aria-label="arrow down outline"></ion-icon>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="section" v-if="!loading">
    <div class="wallet-footer">
      <div class="row mt-1">
        <div class="col-4">
          <div class="stat-box">
            <div class="title mal-text">Capacity</div>
            <div class="value text-primary">
              {{
                selected_parking.maximum_capacity
                  ? selected_parking.maximum_capacity
                  : "00"
              }}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="stat-box">
            <div class="title mal-text">Filled</div>
            <div class="value text-danger">
              {{ selected_parking.filled ? selected_parking.filled : "00" }}
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="stat-box">
            <div class="title mal-text">Free</div>
            <div class="value text-success">{{(selected_parking.maximum_capacity ? selected_parking.maximum_capacity :
            0) -
            (selected_parking.filled ? selected_parking.filled : 0)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section mt-2 mb-2" v-if="!loading">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Vehicle</th>
              <th scope="col">Parked</th>
              <th scope="col">Left</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="location in responseData.parking_vehicles" v-bind:key="location">
              <td class="text-primary font-dark">
                {{ location.bus_code }}
              </td>
              <td class="text-danger font-dark">
                {{ location.parking_parked_time }}
              </td>
              <td class="text-success font-dark">
                {{ location.parking_left_time }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>
<script>
import $ from "jquery";
import UserService from "../../../services/user.service";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
export default {
  name: "Admin List",
  props: {
    unit_id: null,
  },
  components: {
    AlertModel,
    DialogModel,
  },
  data() {
    return {
      loading: false,
      p_btn_loading: false,
      l_btn_loading: false,
      loading_list: false,
      code: 'CAR',
      number: '',
      code_validation: '',
      message: "",
      country_code: "91",
      country_code_whatsapp: "91",
      mobile_number: "",
      whatsapp_number: "",
      wing: "",
      stay: "",
      user_level: "",
      sub_levels: [],
      member_lists: [],
      unit: [],
      editable_id: "",
      editable: true,
      add_admin: false,
      abroad_fields: false,
      pin_number: "",
      arrival_confirm: "",
      arrival_time: "",
      saturday_coming: "",
      arrival_mode: "",
      spouse_coming: "",
      food_pref: "",
      child_coming: [],
      remarks: "",
      search_qry: "",
      responseData: {
        parking_locations: [],
        permissions: [],
        district_code_list: [],
        event: [],
        parking_vehicles: []
      },
      alert_type: "",
      alert_message: "",
      dialog_message: "",
      dialog_action: "",
      dialog_action_params: [],

      parking_select: true,
      selected_parking: [],
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      location.replace("/");
    }
    this.$root.updateParent("Parking Cordinator");
    this.getParkingDetails();
    window.onpopstate = function () {
      if ($("body").hasClass("modal-open")) {
        location.reload();
      }
    };
  },

  methods: {
    getParkingDetails() {
      this.loading = true;
      let data = {
        event: this.$route.params.event,
        parking_id: this.$route.params.parking_location,
        parking_platform : 'parking',
      };
      UserService.authPostRequest(`get-event-parking`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.selected_parking = response.data.data.parking_locations;
            this.responseData = response.data.data;
            this.loading = false;
          } else {
            this.responseData = response.data.data;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    updateParkingDetails(action) {
      if (this.code == '' || this.number == '') {
        this.code_validation = 'ഫിൽ ചെയ്യുക.';
      } else {
        if (action == 'plus') {
          this.p_btn_loading = true;
        } else {
          this.l_btn_loading = true;
        }

        this.code_validation = '';
        let data = {
          parking_id: this.selected_parking.id,
          action: action,
          event: this.$route.params.event,
          vehicle_code: this.code + '-' + this.number,
          parking_platform : 'parking'
        };
        UserService.authPostRequest(`update-event-parking`, data).then(
          (response) => {
            if (response.data.status == "success") {
              this.responseData = response.data.data;
              this.selected_parking = this.responseData.selected_parking;
              this.alert_type = "Success";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              // this.$root.updateParent(this.responseData.event.event_name);
              if (action == 'plus') {
                this.p_btn_loading = false;
              } else {
                this.l_btn_loading = false;
              }
            } else if (response.data.status == "warning") {
              this.alert_type = "Warning";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              if (action == 'plus') {
                this.p_btn_loading = false;
              } else {
                this.l_btn_loading = false;
              }
            } else {
              this.alert_type = "Error";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              if (action == 'plus') {
                this.p_btn_loading = false;
              } else {
                this.l_btn_loading = false;
              }
            }
          },
          (error) => {
            if (action == 'plus') {
              this.p_btn_loading = false;
            } else {
              this.l_btn_loading = false;
            }
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    selectParking(parking) {
      localStorage.setItem("parking_id", parking.id);
      this.parking_select = false;
      this.selected_parking = parking;
      this.alert_type = "Success";
      this.alert_message = "Parking Selected.";
      this.$refs.alert_model.openModel();
    },
    changeParking() {
      localStorage.setItem("parking_id", "");
      this.parking_select = true;
      this.selected_parking = [];
      //   this.alert_type = "Success";
      //   this.alert_message = 'Parking Selected.';
      //   this.$refs.alert_model.openModel();
    },
  },
};
</script>
<style scoped>
.section-heading {
  padding: 10px 10px 0px 10px;
}

.error-feedback {
  color: red;
}

.listview {
  margin-bottom: 20px;
}

.edit-btn {
  margin-left: 8px;
}

.progress {
  width: 80px;
}

.listview>li footer {
  color: #171617;
}

.min-width-50 {
  min-width: 60% !important;
}

.action-button-list>li {
  min-height: 1px !important;
}

.sl-no {
  font-weight: 900;
  margin-right: 12px;
}

.searchbox .form-control {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid #bdbdd5;
  height: 36px;
  padding: 0 10px 0 10px;
}

.form-check {
  padding: 0;
  margin: 0;
  padding-left: 10px;
  min-height: auto;
  height: auto;
  padding-bottom: 5px;
}

.input-group .input-group-text {
  background: transparent;
  border: 0;
  border: 1px solid #1a418c;
  /* border-top: 1px solid #1a418c; */
  border-radius: 5px 5px 5px 5px;
  font-weight: 400;
  color: #27173e;
  height: auto;
  width: auto;
  padding: 0px 10px 0px 10px;
}

.stat-box {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
  border-radius: 10px;
  padding: 10px 10px;
}

.item-button {
  width: 100% !important;
}

.item-button-top {
  margin-right: 10px !important;
}

.wallet-card .wallet-footer .item strong {
  display: block;
  color: #27173E;
  font-weight: 600;
  font-size: 14px;
  line-height: 15PX;
}

.wallet-card .balance .total {
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1em;
  font-size: 20px;
}
</style>
