<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text custom-title">{{report_header}}</h3>
        </div>
    </div>

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2"
        v-if="!loading_list && currentUser.user_level <= 4 && currentUser.unit_id != $route.params.id && unit.consol_form_status_level == 0">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="mal-text text-center ">ശാഖ റിപ്പോർട്ട് അയച്ചിട്ടില്ല</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>

    <div class="section mt-2 mb-2"
        v-if="(!loading_list && currentUser.user_level <= 4 && currentUser.unit_id == $route.params.id) || (!loading_list && currentUser.user_level <= 4 && unit.consol_form_status_level > 0)">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Wing</th>
                            <th scope="col">Members</th>
                            <th scope="col">Biodata</th>
                            <th scope="col" class="text-center mal-text">ശാഖ രൂപീകരിക്കാൻ കഴിയുമോ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(sub_level,index) in sub_levels" v-bind:key="sub_level">
                            <td>{{ index}}</td>
                            <td class=" text-primary text-end font-dark">
                                {{sub_level.count}}</td>
                            <td class=" text-primary text-end font-dark">
                                {{sub_level.filled}}</td>
                            <td class="text-center">
                                <span class="badge badge-success" v-if="sub_level.count >= 5">
                                    <ion-icon name="checkmark-outline" class="icon-size20">
                                    </ion-icon>
                                </span>
                                <span class="badge badge-danger" v-if="sub_level.count < 5">
                                    <ion-icon name="close-outline" class="icon-size20"></ion-icon>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-end text-primary font-dark total-size">
                                Total
                            </td>
                            <td class="text-end text-primary font-dark total-size text-end">
                                {{(unit.members_count)}}
                            </td>
                            <td class="text-end text-primary font-dark total-size text-end">
                                {{(unit.members_count_filled)}}
                            </td>
                            <td></td>
                        </tr>
                        <tr v-if="currentUser.user_level <= 4 && currentUser.unit_id == $route.params.id">
                            <td class="text-primary font-dark total-size mal-text text-center input-info" colspan="4">
                                ഓർഗനൈസേഷന്റെയും യൂത്തിന്റെയും അഡ്മിന് മാത്രമേ മണ്ഡലത്തിലേക്ക് അയക്കാൻ ഉള്ള പെർമിഷൻ ഉണ്ടാവുകയുള്ളു
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <strong class="mal-text text-primary"
                                    v-if="unit.consol_form_status_level == 0">മണ്ഡലത്തിലേക്ക് അയച്ചിട്ടില്ല</strong>
                                <strong class="mal-text text-primary"
                                    v-if="unit.consol_form_status_level == 1">മണ്ഡലത്തിലേക്ക് അയച്ചു</strong>
                                <strong class="mal-text text-primary" v-if="unit.consol_form_status_level == 3">മണ്ഡലം
                                    റിജെക്ട് ചെയ്തു</strong>
                                <strong class="mal-text text-primary" v-if="unit.consol_form_status_level == 2">മണ്ഡലം
                                    സ്വീകരിച്ചു</strong>
                            </td>
                        </tr>
                        <tr
                            v-if="unit_permission || (currentUser.user_level <= 4 && currentUser.unit_id == $route.params.id && currentUser.wing == '1') || (currentUser.user_level <= 4 && currentUser.unit_id == $route.params.id && currentUser.wing == '2')">
                            <td colspan="4">
                                <a href="javascript:void(0)" data-bs-toggle="modal" @click="openSubmitModel"
                                    v-if="unit.consol_form_status_level == 0 || unit.consol_form_status_level == 3">
                                    <button class="btn btn-primary report-btn mal-text">മണ്ഡലത്തിലേക്ക് അയക്കാൻ
                                        ഇവിടെ
                                        അമർത്തുക
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                    </button>
                                </a>

                            </td>
                        </tr>
                        <tr v-if="currentUser.user_level <= 3 && unit.consol_form_status_level > 0">
                            <td colspan="4">
                                <div class="row" v-if="currentUser.zone_id == unit.zone_id">
                                    <a href="javascript:void(0)" class="col-6"
                                        @click="acceptConsSheet($route.params.id)"
                                        v-if="unit.consol_form_status_level == 1">
                                        <button
                                            class="btn btn-primary btn-accept-reject report-btn-success report-btn mal-text">സ്വീകരിക്കാൻ
                                            ഇവിടെ അമർത്തുക
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </button>
                                    </a>
                                    <a href="javascript:void(0)" class="col-6"
                                        @click="rejectConsSheet($route.params.id)"
                                        v-if="unit.consol_form_status_level == 1">
                                        <button
                                            class="btn btn-danger btn-accept-reject report-btn-success report-btn mal-text">റിജെക്ട്
                                            ചെയ്യാൻ ഇവിടെ അമർത്തുക
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </button>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <div class="section mt-2 mb-2"
        v-if="(!loading_list && currentUser.user_level <= 4 && currentUser.unit_id == $route.params.id) || (!loading_list && currentUser.user_level <= 4 && unit.consol_form_status_level > 0)">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr v-if="currentUser.user_level <= 4 && currentUser.unit_id == $route.params.id">
                            <td class="text-primary font-dark total-size mal-text text-center" colspan="4">

                                മെമ്പർഷിപ്പുകൾ എക്സിക്യൂട്ടീവ് അംഗീകരിക്കുന്ന മുറക്ക് നാട്ടിൽ സ്ഥിര താമസം ഉള്ള 5 അംഗങ്ങൾ
                                ഉണ്ടെങ്കിൽ അതാത് ഘടകത്തിന് ശാഖ രൂപീകരിക്കാം

                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    <div class="section mt-2 mb-2" v-if="election && !loading_list">
        <div class="card">
            <div class="listed-detail mt-3">
                <h3 class="text-center mt-2 mal-text custom-title">ശാഖ ഇലക്ഷൻ ഷെഡ്യൂൾ</h3>
            </div>

            <ul class="listview flush transparent simple-listview no-space mt-3 election-schedule">
                <li>
                    <strong>തിയതി</strong>
                    <span class="text-success">{{election.election_date}}</span>
                </li>
                <li>
                    <strong>സമയം</strong>
                    <span>{{election.election_time}}</span>
                </li>
                <li>
                    <strong>സ്ഥലം</strong>
                    <span>{{election.election_location}}</span>
                </li>
                <li>
                    <strong class="mal-text custom-title">പ്രിസൈഡിങ് ഓഫീസറുടെ പേര്</strong>
                    <!-- <span>Shopping</span> -->
                </li>
                <li>
                    <strong>വിസ്‌ഡം</strong>
                    <span class="text-end">{{election.wisdom_preside_officer}}
                        <br>{{election.wisdom_preside_mobile}}</span>
                </li>
                <li>
                    <strong>യൂത്ത്</strong>
                    <span class="text-end">{{election.youth_preside_officer}}
                        <br>{{election.youth_preside_mobile}}</span>
                </li>
                <li>
                    <strong>സ്റ്റുഡന്റ്‌സ്</strong>
                    <span class="text-end">{{election.students_preside_officer}}
                        <br>{{election.students_preside_mobile}}</span>
                </li>
                <li>
                    <strong>വിമൺ</strong>
                    <span class="text-end">{{election.women_preside_officer}}
                        <br>{{election.women_preside_mobile}}</span>
                </li>
                <li>
                    <strong>ഗേൾസ്</strong>
                    <span class="text-end">{{election.girls_preside_officer}}
                        <br>{{election.girls_preside_mobile}}</span>
                </li>
            </ul>
        </div>

    </div>
    <div class="section mt-2 mb-2" v-if="election && !loading_list">
        <div class="card">
            <div class="listed-detail mt-3">
                <h3 class="text-center mt-2 mal-text custom-title">ശാഖാ ബാലവേദി ഇലക്ഷൻ ഷെഡ്യൂൾ</h3>
            </div>

            <ul class="listview flush transparent simple-listview no-space mt-3 election-schedule">
                <li>
                    <strong>തിയതി</strong>
                    <span class="text-success">{{election.balavedi_election_date}}</span>
                </li>
                <li>
                    <strong>സമയം</strong>
                    <span>{{election.balavedi_election_time}}</span>
                </li>
                <li>
                    <strong>സ്ഥലം</strong>
                    <span>{{election.balavedi_election_location}}</span>
                </li>
                <li>
                    <strong class="mal-text custom-title">ബാലവേദി പ്രിസൈഡിങ് ഓഫീസറുടെ പേര്</strong>
                    <!-- <span>Shopping</span> -->
                </li>
                <li>
                    <strong>സ്റ്റുഡന്റ്‌സ്</strong>
                    <span class="text-end">{{election.balavedi_preside_officer}}
                        <br>{{election.balavedi_preside_mobile}}</span>
                </li>
            </ul>
        </div>

    </div>
    <!-- Form Action Sheet -->
    <div class="modal fade action-sheet" id="actionSheetForm" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Action</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <form>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <div class="form-check mb-1 col-12">
                                        <input type="checkbox" class="form-check-input" :disabled="true" checked
                                            id="customCheckb1">
                                        <label class="form-check-label mal-text" for="customCheckb1">എന്റെ ശാഖയിലെ
                                            2022
                                            വർഷത്തെ മെമ്പർഷിപ്പ് പ്രവർത്തനം അവസാനിച്ചു.</label>
                                    </div>
                                </div>
                                <div class="input-info">{{message}}</div>
                            </div>
                            <div class="form-group basic">
                                <button type="button" class="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal"
                                    @click="submitToZonal(currentUser.unit_id)">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Form Action Sheet -->
    
</template>
<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
export default {
    name: 'Reports',
    components: {
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            mobile_number: '',
            wing: '',
            user_level: "",
            sub_levels: [],
            admin_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            district: '',
            total: 0.00,
            total_units: 0.00,
            election: [],
            unit_permission: '',

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getReport();
            if (this.user_level == this.currentUser.user_level) {
                this.editable = false;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('Reports');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getReport() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level + 1,
                unit_id: parseInt(this.$route.params.id),
                type: 'unit_level'
            }
            UserService.authPostRequest('get-console-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.total_units = response.data.data.total_unit;
                        this.unit = response.data.data.unit;
                        if (this.unit.wisdom_count >= 5) {
                            this.unit_permission = 1;
                        } else if (this.unit.youth_count >= 5) {
                            this.unit_permission = 2;
                        } else if (this.unit.students_count >= 5) {
                            this.unit_permission = 3;
                        } else if (this.unit.women_count >= 5) {
                            this.unit_permission = 4;
                        } else if (this.unit.girls_count >= 5) {
                            this.unit_permission = 6;
                        }
                        this.election = response.data.data.election;
                        this.report_header = this.unit.name_mal + ' ശാഖ';
                        this.$root.updateParent('Membership Completion report');

                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        openSubmitModel() {
            if ((parseInt(this.unit.members_count) - parseInt(this.unit.balavedi_count)) == (parseInt(this.unit.members_count_filled) - parseInt(this.unit.balavedi_count_filled))) {
                $('#actionSheetForm').modal('show');
            } else {
                this.$swal.fire(
                    '',
                    'താങ്കളുടെ ശാഖയിൽ ' + ((parseInt(this.unit.members_count) - parseInt(this.unit.balavedi_count)) - (parseInt(this.unit.members_count_filled) - parseInt(this.unit.balavedi_count_filled))) + ' ആളുകൾ കൂടി ബയോഡാറ്റ പൂർത്തിയാക്കാൻ ബാക്കിയുണ്ട്. ബയോഡാറ്റ പൂർത്തിയാക്കിയ ശേഷം റിപ്പോർട്ട് അയക്കുക.',
                    'warning',
                ).then((result) => {
                    if (result.isConfirmed) {
                        // $('#actionSheetForm').modal('show');
                    }
                });

            }
        },
        submitToZonal(id) {
            var data = {
                id: id
            }

            UserService.authPostRequest('submit-cons-to-zonal', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.$swal.fire(
                            '',
                            response.data.message,
                            'success',
                        );

                        this.getReport();
                        this.loading = false;
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        acceptConsSheet(id) {
            var data = {
                id: id
            }
            this.$swal.fire({
                title: '',
                text: "ഉറപ്പായും സ്വീകരിക്കണോ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'അതെ'
            }).then((result) => {
                if (result.isConfirmed) {
                    UserService.authPostRequest('accept-cons-sheet', data).then(
                        (response) => {
                            if (response.data.status == 'success') {
                                this.$swal.fire(
                                    '',
                                    response.data.message,
                                    'success'
                                );
                                this.getReport();
                                this.loading = false;
                            } else {
                                this.loading = true;
                            }
                        },
                        (error) => {
                            this.loading = false;
                            this.message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            })
        },
        rejectConsSheet(id) {
            var data = {
                id: id
            }
            this.$swal.fire({
                title: '',
                text: "ഉറപ്പായും റിജെക്ട് ചെയ്യണോ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'അതെ'
            }).then((result) => {
                if (result.isConfirmed) {
                    UserService.authPostRequest('reject-cons-sheet', data).then(
                        (response) => {
                            if (response.data.status == 'success') {
                                this.$swal.fire(
                                    '',
                                    response.data.message,
                                    'success'
                                );
                                this.getReport();
                                this.loading = false;
                            } else {
                                this.loading = true;
                            }
                        },
                        (error) => {
                            this.loading = false;
                            this.message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            })
        },

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.input-info {
    color: red !important;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 5px;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.font-dark {
    font-weight: 500;
}

.total-size {
    font-size: 18px;
}

.report-btn-success {
    width: 100%;
}

.icon-size20 {
    font-size: 20px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #1a418c;
    opacity: 100%;
}

.btn-accept-reject {
    height: 50px;
}

.election-schedule {
    padding: 10px !important;
}
</style>