<template>
    <!-- Alert Success Action Sheet -->
    <div class="modal fade action-sheet" id="actionSheetAlertSuccess" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="iconbox text-success" v-if="type == 'Success'">
                            <ion-icon name="checkmark-circle"></ion-icon>
                        </div>
                        <div class="iconbox text-danger" v-if="type == 'Error'">
                            <ion-icon name="alert-circle"></ion-icon>
                        </div>
                        <div class="iconbox text-warning" v-if="type == 'Warning'">
                            <ion-icon name="alert-circle"></ion-icon>
                        </div>
                        <div class="text-center p-2">
                            <h3>{{type}}</h3>
                            <p class="mal-text text-primary" v-html="message"></p>
                        </div>
                        <a href="#" class="btn btn-primary btn-lg btn-block" @click="closeModel()"
                            data-bs-dismiss="modal">Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Alert Success Action Sheet -->
</template>
<script>
import $ from 'jquery';
export default {
    props: {
        type: String,
        message: String
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        openModel() {
            $('#actionSheetAlertSuccess').modal('show');
        },
        closeModel() {
            $('#actionSheetAlertSuccess').modal('hide');
        },
    },
    mounted() {
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
};
</script>