<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text">മെമ്പർമാരുടെ ലിസ്റ്റ്</h3>
        </div>
    </div>
    <div class="section pt-1 mt-1">
        <div class="wallet-card">
            <div class="wallet-footer">
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(1)">
                            Wisdom({{unit.wisdom_count_filled}}/{{unit.wisdom_count}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(2)">
                            Youth({{unit.youth_count_filled}}/{{unit.youth_count}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(3)">
                            Students({{unit.students_count_filled}}/{{unit.students_count}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(4)">
                            Women({{unit.women_count_filled}}/{{unit.women_count}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(5)">
                            Balavedi({{unit.balavedi_count_filled}}/{{unit.balavedi_count}})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(6)">
                            Girls({{unit.girls_count_filled}}/{{unit.girls_count}})
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- AddMemberModel -->
    <div class="modal fade action-sheet" id="addMemberModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit Member</h5>
                    <h5 class="modal-title" v-if="add_admin">Add Member</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addMember" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="Name">Name <span class="mal-text">(പേര്)</span></label>
                                <div class="form-group boxed">
                                    <Field name="name" type="text" :disabled="!add_admin" class="form-control" id="name"
                                        v-model="name" placeholder="Enter Name" autocomplete="off" />
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper row">
                                    <label class="label" for="country_code">Mobile Number <span class="mal-text">(മൊബൈൽ
                                            നമ്പർ)</span></label>
                                    <div class="form-group boxed" style="width:30% !important;">
                                        <div class="input-wrapper">
                                            <Field as="select" class="form-control custom-select" v-model="country_code"
                                                name="country_code" :disabled="!add_admin" id="select4"
                                                @change="onChangeCountryCode($event)">
                                                <option v-for="item in country_code_list" :value="item.item_value"
                                                    v-bind:key="item.item_value">
                                                    {{ item.name }}</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="form-group boxed mobile-field"
                                        style="width:68% !important; margin-left: 2%;">
                                        <div class="input-wrapper">
                                            <Field name="mobile_number" type="number" id="mobile_number"
                                                class="form-control" :disabled="!add_admin" placeholder=""
                                                autocomplete="off" v-model="mobile_number"
                                                oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 13); } if(this.value.length > 12) { this.value = this.value.slice(0, 12);} " />
                                        </div>
                                    </div>
                                    <ErrorMessage name="mobile_number" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="abroad_fields">
                                <div class="input-wrapper row">
                                    <label class="label" for="country_code">Whatsapp Number <span
                                            class="mal-text">(വാട്സ്ആപ്പ് നമ്പർ)</span></label>
                                    <div class="form-group boxed" style="width:30% !important;">
                                        <div class="input-wrapper">
                                            <Field as="select" :disabled="!add_admin" class="form-control custom-select"
                                                v-model="country_code_whatsapp" name="country_code_whatsapp"
                                                id="select4">
                                                <option v-for="item in country_code_list" :value="item.item_value"
                                                    v-bind:key="item.item_value">
                                                    {{ item.name }}</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="form-group boxed mobile-field"
                                        style="width:68% !important; margin-left: 2%;">
                                        <div class="input-wrapper">
                                            <Field name="whatsapp_number" type="number" id="whatsapp_number"
                                                class="form-control" :disabled="!add_admin" placeholder=""
                                                autocomplete="off" v-model="whatsapp_number"
                                                oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 13); } if(this.value.length > 12) { this.value = this.value.slice(0, 12);} " />
                                        </div>
                                    </div>
                                    <ErrorMessage name="whatsapp_number" class="error-feedback" />
                                </div>
                            </div>
                            <!-- <div class="form-group basic">
                                <label class="label" for="stay">Stay <span class="mal-text">(താമസം)</span></label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper mal-text">
                                        <Field as="select" class="form-control custom-select" v-model="stay" name="stay"
                                            id="select4">
                                            <option value>താമസം സെലക്ട് ചെയ്യുക</option>
                                            <option value="native">നാട്ടിൽ</option>
                                            <option value="outof_state">കേരളത്തിന് പുറത്ത്</option>
                                            <option value="outof_country">ഇന്ത്യക്കു പുറത്ത്</option>

                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="stay" class="error-feedback" />
                            </div>   -->
                            <div class="form-group basic">
                                <label class="label" for="wing">Wing <span class="mal-text">(വിങ്)</span></label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <Field as="select" class="form-control custom-select" v-model="wing" name="wing"
                                            id="select4">
                                            <option selected>Select Wing</option>
                                            <option value="1">Wisdom</option>
                                            <option value="2">Youth</option>
                                            <option value="3">Students</option>
                                            <option value="4">Women</option>
                                            <option value="5">Balavedi</option>
                                            <option value="6">Girls</option>

                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="wing" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="editable || add_admin">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!editable && !add_admin" @click="closeAddMemberModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="section-heading">
        <div class="listview-title mt-0">
            List </div><br>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.length <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>
    <ul class="listview image-listview inset text" v-if="!loading_list">
        <li v-for="(member_list , index) in member_lists" v-bind:key="member_list.id"
            :set="background = member_list.fillStatus <= 0 ? 'rgb(242 187 202)' : member_list.fillStatus >= 90 ? 'rgb(209 232 210)' : '#ffffff' ">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{index+1}}</span>
                <img src="assets/img/sample/avatar/avatar1.png" alt="image" class="image">
                <div class="in" :set="checked_ =member_list.attendance == 'P'?true:false ">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b> {{ member_list.name }} </b>
                        <footer>{{ member_list.country_code }} {{ member_list.mobile }}
                        </footer>
                    </div>
                    <div>
                        <input type="checkbox" :checked="checked_" class="form-check-input" @change="changeAttendace($event,member_list.id)" name="study_checkbox" id="customCheckb2">
                    </div>
                    <!-- <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset'+member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-horizontal"></ion-icon>
                        </button>
                    </div> -->

                    <!-- Default Action Sheet Inset -->
                    <!-- <div class="modal fade action-sheet" :id="'actionSheetInset'+member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li
                                            v-if="member_list.user_id && currentUser.unit_id == member_list.unit_id && member_list.fillStatus >= 100">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="downloadBiodata(member_list)">
                                                <span>
                                                    <ion-icon name="print-outline"></ion-icon> Print Biodata
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.user_id && currentUser.unit_id == member_list.unit_id">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="goToBiodata(member_list)">
                                                <span>
                                                    <ion-icon name="receipt-outline"></ion-icon>View Biodata
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'tel:'+member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>

                                        <li
                                            v-if="currentUser.unit_id == member_list.unit_id && (unit.consol_form_status_level == 0 || unit.consol_form_status_level == 3)">
                                            <a href="javascript:void(0)" @click="memberView(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li
                                            v-if="(currentUser.user_level <= 2 && currentUser.district_id == member_list.district_id) && (unit.consol_form_status_level == 0 || unit.consol_form_status_level == 3)">
                                            <a href="javascript:void(0)" @click="memberDelete(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>

</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";
import $ from 'jquery';
export default {
    name: 'Admin List',
    props: {
        unit_id: null
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            name: yup.string().matches(nameRegex, "പേര് ഇംഗീഷിൽ തന്നെ ടൈപ്പ് ചെയ്യുക!").required("പേര് ടൈപ്പ് ചെയ്യുക!"),
            mobile_number: yup.string().required("മൊബൈൽ നമ്പർ ടൈപ്പ് ചെയ്യുക!"),
            wing: yup.string().required("വിങ് സെലക്ട് ചെയ്യുക!"),
        });
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            schema,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: ''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList(1);
            if ((this.unit_id == this.currentUser.unit_id) || this.currentUser.user_level == 4) {
                this.editable = true;
            } else {
                this.editable = false;
            }
        }
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getMembersList(wing) {
            this.loading_list = true;
            let unit_id = this.$route.params.id;
            let data = {
                unit_id: unit_id ? unit_id : this.currentUser.unit_id,
                wing: wing,
            }
            UserService.authPostRequest('get-members', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_lists = response.data.data.list;
                        this.unit = response.data.data.unit;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeAttendace(event,member) {
            let data = {
                member_id : member,
                unit_id : this.unit_id ? this.unit_id : this.currentUser.unit_id,
                attendance : event.target.checked
            }
            this.loading = true;
            UserService.authPostRequest('mark-attendance', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        closeAddMemberModel() {
            $('#addMemberModel').modal('hide');
        }

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}
</style>