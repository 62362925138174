<template>
    <!-- Wallet Card -->
    <div v-if="currentUser.user_level != 6">
        <div class="section text-center" v-if="loading">
            <div class="spinner-border text-secondary" role="status"></div>
        </div>
        <div class="section mt-2 mb-2" v-if="!sub_levels.length && !loading">
            <div class="card">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="mal-text text-center ">താങ്കളെ എവിടെയും പ്രെസിഡിങ് ഓഫീസർ ആയി
                                    നിയമിച്ചിട്ടില്ല</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
        <ul class="listview image-listview inset text mt-2 pt-2" v-if="!loading && sub_levels.length">
            <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
                <router-link :to="'/org/' + 3 + '/' + sub_level.id">
                    <a href="javascript:void(0)" class="item">
                        <div class="in">
                            <div>
                                <b class="text-main">{{ sub_level.name }}</b>
                            </div>
                        </div>
                    </a>
                </router-link>
            </li>
        </ul>
        <!-- <add-members ref="add_member" v-if="user_level == 4" :current_user="currentUser"></add-members> -->
    </div>
</template>
<script>
import UserService from "../services/user.service";
export default {
    name: 'Organization',
    data() {
        return {
            loading: false,
            message: "",
            user_level: "",
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
            unit: []
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = this.currentUser.user_level;
            if (this.user_level == 1) {
                this.sub_level_list_name = 'District List';
                this.user_level_id = 0;
                this.$root.updateParent("State");
            } else if (this.user_level == 2) {
                this.sub_level_list_name = 'Zone List';
                this.user_level_id = this.currentUser.district_id;
                this.$root.updateParent("District");
            }
            else if (this.user_level == 3) {
                this.sub_level_list_name = 'Unit List';
                this.user_level_id = this.currentUser.zone_id;
                this.$root.updateParent("Unit");
            }
            else if (this.user_level == 4) {
                this.sub_level_list_name = 'Members List';
                this.user_level_id = this.currentUser.unit_id;
                this.$root.updateParent("Unit List");
            }
            this.getSubLevels();
            console.log(this.unit);
        }
    },
    methods: {
        openAddMemberModel() {
            this.$refs.add_member.adminAdd();
        },
        getSubLevels() {
            this.loading = true;
            if (this.user_level == 2) {
                this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.currentUser.unit_id;
            }
            let data = {
                sub_level_type: 5,
                id: this.currentUser.id,
                presiding: true,
                presiding_zones:true,
            }
            UserService.authPostRequest('get-sub-levels', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.sub_levels = response.data.data.list;
                        console.log(this.sub_levels);
                        this.unit = response.data.data.unit;
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (this.message == 'Unauthenticated.') {
                        location.replace('/');
                    }
                }
            );
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/level-2-org/' + (this.user_level + 1) + '/' + sub_level_id);
        }
    },
};
</script>
<style scoped>
.list-title {
    font-size: 16px;
}

.item {
    margin-bottom: 10px;
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    height: 46px !important;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}
.listview>li a:after {
    content: "\f3d1" !important;
    font-family: "Ionicons" !important;
    font-size: 18px !important;
    position: absolute !important;
    right: 16px !important;
    height: 18px !important;
    top: 50% !important;
    margin-top: -9px !important;
    line-height: 1em !important;
    color: #A9ABAD !important;
    opacity: 0.6 !important;
}
.text-main{
    font-size:18px;
}
</style>