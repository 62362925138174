<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text">ശാഖ അഡ്മിൻ നിയമന റിപ്പോർട്ട്</h3>
        </div>
    </div>

    <div class="section-heading">
        <div class="listview-title mt-0">
            Report List </div>
        <div class="listview-title mt-0 mal-text">
            <span>{{district}}</span> | <span v-if="currentUser.wing == 1">വിസ്‌ഡം</span>
            <span v-if="currentUser.wing == 2">യൂത്ത്</span>
            <span v-if="currentUser.wing == 3">സ്റ്റുഡന്റസ്</span>
            <span v-if="currentUser.wing == 4">വിമൻസ്</span>
            <span v-if="currentUser.wing == 6">Girls</span>(വിങ്)
        </div>
        <!-- <div class="row">
            <div class="col-6">
                test
            </div>
            <div class="col-6">
                test
            </div>
        </div> -->
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <ul class="listview image-listview inset text" v-if="!loading_list && user_level != 4">
        <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
            <router-link :to="'/org/' + (user_level) + '/' + sub_level.id + '/report-dashboard'">
                <a class="item">
                    <div class="in">
                        <div>
                            <b class="list-title">{{ sub_level.name }}</b>
                            <footer>
                                <div v-for="(admin, index) in sub_level.admins_count" v-bind:key="admin.name"
                                    class="footer_div"
                                    :set="div_class = (sub_level.total_unit - admin.count > 0) ? 'badge-danger' : 'badge-info'">
                                    <span class="text-only mal-text" v-if="index == '1'">ശാഖകൾ</span> <span v-if="index == '1'"
                                        class="badge badge-primary first_span"> {{ sub_level.total_unit }}</span>
                                    <span class="text-only mal-text" v-if="index == currentUser.wing">അഡ്മിന്മാരെ നിയമിച്ചത്</span><span
                                        v-if="index == currentUser.wing" class="badge badge-info first_span ">{{
                                                admin.count
                                        }}</span>
                                    <span class="text-only mal-text" v-if="index == currentUser.wing">ബാക്കി</span><span
                                        v-if="index == currentUser.wing" class="badge first_span"
                                        v-bind:class="div_class">{{ sub_level.total_unit - admin.count }}</span>


                                </div>
                            </footer>
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
    </ul>
</template>
<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
export default {
    name: 'Reports',
    components: {
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            mobile_number: '',
            wing: '',
            user_level: "",
            sub_levels: [],
            admin_lists: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            district : '',
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getReport();
            if (this.user_level == this.currentUser.user_level) {
                this.editable = false;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('Reports');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getReport() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level + 1,
                id: parseInt(this.$route.params.id),
                type: 'unit_level'
            }
            UserService.authPostRequest('get-admin-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.$root.updateParent(response.data.data.page_title);
                        this.district = response.data.data.page_title;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 5px;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}
</style>