<template>
    <!-- Wallet Card -->
    <div class="section pt-1">
        <div class="wallet-card">
            <!-- Balance -->
            <div class="balance">
                <div class="left">
                    <span class="title mal-text">മണ്ഡലം നൽകിയിട്ടുള്ള ടാർഗറ്റ് </span>
                    <h1 class="total text-primary">{{ unit.nerpatham_target }}</h1>
                </div>
                <div class="right row">
                    <a :href="'/#/org/'+$route.params.sub_level+'/'+$route.params.id+'/nerpatham-bundle-list'" class="button col-6 mr-1">
                        <ion-icon name="albums-outline"></ion-icon>
                    </a>
                    <a href="javascript:void(0);" class="button col-6" @click="adminAdd">
                        <ion-icon name="add-outline"></ion-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ചേർത്തത്</div>
                    <div class="value text-success">{{ unit.total_entry }}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ബാക്കിയുള്ളത്</div>
                    <div class="value text-danger">{{ minusToZero ? minusToZero : '0' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">നേർപഥം വരിക്കാരുടെ ലിസ്റ്റ്</h3>
        </div>
    </div>

    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit</h5>
                    <h5 class="modal-title" v-if="add_admin">Add New</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema" ref="myForm">
                            <!-- <div class="form-group basic">
                                <label class="label" for="stay"><span>നിലവിൽ വരിക്കാരൻ ആണോ</span></label>
                                <select as="select" class="form-control custom-select" v-model="isSubscriber"
                                    name="isSubscriber" id="select4">
                                    <option value="Y">അതെ</option>
                                    <option value="N">അല്ല</option>
                                </select>
                            </div> -->
                            <div class="form-group basic">
                                <label class="label" for="stay"><span>നിലവിൽ മെമ്പർ ആണോ</span></label>
                                <select as="select" class="form-control custom-select" v-model="isMember" name="isMember"
                                    id="select4">
                                    <option value="Y">അതെ</option>
                                    <option value="N">അല്ല</option>
                                </select>
                                <input type="hidden" v-model="member_id" />
                            </div>
                            <div class="form-group basic typehead-div" v-if="isMember == 'Y'">
                                <!-- <div class="input-wrapper col-12"> -->
                                <label class="label" for="text4b">Member</label>
                                <v-select label="text" :filterable="true" class="form-control" v-model="member"
                                    :value="member" :options="member_list" @input="getTextList($event.target.value, 0)"
                                    @change="changeMember(member)">
                                    <template>
                                        type to search...
                                    </template>
                                    <!-- <template>
                                            <div class="d-center">
                                                {{ member.name }}
                                            </div>
                                        </template> -->
                                </v-select>
                                <ErrorMessage name="member" class="error-feedback" />
                                <!-- </div> -->
                                <!-- <div class="input-info" v-if="member">Mobile :{{ member.country_code }} {{
                                    member.mobile
                                }}
                                </div> -->
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="name">Name<span class="text-danger">*</span></label>
                                <Field name="name" type="text" class="form-control" v-model="name"
                                    :disabled="disable_member_form" placeholder="Name" autocomplete="off" />
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="mobile">Mobile Number<span class="text-danger">*</span></label>
                                <Field name="mobile" type="text" class="form-control" :disabled="disable_member_form"
                                    v-model="mobile" placeholder="Mobile" autocomplete="off" />
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="house_name"><span>House Name</span><span
                                        class="text-danger">*</span></label>
                                <Field name="house_name" type="text" class="form-control" v-model="house_name"
                                    placeholder="House Name" autocomplete="off" />
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="place"><span>Place</span></label>
                                <Field name="place" type="text" class="form-control" v-model="place" placeholder="Place"
                                    autocomplete="off" />
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="post"><span>Post</span><span class="text-danger">*</span></label>
                                <Field name="post" type="text" class="form-control" v-model="post" placeholder="Post"
                                    autocomplete="off" />
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="pin">Pin<span class="text-danger">*</span></label>
                                <Field name="pin" type="text" class="form-control" v-model="pin" placeholder="pin"
                                    autocomplete="off" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="district"> District <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="district" name="district"
                                        v-model="district" @change="getCollegeList">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="district in kerala_district_list" v-bind:key="district.name"
                                            v-bind:value="district.name">
                                            {{ district.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="district" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="postal_type"> Postal Type <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="postal_type"
                                        name="postal_type" v-model="postal_type">
                                        <option value="Postal" selected>Postal</option>
                                        <option value="Bundle" selected>Bundle</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="postal_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="postal_type == 'Bundle'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="bundle"> Bundle <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="bundle" name="bundle"
                                        v-model="bundle">
                                        <option value="" selected>---Not Selected---</option>
                                        <option v-for="bundle in bundle_list" v-bind:key="bundle.id"
                                            v-bind:value="bundle.id">
                                            {{ bundle.bundle_name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="district" class="error-feedback" />
                            </div>
                            <!-- <div class="form-group basic">
                                <label class="label" for="stay"><span>Payment Status</span><span class="text-danger">*</span></label>
                                <Field as="select" class="form-control custom-select" v-model="payment_status"
                                    name="payment_status" id="select4" :disabled="payment_mode == 'Online'">
                                    <option value>---Not Selected---</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Not Paid">Not Paid</option>
                                </Field>
                            </div> -->
                            <div class="form-group basic">
                                <label class="label" for="payment_ref"><span>Payment Ref</span></label>
                                <Field name="payment_ref" type="text" class="form-control" v-model="payment_ref"
                                    placeholder="Payment Ref" autocomplete="off" :disabled="payment_mode == 'Online'" />
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="receipt_number">Receipt Number<span
                                        class="text-danger">*</span></label>
                                <Field name="receipt_number" type="text" class="form-control" v-model="receipt_no"
                                    placeholder="Receipt Number" autocomplete="off" />
                            </div>
                            <!-- <div class="form-group basic" v-if="subscription_no">
                                <label class="label" for="subscription_number"><span>Subscription
                                        Number</span></label>
                                <Field name="subscription_number" :disabled="true" type="text" class="form-control"
                                    v-model="subscription_no" placeholder="Subscription Number" autocomplete="off" />
                            </div> -->
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic row">
                                <button type="button" @click="handleButtonClick('save')"
                                    class="btn btn-primary btn-block btn-lg col mt-1" :disabled="loading"
                                    v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                &nbsp;
                                <button type="button" @click="handleButtonClick('renew')"
                                    class="btn btn-info btn-block btn-lg col" :disabled="loading || !renewal_btn"
                                    v-if="permissions.update && editable_id && renewal_btn">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Renew & Save</span></button>
                                <button type="button" class="btn btn-warning btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- AddPaymentModel -->
    <div class="modal fade action-sheet" id="addPaymentModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Payment</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addPayment" :validation-schema="payment_schema">
                            <div class="form-group basic">
                                <label class="label" for="name">Name<span class="text-danger">*</span></label>
                                <Field name="name" type="text" class="form-control" v-model="name"
                                    :disabled="true" placeholder="Name" autocomplete="off" />
                            </div>
                            <!-- <div class="form-group basic">
                                <label class="label" for="stay"><span>Payment Status</span><span class="text-danger">*</span></label>
                                <Field as="select" class="form-control custom-select" v-model="payment_status"
                                    name="payment_status" id="select4">
                                    <option value>---Not Selected---</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Not Paid">Not Paid</option>
                                </Field>
                            </div> -->
                            <div class="form-group basic">
                                <label class="label" for="payment_ref"><span>Payment Ref</span></label>
                                <Field name="payment_ref" type="text" class="form-control" v-model="payment_ref"
                                    placeholder="Payment Ref" autocomplete="off" />
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="receipt_number">Receipt Number<span
                                        class="text-danger">*</span></label>
                                <Field name="receipt_number" type="text" class="form-control" v-model="receipt_no"
                                    placeholder="Receipt Number" autocomplete="off" />
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>
                            <div class="form-group basic row">
                                <button type="submit"
                                    class="btn btn-primary btn-block btn-lg col mt-1" :disabled="loading"
                                    v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-warning btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div id="search" v-if="!loading_list">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
                    @input="filteredMembers()">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div> -->
    <!-- <div class="section" v-if="!loading_list">
        <span><a :href="'https://batch.msoftit.com/reports/pdf/reportZonalCouncil.php?zone_id=' + unit.id + '&wing=6&mobile='+currentUser.phone"
                :download="'Girls Zonal Council'">
                <ion-icon name="download-outline"></ion-icon> Download PDF
            </a></span>
    </div> -->
    <div class="section pt-1 mt-0">
        <div class="wallet-card" style="border-radius: 10px 10px 0px 0px;">
            <div class="wallet-footer">
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" v-bind:class="postal_type == 'Postal' ? 'active' : ''" data-bs-toggle="tab"
                            href="javascript:void(0)" role="tab" @click="getMembersList(1, 'Postal')">
                            Postal
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-bind:class="postal_type == 'Bundle' ? 'active' : ''" data-bs-toggle="tab"
                            href="javascript:void(0)" role="tab" @click="getMembersList(1, 'Bundle')">
                            Bundle
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="section text-center mt-1" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-1">
        <h4 class="text-center mt-0 mal-text text-danger" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>
    <ul class="listview image-listview inset text mt-1" style="border-radius: 0px 0px 10px 10px;"
        v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <!-- <span class="button verified" v-if="member_list.member.verified == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified" v-if="member_list.member.verified == 'N' && member_list.member.verification_status">
                            <ion-icon name="close-outline"></ion-icon>
                        </span> -->
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <header><b class="text-info">
                                {{ member_list.subscription_no ? member_list.subscription_no : 'New Subscription' }}</b>
                        </header>
                        <b> {{ member_list.name }} </b>
                        <footer class="text-primary">
                            <table>
                                <tr>
                                    <th>Mobile: <span class="text-dark">{{ member_list.mobile }} </span></th>
                                </tr>
                                <tr v-if="member_list.bundle_name">
                                    <th>Bundle: <span class="text-dark">{{ member_list.bundle_name }} </span></th>
                                </tr>
                                <tr>
                                    <th>Expiry Date: <span class="text-dark">{{ member_list.expiry_date ?
                                        member_list.expiry_date : '--/--/----' }}</span></th>
                                </tr>
                                <tr>
                                    <th>Current Status: <span
                                            :class="member_list.status == 'Active' ? 'text-success' : member_list.status == 'Requested' ? 'text-warning' : 'text-danger'">{{
                                                member_list.status }}</span>
                                    </th>
                                </tr>
                                <tr v-if="member_list.expiry_date">
                                    <th>Renewal Status: <span
                                            :class="member_list.renewal_status == 'Renewed' ? 'text-success' : member_list.renewal_status == 'Requested' ? 'text-warning' : 'text-danger'">{{
                                                member_list.renewal_status }}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Payment Status: <span
                                            :class="member_list.payment_status == 'Paid' ? 'text-success' : member_list.payment_status == 'Not Paid' ? 'text-danger' : 'text-warning'">{{
                                                member_list.payment_status }}</span>
                                    </th>
                                </tr>
                                <tr v-if="member_list.payment_status == 'Paid'">
                                    <th>Payment Mode: <span class="text-dark">{{member_list.payment_mode}}</span>
                                    </th>
                                </tr>
                            </table>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">

                                        <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.payment_mode != 'Online'">
                                            <a href="javascript:void(0)" @click="adminPayment(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="wallet-outline"></ion-icon> Payment
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" @click="adminView(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.postal_type != 'Bundle'">
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total"
            :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page"
            :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import vSelect from 'vue-select'
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            kerala_district_list: [],
            submit_type: '',
            payment_status: '',
            payment_amount:'600.00',
            payment_ref: '',
            postal_type: 'Postal',
            renewal_btn: false,
            payemnt_mode : '',
            bundle_list:[],
            bundle:'',
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.unit.total_entry;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList(1);
            this.$root.updateParent('നേർപഥം ലിസ്റ്റ്');
        }
        this.getComboboxValues('COUNTRY_CODE');
        this.getComboboxValues('DISTRICT_KERALA');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        handleButtonClick(buttonType) {
            this.submit_type = buttonType;
            this.submitForm();
        },
        submitForm() {
            // Use the $refs to access the form and submit it
            this.$refs.myForm.$el.dispatchEvent(new Event('submit'));
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case "DISTRICT_KERALA":
                            this.kerala_district_list = response.data.data.list.options;
                            break;
                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeMember(member) {
            console.log(member);
            this.name = member.name;
            this.mobile = member.mobile;
            this.house_name = member.houseName;
            this.pin = parseInt(member.pinCode);
            this.post = member.post;
            this.place = member.place;
            if (member.kerala_district) {
                this.district = member.kerala_district.name;
            }
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            if (admin.member_id) {
                this.member_id = admin.member_id;
                this.member = admin.member;
                this.isMember = 'Y';
                this.disable_member_form = true;
            } else {
                this.isMember = 'N';
                this.disable_member_form = false;
            }
            if (admin.remarks == 'renew') {
                this.isSubscriber = true;
            }
            console.log(admin.status);
            if (admin.renewal_status == 'Not Requested') {
                this.renewal_btn = true;
            } else {
                this.renewal_btn = false;
            }

            if (!admin.expiry_date) {
                this.renewal_btn = false;
            }
            this.subscription_no = admin.subscription_no;
            this.receipt_no = admin.reciept_no;
            this.member_edit = false;
            if (admin.member) {
                let member_new = admin.member;
                member_new.text = admin.member.name + '/' + admin.member.mobile;
                member_new.value = admin.member.id;
                this.member_list.push(member_new);
            }
            this.name = admin.name;
            this.mobile = admin.mobile;
            this.house_name = admin.houseName;
            this.pin = parseInt(admin.pinCode);
            this.post = admin.post;
            this.place = admin.place;
            this.district = admin.district;
            this.payment_status = admin.payment_status;
            this.payment_ref = admin.payment_ref;
            this.payment_mode = admin.payment_mode;
            this.bundle = admin.bundle_id;
            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
        },
        adminPayment(admin) {
            $('#addPaymentModel').modal('show');
            this.message = '';
            this.isEdit = true;
            this.name = admin.name;
            this.payment_status = admin.payment_status;
            this.payment_ref = admin.payment_ref;
            this.receipt_no = admin.reciept_no;
            this.editable_id = admin.id;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.disable_member_form = false;
            this.message = '';
            this.member_id = '';
            this.member_list = [];
            this.member = '';
            this.isMember = '';
            this.name = '';
            this.mobile = '';
            this.house_name = '';
            this.pin = '';
            this.post = '';
            this.place = '';
            this.district = '';
            this.subscription_no = '';
            this.receipt_no = '';
            this.member_edit = true;
            
            this.editable_id = '';
            this.add_admin = true;
            this.edit_admin = false;
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            this.loading = true;
            console.log(user);
            user.editable_id = this.editable_id;
            user.is_member = this.isMember;
            user.isSubscriber = this.isSubscriber;
            user.submit_type = this.submit_type;
            if (!this.member && this.isMember == 'Y') {
                this.message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക';
            }
            if (this.member) {
                user.member_id = this.member.id;
            }
            user.unit_id = parseInt(this.$route.params.id);
            UserService.addAdmin('store-nerpatham-subscriptions', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        addPayment(user) {
            this.loading = true;
            user.editable_id = this.editable_id;
            user.unit_id = parseInt(this.$route.params.id);
            UserService.addAdmin('store-nerpatham-payment', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addPaymentModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delate-nerpatham-subscriptions', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        getMembersList(page = 1, postal) {
            this.loading_list = true;
            if (postal) {
                this.postal_type = postal;
            }
            let data = {
                user_level: this.user_level,
                unit_id: parseInt(this.$route.params.id),
                postal_type: this.postal_type
            }
            UserService.authPostRequest(`get-nerpatham-subscriptions?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.bundle_list = response.data.data.bundles;
                        this.permissions = response.data.data.permissions;
                        this.member_lists = response.data.data.list;
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        this.$root.updateParent('നേർപഥം ലിസ്റ്റ് ' + response.data.data.unit.name);
                        this.loading_list = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading_list = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        downloadBiodata(member_list) {
            let data = {
                id: member_list.id
            }
            UserService.authPostRequest('download-member-pdf', data).then(
                (response) => {
                    if (response) {
                        // let blob = new Blob([response], {
                        //     type: 'application/pdf'
                        // })
                        // let link = document.createElement('a')
                        // link.href = response.data.data.link
                        // link.setAttribute('download', 'file.pdf');
                        // link.click()

                        // window.open(response.data.data.link)

                        const link = document.createElement('a');
                        link.href = response.data.data.link
                        link.setAttribute('download', member_list.name + ' biodata-form.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        this.loading = false;
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToBiodata(member_list) {
            this.$router.push('/bio-data/' + member_list.user_id);
        },
        showActionModel() {
            this.pin_number = '';
        },
        attendanceOpen(member) {
            this.member_id = member.id;
            this.attendance = member.attendance.attendance != 'N' ? member.attendance.attendance : 'A';
            this.attendance_remarks = member.attendance.leave_reason;
            $('#attendanceModel').modal('show');
        },
        markAttandance() {
            let data = {
                member_id: this.member_id,
                user_level: this.user_level,
                user_level_id: parseInt(this.$route.params.sub_level),
                attendance: this.attendance,
                remarks: this.attendance_remarks
            }
            this.loading = true;
            UserService.authPostRequest('mark-attendance', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#attendanceModel').modal('hide');
                        if (this.attendance == "P") {
                            this.attendance_present.push(this.member_id);
                            const index = this.attendance_absent.indexOf(this.member_id);
                            if (index > -1) { // only splice array when item is found
                                this.attendance_absent.splice(index, 1); // 2nd parameter means remove one item only
                            }
                        } else {
                            this.attendance_absent.push(this.member_id);
                            const index = this.attendance_present.indexOf(this.member_id);
                            if (index > -1) { // only splice array when item is found
                                this.attendance_present.splice(index, 1); // 2nd parameter means remove one item only
                            }
                        }

                        console.log(this.attendance_absent);

                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );

        },
        filteredMembers() {
            // this.loading_list = true;
            // let filter_list = this.orgnal_member_lists;
            // filter_list = filter_list.filter((samsung) => {
            //     return this.search_qty.toLowerCase().split(' ').every(v => samsung.member.name.toLowerCase().includes(v)) || this.search_qty.toLowerCase().split(' ').every(v => samsung.member.mobile.toLowerCase().includes(v));
            // });
            // this.member_lists = filter_list;
            this.loading_list = false;
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
}

.paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
}

.paginate-buttons:hover {
    background-color: #d8d8d8;
}

.active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
}

.active-page:hover {
    background-color: #2988c8;
}</style>