<template>
  <div class="section pt-1">
    <div class="wallet-card">
      <!-- Balance -->
      <div class="balance">
        <div class="left">
          <span class="title" v-html="event_details.name"></span>
          <h1 class="total text-mal">{{ event_details.vehicle_permissions.vehicle_title }}</h1>
        </div>
        <div class="right">
          <a href="javascript:void(0);" class="button" @click="addNew">
            <ion-icon name="add-outline"></ion-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="section text-center mt-1" v-if="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="section mt-1" v-if="!loading">
    <div id="search" style="padding: 0px !important;">
      <div class="form-group" v-if="currentUser.user_level == 1">
        <div class="input-wrapper">
          <label class="label" for="filter_district">District</label>
          <select as="select" class="form-control custom-select" v-model="filter_district" name="filter_district"
            id="select4" @change="getEventDetails()">
            <option value>---All---</option>
            <option v-for="district in district_list" v-bind:value="district.id" v-bind:key="district.id">{{
              district.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="section mt-1" v-if="!loading && custom_message">
    <div class="card text-white mb-2" style="background-color: #cceee6;">
      <div class="card-header mal-text text-primary text-center">ഞായറാഴ്ച രാത്രി ഭക്ഷണം ആവശ്യമുള്ളവരുടെ ശ്രദ്ധയ്ക്ക്
      </div>
      <div class="card-body">
        <p class="card-text text-primary" v-html="event_details.vehicle_permissions.vehicle_message_content">

        </p>
      </div>
    </div>
  </div>
  <div class="section mt-1" v-if="!loading && event_details.vehicle_permissions.vehicle_message_title">
    <div class="card text-white mb-2" style="background-color: #cceee6;">
      <div class="card-header mal-text text-primary text-center"
        v-html="event_details.vehicle_permissions.vehicle_message_title"></div>
      <div class="card-body">
        <p class="card-text text-primary" v-html="event_details.vehicle_permissions.vehicle_message_content">

        </p>
      </div>
    </div>
  </div>
  <!-- vehicle list -->
  <div class="section mt-1 mb-1" v-if="!loading">
    <div class="card mt-1" v-for="(vehicle, index) in vehciles" v-bind:key="vehicle.id">
      <div class="card-body text-primary">
        <div class="wallet-card">
          <!-- Balance -->
          <div class="balance">
            <div class="left">
              <span class="title mal-text">വാഹനം {{ index + 1 }}</span>
              <h1 class="total text-primary">{{ vehicle.vehicle_type_name }}: {{ vehicle.bus_code }}
                <div class="spinner-grow text-danger" role="status"
                  v-if="(vehicle.status == 'Created' || vehicle.status == 'Updated') && vehicle.food == 'Y'">
                </div>
              </h1>
            </div>
            <div class="right">
              <a href="#" class="button" data-bs-toggle="modal" :data-bs-target="'#actionSheetInset' + vehicle.id"
                @click="showActionModel">
                <ion-icon name="ellipsis-vertical-outline" role="img" class="md hydrated"
                  aria-label="add outline"></ion-icon>
              </a>
            </div>
          </div>
          <!-- * Balance -->
        </div>
        <ul class="listview flush transparent simple-listview no-space mt-1">
          <li>
            <strong>Capacity:</strong>
            <h3 class="m-0 text-primary">{{ vehicle.total_person }}</h3>
          </li>
          <li>
            <strong>Coordinator:</strong>
            <h3 class="m-0 text-primary">{{ vehicle.leader_name }}</h3>
          </li>
          <li>
            <strong>On Boarded:(<span class="text-danger">{{ vehicle.no_of_boys + vehicle.no_of_girls
                }}</span>)</strong>
            <h3 class="m-0 text-primary">Boys: <span class="text-danger">{{ vehicle.no_of_boys }}</span> Girls: <span
                class="text-danger">{{ vehicle.no_of_girls }}</span></h3>
          </li>
          <li v-if="vehicle.org_level >= 2">
            <strong>District:</strong>
            <h3 class="m-0 text-primary">{{ vehicle.district ? vehicle.district.name : '' }}</h3>
          </li>
          <li v-if="vehicle.org_level >= 3">
            <strong>Zone:</strong>
            <h3 class="m-0 text-primary">{{ vehicle.zone ? vehicle.zone.name : '' }}</h3>
          </li>
          <li v-if="vehicle.org_level >= 4">
            <strong>Unit:</strong>
            <h3 class="m-0 text-primary">{{ vehicle.unit ? vehicle.unit.name : '' }}</h3>
          </li>
          <!-- <li>
            <strong>No Girls:</strong>
            <h3 class="m-0 text-primary">{{ vehicle.no_of_girls }}</h3>
          </li> -->
          <li v-if="vehicle.food == 'Y'">
            <strong class="mal-text">ഭക്ഷണം:</strong>
            <h3 class="m-0 text-primary">{{ vehicle.food == 'N' ? 'വേണ്ട' : vehicle.total_packet + ' പാക്കറ്റ്' }}</h3>
          </li>
          <li v-if="vehicle.food == 'Y'">
            <strong class="mal-text">ക്യാഷ്</strong>
            <h3 class="m-0" v-bind:class="vehicle.amount_class ? vehicle.amount_class : 'text-primary'">₹{{
              (vehicle.price *
                vehicle.total_packet).toFixed(2) }}</h3>
          </li>
          <li v-if="vehicle.food == 'Y'">
            <strong class="mal-text">സ്റ്റാറ്റസ്:</strong>
            <span class="text-right text-align: justify;"
              v-bind:class="vehicle.status_class ? vehicle.status_class : 'text-primary mal-text'"
              v-html="vehicle.display_status">
            </span>
          </li>
        </ul>

      </div>
      <div class="modal fade action-sheet" :id="'actionSheetInset' + vehicle.id" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Action</h5>
            </div>
            <div class="modal-body">
              <ul class="action-button-list">
                <li v-if="vehicle.download_url">
                  <a :href="vehicle.download_url" target="_blank" download="bus_number.pdf" class="btn btn-list">
                    <span>
                      <ion-icon name="print-outline"></ion-icon> Print Bus Number
                    </span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="attendeesListView(vehicle)" class="btn btn-list"
                    data-bs-dismiss="modal">
                    <span>
                      <ion-icon name="list-outline"></ion-icon> Attendees List
                    </span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="editData(vehicle)" class="btn btn-list" data-bs-dismiss="modal">
                    <span>
                      <ion-icon name="pencil-outline"></ion-icon> Edit
                    </span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="deleteDataConfirmation(vehicle)" class="btn btn-list"
                    data-bs-dismiss="modal">
                    <span>
                      <ion-icon name="trash-outline"></ion-icon> Remove
                    </span>
                  </a>
                </li>
                <li class="action-divider"></li>
                <li>
                  <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                    <span>
                      <ion-icon name="close-outline"></ion-icon> Cancel
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section mt-2" v-if="!loading">
    <vue-awesome-paginate v-if="vehciles.total > 0" :total-items="vehciles.total" :items-per-page="vehciles.per_page"
      :max-pages-shown="3" v-model="vehciles.current_page" :on-click="getEventDetails" :show-ending-buttons="true"
      :show-breakpoint-buttons="false" />
  </div>

  <div class="modal fade modalbox" id="attendeeListModel" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Attendees List</h5>
        </div>
        <div class="modal-body">
          <a :href="attendees_download_url" target="_blank" download="attendees.pdf" class="btn btn-list">
            <span>
              <ion-icon name="print-outline"></ion-icon> Download PDF
            </span>
          </a>
          <a :href="attendees_excel_download_url" target="_blank" download="attendees" class="btn btn-list">
            <span>
              <ion-icon name="download-outline"></ion-icon> Download Excel
            </span>
          </a>
          <ul class="listview image-listview inset text mt-1 mb-2">
            <li v-for="( checkin_list, index ) in checkin_lists.data " v-bind:key="checkin_list.id"
              :style="{ 'background-color': checkin_list.confirmation_status == 'NP' ? '#ffb3b3' : (checkin_list.registered_from == 'campus' ? '#ffffb3' : checkin_list.registered_from == 'public' ? '#b3ffec' : '#f2f2f2') }">
              <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (checkin_lists.from + index) }}</span>
                <div class="avatar-section">
                  <a href="#">
                    <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                    <span class="button-payment payment-verified" v-if="checkin_list.payment_status == 'Paid'">
                      <ion-icon name="card-outline"></ion-icon>
                    </span>
                    <span class="button verified" v-if="checkin_list.confirmation_status == 'Y'">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </span>

                    <span class="button not-verified" v-if="checkin_list.confirmation_status == 'N'">
                      <ion-icon name="close-outline"></ion-icon>
                    </span>
                  </a>
                </div>
                <div class="in">
                  <div class="min-width-50">
                    <!-- <header>User</header> -->
                    <b> {{ checkin_list.name }} </b>
                    <footer class="text-primary">
                      <table>
                        <tr>
                          <th>Mobile: <span class="text-dark">{{ checkin_list.mobile }} </span></th>
                        </tr>
                        <!-- <tr>
                                    <th>District: <span class="text-dark">{{ checkin_list.district ?
                                        checkin_list.district.name : '' }} </span>
                                    </th>
                                </tr> -->
                        <tr>
                          <th>Zone: <span class="text-dark">{{ checkin_list.zone ? checkin_list.zone.name : '' }}
                            </span></th>
                        </tr>
                        <tr>
                          <th>Unit: <span class="text-dark">{{ checkin_list.unit ? checkin_list.unit.name : '' }}
                            </span></th>
                        </tr>
                        <tr v-if="checkin_list.checkin_by_name" style="border-left: solid 1px;
                                border-right: solid 1px; border-top: solid 1px"
                          :v-bind:style="!checkin_list.bus ? 'border-bottom: solid 1px' : ''">
                          <th>Checkin By: <span class="text-dark">{{ checkin_list.checkin_by_name ?
                            checkin_list.checkin_by_name
                            : '' }} </span></th>
                        </tr>
                        <tr v-if="checkin_list.bus" style="border-bottom: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                          <th>Bus: <span class="text-danger">{{ checkin_list.bus ?
                            checkin_list.bus.bus_code
                            : '' }} </span></th>
                        </tr>
                      </table>
                    </footer>
                  </div>
                  <!-- <div class="card-button dropdown">
                    <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                      :data-bs-target="'#actionSheetInset_' + checkin_list.id">
                      <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                    </button>
                  </div> -->

                  <!-- Default Action Sheet Inset -->
                  <div class="modal fade action-sheet" :id="'actionSheetInset_' + checkin_list.id" tabindex="-1"
                    role="dialog">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">Action</h5>
                        </div>
                        <div class="modal-body">

                          <ul class="action-button-list">
                            <li v-if="responseData.permissions.checkin && checkin_list.confirmation_status != 'NP'">
                              <a href="javascript:void(0)" @click="checkinView(checkin_list)"
                                class="btn btn-list text-danger" data-bs-dismiss="modal">
                                <span>
                                  <ion-icon name="business-outline"></ion-icon> Change CheckIn Status
                                </span>
                              </a>
                            </li>
                            <li>
                              <a :href="'tel:' + checkin_list.mobile" class="btn btn-list">
                                <span>
                                  <ion-icon name="call-outline"></ion-icon> Call
                                </span>
                              </a>
                            </li>
                            <!-- <li>
                                            <a href="javascript:void(0)" @click="adminView(checkin_list)"
                                                class="btn btn-list text-primary" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li> -->
                            <!-- <li>
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(checkin_list)"
                                                class="btn btn-list text-warning" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li> -->
                            <li class="action-divider"></li>
                            <li>
                              <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                <span>
                                  <ion-icon name="close-outline"></ion-icon> Cancel
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- * Default Action Sheet Inset -->
                </div>
              </a>
            </li>
            <li v-if="checkin_lists.data ? checkin_lists.data.length == 0 : false">
              No Attendees.
            </li>

            <div class="form-group basic mt-2">
              <button type="button" class="btn btn-outline-danger btn-block btn-lg"
                @click="closeAttendeesListModel">Close</button>
            </div>
          </ul>

        </div>
      </div>
    </div>
  </div>


  <div class="modal fade action-sheet" id="addNewModel" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New</h5>
        </div>
        <div class="modal-body">
          <div class="action-sheet-content">
            <Form @submit="addNewData" :validation-schema="schema">
              <!-- New Organization Level Selection -->
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label text-primary" for="org_level">
                    <span>Organization Level <span class="text-danger">*</span></span>
                  </label>
                  <Field as="select" class="form-control custom-select" v-model="org_level" name="org_level"
                    @change="handleOrgLevelChange">
                    <option value="">--Select--</option>
                    <option value="2" v-if="this.currentUser.user_level <= 2">District</option>
                    <option value="3" v-if="this.currentUser.user_level <= 3">Zone</option>
                    <option value="4" v-if="this.currentUser.user_level <= 4">Unit</option>
                  </Field>
                  <ErrorMessage name="org_level" class="error-feedback" />
                </div>
              </div>

              <!-- Conditional District Selection -->
              <div class="form-group basic" v-if="org_level >= 2">
                <div class="input-wrapper">
                  <label class="label text-primary" for="district">
                    <span>District <span class="text-danger">*</span></span>
                  </label>
                  <Field as="select" class="form-control custom-select" v-model="district" name="district"
                    @change="handleDistrictChange">
                    <option value="">--Select District--</option>
                    <option v-for="dist in district_list" :key="dist.id" :value="dist.id">
                      {{ dist.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="district" class="error-feedback" />
                </div>
              </div>

              <!-- Conditional Zone Selection -->
              <div class="form-group basic" v-if="org_level >= 3">
                <div class="input-wrapper">
                  <label class="label text-primary" for="zone">
                    <span>Zone <span class="text-danger">*</span></span>
                  </label>
                  <Field as="select" class="form-control custom-select" v-model="zone" name="zone"
                    @change="handleZoneChange">
                    <option value="">--Select Zone--</option>
                    <option v-for="zn in zone_list" :key="zn.id" :value="zn.id">
                      {{ zn.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="zone" class="error-feedback" />
                </div>
              </div>

              <!-- Conditional Unit Selection -->
              <div class="form-group basic" v-if="org_level >= 4">
                <div class="input-wrapper">
                  <label class="label text-primary" for="unit">
                    <span>Unit <span class="text-danger">*</span></span>
                  </label>
                  <Field as="select" class="form-control custom-select" v-model="unit" name="unit">
                    <option value="">--Select Unit--</option>
                    <option v-for="unt in unit_list" :key="unt.id" :value="unt.id">
                      {{ unt.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="unit" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <input type="hidden" v-model="editable_id" />
                  <label class="label text-primary" for="stay"><span>Vehicle Type <span
                        class="text-danger">*</span></span></label>
                  <Field as="select" class="form-control custom-select" v-model="vehicle_type" name="vehicle_type"
                    id="select4" @change="changeDep">
                    <option value>--Select--</option>
                    <option v-for=" vehicle_type in vehicle_type_list " v-bind:key="vehicle_type"
                      v-bind:value="vehicle_type.id">
                      {{ vehicle_type.name }}
                    </option>
                    <option value="1360" v-if="currentUser.user_level == 1">Reg Desk</option>
                  </Field>
                  <ErrorMessage name="vehicle_type" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label text-primary" for="total_person"><span>Seat Capacity</span> <span
                      class="text-danger">*</span></label>
                  <Field name="total_person" type="number" class="form-control" v-model="total_person" placeholder=""
                    autocomplete="off" />
                  <ErrorMessage name="total_person" class="error-feedback" />
                </div>
              </div>
              <!-- <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="bus_accomodation"><span>Bus accommodation സൗകര്യത്തിന്
                      ലഭ്യമാണോ</span><span class="text-danger">*</span></label>
                  <div class="form-check form-check-inline">
                    <Field type="radio" class="form-check-input" value="Y" name="bus_accomodation" id="bus_accomodation_1"
                      v-model=" bus_accomodation " />
                    <label class="form-check-label" for="bus_accomodation_1">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <Field type="radio" class="form-check-input" value="N" name="bus_accomodation" id="bus_accomodation_2"
                      v-model=" bus_accomodation " />
                    <label class="form-check-label" for="bus_accomodation_2">No</label>
                  </div>
                </div>
                <ErrorMessage name="bus_accomodation" class="error-feedback" />
              </div> -->
              <!-- <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="gents"><span>പുരുഷന്മാർ</span></label>
                  <Field name="gents" type="number" class="form-control" v-model="gents" placeholder=""
                    autocomplete="off" />
                  <ErrorMessage name="gents" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="ladies"><span>സ്ത്രീകൾ</span></label>
                  <Field name="ladies" type="number" class="form-control" v-model="ladies" placeholder=""
                    autocomplete="off" />
                  <ErrorMessage name="ladies" class="error-feedback" />
                </div>
              </div> -->
              <div class="form-group basic"
                v-if="vehicle_type && vehicle_type != '1024' && event_details.vehicle_permissions.food">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="stay"><span>ഭക്ഷണം വേണോ?</span></label>
                  <div class="form-check form-check-inline">
                    <Field type="radio" class="form-check-input" value="Y" name="food" id="food_1" v-model="food" />
                    <label class="form-check-label" for="food_1">വേണം</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <Field type="radio" class="form-check-input" value="N" name="food" id="food_2" v-model="food" />
                    <label class="form-check-label" for="food_2">വേണ്ട</label>
                  </div>
                </div>
                <ErrorMessage name="food" class="error-feedback" />
              </div>
              <div class="form-group basic" v-if="food == 'Y' && vehicle_type && vehicle_type != '1024'">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="total_packet"><span>എത്ര പാക്കറ്റ്</span></label>
                  <Field name="total_packet" type="number" class="form-control" v-model="total_packet" placeholder=""
                    autocomplete="off" />
                  <ErrorMessage name="total_packet" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic"
                v-if="food == 'Y' && vehicle_type && vehicle_type != '1024' && event_details.vehicle_permissions.food_collection_point">
                <div class="input-wrapper">
                  <input type="hidden" v-model="editable_id" />
                  <label class="label mal-text text-primary" for="stay"><span>ഭക്ഷണം കൈപ്പറ്റാൻ ഉദ്ദേശിക്കുന്ന സ്ഥലം
                      സെലക്ട് ചെയ്യുക.(ഡ്രൈവറുമായി ചർച്ച ചെയ്തു തീരുമാനിക്കുക)</span></label>
                  <Field as="select" class="form-control custom-select" v-model="food_pickup_point"
                    name="food_pickup_point" id="select4" @change="changeDep">
                    <option value>--Select--</option>
                    <option v-for=" food_pickup_point in food_pickup_point_list " v-bind:key="food_pickup_point"
                      v-bind:value="food_pickup_point.id">
                      {{ food_pickup_point.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="food_pickup_point" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label text-primary" for="leader"><span>Bus coordinator</span> <span
                      class="text-danger">*</span></label>
                  <Field name="leader_name" type="text" class="form-control" v-model="leader_name" placeholder=""
                    autocomplete="off" />
                  <ErrorMessage name="leader_name" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label text-primary" for="leader_mobile"><span>Mobile</span> <span
                      class="text-danger">*</span></label>
                  <Field name="leader_mobile" type="number" class="form-control" v-model="leader_mobile" placeholder=""
                    autocomplete="off" />
                  <ErrorMessage name="leader_mobile" class="error-feedback" />
                </div>
              </div>

              <div class="form-group basic" v-if="permissions.sub_coordinator">
                <div class="input-wrapper">
                  <label class="label text-primary" for="ass_leader_1">Sub Coordinator 1</label>
                  <v-select label="text" class="mt-1" :filterable="true" v-model="ass_leader_1" :value="ass_leader_1"
                    :options="ass_leader_1_list" @input="getTextList($event.target.value, 1)">
                    <template>
                      Type to search...
                    </template>
                    <template>
                      <div class="d-center">
                        {{ ass_leader_1.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="form-group basic" v-if="permissions.sub_coordinator">
                <div class="input-wrapper">
                  <label class="label text-primary" for="ass_leader_2">Sub Coordinator 2</label>
                  <v-select label="text" class="mt-1" :filterable="true" v-model="ass_leader_2" :value="ass_leader_2"
                    :options="ass_leader_2_list" @input="getTextList($event.target.value, 2)">
                    <template>
                      Type to search...
                    </template>
                    <template>
                      <div class="d-center">
                        {{ ass_leader_2.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>

              <div class="form-group basic" v-if="permissions.sub_coordinator">
                <div class="input-wrapper">
                  <label class="label text-primary" for="ass_leader_3">Sub Coordinator 3</label>
                  <v-select label="text" class="mt-1" :filterable="true" v-model="ass_leader_3" :value="ass_leader_3"
                    :options="ass_leader_3_list" @input="getTextList($event.target.value, 3)">
                    <template>
                      Type to search...
                    </template>
                    <template>
                      <div class="d-center">
                        {{ ass_leader_3.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>

              <span class="title">താഴെ ഉള്ള വിവരങ്ങൾ ഇല്ലെങ്കിൽ പിന്നീട് നൽകിയാലും മതി.</span>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="expected_arrival_time"><span>ഏകദേശം സമ്മേള നഗരിയിൽ
                      എത്തിച്ചേരുന്ന സമയം</span></label>
                  <Field name="expected_arrival_time" type="time" class="form-control" v-model="expected_arrival_time"
                    placeholder="" autocomplete="off" />
                  <ErrorMessage name="expected_arrival_time" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="leader_2_name"><span>ഡ്രൈവറുടെ പേര്</span></label>
                  <Field name="leader_2_name" type="text" class="form-control" v-model="leader_2_name" placeholder=""
                    autocomplete="off" />
                  <ErrorMessage name="leader_2_name" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="leader_2_mobile"><span>മൊബൈൽ</span></label>
                  <Field name="leader_2_mobile" type="number" class="form-control" v-model="leader_2_mobile"
                    placeholder="" autocomplete="off" />
                  <ErrorMessage name="leader_2_mobile" class="error-feedback" />
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label mal-text text-primary" for="vehicle_no"><span>Vehicle No</span></label>
                  <Field name="vehicle_no" type="text" class="form-control" v-model="vehicle_no" placeholder="KL10A001"
                    autocomplete="off" />
                  <ErrorMessage name="vehicle_no" class="error-feedback" />
                </div>
              </div>

              <div class="form-group basic">
                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btn_loading"
                  v-if="permissions.update_vehicle_details">
                  <span v-show="btn_loading" class="spinner-border spinner-border-sm"></span>
                  <span>Save</span></button>
                <button type="button" class="btn btn-primary btn-block btn-lg"
                  v-if="!permissions.update_vehicle_details" @click="closeNewAddModel">Close</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import * as yup from "yup";
import UserService from "../../../services/user.service";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
import vSelect from 'vue-select';
export default {
  name: "Admin List",
  props: {
    unit_id: null,
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertModel,
    DialogModel,
    vSelect
  },
  data() {
    const schema = yup.object().shape({
      org_level: yup.string().required("ഫിൽ ചെയ്യുക"),
      district: yup.string().when('org_level', {
        is: value => value && parseInt(value) >= 2,
        then: yup.string().required("ഫിൽ ചെയ്യുക")
      }),
      zone: yup.string().when('org_level', {
        is: value => value && parseInt(value) >= 3,
        then: yup.string().required("ഫിൽ ചെയ്യുക")
      }),
      unit: yup.string().when('org_level', {
        is: value => value && parseInt(value) >= 4,
        then: yup.string().required("ഫിൽ ചെയ്യുക")
      }),
      vehicle_type: yup.string().required("ഫിൽ ചെയ്യുക"),

      // food: yup.string().required("ഫിൽ ചെയ്യുക"),
      total_person: yup.string().required("ഫിൽ ചെയ്യുക"),
      // food: yup.string().when('vehicle_type', {
      //   is: value => value && value != "1024",
      //   then: yup
      //     .string()
      //     .required("ഫിൽ ചെയ്യുക")
      // }),
      total_packet: yup.string().when('food', {
        is: value => value && value === "Y",
        then: yup
          .string()
          .required("ഫിൽ ചെയ്യുക")
      }),
      // food_pickup_point: yup.string().when('food', {
      //   is: value => value && value === "Y",
      //   then: yup
      //     .string()
      //     .required("ഫിൽ ചെയ്യുക")
      // }),
      leader_name: yup.string().required("ഫിൽ ചെയ്യുക"),
      leader_mobile: yup.string().required("ഫിൽ ചെയ്യുക"),
    });
    return {
      alert_type: "",
      alert_message: "",
      dialog_message: "",
      dialog_action: "",
      dialog_action_params: [],
      checkin_lists: [],
      org_level: "",
      district: "",
      zone: "",
      unit: "",

      loading: false,
      btn_loading: false,
      schema,
      event_details: {
        event: [],
        vehicle_permissions: []
      },
      permissions: [],
      custom_message: "",
      message_box: [],
      volunteer_department: "",
      volunteer_ready: "",
      vehicle_type_list: [],
      food_pickup_point_list: [],
      food_pickup_point: '',
      food: 'No',
      total_person: '',
      total_packet: '',
      leader_name: '',
      leader_mobile: '',
      leader_2_name: '',
      leader_2_mobile: '',
      vehciles: [],
      gents: '',
      ladies: '',
      expected_arrival_time: '',
      district_list: [],
      zone_list: [],
      unit_list: [],
      filter_district: '',
      ass_leader_1_list: [],
      ass_leader_2_list: [],
      ass_leader_3_list: [],
      ass_leader_1: [],
      ass_leader_2: [],
      ass_leader_3: [],
      bus_id: '',
      attendees_excel_download_url: '',
      attendees_download_url: ''

    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      location.replace("/");
    }
    this.$root.updateParent("Bus Details");
    this.getEventDetails();
    this.getComboboxValues("EVENT_VEHICLE_TYPES");
    this.getComboboxValues("WIC_FOOD_PICKUP_PONIT");
    if(this.currentUser.user_level <= 2){
      this.getDistrictList();
    }else if(this.currentUser.user_level == 3){
      this.getZoneList();
    }else if(this.currentUser.user_level == 4){
      this.getUnitList();
    }
    
    this.getZoneList();
    this.getUnitList();
    this.org_level = this.currentUser.user_level;
    window.onpopstate = function () {
      if ($("body").hasClass("modal-open")) {
        location.reload();
      }
    };
  },

  methods: {
    getTextList(event, number) {
      this.message = '';
      let data = {
        q: event,
        filter: 'inside',
        wing: this.event_details.wing,
        type: 0,
        user_level: this.currentUser.user_level,
        user_level_id: this.currentUser.district_id
      }
      UserService.authPostRequest('get-typehead-values', data).then(
        (response) => {
          if (response.data.status == 'success') {
            if (number == 1) {
              this.ass_leader_1_list = response.data.data.list;
            }
            if (number == 2) {
              this.ass_leader_2_list = response.data.data.list;
            }
            if (number == 3) {
              this.ass_leader_3_list = response.data.data.list;
            }
            this.loading_list = false;
          } else {
            this.loading_list = true;
          }
        },
        (error) => {
          this.loading_list = true;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    handleOrgLevelChange() {
      this.selected_district = '';
      this.selected_zone = '';
      this.selected_unit = '';
      this.zone_list = [];
      this.unit_list = [];
      
      if (parseInt(this.org_level) >= 2) {
        this.getDistrictList();
      }
    },
    
    handleDistrictChange() {
      this.zone = "";
      this.unit = "";
      this.zone_list = [];
      this.unit_list = [];
      
      if (parseInt(this.org_level) >= 3 && this.district) {
        this.getZoneList(this.district);
      }
    },
    
    handleZoneChange() {
      this.unit = "";
      
      if (parseInt(this.org_level) >= 4 && this.zone) {
        this.getUnitList(this.zone);
      }
    },
    getDistrictList() {
      // this.loading_list = true;
      let data = {}
      UserService.authPostRequest(`get-district-by-id`, data).then(
        (response) => {
          if (response.data.status == 'success') {
            this.district_list = response.data.data.list;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getZoneList(district_id) {
      // this.loading_list = true;
      let data = {
        district_id: district_id
      }
      UserService.authPostRequest(`get-zone-by-id`, data).then(
        (response) => {
          if (response.data.status == 'success') {
            this.zone_list = response.data.data.list;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getUnitList(zone_id) {
      // this.loading_list = true;
      let data = {
        zone_id: zone_id
      }
      UserService.authPostRequest(`get-unit-by-id`, data).then(
        (response) => {
          if (response.data.status == 'success') {
            this.unit_list = response.data.data.list;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getEventDetails(page_ = 1) {
      this.loading = true;
      let data = {
        slug: this.$route.params.slug,
        event_id: this.$route.params.event_id,
        page: "bus-details",
        district_filter: this.filter_district
      };
      UserService.authPostRequest(`get-event-details?page=${page_}`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.event_details = response.data.data.event_details;
            this.permissions = response.data.data.permissions;
            this.custom_message = response.data.data.message;
            this.message_box = response.data.data.message_box;
            this.vehciles = response.data.data.vehciles;
            if (this.vehciles.length == 0 && !this.filter_district) {
              this.addNew();
            }
            // if (response.data.data.volunteer) {
            //   this.member_id = response.data.data.volunteer.member_id;
            //   this.volunteer_ready = "Y";
            //   this.volunteer_department = response.data.data.volunteer.department_id;
            // }
            // if (this.member_list.length == 1) {
            //   this.member_id = this.member_list[0].id;
            // } else {
            //   this.member_list.forEach((element) => {
            //     if (element.mobileSelf == "Y") {
            //       this.member_id = element.id;
            //     }
            //   });
            // }
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getComboboxValues(code, id = null) {
      var data = {
        code: code,
        type_id: id,
        member_id: this.member_id,
      };
      UserService.getCombobox(data).then(
        (response) => {
          switch (code) {
            case "EVENT_VEHICLE_TYPES":
              this.vehicle_type_list = response.data.data.list.options;
              break;
            case "WIC_FOOD_PICKUP_PONIT":
              this.food_pickup_point_list = response.data.data.list.options;
              break;
            default:
              break;
          }
        },
        (error) => {
          this.loading = false;
          this.messafe =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    changeDep() {
      this.volunteer_department_message = "";
    },
    updateVolunteer(data) {
      this.btn_loading = true;
      if (!data.volunteer_department && this.volunteer_ready == "Y") {
        this.volunteer_department_message = "ഫിൽ ചെയ്യുക";
        this.btn_loading = false;
      } else {
        this.volunteer_department_message = "";
        data.event_id = this.event_details.id;
        data.member_id = this.member_id;
        UserService.authPostRequest(`store-event-volunteer`, data).then(
          (response) => {
            if (response.data.status == "success") {
              this.alert_type = "Success";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.btn_loading = false;
            } else if (response.data.status == "warning") {
              this.alert_type = "Warning";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.btn_loading = false;
            } else {
              this.alert_type = "Error";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.btn_loading = false;
            }
          },
          (error) => {
            this.alert_type = "Error";
            this.alert_message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.$refs.alert_model.openModel();
            this.btn_loading = false;
          }
        );
      }
    },
    addNew() {
      this.editable_id = null;
      this.vehicle_type = '';
      this.total_person = '';
      this.food = '';
      this.total_packet = '';
      this.leader_name = '';
      this.leader_mobile = '';
      this.leader_2_name = '';
      this.leader_2_mobile = '';
      this.expected_arrival_time = '';
      this.gents = '';
      this.ladies = '';
      $("#addNewModel").modal("show");
    },
    closeNewAddModel() {
      $("#addNewModel").modal("hide");
    },
    editData(data) {
      
      this.editable_id = data.id;
      this.org_level = data.org_level;
      this.district = data.district_id;
      this.zone = data.zone_id;
      this.unit = data.unit_id;
      this.vehicle_type = data.vehicle_type;
      this.total_person = data.total_person;
      this.food = data.food;
      this.total_packet = data.total_packet;
      this.leader_name = data.leader_name;
      this.leader_mobile = data.leader_mobile;
      this.leader_2_name = data.leader_2_name;
      this.leader_2_mobile = data.leader_2_mobile;
      this.food_pickup_point = data.food_pickup_point ? data.food_pickup_point : '';
      this.expected_arrival_time = data.expected_arrival_time;
      this.gents = data.gents;
      this.ladies = data.ladies;
      this.vehicle_no = data.vehicle_number;
      this.bus_accomodation = data.vehicle_accommodation;

      if (this.district) {
        this.getZoneList(this.district);
      }
      if (this.zone) {
        this.getUnitList(this.zone);
      }

      if (data.ass_leader_1) {
        this.ass_leader_1 = {
          id: data.ass_leader_1.id,
          text: data.ass_leader_1.name + '-' + data.ass_leader_1.mobile,
          name: data.ass_leader_1.name
        }
        this.ass_leader_1_list.push(this.ass_leader_1);
      }

      if (data.ass_leader_2) {
        this.ass_leader_2 = {
          id: data.ass_leader_2.id,
          text: data.ass_leader_2.name + '-' + data.ass_leader_2.mobile,
          name: data.ass_leader_2.name
        }
        this.ass_leader_2_list.push(this.ass_leader_2);
      }
      if (data.ass_leader_3) {
        this.ass_leader_3 = {
          id: data.ass_leader_3.id,
          text: data.ass_leader_3.name + '-' + data.ass_leader_3.mobile,
          name: data.ass_leader_3.name
        }
        this.ass_leader_3_list.push(this.ass_leader_3);
      }
      $("#addNewModel").modal("show");
    },
    addNewData(data) {
      this.btn_loading = true;
      data.org_level = this.org_level;
      data.district = this.district;
      data.zone = this.zone;
      data.unit = this.unit;
      
      data.event_id = this.event_details.id;
      data.editable_id = this.editable_id;
      
      if (this.ass_leader_1) {
        data.ass_leader_1 = this.ass_leader_1.id;
      }
      if (this.ass_leader_2) {
        data.ass_leader_2 = this.ass_leader_2.id;
      }
      if (this.ass_leader_3) {
        data.ass_leader_3 = this.ass_leader_3.id;
      }
      data.event_id = this.event_details.id;
      data.editable_id = this.editable_id;
      if (this.ass_leader_1) {
        data.ass_leader_1 = this.ass_leader_1.id;
      }
      if (this.ass_leader_3) {
        data.ass_leader_3 = this.ass_leader_3.id;
      }
      if (this.ass_leader_2) {
        data.ass_leader_2 = this.ass_leader_2.id;
      }
      UserService.authPostRequest(`store-event-bus-details`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.alert_type = "Success";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.getEventDetails();
            this.closeNewAddModel();
            this.btn_loading = false;
          } else if (response.data.status == "warning") {
            this.alert_type = "Warning";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.btn_loading = false;
          } else {
            this.alert_type = "Error";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.btn_loading = false;
          }
        },
        (error) => {
          this.alert_type = "Error";
          this.alert_message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.$refs.alert_model.openModel();
          this.btn_loading = false;
        }
      );
    },
    deleteDataConfirmation(vehicle_detail) {
      var data = {
        id: vehicle_detail.id,
      }
      this.dialog_action_params = data;
      this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
      this.dialog_action = 'deleteData';
      this.$refs.dialog_model.openModel();
      this.loading = false;
    },
    deleteData(data) {
      data.event_id = this.event_details.id;
      data.editable_id = this.editable_id;
      UserService.authPostRequest(`delete-event-bus-details`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.alert_type = "Success";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.getEventDetails();
            this.closeNewAddModel();
            this.btn_loading = false;
          } else if (response.data.status == "warning") {
            this.alert_type = "Warning";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.btn_loading = false;
          } else {
            this.alert_type = "Error";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.btn_loading = false;
          }
        },
        (error) => {
          this.alert_type = "Error";
          this.alert_message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.$refs.alert_model.openModel();
          this.btn_loading = false;
        }
      );
    },
    getCheckinList(page = 1, bus_id) {
      this.page_type = 'checkin';
      this.loading_list = true;
      let data = {
        event: this.$route.params.slug,
        type: 'checkin',
        checkin: true,
        bus_id: bus_id,
      }
      UserService.authPostRequest(`get-event-registrations?page=${page}`, data).then(
        (response) => {
          if (response.data.status == 'success') {
            this.responseData = response.data.data;
            this.checkin_lists = response.data.data.list;
            this.loading_list = false;
          } else {
            this.responseData = response.data.data;
            this.loading_list = true;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    attendeesListView(vehicle) {
      $('#attendeeListModel').modal('show');
      this.bus_id = vehicle.id;
      this.attendees_excel_download_url = vehicle.attendees_excel_download_url;
      this.attendees_download_url = vehicle.attendees_download_url;
      this.getCheckinList(1, vehicle.id);
    },
    closeAttendeesListModel() {
      $('#attendeeListModel').modal('hide');
    }
  },
};
</script>
<style scoped>
.section-heading {
  padding: 10px 10px 0px 10px;
}

.error-feedback {
  color: red;
}

.listview {
  margin-bottom: 0px;
}

.edit-btn {
  margin-left: 8px;
}

.progress {
  width: 80px;
}

.listview>li footer {
  color: #171617;
}

.min-width-50 {
  min-width: 60% !important;
}

.action-button-list>li {
  min-height: 1px !important;
}

.sl-no {
  font-weight: 900;
  margin-right: 12px;
}

.searchbox .form-control {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid #bdbdd5;
  height: 36px;
  padding: 0 10px 0 10px;
}

.form-check {
  padding: 0;
  margin: 0;
  padding-left: 10px;
  min-height: auto;
  height: auto;
  padding-bottom: 5px;
}

.input-group .input-group-text {
  background: transparent;
  border: 0;
  border: 1px solid #1a418c;
  /* border-top: 1px solid #1a418c; */
  border-radius: 5px 5px 5px 5px;
  font-weight: 400;
  color: #27173e;
  height: auto;
  width: auto;
  padding: 0px 10px 0px 10px;
}

.stat-box {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
  border-radius: 10px;
  padding: 10px 10px;
}

.wallet-card {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(209 26 26 / 9%);
  border-radius: 10px;
  padding: 2px 10px;
  position: relative;
  z-index: 1;
}

.wallet-card .balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  height: auto;
}

.wallet-card .wallet-footer .item .icon-wrapper {
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 14px;
  padding-left: 10px;
}

.menu-title {
  color: #fff !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  text-align: left !important;
}

.wallet-card .balance .total {
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1em;
  font-size: 18px;
}

.wallet-card .balance .title {
  color: #27173e;
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-bottom: 0px;
}

.text-bold {
  font-weight: 600;
}

.card .card-body {
  padding: 10px;
}

.card .card-header {
  background: transparent;
  color: #27173E;
  font-weight: 500;
  font-size: 17px;
  padding: 5px 5px;
  border-bottom: 1px solid #DCDCE9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-body {
  text-align: justify;
  text-justify: inter-word;
}

.avatar-section .button-payment {
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  bottom: 0;
}

.avatar-section .button {
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 24px;
  bottom: 0;
}

.avatar-section .verified {
  background: #4dc81c;
}

.avatar-section .payment-verified {
  background: #05d0a4;
}
</style>
