<template>
    <div class="section mt-1" v-if="responseData.common_links.length && permissions.common_links">
        <div class="transactions">
            <a v-for="common_links in responseData.common_links" v-bind:key="common_links" :href="common_links.link"
                class="item">
                <div class="detail">
                    <img :src="common_links.image" alt="img" class="image-block imaged w48">
                    <div>
                        <strong class="mal-text">{{ common_links.title }}</strong>
                        <p class="mal-text">{{ common_links.desc }}</p>
                    </div>
                </div>
                <div class="right">
                    <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                </div>
            </a>
        </div>
    </div>
    <!-- Wallet Card -->
    <!-- <div class="section mt-1" v-if="!loading && (permissions.election ? permissions.election.view : false)">
        <div class="wallet-card">
            <div class="wallet-footer row">
                <div class="item col-6" v-if="user_level == 3">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/zone-cons-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="receipt-outline"></ion-icon>
                                <strong class="menu-title mal-text">ശാഖ ഇലക്ഷൻ/മെമ്പർഷിപ് റിപ്പോർട്ട് </strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6"
                    v-if="(user_level <= 2) && (permissions.user ? permissions.user.user_level <= 2 : false)">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/election-cons-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="people-outline"></ion-icon>
                                <strong class="menu-title mal-text">മണ്ഡലം ഇലക്ഷൻ ഷെഡ്യൂൾ/റിപ്പോർട്ട്</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6"
                    v-if="((user_level == 3 && permissions.user ? permissions.user.user_level <= 3 : false) || (user_level == 2 && permissions.user ? permissions.user.user_level <= 3 : false)) && permissions.election_schedule ? permissions.election_schedule.view : false">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/election-schedule-details'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue2">
                                <ion-icon name="calendar-outline"></ion-icon>
                                <strong class="menu-title mal-text">{{ $route.params.sub_level == 2 ? 'ജില്ല' : 'മണ്ഡലം'
                                }}
                                    ഇലക്ഷൻ
                                    ഷെഡ്യൂൾ</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                
                <div class="item col-6" v-if="user_level == 3">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/org-councilers-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue3">
                                <ion-icon name="globe-outline"></ion-icon>
                                <strong class="mal-text menu-title">ജില്ല/സംസ്ഥാന കൗൺസിലർമാർ</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6"
                    v-if="((user_level == 3 && permissions.user ? permissions.user.user_level <= 3 : false) || (user_level == 2 && permissions.user ? permissions.user.user_level <= 3 : false)) && permissions.general_council ? permissions.general_council.view : false">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/general-council'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue2">
                                <ion-icon name="people-outline"></ion-icon>
                                <strong class="menu-title mal-text">
                                    {{ $route.params.sub_level == 2 ? 'ജില്ല ജനറൽ' : 'മണ്ഡല ജനറൽ' }}
                                    കൗൺസിൽ</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6"
                    v-if="((user_level == 3 && permissions.user ? permissions.user.user_level <= 3 : false) || (user_level == 2 && permissions.user ? permissions.user.user_level <= 3 : false)) && permissions.election_executives ? permissions.election_executives.view : false">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/org-excecutive-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue4">
                                <ion-icon name="person-outline"></ion-icon>
                                <strong class="mal-text menu-title">{{ $route.params.sub_level == 2 ? 'ജില്ല' : 'മണ്ഡലം'
                                }}
                                    എക്സിക്യൂട്ടീവ്/ ഭാരവാഹികൾ</strong>
                            </div>
                        </a>
                    </router-link>
                </div>


                <div class="item col-6" v-if="user_level == 4">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/cons-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="receipt-outline"></ion-icon>
                                <strong class="menu-title mal-text">ഇലക്ഷൻ ഷെഡ്യൂൾ/മെമ്പർഷിപ് റിപ്പോർട്ട്</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6"
                    v-if="(user_level == 4 && currentUser.zone_id == unit.zone_id) || (user_level == 4 && currentUser.user_level <= 3 && currentUser.district_id == unit.district_id) || (user_level == 4 && currentUser.user_level == 1)">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/excecutive-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue2">
                                <ion-icon name="person-outline"></ion-icon>
                                <strong class="menu-title mal-text">എക്സിക്യൂട്ടീവ്/ഇലക്ഷൻ റിപ്പോർട്ട് </strong>
                            </div>

                        </a>
                    </router-link>
                </div>


                <div class="item col-6"
                    v-if="(user_level == 4 && currentUser.zone_id == unit.zone_id) || (user_level == 4 && currentUser.user_level <= 3 && currentUser.district_id == unit.district_id) || (user_level == 4 && currentUser.user_level == 1)">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/attendance'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue3">
                                <ion-icon name="checkmark-circle-outline"></ion-icon>
                                <strong class="menu-title mal-text">മീറ്റിംഗ് അറ്റന്റൻസ് </strong>
                            </div>

                        </a>
                    </router-link>
                </div>
            </div>
        </div>

    </div> -->
    <!-- <div class="section pt-2">
        <div class="wallet-card">
            <div class="wallet-footer row">
                <div class="item col-6">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/admin-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-primary">
                                <ion-icon name="list-outline"></ion-icon>
                                <strong class="menu-title mal-text">അഡ്മിൻ ലിസ്റ്റ്</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6" v-if="permissions.executives ? permissions.executives.view : false">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/excecutive-list'"
                        v-if="(user_level == 4 && permissions.user.user_level <= 4)">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue2">
                                <ion-icon name="person-outline"></ion-icon>
                                <strong class="menu-title mal-text">എക്സിക്യൂട്ടീവ്/ഭാരവാഹികൾ</strong>
                            </div>
                        </a>
                    </router-link>
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/org-excecutive-list'"
                        v-if="(user_level == 3 && permissions.user.user_level <= 3)">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue2">
                                <ion-icon name="person-outline"></ion-icon>
                                <strong class="menu-title mal-text">എക്സിക്യൂട്ടീവ്/ഭാരവാഹികൾ</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6" v-if="permissions.councillors ? permissions.councillors.view : false">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/org-councilers-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue3">
                                <ion-icon name="globe-outline"></ion-icon>
                                <strong class="mal-text menu-title">ജില്ല/സംസ്ഥാന കൗൺസിലർമാർ</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6"
                    v-if="((user_level == 3 && permissions.user ? permissions.user.user_level <= 3 : false) || (user_level == 2 && permissions.user ? permissions.user.user_level <= 3 : false)) && permissions.general_council ? permissions.general_council.view : false">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/general-council'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue2">
                                <ion-icon name="people-outline"></ion-icon>
                                <strong class="menu-title mal-text">
                                    {{ $route.params.sub_level == 2 ? 'ജില്ല ജനറൽ' : 'മണ്ഡല ജനറൽ' }}
                                    കൗൺസിൽ</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12" v-if="user_level == 4 && currentUser.user_level <= 4">
                    <router-link :to="'/org/' + user_level + '/' + $route.params.id + '/nerpatham-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="book-outline"></ion-icon>
                                <strong class="menu-title mal-text">നേർപഥം ചേർക്കാനും ചേർത്തവരെ കാണാനും ഇവിടെ
                                    അമർത്തുക</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12" v-if="user_level == 4">
                    <router-link :to="'/org/' + user_level + '/' + $route.params.id + '/qhls-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="clipboard-outline"></ion-icon>
                                <strong class="menu-title mal-text">QHLS രജിസ്റ്റർ ചെയ്യാനും ലിസ്റ്റ് കാണാനും ഇവിടെ
                                    അമർത്തുക</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12" v-if="user_level <= 3">
                    <router-link :to="'/org/' + user_level + '/' + $route.params.id + '/nerpatham-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="book-outline"></ion-icon>
                                <strong class="menu-title mal-text">നേർപഥം റിപ്പോർട്ട്</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12" v-if="user_level <= 3">
                    <router-link :to="'/org/' + user_level + '/' + $route.params.id + '/nerpatham-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="book-outline"></ion-icon>
                                <strong class="menu-title mal-text"> റിപ്പോർട്ട്</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12" v-for="event_report in responseData.event_reports" v-bind:key="event_report.event_code">
                    <router-link :to="'/org/' + user_level + '/' + $route.params.id + '/event-report/'+event_report.event_code">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon :name="event_report.event_icon"></ion-icon>
                                <strong class="menu-title mal-text">{{event_report.event_btn_text}}</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12" v-for="event_registration in responseData.event_registrations" v-bind:key="event_registration.event_code">
                    <router-link :to="'/org/' + user_level + '/' + $route.params.id + '/event-registrations/'+event_registration.event_code">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon :name="event_registration.event_icon"></ion-icon>
                                <strong class="menu-title mal-text">{{event_registration.event_btn_text}}</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12" v-for="exam_event_registration in responseData.exam_event_registrations"
                v-bind:key="exam_event_registration.event_code">
                <router-link
                    :to="'/org/' + user_level + '/' + $route.params.id + '/exam-event-registrations/' + exam_event_registration.event_code">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper back-color-blue1">
                            <ion-icon :name="exam_event_registration.event_icon"></ion-icon>
                            <strong
                                class="menu-title mal-text">{{ exam_event_registration.event_btn_text }}</strong>
                        </div>
                    </a>
                </router-link>
            </div>

            <div class="item col-12" v-for="exam_event_report in responseData.exam_event_reports"
                v-bind:key="exam_event_report.event_code">
                <router-link
                    :to="'/org/' + user_level + '/' + $route.params.id + '/exam-event-report/' + exam_event_report.event_code">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper back-color-blue1">
                            <ion-icon :name="exam_event_report.event_icon"></ion-icon>
                            <strong class="menu-title mal-text">{{ exam_event_report.event_btn_text }}</strong>
                        </div>
                    </a>
                </router-link>
            </div>
            <div class="item col-6">
                    <router-link :to="'/downloads'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="cloud-download-outline"></ion-icon>
                                <strong class="menu-title mal-text">ഡൌൺലോഡ്</strong>
                            </div>
                        </a>
                    </router-link>
                </div> -->
                <!-- <div class="item col-4">
                    <router-link :to="'/campus/2/' + $route.params.id">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="list-outline"></ion-icon>
                            </div>
                            <strong>Campus Management</strong>
                        </a>
                    </router-link>
                </div>
            </div>
        </div>
        
        <div class="wallet-card  mt-1" v-if="permissions.extra_links">
            <div class="wallet-footer row">
                <div class="item col-12" v-for="extra_link in responseData.extra_links"
                    v-bind:key="extra_link">
                    <router-link
                        :to="extra_link.url">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon :name="extra_link.icon"></ion-icon>
                                <strong class="menu-title mal-text">{{ extra_link.btn_text }}</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="wallet-card mt-1" v-if="user_level <= 4">
            <div class="wallet-footer wallet-footer-user">
                <div class="item">
                    <a href="javascript:void(0)" @click="openAddMemberModel">
                        <div class="row">
                            <div class="icon-wrapper col-2">
                                <ion-icon name="person-add-outline"></ion-icon>
                            </div>
                            <strong class="add-member-btn mal-text col-10">ഒരു വ്യക്തിയെ കരട് ലിസ്റ്റിലേക്ക്
                                ചേർക്കാൻ
                                ഇവിടെ
                                അമർത്തുക</strong>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="section mt-1" v-if="responseData.common_links.length && permissions.common_links">
        <div class="transactions">
            <a v-for="common_links in responseData.common_links" v-bind:key="common_links" :href="common_links.link"
                class="item">
                <div class="detail">
                    <img :src="common_links.image" alt="img" class="image-block imaged w48">
                    <div>
                        <strong class="mal-text">{{ common_links.title }}</strong>
                        <p class="mal-text">{{ common_links.desc }}</p>
                    </div>
                </div>
                <div class="right">
                    <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                </div>
            </a>
        </div>
    </div> -->

    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Admin</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="Name">Name</label>
                                <div class="form-group boxed">
                                    <Field name="name" type="text" class="form-control" id="name"
                                        placeholder="Enter Name" />
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                                <div class="form-group" v-if="message">
                                    <div v-if="message" class="error-feedback">
                                        {{ message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper row">
                                    <label class="label" for="country_code">Mobile Number</label>
                                    <div class="form-group boxed" style="width:30% !important;">
                                        <div class="input-wrapper">
                                            <Field as="select" class="form-control custom-select" v-model="country_code"
                                                name="country_code" id="select4">
                                                <option v-for="item in country_code_list" :value="item.item_value"
                                                    v-bind:key="item.item_value">
                                                    {{ item.name }}</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="form-group boxed mobile-field"
                                        style="width:68% !important; margin-left: 2%;">
                                        <div class="input-wrapper">
                                            <Field name="mobile_number" type="number" id="mobile_number"
                                                class="form-control" placeholder=""
                                                oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 11); } if (this.value.length > 10) { this.value = this.value.slice(0, 10); }" />
                                        </div>
                                    </div>
                                    <ErrorMessage name="mobile_number" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="wing">Wing</label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <Field as="select" class="form-control custom-select" v-model="wing" name="wing"
                                            id="select4">
                                            <option selected>Select Wing</option>
                                            <option value="1">Wisdom</option>
                                            <option value="2">Youth</option>
                                            <option value="3">Students</option>

                                            <option value="4">Women</option>
                                            <!-- <option value="5">Balavedi</option> -->
                                            <option value="6">Girls</option>

                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="wing" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * AddAdminModel -->
    <div class="modal fade action-sheet" id="nerpathamTargetModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Nerpatham Target</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addNerpathamTarget">
                            <div class="form-group basic">
                                <label class="label" for="Target">Target</label>
                                <div class="form-group boxed">
                                    <Field name="target" type="number" class="form-control" id="target"
                                        v-model="nerpatham_target" placeholder="Enter Target" />
                                </div>
                                <input type="hidden" v-model="sub_level_id">
                                <div class="form-group" v-if="message">
                                    <div v-if="message" class="error-feedback">
                                        {{ message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="section pt-2" v-if="user_level == 2">
        <router-link :to="'/org/' + (user_level + 1) + '/' + $route.params.id + '/unit-report'">
            <button class="btn btn-primary report-btn mal-text">ശാഖാ അഡ്മിൻ നിയമന റിപ്പോർട്ട് &nbsp;&nbsp; Click Here
                <ion-icon name="arrow-forward-outline"></ion-icon>
            </button>
        </router-link>
    </div> -->

    <div class="listview-title mt-2" v-if="user_level != 4">{{ sub_level_list_name }}</div>
    <div class="section text-center" v-if="loading_list && user_level != 4">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <ul class="listview image-listview inset text" v-if="!loading_list && user_level != 4">
        <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
            <a class="item">
                <div class="in">
                    <router-link :to="'/org/' + (user_level + 1) + '/' + sub_level.id">
                        <div>
                            <b class="list-title">{{ sub_level.name }}</b>
                            <!-- <footer>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-primary">WS</i>
                                            <span class="chip-label">{{ sub_level.wisdom_count_filled }}/{{
                                                    sub_level.wisdom_count
                                            }}</span>
                                        </div>
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-success">YT</i>
                                            <span class="chip-label">{{ sub_level.youth_count_filled }}/{{
                                                    sub_level.youth_count
                                            }}</span>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-1">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-danger">ST</i>
                                            <span class="chip-label">{{ sub_level.students_count_filled }}/{{
                                                    sub_level.students_count
                                            }}</span>
                                        </div>
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-pink">WM</i>
                                            <span class="chip-label">{{ sub_level.women_count_filled }}/{{
                                                    sub_level.women_count
                                            }}</span>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-1">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-pink-dark">GL</i>
                                            <span class="chip-label">{{ sub_level.girls_count_filled }}/{{
                                                    sub_level.girls_count
                                            }}</span>
                                        </div>
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-warning">BL</i>
                                            <span class="chip-label">{{ sub_level.balavedi_count_filled }}/{{
                                                    sub_level.balavedi_count
                                            }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-secondary">Total</i>
                                    <span class="chip-label">{{ sub_level.members_count_filled }}/{{
                                            sub_level.members_count
                                    }}</span>
                                </div>
                            </footer> -->
                            <!-- <footer v-if="user_level == 1"><span class="badge badge-primary">Total zones : {{
                                    sub_level.zones_count
                            }}
                                </span>
                            </footer>
                            <footer v-if="user_level == 2"><span class="badge badge-primary">Total units : {{
                                    sub_level.units_count
                            }}
                                </span>
                            </footer> -->
                        </div>
                    </router-link>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + sub_level.id">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + sub_level.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li v-if="currentUser.user_level <= 3">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="nerpathamTarget(sub_level)">
                                                <span>
                                                    <ion-icon name="book-outline"></ion-icon>നേർപഥം ടാർഗറ്റ്
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

        </li>
    </ul>
    <add-members v-if="user_level == 4" :unit_id="$route.params.id" ref="add_member"
        v-bind:permissions="permissions"></add-members>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";
import $ from 'jquery'
import AddMembers from './MembersList.vue';
import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
export default {
    name: 'Organization',
    components: {
        Form,
        Field,
        ErrorMessage,
        AddMembers,
        AlertModel,
        DialogModel
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            name: yup.string().matches(nameRegex, "Please enter name in English").required("Name is required!"),
            mobile_number: yup.string().min(10, 'Must be 10 digits').required("Mobile Number is required!"),
            wing: yup.string().required("Wing is required!"),
        });

        return {
            loading: false,
            loading_list: false,
            permissions: [],
            message: "",
            schema,
            country_code: "91",
            wing: "",
            user_level: "",
            sub_levels: [],
            admin_list: [],
            unit: [],
            nerpatham_target: '',
            sub_level_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            responseData:{
                common_links : []
            }
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            if (this.user_level == 1) {
                this.sub_level_list_name = 'District List';
            } else if (this.user_level == 2) {
                this.sub_level_list_name = 'Zone List';
            }
            else if (this.user_level == 3) {
                this.sub_level_list_name = 'Unit List';
            }

            // if (this.user_level != 4) {
            this.getSubLevels();
            // }
            this.getComboboxValues('COUNTRY_CODE');
            this.$root.updateParent('Loading...');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }

                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        openAddMemberModel() {
            this.$refs.add_member.adminAdd();
        },
        addAdmin(user) {
            user.username = user.country_code + user.mobile_number;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 4) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = this.currentUser.zone_id;
                user.unit_id = parseInt(this.$route.params.id);
            }

            this.loading = true;
            UserService.addAdmin('register', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide');
                        this.$swal('Admin Saved!', '', 'seccess');
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    }
                    else {

                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    console.log((error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString());
                }
            );

        },

        getSubLevels() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level + 1,
                id: parseInt(this.$route.params.id)
            }
            UserService.getSubLevels('get-sub-levels', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.sub_levels = response.data.data.list;
                        this.unit = response.data.data.unit;
                        this.permissions = response.data.data.permission;
                        this.$root.updateParent(response.data.data.page_title);
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminList() {
            let data = {
                id: parseInt(this.$route.params.id)
            }
            UserService.getAdminList('get-admin-list', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.admin_list = response.data.data.list;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        showAddAdminModel() {
            $('#addAdminModel').modal('show');
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/org/' + (this.user_level + 1) + '/' + sub_level_id);
        },
        nerpathamTarget(sub_level = null) {
            this.nerpatham_target = sub_level.nerpatham_target;
            this.sub_level_id = sub_level.id;
            $('#nerpathamTargetModel').modal('show');
        },
        addNerpathamTarget(data) {
            data.level = parseInt(this.$route.params.sub_level) + 1;
            data.level_id = parseInt(this.sub_level_id);
            UserService.addAdmin('set-nerpatham-target', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.getSubLevels();
                        $('#nerpathamTargetModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

.item {
    margin-bottom: -5px;
}

.list-title {
    font-size: 16px;
    color: black;
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    height: 46px !important;
}

.chip-media {
    margin-left: 5px;
    width: 135px !important;
    padding-left: 25px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}




.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.action-button-list>li {
    min-height: 1px !important;
}
.transactions .item {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 5px 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
