<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text custom-title">{{report_header}}</h3>
        </div>
    </div>
    <div class="section mt-2 mb-2"
        v-if="!loading_list && (sub_level_counts.wisdom_count >= 3 || sub_level_counts.youth_count >= 3 || sub_level_counts.students_count >= 3 || sub_level_counts.girls_count >= 3 || sub_level_counts.women_count >= 3)">
        <Form @submit="storeBasicInfo" :validation-schema="schema">
            <div class="section-title mal-text text-center custom-title">{{$route.params.sub_level == 2?'ജില്ല':'മണ്ഡലം'}} ജനറൽ കൌൺസിൽ യോഗം</div>
            <div class="card">
                <div class="card-body">
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന തിയ്യതി</label>
                            <Field type="date" class="form-control" name="date" id="text4b" v-model="date" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                            <ErrorMessage name="date" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന സമയം</label>
                            <Field as="select" class="form-control custom-select" v-model="time" name="time"
                                id="select4">
                                <option v-for="item in time_list" :value="item.value" v-bind:key="item.value">
                                    {{ item.text }}</option>
                            </Field>
                            <ErrorMessage name="time" class="error-feedback" />
                        </div>
                    </div>

                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന സ്ഥലം</label>
                            <Field type="text" class="form-control" name="place" id="text4b" v-model="place" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                            <ErrorMessage name="place" class="error-feedback" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-2">
                <div class="card-body">
                    <div class="mb-05 custom-title">
                        പ്രിസൈഡിങ് ഓഫീസറുടെ പേര്
                    </div>
                    <div class="form-group boxed row" v-if="sub_level_counts.wisdom_count >= 3">
                        <div class="input-wrapper col-12">
                            <label class="label" for="text4b">ഓർഗനൈസേഷൻ</label>
                            <!-- <Field type="text" class="form-control" name="wisdom" id="text4b"
                                v-if="wisdom && !wisdom_edit" :readonly="true" v-model="wisdom.text" /> -->
                            <v-select label="name" :filterable="false" v-model="wisdom" :value="wisdom"
                                :options="wisdom_list" @input="getTextList($event.target.value,1)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="wisdom">
                                    <div class="d-center">
                                        {{ wisdom.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="wisdom" class="error-feedback" />
                        </div>
                        <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('wisdom')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button> -->
                        <div class="input-info text-primary" v-if="wisdom">Mobile :
                            {{wisdom.country_code}}{{wisdom.mobile}}
                            | Unit : {{wisdom.unit?wisdom.unit.name_mal:''}} <br> Zone : {{wisdom.zone?wisdom.zone.name_mal:''}} | Dt :
                            {{wisdom.district?wisdom.district.name:''}}</div>
                    </div>
                    <div class="form-group boxed row" v-if="sub_level_counts.youth_count >= 3">
                        <div class="input-wrapper col-12">
                            <label class="label" for="text4b">യൂത്ത്</label>
                            <!-- <Field type="text" class="form-control" name="youth" id="text4b" v-if="youth && !youth_edit"
                                :readonly="true" v-model="youth.text" /> -->
                            <v-select label="name" :filterable="false" v-model="youth" :value="youth"
                                :options="youth_list" @input="getTextList($event.target.value,2)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="youth">
                                    <div class="d-center">
                                        {{ youth.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="youth" class="error-feedback" />
                        </div>
                        <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('youth')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button> -->
                        <div class="input-info text-primary" v-if="youth">Mobile : {{youth.country_code}}
                            {{youth.mobile}}
                            | Unit : {{youth.unit?youth.unit.name_mal:''}} <br> Zone : {{youth.zone?youth.zone.name_mal:''}} | Dt :
                            {{youth.district?youth.district.name:''}}</div>
                    </div>
                    <div class="form-group boxed row" v-if="sub_level_counts.students_count >= 3">
                        <div class="input-wrapper col-12">
                            <label class="label" for="text4b">സ്റ്റുഡൻസ്</label>
                            <!-- <Field type="text" class="form-control" name="students" id="text4b"
                                v-if="students && !students_edit" :readonly="true" v-model="students.text" /> -->
                            <v-select label="name" :filterable="false" v-model="students" :value="students"
                                :options="students_list" @input="getTextList($event.target.value,3)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="students">
                                    <div class="d-center">
                                        {{ students.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="students" class="error-feedback" />
                        </div>
                        <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('students')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button> -->
                        <div class="input-info text-primary" v-if="students">Mobile :{{students.country_code}}
                            {{students.mobile}}
                            | Unit : {{students.unit?students.unit.name_mal:''}} <br> Zone : {{students.zone?students.zone.name_mal:''}} | Dt :
                            {{students.district?students.district.name:''}}</div>
                    </div>
                    <div class="form-group boxed row" v-if="sub_level_counts.women_count >= 3">
                        <div class="input-wrapper col-12">
                            <label class="label" for="text4b">വിമൺ</label>
                            <!-- <Field type="text" class="form-control" name="women" id="text4b" v-if="women && !women_edit"
                                :readonly="true" v-model="women.text" /> -->
                            <v-select label="name" :filterable="false" v-model="women" :value="women"
                                :options="women_list" @input="getTextList($event.target.value,4)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="women">
                                    <div class="d-center">
                                        {{ women.text }}
                                    </div>
                                </template>

                            </v-select>
                            <ErrorMessage name="women" class="error-feedback" />
                        </div>
                        <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('women')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button> -->
                        <div class="input-info text-primary" v-if="women">Mobile :{{women.country_code}}
                            {{women.mobile}}
                            | Unit : {{women.unit?women.unit.name_mal:''}} <br> Zone : {{women.zone?women.zone.name_mal:''}} | Dt :
                            {{women.district?women.district.name:''}}</div>
                    </div>

                    <div class="form-group boxed row" v-if="sub_level_counts.girls_count >= 3">
                        <div class="input-wrapper col-12">
                            <label class="label" for="text4b">ഗേൾസ്</label>
                            <!-- <Field type="text" class="form-control" name="girls" id="text4b" v-if="girls && !girls_edit"
                                :readonly="true" v-model="girls.text" /> -->
                            <v-select label="name" :filterable="false" v-model="girls" :value="girls"
                                :options="girls_list" @input="getTextList($event.target.value,6)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="girls">
                                    <div class="d-center">
                                        {{ girls.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="girls" class="error-feedback" />
                        </div>
                        <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('girls')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button> -->
                        <div class="input-info text-primary" v-if="girls">Mobile :{{girls.country_code}}
                            {{girls.mobile}}
                            | Unit : {{girls.unit?girls.unit.name_mal:''}} <br> Zone : {{girls.zone?girls.zone.name_mal:''}} | Dt :
                            {{girls.district?girls.district.name:''}}</div>
                    </div>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="mb-05 custom-title">
                        സംസ്ഥാന പ്രതിനിധികൾ
                    </div>
                    <div class="form-group boxed row">
                        <div class="input-wrapper col-12">
                            <label class="label" for="text4b">സംസ്ഥാന പ്രതിനിധി 1</label>
                            <!-- <Field type="text" class="form-control" name="state_1" id="text4b"
                                v-if="state_1 && !state_1_edit" :readonly="true" v-model="state_1.text" /> -->
                            <v-select label="name" :filterable="false" v-model="state_1" :value="state_1"
                                :options="state_1_list" @input="getTextList($event.target.value,0,1)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="state_1">
                                    <div class="d-center">
                                        {{ state_1.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="state_1" class="error-feedback" />
                        </div>
                        <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('state_1')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button> -->
                        <div class="input-info text-primary" v-if="state_1">Mobile :
                            {{state_1.country_code}}{{state_1.mobile}} | Unit : {{state_1.unit?state_1.unit.name_mal:''}} <br> Zone :
                            {{state_1.zone?state_1.zone.name_mal:''}} | Dt : {{state_1.district?state_1.district.name:''}}</div>
                    </div>
                    <div class="form-group boxed row">
                        <div class="input-wrapper col-12">
                            <label class="label" for="text4b">സംസ്ഥാന പ്രതിനിധി 2</label>
                            <!-- <Field type="text" class="form-control" name="state_2" id="text4b"
                                v-if="state_2 && !state_2_edit" :readonly="true" v-model="state_2.text" /> -->
                            <v-select label="name" :filterable="false" v-model="state_2" :value="state_2"
                                :options="state_2_list" @input="getTextList($event.target.value,0,2)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="state_2">
                                    <div class="d-center">
                                        {{ state_2.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="state_2" class="error-feedback" />
                        </div>
                        <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('state_2')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button> -->
                        <div class="input-info text-primary" v-if="state_2">Mobile :
                            {{state_2.country_code}}{{state_2.mobile}} | Unit : {{state_2.unit?state_2.unit.name_mal:''}} <br> Zone :
                            {{state_2.zone?state_2.zone.name_mal:''}} | Dt : {{state_2.district?state_2.district.name:''}}</div>
                    </div>
                    <div class="form-group boxed row">
                        <div class="input-wrapper col-12">
                            <label class="label" for="text4b">സംസ്ഥാന പ്രതിനിധി 3</label>
                            <!-- <Field type="text" class="form-control" name="state_3" id="text4b"
                                v-if="state_3 && !state_3_edit" :readonly="true" v-model="state_3.text" /> -->
                            <v-select label="name" :filterable="false" v-model="state_3" :value="state_3"
                                :options="state_3_list" @input="getTextList($event.target.value,0,3)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="state_3">
                                    <div class="d-center">
                                        {{ state_3.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="state_3" class="error-feedback" />
                        </div>
                        <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('state_3')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button> -->
                        <div class="input-info text-primary" v-if="state_3">Mobile :
                            {{state_3.country_code}}{{state_3.mobile}} | Unit : {{state_3.unit?state_3.unit.name_mal:''}} <br> Zone :
                            {{state_3.zone?state_3.zone.name_mal:''}} | Dt : {{state_3.district?state_3.district.name:''}}</div>
                    </div>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="transparent pt-3">
                        <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import $ from 'jquery';
import UserService from "../../services/user.service";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
export default {
    name: 'Reports',
    components: {
        Form,
        Field,
        ErrorMessage,
        vSelect,
        AlertModel,
        DialogModel
    },
    data() {
        const schema = yup.object().shape({
            date: yup.string().required("തിയതി സെലക്ട് ചെയ്യുക!"),
            time: yup.string().required("സമയം സെലക്ട് ചെയ്യുക!"),
            place: yup.string().required("സ്ഥലം ടൈപ്പ് ചെയ്യുക!"),
            // wisdom: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
            // students: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
            // youth: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
            // women: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
            // girls: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
        });

        return {
            loading: false,
            message: "",
            schema,
            loading_list: false,
            disable_all: false,
            date: "",
            time: "",
            place: "",
            b_date: "",
            b_time: "",
            b_place: "",

            wisdom_list: [],
            youth_list: [],
            students_list: [],

            state_1_list: [],
            state_2_list: [],
            state_3_list: [],
            state_1: '',
            state_2: '',
            state_3: '',
            state_1_edit: true,
            state_2_edit: true,
            state_3_edit: true,

            women_list: [],
            b_students_list: [],
            girls_list: [],
            wisdom: '',
            youth: '',
            girls: '',
            women: '',
            students: '',
            b_students: '',
            sub_levels: [],
            wisdom_edit: true,
            youth_edit: true,
            students_edit: true,
            b_students_edit: true,
            women_edit: true,
            girls_edit: true,
            time_list: [],
            sub_level_type_value: [],
            sub_level_counts: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: []

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getElectionSchedule();
            if (this.user_level == this.currentUser.user_level) {
                this.editable = false;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('Reports');
        }
        this.populate();
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        populate() {
            let hours, minutes, ampm;
            for (var i = 240; i <= 1320; i += 30) {
                hours = Math.floor(i / 60);
                minutes = i % 60;
                if (minutes < 10) {
                    minutes = '0' + minutes;
                }
                ampm = hours % 24 < 12 ? 'AM' : 'PM';
                hours = hours % 12;
                if (hours === 0) {
                    hours = 12;
                }

                this.time_list.push({
                    value: hours + ':' + minutes + ' ' + ampm,
                    text: hours + ':' + minutes + ' ' + ampm,
                });
            }
            console.log(this.time_list);
        },
        editBtn(type) {
            if (type == 'wisdom') {
                this.wisdom_edit = true;
            } else if (type == 'youth') {
                this.youth_edit = true;
            }
            else if (type == 'women') {
                this.women_edit = true;
            } else if (type == 'students') {
                this.students_edit = true;
            } else if (type == 'b_students') {
                this.b_students_edit = true;
            } else if (type == 'girls') {
                this.girls_edit = true;
            }
        },
        getTextList(event, type, b) {
            let data = {
                user_level: this.user_level,
                user_level_id: this.$route.params.id,
                q: event,
                type: type,
                filter: 'outside'
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        if (type == 1) {
                            this.wisdom_list = response.data.data.list;
                        }
                        if (type == 2) {
                            this.youth_list = response.data.data.list;
                        }
                        if (type == 3) {
                            this.students_list = response.data.data.list;
                        }
                        if (type == 4) {
                            this.women_list = response.data.data.list;
                        }
                        if (type == 6) {
                            this.girls_list = response.data.data.list;
                        }
                        if (type == 0) {
                            if (b == 1) {
                                this.state_1_list = response.data.data.list;
                            } else if (b == 2) {
                                this.state_2_list = response.data.data.list;
                            } else {
                                this.state_3_list = response.data.data.list;
                            }
                        }

                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getElectionSchedule() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level,
                sub_level_id: parseInt(this.$route.params.id),
            }
            UserService.authPostRequest('get-election-schedule', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.sub_level_type_value = response.data.data.sub_level_type_value;
                        this.sub_level_counts = this.sub_level_type_value.sub_level_counts;
                        if (this.sub_levels) {
                            this.wisdom_edit = false;
                            this.youth_edit = false;
                            this.students_edit = false;
                            this.b_students_edit = false;
                            this.women_edit = false;
                            this.girls_edit = false;

                            this.date = this.sub_levels.election_date;
                            this.time = this.sub_levels.election_time;
                            this.place = this.sub_levels.election_location;

                            this.b_date = this.sub_levels.balavedi_election_date;
                            this.b_time = this.sub_levels.balavedi_election_time;
                            this.b_place = this.sub_levels.balavedi_election_location;

                            this.wisdom = this.sub_levels.data.wisdom;
                            // {
                            //     value: this.sub_levels.wisdom_preside_member_id,
                            //     text: this.sub_levels.wisdom_preside_officer,
                            //     mobile: this.sub_levels.wisdom_preside_mobile
                            // }
                            this.wisdom_list.push(this.wisdom);

                            // this.youth = {
                            //     value: this.sub_levels.youth_preside_member_id,
                            //     text: this.sub_levels.youth_preside_officer,
                            //     mobile: this.sub_levels.youth_preside_mobile
                            // }
                            this.youth = this.sub_levels.data.youth;
                            this.youth_list.push(this.youth);

                            // this.women = {
                            //     value: this.sub_levels.women_preside_member_id,
                            //     text: this.sub_levels.women_preside_officer,
                            //     mobile: this.sub_levels.women_preside_mobile
                            // }
                            this.women = this.sub_levels.data.women;
                            this.women_list.push(this.women);

                            // this.girls = {
                            //     value: this.sub_levels.girls_preside_member_id,
                            //     text: this.sub_levels.girls_preside_officer,
                            //     mobile: this.sub_levels.girls_preside_mobile
                            // }
                            this.girls = this.sub_levels.data.girls;
                            this.girls_list.push(this.girls);

                            // this.students = {
                            //     value: this.sub_levels.students_preside_member_id,
                            //     text: this.sub_levels.students_preside_officer,
                            //     mobile: this.sub_levels.students_preside_mobile
                            // }
                            this.students = this.sub_levels.data.students;
                            this.students_list.push(this.students);

                            // this.b_students = {
                            //     value: this.sub_levels.balavedi_preside_member_id,
                            //     text: this.sub_levels.balavedi_preside_officer,
                            //     mobile: this.sub_levels.balavedi_preside_mobile
                            // }
                            this.state_1 = this.sub_levels.data.state_1;
                            this.state_1_list.push(this.state_1);
                            this.state_2 = this.sub_levels.data.state_2;
                            this.state_2_list.push(this.state_2);
                            this.state_3 = this.sub_levels.data.state_3;
                            this.state_3_list.push(this.state_3);
                        }
                        if (this.user_level == 3) {
                            this.report_header = this.sub_level_type_value.name_mal + ' മണ്ഡലം';
                            this.$root.updateParent('മണ്ഡലം  ഇലക്ഷൻ ഷെഡ്യൂൾ');
                        } else if (this.user_level == 2) {
                            this.report_header = this.sub_level_type_value.name + ' ജില്ല';
                            this.$root.updateParent('ജില്ല ഇലക്ഷൻ ഷെഡ്യൂൾ ');
                        }


                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        storeBasicInfo(data) {
            data.id = this.$route.params.id;
            data.user_level = this.$route.params.sub_level;
            data.user_id = this.currentUser.id;

            data.wisdom = this.wisdom;
            data.youth = this.youth;
            data.students = this.students;
            data.b_students = this.b_students;
            data.women = this.women;
            data.girls = this.girls;

            data.state_1 = this.state_1;
            data.state_2 = this.state_2;
            data.state_3 = this.state_3;

            this.loading = true;
            UserService.authPostRequest('store-election-schedule', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.loading = false;
                    }
                    this.alert_message = response.data.message;
                    this.$refs.alert_model.openModel();
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.response.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red !important;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 5px;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.font-dark {
    font-weight: 500;
}

.total-size {
    font-size: 18px;
}

.report-btn-success {
    width: 100%;
}

.icon-size20 {
    font-size: 20px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #1a418c;
    opacity: 100%;
}

.btn-accept-reject {
    height: 50px;
}

.form-control {
    /* height: 28px !important; */
}

.edit-btn {
    margin-top: 24px;
}

.d-center {
    display: flex;
    align-items: center;
}

.selected img {
    width: auto;
    max-height: 23px;
    margin-right: 0.5rem;
}

.v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
    border-bottom: none;
}

.v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
}

.v-select .dropdown-menu .active>a {
    color: #fff;
}
</style>