<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 custom-title">Create Meeting</h3>
        </div>
    </div>
    <div class="section pt-0 mt-1 pb-2">
        <div class="card">
            <div class="card-body">
                <Form @submit="handleRegistration" :validation-schema="schema">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="change_level">
                                Meeting Level
                                <span class="text-danger">*</span>
                            </label>
                            <!-- <div class="form-check form-check-inline" v-if="currentUser.user_level <= 4">
                                <input type="radio" :disabled="editable_id?true:false" :checked="currentUser.user_level == 4?true:false" class="form-check-input" value="4" name="change_level" id="change_level_1" v-model="formData.change_level" @change="changeUserLevel" />
                                <label class="form-check-label" for="change_level_1">ശാഖാ[{{ profile.unit }}]&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline" v-if="currentUser.user_level <= 3">
                                <input type="radio" :disabled="editable_id?true:false" :checked="currentUser.user_level == 3?true:false" class="form-check-input" value="3" name="change_level" id="change_level_2" v-model="formData.change_level" @change="changeUserLevel" />
                                <label class="form-check-label" for="change_level_2">മണ്ഡലം[{{ profile.zone }}]&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline" v-if="currentUser.user_level <= 2">
                                <input type="radio" class="form-check-input" value="2" :checked="currentUser.user_level == 2?true:false" name="change_level" id="change_level_3" v-model="formData.change_level" @change="changeUserLevel" />
                                <label class="form-check-label" for="change_level_3">ജില്ല[{{ profile.district }}]&nbsp;</label>
                            </div> -->
                            <div class="form-check form-check-inline" v-if="currentUser.user_level == 1">
                                <input type="radio" class="form-check-input" value="1"
                                    :checked="currentUser.user_level == 1 ? true : false" name="change_level"
                                    id="change_level_4" v-model="formData.change_level" @change="changeUserLevel" />
                                <label class="form-check-label" for="change_level_4">സ്റ്റേറ്റ് &nbsp;</label>
                            </div>
                            <br />
                            <ErrorMessage name="change_level" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="meeting_type">
                                Meeting Type
                                <span class="text-danger">*</span>
                            </label>
                            <Field as="select" class="form-control custom-select" v-model="formData.type" name="type"
                                id="select4">
                                <option value>---Select---</option>
                                <option value="wing_meeting">Wing Meeting</option>
                            </Field>
                        </div>
                        <ErrorMessage name="type" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="meeting_name">
                                Meeting Name
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="text" :disabled="!permissions.edit" class="form-control" name="meeting_name"
                                v-model="formData.meeting_name" autocomplete="off" />
                            <ErrorMessage name="meeting_name" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="meeting_date">
                                Meeting Date
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="date" :disabled="!permissions.edit" class="form-control" name="meeting_date"
                                v-model="formData.meeting_date" autocomplete="off" />
                            <ErrorMessage name="meeting_date" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="meeting_time">
                                Meeting Time
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="time" :disabled="!permissions.edit" class="form-control" name="meeting_time"
                                v-model="formData.meeting_time" autocomplete="off" />
                            <ErrorMessage name="meeting_time" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="meeting_mode">
                                Meeting Mode
                                <span class="text-danger">*</span>
                            </label>
                            <Field as="select" class="form-control custom-select" v-model="formData.mode" name="mode"
                                id="select4">
                                <option value>---Select---</option>
                                <option value="Physical">Physical</option>
                                <option value="Online">Online</option>
                            </Field>
                        </div>
                        <ErrorMessage name="mode" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="meeting_location">
                                Location
                                <span class="text-danger">*</span>
                            </label>
                            <Field type="text" :disabled="!permissions.edit" class="form-control" name="meeting_location"
                                v-model="formData.meeting_location" autocomplete="off" />
                            <ErrorMessage name="meeting_location" class="error-feedback" />
                        </div>
                    </div>
                    <!-- <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="recurring_meeting">
                                ഈ മീറ്റിംഗ് ആഴ്ചയിലോ മാസത്തിലോ നടക്കാറുള്ളതാണോ?
                                                    <span class="text-danger">*</span>
                                                </label>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false" class="form-check-input" value="Y" name="recurring_meeting" id="recurring_meeting_1" v-model="formData.recurring_meeting" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_1">അതെ&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false" checked class="form-check-input" value="N" name="recurring_meeting" id="recurring_meeting_2" v-model="formData.recurring_meeting" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_2">അല്ല&nbsp;</label>
                            </div>
                            <br />
                            <ErrorMessage name="recurring_meeting" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic" v-if="formData.recurring_meeting == 'Y'">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="recurring_meeting_type">
                                ആണെങ്കിൽ ഏതാണ്
                                                    <span class="text-danger">*</span>
                                                </label>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false" class="form-check-input" value="Weekly" name="recurring_meeting_type" id="recurring_meeting_type_1" v-model="formData.recurring_meeting_type" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_type_1">ആഴ്ചയിൽ&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false" class="form-check-input" value="Monthly" name="recurring_meeting_type" id="recurring_meeting_type_2" v-model="formData.recurring_meeting_type" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_type_2">മാസത്തിൽ&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false" class="form-check-input" value="Monthly_2_Time" name="recurring_meeting_type" id="recurring_meeting_type_2" v-model="formData.recurring_meeting_type" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_type_2">മാസത്തിൽ 2 തവണ&nbsp;</label>
                            </div>
                            <br />
                            <ErrorMessage name="recurring_meeting_type" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic" v-if="formData.recurring_meeting_type == 'Weekly'">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="recurring_meeting_week_day">
                                ഏതു ദിവസം
                                                    <span class="text-danger">*</span>
                                                </label>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false"  class="form-check-input" value="Friday" name="recurring_meeting_week_day" id="recurring_meeting_week_day_1" v-model="formData.recurring_meeting_week_day" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_week_day_1">വെള്ളി&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false"  class="form-check-input" value="Saturday" name="recurring_meeting_week_day" id="recurring_meeting_week_day_2" v-model="formData.recurring_meeting_week_day" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_week_day_2">ശനി&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false"  class="form-check-input" value="Sunday" name="recurring_meeting_week_day" id="recurring_meeting_week_day_3" v-model="formData.recurring_meeting_week_day" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_week_day_3">ഞായർ&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false"  class="form-check-input" value="Monday" name="recurring_meeting_week_day" id="recurring_meeting_week_day_4" v-model="formData.recurring_meeting_week_day" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_week_day_4">തിങ്കൾ&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false"  class="form-check-input" value="Tuesday" name="recurring_meeting_week_day" id="recurring_meeting_week_day_5" v-model="formData.recurring_meeting_week_day" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_week_day_5">ചൊവ്വ&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false"  class="form-check-input" value="Wednesday" name="recurring_meeting_week_day" id="recurring_meeting_week_day_6" v-model="formData.recurring_meeting_week_day" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_week_day_6">ബുധൻ&nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" :disabled="editable_id?true:false"  class="form-check-input" value="Thursday" name="recurring_meeting_week_day" id="recurring_meeting_week_day_7" v-model="formData.recurring_meeting_week_day" @change="changeRecurring" />
                                <label class="form-check-label" for="recurring_meeting_week_day_7">വ്യായം&nbsp;</label>
                            </div>
                            
                            <br />
                            <ErrorMessage name="recurring_meeting_week_day" class="error-feedback" />
                        </div>
                    </div> -->
                    <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                        v-if="permissions.edit">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Save</span>
                    </button>
                </Form>
            </div>
        </div>
    </div>

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import $ from "jquery";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({
            center_name: yup.string().required("ഫിൽ ചെയ്യുക!"),
            center_adress: yup.string().required("ഫിൽ ചെയ്യുക!"),
            center_type: yup.string().required("ഒന്ന് സെലക്ട് ചെയുക!"),
            no_of_rooms: yup.string().required("ഫിൽ ചെയ്യുക!"),
            no_of_benches: yup.string().required("ഫിൽ ചെയ്യുക!"),
            no_of_chairs: yup.string().required("ഫിൽ ചെയ്യുക!"),
            no_of_desks: yup.string().required("ഫിൽ ചെയ്യുക!"),
            no_of_estimated_registrations: yup.string().required("ഫിൽ ചെയ്യുക!"),

        });
        return {
            loading: false,
            schema,
            user_level: "",
            unit: [],
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            relief_reason_list: [],
            relief_type_list: [],
            children_enable: false,
            formData: {
                change_level: '',
                center_name: '',
                center_adress: '',
                center_location: '',
                center_type: '',
                no_of_rooms: '',
                no_of_benches: '',
                no_of_desks: '',
                no_of_chairs: '',
                no_of_estimated_registrations: '',
                office_room: '',
                library: '',
                toilet: '',
                rest_area: '',
                projector: '',
                board: '',
                fan_ac: '',
                drinking_water: '',
                org_cordinator: '',
                youth_cordinator: '',
                student_cordinator: '',
            },
            reliefFamily: [],
            old_child_count: 0,
            editable_id: '',
            profile_image: null,
            profile_file: null,
            profile_image_save: false,
            profile_image_uploaded: false,
            org_member_list: [],
            org_member: [],
            youth_member_list: [],
            youth_member: [],
            old_duty: [],
            new_duty: [],
            requestData: [],
            country_code_list: [],
            profile: [],
            student_cordinator: '',
            permissions: {
                edit: true
            }
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        childLength() {
            return parseInt(this.formData.lessthan_16_count);
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            if (this.unit_id == this.currentUser.unit_id || this.currentUser.user_level == 4) {
                this.editable = true;
            } else {
                this.editable = false;
            }

            if (this.user_level == 1) {
                this.formData.change_level = 3;
            } else if (this.user_level == 2) {
                this.formData.change_level = 3;
            } else if (this.user_level == 3) {
                this.formData.change_level = 3;
            } else if (this.user_level == 4) {
                this.formData.change_level = 4;
            }

            this.$root.updateParent("Meetings");
            if (this.$route.params.id) {
                this.getCreCenterData(this.$route.params.id);
            }
            this.profile = JSON.parse(localStorage.getItem("my_profile"));
            this.getMeetingAreas();
        }

        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        changeOld() {
            this.formData.new_department = this.formData.old_department;
            this.formData.new_duty = this.formData.old_duty;
        },
        uploadImage(event) {
            var file = event.target.files[0];
            this.profile_file = file;
            this.profile_image = URL.createObjectURL(file);
            this.profile_image_save = true;
        },
        getDepartments(member_id, user_level) {
            if (this.formData.for_what == '4' || this.formData.for_what == '3') {
                return 0;
            }
            this.message = '';
            let data = {
                member_id: member_id,
                user_level: user_level
            }
            UserService.authPostRequest('get-department-by-member', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.old_department_list = response.data.data.departments;
                        this.old_duty = response.data.data.duty;

                        this.new_department_list = response.data.data.departments;
                        this.new_duty = response.data.data.duty;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, wing) {
            let user_level = this.formData.change_level;
            let user_level_id = 0;
            if (user_level == 1) {
                user_level_id = 0;
            } else if (user_level == 2) {
                user_level_id = this.currentUser.district_id;
            } else if (user_level == 3) {
                user_level_id = this.currentUser.zone_id;
            } else if (user_level == 4) {
                user_level_id = this.currentUser.unit_id;
            }

            this.message = '';
            let data = {
                type: 'members',
                q: event,
                filter: 'inside',
                user_level_id: user_level_id,
                user_level: user_level,
                wing: wing,
            }
            UserService.authPostRequest('get-members-executives-by-id', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        if (wing == 1) {
                            this.org_member_list = response.data.data.list;
                        } else {
                            this.youth_member_list = response.data.data.list;
                        }

                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "RELIEF_REASON":
                            this.relief_reason_list = response.data.data.list.options;
                            break;
                        case "RELIEF_TYPE":
                            this.relief_type_list = response.data.data.list.options;
                            break;
                        case "COUNTRY_CODE":
                            this.country_code_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getMeetingAreas() {
            let data = {
                user_id: this.currentUser.id
            }
            UserService.authPostRequest('get-meeting-areas', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        console.log(response.data);
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        checkStudent() {
            let stud_items = false;
            this.formData.relief_types.forEach(element => {
                this.relief_type_list.forEach(element_ => {
                    if (element_.id == element) {
                        if (element_.item_value == 'student') {
                            stud_items = true;
                        }
                    }
                });
            });

            return stud_items;
        },
        getChild(type = null, count_inc = null) {
            if (type == "student") {
                if (!this.formData.lessthan_16_count) {
                    this.formData.relief_types.pop();
                    this.alert_type = "Warning";
                    this.alert_message = "16 വയസ്സിനു താഴെ ഉള്ള കുട്ടികളുടെ എണ്ണം നൽകുക.";
                    this.$refs.alert_model.openModel();
                } else {
                    if (this.formData.child.length > 0) {
                        for (let i = (this.formData.child.length + 1); i <= parseInt(this.formData.lessthan_16_count); i++) {
                            this.formData.child.push({
                                name: '',
                                age: '',
                                sex: '',
                                editable_id: null
                            });
                        }
                    } else {
                        for (let i = 1; i <= parseInt(this.formData.lessthan_16_count); i++) {
                            this.formData.child.push({
                                name: '',
                                age: '',
                                sex: '',
                                editable_id: null
                            });
                        }
                    }

                    this.old_child_count = parseInt(this.formData.lessthan_16_count);
                }
            } else if (count_inc && this.children_enable) {
                let count = this.formData.child.length;
                for (let i = (count + 1); i <= parseInt(this.formData.lessthan_16_count); i++) {
                    this.formData.child.push({
                        name: '',
                        age: '',
                        sex: '',
                        editable_id: null
                    });
                }
                this.old_child_count = parseInt(this.formData.lessthan_16_count);
            }
            if (this.checkStudent()) {
                this.children_enable = true;
            } else {
                this.children_enable = false;
            }

            console.log(this.formData.child);

        },
        handleRegistration(data) {
            console.log(data);
            this.loading = true;
            const formData_ = new FormData();
            if (this.editable_id) {
                formData_.append('editable_id', this.editable_id);
            }

            let user_level = this.formData.change_level;
            let user_level_id = 0;
            if (user_level == 1) {
                user_level_id = 0;
            } else if (user_level == 2) {
                user_level_id = this.currentUser.district_id;
            } else if (user_level == 3) {
                user_level_id = this.currentUser.zone_id;
            } else if (user_level == 4) {
                user_level_id = this.currentUser.unit_id;
            }

            formData_.append('user_level', user_level);
            formData_.append('user_level_id', user_level_id);

            formData_.append('center_level', this.formData.change_level);
            formData_.append('center_name', this.formData.center_name);
            formData_.append('center_adress', this.formData.center_adress);
            formData_.append('center_location', this.formData.center_location);

            formData_.append('center_type', this.formData.center_type);

            formData_.append('no_of_rooms', this.formData.no_of_rooms);
            formData_.append('no_of_benches', this.formData.no_of_benches);
            formData_.append('no_of_desks', this.formData.no_of_desks);
            formData_.append('no_of_chairs', this.formData.no_of_chairs);
            formData_.append('no_of_estimated_registrations', this.formData.no_of_estimated_registrations);

            formData_.append('office_room', this.formData.office_room);
            formData_.append('library', this.formData.library);
            formData_.append('toilet', this.formData.toilet);
            formData_.append('rest_area', this.formData.rest_area);
            formData_.append('projector', this.formData.projector);
            formData_.append('board', this.formData.board);
            formData_.append('fan_ac', this.formData.fan_ac);
            formData_.append('drinking_water', this.formData.drinking_water);

            formData_.append('org_cordinator', this.org_member.id);
            formData_.append('youth_cordinator', this.youth_member.id);
            formData_.append('student_cordinator', this.formData.student_cordinator);

            UserService.authPostRequest("store-cre-center", formData_).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        if (!this.editable_id) {
                            this.org_member_list = [];
                            this.org_member = [];
                            this.youth_member_list = [];
                            this.youth_member = [];
                            this.formData = {
                                change_level: this.formData.change_level,
                                center_name: '',
                                center_adress: '',
                                center_location: '',
                                center_type: '',
                                no_of_rooms: '',
                                no_of_benches: '',
                                no_of_desks: '',
                                no_of_chairs: '',
                                no_of_estimated_registrations: '',
                                office_room: '',
                                library: '',
                                toilet: '',
                                rest_area: '',
                                projector: '',
                                board: '',
                                fan_ac: '',
                                drinking_water: '',
                                org_cordinator: '',
                                youth_cordinator: '',
                                student_cordinator: '',
                            };
                        }

                        // setTimeout(() => {
                        //     location.replace('/org/'+parseInt(this.$route.params.sub_level)+'/'+parseInt(this.$route.params.id)+'/request-list');
                        // }, 1500);
                        this.loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeUserLevel() {
            this.getStudentCordinator();
        },
        getCreCenterData(id) {
            let data = {
                id: id
            }
            UserService.authPostRequest('show-cre-center', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.requestData = response.data.data.center;
                        this.permissions = response.data.data.permissions;
                        this.formData.change_level = (this.requestData.user_level).toString();
                        this.formData.center_name = this.requestData.center_name;
                        this.formData.center_adress = this.requestData.center_adress;
                        this.formData.center_location = this.requestData.center_location;
                        this.formData.center_type = this.requestData.center_type;
                        this.formData.no_of_rooms = this.requestData.no_of_rooms;
                        this.formData.no_of_benches = this.requestData.no_of_benches;
                        this.formData.no_of_desks = this.requestData.no_of_desks;
                        this.formData.no_of_chairs = this.requestData.no_of_chairs;
                        this.formData.no_of_estimated_registrations = this.requestData.no_of_estimated_registrations;
                        this.formData.office_room = this.requestData.office_room == 'Y' ? true : false;
                        this.formData.library = this.requestData.library == 'Y' ? true : false;
                        this.formData.toilet = this.requestData.toilet == 'Y' ? true : false;
                        this.formData.rest_area = this.requestData.rest_area == 'Y' ? true : false;
                        this.formData.projector = this.requestData.projector == 'Y' ? true : false;
                        this.formData.board = this.requestData.board == 'Y' ? true : false;
                        this.formData.fan_ac = this.requestData.fan_ac == 'Y' ? true : false;
                        this.formData.drinking_water = this.requestData.drinking_water == 'Y' ? true : false;
                        this.editable_id = this.requestData.id;

                        if (this.requestData.org_member) {
                            this.org_member = {
                                id: this.requestData.org_member.id,
                                member_id: this.requestData.org_member.id,
                                text: this.requestData.org_member.name + '-' + this.requestData.org_member.mobile,
                                mobile: this.requestData.org_member.mobile,
                                country_code: this.requestData.org_member.country_code
                            }
                            this.org_member_list.push(this.org_member);
                        }

                        if (this.requestData.youth_member) {
                            this.youth_member = {
                                id: this.requestData.youth_member.id,
                                member_id: this.requestData.youth_member.id,
                                text: this.requestData.youth_member.name + '-' + this.requestData.youth_member.mobile,
                                mobile: this.requestData.youth_member.mobile,
                                country_code: this.requestData.youth_member.country_code
                            }
                            this.youth_member_list.push(this.youth_member);
                        }

                        if (this.requestData.student_member) {
                            this.student_cordinator = this.requestData.student_member.name;
                        }

                        // if (this.formData.for_what == 1 || this.formData.for_what == 2) {
                        //     this.getDepartments(this.requestData.member_to_change.member_id, this.requestData.user_level);
                        // }

                        // if (this.requestData.new_member_to_change) {
                        //     this.new_member = {
                        //         id: this.requestData.new_member_to_change.member_id,
                        //         member_id: this.requestData.new_member_to_change.member_id,
                        //         text: this.requestData.new_member_to_change.member.name,
                        //         mobile: this.requestData.new_member_to_change.member.mobile,
                        //         country_code: this.requestData.new_member_to_change.member.country_code
                        //     }
                        //     this.new_member_list.push(this.new_member);
                        // }


                        // if (this.requestData.departments_to_change) {
                        //     this.formData.old_department = JSON.parse(this.requestData.departments_to_change);
                        // }
                        // if (this.requestData.new_departments_to_change) {
                        //     this.formData.new_department = JSON.parse(this.requestData.new_departments_to_change);
                        // }
                        // if (this.requestData.designations_to_change) {
                        //     this.formData.old_duty = JSON.parse(this.requestData.designations_to_change);
                        //     console.log(this.formData.old_duty);
                        // }
                        // if (this.requestData.new_designations_to_change) {
                        //     this.formData.new_duty = JSON.parse(this.requestData.new_designations_to_change);
                        // }

                        // this.formData.family_leader_house_name = this.reliefFamily.family_head_surname;
                        // this.formData.family_leader_mobile = this.reliefFamily.family_contact;
                        // this.formData.place = this.reliefFamily.family_place;
                        // this.formData.panjayath = this.reliefFamily.family_panchayath;
                        // this.formData.zakath = this.reliefFamily.zakath_eligible;
                        // this.formData.total_count = this.reliefFamily.family_members_count;
                        // this.formData.lessthan_16_count = this.reliefFamily.boys_count;
                        // this.formData.yatheem_count = this.reliefFamily.orphan_count;
                        // this.formData.widow_count = this.reliefFamily.widow_count;
                        // this.editable_id = this.reliefFamily.id;
                        // if (this.reliefFamily.family_reason_to_include) {
                        //     this.formData.relief_reason = JSON.parse(this.reliefFamily.family_reason_to_include);
                        // }
                        // if (this.reliefFamily.relief_types) {
                        //     this.formData.relief_types = JSON.parse(this.reliefFamily.relief_types);
                        //     if (response.data.data.child.length > 0) {
                        //         response.data.data.child.forEach(element => {
                        //             this.formData.child.push({
                        //                 name: element.child_name,
                        //                 age: element.child_age,
                        //                 sex: element.child_sex,
                        //                 editable_id: element.id
                        //             });
                        //         });
                        //         this.old_child_count = response.data.data.child.length;
                        //         this.children_enable = true;
                        //     }
                        // }
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}

.custom-file-upload {
    position: relative;
    display: flex;
    width: 50%;
    height: 100px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #060606;
}</style>
