<template>
    <div class="section text-center mt-2 mb-2" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="section-title mal-text">F. ശാരീരീരിക വെല്ലുവിളികൾ</div>
        <div class="card">
            <div class="card-body">
                <Form @submit="storeBasicInfo" :validation-schema="schema">
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">ശാരീരീരിക വെല്ലുവിളികൾ</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="disability" name="disability"
                                id="select4" @change="getChildList($event)">
                                <option v-for="item in disability_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name_mal }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="disability" class="error-feedback" />
                    </div>
                    <div class="form-group boxed" v-if="child_active">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b"></label>
                            <div class="row">
                                <div class="form-check mb-1 col-6" v-for="(item, index) in child_list" :value="item.id"
                                    v-bind:key="item.id" :set="id = 'customCheckb2' + (index + 1)">
                                    <input type="checkbox" class="form-check-input" v-bind:id="id" v-bind:value="item.id"
                                        @change="getChildList('child', item.name)" v-model="child">
                                    <label class="form-check-label" v-bind:for="id">{{ item.name_mal }}</label>
                                </div>
                                <ErrorMessage name="child" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div class="transparent pt-3" v-if="currentUser.id == profile.user_id">
                        <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save & Next</span>
                        </button>
                    </div>
                    <div class="transparent pt-3" v-if="currentUser.id != profile.user_id">
                        <button type="button" @click="goToNextPage" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Next</span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
export default {
    name: 'Basic Info',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            disability: yup.string().required("ശാരീരീരിക വെല്ലുവിളികൾ സെലക്ട് ചെയ്യുക!"),
        });

        return {
            loading: false,
            loading_list: false,
            message: "",
            schema,
            disability: '',
            child: [],
            profile: [],

            disability_list: [],
            child_list: [],
            child_active:false,
            disable_all:false
            
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.loading_list = true;
        this.getComboboxValues('DISABILITIES');
        this.getProfile();
        this.$root.updateParent("ബയോ ഡാറ്റ");
    },
    methods: {
        getProfile() {
            var data = {
                id: this.$route.params.id
            }
            UserService.authPostRequest('get-profile', data).then(
                (response) => {
                    this.profile = response.data.data.list;
                    if(this.profile.user_id != this.currentUser.id){
                        this.disable_all = true;
                        this.$root.updateParent(this.profile.name+" യുടെ ബയോ ഡാറ്റ");
                    }
                    this.disability = this.profile.disability?this.profile.disability:'';
                    this.child = this.profile.disabilities?JSON.parse(this.profile.disabilities):[];
                    if(this.child.length > 0){
                        this.child_active = true;
                        this.getComboboxValues('DIFFERENTLY_ABLED_SUB');
                    }
                    this.loading_list = false;
                },
            );
        },
        storeBasicInfo(data) {
            data.id = this.$route.params.id;
            data.user_id = this.currentUser.id;
            if(data.disability > 607){
                data.disabilities = null;
            }else{
                data.disabilities = JSON.stringify(this.child);
            }
            
            data.submit_type = 'disability';
            this.loading = true;
            console.log(data);
            UserService.authPostRequest('store-profile', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.$swal('', 'നിങ്ങളുടെ ബയോ ടാറ്റ ചേർക്കൽ പൂർത്തിയായി.', 'seccess');
                        this.loading = false;
                        this.$router.push('/bio-data');
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToNextPage(){
            this.$router.push('/bio-data/' + this.profile.user_id);
        },
        getComboboxValues(code) {
            var data = {
                code: code
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'DISABILITIES':
                            this.disability_list = response.data.data.list.options;
                            break;
                        case 'DIFFERENTLY_ABLED_SUB':
                            this.child_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
            );
        },
        getChildList(event) {
            if (event.target.value < 607) {
                this.child_active = true;
                this.getComboboxValues('DIFFERENTLY_ABLED_SUB');
            }else{
                this.child_active = false;
            }
        },
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

.label {
    font-size: 12px !important;
}

.form-control {
    height: 30px !important;
}

.mobile-field {
    margin-top: 23px;
}

.hr-line {
    height: 8px !important;
    background-color: #1a418c;
    opacity: 75%;
}
.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
    opacity: 1 !important;
}
.listview-title {
  color: #1a418c;
  padding: 1px 0px;
  font-size: 17px;
  font-weight: 900;
}
.section-title {
  color: #1a418c;
  padding: 1px 0%;
  font-size: 17px;
  font-weight: 900;
}
</style>