<template>
    <!-- Wallet Card -->
    <div class="section  pt-1">
        <div class="wallet-card">
            <!-- Balance -->
            <div class="balance">
                <div class="left">
                    <span class="title">Total Balance</span>
                    <h1 class="total">$ 2,562.50</h1>
                </div>
                <!-- <div class="right">
                    <a href="#" class="button" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                        <ion-icon name="add-outline"></ion-icon>
                    </a>
                </div> -->
            </div>
            <!-- * Balance -->
            <!-- Wallet Footer -->
            <div class="wallet-footer">
                <div class="item">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                        <div class="icon-wrapper bg-danger">
                            <ion-icon name="arrow-down-outline"></ion-icon>
                        </div>
                        <strong>Withdraw</strong>
                    </a>
                </div>
                <div class="item">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                        <div class="icon-wrapper">
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                        </div>
                        <strong>Send</strong>
                    </a>
                </div>
                <div class="item">
                    <a href="app-cards.html">
                        <div class="icon-wrapper bg-success">
                            <ion-icon name="card-outline"></ion-icon>
                        </div>
                        <strong>Cards</strong>
                    </a>
                </div>
                <div class="item">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                        <div class="icon-wrapper bg-warning">
                            <ion-icon name="swap-vertical"></ion-icon>
                        </div>
                        <strong>Exchange</strong>
                    </a>
                </div>

            </div>
            <!-- * Wallet Footer -->
        </div>
    </div>
    <!-- Wallet Card -->
    <div class="home">
        <div class="section full mb-3 pt-2" v-if="currentUser.user_level <= 3">
            <div class="section-title mal-text custom-title text-center">ശാഖ ഇലക്ഷൻ ഷെഡ്യൂൾ</div>
            <div id="chart">
                <apexchart type="pie" width="380" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
        <div class="section mt-2 mb-2" v-if="user_level < 4">
            <div class="section-title">{{ sub_level_list_name }} Report</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{ sub_level_list_name }}</th>
                                <th scope="col">Members</th>
                                <th scope="col">Unit Schedule</th>
                                <th scope="col">Election Report</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(sub_level, index) in sub_levels" v-bind:key="sub_level.id">
                                <th>{{ index + 1 }}</th>
                                <td>{{ sub_level.name }}</td>
                                <td class="text-primary">{{ sub_level.members_count_filled }}/{{ sub_level.members_count
                                }}<br><b>{{
        isNaN(((sub_level.members_count_filled * 100) /
            sub_level.members_count).toFixed(0)) ? '0' : ((sub_level.members_count_filled *
                100) /
                sub_level.members_count).toFixed(0)
}}% </b></td>
                                <td class="text-primary" v-if="!sub_level.from_unit">
                                    {{ sub_level.elec_scheduled_units_count }}/{{ sub_level.units_count }}<br><b>{{
                                            isNaN(((sub_level.elec_scheduled_units_count * 100) /
                                                sub_level.units_count).toFixed(0)) ? '0' :
                                                ((sub_level.elec_scheduled_units_count * 100) /
                                                    sub_level.units_count).toFixed(0)
                                    }}% </b>
                                </td>
                                <td class="text-primary" v-if="!sub_level.from_unit">
                                    {{ sub_level.unit_election_completed_count }}/{{ sub_level.units_count }}<br><b>{{
                                            isNaN(((sub_level.unit_election_completed_count * 100) /
                                                sub_level.units_count).toFixed(0)) ? '0' :
                                                ((sub_level.unit_election_completed_count * 100) /
                                                    sub_level.units_count).toFixed(0)
                                    }}% </b>
                                </td>

                                <td class="text-primary" v-if="sub_level.from_unit">
                                    <span class="badge badge-success" v-if="sub_level.elec_scheduled_units_count == 1">
                                        <ion-icon name="checkmark-outline" class="icon-size20">
                                        </ion-icon>
                                    </span>
                                    <span class="badge badge-danger" v-if="sub_level.elec_scheduled_units_count == 0">
                                        <ion-icon name="close-outline" class="icon-size20"></ion-icon>
                                    </span>
                                </td>
                                <td class="text-primary" v-if="sub_level.from_unit">
                                    <span class="badge badge-success"
                                        v-if="sub_level.unit_election_completed_count == 1">
                                        <ion-icon name="checkmark-outline" class="icon-size20">
                                        </ion-icon>
                                    </span>
                                    <span class="badge badge-danger"
                                        v-if="sub_level.unit_election_completed_count == 0">
                                        <ion-icon name="close-outline" class="icon-size20"></ion-icon>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-primary">
                                    Total
                                </td>
                                <td class="text-primary">
                                    {{ totalItem.members_filled_sum }}/{{ totalItem.members_sum }}<br><b>{{
                                            isNaN(((totalItem.members_filled_sum * 100) /
                                                totalItem.members_sum).toFixed(0)) ? '0' : ((totalItem.members_filled_sum * 100)
                                                    /
                                                    totalItem.members_sum).toFixed(0)
                                    }}% </b>
                                </td>
                                <td class="text-primary">
                                    {{ totalItem.units_scheduled_sum }}/{{ totalItem.units_sum }}<br><b>{{
                                            isNaN(((totalItem.units_scheduled_sum * 100) /
                                                totalItem.units_sum).toFixed(0)) ? '0' : ((totalItem.units_scheduled_sum * 100)
                                                    /
                                                    totalItem.units_sum).toFixed(0)
                                    }}% </b>
                                </td>
                                <td class="text-primary">
                                    {{ totalItem.units_election_sum }}/{{ totalItem.units_sum }}<br><b>{{
                                            isNaN(((totalItem.units_election_sum * 100) /
                                                totalItem.units_sum).toFixed(0)) ? '0' : ((totalItem.units_election_sum * 100) /
                                                    totalItem.units_sum).toFixed(0)
                                    }}% </b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import UserService from "../../services/user.service";
// import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/dist/style.css'
export default {
    components: {
    },
    data() {
        return {
            sub_level_list_name: '',
            user_level: '',
            sub_levels: [],
            unit: [],
            banners: [],
            total_unit: 0.00,
            total_scheduled: 0.00,

            series: [0, 0],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                colors: ['#64b167', '#db7491'],
                labels: ['Total Units', 'Total Unit Scheduled'],
                responsive: [{
                    breakpoint: 300,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

        };
    },

    mounted() {
        localStorage.activeMenu = 'Home';
        if (!this.currentUser) {
            localStorage.removeItem("user");
            location.replace('/');
        } else {
            this.user_level = this.currentUser.user_level;
            if (this.user_level == 1) {
                this.sub_level_list_name = 'District';
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.sub_level_list_name = 'Zone';
                this.user_level_id = this.currentUser.district_id;
            }
            else if (this.user_level == 3) {
                this.sub_level_list_name = 'Unit';
                this.user_level_id = this.currentUser.zone_id;
            }
            this.getHomeContents();
            this.getSubLevels();
            this.$root.updateParent('Dashboard');

        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        totalItem() {
            let members_sum = 0;
            let members_filled_sum = 0;

            let units_sum = 0;
            let units_scheduled_sum = 0;
            let units_election_sum = 0;

            this.sub_levels.forEach(element => {
                units_sum += element.units_count;
                units_scheduled_sum += element.elec_scheduled_units_count;
                units_election_sum += element.unit_election_completed_count;

                members_sum += element.members_count;
                members_filled_sum += element.members_count_filled;

            });
            let data = {
                members_sum: members_sum,
                members_filled_sum: members_filled_sum,
                units_sum: units_sum,
                units_scheduled_sum: units_scheduled_sum,
                units_election_sum: units_election_sum
            }
            return data;
        }
    },
    methods: {
        updateChart() {
            // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
            this.chartOptions = {
                chart: {
                    type: 'pie'
                },
                colors: ['#64b167', '#db7491'],
                labels: ['Units Scheduled(' + this.totalItem.units_scheduled_sum + ')', 'Units Not Scheduled(' + (this.totalItem.units_sum - this.totalItem.units_scheduled_sum) + ')'],
                responsive: [{
                    breakpoint: this.totalItem.units_sum,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
            // In the same way, update the series option
            this.series = [this.totalItem.units_scheduled_sum, (parseFloat(this.totalItem.units_sum) - parseFloat(this.totalItem.units_scheduled_sum))]
        },
        getHomeContents() {
            this.loading = true;
            let data = {
            }
            UserService.authPostRequest('get-home-contents', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.banners = response.data.data.banners;
                        localStorage.setItem('user', JSON.stringify(response.data.data.user));
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (this.message == 'Unauthenticated.') {
                        localStorage.removeItem("user");
                        location.replace('/');
                    }
                }
            );
        },
        getSubLevels() {
            this.loading = true;
            if (this.user_level == 2) {
                this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.currentUser.unit_id;
            }
            let data = {
                sub_level_type: this.user_level + 1,
                id: this.user_level_id
            }
            UserService.getSubLevels('get-sub-levels', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.sub_levels = response.data.data.list;
                        this.unit = response.data.data.unit;
                        this.updateChart();
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (this.message == 'Unauthenticated.') {
                        localStorage.removeItem("user");
                        location.replace('/');
                    }
                }
            );
        },
    },

};
</script>
<style scoped>
.blue-head {
    color: blue;
}

.carousel__slide {
    width: 100% !important;
}

.card-slider {
    width: 90% !important;
}

.card-title {
    font-weight: 600 !important;
}

th {
    padding: 5px !important;
    font-size: 12px;
}

td {
    padding: 5px !important;
}
</style>