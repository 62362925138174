<template>
    <div class="appHeader bg-primary text-light" v-if="this.$route.name == 'Home' || this.$route.name == 'Welcome' || this.$route.name == 'Register' || this.$route.name == 'Login'">
        <div class="left">
            <a href="javascript:void(0)" class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                <ion-icon name="menu-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            <img src="assets/img/wisdom-guide.png" alt="logo" class="logo">
        </div>
        <div class="right">
            <!-- <a href="/#/notifications" class="headerButton" v-if="this.$route.name != 'Welcome'">
                <ion-icon class="icon" name="notifications-outline"></ion-icon>
                <span class="badge badge-danger">0</span>
            </a> -->
            <router-link :to="'/user-login'" v-if="this.$route.name == 'Welcome'">
                <a href="javascript:void(0)" class="headerButton">
                    Login<ion-icon class="icon" name="log-in-outline"></ion-icon>
                </a>
            </router-link>
        </div>
    </div>
    <!-- App Header -->
    <div class="appHeader" v-else>
        <div class="left">
            <a href="javascript:void(0)" class="headerButton goBack" @click="goBack()">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            {{ page_header ? page_header : this.$route.name }}
        </div>
        <div class="right">
            <button v-if="minutes || seconds" type="button" class="btn btn-outline-danger me-1 mb-1">{{ minutes }}:{{ seconds }}</button>
            <!-- <a href="javascript:void(0)" class="headerButton" v-if="this.$route.name != 'Welcome'">
                <ion-icon class="icon" name="notifications-outline"></ion-icon>
                <span class="badge badge-danger">0</span>
            </a> -->
        </div>
    
    </div>
    <!-- * App Header -->
    
    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute" v-if="this.$route.name == 'Login'">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->
</template>

<script>
export default {
    props: {
        page_header: String,
        minutes: String,
        seconds: String
    },
    methods: {
        goBackAnimation() {
            // Go Back Animation
            // pageBody.classList.add("animationGoBack")
            setTimeout(() => {
                this.$router.go(-1);
            }, 300);
        },
        goBack() {
            this.$router.go(-1);
        }
    },
    mounted() {
        // Go Back Button
        var goBackButton = document.querySelectorAll(".goBack");
        goBackButton.forEach(function(el) {
            el.addEventListener("click", function() {
                // if (Finapp.Animation.goBack) {
                //     goBackAnimation();
                // }
                // else {
                //     this.$router.go(-1);
                // }

            })
        })

    },
};
</script>