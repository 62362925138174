<template>
    <div class="section text-center mt-2 mb-2" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="section-title mal-text">D. കൈകാര്യം ചെയ്യുന്ന ഭാഷകൾ</div>
        <div class="card">
            <div class="card-body">
                <Form @submit="storeBasicInfo" :validation-schema="schema">
                    <div class="form-group boxed" >
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b" v-if="profile.wing != 5">സംസാരിക്കുന്ന ഭാഷകൾ</label>
                            <label class="label mal-text" for="text4b" v-if="profile.wing == 5">നിങ്ങൾക്ക് അറിയുന്ന ഭാഷകൾ</label>
                            <div class="row">
                                <div class="form-check mb-1 col-6" v-for="(item, index) in speaking_list"
                                    :value="item.id" v-bind:key="item.id" :set="id = 'customCheckb' + (index + 1)">
                                    <input :disabled="disable_all" type="checkbox" class="form-check-input" v-bind:id="id"
                                        v-bind:value="item.id" @change="getChildList('speaking', item.name)"
                                        v-model="speaking">
                                    <label class="form-check-label" v-bind:for="id">{{ item.name_mal }}</label>
                                </div>
                                <ErrorMessage name="speaking" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group boxed" v-if="speaking_text">
                            <div class="input-wrapper">
                                <label class="label" for="text4b">Language 1</label>
                                <Field name="speaking_1" type="text" class="form-control" id="speaking_1" v-model="speaking_1"
                                    placeholder="" />
                            </div>
                        </div>
                        <div class="form-group boxed" v-if="speaking_text">
                            <div class="input-wrapper">
                                <label class="label" for="text4b">Language 2</label>
                                <Field name="speaking_2" type="text" class="form-control" id="speaking_2" v-model="speaking_2"
                                    placeholder="" />
                            </div>
                        </div>
                        <div class="form-group boxed" v-if="speaking_text">
                            <div class="input-wrapper">
                                <label class="label" for="text4b">Language 3</label>
                                <Field name="speaking_3" type="text" class="form-control" id="speaking_3" v-model="speaking_3"
                                    placeholder="" />
                            </div>
                        </div> -->
                    <div class="form-group boxed" v-if="this.profile.wing != 5">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">എഴുതാൻ കഴിയുന്ന ഭാഷകൾ</label>
                            <div class="row">
                                <div class="form-check mb-1 col-6" v-for="(item, index) in writing_list"
                                    :value="item.id" v-bind:key="item.id" :set="id = 'customCheckb2' + (index + 1)">
                                    <input :disabled="disable_all" type="checkbox" class="form-check-input" v-bind:id="id"
                                        v-bind:value="item.id" @change="getChildList('writing', item.name)"
                                        v-model="writing">
                                    <label class="form-check-label" v-bind:for="id">{{ item.name_mal }}</label>
                                </div>
                                <ErrorMessage name="writing" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div v-if="writing_text">
                        <!-- <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Language 1</label>
                                    <Field name="writing_1" type="text" class="form-control" id="writing_1"
                                        placeholder="" />
                                </div>
                            </div>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Language 2</label>
                                    <Field name="writing_2" type="text" class="form-control" id="writing_2"
                                        placeholder="" />
                                </div>
                            </div>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Language 3</label>
                                    <Field name="writing_3" type="text" class="form-control" id="writing_3"
                                        placeholder="" />
                                </div>
                            </div> -->
                    </div>
                    <div class="form-group boxed" v-if="this.profile.wing != 5">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">പ്രസംഗിക്കാൻ / ക്ലാസ് എടുക്കാൻ കഴിയുന്ന
                                ഭാഷകൾ</label>
                            <div class="row">
                                <div class="form-check mb-1 col-6" v-for="(item, index) in speech_list" :value="item.id"
                                    v-bind:key="item.id" :set="id = 'customCheckb3' + (index + 1)">
                                    <input :disabled="disable_all" type="checkbox" class="form-check-input" v-bind:id="id"
                                        v-bind:value="item.id" @change="getChildList('speech', item.name)"
                                        v-model="speech">
                                    <label class="form-check-label" v-bind:for="id">{{ item.name_mal }}</label>
                                </div>
                                <ErrorMessage name="speech" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div v-if="speech_text">
                        <!-- <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Language 1</label>
                                    <Field name="speech_1" type="text" class="form-control" id="speech_1"
                                        placeholder="" />
                                </div>
                            </div>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Language 2</label>
                                    <Field name="speech_2" type="text" class="form-control" id="speech_2"
                                        placeholder="" />
                                </div>
                            </div>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Language 3</label>
                                    <Field name="speech_3" type="text" class="form-control" id="speech_3"
                                        placeholder="" />
                                </div>
                            </div> -->
                    </div>

                    <div class="transparent pt-3" v-if="currentUser.id == profile.user_id">
                        <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save & Next</span>
                        </button>
                    </div>
                    <div class="transparent pt-3" v-if="currentUser.id != profile.user_id">
                        <button type="button" @click="goToNextPage" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Next</span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
export default {
    name: 'Basic Info',
    components: {
        Form,
        ErrorMessage,
    },
    data() {
        // const schema = yup.object().shape({
        //     speaking: yup.string().required("ഭൗതിക വിദ്യാഭാസം സെലക്ട് ചെയ്യുക!"),
        //     writing: yup.string().required("ഭൗതിക വിദ്യാഭാസം സെലക്ട് ചെയ്യുക!"),
        //     speech: yup.string().required("ഭൗതിക വിദ്യാഭാസം സെലക്ട് ചെയ്യുക!"),
        // });

        return {
            loading: false,
            loading_list: false,
            message: "",
            speaking: [],
            writing: [],
            disable_all:false,
            speech: [],

            speaking_list: [],
            writing_list: [],
            speech_list: [],
            profile:[],
            speaking_text: false,
            writing_text: false,
            speech_text: false,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.loading_list = true;
        this.getComboboxValues('LANGUAGE_SPEAK');
        this.getComboboxValues('LANGUAGE_WRITE');
        this.getComboboxValues('LANGUAGE_TEACH');
        this.getProfile();
        this.$root.updateParent("ബയോ ഡാറ്റ");
    },
    methods: {
        getProfile() {
            var data = {
                id: this.$route.params.id
            }
            UserService.authPostRequest('get-profile', data).then(
                (response) => {
                    this.profile = response.data.data.list;
                    if(this.profile.user_id != this.currentUser.id){
                        this.disable_all = true;
                        this.$root.updateParent(this.profile.name+" യുടെ ബയോ ഡാറ്റ");
                    }
                    this.speaking = this.profile.speaking_languages ? JSON.parse(this.profile.speaking_languages) : [];
                    this.writing = this.profile.writing_languages ? JSON.parse(this.profile.writing_languages) : [];
                    this.speech = this.profile.speeching_languages ? JSON.parse(this.profile.speeching_languages) : [];

                    this.loading_list = false;
                },
            );
        },
        storeBasicInfo(data) {
            data.id = this.$route.params.id;
            data.user_id = this.currentUser.id;
            data.speaking = JSON.stringify(this.speaking);
            data.writing = JSON.stringify(this.writing);
            data.speech = JSON.stringify(this.speech);
            data.submit_type = 'language';

            console.log(data);
            this.loading = true;
            UserService.authPostRequest('store-profile', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        // this.$swal('സേവ് ചെയ്തു', '', 'seccess');
                        this.loading = false;
                        this.$router.push('/bio-data/contribute-info/' + this.$route.params.id);
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToNextPage(){
            this.$router.push('/bio-data/contribute-info/' + this.$route.params.id);
        },
        getComboboxValues(code) {
            var data = {
                code: code
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'LANGUAGE_SPEAK':
                            this.speaking_list = response.data.data.list.options;
                            break;
                        case 'LANGUAGE_WRITE':
                            this.writing_list = response.data.data.list.options;
                            break;
                        case 'LANGUAGE_TEACH':
                            this.speech_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
            );
        },
        getChildList(field, event) {
            switch (field) {
                case 'speaking':
                    if (event == 'Other Language') {
                        if (this.speaking_text) {
                            this.speaking_text = false;
                        } else {
                            this.speaking_text = true;
                        }
                    }
                    break;
                case 'writing':
                    if (event == 'Other') {
                        if (this.writing_text) {
                            this.writing_text = false;
                        } else {
                            this.writing_text = true;
                        }
                    }
                    break;
                case 'speech':
                    if (event == 'Other') {
                        if (this.speech_text) {
                            this.speech_text = false;
                        } else {
                            this.speech_text = true;
                        }
                    }
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

.label {
    font-size: 12px !important;
}

.form-control {
    height: 30px !important;
}

.mobile-field {
    margin-top: 23px;
}

.hr-line {
    height: 8px !important;
    background-color: #1a418c;
    opacity: 75%;
}

.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
    opacity: 1 !important;
}

.listview-title {
  color: #1a418c;
  padding: 1px 0px;
  font-size: 17px;
  font-weight: 900;
}
.section-title {
  color: #1a418c;
  padding: 1px 0%;
  font-size: 17px;
  font-weight: 900;
}
</style>