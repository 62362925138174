<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text text-primary"> {{ zone.name_mal ? zone.name_mal : zone.name }} {{
                $route.params.sub_level == 2 ?
                    'ജില്ല' : $route.params.sub_level == 1||$route.params.sub_level == 0 ?
                        'സ്റ്റേറ്റ്' : 'മണ്ഡലം'
            }}
                <br>കൗൺസിൽ അറ്റന്റൻസ് റിപ്പോർട്ട്
            </h3>
        </div>
    </div>
    <div class="section pt-1 mt-1">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr v-if="$route.params.sub_level == 1">
                            <th scope="col">District</th>
                            <th scope="col" class="text-end mal-text">ആകെ എണ്ണം</th>
                            <th scope="col" class="text-end mal-text">പങ്കെടുത്തവർ</th>
                        </tr>
                        <tr v-if="$route.params.sub_level == 0">
                            <th scope="col">District</th>
                            <th scope="col" class="text-end mal-text">W/Y/S</th>
                            <th scope="col" class="text-end mal-text">W/Y/S</th>
                        </tr>
                    </thead>
                    <tbody v-if="((user_level != 1) || (user_level == 1 && currentUser.wing == 1)) && $route.params.sub_level == 1">
                        <tr v-for="report_ in district_report" v-bind:key="report_">
                            <td class="text-primary mal-text">
                                {{ report_.name }}
                            </td>
                            <td class="text-end text-primary">
                                {{ report_.attendance.wisdom_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ report_.attendance.wisdom_count }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="((user_level != 1) || (user_level == 1 && currentUser.wing == 2)) && $route.params.sub_level == 1">
                        <tr v-for="report_ in district_report" v-bind:key="report_">
                            <td class="text-primary mal-text">
                                {{ report_.name }}
                            </td>
                            <td class="text-end text-primary">
                                {{ report_.attendance.youth_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ report_.attendance.youth_count }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="((user_level != 1) || (user_level == 1 && currentUser.wing == 3)) && $route.params.sub_level == 1">
                        <tr v-for="report_ in district_report" v-bind:key="report_">
                            <td class="text-primary mal-text">
                                {{ report_.name }}
                            </td>
                            <td class="text-end text-primary">
                                {{ report_.attendance.students_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ report_.attendance.students_count }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="$route.params.sub_level == 0">
                        <tr v-for="report_ in district_report" v-bind:key="report_">
                            <td class="text-primary mal-text">
                                {{ report_.name }}
                            </td>
                            <td class="text-end text-primary">
                                {{ report_.attendance.wisdom_total_count }}/
                                {{ report_.attendance.youth_total_count }}/
                                {{ report_.attendance.students_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ report_.attendance.wisdom_count }}/
                                {{ report_.attendance.youth_count }}/
                                {{ report_.attendance.students_count }}
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
    <div class="section pt-1 mt-1">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Wing</th>
                            <th scope="col" class="text-end mal-text">ആകെ എണ്ണം</th>
                            <th scope="col" class="text-end mal-text">പങ്കെടുത്തവർ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="(user_level != 1) || (user_level == 1 && currentUser.wing == 1)">
                            <td class="text-primary mal-text">
                                വിസ്‌ഡം
                            </td>
                            <td class="text-end text-primary">
                                {{ attendance_report.wisdom_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ attendance_report.wisdom_count }}
                            </td>
                        </tr>
                        <tr v-if="(user_level != 1) || (user_level == 1 && currentUser.wing == 2)">
                            <td class="text-primary mal-text">
                                യൂത്ത്
                            </td>
                            <td class="text-end text-primary">
                                {{ attendance_report.youth_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ attendance_report.youth_count }}
                            </td>
                        </tr>
                        <tr v-if="(user_level != 1) || (user_level == 1 && currentUser.wing == 3)">
                            <td class="text-primary mal-text">
                                സ്റ്റുഡന്റസ്
                            </td>
                            <td class="text-end text-primary">
                                {{ attendance_report.students_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ attendance_report.students_count }}
                            </td>
                        </tr>
                        <tr v-if="($route.params.sub_level == 3)">
                            <td class="text-primary mal-text">
                                വിമെൻ
                            </td>
                            <td class="text-end text-primary">
                                {{ attendance_report.women_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ attendance_report.women_count }}
                            </td>
                        </tr>
                        <tr v-if="($route.params.sub_level == 3)">
                            <td class="text-primary mal-text">
                                ഗേൾസ്
                            </td>
                            <td class="text-end text-primary">
                                {{ attendance_report.girls_total_count }}
                            </td>
                            <td class="text-end text-success">
                                {{ attendance_report.girls_count }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-primary mal-text">
                                ആകെ എണ്ണം
                            </td>
                            <td class="text-end text-primary">
                                <b>
                                    {{ parseFloat(attendance_report.wisdom_total_count) +
    parseFloat(attendance_report.youth_total_count) +
    parseFloat(attendance_report.students_total_count)
                                    }}
                                </b>
                            </td>
                            <td class="text-end text-success">
                                <b>
                                    {{ parseFloat(attendance_report.wisdom_count) +
    parseFloat(attendance_report.youth_count) +
    parseFloat(attendance_report.students_count)
                                    }}
                                </b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="section pt-1 mt1">
        <div class="card">
            <div clas="card-body">
                <p class="text-primary text-center">
                    ക്യൂ ആർ കോഡ് വെച്ച് അറ്റന്റൻസ് മാർക്ക് ചെയ്യാനുള്ള ഓപ്ഷൻ ഉപയോഗപ്പെടുത്തുക. അതിനു കഴിയാത്തവരുടെ
                    അറ്റന്റൻസ് ജനറൽ കൗൺസിൽ ലിസ്റ്റിൽ പോയി മാർക്ക് ചെയ്യാവുന്നതാണ്. </p>
                <p class="text-primary text-center">കൌൺസിൽ പങ്കെടുത്ത എല്ലാവരുടെയും
                    അറ്റന്റൻസ് മാർക്ക് ചെയ്തു എന്ന് ഉറപ്പു വരുത്തുക.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import UserService from "../services/user.service";
import $ from 'jquery';
export default {
    name: 'Admin List',
    props: {
        unit_id: null
    },
    components: {
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            attendance_report: [],
            zone: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: ''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList(1);
            if ((this.unit_id == this.currentUser.unit_id) || this.currentUser.user_level == 4) {
                this.editable = true;
            } else {
                this.editable = false;
            }
        }
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getMembersList() {
            this.loading_list = true;
            let data = {
                user_level: parseInt(this.$route.params.sub_level),
                user_level_id: parseInt(this.$route.params.id),
            }
            UserService.authPostRequest('get-attendace-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.attendance_report = response.data.data.list;
                        this.district_report = response.data.data.districts;
                        this.zone = response.data.data.zone;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },


    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}
</style>