<template>
    <div class="section mt-2 text-center">
        <div class="card">
            <div class="card-body pt-0 pb-0">
                <div class="icon-wrapper">
                    <ion-icon name="cloud-download-outline" class="icon-1"></ion-icon>
                </div>
                <!-- <img src="assets/logo.png" alt="image" class="imaged w-50 "> -->
                <h2 class="mt-0 mal-text">Downloads<br> </h2>
            </div>
        </div>
    </div>
    <div class="section mt-2">
        <!-- <div class="section-title">Today</div> -->
        <div class="transactions">
            <!-- item -->
            <a v-for="item in list" v-bind:key="item.name"
                :set="url = 'https://guide.msoftit.com/uploads/downloads/forms/'+item.file" :href="url" download=""
                class="item">
                <div class="detail">
                    <!-- <img src="assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48"> -->
                    <div>
                        <strong>{{item.name}}</strong>
                        <p class="mal-text">ഡൌൺലോഡ് ചെയ്യാൻ ഇവിടെ അമർത്തുക </p>
                    </div>
                </div>
                <!-- <div class="right">
                    <div class="price text-danger"> - $ 150</div>
                </div> -->
            </a>
        </div>
    </div>

</template>
<script>
import UserService from "../../services/user.service";
export default {
    data() {
        return {
            list: []
        };
    },
    mounted() {
        this.getDownloads();
    },
    methods: {
        refreshPage() {
            localStorage.removeItem("user");
            location.replace('/');
        },
        getDownloads() {
            let data = {
            }
            UserService.authPostRequest('get-downloads', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.list = response.data.data.list;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.accordion-button {
    font-weight: 700 !important;
}

.imaged {
    width: 60px !important;
}

.btn-primary {
    width: 100%;
}
.icon-1{
    color:#1a418c !important;
    font-size:40px;
}

</style>