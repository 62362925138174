<template>
    <div class="section text-center mt-2" v-if="loading">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-1" v-if="responseData.common_links.length">
        <div class="transactions">
            <a v-for="common_links in responseData.common_links" v-bind:key="common_links" :href="common_links.link"
                class="item">
                <div class="detail">
                    <img :src="common_links.image" alt="img" class="image-block imaged w48">
                    <div>
                        <strong class="mal-text">{{ common_links.title }}</strong>
                        <p class="mal-text">{{ common_links.desc }}</p>
                    </div>
                </div>
                <div class="right">
                    <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                </div>
            </a>
        </div>
    </div>
    <!-- Wallet Card -->
    <div class="section pt-2" style="display:none;">
        <div class="wallet-card">
            <div class="wallet-footer row">
                <div class="item col-4" v-if="currentUser.user_level <= 2">
                    <a href="javascript:void(0)" class="button" @click="showAddAdminModel">
                        <div class="icon-wrapper">
                            <ion-icon name="person-add-outline"></ion-icon>
                        </div>
                        <strong>New Admin</strong>
                    </a>
                </div>
                <div class="item col-4">
                    <router-link :to="'/campus/' + (user_level) + '/' + $route.params.id + '/admin-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="list-outline"></ion-icon>
                            </div>
                            <strong>Admin List</strong>
                        </a>
                    </router-link>
                </div>
                <div class="item col-4" v-if="currentUser.user_level <= 3">
                    <a href="javascript:void(0)" class="button" @click="openAddMemberModel">
                        <div class="icon-wrapper">
                            <ion-icon name="person-add-outline"></ion-icon>
                        </div>
                        <strong>New Member</strong>
                    </a>
                </div>

                <!-- <div class="item col-4" v-if="user_level == 3">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/zone-cons-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="people-outline"></ion-icon>
                            </div>
                            <strong>Membership Report/Election</strong>
                        </a>
                    </router-link>
                </div> -->
                <!-- <div class="item col-4" v-if="user_level == 3">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/cons-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="people-outline"></ion-icon>
                            </div>
                            <strong>Membership Report/Election</strong>
                        </a>
                    </router-link>
                </div> -->
                <!-- <div class="item col-4" v-if="user_level == 3">
                    <router-link :to="'/downloads'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="cloud-download-outline"></ion-icon>
                            </div>
                            <strong class="mal-text">ഡൌൺലോഡ്</strong>
                        </a>
                    </router-link>
                </div> -->
                <!-- <div class="item col-4" v-if="(user_level == 3 && currentUser.zone_id == unit.zone_id) || (user_level == 3 && currentUser.user_level <= 3 && currentUser.district_id == unit.district_id) || (user_level == 3 && currentUser.user_level == 1)">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/excecutive-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="people-circle-outline"></ion-icon>
                            </div>
                            <strong class="mal-text">ഭാരവാഹികൾ/ഇലക്ഷൻ റിപ്പോർട്ട്</strong>
                        </a>
                    </router-link>
                </div> -->
                <!-- <div class="item col-4" v-if="(user_level == 3 && currentUser.zone_id == unit.zone_id) || (user_level == 3 && currentUser.user_level <= 3 && currentUser.district_id == unit.district_id) || (user_level == 3 && currentUser.user_level == 1)">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/attendance'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="checkmark-outline"></ion-icon>
                            </div>
                            <strong class="mal-text">മീറ്റിംഗ് അറ്റന്റൻസ്</strong>
                        </a>
                    </router-link>
                </div> -->

            </div>
            <!-- * Wallet Footer -->
        </div>
        <!-- <div class="wallet-card mt-1" v-if="user_level == 3">
            <div class="wallet-footer wallet-footer-user">
                <div class="item">
                    <a href="javascript:void(0)" @click="openAddMemberModel">
                        <div class="row">
                            <div class="icon-wrapper col-2">
                                <ion-icon name="person-add-outline"></ion-icon>
                            </div>
                            <strong class="add-member-btn col-9">Add New Member</strong>
                        </div>
                    </a>
                </div>
            </div>
        </div> -->
    </div>
    <!-- Wallet Card -->

    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Admin</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="Name">Name</label>
                                <div class="form-group boxed">
                                    <Field name="name" type="text" class="form-control" id="name"
                                        placeholder="Enter Name" />
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                                <div class="form-group" v-if="message">
                                    <div v-if="message" class="error-feedback">
                                        {{ message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper row">
                                    <label class="label" for="country_code">Mobile Number</label>
                                    <div class="form-group boxed" style="width:30% !important;">
                                        <div class="input-wrapper">
                                            <Field as="select" class="form-control custom-select" v-model="country_code"
                                                name="country_code" id="select4">
                                                <option v-for="item in country_code_list" :value="item.item_value"
                                                    v-bind:key="item.item_value">
                                                    {{ item.name }}</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="form-group boxed mobile-field"
                                        style="width:68% !important; margin-left: 2%;">
                                        <div class="input-wrapper">
                                            <Field name="mobile_number" type="number" id="mobile_number"
                                                class="form-control" placeholder=""
                                                oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 11); } if (this.value.length > 10) { this.value = this.value.slice(0, 10); }" />
                                        </div>
                                    </div>
                                    <ErrorMessage name="mobile_number" class="error-feedback" />
                                </div>
                            </div>
                            <!-- <div class="form-group basic">
                                <label class="label" for="wing">Wing</label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <Field as="select" class="form-control custom-select" v-model="wing" name="wing"
                                            id="select4">
                                            <option selected>Select Wing</option>
                                            <option value="1">Wisdom</option>
                                            <option value="2">Youth</option>
                                            <option value="3">Students</option>

                                            <option value="4">Women</option>
                                            <option value="6">Girls</option>

                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="wing" class="error-feedback" />
                            </div> -->
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * AddAdminModel -->
    <!-- <div class="section pt-2" v-if="user_level == 2">
        <router-link :to="'/org/' + (user_level + 1) + '/' + $route.params.id + '/unit-report'">
            <button class="btn btn-primary report-btn mal-text">ശാഖാ അഡ്മിൻ നിയമന റിപ്പോർട്ട് &nbsp;&nbsp; Click Here
                <ion-icon name="arrow-forward-outline"></ion-icon>
            </button>
        </router-link>
    </div> -->

    <div class="listview-title mt-1" v-if="!loading_list && user_level != 3">{{ sub_level_list_name }}</div>
    <div class="section text-center" v-if="loading_list && user_level != 3">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <ul class="listview image-listview inset text" v-if="!loading_list && user_level != 3">
        <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
            <router-link :to="'/campus/' + (user_level + 1) + '/' + sub_level.id">
                <a class="item">
                    <div class="in">
                        <div>
                            <b class="list-title">{{ sub_level.name }}</b>
                            <!-- <footer>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-primary">WS</i>
                                            <span class="chip-label">{{ sub_level.wisdom_count_filled }}/{{
                                            sub_level.wisdom_count
                                            }}</span>
                                        </div>
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-success">YT</i>
                                            <span class="chip-label">{{ sub_level.youth_count_filled }}/{{
                                            sub_level.youth_count
                                            }}</span>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-1">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-danger">ST</i>
                                            <span class="chip-label">{{ sub_level.students_count_filled }}/{{
                                            sub_level.students_count
                                            }}</span>
                                        </div>
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-pink">WM</i>
                                            <span class="chip-label">{{ sub_level.women_count_filled }}/{{
                                            sub_level.women_count
                                            }}</span>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-1">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-pink-dark">GL</i>
                                            <span class="chip-label">{{ sub_level.girls_count_filled }}/{{
                                            sub_level.girls_count
                                            }}</span>
                                        </div>
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-warning">BL</i>
                                            <span class="chip-label">{{ sub_level.balavedi_count_filled }}/{{
                                            sub_level.balavedi_count
                                            }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-secondary">Total</i>
                                    <span class="chip-label">{{ sub_level.members_count_filled }}/{{
                                    sub_level.members_count
                                    }}</span>
                                </div>
                            </footer> -->
                            <!-- <footer v-if="user_level == 1"><span class="badge badge-primary">Total zones : {{
                                    sub_level.zones_count
                            }}
                                </span>
                            </footer>
                            <footer v-if="user_level == 2"><span class="badge badge-primary">Total units : {{
                                    sub_level.units_count
                            }}
                                </span>
                            </footer> -->
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
    </ul>
    <add-members v-if="user_level == 3" :unit_id="$route.params.id" ref="add_member"></add-members>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import $ from 'jquery'
import AddMembers from './MembersList.vue';
export default {
    name: 'Organization',
    components: {
        Form,
        Field,
        ErrorMessage,
        AddMembers
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            name: yup.string().matches(nameRegex, "Please enter name in English").required("Name is required!"),
            mobile_number: yup.string().min(10, 'Must be 10 digits').required("Mobile Number is required!"),
            wing: yup.string().required("Wing is required!"),
        });

        return {
            loading: false,
            loading_list: false,
            message: "",
            schema,
            country_code: "91",
            wing: 3,
            user_level: "",
            sub_levels: [],
            admin_list: [],
            unit: [],
            responseData: {
                common_links: []
            }
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            if (this.user_level == 2) {
                this.sub_level_list_name = 'Campus List';
            }else if (this.user_level == 1) {
                this.sub_level_list_name = 'District List';
            }
            this.getSubLevels();
            this.getComboboxValues('COUNTRY_CODE');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }

                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        openAddMemberModel() {
            this.$refs.add_member.adminAdd();
        },
        addAdmin(user) {
            user.username = user.country_code + user.mobile_number;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = this.currentUser.zone_id;
                user.unit_id = parseInt(this.$route.params.id);
            }

            this.loading = true;
            UserService.addAdmin('register', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide');
                        this.$swal('Admin Saved!', '', 'seccess');
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    }
                    else {

                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    console.log((error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString());
                }
            );

        },

        getSubLevels() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level + 1,
                id: parseInt(this.$route.params.id)
            }
            UserService.getSubLevels('get-campus-sub-levels', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.responseData = response.data.data;
                        this.unit = response.data.data.unit;
                        this.$root.updateParent(response.data.data.page_title);
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminList() {
            let data = {
                id: parseInt(this.$route.params.id)
            }
            UserService.getAdminList('get-admin-list', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.admin_list = response.data.data.list;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        showAddAdminModel() {
            $('#addAdminModel').modal('show');
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/org/' + (this.user_level + 1) + '/' + sub_level_id);
        },
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

.item {
    margin-bottom: 10px;
}

.list-title {
    font-size: 16px;
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    height: 46px !important;
}

.chip-media {
    margin-left: 5px;
    width: 135px !important;
    padding-left: 25px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}
</style>