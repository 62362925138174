<template>
    <div class="section pt-1">
        <div class="wallet-card">
            <!-- Balance -->
            <div class="balance">
                <div class="left">
                    <span class="title" v-html="event_details.name"></span>
                    <h1 class="total text-mal">Food and Bus Report</h1>
                </div>
                <!-- <div class="right">
          <a href="javascript:void(0);" class="button" @click="addNew">
            <ion-icon name="add-outline"></ion-icon>
          </a>
        </div> -->
            </div>
        </div>
    </div>
    <div id="search">
        <form class="search-form">
            <div class="form-group searchbox">
                <select as="select" class="form-control custom-select" v-model="filter_type" name="filter_type" id="select4"
                    @change="getEventDetails(1, search_qry, $event.target.value)">
                    <option value="food">Food Only</option>
                    <option value="bus" selected>All</option>
                </select>
                <!-- <i class="input-icon">
                    <ion-icon name="filter-outline"></ion-icon>
                </i> -->
            </div>
        </form>
    </div>
    <div id="search">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ BUS CODE സെർച്ച് ചെയ്യുക..." v-model="search_qry"
                    @input="getEventDetails(1, search_qry, filter_type)">
                <!-- <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i> -->
            </div>
        </form>
    </div>
    <div class="section" v-if="!loading && responseData.download">
        <span><a :href="responseData.download.all_report" :download="'Vehicle Report'">
                <ion-icon name="download-outline"></ion-icon> Download List PDF
            </a></span><br>
        <span><a :href="responseData.download.summary_report" :download="'Report Summary'">
                <ion-icon name="download-outline"></ion-icon> Download Summary PDF
            </a></span>
    </div>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border" role="status"></div>
    </div>
    <ul class="listview image-listview inset mt-1" v-if="!loading">
        <li v-for="(vehicle, index) in vehciles.data" v-bind:key="vehicle.id">
            <a href="javascript:void(0);" class="item">
                <div class="in">
                    <div>
                        <header class="mal-text text-secondary">വാഹനം {{ vehciles.from + index }} </header>
                        <b class="text-primary mal-text text-bold">{{ vehicle.vehicle_type_name }} - {{
                            vehicle.bus_code
                        }}</b>
                        <footer class="mal-text">
                            <table>
                                <tr>
                                    <th>
                                        ആളുകൾ:
                                    </th>
                                    <th>
                                        <span class="text-bold text-primary">{{ vehicle.total_person }}</span>
                                    </th>
                                </tr>
                                <tr v-if="vehicle.food == 'Y'">
                                    <th>
                                        ഭക്ഷണം:
                                    </th>
                                    <th>
                                        <span class="text-bold text-primary">{{
                                            vehicle.food == 'N' ? 'വേണ്ട' : vehicle.total_packet + ' പാക്കറ്റ്'
                                        }}</span> <span class="text-danger " style="font-size: 14px;"
                                            v-if="vehicle.food == 'Y'"><b class="text-bold"
                                                v-bind:class="vehicle.status == 'Confirmed' || vehicle.status == 'Delivered' ? 'text-success' : 'text-danger'">(Rs
                                                {{ (responseData.price *
                                                    vehicle.total_packet).toFixed(2) }})</b></span>
                                    </th>
                                </tr>
                                <tr v-if="vehicle.food == 'Y'">
                                    <th>
                                        സ്റ്റാറ്റസ്:
                                    </th>
                                    <th>
                                        <span class="text-bold"
                                            v-bind:class="vehicle.status == 'Confirmed' || vehicle.status == 'Delivered' ? 'text-success' : 'text-danger'">{{
                                                vehicle.display_status
                                            }}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        Border Punching:
                                    </th>
                                    <th>
                                        <span class="text-bold text-success"
                                            v-if="vehicle.checkin_platform_parked_time">
                                            {{ vehicle.border_crossing_point }}({{ vehicle.checkin_platform_parked_time
                                            }})</span>
                                        <span class="text-bold text-danger"
                                            v-else>---</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="2">
                                        {{ vehicle.unit.name }},{{ vehicle.zone.name }},{{ vehicle.district.name }}
                                    </th>
                                </tr>
                            </table>

                            <!-- ആളുകൾ: <span class="text-bold text-primary">{{ vehicle.total_person }}</span><br>
              ഭക്ഷണം: <span class="text-bold text-primary">{{
                vehicle.food == 'N' ? 'വേണ്ട' : vehicle.total_packet + ' പാക്കറ്റ്'
              }}</span> <span class="text-danger " style="font-size: 14px;" v-if="vehicle.food == 'Y'"><b
                  class="text-bold">(Rs {{ (vehicle.price * vehicle.total_packet).toFixed(2) }})</b></span><br>
              ഭക്ഷണം സ്റ്റാറ്റസ്: <span class="text-bold text-primary">{{ vehicle.display_status }}</span> -->
                        </footer>
                    </div>

                    <div class="card-button dropdown">
                        <div class="iconbox"
                            v-bind:class="vehicle.status == 'Confirmed' || vehicle.status == 'Delivered' ? 'text-success' : 'text-danger'"
                            style="font-size: 25px">
                            <ion-icon
                                v-bind:name="vehicle.status == 'Confirmed' || vehicle.status == 'Delivered' ? 'checkmark-circle' : 'close-circle'"
                                role="img" class="md hydrated" aria-label="checkmark circle"></ion-icon>
                        </div>
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + vehicle.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + vehicle.id" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li v-if="permissions.bus_checkin_btn">
                                            <a href="javascript:void(0)" data-bs-dismiss="modal" class="btn btn-list"
                                                @click="checkinMarkView(vehicle)">
                                                <span>
                                                    <ion-icon name="flag-outline"></ion-icon> Bus Border Checkin
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="vehicle.download_url">
                                            <a :href="vehicle.download_url" target="_blank" download="bus_number.pdf"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="print-outline"></ion-icon> Print Bus Number
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="permissions.food_deliver_btn">
                                            <a href="javascript:void(0)" data-bs-dismiss="modal" class="btn btn-list"
                                                @click="deliveredConfirmation(vehicle)">
                                                <span>
                                                    <ion-icon name="fast-food-outline"></ion-icon> Food Delivered
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" @click="editData(vehicle)" class="btn btn-list"
                                                data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> View
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'tel:' + vehicle.leader_mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'https://wa.me/' + vehicle.leader_mobile + '?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="permissions.delete_vehicle">
                                            <a href="javascript:void(0)" @click="deleteDataConfirmation(vehicle)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="vehciles.data">
        <vue-awesome-paginate v-if="vehciles.total > 0" :total-items="vehciles.total" :items-per-page="vehciles.per_page"
            :max-pages-shown="3" v-model="vehciles.current_page" :on-click="getEventDetails" :show-ending-buttons="true"
            :show-breakpoint-buttons="false" />
    </div>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text custom-title">Border checked vehicles list</h3>
        </div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading && responseData.checked_vehicles">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Bus</th>
                            <th scope="col">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="bus in responseData.checked_vehicles" v-bind:key="bus">
                            <td class="text-primary font-dark">
                                {{ bus.bus_code }}
                            </td>
                            <td class="text-primary font-dark">
                                {{ bus.checkin_platform_parked_time }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal fade action-sheet" id="addNewModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addNewData" :validation-schema="schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <input type="hidden" v-model="editable_id" />
                                    <label class="label text-primary" for="stay"><span>Payment Status (Admin
                                            Only)</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="status" name="status"
                                        id="select4">
                                        <option value>--Select--</option>
                                        <option value="Created">ബുക്ക് ചെയ്തു, ക്യാഷ് അടച്ചിട്ടില്ല.</option>
                                        <option value="Confirmed">ക്യാഷ് അടച്ചു. ബുക്കിംഗ് സ്വീകരിച്ചു.</option>
                                        <option value="Updated">ബുക്കിംഗ് എണ്ണം മാറിയിട്ടുണ്ട്. ബാക്കി ക്യാഷ് അടച്ച്
                                            ബുക്കിംഗ് ഉറപ്പാക്കുക.</option>
                                    </Field>
                                    <ErrorMessage name="vehicle_type" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="paid_amount"><span>Paid
                                            Amount</span></label>
                                    <Field name="paid_amount" type="number" class="form-control" v-model="paid_amount"
                                        placeholder="" autocomplete="off" />
                                    <ErrorMessage name="paid_amount" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <input type="hidden" v-model="editable_id" />
                                    <label class="label mal-text text-primary" for="stay"><span>വാഹനത്തിന്റെ
                                            ഇനം</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="vehicle_type"
                                        name="vehicle_type" id="select4" @change="changeDep">
                                        <option value>--Select--</option>
                                        <option v-for="vehicle_type in vehicle_type_list" v-bind:key="vehicle_type"
                                            v-bind:value="vehicle_type.id">
                                            {{ vehicle_type.name }}
                                        </option>
                                    </Field>
                                    <ErrorMessage name="vehicle_type" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="total_person"><span>ആളുകളുടെ
                                            എണ്ണം</span></label>
                                    <Field name="total_person" type="number" class="form-control" v-model="total_person"
                                        placeholder="" autocomplete="off" />
                                    <ErrorMessage name="total_person" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="vehicle_type && vehicle_type != '1024'">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="stay"><span>ഭക്ഷണം
                                            വേണോ?</span></label>
                                    <div class="form-check form-check-inline">
                                        <Field type="radio" class="form-check-input" value="Y" name="food" id="food_1"
                                            v-model="food" />
                                        <label class="form-check-label" for="food_1">വേണം</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <Field type="radio" class="form-check-input" value="N" name="food" id="food_2"
                                            v-model="food" />
                                        <label class="form-check-label" for="food_2">വേണ്ട</label>
                                    </div>
                                </div>
                                <ErrorMessage name="food" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="food == 'Y' && vehicle_type && vehicle_type != '1024'">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="total_packet"><span>എത്ര
                                            പാക്കറ്റ്</span></label>
                                    <Field name="total_packet" type="number" class="form-control" v-model="total_packet"
                                        placeholder="" autocomplete="off" />
                                    <ErrorMessage name="total_packet" class="error-feedback" />
                                </div>
                            </div>
                            <!-- <div class="form-group basic" v-if="food == 'Y' && vehicle_type && vehicle_type != '1024'">
                                <div class="input-wrapper">
                                    <input type="hidden" v-model="editable_id" />
                                    <label class="label mal-text text-primary" for="stay"><span>ഭക്ഷണം കൈപ്പറ്റാൻ
                                            ഉദ്ദേശിക്കുന്ന സ്ഥലം
                                            സെലക്ട് ചെയ്യുക.(ഡ്രൈവറുമായി ചർച്ച ചെയ്തു തീരുമാനിക്കുക)</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="food_pickup_point"
                                        name="food_pickup_point" id="select4" @change="changeDep">
                                        <option value>--Select--</option>
                                        <option v-for="food_pickup_point in food_pickup_point_list"
                                            v-bind:key="food_pickup_point" v-bind:value="food_pickup_point.id">
                                            {{ food_pickup_point.name }}
                                        </option>
                                    </Field>
                                    <ErrorMessage name="food_pickup_point" class="error-feedback" />
                                </div>
                            </div> -->
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="leader"><span>അമീറിന്റെ
                                            പേര്</span></label>
                                    <Field name="leader_name" type="text" class="form-control" v-model="leader_name"
                                        placeholder="" autocomplete="off" />
                                    <ErrorMessage name="leader" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary"
                                        for="leader_mobile"><span>മൊബൈൽ</span></label>
                                    <Field name="leader_mobile" type="number" class="form-control" v-model="leader_mobile"
                                        placeholder="" autocomplete="off" />
                                    <ErrorMessage name="leader_mobile" class="error-feedback" />
                                </div>
                            </div>
                            <span class="title">താഴെ ഉള്ള വിവരങ്ങൾ ഇല്ലെങ്കിൽ പിന്നീട് നൽകിയാലും മതി.</span>

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="leader_2_name"><span>ഡ്രൈവറുടെ
                                            പേര്</span></label>
                                    <Field name="leader_2_name" type="text" class="form-control" v-model="leader_2_name"
                                        placeholder="" autocomplete="off" />
                                    <ErrorMessage name="leader_2_name" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary"
                                        for="leader_2_mobile"><span>മൊബൈൽ</span></label>
                                    <Field name="leader_2_mobile" type="number" class="form-control"
                                        v-model="leader_2_mobile" placeholder="" autocomplete="off" />
                                    <ErrorMessage name="leader_2_mobile" class="error-feedback" />
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btn_loading"
                                    v-if="permissions.update_vehicle_details">
                                    <span v-show="btn_loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!permissions.update_vehicle_details" @click="closeNewAddModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade action-sheet" id="checkinPointModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Checkin Point</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="checkingMark" :validation-schema="checkinPoint">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <input type="hidden" v-model="editable_id" />
                                    <label class="label text-primary" for="punching_point"><span>Punching
                                            Point</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="punching_point"
                                        name="punching_point" id="select4">
                                        <option value>--Select--</option>
                                        <option value="POINT 1">Bismi Electricals (Point 1)</option>
                                        <option value="POINT 2">Honda Mini (Point 2)</option>
                                        <!-- <option value="POINT 3">POINT 3</option> -->
                                    </Field>
                                    <ErrorMessage name="punching_point" class="error-feedback" />
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btn_loading"
                                    v-if="permissions.update_vehicle_details">
                                    <span v-show="btn_loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!permissions.update_vehicle_details" @click="closeNewAddModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import * as yup from "yup";
import UserService from "../../../services/user.service";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null,
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({

            paid_amount: yup.string().required("ഫിൽ ചെയ്യുക"),
            // food_pickup_point: yup.string().when('food', {
            //     is: value => value && value === "Y",
            //     then: yup
            //         .string()
            //         .required("ഫിൽ ചെയ്യുക")
            // }),
            total_packet: yup.string().when('food', {
                is: value => value && value === "Y",
                then: yup
                    .string()
                    .required("ഫിൽ ചെയ്യുക")
            })
        });
        return {
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],

            loading: false,
            btn_loading: false,
            schema,
            event_details: {
                event: [],
            },
            permissions: [],
            custom_message: "",
            volunteer_department: "",
            volunteer_ready: "",
            vehicle_type_list: [],
            food_pickup_point_list: [],
            food_pickup_point: '',
            food: '',
            total_person: '',
            total_packet: '',
            leader_name: '',
            leader_mobile: '',
            leader_2_name: '',
            leader_2_mobile: '',
            vehciles: [],
            responseData: {
                download: [],
                checked_vehicles: []
            },

            search_qry: '',
            filter_type: 'food'
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        }
        this.$root.updateParent("Bus Details");
        this.getEventDetails(1, null, this.filter_type);
        this.getComboboxValues("EVENT_VEHICLE_TYPES");
        this.getComboboxValues("WIC_FOOD_PICKUP_PONIT");
        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },

    methods: {
        getEventDetails(page = null, q = null, filter_type = null) {
            this.loading = true;
            let data = {
                slug: this.$route.params.slug,
                event_id: this.$route.params.event_id,
                report_type: "bus_details_list",
                q: q ? q : this.search_qry,
                page: page,
                filter_type: filter_type ? filter_type : this.filter_type
            };
            UserService.authPostRequest(`get-event-report`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.responseData = response.data.data;
                        this.permissions = response.data.data.permissions;
                        this.vehciles = response.data.data.vehicles;
                        this.event_details = response.data.data.event_details;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
                member_id: this.member_id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "EVENT_VEHICLE_TYPES":
                            this.vehicle_type_list = response.data.data.list.options;
                            break;
                        case "WIC_FOOD_PICKUP_PONIT":
                            this.food_pickup_point_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeDep() {
            this.volunteer_department_message = "";
        },
        updateVolunteer(data) {
            this.btn_loading = true;
            if (!data.volunteer_department && this.volunteer_ready == "Y") {
                this.volunteer_department_message = "ഫിൽ ചെയ്യുക";
                this.btn_loading = false;
            } else {
                this.volunteer_department_message = "";
                data.event_id = this.event_details.id;
                data.member_id = this.member_id;
                UserService.authPostRequest(`store-event-volunteer`, data).then(
                    (response) => {
                        if (response.data.status == "success") {
                            this.alert_type = "Success";
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                        } else if (response.data.status == "warning") {
                            this.alert_type = "Warning";
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                        } else {
                            this.alert_type = "Error";
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                        }
                    },
                    (error) => {
                        this.alert_type = "Error";
                        this.alert_message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    }
                );
            }
        },
        addNew() {
            this.editable_id = null;
            this.vehicle_type = '';
            this.total_person = '';
            this.food = '';
            this.total_packet = '';
            this.leader_name = '';
            this.leader_mobile = '';
            this.leader_2_name = '';
            this.leader_2_mobile = '';
            $("#addNewModel").modal("show");
        },
        closeNewAddModel() {
            $("#addNewModel").modal("hide");
        },
        editData(data) {
            this.editable_id = data.id;
            this.vehicle_type = data.vehicle_type;
            this.total_person = data.total_person;
            this.food = data.food;
            this.total_packet = data.total_packet;
            this.leader_name = data.leader_name;
            this.leader_mobile = data.leader_mobile;
            this.leader_2_name = data.leader_2_name;
            this.leader_2_mobile = data.leader_2_mobile;
            this.food_pickup_point = data.food_pickup_point ? data.food_pickup_point : '';
            this.status = data.status;
            this.paid_amount = data.paid_amount;
            $("#addNewModel").modal("show");
        },
        addNewData(data) {
            this.btn_loading = true;
            data.event_id = this.event_details.id;
            data.editable_id = this.editable_id;
            data.user_level = 1;
            UserService.authPostRequest(`store-event-bus-details`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getEventDetails();
                        this.closeNewAddModel();
                        this.btn_loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    }
                },
                (error) => {
                    this.alert_type = "Error";
                    this.alert_message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btn_loading = false;
                }
            );
        },
        deliveredConfirmation(vehicle_detail) {
            var data = {
                id: vehicle_detail.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ഫുഡ് ഡെലിവർ മാർക്ക് ചെയ്യണോ?';
            this.dialog_action = 'deliverFood';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        deliverFood(data) {
            data.event_id = this.event_details.id;
            data.editable_id = this.id;
            UserService.authPostRequest(`food-deliver-event-bus-details`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getEventDetails();
                        this.btn_loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    }
                },
                (error) => {
                    this.alert_type = "Error";
                    this.alert_message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btn_loading = false;
                }
            );
        },
        deleteDataConfirmation(vehicle_detail) {
            var data = {
                id: vehicle_detail.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'deleteData';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        deleteData(data) {
            data.event_id = this.event_details.id;
            data.editable_id = this.editable_id;
            UserService.authPostRequest(`delete-event-bus-details`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getEventDetails();
                        this.closeNewAddModel();
                        this.btn_loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    }
                },
                (error) => {
                    this.alert_type = "Error";
                    this.alert_message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btn_loading = false;
                }
            );
        },
        checkinMarkView(data) {
            this.editable_id = data.id;
            $("#checkinPointModel").modal("show");
        },
        checkinMarkConfirmation(vehicle_detail) {
            var data = {
                id: this.editable_id,
                checkin_point: vehicle_detail.punching_point
            }
            this.dialog_action_params = data;
            this.dialog_message = 'വാഹനം ചെക്കിന് ചെയ്യണോ?';
            this.dialog_action = 'checkingMark';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        checkingMark(data) {
            data.event_id = this.event_details.id;
            data.editable_id = this.id;
            data.id = this.editable_id;
            data.type = 'checkin';
            UserService.authPostRequest(`food-deliver-event-bus-details`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getEventDetails();
                        $("#checkinPointModel").modal("hide");
                        this.btn_loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    }
                },
                (error) => {
                    this.alert_type = "Error";
                    this.alert_message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btn_loading = false;
                }
            );
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.searchbox .form-control {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #bdbdd5;
    height: 36px;
    padding: 0 10px 0 10px;
}

.form-check {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    min-height: auto;
    height: auto;
    padding-bottom: 5px;
}

.input-group .input-group-text {
    background: transparent;
    border: 0;
    border: 1px solid #1a418c;
    /* border-top: 1px solid #1a418c; */
    border-radius: 5px 5px 5px 5px;
    font-weight: 400;
    color: #27173e;
    height: auto;
    width: auto;
    padding: 0px 10px 0px 10px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(209 26 26 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
    position: relative;
    z-index: 1;
}

.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    height: auto;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .total {
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1em;
    font-size: 24px;
}

.wallet-card .balance .title {
    color: #27173e;
    font-weight: 500;
    font-size: 14px;
    display: block;
    margin-bottom: 0px;
}

.text-bold {
    font-weight: 600;
}
</style>
