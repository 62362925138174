<template>
    <!-- App Sidebar -->
    <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <!-- profile box -->
                    <div class="profileBox pt-2 pb-2" v-if="currentUser">
                        <div class="image-wrapper">
                            <img src="assets/icons_blue/icon-192x192.png" alt="image" class="imaged  w36"
                                v-if="profile.avatar == 'member.jpg' || profile.avatar == null">
                            <img :src="'https://guide.msoftit.com/' + profile.avatar" alt="image" class="imaged  w36"
                                v-else>
                        </div>
                        <div class="in" @click="goToUrl('home')" data-bs-dismiss="modal">
                            <strong>{{ currentUser.name }}</strong>
                            <div class="text-muted">{{ currentUser.phone }}</div>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-link btn-icon sidebar-close"
                            data-bs-dismiss="modal">
                            <ion-icon name="close-outline"></ion-icon>
                        </a>
                    </div>
                    <div class="profileBox pt-2 pb-2" v-else>
                        <div class="image-wrapper">
                            <img src="assets/icons_blue/icon-192x192.png" alt="image" class="imaged  w36">
                        </div>
                        <!-- <div class="in" @click="goToUrl('user-login')" data-bs-dismiss="modal">
                            <strong>Login</strong>
                            <div class="text-muted">or Create new</div>
                        </div> -->
                        <a href="javascript:void(0)" class="btn btn-link btn-icon sidebar-close"
                            data-bs-dismiss="modal">
                            <ion-icon name="close-outline"></ion-icon>
                        </a>
                    </div>
                    <!-- * profile box -->
                    <!-- balance -->
                    <!-- <div class="sidebar-balance">
                        <div class="listview-title">Balance</div>
                        <div class="in">
                            <h1 class="amount">$ 2,562.50</h1>
                        </div>
                    </div> -->
                    <!-- * balance -->



                    <!-- menu -->
                    <div class="listview-title mt-1">Menu</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="javascript:void(0)" class="item" @click="goToUrl('/')" data-bs-dismiss="modal">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="home-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Home
                                    <span class="badge badge-primary"></span>
                                </div>
                            </a>
                        </li>
                        <li v-if="!currentUser">
                            <a href="javascript:void(0)" class="item" @click="goToUrl('user-login')"
                                data-bs-dismiss="modal">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="log-in-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Login
                                    <span class="badge badge-primary"></span>
                                </div>
                            </a>
                        </li>
                        <li v-if="currentUser">
                            <a href="javascript:void(0)" class="item" @click="logout()" data-bs-dismiss="modal">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="log-out-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Logout
                                    <span class="badge badge-primary"></span>
                                </div>
                            </a>
                        </li>
                        <li v-if="!currentUser">
                            <a href="javascript:void(0)" class="item" @click="goToUrl('register')"
                                data-bs-dismiss="modal">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="person-add-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Create new account
                                    <span class="badge badge-primary"></span>
                                </div>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="app-pages.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="document-text-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Pages
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-components.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="apps-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Components
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-cards.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    My Cards
                                </div>
                            </a>
                        </li> -->
                    </ul>
                    <!-- * menu -->

                </div>
            </div>
        </div>
    </div>
    <!-- * App Sidebar -->
</template>
<script>
import AuthService from "../../services/auth.service";
export default {
    data() {
        return {
            profile: []
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (this.currentUser) {
            this.profile = JSON.parse(localStorage.getItem('my_profile'));
        }
    },
    methods: {
        goToUrl(url) {
            this.$router.push(url);
        },
        logout() {
            AuthService.logout();
            localStorage.activeMenu = 'Home';
            location.replace('/');
        }
    },
};
</script>