<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">പ്രോഗ്രാം ആക്ടിവിറ്റി റിപ്പോർട്ട്</h3>
        </div>
    </div>
    <div class="section inset mt-1 mb-1" v-if="currentUser.user_level <= 3">
        <div class="accordion" id="accordionExample2">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion001" aria-expanded="false">
                        <ion-icon name="filter-outline" role="img" class="md hydrated"
                            aria-label="wallet outline"></ion-icon>
                        Filter
                    </button>
                </h2>
                <div id="accordion001" class="accordion-collapse collapse" data-bs-parent="#accordionExample2" style="">
                    <div class="accordion-body">
                        <div id="search" style="padding: 0px !important;">
                            <div class="form-group" v-if="currentUser.user_level <= 2">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_by">Filter By</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_by"
                                        name="filter_by" id="select4" @change="getProgramList($event.target.value)">
                                        <option value>---All---</option>
                                        <option value="2" v-if="currentUser.user_level == 1">District</option>
                                        <option value="3">Zone</option>
                                        <option value="4">Unit</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level == 1">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_district">District</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_district"
                                        name="filter_district" id="select4" @change="getZoneList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="district in district_list" v-bind:value="district.id"
                                            v-bind:key="district.id">{{
                                                district.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 2">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_zone">Zone</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_zone"
                                        name="filter_zone" id="select4" @change="getUnitList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="zone in zone_list" v-bind:value="zone.id" v-bind:key="zone.id">{{
                                            zone.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 3">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_unit">Unit</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_unit"
                                        name="filter_unit" id="select4">
                                        <option value>---All---</option>
                                        <option v-for="unit in unit_list" v-bind:value="unit.id" v-bind:key="unit.id">{{
                                            unit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group" v-if="currentUser.user_level <= 3">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_program">Programs</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_program"
                                        name="filter_program" id="select4">
                                        <option value>---All---</option>
                                        <option v-for="programItem in programItemList" v-bind:value="programItem.id"
                                            v-bind:key="programItem.id">{{
                                                programItem.name_ml }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <button type="button" class="btn btn-danger btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="clearFilter()">
                                        <ion-icon name="trash-bin-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Clear
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="getMembersList(null, null, 'local')">
                                        <ion-icon name="search-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Activity</h5>
                    <h5 class="modal-title" v-if="add_admin">Activity</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <!-- <div class="table-responsive"> -->
                            <table>
                                <tr>
                                    <th class="text-primary text-mal">പ്രോഗ്രാം</th>
                                    <th class="text-dark">: {{program_item}}</th>
                                </tr>
                                <tr>
                                    <th class="text-primary text-mal">തിയ്യതി</th>
                                    <th class="text-dark">: {{program_date}}</th>
                                </tr>
                                <tr>
                                    <th class="text-primary text-mal">സ്ഥലം</th>
                                    <th class="text-dark">: {{program_place}}</th>
                                </tr>
                                <tr>
                                    <th class="text-primary text-mal">ചുമതല</th>
                                    <th class="text-dark">: {{program_duty}}</th>
                                </tr>
                                <tr>
                                    <th class="text-primary text-mal">പ്രാസംഗികൻ</th>
                                    <th class="text-dark">: {{program_item}}</th>
                                </tr>
                                <tr v-for="item in custom_columns" v-bind:key="item.id">
                                    <th class="text-primary">{{item.field?item.field.title:''}}</th>
                                    <th class="text-dark" v-if="item.multiple == 1">:
                                        <span v-for="(s_item,index) in JSON.parse(item.value)" :key="s_item">
                                        {{s_item}} {{ JSON.parse(item.value).length == (index+1)?'':',' }}
                                    </span>
                                    </th>
                                    <th class="text-dark" v-else>: {{item.value}}</th>
                                </tr>
                            </table>
                        <!-- </div> -->
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DeleteMemberModel -->

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-0">
        <h4 class="text-center mt-1 text-danger" v-if="!loading_list && member_lists.total <= 0">No Data.</h4>
    </div>
    <!-- <div id="search" v-if="!loading_list">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
                    @input="filteredMembers()">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div> -->
    <!-- <div class="section" v-if="!loading_list">
        <span><a :href="'https://batch.msoftit.com/reports/pdf/reportZonalCouncil.php?zone_id=' + unit.id + '&wing=6&mobile='+currentUser.phone"
                :download="'Girls Zonal Council'">
                <ion-icon name="download-outline"></ion-icon> Download PDF
            </a></span>
    </div> -->
    <ul class="listview image-listview inset text mt-2" v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                <!-- <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <span class="button verified" v-if="member_list.member.verified == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified" v-if="member_list.member.verified == 'N' && member_list.member.verification_status">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div> -->
                <div class="in">
                    <div class="min-width-50">
                        <header class="mal-text text-danger" style="font-size: 14px;"><b>{{ member_list.program?member_list.program.name_ml:''
                        }}</b></header>
                        <!-- <b> {{ member_list.date }} | {{ member_list.place }}</b> -->
                        <b>
                            <table>
                                <tr>
                                    <th colspan="2" class="text-info text-mal">{{ member_list.user_level ==
                                        2 ? 'ജില്ല' : member_list.user_level == 3 ? 'മണ്ഡലം' : 'ശാഖ' }} തലം പ്രോഗ്രാം</th>
                                </tr>
                                <tr>
                                    <th class="text-primary text-mal">{{ member_list.user_level ==
                                        2 ? 'ജില്ല' : member_list.user_level == 3 ? 'മണ്ഡലം' : 'ശാഖ' }}</th>
                                    <td>:
                                        {{ member_list.user_level ==
                                            2 ? (member_list.district ? member_list.district.name : '') : member_list.user_level
                                                ==
                                                3 ? (member_list.zone ? member_list.zone.name : '') : (member_list.unit ?
                                                    member_list.unit.name : '')
                                        }}</td>
                                </tr>
                                <tr v-if="member_list.date">
                                    <th class="text-primary text-mal">തിയതി </th>
                                    <td>: {{ member_list.date }} </td>
                                </tr>
                                <tr v-if="member_list.place">
                                    <th class="text-primary text-mal">സ്ഥലം </th>
                                    <td>: {{ member_list.place }} </td>
                                </tr>
                                <tr v-if="member_list.duty">
                                    <th class="text-primary text-mal">ചുമതല </th>
                                    <td>: {{ member_list.duty }} </td>
                                </tr>
                                <tr v-if="member_list.faculty">
                                    <th class="text-primary text-mal">പ്രാസംഗികൻ</th>
                                    <td>: {{ member_list.faculty }} </td>
                                </tr>
                            </table>
                        </b>
                        <!-- <footer class="text-primary">{{ member_list.faculty }}
                        </footer> -->
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <!-- <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li> -->
                                        <li>
                                            <a href="javascript:void(0)" @click="adminView(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="eye-outline"></ion-icon> View
                                                </span>
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li> -->
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total"
            :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page"
            :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            level_name: '',

            programItemList: [],
            program_item: '',
            selected_program_item: [],

            district_list: [],
            zone_list: [],
            unit_list: [],

            filter_district: '',
            filter_zone: '',
            filter_unit: '',
            filter_program: '',
            filter_by: '',
            custom_columns:[]
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.currentUser.user_level);
            if (this.user_level == 4) {
                this.user_level_id = parseInt(this.currentUser.unit_id);
            } else if (this.user_level == 3) {
                this.user_level_id = parseInt(this.currentUser.zone_id);
            } else if (this.user_level == 2) {
                this.user_level_id = parseInt(this.currentUser.district_id);
            } else if (this.user_level == 1) {
                this.user_level_id = 0;
            }
            if (this.user_level == 1) {
                this.getDistrictList();
            }
            if (this.user_level == 2) {
                this.getZoneList(this.currentUser.district_id);
            }
            this.getMembersList(1);
            this.$root.updateParent('Activity Tracking');
        }
        this.getComboboxValues('COUNTRY_CODE');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            // this.$router.push('add-activity-tracking/'+admin.id);
            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            this.program_item = admin.program.name_ml;
            this.program_date = admin.date;
            this.program_place = admin.place;
            this.program_duty = admin.duty;
            this.program_faculty = admin.faculty;
            this.custom_columns = admin.options;
            console.log(admin.options);
            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.program_date = '';
            this.program_place = '';
            this.program_duty = '';
            this.program_faculty = '';
            this.program_area = '';
            this.editable_id = null;
            this.add_admin = true;
            this.edit_admin = false;
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            user.editable_id = this.editable_id;
            user.program_id = this.program_item;
            UserService.addAdmin('store-activity', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delate-activity', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        getMembersList(page = 1) {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                user_level_id: this.user_level_id,
                filter_district: this.filter_district ? this.filter_district : '',
                filter_zone: this.filter_zone ? this.filter_zone : '',
                filter_unit: this.filter_unit ? this.filter_unit : '',
                filter_program: this.filter_program ? this.filter_program : '',
                filter_by: this.filter_by ? this.filter_by : '',
            }
            UserService.authPostRequest(`get-activity-report?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.permissions = response.data.data.permissions;
                        this.member_lists = response.data.data.list;
                        this.level_name = response.data.data.level_name;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getProgramList(user_level) {
            this.loading_list = true;
            let data = {
                user_level: user_level,
                wing: this.currentUser.wing
            }
            UserService.authPostRequest(`get-program-list`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.programItemList = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        showActionModel() {
            this.pin_number = '';
        },
        getFields(program_list) {
            this.programItemList.forEach(element => {
                if (element.id == program_list) {
                    this.selected_program_item = element;
                }
            });
        },
        getDistrictList() {
            // this.loading_list = true;
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getZoneList(district_id) {
            // this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getUnitList(zone_id) {
            // this.loading_list = true;
            let data = {
                zone_id: zone_id
            }
            UserService.authPostRequest(`get-unit-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.unit_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clearFilter() {
            this.filter_district = '';
            this.filter_zone = '';
            this.filter_unit = '';
            this.filter_by = '';
            this.filter_program = '';
            this.getMembersList();
        },

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
}

.paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
}

.paginate-buttons:hover {
    background-color: #d8d8d8;
}

.active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
}

.active-page:hover {
    background-color: #2988c8;
}
</style>