<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">വിസ്‌ഡം റിലീഫ് പദ്ധതി തിരിച്ചുള്ള ലിസ്റ്റ്</h3>
        </div>
    </div>
    <div class="section">
        <div class="row mt-1">
            <div class="col-6" v-if="filter_type && permissions.indicate_target">
                <div class="stat-box mt-1">
                    <div class="title mal-text">രജിസ്റ്റർ ചെയ്യേണ്ട എണ്ണം</div>
                    <div class="value text-primary">{{ unit.target ? unit.target : '--' }}</div>
                </div>
            </div>
            <div class="col-6" v-if="filter_type">
                <div class="stat-box mt-1">
                    <div class="title mal-text">രജിസ്റ്റർ ചെയ്തവർ</div>
                    <div class="value text-info">{{ member_lists?member_lists.total:'00' }}</div>
                </div>
            </div>
        <!-- </div>
        <div class="row mt-1"> -->
            <div class="col-6" v-if="filter_type && permissions.indicate_target">
                <div class="stat-box mt-1">
                    <div class="title mal-text">അനുവദിച്ചത്</div>
                    <div class="value text-warning">{{ unit.allotted ? unit.allotted : '--' }}</div>
                </div>
            </div>
            <div class="col-6" v-if="filter_type">
                <div class="stat-box mt-1">
                    <div class="title mal-text">വിതരണം ചെയ്തത്</div>
                    <div class="value text-success">{{ unit.distributed ? unit.distributed : '--' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div id="search" v-if="user_level == 2">
        <form class="search-form">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>Type</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="type" name="type" id="select4" @change="getMembersList(1, filter_type,filter_reason,search_qry,$event.target.value)">
                                    <option value="All">All</option>
                                    <option value="District" selected>District Entry</option>
                                </select>
                <i class="input-icon">
                                    <ion-icon name="filter-outline"></ion-icon>
                                </i>
            </div>
        </form>
    </div>
    <div id="search">
        <form class="search-form">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>റിലീഫ് പദ്ധതി</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_type" name="filter_type" id="select4" @change="getMembersList(1, $event.target.value,filter_reason,search_qry)">
                                    <option value>All</option>
                                    <option v-for="type in relief_type_list" v-bind:value="type.id" v-bind:key="type.id">{{type.name_mal}}</option>
                                </select>
                <i class="input-icon">
                                    <ion-icon name="filter-outline"></ion-icon>
                                </i>
            </div>
        </form>
    </div>
    <div id="search">
        <form class="search-form">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>ലിസ്റ്റിൽ ഉൾപ്പെടുത്താനുള്ള കാരണം</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_reason" name="filter_type" id="select4" @change="getMembersList(1, filter_type,$event.target.value,search_qry)">
                                    <option value>All</option>
                                    <option v-for="type in relief_reason_list" v-bind:value="type.id" v-bind:key="type.id">{{type.name_mal}}</option>
                                </select>
                <i class="input-icon">
                                    <ion-icon name="filter-outline"></ion-icon>
                                </i>
            </div>
        </form>
    </div>
    <div id="search">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qry"
                    @input="getMembersList(1, filter_type,filter_reason,$event.target.value)">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-2 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">ലിസ്റ്റ്</h3>
        </div>
    </div>
    <div class="section text-primary" v-if="permissions.result_download">
        <span><a :href="download" :download="'Result'">
                                <ion-icon name="download-outline"></ion-icon> Download
                            </a></span>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2">
        <h4 class="text-center mt-0 mal-text text-danger" v-if="!loading_list && member_lists.total <= 0 && filter_type">ഇതുവരെ ആരെയും ചേർത്തിട്ടില്ല
        </h4>
    </div>
    <div class="section mt-2">
        <h4 class="text-center mt-0 mal-text text-danger" v-if="!filter_type">ലിസ്റ്റും അനുവദിച്ച എണ്ണവും കാണാൻ റിലീഫ് പദ്ധതി സെലക്ട് ചെയ്യുക
        </h4>
    </div>
    
    <ul class="listview image-listview inset text mt-2" v-if="!loading_list && member_lists.total > 0 && filter_type">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id">
            <a href="javascript:void(0)" class="item">
                                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                                <div class="avatar-section">
                                    <a href="#">
                                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                                        <span class="button verified" v-if="permissions.indicate_target && (member_lists.from + index) <= target">
                                            <ion-icon name="checkmark-outline"></ion-icon>
                                        </span>
                                        <span class="button not-verified" v-if="permissions.indicate_target && (member_lists.from + index) > target">
                                            <ion-icon name="close-outline"></ion-icon>
                                        </span>
                                    </a>
            </div>
            <div class="in">
                <div class="min-width-50">
                    <header>{{getFormattedDate(member_list.created_at)}}</header>
                    <b> {{ member_list.family_head_name }} <span v-if="member_list.child_age">({{ member_list.child_age }}y,{{ member_list.child_sex }})</span>  </b>
                    <footer class="text-primary">
                        <span v-if="member_list.parent_name">Parent: {{ member_list.parent_name }} <br> </span>
                        <span v-if="member_list.family_contact">Contact: {{ member_list.family_contact }} <br> </span>
                        <span v-if="member_list.family">Unit: {{ member_list.family.unit?member_list.family.unit.name:'' }} <br> </span>
                        <span v-if="member_list.family">Zone: {{ member_list.family.zone?member_list.family.zone.name:'' }} <br> </span>
                        <span v-if="member_list" class="text-danger">Added: {{ member_list.family?member_list.family.added_by_name:member_list.added_by_name }} </span>
                        <span v-if="member_list.this_year_deliveries.length > 0" class="mal-text text-success"><br> കൊടുത്തത്: <span v-for="(given_item,index) in member_list.this_year_deliveries" :key="given_item.id">{{given_item.relief_type.name_mal}} {{member_list.this_year_deliveries.length == index+1 ? '':','}}</span></span>
                    </footer>
                </div>
                <div class="card-button dropdown">
                    <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal" :data-bs-target="'#actionSheetInset' + member_list.id+'-'+member_list.child_id" @click="showActionModel">
                                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                                        </button>
                </div>
    
                <!-- Default Action Sheet Inset -->
                <div class="modal fade action-sheet" :id="'actionSheetInset'+member_list.id +'-'+member_list.child_id" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Action</h5>
                            </div>
                            <div class="modal-body">
                                <ul class="action-button-list">
                                    <li>
                                        <a href="javascript:void(0)" class="btn btn-list" @click="showUpdateForm(member_list)" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="checkmark-done-outline"></ion-icon> Update Status
                                                    </span>
                                                </a>
                                    </li>
                                    <li>
                                        <a :href="'tel:' + member_list.family_contact" class="btn btn-list">
                                                    <span>
                                                        <ion-icon name="call-outline"></ion-icon> Call
                                                    </span>
                                                </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal" @click="adminEdit(member_list)">
                                                    <span>
                                                        <ion-icon name="pencil-outline"></ion-icon> Edit
                                                    </span>
                                                </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)" class="btn btn-list" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="trash-outline"></ion-icon> Remove
                                                    </span>
                                                </a>
                                    </li>
                                    <li class="action-divider"></li>
                                    <li>
                                        <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="close-outline"></ion-icon> Cancel
                                                    </span>
                                                </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- * Default Action Sheet Inset -->
            </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total" :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page" :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false"
        />
    </div>
    
    <div class="modal fade action-sheet" id="updateStatusModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Status</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="card">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Type</th>
                                            <th scope="col">Date</th>
                                            <th scope="col" v-if="permissions.coupen">Coupon</th>
                                            <th scope="col" v-if="permissions.shop">Shop</th>
                                            <th scope="col"></th>
    
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in relief_deliveries" :key="item.id">
                                            <td class="text-primary font-dark">
                                                {{ item.relief_type.name_mal }}({{ item.year }})
                                            </td>
                                            <td class="text-primary font-dark">
                                                {{ item.delivery_date }}
                                            </td>
                                            <td class="text-primary font-dark" v-if="permissions.coupen">
                                                {{ item.coupon_no }}
                                            </td>
                                            <td class="text-primary font-dark" v-if="permissions.shop">
                                                {{ item.relief_shop?item.relief_shop.shop_name:'' }}
                                            </td>
                                            <td class="text-primary font-dark">
                                                <button type="button" class="btn btn-link btn-icon"  @click="deleteDeliveryConfirmation(item.id)">
                                                    <ion-icon name="trash-outline"></ion-icon>
                                                </button> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr>
                        <div class="">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="stay"><span>റിലീഫ് പദ്ധതി</span></label>
                                    <select as="select" class="form-control custom-select" :disabled="true" v-model="form_relief_type" name="form_relief_type" id="select4">
                                                    <option value>---Select---</option>
                                                    <option v-for="type in relief_type_list"  v-bind:value="type.id" v-bind:key="type.id">{{type.name_mal}}</option>
                                                </select>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="this.permissions.coupen">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="coupon_no">
                                            കൂപ്പൺ നമ്പർ <button class="badge badge-primary"  @click="generateCopenCode()">Generate Coupen Code</button>
                                                <span class="text-danger">*</span>
                                              </label>
                                    <input name="coupon_no" type="text" class="form-control" disabled="true" v-model="form_coupon_no" placeholder="Coupon No" autocomplete="off" />
                                    <ErrorMessage name="family_leader_name" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="this.permissions.shop">
                                <div class="input-wrapper">
                                    <label class="label mal-text text-primary" for="form_relief_shop"><span>ഷോപ്</span>
                                        <span class="text-danger">*</span></label>
                                    <select as="select" class="form-control custom-select" v-model="form_relief_shop" name="form_relief_shop" id="select4">
                                                    <option value>---Select---</option>
                                                    <option v-for="type in relief_shop_list"  v-bind:value="type.id" v-bind:key="type.id">{{type.shop_name}}</option>
                                                </select>
                                </div>
                            </div>
                            <a href="javascript:void(0)" class="btn btn-primary btn-lg col-6" :disabled="loading" @click="updateStatus()">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span>
                                </a>
                            <a href="javascript:void(0)" class="btn btn-secondary btn-lg col-6 cancel-btn" @click="closeModel()" data-bs-dismiss="modal">
                                      Cancel
                                </a>
                            <!-- <div class="form-group basic">
                                        <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                            v-if="editable || add_admin">
                                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                            <span>Save</span></button>
                                        <button type="button" class="btn btn-primary btn-block btn-lg"
                                            v-if="!editable && !add_admin" @click="closeAddAdminModel">Close</button>
                                    </div> -->
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import moment from 'moment'
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_type: '',
            isMember: 'Y',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            download: '',
            relief_reason_list: [],
            relief_type_list: [],
            filter_reason: '',
            form_relief_type: '',
            selected_relief_family: [],
            relief_deliveries: [],
            relief_shop_list:[],
            target: 0,
            search_qry:'',
            form_coupon_no:'',
            type:'District'
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList(1);
            this.$root.updateParent('Loading...');
        }
        this.getComboboxValues("RELIEF_TYPE");
        this.getComboboxValues("RELIEF_REASON");
        window.onpopstate = function() {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getFormattedDate(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        getEpoch(date) {
            return moment(date).valueOf();
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'RELIEF_TYPE':
                            this.relief_type_list = response.data.data.list.options;
                            break;
                        case "RELIEF_REASON":
                            this.relief_reason_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getMembersList(page = 1, filter_type = null, filter_reason = null,search_qry = null,type = null) {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                unit_id: parseInt(this.$route.params.id),
                filter_type: filter_type ? filter_type : this.filter_type,
                filter_reason: filter_reason ? filter_reason : this.filter_reason,
                search_qry: search_qry ? search_qry : this.search_qry,
                type: type ? type : this.type
            }
            UserService.authPostRequest(`get-relief-families?type=new&&page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                    
                        this.target = response.data.data.target;
                        this.permissions = response.data.data.permissions;
                        this.download = response.data.data.result_download_url;
                        this.member_lists = response.data.data.list;
                        this.relief_shop_list = response.data.data.shop_list;
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        this.loading_list = false;
                        this.$root.updateParent('വിസ്‌ഡം റിലീഫ് ലിസ്റ്റ്');

                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
                type : admin_list.family?'child':'family'
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delete-relief-family', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminEdit(member_list) {
            let id = '';
            if (member_list.family_id) {
                id = member_list.family_id;
            } else {
                id = member_list.id;
            }
            this.$router.push('/org/' + this.$route.params.sub_level + '/' + this.$route.params.id + '/relief-entry/' + id);
        },
        showUpdateForm(member_list = null) {
            this.selected_relief_family = member_list;
            this.relief_deliveries = member_list.deliveries;
            this.form_relief_type = this.filter_type;
            this.form_relief_shop = '';
            this.form_coupon_no = '';
            $('#updateStatusModel').modal('show');
        },
        closeModel() {
            $("#updateStatusModel").modal("hide");
        },
        updateStatus() {
            let data = {
                relief_type: this.form_relief_type,
                relief_family_id: this.selected_relief_family.id,
                relief_family_type: this.selected_relief_family.family_id ? 'student' : 'family',
                relief_shop : this.form_relief_shop,
                relief_coupen : this.form_coupon_no,
            }
            UserService.authPostRequest('update-relief-status', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $("#updateStatusModel").modal("hide");
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, this.filter_type, this.filter_reason);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        deleteDeliveryConfirmation(id) {
            var data = {
                id: id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'deleteDelivery';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        deleteDelivery(data) {
            UserService.authPostRequest('delete-relief-delivery', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        // this.closeModel();
                        this.relief_deliveries = [];
                        this.getMembersList(1);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        generateCopenCode() {
            let data = {
                relief_type: this.form_relief_type,
                relief_family_id: this.selected_relief_family.id,
                relief_family_type: this.selected_relief_family.family_id ? 'student' : 'family'
            }
            UserService.authPostRequest('generate-coupen-code', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.form_coupon_no = response.data.data;
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.cancel-btn {
    left: 4% !important;
}
</style>