<template>
    <div class="section wallet-card-section pt-1">
        <div class="wallet-card">
            <div class="balance">
                <div class="left">
                    <span class="title mal-text">ഇലക്ഷൻ ഷെഡ്യൂൾ റിപ്പോർട്ട് </span>
                    <p class="text-primary">Total Results: {{sub_levels.total}}<br>
                        <span class="text-secondary" v-for="(filter,index) in filters"
                            v-bind:key="filter">{{filter.filter}} :
                            {{filter.display}} {{(filters.length == index+1) ? '' :'| '}}</span>
                    </p>

                </div>
                <div class="right">
                    <a href="#" class="button" data-bs-toggle="modal" data-bs-target="#filterModel">
                        <ion-icon name="funnel-outline"></ion-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 custom-title">Unit Election Schedules <br>Page :
                {{sub_levels.current_page}}</h3>
            <button type="button" class="btn btn-primary btn-block btn-lg" data-bs-toggle="modal"
                data-bs-target="#filterModel">
                <span>Filter</span></button>

        </div>
    </div> -->

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <div class="section mt-2">
        <!-- <div class="section-title">Today</div> -->
        <div class="transactions">
            <!-- item -->
            <vue-awesome-paginate v-if="sub_levels.total > 0" :total-items="sub_levels.total"
                :items-per-page="sub_levels.per_page" :max-pages-shown="3" v-model="sub_levels.current_page"
                :on-click="getReport" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
            <a href="javascript:void(0)" class="item row" v-for="sub_level in sub_levels.data" v-bind:key="sub_level.id"
                @click="goToSublevelReport(sub_level.zone.id)">
                <div class="detail col-6">
                    <!-- <img src="assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48"> -->
                    <div>
                        <strong class="mal-text" v-if="sub_level.zone">{{sub_level.zone.name_mal}}</strong>
                        <p v-if="sub_level.district">ജില്ല: {{sub_level.district.name}}</p>
                    </div>
                </div>
                <div class="right text-end col-6" v-if="status_filter != '0'">
                    <div class="price text-primary">Date : {{sub_level.election_date}} <br>
                        Time : {{sub_level.election_time}} <br>
                        സ്ഥലം : {{sub_level.election_location}}</div>
                </div>
                <div class="col-12" v-if="status_filter != '0' && sub_level.zone">
                    Election Status :
                    <span class="badge badge-secondary mal-text" v-if="sub_level.zone.election_status_level <= 1">
                        ഷെഡ്യൂൾ ചെയ്തു
                    </span>
                    <span class="badge badge-primary mal-text" v-if="sub_level.zone.election_status_level == 2">
                        ജില്ലയിലേക്ക് അയച്ചു
                    </span>
                    <span class="badge badge-success mal-text" v-if="sub_level.zone.election_status_level == 3">
                        ജില്ല സ്വീകരിച്ചു
                    </span>
                    <span class="badge badge-danger mal-text" v-if="sub_level.zone.election_status_level == 4">
                        ജില്ല റിജെക്ട് ചെയ്യ്തു
                    </span>
                </div>
            </a>
            <!-- * item -->
            <vue-awesome-paginate v-if="sub_levels.total > 0" :total-items="sub_levels.total"
                :items-per-page="sub_levels.per_page" :max-pages-shown="3" v-model="sub_levels.current_page"
                :on-click="getReport" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
        </div>

    </div>
    <!-- filterModel -->
    <div class="modal fade action-sheet" id="filterModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Filter</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group" v-if="message_delete">
                            <div v-if="message_delete" class="error-feedback">
                                {{ message_delete }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <div class="form-group boxed">
                                <label class="label" for="stay"><span class="mal-text">ഇലക്ഷൻ സ്റ്റാറ്റസ്</span></label>
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select mal-text"
                                        v-model="status_filter" name="status_filter"
                                        @change="changeElectionStatus($event.target.value)" id="select4">
                                        <option value="1">ഷെഡ്യൂൾ ചെയ്തു</option>
                                        <option value="2">ജില്ലയിലേക്ക് അയച്ചു</option>
                                        <option value="3">ജില്ല സ്വീകരിച്ചു</option>
                                        <option value="4">ജില്ല റിജെക്ട് ചെയ്യ്തു</option>
                                        <option value="0">ഷെഡ്യൂൾ ചെയ്തിട്ടില്ല</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group boxed" v-if="date_filter_show">
                                <label class="label" for="stay"><span class="mal-text">തിയതി</span></label>
                                <div class="input-wrapper">
                                    <input type="date" class="form-control" name="date_filter" id="text4b" v-model="date_filter"/>
                                    <!-- <select as="select" class="form-control custom-select" v-model="date_filter"
                                        name="date_filter" id="select4">
                                        <option value="">All Date</option>
                                        <option value="2022-09-25">Sep 25</option>
                                        <option value="2022-09-26">Sep 26</option>
                                        <option value="2022-09-27">Sep 27</option>
                                        <option value="2022-09-28">Sep 28</option>
                                        <option value="2022-09-29">Sep 29</option>
                                        <option value="2022-09-30">Sep 30</option>
                                        <option value="2022-10-01">Oct 01</option>
                                        <option value="2022-10-02">Oct 02</option>
                                        <option value="2022-10-03">Oct 03</option>
                                        <option value="2022-10-04">Oct 04</option>
                                        <option value="2022-10-05">Oct 05</option>
                                        <option value="2022-10-06">Oct 06</option>
                                        <option value="2022-10-07">Oct 07</option>
                                        <option value="2022-10-08">Oct 08</option>
                                        <option value="2022-10-09">Oct 09</option>
                                        <option value="2022-10-10">Oct 10</option>
                                        <option value="2022-10-11">Oct 11</option>
                                        <option value="2022-10-12">Oct 12</option>
                                        <option value="2022-10-13">Oct 13</option>
                                        <option value="2022-10-14">Oct 14</option>
                                        <option value="2022-10-15">Oct 15</option>
                                    </select> -->
                                </div>
                            </div>

                            <div class="form-group boxed" v-if="user_level == 1">
                                <label class="label" for="stay"><span class="mal-text">ജില്ല</span></label>
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="district_filter"
                                        name="district_filter" id="select4" @change="getZoneList($event.target.value)">
                                        <option value="">All</option>
                                        <option v-for="item in district_list" :value="item.id" v-bind:key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="form-group boxed" v-if="user_level <= 2">
                                <label class="label" for="stay"><span class="mal-text">മണ്ഡലം</span></label>
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="zone_filter"
                                        name="zone_filter" id="select4">
                                        <option value="">All</option>
                                        <option v-for="item in zone_list" :value="item.id" v-bind:key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                </div>
                            </div> -->
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="filterList()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading" data-bs-dismiss="modal">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Filter</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * AddMemberModel -->

</template>
<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
export default {
    name: 'Reports',
    components: {
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            mobile_number: '',
            wing: '',
            user_level: "",
            sub_levels: [],
            admin_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            district: '',
            total: 0.00,
            date_filter: '',
            status_filter: '1',
            district_filter: '',
            zone_filter: '',
            district_list: [],
            zone_list: [],
            filters: [],
            date_filter_show: true

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.user_level_id = parseInt(this.$route.params.id);
            this.getReport();
            if (this.user_level == 1) {
                this.getDistrictList();
            } else if (this.user_level == 2) {
                this.getZoneList(this.user_level_id);
            }
            this.$root.updateParent('മണ്ഡലം ഇലക്ഷൻ ഷെഡ്യൂൾ റിപ്പോർട്ട്');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getReport(page = 1) {
            this.loading_list = true;
            let data = {
                user_level_id: this.user_level_id,
                user_level: this.user_level,
                date: this.date_filter,
                status: this.status_filter,
                zone_id: this.zone_filter,
                district_id: this.district_filter,
            }
            UserService.authPostRequest(`get-election-scheduled-report?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.filters = response.data.data.filters;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getDistrictList() {
            this.loading_list = true;
            let data = {
            }
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    if (error)
                        this.loading_list = true;
                    this.district_list = [];
                }
            );
        },
        getZoneList(district_id) {
            this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    if (error)
                        this.loading_list = true;
                    this.zone_list = [];
                }
            );
        },
        filterList() {
            $('#filterModel').modal('hide');
            this.getReport(1);
        },
        changeElectionStatus(status) {
            if (status == 0) {
                this.date_filter = '',
                    this.date_filter_show = false
            } else {
                this.date_filter_show = true
            }
        },
        goToSublevelReport(id) {
            this.$router.push('/org/3/' + id + '/election-schedule-details');
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 5px;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.font-dark {
    font-weight: 500;
}

.total-size {
    font-size: 18px;
}

.report-btn-success {
    width: 100%;
}


.wallet-footer-user {
    height: 46px !important;
}

.chip-media {
    margin-left: 5px;
    width: 135px !important;
    padding-left: 25px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.btn-icon {
    height: 22px !important;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
}

.paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
}

.paginate-buttons:hover {
    background-color: #d8d8d8;
}

.active-page {
    background-color: #3498db !important;
    border: 1px solid #3498db !important;
    color: white !important;
}

.active-page:hover {
    background-color: #2988c8;
}

.wallet-card {
    padding: 15px 24px !important;
}

.wallet-card-section:before {
    height: 60px !important;
}
</style>