<template>
    <!-- Wallet Card -->
    <div class="section pt-1">
        <div class="wallet-card">
            <!-- Balance -->
            <div class="balance">
                <div class="left">
                    <span class="title mal-text">പ്രോഗ്രാം ആക്ടിവിറ്റി ട്രാക്കിംഗ്</span>
                    <h1 class="total text-primary" style="font-size: 16px;">{{ level_name }}</h1>
                </div>
                <div class="right row">
                    <a href="/#/org/activity-tracking-report" class="button col-6 mr-1" v-if="currentUser.user_level != 4">
                        <ion-icon name="list-outline"></ion-icon>
                    </a>
                    <a href="/#/org/add-activity-tracking" class="button col-6">
                        <ion-icon name="add-outline"></ion-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="section">
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ചേർത്തത്</div>
                    <div class="value text-success">{{ member_lists.total }}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ബാക്കിയുള്ളത്</div>
                    <div class="value text-danger">{{ minusToZero ? minusToZero : '0' }}</div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Wallet Card -->
    <!-- <div class="section pt-2">
        <div class="wallet-card mt-1" v-if="currentUser.user_level <= 4">
            <div class="wallet-footer wallet-footer-user row">
                <div class="item col-12">
                    <a href="javascript:void(0)" @click="adminAdd">
                        <div class="icon-wrapper back-color-blue1">
                            <ion-icon name="add-outline"></ion-icon>
                            <strong class="menu-title mal-text">നേർപഥം ചേർക്കാൻ ഇവിടെ അമർത്തുക</strong>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div> -->
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">പ്രോഗ്രാം ആക്ടിവിറ്റി ലിസ്റ്റ്</h3>
        </div>
    </div>

    <!-- attendanceModel -->
    <div class="modal fade action-sheet" id="attendanceModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Council Attendance</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group" v-if="attendance_message">
                            <div v-if="attendance_message" class="error-feedback">
                                {{ attendance_message }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="stay"><span>Attendance</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="attendance"
                                        name="attendance" id="select4">
                                        <option value="P">Present</option>
                                        <option value="A">Absend</option>
                                        <option value="L">Leave</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                        </div>
                        <div class="form-group basic" v-if="attendance == 'A' || attendance == 'L'">
                            <label class="label" for="stay"><span>Remarks</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <div class="form-group boxed">
                                        <input name="attendance_remarks" type="text" class="form-control"
                                            v-model="attendance_remarks" placeholder="Remarks" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="markAttandance()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit Activity</h5>
                    <h5 class="modal-title" v-if="add_admin">Add Activity</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="program_item"><span>പ്രോഗ്രാം</span></label>
                                <select as="select" class="form-control custom-select" v-model="program_item"
                                    name="program_item" id="select4" @change="getFields(program_item)">
                                    <option value="Y">---Select---</option>
                                    <option v-for="programItem in programItemList" v-bind:value="programItem.id"
                                        v-bind:key="programItem.id">{{
                                            programItem.name_ml }}
                                    </option>
                                </select>
                                <ErrorMessage name="member" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="program_status"><span>പ്രോഗ്രാം സ്റ്റാറ്റസ്</span></label>
                                <select as="select" class="form-control custom-select" v-model="program_status"
                                    name="program_status" id="select4">
                                    <option value="Upcoming">Upcoming(വരാനിരിക്കുന്നത്)</option>
                                    <option value="Done">Done(കഴിഞ്ഞു)</option>
                                    <option value="Cancelled">Cancelled(ഒഴിവാക്കി)</option>
                                </select>
                            </div>
                            <div class="form-group basic" v-if="selected_program_item.date">
                                <label class="label text-mal" for="date"><span>തിയ്യതി</span></label>
                                <div class="form-group">
                                    <Field name="program_date" type="date" class="form-control" v-model="program_date"
                                        autocomplete="off" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="selected_program_item.place">
                                <label class="label text-mal" for="place"><span>സ്ഥലം</span></label>
                                <div class="form-group">
                                    <Field name="program_place" type="text" class="form-control" v-model="program_place"
                                        autocomplete="off" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="selected_program_item.duty">
                                <label class="label text-mal" for="duty"><span>ചുമതല</span></label>
                                <div class="form-group">
                                    <Field name="program_duty" type="text" class="form-control" v-model="program_duty"
                                        autocomplete="off" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="selected_program_item.faculty">
                                <label class="label text-mal" for="faculty"><span>പ്രാസംഗികൻ</span></label>
                                <div class="form-group">
                                    <Field name="program_faculty" type="text" class="form-control" v-model="program_faculty"
                                        autocomplete="off" />
                                </div>
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="permissions.edit_activity">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!permissions.edit_activity" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DeleteMemberModel -->

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-0">
        <h4 class="text-center mt-1 text-danger" v-if="!loading_list && member_lists.total <= 0">No Data.</h4>
    </div>
    <!-- <div id="search" v-if="!loading_list">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
                    @input="filteredMembers()">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div> -->
    <!-- <div class="section" v-if="!loading_list">
        <span><a :href="'https://batch.msoftit.com/reports/pdf/reportZonalCouncil.php?zone_id=' + unit.id + '&wing=6&mobile='+currentUser.phone"
                :download="'Girls Zonal Council'">
                <ion-icon name="download-outline"></ion-icon> Download PDF
            </a></span>
    </div> -->
    <ul class="listview image-listview inset text mt-2" v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                <!-- <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <span class="button verified" v-if="member_list.member.verified == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified" v-if="member_list.member.verified == 'N' && member_list.member.verification_status">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div> -->
                <div class="in">
                    <div class="min-width-50">
                        <header class="mal-text text-danger" style="font-size: 14px;"><b>{{ member_list.program.name_ml
                        }}</b></header>
                        <!-- <b> {{ member_list.date }} | {{ member_list.place }}</b> -->
                        <b>
                            <table>
                                <tr v-if="member_list.date">
                                    <th class="text-primary text-mal">സ്റ്റാറ്റസ് </th>
                                    <td>: {{ member_list.status }} </td>
                                </tr>
                                <tr v-if="member_list.date">
                                    <th class="text-primary text-mal">തിയതി </th>
                                    <td>: {{ member_list.date }} </td>
                                </tr>
                                <tr v-if="member_list.place">
                                    <th class="text-primary text-mal">സ്ഥലം </th>
                                    <td>: {{ member_list.place }} </td>
                                </tr>
                                <tr v-if="member_list.duty">
                                    <th class="text-primary text-mal">ചുമതല </th>
                                    <td>: {{ member_list.duty }} </td>
                                </tr>
                                <tr v-if="member_list.faculty">
                                    <th class="text-primary text-mal">പ്രാസംഗികൻ</th>
                                    <td>: {{ member_list.faculty }} </td>
                                </tr>
                            </table>
                        </b>
                        <!-- <footer class="text-primary">{{ member_list.faculty }}
                        </footer> -->
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <!-- <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li> -->
                                        <li>
                                            <a href="javascript:void(0)" @click="adminView(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total"
            :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page"
            :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            level_name: '',

            programItemList: [],
            program_item: '',
            selected_program_item: [],
            program_status: '',
            program_date: '',
            program_place: '',
            program_duty: '',
            program_faculty: '',
            program_area: ''

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.currentUser.user_level);
            if (this.user_level == 4) {
                this.user_level_id = parseInt(this.currentUser.unit_id);
            } else if (this.user_level == 3) {
                this.user_level_id = parseInt(this.currentUser.zone_id);
            } else if (this.user_level == 2) {
                this.user_level_id = parseInt(this.currentUser.district_id);
            } else if (this.user_level == 1) {
                this.user_level_id = 0;
            }
            this.getProgramList();
            this.getMembersList(1);
            this.$root.updateParent('Activity Tracking');
        }
        this.getComboboxValues('COUNTRY_CODE');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            this.$router.push('add-activity-tracking/'+admin.id);
            // $('#addAdminModel').modal('show');
            // this.message = '';
            // this.isEdit = true;
            // this.getFields(admin.org_program_id);
            // this.program_item = admin.org_program_id;
            // this.program_date = admin.date;
            // this.program_place = admin.place;
            // this.program_duty = admin.duty;
            // this.program_faculty = admin.faculty;
            // this.program_status = admin.status;
            // this.editable_id = admin.id;
            // this.add_admin = false;
            // this.edit_admin = true;
        },
        adminAdd() {
            this.$router.push('add-activity-tracking');
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            user.editable_id = this.editable_id;
            user.program_id = this.program_item;
            user.program_status = this.program_status;
            UserService.addAdmin('store-activity', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delate-activity', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        getMembersList(page = 1) {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                user_level_id: this.user_level_id
            }
            UserService.authPostRequest(`get-activity-list?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.permissions = response.data.data.permissions;
                        this.member_lists = response.data.data.list;
                        this.level_name = response.data.data.level_name;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getProgramList() {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                wing: this.currentUser.wing
            }
            UserService.authPostRequest(`get-program-list`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.programItemList = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        showActionModel() {
            this.pin_number = '';
        },
        getFields(program_list) {
            this.programItemList.forEach(element => {
                if (element.id == program_list) {
                    this.selected_program_item = element;
                }
            });
        }

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
}

.paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
}

.paginate-buttons:hover {
    background-color: #d8d8d8;
}

.active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
}

.active-page:hover {
    background-color: #2988c8;
}
</style>