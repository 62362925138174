<template>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section pt-1" v-if="!loading">
        <div class="wallet-card">
            <div class="balance">
                <div class="left">
                    <h1 class="total text-primary text-center" v-html="event_details.name"></h1>
                    <span class="title text-center" v-html="event_details.place"></span>
                    <span class="title text-center" v-html="event_details.date"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="section mt-1" v-if="!loading && volunteer_message">
        <div class="card">
            <div class="card-body">
                {{ volunteer_message }}
            </div>
        </div>
    </div>
    <div class="section mt-1" v-if="!loading">
        <Form @submit="updateVolunteer" :validation-schema="schema">
            <div class="wallet-card mt-1">
                <div class="form-group basic" v-if="member_list.length > 1">
                    <div class="input-wrapper">
                        <label class="label" for="stay"><span>Member</span></label>
                        <Field as="select" class="form-control custom-select" v-model="member_id" name="member_id"
                            id="select4" @change="changeMember">
                            <option value>--Select--</option>
                            <option v-for="member in member_list" v-bind:key="member.id" v-bind:value="member.id">
                                {{ member.name }}
                            </option>
                        </Field>
                        <ErrorMessage name="member_id" class="error-feedback" />
                    </div>
                </div>

                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label mal-text" for="stay"><span>വളണ്ടിയർ ആകാൻ തയ്യാറാണോ?</span></label>
                        <Field as="select" class="form-control custom-select" v-model="volunteer_ready"
                            name="volunteer_ready" id="select4" @change="readyChange">
                            <option value="Y">അതെ</option>
                            <option value="N">അല്ല</option>
                        </Field>
                        <ErrorMessage name="volunteer_ready" class="error-feedback" />
                    </div>
                </div>
                <div class="form-group basic" v-if="volunteer_ready == 'Y'">
                    <div class="input-wrapper">
                        <label class="label" for="stay"><span>വളണ്ടിയർ ആകാൻ താല്പര്യമുള്ള വകുപ്പ്
                                തിരഞ്ഞെടുക്കുക.</span></label>
                        <Field as="select" class="form-control custom-select" v-model="volunteer_department"
                            name="volunteer_department" id="select4" @change="changeDep">
                            <option value>--Select--</option>
                            <option v-for="department in department_list" v-bind:key="department"
                                v-bind:value="department.id">
                                {{ department.name }}
                            </option>
                        </Field>
                        <span id="volunteer_department" class="error-feedback">{{
                            volunteer_department_message
                        }}</span>
                    </div>
                </div>
                <div class="form-group basic" v-if="volunteer_ready == 'Y'">
                    <div class="input-wrapper">
                        <label class="label" for="stay"><span>നിങ്ങൾക്കു എത്തിച്ചേരാൻ കഴിയുന്ന ദിവസം?</span></label>
                        <!-- <Field as="select" class="form-control custom-select" v-model="volunteer_time" name="volunteer_time"
                            id="select4">
                            <option value>--Select--</option>
                            <option v-for="time in time_list" v-bind:key="time" v-bind:value="time">
                                {{ time }}
                            </option>
                        </Field> -->
                        <div class="input-group d-flex align-items-center mt-1">
                            <div class="form-check form-check-inline" v-for="item in time_list" :key="item"
                                >
                                <Field class="form-check-input" name="volunteer_time" type="checkbox"
                                    :id="'time' + item" :value="item" v-model="volunteer_time" />
                                <label class="form-check-label" :for="'time' + item">{{ item }}
                                </label>
                            </div>
                        </div>
                        <span id="volunteer_time" class="error-feedback">{{
                            volunteer_time_message
                        }}</span>
                    </div>
                </div>
                <div class="form-group basic" v-if="volunteer_ready == 'Y'">
                    <div class="input-wrapper">
                        <label class="label" for="stay"><span>താങ്കൾക്കുള്ള ശാരീരിക പ്രയാസങ്ങൾ?</span></label>
                        <!-- <Field as="select" class="form-control custom-select" v-model="health_status" name="health_status"
                            id="select4">
                            <option value>--Select--</option>
                            <option v-for="health_status in health_status_list" v-bind:key="health_status" v-bind:value="health_status">
                                {{ health_status }}
                            </option>
                        </Field> -->
                        <div class="input-group d-flex align-items-center mt-1">
                            <div class="form-check form-check-inline" v-for="item in health_status_list" :key="item"
                                >
                                <Field class="form-check-input" name="health_status" type="checkbox"
                                    :id="'jealth' + item" :value="item" v-model="health_status" />
                                <label class="form-check-label" :for="'jealth' + item">{{ item }}
                                </label>
                            </div>
                        </div>
                        <span id="health_status" class="error-feedback">{{
                            health_status_message
                        }}</span>
                    </div>
                </div>

            </div>
            <button type="submit" class="btn btn-primary btn-block btn-md mt-1" :disabled="btn_loading"
                v-if="permissions.update_volunteer">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span>Save</span>
            </button>
            <button type="button" class="btn btn-primary btn-block btn-md mt-1" v-if="!permissions.update_volunteer">
                Close
            </button>
        </Form>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import $ from "jquery";
import * as yup from "yup";
import UserService from "../../../services/user.service";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null,
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({
            volunteer_department: yup.string().required("ഫിൽ ചെയ്യുക"),
        });
        return {
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],

            loading: false,
            btn_loading: false,
            schema,
            event_details: {
                event: [],
            },
            permissions: [],
            volunteer_message: "",
            volunteer_department: "",
            volunteer_ready: "",
            department_list: [],
            member_list: [],
            member_id: "",
            volunteer_department_message: "",
            volunteer_time_message: "",
            time_list: [],
            volunteer_time: [],
            health_status: [],
            health_status_list: [],
            health_status_message: "",


        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        }
        this.$root.updateParent("Volunteer Registration");
        this.getEventDetails();
        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },

    methods: {
        changeMember(member) {
            if (member == 4 || member == 6) {
                this.getComboboxValues(this.event_details.event_capital + "_VDL");
            } else {
                this.getComboboxValues(this.event_details.event_capital + "_VDG");
            }
        },
        getEventDetails() {
            this.loading = true;
            let data = {
                slug: this.$route.params.slug,
                page: "volunteer",
            };
            UserService.authPostRequest(`get-event-details`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.event_details = response.data.data.event_details;
                        this.time_list = response.data.data.volunteer_times;
                        this.health_status_list = response.data.data.volunteer_health_status;
                        this.permissions = response.data.data.permissions;
                        this.volunteer_message = response.data.data.message;
                        this.member_list = response.data.data.members;
                        if (response.data.data.volunteer) {
                            this.member_id = response.data.data.volunteer.member_id;
                            this.volunteer_ready = "Y";
                            this.volunteer_department = response.data.data.volunteer.department_id;
                            this.volunteer_time = JSON.parse(response.data.data.volunteer.time);
                            this.health_status = JSON.parse(response.data.data.volunteer.health_status);
                        }
                        if (this.member_list.length == 1) {
                            this.member_id = this.member_list[0].id;
                            let wing = this.member_list[0].wing;
                            if (wing == 4 || wing == 6) {
                                this.getComboboxValues(this.event_details.event_capital + "_VDL");
                            } else {
                                this.getComboboxValues(this.event_details.event_capital + "_VDG");
                            }
                        } else {
                            this.member_list.forEach((element) => {
                                if (element.mobileSelf == "Y") {
                                    this.member_id = element.id;
                                    let wing = element.wing;
                                    if (wing == 4 || wing == 6) {
                                        this.getComboboxValues(this.event_details.event_capital + "_VDL");
                                    } else {
                                        this.getComboboxValues(this.event_details.event_capital + "_VDG");
                                    }
                                }
                            });
                        }
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
                member_id: this.member_id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case this.event_details.event_capital + "_VDL":
                            this.department_list = response.data.data.list.options;
                            break;
                        case this.event_details.event_capital + "_VDG":
                            this.department_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeDep() {
            this.volunteer_department_message = "";
        },
        updateVolunteer(data) {
            this.btn_loading = true;
            if (!data.volunteer_department && this.volunteer_ready == 'Y') {
                this.volunteer_department_message = "ഫിൽ ചെയ്യുക";
                this.btn_loading = false;
            } else if (!this.volunteer_time.length && this.volunteer_ready == 'Y') {
                this.volunteer_time_message = "ഫിൽ ചെയ്യുക";
                this.btn_loading = false;
            }else if (!this.health_status.length && this.volunteer_ready == 'Y') {
                this.health_status_message = "ഫിൽ ചെയ്യുക";
                this.btn_loading = false;
            } else {
                this.volunteer_department_message = "";
                data.event_id = this.event_details.id;
                data.member_id = this.member_id;
                UserService.authPostRequest(`store-event-volunteer`, data).then(
                    (response) => {
                        if (response.data.status == "success") {
                            this.alert_type = "Success";
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                        } else if (response.data.status == "warning") {
                            this.alert_type = "Warning";
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                        } else {
                            this.alert_type = "Error";
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.btn_loading = false;
                        }
                    },
                    (error) => {
                        this.alert_type = "Error";
                        this.alert_message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    }
                );
            }
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.searchbox .form-control {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #bdbdd5;
    height: 36px;
    padding: 0 10px 0 10px;
}

.form-check {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    min-height: auto;
    height: auto;
    padding-bottom: 5px;
}

.input-group .input-group-text {
    background: transparent;
    border: 0;
    border: 1px solid #1a418c;
    /* border-top: 1px solid #1a418c; */
    border-radius: 5px 5px 5px 5px;
    font-weight: 400;
    color: #27173e;
    height: auto;
    width: auto;
    padding: 0px 10px 0px 10px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(209 26 26 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
    position: relative;
    z-index: 1;
}

.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    height: auto;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .total {
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1em;
    font-size: 24px;
}

.wallet-card .balance .title {
    color: #27173e;
    font-weight: 500;
    font-size: 14px;
    display: block;
    margin-bottom: 0px;
}
</style>
