<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mal-text mt-1 custom-title">സി ആർ ഇ സെന്റർ ലിസ്റ്റ്</h3>
        </div>
    </div>
    <div id="search">
        <form class="search-form">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>Status</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_type" name="filter_type" id="select4"
                    @change="getMeetings">
                    <option value>All</option>
                    <option value="Approved">Approved</option>
                    <option value="Pending Approval">Pending Approval</option>
                </select>
                <i class="input-icon">
                    <ion-icon name="filter-outline"></ion-icon>
                </i>
            </div>
        </form>
        <form class="search-form"
            v-if="responseData.permissions.district_filter || $route.params.sub_level == 1 || (currentUser.wing == 3 && (currentUser.testing_user == 'Y' || currentUser.testing_user == '5388Y'))">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>District</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_district" name="filter_district"
                    id="select4" @change="getMeetings">
                    <option value>All</option>
                    <option v-for="district in district_list" v-bind:key="district.id" v-bind:value="district.id">
                        {{ district.name }}
                    </option>
                </select>
                <i class="input-icon"><ion-icon name="filter-outline"></ion-icon></i>
            </div>
        </form>
        <form class="search-form">
            <label class="label text-primary" style="margin-bottom:0px;" for="stay"><span>Faculty</span></label>
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_faculty" name="filter_faculty"
                    id="select4" @change="getMeetings">
                    <option value>All</option>
                    <option value="1">Added</option>
                    <option value="2">Not Added</option>
                </select>
                <i class="input-icon">
                    <ion-icon name="filter-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-3" v-if="responseData.centers.total == 0 && !loading">
        <h3 class="text-center mt-2 text-primary">No Data
        </h3>
    </div>
    <div class="section pb-2" v-if="!loading">
        <div class="card pl-2 pr-2 mt-1" v-for="requestData in responseData.centers.data" v-bind:key="requestData.id">
            <div class="listed-detail mt-1">
                <h3 class="text-center mt-1">{{ requestData.center_name }}</h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-1">
                <li>
                    <strong>Status</strong>
                    <span
                        :class="requestData.approve_status == 'Pending Approval' ? 'text-warning' : 'text-success'">{{ requestData.approve_status }}</span>
                </li>
                <li>
                    <strong>Level</strong>
                    <span class="text-primary">{{ requestData.user_level == 3 ? requestData.zone.name : requestData.unit.name }}
                        {{ requestData.user_level == 3 ? 'Zone' : ' Unit' }}</span>
                </li>
                <li>
                    <strong>Cordinator</strong>
                    <span class="text-primary text-right" v-if="requestData.student_cordinator">{{ requestData.student_cordinator.name }} <br>
                        <a :href="'tel:' + requestData.student_cordinator.mobile">
                            <span class="text-primary">
                                <ion-icon name="call-outline"></ion-icon>
                            </span>
                        </a> &nbsp;
                        <a
                            :href="'https://wa.me/' + requestData.student_cordinator.whatsapp_country_code + requestData.student_cordinator.whatsapp + '?text='">
                            <span class="text-success">
                                <ion-icon name="logo-whatsapp"></ion-icon>
                            </span>
                        </a>
                    </span>
                </li>
                <li>
                    <strong>Faculty</strong>
                    <span class="text-primary text-right" v-if="requestData.faculty">{{ requestData.faculty.name }}<br>
                        <a :href="'tel:' + requestData.faculty.mobile">
                            <span class="text-primary">
                                <ion-icon name="call-outline"></ion-icon>
                            </span>
                        </a> &nbsp;
                        <a v-if="requestData.faculty.whatsapp_country_code && requestData.faculty.whatsapp"
                            :href="'https://wa.me/' + requestData.faculty.whatsapp_country_code + requestData.faculty.whatsapp + '?text='">
                            <span class="text-success">
                                <ion-icon name="logo-whatsapp"></ion-icon>
                            </span>
                        </a>
                    </span>
                    <span class="text-danger" v-else>Not Added
                    </span>
                </li>
                <li>
                    <strong>Added By</strong>
                    <span class="text-primary">{{ requestData.added_by_name }}</span>
                </li>
                <li>
                    <strong>Action</strong>
                    <span><router-link :to="'/org/' + user_level + '/' + user_level_id + '/view-cre-center/' + requestData.id"
                            class="text-danger">View</router-link></span>
                </li>
            </ul>
        </div>
    </div>

    <div class="section mt-2" v-if="!loading">
        <vue-awesome-paginate v-if="responseData.centers.total > 0" :total-items="responseData.centers.total"
            :items-per-page="responseData.centers.per_page" :max-pages-shown="3" v-model="responseData.centers.current_page"
            :on-click="getMeetings" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
// import { Form, Field } from "vee-validate";
import UserService from "../../services/user.service";
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import moment from 'moment'
export default {
    name: 'Organization',
    components: {
        // Form,
        // Field,
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            tabHome: true,
            tabUpcoming: false,
            tabOld: false,
            responseData: {
                centers: [],
                meeting: [],
                participants: [],
                agenda: [],
                minutes: [],
                list: [],
                permissions: []
            },

            message: "",
            user_level: "",
            permissions: [],
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
            unit: [],
            nerpatham_target: '',
            sub_level_id: '',
            request_type_list: [],
            sub_level: [],
            filter_type: '',
            filter_faculty: '',
            district_list: [],
            zone_list: [],
            unit_list: [],

            filter_district: '',
            filter_zone: '',
            filter_unit: '',
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = this.currentUser.user_level;
            this.$root.updateParent("Loading...");
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getDistrictList();
            this.getMeetings();
        }
    },
    methods: {
        getFormattedDate(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        getDistrictList() {
            this.loading_list = true;
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    if (error)
                        this.loading_list = true;
                    this.district_list = [];
                }
            );
        },
        getMeetings(page = 1) {
            this.loading = true;
            let data = {
                user_level_id: parseInt(this.$route.params.id),
                user_level: parseInt(this.$route.params.sub_level),
                district_id: this.filter_district,
                status: this.filter_type,
                faculty: this.filter_faculty
            }
            UserService.authPostRequest(`get-cre-requests?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.responseData = response.data.data;
                        this.$root.updateParent('CRE Registrations');
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },

    },
};
</script>

<style scoped>
.list-title {
    font-size: 16px;
    color: black;
}

.item {
    /* margin-bottom: 10px; */
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    /* height: 46px !important; */
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.w-50 {
    width: 50px !important;
}

.goals .item {
    padding: 10px 10px;
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    margin-bottom: 10px;
}

.price-icon {
    /* margin-top: -1%; */
    font-size: 24px;
}

.chip-label[data-v-638c6bb6] {
    padding: 0px 5px 0px 5px !important;
    font-size: 14px !important;
}

.pad-5-left {
    padding-left: 5px;
}</style>