<template>
    <div class="section">
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">Total</div>
                    <div class="value text-primary">{{ responseData.count_total ? responseData.count_total : '00' }}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">Members</div>
                    <div class="value text-success">{{ responseData.count_total_m ? responseData.count_total_m : '00' }}</div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title mal-text">Added By Members</div>
                    <div class="value text-warning">{{ responseData.count_total_o ? responseData.count_total_o : '00' }}</div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title mal-text">Public</div>
                    <div class="value text-danger">{{ responseData.count_total_p ? responseData.count_total_p : '00' }}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- Wallet Card -->
    <!-- <div class="section pt-1">
        <div class="wallet-card">
            <div class="balance">
                <div class="left text-center">
                    <span class="title mal-text text-center">{{responseData.event}}</span>
                    <h1 class="total text-primary">{{responseData.count_total}}</h1>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="section">
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ചേർത്തത്</div>
                    <div class="value text-success">{{ count_total }}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ബാക്കിയുള്ളത്</div>
                    <div class="value text-danger">{{ minusToZero ? minusToZero : '0' }}</div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text custom-title">{{ responseData.event }}<br>{{ page_title }}</h3>
        </div>
    </div>

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>
    <div class="modal fade action-sheet" id="nerpathamTargetModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Nerpatham Target</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addNerpathamTarget">
                            <div class="form-group basic">
                                <label class="label" for="Target">Target</label>
                                <div class="form-group boxed">
                                    <Field name="target" type="number" class="form-control" id="target"
                                        v-model="nerpatham_target" placeholder="Enter Target" />
                                </div>
                                <input type="hidden" v-model="sub_level_id">
                                <div class="form-group" v-if="message">
                                    <div v-if="message" class="error-feedback">
                                        {{ message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Others</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="sub_level in member_lists" v-bind:key="sub_level">
                            <td class="text-primary font-dark">
                                <router-link v-if="user_level == 3"
                                    :to="'/org/' + (user_level + 1) + '/' + sub_level.id + '/event-registrations/' + $route.params.event">
                                    {{ sub_level.name }}
                                </router-link>
                                <router-link v-if="user_level <= 2"
                                    :to="'/org/' + (user_level + 1) + '/' + sub_level.id + '/event-report/' + $route.params.event">
                                    {{ sub_level.name }}
                                </router-link><br>
                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-primary">WS</i>
                                    <span class="chip-label">{{ sub_level.event_reg.wisdom_count }}</span>
                                </div>
                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-success">YT</i>
                                    <span class="chip-label"> {{ sub_level.event_reg.youth_count }}</span>
                                </div>
                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-danger">ST</i>
                                    <span class="chip-label">{{ sub_level.event_reg.students_count }}</span>
                                </div>
                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-pink">WM</i>
                                    <span class="chip-label">{{ sub_level.event_reg.women_count }}</span>
                                </div>
                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-pink-dark">GL</i>
                                    <span class="chip-label">{{ sub_level.event_reg.girls_count }}</span>
                                </div>
                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-warning">BL</i>
                                    <span class="chip-label">{{ sub_level.event_reg.balavedi_count }}</span>
                                </div><br>
                                <div class="chip chip-media mt-1">
                                    <i class="chip-icon bg-dark">TTL</i>
                                    <span class="chip-label">{{ sub_level.event_reg.total_count }}</span>
                                </div>
                            </td>
                            <td class="text-primary font-dark">
                                {{ sub_level.event_reg_count_o }}
                            </td>
                            <td class="text-primary font-dark">
                                {{ sub_level.event_reg_count }}
                            </td>

                            <!-- <td class="text-primary font-dark">
                                <div class="card-button dropdown">
                                    <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                                        :data-bs-target="'#actionSheetInset' + sub_level.id">
                                        <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                                    </button>
                                </div>
                                <div class="modal fade action-sheet" :id="'actionSheetInset' + sub_level.id"
                                    tabindex="-1" role="dialog">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Action</h5>
                                            </div>
                                            <div class="modal-body">
                                                <ul class="action-button-list">
                                                    <li v-if="currentUser.user_level <= 3">
                                                        <a href="javascript:void(0)" class="btn btn-list"
                                                            data-bs-dismiss="modal" @click="nerpathamTarget(sub_level)">
                                                            <span>
                                                                <ion-icon name="book-outline"></ion-icon>നേർപഥം ടാർഗറ്റ്
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li class="action-divider"></li>
                                                    <li>
                                                        <a href="#" class="btn btn-list text-danger"
                                                            data-bs-dismiss="modal">
                                                            <span>
                                                                <ion-icon name="close-outline"></ion-icon> Cancel
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- <ul class="listview image-listview inset text" v-if="!loading_list && user_level != 4">
        <li v-for="sub_level in member_lists" v-bind:key="sub_level.id">
            <a class="item">
                <div class="in">
                    <router-link :to="'/org/' + (user_level + 1) + '/' + sub_level.id">
                        <div>
                            <b class="list-title">{{ sub_level.name }}</b>
                        </div>
                    </router-link>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + sub_level.id">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + sub_level.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li v-if="currentUser.user_level <= 3">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="nerpathamTarget(sub_level)">
                                                <span>
                                                    <ion-icon name="book-outline"></ion-icon>നേർപഥം ടാർഗറ്റ്
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>

        </li>
    </ul> -->
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field } from "vee-validate";
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Nerpatham Report',
    components: {
        Form,
        Field,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            count_total: 0,
            count_target: 0,
            level: [],
            nerpatham_target: '',
            target_title: 'ടാർഗറ്റ്',
            page_title: 'റിപ്പോർട്ട്',
            series: [0, 0],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                colors: ['#64b167', '#db7491'],
                labels: ['Target', 'Total'],
                responsive: [{
                    breakpoint: 100,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            responseData: []


        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.count_target - this.count_total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList();
            this.$root.updateParent('Registration Report');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        updateChart() {
            this.chartOptions = {
                chart: {
                    type: 'pie'
                },
                colors: ['#64b167', '#db7491'],
                labels: ['ചേർത്തത്', 'ബാക്കി ഉള്ളത്'],
                responsive: [{
                    breakpoint: this.count_target,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            show: false,
                        }
                    }
                }]
            }
            // In the same way, update the series option
            this.series = [parseFloat(this.count_total), parseFloat(this.count_target - this.count_total)]
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            if (admin.member_id) {
                this.member_id = admin.member_id;
                this.disable_member_form = true;
            } else {
                this.disable_member_form = false;
            }
            this.name = admin.name;
            this.mobile = admin.mobile;
            this.subscription_no = admin.subscription_no;
            this.receipt_no = admin.reciept_no;
            this.member_edit = false;
            this.member_list.push(this.member);
            this.designation = admin.designation;
            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.disable_member_form = false;
            this.message = '';
            this.member_id = '';
            this.name = '';
            this.mobile = '';
            this.subscription_no = '';
            this.receipt_no = '';
            this.member_edit = true;
            this.member = '';
            this.editable_id = null;
            this.add_admin = true;
            this.edit_admin = false;
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            console.log(user);
            user.editable_id = this.editable_id;
            user.is_member = this.isMember;
            if (!this.member && this.isMember == 'Y') {
                this.message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക';
            }
            if (this.member) {
                user.member_id = this.member.id;
            }
            user.unit_id = parseInt(this.$route.params.id);
            UserService.addAdmin('store-nerpatham-subscriptions', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delate-nerpatham-subscriptions', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        getMembersList() {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                user_level_id: parseInt(this.$route.params.id),
                event_type: this.$route.params.event
            }
            UserService.authPostRequest(`get-event-report`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.member_lists = response.data.data.list;
                        this.level = response.data.data.level;
                        this.count_target = response.data.data.count_target;
                        this.count_total = response.data.data.count_total;
                        this.level = response.data.data.level;
                        this.target_title = response.data.data.target_title;
                        this.page_title = response.data.data.page_title;
                        this.loading_list = false;
                        this.updateChart();
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        downloadBiodata(member_list) {
            let data = {
                id: member_list.id
            }
            UserService.authPostRequest('download-member-pdf', data).then(
                (response) => {
                    if (response) {
                        // let blob = new Blob([response], {
                        //     type: 'application/pdf'
                        // })
                        // let link = document.createElement('a')
                        // link.href = response.data.data.link
                        // link.setAttribute('download', 'file.pdf');
                        // link.click()

                        // window.open(response.data.data.link)

                        const link = document.createElement('a');
                        link.href = response.data.data.link
                        link.setAttribute('download', member_list.name + ' biodata-form.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        this.loading = false;
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToBiodata(member_list) {
            this.$router.push('/bio-data/' + member_list.user_id);
        },
        showActionModel() {
            this.pin_number = '';
        },
        nerpathamTarget(sub_level = null) {
            this.nerpatham_target = sub_level.nerpatham_target;
            this.sub_level_id = sub_level.id;
            $('#nerpathamTargetModel').modal('show');
        },
        addNerpathamTarget(data) {
            data.level = parseInt(this.$route.params.sub_level) + 1;
            data.level_id = parseInt(this.sub_level_id);
            UserService.addAdmin('set-nerpatham-target', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.getMembersList();
                        $('#nerpathamTargetModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: auto;
}

.left {
    margin-left: 20px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 0px 16px;
    position: relative;
    z-index: 1;
}
.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}
</style>