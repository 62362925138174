<template>
    <div class="section pt-1 mt-2" v-if="permissions.election_report ? permissions.election_report.view : false">
        <div class="wallet-card mt-1">
            <div class="wallet-footer wallet-footer-user">
                <div class="item">
                    <router-link :to="'/org/4/' + $route.params.id +'/excecutive-report'">
                        <a href="javascript:void(0)">
                            <div class="row">
                                <div class="icon-wrapper col-2">
                                    <ion-icon name="receipt-outline"></ion-icon>
                                </div>
                                <strong class="add-member-btn mal-text col-10">ശാഖ ഇലക്ഷൻ റിപ്പോർട്ട് കാണാൻ ഇവിടെ
                                    അമർത്തുക</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="section pt-1 mt-2">
        <div class="wallet-card">
            <div class="wallet-footer">
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(1)">
                            Wisdom
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(2)">
                            Youth
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(3)">
                            Students
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(4)">
                            Women
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(5)">
                            Balavedi
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(6)">
                            Girls
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit Excecutive Member</h5>
                    <h5 class="modal-title" v-if="add_admin">Add Excecutive Member</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="wing">Wing</label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <Field as="select" :disabled="true" class="form-control custom-select"
                                            v-model="wing" name="wing" id="select4"
                                            @change="getDepartments($event.target.value,true)">
                                            <option selected>Select Wing</option>
                                            <option value="1">Wisdom</option>
                                            <option value="2">Youth</option>
                                            <option value="3">Students</option>
                                            <option value="4">Women</option>
                                            <option value="5">Balavedi</option>
                                            <option value="6">Girls</option>

                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="wing" class="error-feedback" />
                            </div>
                            <div class="form-group row boxed typehead-div">
                                <div class="input-wrapper col-10">
                                    <label class="label" for="text4b">Member</label>
                                    <Field type="text" class="form-control" name="member" id="text4b"
                                        v-if="member && !member_edit" :readonly="true" v-model="member.text" />

                                    <v-select v-if="member_edit" label="name" :filterable="false" v-model="member"
                                        :value="member" :options="member_list"
                                        @input="getTextList($event.target.value,wing)">
                                        <template>
                                            type to search...
                                        </template>
                                        <template>
                                            <div class="d-center">
                                                {{ member.text }}
                                            </div>
                                        </template>
                                    </v-select>
                                    <ErrorMessage name="member" class="error-feedback" />
                                </div>
                                <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                                    @click="editBtn()">
                                    <ion-icon name="create-outline"></ion-icon>
                                </button>
                                <div class="input-info" v-if="member">Mobile :{{member.country_code}} {{member.mobile}}
                                </div>
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="designation">Designation</label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <Field as="select" class="form-control custom-select" v-model="designation"
                                            name="designation" id="select4">
                                            <option v-for="item in (wing != 5?designations:balavedi_designations)"
                                                v-bind:key="item.id" v-bind:value="item.id">
                                                {{item.value}}
                                            </option>
                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="designation" class="error-feedback" />
                            </div>
                            <div class="form-group boxed" v-if="wing != 5">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="text4b">വിങ്ങുകൾ</label>
                                    <div class="row">
                                        <div class="form-check mb-1 col-12" v-for="(item, index) in department_list"
                                            :value="item.id" v-bind:key="item.id"
                                            :set="id = 'customCheckb2' + (index + 1)">
                                            <input type="checkbox" class="form-check-input" v-bind:id="id"
                                                v-bind:value="{id : item.id, text:item.name_mal}" v-model="department">
                                            <label class="form-check-label mal-tex" v-bind:for="id">{{ item.name_mal
                                            }}</label>
                                        </div>
                                        <ErrorMessage name="department" class="error-feedback" />
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="editable || add_admin">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!editable && !add_admin" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DeleteMemberModel -->
    <div class="modal fade action-sheet" id="excCountModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">എക്സിക്യൂട്ടീവ് എണ്ണം</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">

                        <div class="form-group" v-if="exc_count">
                            <div v-if="exc_count" class="error-feedback">
                                {{ message_delete }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="Name"><span class="mal-text">എക്സിക്യൂട്ടീവ് ആയി സെലക്ട്
                                    ചെയ്തവരുടെ എണ്ണം</span></label>
                            <div class="form-group boxed">
                                <Field name="count" type="number" class="form-control" v-model="exc_count"
                                    placeholder="Count" autocomplete="off" />
                            </div>
                        </div>
                        <div class="form-group" v-if="message">
                            <div v-if="message" class="error-feedback">
                                {{ message }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <button type="button" @click="countSave()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * AddMemberModel -->
    <div class="section pt-0 mt-0" v-if="this.wing != '5'">
        <div class="wallet-card mt-1">
            <div class="wallet-footer wallet-footer-user">
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="row">
                            <strong class="add-member-btn col-12 text-center text-primary">
                                എക്സിക്യൂട്ടീവ് അംഗങ്ങളുടെ എണ്ണം : <span>{{exc_count}}</span>
                            </strong>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="section-heading">
        <div class="listview-title mt-0 mal-text custom-title">
            ലിസ്റ്റ്</div>
        <button type="button" class="btn btn-primary btn-sm me-1 mb-1" @click="countAdd"
        v-if="permissions.executives ? permissions.executives.update : false">
            <ion-icon name="add-outline"></ion-icon>
            Excecutive Count
        </button>
        <button type="button" class="btn btn-primary btn-sm me-1 mb-1" @click="adminAdd" v-if="permissions.executives ? permissions.executives.create : false">
            <ion-icon name="add-outline"></ion-icon>
            New
        </button>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2">
        <div class="card">
            <h4 class="text-center mt-1 mal-text error-feedback" v-if="!loading_list && message">{{message}}</h4>
        </div>
    </div>
    <ul class="listview image-listview inset text" v-if="!loading_list">
        <li v-for="admin_list in admin_lists" v-bind:key="admin_list.id">
            <a href="javascript:void(0)" class="item">
                <img v-if="admin_list.member.avatar != 'member.jpg' && admin_list.member.avatar != null" :src="'https://guide.msoftit.com/'+admin_list.member.avatar" alt="avatar" class="image">
                <img v-if="!admin_list.member.avatar || admin_list.member.avatar == 'member.jpg'" :src="'assets/img/sample/avatar/avatar1.png'" alt="avatar" class="image">
                
                <div class="in">
                    <div>
                        <header class="mal-text">{{admin_list.user_sublevel_desc}}</header>
                        <b>{{ admin_list.member.name }} </b>
                        <footer><span v-for="(department , index) in admin_list.departments" v-bind:key="department.id"
                                class="mal-text custom-title">{{department.name_mal}} {{index+1 ==
                                (admin_list.departments.length)?'':'|'}}</span></footer>
                    </div>
                    <!-- <a href="javascript:void(0)" @click="adminView(admin_list)" v-if="!admin_list.user_level_name">
                        <span class="badge badge-primary">
                            <ion-icon name="create-outline"></ion-icon>
                        </span>
                    </a> -->
                    <!-- Default Action Sheet Inset -->
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset'+admin_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-horizontal"></ion-icon>
                        </button>
                    </div>
                    <div class="modal fade action-sheet" :id="'actionSheetInset'+admin_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li v-if="permissions.executives ? permissions.executives.call : false">
                                            <a :href="'tel:'+admin_list.member.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="admin_list.member.whatsapp && permissions.executives ? permissions.executives.whatsapp : false">
                                            <a :href="'https://wa.me/'+admin_list.member.whatsapp_country_code+admin_list.member.whatsapp+'?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="permissions.executives ? permissions.executives.update : false">
                                            <a href="javascript:void(0)" @click="adminView(admin_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="permissions.executives ? permissions.executives.delete : false">
                                            <a href="javascript:void(0)" @click="adminDelete(admin_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import $ from 'jquery';
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        ErrorMessage,
        vSelect
    },
    data() {
        // const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            wing: yup.string().required("Wing is required!"),
            designation: yup.string().required("Designation is required!"),
            // member: yup.string().required("Name is required!"),
        });
        return {
            loading: false,
            loading_list: false,
            message: "",
            permissions: [],
            wing: '',
            designation: '',
            designations: [
                { id: '1', value: 'പ്രസിഡണ്ട്' },
                { id: '3', value: 'സെക്രെട്ടറി' },
                { id: '5', value: 'ട്രെഷറർ' },
                { id: '2', value: 'വൈസ് പ്രസിഡണ്ട്' },
                { id: '4', value: 'ജോയിന്റ് സെക്രെട്ടറി' },
                { id: '6', value: 'എക്സിക്യൂട്ടീവ് മെമ്പർ' },
            ],
            balavedi_designations: [
                { id: '1', value: 'ക്യാപ്റ്റൻ' },
                { id: '3', value: 'വൈസ് ക്യാപ്റ്റൻ' },
            ],
            schema,
            user_level: "",
            admin_lists: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            member_list: [],
            department_list: [],
            department: [],
            member: '',
            member_edit: true,
            report_permission: false,
            add_permission: false,
            unit: [],
            exc_count: 0,
            error_401:false

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {

            this.user_level = parseInt(this.$route.params.sub_level);
            this.getAdminList(1);

            if (this.user_level == this.currentUser.user_level) {
                this.editable = true;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('ഭാരവാഹികളുടെ ലിസ്റ്റ്');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        onSearch(search, loading, type) {
            if (search.length) {
                loading(true);
                this.getTextList(search, type);
                // this.search(loading, search, this);
            }
        },
        // search: _.debounce((loading, search, vm) => {
        //     fetch(
        //         `https://api.github.com/search/repositories?q=${escape(search)}`
        //     ).then(res => {
        //         res.json().then(json => (vm.options = json.items));
        //         loading(false);
        //     });
        // }, 350),
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                unit_id: this.$route.params.id,
                filter_with_unit: true
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getDepartments(wing, clear_member = false) {
            let data = {
                wing: wing,
                user_level: this.user_level,
                level_id: this.$route.params.id
            }
            if (clear_member) {
                this.member = '';
                this.member_list = [];
                this.member_edit = true;
            }

            UserService.authPostRequest('get-departments', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.department_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getAdminList(wing) {
            this.error_401 = false;
            this.wing = wing.toString();
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level,
                id: this.user_level == 1 ? null : parseInt(this.$route.params.id),
                wing: wing
            }
            UserService.getAdminList('get-excecutive-list', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.admin_lists = response.data.data.list;
                        this.report_permission = response.data.data.report_permission;
                        this.add_permission = response.data.data.add_permission;
                        this.unit = response.data.data.unit;
                        this.permissions = response.data.data.permission;
                        if (response.data.data.unit) {
                            if (this.wing == '1') {
                                this.exc_count = response.data.data.unit.wisdom_executives_count;
                            } else if (this.wing == '2') {
                                this.exc_count = response.data.data.unit.youth_executives_count;
                            } else if (this.wing == '3') {
                                this.exc_count = response.data.data.unit.students_executives_count;
                            } else if (this.wing == '4') {
                                this.exc_count = response.data.data.unit.women_executives_count;
                            } else if (this.wing == '5') {
                                this.exc_count = response.data.data.unit.balavedi_executives_count;
                            } else if (this.wing == '6') {
                                this.exc_count = response.data.data.unit.gilrs_executives_count;
                            } else {
                                this.exc_count = 0;
                            }
                        }

                        this.message = response.data.message;
                        this.loading_list = false;
                    }else if(response.data.status == 'warning'){
                        this.error_401 = true;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.wing = admin.wing;
            this.getTextList(admin.member.name, this.wing);
            this.getDepartments(admin.wing);
            this.member = {
                id: admin.member_id,
                text: admin.member.name,
                mobile: admin.member.mobile,
                country_code: admin.member.country_code
            }
            this.member_edit = false;
            this.member_list.push(this.member);
            this.designation = admin.designation;
            if (admin.departments) {
                let item = [];
                admin.departments.forEach(element => {
                    let item_ = { id: element.pivot.department_id, text: element.name_mal }
                    item.push(item_);
                });
                this.department = item;
            }
            this.editable_id = admin.id;
            this.add_admin = false;
        },
        adminAdd() {
            if (this.exc_count <= 0) {
                this.$swal.fire(
                    '',
                    'എക്സിക്യൂട്ടീവ് അംഗങ്ങളുടെ എണ്ണം അപ്ഡേറ്റ് ചെയ്തിട്ടില്ല. അതിനു ശേഷം അംഗങ്ങളെ ചേർക്കൽ തുടങ്ങുക.'
                ).then((result) => {
                    if (result.isConfirmed) {
                        $('#excCountModel').modal('show');
                    }
                });

            } else {
                $('#addAdminModel').modal('show');
                this.getDepartments(this.wing);
                this.message = '';
                this.designation = '';
                this.member_edit = true;
                this.member = '';
                this.department = [];
                this.editable_id = null;
                this.add_admin = true;
            }
        },
        countAdd() {
            $('#excCountModel').modal('show');
            this.message = '';
        },
        addAdmin(user) {
            user.id = this.editable_id;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;

            if (!this.member) {
                this.message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക';
            }
            user.member = {
                id: this.member.id,
                name: this.member.name,
            }
            user.department = JSON.stringify(this.department);
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 4) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = this.currentUser.zone_id;
                user.unit_id = parseInt(this.$route.params.id);
            }
            if (this.member.country_code != '91') {
                $('#addAdminModel').modal('hide');
                this.$swal.fire({
                    title: '',
                    text: "ബയോഡാറ്റ പ്രകാരം ഈ മെമ്പർ പ്രവാസിയാണ് എന്ന കാര്യം ശ്രദ്ധിക്കുക.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'തുടരുക'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.loading = true;
                        UserService.addAdmin('store-excecutive-member', user).then(
                            (response) => {
                                if (response.data.status == 'success') {
                                    $('#addAdminModel').modal('hide')
                                    this.$swal('', response.data.message, 'seccess');
                                    this.getAdminList(user.wing);
                                    this.loading = false;
                                    this.message = '';
                                } else if (response.data.status == 'warning') {
                                    // this.$swal('', response.data.message, 'warning');
                                    this.message = response.data.message;
                                    this.loading = false;
                                } else if (response.data.status == 'error') {
                                    this.message = response.data.message;
                                    this.loading = false;
                                } else {
                                    this.loading = false;
                                }
                            },
                            (error) => {
                                this.loading = false;

                                this.messafe = (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                            }
                        );
                    }
                });
            } else {
                this.loading = true;
                UserService.addAdmin('store-excecutive-member', user).then(
                    (response) => {
                        if (response.data.status == 'success') {
                            $('#addAdminModel').modal('hide')
                            this.$swal('', response.data.message, 'seccess');
                            this.getAdminList(user.wing);
                            this.loading = false;
                            this.message = '';
                        } else if (response.data.status == 'warning') {
                            // this.$swal('', response.data.message, 'warning');
                            this.message = response.data.message;
                            this.loading = false;
                        } else if (response.data.status == 'error') {
                            this.message = response.data.message;
                            this.loading = false;
                        } else {
                            this.loading = false;
                        }
                    },
                    (error) => {
                        this.loading = false;

                        this.messafe = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        },
        countSave() {
            let user = {};
            user.id = this.editable_id;
            user.wing = this.wing;
            user.exc_count = this.exc_count;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 4) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = this.currentUser.zone_id;
                user.unit_id = parseInt(this.$route.params.id);
            }
            this.loading = true;
            UserService.addAdmin('set-excecutive-count', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#excCountModel').modal('hide');
                        this.message = '';
                        // this.$swal('', response.data.message, 'seccess');
                        // setTimeout(function () {
                        //     this.adminAdd();
                        // }, 500);

                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        adminDelete(admin_list) {
            var data = {
                id: admin_list.id
            }
            this.$swal.fire({
                title: '',
                text: "ഉറപ്പായും ഡിലീറ്റ് ചെയ്യണോ ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    UserService.authPostRequest('delete-excecutive-member', data).then(
                        (response) => {
                            if (response.data.status == 'success') {
                                this.$swal.fire(
                                    '',
                                    response.data.message,
                                    'success'
                                );
                                this.getAdminList(admin_list.wing);
                                this.loading = false;
                            } else if (response.data.status == 'warning') {
                                this.$swal.fire(
                                    '',
                                    response.data.message,
                                    'warning'
                                );
                                this.loading = false;
                            } else {
                                this.loading = true;
                            }
                        },
                        (error) => {
                            this.loading = false;
                            this.message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            })
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        editBtn() {
            this.member_edit = true;
        },

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.edit-btn {
    margin-top: 24px;
}

.action-button-list>li {
    min-height: 1px !important;
}

.typehead-div {
    /* margin-left: -3% !important; */
}

.d-center {
    display: flex;
    align-items: center;
}

.selected img {
    width: auto;
    max-height: 23px;
    margin-right: 0.5rem;
}

.v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
    border-bottom: none;
}

.v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
}

.v-select .dropdown-menu .active>a {
    color: #fff;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}
</style>