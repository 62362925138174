<template>
    <div class="section pt-1 mt-2">
        <div class="wallet-card">
            <div class="wallet-footer">
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(1)">
                            Wisdom
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(2)">
                            Youth
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(3)">
                            Students
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(4)">
                            Women
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(6)">
                            Girls
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit Councillor</h5>
                    <h5 class="modal-title" v-if="add_admin">Add Councillor</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="wing">Wing</label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <Field as="select" :disabled="true" class="form-control custom-select"
                                            v-model="wing" name="wing" id="select4"
                                            @change="getDepartments($event.target.value, true)">
                                            <option selected>Select Wing</option>
                                            <option value="1">Wisdom</option>
                                            <option value="2">Youth</option>
                                            <option value="3">Students</option>
                                            <option value="4">Women</option>
                                            <option value="6">Girls</option>
                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="wing" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <label class="label mal-text" for="councillor">ജില്ല/സംസ്ഥാന കൗൺസിലർ</label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <Field as="select" class="form-control custom-select" v-model="councillor"
                                            name="councillor" id="select5">
                                            <option>Select Councillor Type</option>
                                            <option value="state">State</option>
                                            <option value="district">District</option>
                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="councillor" class="error-feedback" />
                            </div>
                            <div class="form-group row boxed typehead-div">
                                <div class="input-wrapper col-12">
                                    <label class="label" for="text4b">കൗൺസിലർ</label>
                                    <v-select label="text" :filterable="false" v-model="member" :value="member"
                                        :options="member_list" @input="getTextList($event.target.value, wing)">
                                        <template>
                                            type to search...
                                        </template>
                                        <template>
                                            <div class="d-center">
                                                {{ member.text }}
                                            </div>
                                        </template>
                                    </v-select>
                                    <ErrorMessage name="member" class="error-feedback" />
                                </div>
                                <div class="input-info" v-if="member">Mobile :{{ member.country_code }} {{ member.mobile
                                }}
                                </div>
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="editable || add_admin">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!editable && !add_admin" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DeleteMemberModel -->
    <div class="modal fade action-sheet" id="excCountModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">എക്സിക്യൂട്ടീവ് എണ്ണം</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">

                        <div class="form-group" v-if="exc_count">
                            <div v-if="exc_count" class="error-feedback">
                                {{ message_delete }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="Name"><span class="mal-text">എക്സിക്യൂട്ടീവ് ആയി സെലക്ട്
                                    ചെയ്തവരുടെ എണ്ണം</span></label>
                            <div class="form-group boxed">
                                <Field name="count" type="number" class="form-control" v-model="exc_count"
                                    placeholder="Count" autocomplete="off" />
                            </div>
                        </div>
                        <div class="form-group" v-if="message">
                            <div v-if="message" class="error-feedback">
                                {{ message }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <button type="button" @click="countSave()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * AddMemberModel -->
    <div class="section pt-0 mt-0" v-if="this.wing != '5'">
        <div class="wallet-card mt-1">
            <div class="wallet-footer wallet-footer-user">
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="row">
                            <strong class="add-member-btn col-12 text-primary">ജില്ല കൌൺസിൽ എണ്ണം :
                                <span>({{ actual_count_district }}/{{ exc_count_district }})</span> <br>
                                സംസ്ഥാന കൌൺസിൽ എണ്ണം :
                                <span>({{ actual_count_state }}/{{ exc_count_state }})</span>
                            </strong>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="section-heading">
        <div class="listview-title mt-0 mal-text custom-title">
            ലിസ്റ്റ്</div>
        <button type="button" class="btn btn-primary btn-sm me-1 mb-1" @click="adminAdd" v-if="add_permission">
            <ion-icon name="add-outline"></ion-icon>
            New
        </button>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2">
        <div class="card">
            <h4 class="text-center mt-1 mal-text error-feedback" v-if="!loading_list && message">{{ message }}</h4>
        </div>
    </div>
    <ul class="listview image-listview inset text" v-if="!loading_list">
        <li v-for="admin_list in admin_lists" v-bind:key="admin_list.id">
            <a href="javascript:void(0)" class="item">
                <img src="assets/img/sample/avatar/avatar1.png" alt="image" class="image">
                <div class="in">
                    <div>
                        <header class="mal-text">{{ admin_list.user_level == 1 ? 'സംസ്ഥാന കൗൺസിലർ' : 'ജില്ല കൗൺസിലർ' }}
                        </header>
                        <b>{{ admin_list.member.name }} </b>
                        <footer></footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + admin_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-horizontal"></ion-icon>
                        </button>
                    </div>
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + admin_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li>
                                            <a :href="'tel:' + admin_list.member.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="admin_list.member.whatsapp">
                                            <a :href="'https://wa.me/' + admin_list.member.whatsapp_country_code + admin_list.member.whatsapp + '?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="add_permission">
                                            <a href="javascript:void(0)" @click="adminView(admin_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="add_permission">
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(admin_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import $ from 'jquery';
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        ErrorMessage,
        vSelect,
        AlertModel,
        DialogModel
    },
    data() {
        // const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            wing: yup.string().required("Wing is required!"),
            councillor: yup.string().required("Councillor Type is required!"),
        });
        return {
            loading: false,
            loading_list: false,
            message: "",
            wing: '',
            designation: '',
            designations: [
                { id: '1', value: 'പ്രസിഡണ്ട്' },
                { id: '3', value: 'സെക്രെട്ടറി' },
                { id: '5', value: 'ട്രെഷറർ' },
                { id: '2', value: 'വൈസ് പ്രസിഡണ്ട്' },
                { id: '4', value: 'ജോയിന്റ് സെക്രെട്ടറി' },
                { id: '6', value: 'എക്സിക്യൂട്ടീവ് മെമ്പർ' },
            ],
            balavedi_designations: [
                { id: '1', value: 'ക്യാപ്റ്റൻ' },
                { id: '3', value: 'വൈസ് ക്യാപ്റ്റൻ' },
            ],
            schema,
            user_level: "",
            admin_lists: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            member_list: [],
            department_list: [],
            department: [],
            member: '',
            member_edit: true,
            report_permission: false,
            add_permission: false,
            unit: [],
            exc_count_state: 0,
            exc_count_district: 0,

            actual_count_state: 0,
            actual_count_district: 0,

            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: []

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getAdminList(1);
            if (this.user_level == this.currentUser.user_level) {
                this.editable = true;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('കൗൺസിലർമാരുടെ ലിസ്റ്റ്');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        onSearch(search, loading, type) {
            if (search.length) {
                loading(true);
                this.getTextList(search, type);
            }
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                user_level_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getDepartments(wing, clear_member = false) {
            let data = {
                wing: wing,
                user_level: this.user_level,
                level_id: this.$route.params.id
            }
            if (clear_member) {
                this.member = '';
                this.member_list = [];
                this.member_edit = true;
            }

            UserService.authPostRequest('get-departments', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.department_list = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getAdminList(wing) {
            this.wing = wing.toString();
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level,
                id: this.user_level == 1 ? null : parseInt(this.$route.params.id),
                wing: wing
            }
            UserService.getAdminList('get-councilers-list', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.admin_lists = response.data.data.list;
                        this.report_permission = response.data.data.report_permission;
                        this.add_permission = response.data.data.add_permission;
                        this.councilers_count = response.data.data.councilers_count;
                        this.unit = response.data.data.unit;
                        if (response.data.data.unit) {
                            if (this.wing == '1') {
                                this.exc_count_state = this.councilers_count.wisdom.state;
                                this.exc_count_district = this.councilers_count.wisdom.district;
                            } else if (this.wing == '2') {
                                this.exc_count_state = this.councilers_count.youth.state;
                                this.exc_count_district = this.councilers_count.youth.district;
                            } else if (this.wing == '3') {
                                this.exc_count_state = this.councilers_count.students.state;
                                this.exc_count_district = this.councilers_count.students.district;
                            }
                            this.actual_count_state = this.councilers_count.state_count;
                            this.actual_count_district = this.councilers_count.district_count;
                        }

                        this.message = response.data.message;
                        this.loading_list = false;
                    } else if (response.data.status == 'warning') {
                        // $('#excCountModel').modal('hide');
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.wing = admin.wing;
            this.councillor = admin.user_level == 1 ? 'state' : 'district';
            this.member = {
                id: admin.member_id,
                member_id: admin.member_id,
                text: admin.member.name,
                mobile: admin.member.mobile,
                country_code: admin.member.country_code
            }
            this.member_edit = false;
            this.member_list.push(this.member);
            this.editable_id = admin.id;
            this.add_admin = false;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.getDepartments(this.wing);
            this.message = '';
            this.member_edit = true;
            this.member = '';
            this.editable_id = null;
            this.add_admin = true;
        },
        countAdd() {
            $('#excCountModel').modal('show');
            this.message = '';
        },
        addAdmin(user) {
            user.id = this.editable_id;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;

            if (!this.member) {
                this.message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക';
            }
            user.member = {
                id: this.member.member_id,
                name: this.member.name,
            }
            user.department = JSON.stringify(this.department);
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 4) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = this.currentUser.zone_id;
                user.unit_id = parseInt(this.$route.params.id);
            }

            this.loading = true;
            UserService.addAdmin('store-councilers-member', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getAdminList(user.wing);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        countSave() {
            let user = {};
            user.id = this.editable_id;
            user.wing = this.wing;
            user.exc_count = this.exc_count;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 4) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = this.currentUser.zone_id;
                user.unit_id = parseInt(this.$route.params.id);
            }
            this.loading = true;
            UserService.addAdmin('set-excecutive-count', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#excCountModel').modal('hide');
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
                wing: admin_list.wing
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delete-councilers-member', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getAdminList(data.wing);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        editBtn() {
            this.member_edit = true;
        },

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.edit-btn {
    margin-top: 24px;
}

.action-button-list>li {
    min-height: 1px !important;
}

.typehead-div {
    /* margin-left: -3% !important; */
}

.d-center {
    display: flex;
    align-items: center;
}

.selected img {
    width: auto;
    max-height: 23px;
    margin-right: 0.5rem;
}

.v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
    border-bottom: none;
}

.v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
}

.v-select .dropdown-menu .active>a {
    color: #fff;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}
</style>