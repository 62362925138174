<template>
    <div class="section pt-2">
        <div class="wallet-card mt-1" v-if="currentUser.user_level <= 3">
            <div class="wallet-footer wallet-footer-user row">
                <div class="item col-12">
                    <a href="javascript:void(0)" @click="adminAdd">
                        <div class="icon-wrapper back-color-blue1">
                            <ion-icon name="woman-outline"></ion-icon>
                            <strong class="menu-title mal-text">ശാഖകളിൽ നിന്നും ഗേൾസ് കൗൺസിലിലേക്ക് പ്രൊമോട്ട്
                                ചെയ്യാൻ ഇവിടെ അമർത്തുക</strong>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text custom-title">മണ്ഡലം ഗേൾസ് കൌൺസിൽ</h3>
        </div>
    </div>

    <!-- attendanceModel -->
    <div class="modal fade action-sheet" id="attendanceModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Council Attendance</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group" v-if="attendance_message">
                            <div v-if="attendance_message" class="error-feedback">
                                {{ attendance_message }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="stay"><span>Attendance</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="attendance"
                                        name="attendance" id="select4">
                                        <option value="P">Present</option>
                                        <option value="A">Absend</option>
                                        <option value="L">Leave</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                        </div>
                        <div class="form-group basic" v-if="attendance == 'A' || attendance == 'L'">
                            <label class="label" for="stay"><span>Remarks</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <div class="form-group boxed">
                                        <input name="attendance_remarks" type="text" class="form-control"
                                            v-model="attendance_remarks" placeholder="Remarks" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="markAttandance()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit Excecutive Member</h5>
                    <h5 class="modal-title" v-if="add_admin">Add Excecutive Member</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group row boxed typehead-div">
                                <div class="input-wrapper col-12">
                                    <label class="label" for="text4b">Member</label>
                                    <v-select label="text" :filterable="true" :disabled="disable_member_form"
                                        v-model="member" :value="member" :options="member_list"
                                        @input="getTextList($event.target.value, 10)">
                                        <template>
                                            type to search...
                                        </template>
                                        <template>
                                            <div class="d-center">
                                                {{ member.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                    <ErrorMessage name="member" class="error-feedback" />
                                </div>
                                <!-- <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                                    @click="editBtn()">
                                    <ion-icon name="create-outline"></ion-icon>
                                </button> -->
                                <div class="input-info" v-if="member">Mobile :{{ member.country_code }} {{ member.mobile
                                }}
                                </div>
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="editable || add_admin">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!editable && !add_admin" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DeleteMemberModel -->

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>
    <!-- <div id="search" v-if="!loading_list">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
                    @input="filteredMembers()">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div> -->
    <div class="section" v-if="!loading_list">
        <span><a :href="'https://batch.msoftit.com/reports/pdf/reportZonalCouncil.php?zone_id=' + unit.id + '&wing=6&mobile='+currentUser.phone"
                :download="'Girls Zonal Council'">
                <ion-icon name="download-outline"></ion-icon> Download PDF
            </a></span>
    </div>
    <ul class="listview image-listview inset text mt-2" v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.member.id"
            :set="background = (member_list.attendance.attendance) == 'P' ? attendance_absent.includes(member_list.member_id) ? 'rgb(242 187 202)' : 'rgb(209 232 210)' : (member_list.attendance.attendance) == 'A' || (member_list.attendance.attendance) == 'L' ? attendance_present.includes(member_list.member_id) ? 'rgb(209 232 210)' : 'rgb(242 187 202)' : (member_list.attendance.attendance) == 'N' ? attendance_present.includes(member_list.member_id) ? 'rgb(209 232 210)' : attendance_absent.includes(member_list.member_id) ? 'rgb(242 187 202)' : '#ffffff' : '#ffffff'"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <!-- <span class="button verified" v-if="member_list.member.verified == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified" v-if="member_list.member.verified == 'N' && member_list.member.verification_status">
                            <ion-icon name="close-outline"></ion-icon>
                        </span> -->
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b> {{ member_list.member.name }} </b>
                        <footer class="text-primary">{{ member_list.member.country_code }} {{ member_list.member.mobile
                        }} | <span class="mal-text">{{ member_list.unit.name_mal }}</span>
                            |
                            <span class="mal-text text-success" v-if="member_list.comments == 'Girls Promoted'">Promoted by Admin</span>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.member.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.member.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">

                                        <li>
                                            <a href="javascript:void(0)" @click="attendanceOpen(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="checkmark-done-outline"></ion-icon> Council
                                                    Attendance
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'tel:' + member_list.member.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.member.whatsapp">
                                            <a :href="'https://wa.me/' + member_list.member.whatsapp_country_code + member_list.member.whatsapp + '?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.comments == 'Girls Promoted'">
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total"
            :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page"
            :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, ErrorMessage } from "vee-validate";
import UserService from "../services/user.service";
import vSelect from 'vue-select'
import $ from 'jquery';
import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        Form,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: '',
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: ''

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList(1, 6);
            if ((this.unit_id == this.currentUser.unit_id) || this.currentUser.user_level == 4) {
                this.editable = true;
            } else {
                this.editable = false;
            }
            // this.$root.updateParent('Mmebers List');
        }
        this.getComboboxValues('COUNTRY_CODE');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                user_level_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.wing = admin.wing;
            this.getTextList(admin.member.name, this.wing);
            this.getDepartments(admin.wing);
            if (admin.councillor != 'N') {
                this.disable_member_form = true;
            } else {
                this.disable_member_form = false;
            }
            this.member = {
                id: admin.member_id,
                member_id: admin.member_id,
                text: admin.member.name,
                mobile: admin.member.mobile,
                country_code: admin.member.country_code
            }
            this.member_edit = false;
            this.member_list.push(this.member);
            this.designation = admin.designation;
            if (admin.departments) {
                let item = [];
                admin.departments.forEach(element => {
                    let item_ = { id: element.pivot.department_id, text: element.name_mal }
                    item.push(item_);
                });
                this.department = item;
            }
            this.editable_id = admin.id;
            this.add_admin = false;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.member_edit = true;
            this.member = '';
            this.editable_id = null;
            this.add_admin = true;
        },
        addAdmin(user) {
            user.id = this.editable_id;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;
            if (!this.member) {
                this.message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക';
            }
            user.member = {
                id: this.member.id,
                name: this.member.name,
            }
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 4) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = this.currentUser.zone_id;
                user.unit_id = parseInt(this.$route.params.id);
            }
            UserService.addAdmin('store-girls-councillor', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
                wing: 6,
                type:'girls_council',
                user_level : 3
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delete-girls-councillor', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        getMembersList(page = 1) {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                user_level_id: parseInt(this.$route.params.id),
                wing: 6
            }
            UserService.authPostRequest(`get-general-council-members?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_lists = response.data.data.list;
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.user_level_value;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        downloadBiodata(member_list) {
            let data = {
                id: member_list.id
            }
            UserService.authPostRequest('download-member-pdf', data).then(
                (response) => {
                    if (response) {
                        // let blob = new Blob([response], {
                        //     type: 'application/pdf'
                        // })
                        // let link = document.createElement('a')
                        // link.href = response.data.data.link
                        // link.setAttribute('download', 'file.pdf');
                        // link.click()

                        // window.open(response.data.data.link)

                        const link = document.createElement('a');
                        link.href = response.data.data.link
                        link.setAttribute('download', member_list.name + ' biodata-form.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        this.loading = false;
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToBiodata(member_list) {
            this.$router.push('/bio-data/' + member_list.user_id);
        },
        showActionModel() {
            this.pin_number = '';
        },
        attendanceOpen(member) {
            this.member_id = member.id;
            this.attendance = member.attendance.attendance != 'N' ? member.attendance.attendance : 'A';
            this.attendance_remarks = member.attendance.leave_reason;
            $('#attendanceModel').modal('show');
        },
        markAttandance() {
            let data = {
                member_id: this.member_id,
                user_level: this.user_level,
                user_level_id: parseInt(this.$route.params.sub_level),
                attendance: this.attendance,
                remarks: this.attendance_remarks
            }
            this.loading = true;
            UserService.authPostRequest('mark-attendance', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#attendanceModel').modal('hide');
                        if (this.attendance == "P") {
                            this.attendance_present.push(this.member_id);
                            const index = this.attendance_absent.indexOf(this.member_id);
                            if (index > -1) { // only splice array when item is found
                                this.attendance_absent.splice(index, 1); // 2nd parameter means remove one item only
                            }
                        } else {
                            this.attendance_absent.push(this.member_id);
                            const index = this.attendance_present.indexOf(this.member_id);
                            if (index > -1) { // only splice array when item is found
                                this.attendance_present.splice(index, 1); // 2nd parameter means remove one item only
                            }
                        }

                        console.log(this.attendance_absent);

                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );

        },
        filteredMembers() {
            // this.loading_list = true;
            // let filter_list = this.orgnal_member_lists;
            // filter_list = filter_list.filter((samsung) => {
            //     return this.search_qty.toLowerCase().split(' ').every(v => samsung.member.name.toLowerCase().includes(v)) || this.search_qty.toLowerCase().split(' ').every(v => samsung.member.mobile.toLowerCase().includes(v));
            // });
            // this.member_lists = filter_list;
            this.loading_list = false;
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}
</style>