<template>
    <div class="section mt-1">
        <ul class="nav nav-tabs lined" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                    @click="goToTab('tabD')">
                    Report
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToTab('tabP')">
                    Attendance
                </a>
            </li>
        </ul>
    </div>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <!-- <div class="section pt-2" v-if="tabP && !loading">
        <div class="wallet-card mt-1">
            <div class="wallet-footer wallet-footer-user row">
                <div class="item col-6">
                    <router-link :to="'/meeting/' + this.$route.params.id + '/attendance-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="checkmark-done-outline"></ion-icon>
                                <strong class="menu-title mal-text">റിപ്പോർട്ട്</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6">
                    <router-link :to="'/meeting/' + this.$route.params.id + '/qr-code-generator'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="qr-code-outline"></ion-icon>
                                <strong class="menu-title mal-text">ക്യു ആർ കോഡ്</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
            </div>
        </div>
    </div> -->
    <ul class="listview image-listview inset text mt-1" v-if="tabP && !loading">
        <li>
            <a href="javascript:void(0)" class="item">
                <span class="sl-no"></span>
                <div class="in">
                    <div class="min-width-50">
                        <header></header>
                        <b>Students</b>
                        <footer> </footer>
                    </div>
                </div>
            </a>
        </li>
        <li v-for="(member_list, index) in responseData.participants" v-bind:key="member_list.id"
            :set="background = (member_list.attendance) == 'P' ? 'rgb(209 232 210)' : (member_list.attendance) == 'A' || member_list.attendance == 'L' ? 'rgb(242 187 202)' : (member_list.attendance) == 'N' ? '#ffffff' : '#ffffff'"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ index + 1 }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b> {{ member_list.name }} </b>
                        <footer>{{ member_list.country_code }} {{ member_list.mobile }}
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.whatsapp">
                                            <a :href="'https://wa.me/' + member_list.whatsapp_country_code + member_list.whatsapp + '?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" @click="attendanceOpen(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="checkmark-done-outline"></ion-icon>Attendance
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-1" v-if="!loading && tabD">
        <Form @submit="reportDetailSave">
            <div class="card">
                <div class="card-body">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="pin">Date</label>
                            <Field type="date" class="form-control" name="cre_date" v-model="responseData.weekly_report.date"/>
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="cre_date" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="pin">Starting Time</label>
                            <Field type="time" class="form-control" name="starting_time" v-model="responseData.weekly_report.starting_time" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="starting_time" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="pin">Ending Time</label>
                            <Field type="time" class="form-control" name="ending_time" v-model="responseData.weekly_report.ending_time" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="ending_time" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="pin">Faculty Reporting Time</label>
                            <Field type="time" class="form-control" name="faculty_reporting_time"
                                v-model="responseData.weekly_report.faculty_reporting_time"/>
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="faculty_reporting_time" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="code">Refreshment</label>
                            <Field as="select" class="form-control custom-select" id="refreshment" name="refreshment"
                                v-model="responseData.weekly_report.refreshment">
                                <option value="" selected>---Select---</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </Field>
                        </div>
                        <ErrorMessage name="refreshment" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="pin">Module Taken</label>
                            <Field type="text" class="form-control" name="module_taken" placeholder="Module Taken"
                                v-model="responseData.weekly_report.module_taken"/>
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="module_taken" class="error-feedback" />
                    </div>
                    <div class="transparent pt-3">
                        <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading">
                            <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                            <span>Save</span>
                        </button>
                    </div>
                </div>

            </div>
        </Form>
    </div>
    <!-- attendanceModel -->
    <div class="modal fade action-sheet" id="attendanceModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Meeting Attendance</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group" v-if="attendance_message">
                            <div v-if="attendance_message" class="error-feedback">
                                {{ attendance_message }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="stay"><span>Attendance</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="attendance"
                                        name="attendance" id="select4">
                                        <option value="P">Present</option>
                                        <option value="A">Absend</option>
                                        <option value="L">Leave</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                        </div>
                        <div class="form-group basic" v-if="attendance == 'A' || attendance == 'L'">
                            <label class="label" for="stay"><span>Remarks</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <div class="form-group boxed">
                                        <input name="attendance_remarks" type="text" class="form-control"
                                            v-model="attendance_remarks" placeholder="Remarks" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="markAttandance()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
   
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import $ from 'jquery';
export default {
    name: 'Organization',
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            minutes_loading: false,
            btnLoading: false,
            participant: [],
            member_list: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            tabD: true,
            tabP: false,
            tabA: false,
            tabM: false,
            tabH: false,
            tabMQ: false,
            tabMM: false,
            responseData: {
                center: [],
                participants: [],
                weekly_report: [],
            },
            centre_id: '',
            meeting_location: '',
            cre_date: '',
            starting_time: '',
            ending_time: '',
            faculty_reporting_time: '',
            refreshment: '',
            module_taken: '',
            editable_id: '',
            attendance: 'A',
            attendance_remarks: '',
            message: "",
            user_level: "",
            permissions: [],
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
            unit: [],
            nerpatham_target: '',
            sub_level_id: '',
            sub_level: [],
            questians_responses: [],
            agenda_assigned_member: ''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.centre_id = this.$route.params.id;
            this.user_level = this.currentUser.user_level;
            this.$root.updateParent("Loading...");
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            }
            else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            }
            else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getCentreDetails(this.centre_id);
        }
    },
    methods: {
        goToTab(tab) {
            this.loading = true;
            this.tabD = false;
            this.tabP = false;
            this.tabA = false;
            this.tabM = false;
            this.tabH = false;
            if (tab == 'tabD') {
                this.tabD = true;
                // this.getMeetings('Details');
                this.loading = false;
            } else if (tab == 'tabP') {
                this.tabP = true;
                // this.getMeetings('Participants');
                this.loading = false;
            } else if (tab == 'tabA') {
                this.tabA = true;
                // this.getMeetings('Agenda');
                this.loading = false;
            } else if (tab == 'tabM') {
                this.tabM = true;
                // this.getMeetings('Minutes');
                this.loading = false;
            } else {
                this.tabH = true;
                // this.getMeetings('Minutes');
                this.loading = false;
            }
        },
        goToMinutesTab(tab) {
            this.minutes_loading = true;
            this.tabMM = false;
            this.tabMQ = false;
            if (tab == 'tabMM') {
                this.tabMM = true;
                this.minutes_loading = false;
            } else {
                this.tabMQ = true;
                this.minutes_loading = false;
            }
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'all',
                user_level: 1
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getCentreDetails(id) {
            this.loading = true;
            let data = {
                id: id,
            }
            UserService.authPostRequest('show-cre-center-weekly-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.responseData = response.data.data;
                        this.$root.updateParent('CRE Weekly Report');
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },

        reportDetailSave(data) {
            this.btnLoading = true;
            data.id = this.responseData.weekly_report.id;
            data.centre_id = this.centre_id;
            UserService.addAdmin('update-report-details', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else {
                        this.btnLoading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btnLoading = false;
                }
            );
        },

        attendanceOpen(meeting) {
            this.editable_id = meeting.id,
                this.attendance = meeting.attendance != 'N' ? meeting.attendance : 'A';
            this.attendance_remarks = meeting.remarks;
            $('#attendanceModel').modal('show');
        },
        markAttandance() {
            let data = {
                editable_id: this.editable_id,
                centre_id: this.centre_id,
                attendance: this.attendance,
                remarks: this.attendance_remarks
            }
            this.loading = true;
            UserService.authPostRequest('store-cre-attendance', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        $('#attendanceModel').modal('hide');
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );

        },
    },
};
</script>
<style scoped>
.list-title {
    font-size: 16px;
    color: black;
}

.item {
    /* margin-bottom: 10px; */
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    /* height: 46px !important; */
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}


.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}


.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.w-50 {
    width: 50px !important;
}

.goals .item {
    padding: 10px 10px;
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    margin-bottom: 10px;
}

.price-icon {
    /* margin-top: -1%; */
    font-size: 24px;
}

.chip-label[data-v-3bccde2f] {
    padding: 0px 5px 0px 5px !important;
    font-size: 14px !important;
}

.pad-5-left {
    padding-left: 5px;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.questians-label {
    font-weight: 600 !important;
    font-size: 14px !important;

}

.meeting-title {
    font-size: 20px !important;
}
</style>