<!-- EventSlider.vue -->
<template>
    <div class="event-slider-wrapper">
      <Flicking 
        :options="{ 
          renderOnlyVisible: true, 
          panelsPerView: 1, 
          align: 'center',
          circular: true,
          horizontal: true,
          duration: 500,
          defaultIndex: 0,
          deceleration: 0.0075
        }" 
        :plugins="plugins"
        class="event-slider"
      >
        <div 
          class="event-slide" 
          v-for="(slide, index) in eventSlides" 
          :key="index"
        >
          <div class="event-content">
            <div class="event-overlay"></div>
            <div class="event-text-container">
              <div class="event-text-wrapper">
                <h2 class="event-title">{{ slide.title }}</h2>
                <div class="event-date">{{ slide.date }}</div>
                <button class="register-btn" @click="handleEventRegistration(slide)">
                  {{ slide.buttonText || 'Register Now' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Flicking>
    </div>
  </template>
  
  <script setup>
import { AutoPlay } from "@egjs/flicking-plugins";
import { ref } from 'vue';

const eventSlides = ref([
  {
    title: 'NEXT DAEE MEET',
    date: 'NOV. 11 SUNDAY',
    buttonText: 'Register Now'
  },
  {
    title: 'NEXT DAEE MEET',
    date: 'NOV. 11 SUNDAY',
    buttonText: 'Register Now'
  },
  {
    title: 'NEXT DAEE MEET',
    date: 'NOV. 11 SUNDAY',
    buttonText: 'Register Now'
  }
]);

const handleEventRegistration = (event) => {
  console.log('Registration requested for event:', event);
};

const plugins = ref([
  new AutoPlay({ 
    duration: 2000, 
    direction: "NEXT", 
    stopOnHover: true 
  })
]);
</script>
  
  <style scoped>
  .event-slider-wrapper {
    width: 100%;
    position: relative;
    height: 200px;
    overflow: hidden;
    border-radius: 12px;
  }
  
  .event-slider {
    width: 100%;
    height: 100%;
  }
  
  :deep(.flicking-viewport) {
    height: 100% !important;
    overflow: hidden;
    position: relative;
  }
  
  :deep(.flicking-camera) {
    height: 100% !important;
    will-change: transform;
  }
  
  :deep(.flicking-panel) {
    width: 100%;
    height: 100%;
  }
  
  .event-slide {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0);
  }
  
  .event-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://islamonline.net/wp-content/uploads/2023/01/God-Preserve-Quran.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateZ(0);
  }
  
  .event-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(32, 32, 32, 0.7);
    z-index: 1;
  }
  
  .event-text-container {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateZ(0);
  }
  
  .event-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    text-align: center;
    max-width: 90%;
  }
  
  .event-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    margin: 0;
    line-height: 1.2;
    white-space: normal;
    word-wrap: break-word;
  }
  
  .event-date {
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    margin-top: -0.25rem;
  }
  
  .register-btn {
    background: #2196f3;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 0.5rem 2rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    will-change: transform;
  }
  
  .register-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(33, 150, 243, 0.4);
    background: linear-gradient(45deg, #1976d2, #0d47a1);
  }
  
  .register-btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 10px rgba(33, 150, 243, 0.3);
  }
  
  @media (max-width: 640px) {
    .event-slider-wrapper {
      height: 180px;
    }
  
    .event-text-wrapper {
      gap: 0.75rem;
      padding: 1rem;
    }
  
    .event-title {
      font-size: 1.25rem;
    }
  
    .event-date {
      font-size: 1rem;
    }
  
    .register-btn {
      padding: 0.4rem 1.5rem;
      font-size: 0.8rem;
      margin-top: 0.25rem;
    }
  }
  </style>