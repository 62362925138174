<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 custom-title">{{ sub_level_item.name }}</h3>
        </div>
    </div>

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">{{ $route.params.id == 0 ? 'District' : 'Zone' }}</th>
                            <th scope="col" class="text-center min-width">Report/<br>Schedule/<br>Election Report</th>
                            <th scope="col">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="sub_level in sub_levels" v-bind:key="sub_level">
                            <td class=" text-primary font-dark">
                                {{ sub_level.name_mal ? sub_level.name_mal : sub_level.name }}({{ sub_level.members_count }})
                            </td>
                            <td class="text-center">
                                <span class="badge badge-primary" v-if="sub_level.consol_form_status_level == 1">
                                    <ion-icon name="checkmark-outline" class="icon-size20">
                                    </ion-icon>
                                </span>
                                <span class="badge badge-success" v-if="sub_level.consol_form_status_level == 2">
                                    <ion-icon name="checkmark-outline" class="icon-size20">
                                    </ion-icon>
                                </span>
                                <span class="badge badge-danger"
                                    v-if="sub_level.consol_form_status_level == 0 || sub_level.consol_form_status_level == 3">
                                    <ion-icon name="close-outline" class="icon-size20"></ion-icon>
                                </span>
                                /
                                <span class="badge badge-success"
                                    v-if="sub_level.election && sub_level.all_presiding_officers">
                                    <ion-icon name="checkmark-outline" class="icon-size20">
                                    </ion-icon>
                                </span>
                                <span class="badge badge-primary"
                                    v-if="sub_level.election && !sub_level.all_presiding_officers">
                                    <ion-icon name="checkmark-outline" class="icon-size20">
                                    </ion-icon>
                                </span>
                                <span class="badge badge-danger" v-if="!sub_level.election">
                                    <ion-icon name="close-outline" class="icon-size20"></ion-icon>
                                </span>
                                /
                                <span class="badge badge-primary" v-if="sub_level.election_status_level == 2">
                                    <ion-icon name="checkmark-outline" class="icon-size20">
                                    </ion-icon>
                                </span>
                                <span class="badge badge-success"
                                    v-if="sub_level.election_status_level == 3 && sub_level.not_selected_wings == 0">
                                    <ion-icon name="checkmark-outline" class="icon-size20">
                                    </ion-icon>
                                </span>
                                <span class="badge badge-warning"
                                    v-if="sub_level.election_status_level == 3 && sub_level.not_selected_wings > 0">
                                    <ion-icon name="checkmark-outline" class="icon-size20">
                                    </ion-icon>
                                </span>
                                <span class="badge badge-danger"
                                    v-if="sub_level.election_status_level <= 1 || sub_level.election_status_level == 4">
                                    <ion-icon name="close-outline" class="icon-size20"></ion-icon>
                                </span>
                            </td>
                            <td>
                                <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                                    :data-bs-target="'#actionSheetInset' + sub_level.id">
                                    <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                                </button>
                                <div class="modal fade action-sheet" :id="'actionSheetInset' + sub_level.id" tabindex="-1"
                                    role="dialog">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Action</h5>
                                            </div>
                                            <div class="modal-body">
                                                <ul class="action-button-list">
                                                    <li v-if="permissions.consolidated_report?permissions.consolidated_report.view:false">
                                                        <a href="javascript:void(0)" class="btn btn-list"
                                                            @click="goToConsReport(sub_level.id,2)"
                                                            data-bs-dismiss="modal">
                                                            <span>
                                                                <ion-icon name="eye-outline"></ion-icon>
                                                                {{ $route.params.id == 0 ? 'ജില്ല' : 'മണ്ഡലം' }} കൺസോളിഡേറ്റഡ്
                                                                റിപ്പോർട്ട്
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li
                                                        v-if="((currentUser.user_level <= 2 && currentUser.district_id == $route.params.id) || currentUser.user_level == 1) && (permissions.election_schedule?permissions.election_schedule.view:false)">
                                                        <a href="javascript:void(0)" class="btn btn-list"
                                                            @click="goToScheduleElection(sub_level.id)"
                                                            data-bs-dismiss="modal">
                                                            <span>
                                                                <ion-icon name="menu-outline"></ion-icon>
                                                                {{ $route.params.id == 0 ? 'ജില്ല' : 'മണ്ഡലം' }} ഇലക്ഷൻ
                                                                ഷെഡ്യൂൾ
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li
                                                        v-if="((currentUser.user_level <= 2 && currentUser.district_id == $route.params.id || currentUser.user_level == 1) && $route.params.sub_level == 2)">
                                                        <a href="javascript:void(0)" class="btn btn-list"
                                                            @click="goToCouncillorsList(sub_level.id)"
                                                            data-bs-dismiss="modal">
                                                            <span class="mal-text">
                                                                <ion-icon name="globe-outline"></ion-icon>
                                                                ജില്ല/സംസ്ഥാന കൗൺസിലർമാർ
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li
                                                        v-if="(currentUser.user_level <= 2 && currentUser.district_id == $route.params.id || currentUser.user_level == 1) && (permissions.executive?permissions.executive.view:false)">
                                                        <a href="javascript:void(0)" class="btn btn-list"
                                                            @click="goToElectionList(sub_level.id)"
                                                            data-bs-dismiss="modal">
                                                            <span class="mal-text">
                                                                <ion-icon name="people-outline"></ion-icon>
                                                                {{ $route.params.id == 0 ? 'ജില്ല' : 'മണ്ഡലം' }}
                                                                എക്സിക്യൂട്ടീവ്/ ഭാരവാഹികൾ
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li
                                                        v-if="(currentUser.user_level <= 2 && currentUser.district_id == $route.params.id || currentUser.user_level == 1) && (permissions.executive_report?permissions.executive_report.view:false)">
                                                        <a href="javascript:void(0)" class="btn btn-list"
                                                            @click="goToElectionReport(sub_level.id)"
                                                            data-bs-dismiss="modal">
                                                            <span>
                                                                <ion-icon name="receipt-outline"></ion-icon>
                                                                {{ $route.params.id == 0 ? 'ജില്ല' : 'മണ്ഡലം' }} ഇലക്ഷൻ
                                                                റിപ്പോർട്ട്
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li class="action-divider"></li>
                                                    <li>
                                                        <a href="#" class="btn btn-list text-danger"
                                                            data-bs-dismiss="modal">
                                                            <span>
                                                                <ion-icon name="close-outline"></ion-icon> Cancel
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td class="text-end text-primary font-dark total-size">
                                Total
                            </td>
                            <td class="text-end text-primary font-dark total-size text-end">
                                {{(unit.members_count)}}
                            </td>
                            <td></td>
                            <td></td>
                        </tr> -->
                    </tbody>
                </table>
            </div>

        </div>
        <div class="card mt-2" v-if="$route.params.sub_level == 2">
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="text-primary text-end mal-text">
                                സ്റ്റാറ്റസ് :
                            </td>
                            <td colspan="3">
                                <strong class="mal-text text-secondary"
                                    v-if="sub_level_item.consol_form_status_level == 0">സ്റ്റേറ്റിലേക്ക്
                                    അയച്ചിട്ടില്ല</strong>
                                <strong class="mal-text text-primary"
                                    v-if="sub_level_item.consol_form_status_level == 1">സ്റ്റേറ്റിലേക്ക് അയച്ചു</strong>
                                <strong class="mal-text text-danger"
                                    v-if="sub_level_item.consol_form_status_level == 3">സ്റ്റേറ്റ്
                                    റിജെക്ട് ചെയ്തു</strong>
                                <strong class="mal-text text-success"
                                    v-if="sub_level_item.consol_form_status_level == 2">സ്റ്റേറ്റ്
                                    സ്വീകരിച്ചു</strong>
                            </td>
                        </tr>
                        <tr v-if="permissions.send_report">
                            <td colspan="4">
                                <a href="javascript:void(0)" data-bs-toggle="modal" @click="openSubmitModel"
                                    v-if="sub_level_item.consol_form_status_level == 0 || sub_level_item.consol_form_status_level == 3">
                                    <button
                                        class="btn btn-primary report-btn btn-accept-reject mal-text">സ്റ്റേറ്റിലേക്ക്
                                        അയക്കാൻ
                                        ഇവിടെ
                                        അമർത്തുക
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                    </button>
                                </a>
                            </td>
                        </tr>
                        <tr v-if="permissions.accept_report || permissions.reject_report">
                            <td colspan="4">
                                <div class="row">
                                    <a href="javascript:void(0)" class="col-6"
                                        @click="acceptConsReport($route.params.id)" v-if="permissions.accept_report">
                                        <button
                                            class="btn btn-primary btn-accept-reject report-btn-success report-btn mal-text">സ്വീകരിക്കാൻ
                                            ഇവിടെ അമർത്തുക
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </button>
                                    </a>
                                    <a href="javascript:void(0)" :class="permissions.accept_report ? 'col-6' : 'col-12'"
                                        @click="rejectConsReport($route.params.id)" v-if="permissions.reject_report">
                                        <button
                                            class="btn btn-danger btn-accept-reject report-btn-success report-btn mal-text">
                                            റിജെക്ട് ചെയ്യാൻ
                                            ഇവിടെ അമർത്തുക
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </button>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <!-- <ul class="listview image-listview inset text" v-if="!loading_list">
        <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
            <a class="item">
                <div class="in">
                    <div>
                        <b class="list-title">{{ sub_level.name }}</b>
                        <footer>
                            <div class="chip chip-media mt-1">
                                <i class="chip-icon bg-secondary">Status</i>
                                <span class="chip-label">{{
                                sub_level.consol_form_status
                                }}</span>
                            </div>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset'+sub_level.id">
                            <ion-icon name="ellipsis-horizontal"></ion-icon>
                        </button>
                    </div>

                    <div class="modal fade action-sheet" :id="'actionSheetInset'+sub_level.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li>
                                            <a href="javascript:void(0)" class="btn btn-list"
                                                @click="goToConsReport(sub_level.id)" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="eye-outline"></ion-icon> View Consolidated Sheet
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    </ul> -->
    <div class="modal fade action-sheet" id="actionSheetForm" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Action</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="mb-1 col-12 mal-text text-center text-primary" v-if="permissions.ready_to_send">
                            <span class="badge badge-success">
                                <ion-icon name="checkmark-outline" class="icon-size20">
                                </ion-icon>
                            </span>
                            എന്റെ ജില്ലയിലെ എല്ലാ മണ്ഡലത്തിന്റെയും 2022 വർഷത്തെ ഇലക്ഷൻ പ്രവർത്തനം അവസാനിച്ചു.
                        </div>
                        <div class="mb-1 col-12 mal-text text-center text-warning" v-if="!permissions.ready_to_send">
                            <span class="badge badge-warning">
                                <ion-icon name="alert-outline"></ion-icon>
                            </span>
                            നിങ്ങളുടെ ജില്ലയിലെ ഇലക്ഷൻ റിപ്പോർട്ട് പൂർത്തിയായിട്ടില്ല.
                        </div>
                        <div class="text-danger text-center">{{ message }}</div>
                        <div class="form-group basic" v-if="permissions.ready_to_send">
                            <button type="button" class="btn btn-primary btn-block btn-lg"
                                :disabled="!permissions.ready_to_send" data-bs-dismiss="modal"
                                @click="submitToParentLevel(user_level_id)">Submit</button>
                        </div>
                        <div class="form-group basic" v-else>
                            <button type="button" class="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal"
                                @click="closeSubmitModel()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
export default {
    name: 'Reports',
    components: {
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            permissions: [],
            message: "",
            country_code: '91',
            mobile_number: '',
            wing: '',
            user_level: "",
            user_level_id:"",
            sub_levels: [],
            admin_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            district: '',
            total: 0.00,
            sub_level_item: [],

            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: []
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.user_level_id = parseInt(this.$route.params.id);
            this.getReport();
            if (this.user_level == this.currentUser.user_level) {
                this.editable = false;
            } else {
                this.editable = true;
            }
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getReport() {
            this.loading_list = true;
            let data = {
                id: parseInt(this.$route.params.id),
                sub_level: this.user_level + 1
            }
            UserService.authPostRequest('get-cons-election-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.total = response.data.data.total;
                        this.sub_level_item = response.data.data.sub_level;
                        this.permissions = response.data.data.permissions;
                        this.$root.updateParent('ഇലക്ഷൻ റിപ്പോർട്ട്');
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToConsReport(id,level = null) {
            if(level == 2){
                this.$router.push('/org/2/' + id + '/election-cons-report');
            }else{
                this.$router.push('/org/3/' + id + '/zone-cons-report');
            }
            
        },
        goToScheduleElection(id) {
            this.$router.push('/org/' + (this.user_level + 1) + '/' + id + '/sub-level-schedule-election');
        },
        goToElectionReport(id) {
            this.$router.push('/org/' + (this.user_level + 1) + '/' + id + '/org-excecutive-report');
        },
        goToElectionList(id) {
            this.$router.push('/org/' + (this.user_level + 1) + '/' + id + '/org-excecutive-list');
        },
        goToCouncillorsList(id) {
            this.$router.push('/org/' + (this.user_level + 1) + '/' + id + '/org-councilers-list');
        },
        openSubmitModel() {
            this.message = '';
            $('#actionSheetForm').modal('show');
        },
        closeSubmitModel() {
            $('#actionSheetForm').modal('hide');
        },
        submitToParentLevel(id) {
            this.message = '';
            var data = {
                id: id,
                sub_level: this.user_level,
            }
            UserService.authPostRequest('submit-cons-to-parent-level', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.closeSubmitModel();
                        this.permissions = response.data.data.permissions;
                        this.unit = response.data.data.zone;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.response.data.message;
                    this.$refs.alert_model.openModel();
                    this.loading = false;
                }
            );
        },
        acceptConsReport(id) {
            var data = {
                id: id,
                sub_level: this.user_level,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും സ്വീകരിക്കണോ?';
            this.dialog_action = 'acceptConsReportSuccess';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        acceptConsReportSuccess(data) {
            UserService.authPostRequest('accept-cons-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.closeSubmitModel();
                        this.permissions = response.data.data.permissions;
                        this.unit = response.data.data.zone;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.response.data.message;
                    this.$refs.alert_model.openModel();
                    this.loading = false;
                }
            );
        },

        rejectConsReport(id) {
            var data = {
                id: id,
                sub_level: this.user_level,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും റിജെക്ട് ചെയ്യണോ?';
            this.dialog_action = 'rejectConsReportSuccess';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        rejectConsReportSuccess(data) {
            UserService.authPostRequest('reject-cons-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.closeSubmitModel();
                        this.alert_type = 'Success';
                        this.permissions = response.data.data.permissions;
                        this.unit = response.data.data.zone;
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.response.data.message;
                    this.$refs.alert_model.openModel();
                    this.loading = false;
                }
            );
        },

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 5px;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.font-dark {
    font-weight: 500;
}

.total-size {
    font-size: 18px;
}

.report-btn-success {
    width: 100%;
}


.wallet-footer-user {
    height: 46px !important;
}

.chip-media {
    margin-left: 5px;
    width: 135px !important;
    padding-left: 25px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.btn-icon {
    height: 22px !important;
}

.min-width {
    min-width: 124px !important;
}
</style>