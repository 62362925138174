<template>
    <div class="section pt-1 mt-2">
        <div class="wallet-card">
            <div class="wallet-footer">
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(1)">
                            Wisdom
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(2)">
                            Youth
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(3)">
                            Students
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(4)">
                            Women
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(5)">
                            Balavedi
                        </a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getAdminList(6)">
                            Girls
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit Admin</h5>
                    <h5 class="modal-title" v-if="add_admin">Add Admin</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="Name">Name</label>
                                <Field name="name" type="text" class="form-control" id="name" v-model="name"
                                    placeholder="Enter Name" />
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="country_code">Code <span
                                                    class="text-danger">*</span></label>
                                            <Field as="select" class="form-control custom-select" v-model="country_code"
                                                name="country_code" id="select4">
                                                <option value="91" selected="selected">+91</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="code" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="mobile">Mobile <span
                                                    class="text-danger">*</span></label>
                                            <Field name="mobile_number" type="number" id="mobile_number"
                                                class="form-control" placeholder="" v-model="mobile_number"
                                                oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 11); } if(this.value.length > 10) { this.value = this.value.slice(0, 10);} " />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="mobile" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="wing">Wing</label>
                                <div class="input-wrapper">
                                    <Field as="select" class="form-control custom-select" v-model="wing" name="wing"
                                        id="select4">
                                        <option selected>Select Wing</option>
                                        <option value="1">Wisdom</option>
                                        <option value="2">Youth</option>
                                        <option value="3">Students</option>
                                        <option value="4">Women</option>
                                        <option value="6">Girls</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="wing" class="error-feedback" />
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="editable || add_admin">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!editable && !add_admin" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-heading">
        <div class="listview-title mt-0">
            Admin List </div>
        <button type="button" class="btn btn-primary btn-sm me-1 mb-1" @click="adminAdd"
            v-if="(permissions.admin ? permissions.admin.edit : false)">
            <ion-icon name="add-outline"></ion-icon>
            Add
        </button>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <ul class="listview image-listview inset text" v-if="!loading_list">
        <li v-for="admin_list in admin_lists" v-bind:key="admin_list.id">
            <a href="javascript:void(0)" class="item">
                <img src="assets/img/sample/avatar/avatar1.png" alt="image" class="image">
                <div class="in">
                    <div>
                        <b> {{ admin_list.name }} {{ admin_list.user_level_name }} </b>
                        <footer>{{ admin_list.phone }}</footer>
                    </div>
                    <a href="javascript:void(0)" @click="adminView(admin_list)"
                        v-if="!admin_list.user_level_name && sub_level.consol_form_status_level < 2">
                        <span class="badge badge-primary">
                            <ion-icon name="create-outline"></ion-icon>
                        </span>
                    </a>
                </div>
            </a>
        </li>
    </ul>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";
import $ from 'jquery';
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            name: yup.string().matches(nameRegex, "Please enter name in English").required("Name is required!"),
            mobile_number: yup.string().min(10, 'Must be 10 digits').required("Mobile Number is required!"),
            wing: yup.string().required("Wing is required!"),
        });
        return {
            loading: false,
            loading_list: false,
            permissions: [],
            message: "",
            country_code: '91',
            mobile_number: '',
            wing: '',
            schema,
            user_level: "",
            sub_levels: [],
            admin_lists: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            sub_level: []
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getAdminList(1);
            if (this.user_level == this.currentUser.user_level) {
                this.editable = false;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('Admin List');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getAdminList(wing) {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level,
                id: this.user_level == 1 ? null : parseInt(this.$route.params.id),
                wing: wing
            }
            UserService.getAdminList('get-admin-list', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.admin_lists = response.data.data.list;
                        this.sub_level = response.data.data.sub_level;
                        this.permissions = response.data.data.permissions;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.wing = admin.wing;
            this.mobile_number = admin.phone;
            this.name = admin.name;
            this.country_code = admin.country_code;
            this.editable_id = admin.id;
            this.add_admin = false;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.wing = '';
            this.mobile_number = '';
            this.name = '';
            this.country_code = '91';
            this.editable_id = null;
            this.add_admin = true;
        },
        addAdmin(user) {
            user.id = this.editable_id;
            user.username = user.country_code + user.mobile_number;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;
            user.code = this.country_code;
            user.age = '0',
                user.gender = user.wing == '6' || user.wing == '4' ? 'Female' : 'Male';

            user.mobile = this.mobile_number;
            user.pin = 515388;
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 4) {
                user.unit_id = parseInt(this.$route.params.id);
            }
            user.add_admin = true;
            user.district = 0;
            this.loading = true;
            UserService.addAdmin('register', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.$swal('Admin Saved!', '', 'seccess');
                        UserService.authPostRequest('refresh', null).then(
                            (response) => {
                                if (response.data.status == 'success') {
                                    localStorage.setItem('user', JSON.stringify(response.data.user));

                                } else if (response.data.status == 'error') {
                                    this.message = response.data.message;
                                }
                            },
                            (error) => {
                                this.loading = false;

                                console.log((error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                    error.message ||
                                    error.toString());
                            });
                        this.getAdminList(user.wing);
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        }

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}
</style>