<template>
  <div class="section text-center mt-1" v-if="loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="section pt-1" v-if="!loading">
    <div class="wallet-card">
      <!-- Balance -->
      <div class="balance">
        <div class="left">
          <span class="title">Car Parking</span>
          <!-- <span class="title">Name :{{currentUser.name}}</span> -->
          <h1 class="total" v-html="selected_parking.location"></h1>
        </div>
      </div>
      <!-- * Balance -->
      <!-- Wallet Footer -->
      <div class="row wallet-footer" v-if="parking_select">
        <div class="item col-6" v-for="parking in responseData.parking_locations" v-bind:key="parking.id">
          <a :href="'#/event/' + responseData.event.code + '/parking/' + parking.id">
            <div class="icon-wrapper bg-danger item-button">
              <strong class="text-white">{{ parking.location }}-{{ parking.srl_num }}</strong>
              <ion-icon name="car-outline" role="img" class="md hydrated" aria-label="arrow down outline"
                v-if="parking.location_type == 'CAR'"></ion-icon>
              <ion-icon name="bus-outline" role="img" class="md hydrated" aria-label="arrow down outline"
                v-if="parking.location_type == 'BUS'"></ion-icon>
            </div>
          </a>
        </div>
      </div>
      <!-- * Wallet Footer -->
    </div>
  </div>
  <div class="section pt-0 mt-0">
    <div class="listed-detail mt-3">
      <h3 class="text-center mt-2 mal-text custom-title">Parking Report</h3>
    </div>
  </div>
  <div class="section mt-2 mb-2" v-if="!loading">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Location</th>
              <th scope="col">Capacity</th>
              <th scope="col">Filled</th>
              <th scope="col">Free</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="location in responseData.parking_locations" v-bind:key="location">
              <td class="text-primary font-dark">
                {{ location.location }} - {{ location.srl_num }}
              </td>
              <td class="text-primary font-dark">
                {{ location.maximum_capacity }}
              </td>
              <td class="text-danger font-dark">
                {{ location.filled }}
              </td>
              <td class="text-success font-dark">
                {{ location.maximum_capacity - location.filled }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="section pt-0 mt-0">
    <div class="listed-detail mt-3">
      <h3 class="text-center mt-2 mal-text custom-title">Bus moving to parking</h3>
    </div>
  </div>
  <div class="section mt-2 mb-2" v-if="!loading">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Bus</th>
              <th scope="col">Arr Left</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bus in responseData.parking_vehicles" v-bind:key="bus">
              <td class="text-primary font-dark">
                {{ bus.bus_code }}
              </td>
              <td class="text-primary font-dark">
                {{ bus.arr_platform_left_time }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>
<script>
import $ from "jquery";
import UserService from "../../../services/user.service";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
export default {
  name: "Admin List",
  props: {
    unit_id: null,
  },
  components: {
    AlertModel,
    DialogModel,
  },
  data() {
    return {
      loading: false,
      loading_list: false,
      code: '',
      number: '',
      code_validation: '',
      message: "",
      country_code: "91",
      country_code_whatsapp: "91",
      mobile_number: "",
      whatsapp_number: "",
      wing: "",
      stay: "",
      user_level: "",
      sub_levels: [],
      member_lists: [],
      unit: [],
      editable_id: "",
      editable: true,
      add_admin: false,
      abroad_fields: false,
      pin_number: "",
      arrival_confirm: "",
      arrival_time: "",
      saturday_coming: "",
      arrival_mode: "",
      spouse_coming: "",
      food_pref: "",
      child_coming: [],
      remarks: "",
      search_qry: "",
      responseData: {
        parking_locations: [],
        permissions: [],
        district_code_list: [],
        event: []
      },
      alert_type: "",
      alert_message: "",
      dialog_message: "",
      dialog_action: "",
      dialog_action_params: [],

      parking_select: true,
      selected_parking: [],
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      location.replace("/");
    }
    this.$root.updateParent("Parking Dashboard");
    this.getParkingDetails();
    window.onpopstate = function () {
      if ($("body").hasClass("modal-open")) {
        location.reload();
      }
    };
  },

  methods: {
    getParkingDetails() {
      this.loading = true;
      let data = {
        user_level: this.user_level,
        user_level_id: parseInt(this.$route.params.id),
        event: this.$route.params.event,
        parking_platform: 'parking'
      };
      UserService.authPostRequest(`get-event-parking`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.responseData = response.data.data;
            // this.$root.updateParent(this.responseData.event.event_name);
            this.loading = false;
          } else {
            this.responseData = response.data.data;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    updateParkingDetails(action) {
      if (this.code == '' || !this.number == '') {
        this.code_validation = 'ഫിൽ ചെയ്യുക.';
      } else {
        this.loading = true;
        this.code_validation = '';
        let data = {
          parking_id: this.selected_parking.id,
          action: action,
          event: this.$route.params.event,
          vehicle_code: this.code + '-' + this.number
        };
        UserService.authPostRequest(`update-event-parking`, data).then(
          (response) => {
            if (response.data.status == "success") {
              this.responseData = response.data.data;
              this.selected_parking = this.responseData.selected_parking;
              this.alert_type = "Success";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              // this.$root.updateParent(this.responseData.event.event_name);
              this.loading = false;
            } else if (response.data.status == "warning") {
              this.alert_type = "Warning";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.loading = false;
            } else {
              this.alert_type = "Error";
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.loading = false;
            }
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    selectParking(parking) {
      localStorage.setItem("parking_id", parking.id);
      this.parking_select = false;
      this.selected_parking = parking;
      this.alert_type = "Success";
      this.alert_message = "Parking Selected.";
      this.$refs.alert_model.openModel();
    },
    changeParking() {
      localStorage.setItem("parking_id", "");
      this.parking_select = true;
      this.selected_parking = [];
      //   this.alert_type = "Success";
      //   this.alert_message = 'Parking Selected.';
      //   this.$refs.alert_model.openModel();
    },
  },
};
</script>
<style scoped>
.section-heading {
  padding: 10px 10px 0px 10px;
}

.error-feedback {
  color: red;
}

.listview {
  margin-bottom: 20px;
}

.edit-btn {
  margin-left: 8px;
}

.progress {
  width: 80px;
}

.listview>li footer {
  color: #171617;
}

.min-width-50 {
  min-width: 60% !important;
}

.action-button-list>li {
  min-height: 1px !important;
}

.sl-no {
  font-weight: 900;
  margin-right: 12px;
}

.searchbox .form-control {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid #bdbdd5;
  height: 36px;
  padding: 0 10px 0 10px;
}

.form-check {
  padding: 0;
  margin: 0;
  padding-left: 10px;
  min-height: auto;
  height: auto;
  padding-bottom: 5px;
}

.input-group .input-group-text {
  background: transparent;
  border: 0;
  border: 1px solid #1a418c;
  /* border-top: 1px solid #1a418c; */
  border-radius: 5px 5px 5px 5px;
  font-weight: 400;
  color: #27173e;
  height: auto;
  width: auto;
  padding: 0px 10px 0px 10px;
}

.stat-box {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
  border-radius: 10px;
  padding: 10px 10px;
}

.item-button {
  width: 100% !important;
}

.item-button-top {
  margin-right: 10px !important;
}

.wallet-card .wallet-footer .item strong {
  display: block;
  color: #27173E;
  font-weight: 600;
  font-size: 14px;
  line-height: 15PX;
}

.wallet-card .balance .total {
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1em;
  font-size: 20px;
}

.wallet-card .balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  height: auto;
}
</style>
