<template>
    <!-- Alert Success Action Sheet -->
    <div class="modal fade action-sheet" id="actionSheetDialogBox" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="iconbox text-warning">
                            <ion-icon name="alert-circle"></ion-icon>
                        </div>
                        <div class="text-center p-2">
                            <h3>Warning</h3>
                            <p class="mal-text text-primary" v-html="message"></p>
                        </div>
                        <a href="#" class="btn btn-primary btn-lg mal-text col-6" @click="action()" data-bs-dismiss="modal">
                        {{ yes_button ? yes_button : "വേണം" }}
                      </a>
                        <a href="#" class="btn btn-secondary btn-lg mal-text col-6" @click="closeModel()" data-bs-dismiss="modal">
                       {{ no_button ? no_button : "വേണ്ട" }}
                      </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Alert Success Action Sheet -->
</template>

<script>
import $ from "jquery";
export default {
    props: {
        message: String,
        action_method: String,
        action_method_params: Array,
        yes_button: String,
        no_button: String,
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        openModel() {
            $("#actionSheetDialogBox").modal("show");
        },
        closeModel() {
            $("#actionSheetDialogBox").modal("hide");
        },
        action() {
            $("#actionSheetDialogBox").modal("hide");
            this.$parent[this.action_method](this.action_method_params);
        },
    },
    mounted() {
        window.onpopstate = function() {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
};
</script>

<style scoped>
.btn-lg {
    width: 48% !important;
}

.btn-secondary {
    left: 4% !important;
}
</style>
