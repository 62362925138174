<template>
    <!-- App Capsule -->
    <div id="appCapsule">
        <!-- <div class="section mt-2 text-center">
            <img src="/assets/img/wisdom_logo.png" width="100">
        </div> -->
        <div class="section mt-2 text-center">
            <h1>Reset Pin</h1>
            <h4>Reset your pin.</h4>
        </div>
        <div class="section mt-1">
            <Form @submit="resetPin" :validation-schema="pin_reset">
                <div class="card">
                    <div class="card-body pb-1 row">
                        <label class="label" for="pin">Current Pin<span class="mal-text">(നിലവിലുള്ള PIN)</span></label>
                        <div class="form-group boxed" style="width:100% !important;">
                            <div class="input-wrapper">
                                <Field name="current_pin" type="text" class="form-control" id="smscode"
                                    placeholder="••••••" maxlength="6" />
                            </div>
                        </div>
                        <ErrorMessage name="current_pin" class="error-feedback" />
                        <div class="form-group" v-if="message">
                            <div v-if="message" class="error-feedback">
                                {{ message }}
                            </div>
                        </div>
                        <label class="label" for="pin">Pin<span class="mal-text">(പുതിയ PIN)</span></label>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <Field name="pin" type="text" class="form-control" placeholder="••••••" maxlength="6" />
                            </div>
                        </div>
                        <ErrorMessage name="pin" class="error-feedback" />
                        <label class="label" for="pin">Pin Confirmation<span class="mal-text">(പുതിയ PIN ഉറപ്പിക്കാൻ)</span></label>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <Field name="pin_confirmation" type="text" class="form-control" placeholder="••••••"
                                    maxlength="6" />
                            </div>
                        </div>
                        <ErrorMessage name="pin_confirmation" class="error-feedback" />
                    </div>
                    <!-- <div class="section mt-2">
                        <button type="submit" class="btn btn-primary btn-block btn-lg"
                            @click="submitPassword()">Submit</button>
                    </div> -->
                </div>
                <div class="transparent pt-3">
                    <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Submit</span>
                    </button>
                </div>
            </Form>
        </div>
    </div>
    <!-- * App Capsule -->
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";
export default {
    name: "Reset Pin",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.$root.updateParent("Reset Pin");
    },
    data() {
        const pin_reset = yup.object().shape({
            current_pin: yup.string().required("Current pin is required!"),
            pin: yup.string().required("Pin is required!"),
            pin_confirmation: yup.string()
                .oneOf([yup.ref('pin'), null], 'Pin not match!')
        })
        return {
            loading: false,
            message: "",
            pin_reset,
            country_code: "91",
            otp_sent: false,
            content: [],
        };
    },
    methods: {
        resetPin(pin) {
            this.message = null;
            this.loading = true;
            pin.username = this.currentUser.username;
            UserService.resetPin('reset-pin', pin).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.content = response.data.data;
                        this.$swal('', response.data.message, 'seccess');
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        setTimeout(function () {
                            location.replace('/');
                        }, 1000);
                        
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;

                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }

    }
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

#mobile_number {
    margin-top: 0px;
}

.label {
    margin-left: -10px;
    margin-bottom: 0px;
}
</style>
