<template>
    <div class="section mt-2 text-center">
        <div class="card">
            <div class="card-body pt-0 pb-0">
                <p class="text-primary text-center mal-text">മീറ്റിംഗ്ൽ പങ്കെടുക്കുന്നവർക്ക് ഈ ക്യു ആർ കോഡ് അവരുടെ
                    വിസ്‌ഡം ഗൈഡ് ആപ്പിൽ സ്കാൻ ചെയ്തു അറ്റന്റൻസ് മാർക്ക്
                    ചെയ്യാവുന്നതാണ്. അതിനായി ഡൗൺലൊഡ് ചെയ്ത് പ്രിന്റ് എടുത്ത് വെക്കുക.</p>
                <h2 class="mt-0">{{ qr_code_display_value }}<br> </h2>

            </div>
        </div>
    </div>
    <div class="section mt-2 text-center">
        <div class="card" id="divToPrint">
            <div class="card-body pt-3 pb-3">

                <!-- <qr-code :text="qr_code_value" size="200" error-level="L">
                </qr-code> -->
                <QRCodeVue3 :width="200" :height="200" :value="qr_code_value"
                    :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }" :image="'assets/logo.png'"
                    :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0, image: 'assets/logo.png' }"
                    :dotsOptions="{
                        type: 'extra-rounded',
                        color: '#2355b3',
                        gradient: {
                            type: 'linear',
                            rotation: 0,
                            colorStops: [
                                { offset: 0, color: '#2355b3' },
                                { offset: 1, color: '#2355b3' },
                            ],
                        },
                    }" :backgroundOptions="{ color: '#ffffff' }"
                    :cornersSquareOptions="{ type: 'dot', color: '#2355b3' }"
                    :cornersDotOptions="{ type: undefined, color: '#2355b3' }" fileExt="png" :download="false"
                    myclass="my-qur" imgclass="img-qr" downloadButton="my-button"
                    :downloadOptions="{ name: 'Wisdom Guide', extension: 'png' }" />

            </div>
        </div>
        <div class="card mt-2">
            <div class="card-body pt-3 pb-3">
                <a href="#" class="btn btn-primary btn-lg" @click="printDocument()" data-bs-dismiss="modal">Download QR
                    Code</a>
            </div>

        </div>
    </div>
    <!-- <div id="divToPrint">
        <h2 class="text-center">Wisdom Guide QR Code</h2>
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        <img :src="qr_code">
                    </td>
                </tr>
            </tbody>
        </table>
    </div> -->

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import UserService from "../../services/user.service";
import QRCodeVue3 from "qrcode-vue3";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
export default {
    components: {
        AlertModel,
        DialogModel,
        QRCodeVue3
    },
    data() {
        return {
            list: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            qr_code_value: '',
            qr_code_display_value: '',
            qr_code: '',
        };
    },
    mounted() {
        this.getMeetings();
    },

    methods: {
        printDocument() {
            // this.qr_code = $('.img-qr').attr('src');
            const pdfTable = document.getElementById('divToPrint');
            var html = htmlToPdfmake(pdfTable.innerHTML);
            const documentDefinition = { content: html };
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            pdfMake.createPdf(documentDefinition).open();
        },
        refreshPage() {
            localStorage.removeItem("user");
            location.replace('/');
        },
        getMeetings() {
            let data = {
                meeting_id: this.$route.params.id,
                type : 'meeting'
            }
            UserService.authPostRequest('get-meeting-details', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.list = response.data.data.meeting;
                        if (this.list) {
                            this.qr_code_value = 'A-'+this.list.meeting_level+'-'+this.list.id;
                            this.qr_code_display_value = 'A'+this.list.meeting_level+this.list.id;
                        }
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },

    },
};
</script>
<style scoped>
.accordion-button {
    font-weight: 700 !important;
}

.imaged {
    width: 60px !important;
}

.btn-primary {
    width: 100%;
}

.icon-1 {
    color: #1a418c !important;
    font-size: 40px;
}

.my-button {
    margin-top: 100px !important;
}
</style>