<template>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">വിസ്ഡം ഗൈഡിൽ മാറ്റം വരുത്താനുള്ള/ചേർക്കാനുള്ള അപേക്ഷ</h3>
        </div>
    </div>
    <div class="section mt-1 mb-2">
        <div class="card">
            <!-- timeline -->
            <div class="timeline timed ms-1 me-2">
                <div class="item" v-for="(history, index) in requestData.tracks" v-bind:key="history.id">
                    <span class="time">{{ getFormattedDate(history.created_at) }}</span>
                    <div class="dot bg-primary"></div>
                    <div class="content">
                        <div class="chip chip-outline chip-primary mb-05">
                            <span class="chip-label">{{ history.status }}</span>
                        </div>
                        <h4 class="title">{{ history.remarks }} <span v-if="history.status != 'Request Received'">By {{ history.user_name }}({{ history.user_level_name }}) </span></h4>
                        <div class="text text-primary" v-if="index == 0">{{ requestData.requestDetails.type }}</div>
                        <div class="text text-primary">{{ history.comments }}</div>
                    </div>
                </div>
            </div>
            <!-- * timeline -->
        </div>
        <div class="card mt-1">
            <div class="card-body">
                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="for_what">
                            ഈ അപേക്ഷ എന്തിന് വേണ്ടി ?
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="for_what"
                            v-model="formData.for_what" />

                    </div>
                    <textarea :disabled="true" v-if="formData.for_what == 'മറ്റ് കാര്യങ്ങൾക്ക്'" class="form-control"
                        rows="5" name="for_what_other" v-model="formData.for_what_other">
                                                                    </textarea>
                    <ErrorMessage name="for_what_other" class="error-feedback" />
                </div>
                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label mal-text" for="pin"> മാറ്റം വരുത്താൻ തീരുമാനിച്ച യോഗത്തിന്റെ തിയ്യതി</label>
                        <input type="date" :disabled="true" class="form-control" name="meeting_date"
                            v-model="formData.meeting_date" />
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                </div>
                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="minutes_number">
                            തീരുമാനത്തിന്റെ ക്രമ നമ്പർ
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="minutes_number"
                            v-model="formData.minutes_number" placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="minutes_number">
                            തീരുമാനത്തിന്റെ ഫോട്ടോ
                        </label>
                        <a :href="'https://guide.msoftit.com/' + this.requestData.minutes_image"
                            download="image">Download</a>
                    </div>
                </div>
                <div class="form-group basic"
                    v-if="formData.for_what == 'ഭരവഹിയെ മാറ്റാൻ' || formData.for_what == 'വകുപ്പ് മാറ്റാൻ' || formData.for_what == 'മെമ്പർഷിപ്പ് ഒഴിവാക്കാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="member">
                            ഒഴിവാക്കേണ്ട ആൾ
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="member" v-model="formData.member"
                            placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == 'വകുപ്പ് മാറ്റാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="member">
                            ഒഴിവാക്കേണ്ട വകുപ്പ്
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="old_department"
                            v-model="formData.old_department" placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == 'ഭരവഹിയെ മാറ്റാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="member">
                            ഒഴിവാക്കേണ്ട സ്ഥാനം
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="old_duty" v-model="formData.old_duty"
                            placeholder="---" autocomplete="off" />

                    </div>
                </div>

                <div class="form-group basic"
                    v-if="formData.for_what == 'ഭരവഹിയെ മാറ്റാൻ' || formData.for_what == 'വകുപ്പ് മാറ്റാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="new_member">
                            ഈ സ്ഥാനത്തേക്ക് പുതുതായി തിരഞ്ഞെടുത്ത ആൾ
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="new_member"
                            v-model="formData.new_member" placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == 'വകുപ്പ് മാറ്റാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="member">
                            പുതിയ ആൾക്ക് നൽകേണ്ട വകുപ്പ്
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="old_department"
                            v-model="formData.new_department" placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == 'ഭരവഹിയെ മാറ്റാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="member">
                            പുതിയ ആൾക്ക് നൽകേണ്ട സ്ഥാനം
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="old_duty" v-model="formData.new_duty"
                            placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == 'പുതിയ മെമ്പർഷിപ് ചേർക്കാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="name">
                            പേര്
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="name" v-model="formData.name"
                            placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == 'പുതിയ മെമ്പർഷിപ് ചേർക്കാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="name">
                            മൊബൈൽ
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="name" v-model="formData.mobile"
                            placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == 'പുതിയ മെമ്പർഷിപ് ചേർക്കാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="name">
                            വാട്സ്ആപ്പ്
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="name" v-model="formData.whatsapp"
                            placeholder="---" autocomplete="off" />

                    </div>
                </div>
                <div class="form-group basic" v-if="formData.for_what == 'പുതിയ മെമ്പർഷിപ് ചേർക്കാൻ'">
                    <div class="input-wrapper">
                        <label class="label mal-text text-primary" for="name">
                            വിങ്
                        </label>
                        <input type="text" :disabled="true" class="form-control" name="name" v-model="formData.wing"
                            placeholder="---" autocomplete="off" />

                    </div>
                </div>
            </div>
        </div>
        <div class="transparent pt-2">
            <button type="button" data-bs-toggle="modal" :data-bs-target="'#actionSheetInset'"
                v-if="responseData.permissions.action" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading">
                <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                <span>Action</span>
            </button>
        </div>
    </div>
    <!-- Default Action Sheet Inset -->
    <div class="modal fade action-sheet" :id="'actionSheetInset'" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Action</h5>
                </div>
                <div class="modal-body">
                    <ul class="action-button-list">
                        <li
                            v-if="responseData.permissions.action && $route.params.sub_level > 0 && requestData.tracks[requestData.tracks.length - 1].forwarded_to_level > 0">
                            <a href="javascript:void(0)" class="btn btn-list" @click="showActionForm(1)"
                                data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="arrow-forward-outline"></ion-icon> Forward
                                </span>
                            </a>
                        </li>
                        <li
                            v-if="responseData.permissions.action && $route.params.sub_level == 0 && responseData.permissions.guide_permission && requestData.tracks[requestData.tracks.length - 1].status != 'Request Received'">
                            <a href="javascript:void(0)" class="btn btn-list" @click="showActionForm(3, 'guide')"
                                data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="arrow-forward-outline"></ion-icon> Receive Request As Guide Admin
                                </span>
                            </a>
                        </li>
                        <li
                            v-if="responseData.permissions.action && $route.params.sub_level == 0 && responseData.permissions.guide_permission && requestData.tracks[requestData.tracks.length - 1].status == 'Request Received'">
                            <a href="javascript:void(0)" class="btn btn-list" @click="showStatusActionForm()"
                                data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="arrow-forward-outline"></ion-icon> Change Status
                                </span>
                            </a>
                        </li>
                        <li class="action-divider"></li>
                        <li>
                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                <span>
                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- * Default Action Sheet Inset -->
    <!-- actionModel -->
    <div class="modal fade action-sheet" id="actionModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Action</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group basic">
                            <label class="label" for="stay"><span>Comments</span></label>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <textarea class="form-control" rows="5" name="action_comments"
                                        v-model="action_comments">
                                                        </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group basic">
                            <button type="button" @click="action()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>{{ action_btn_text }}</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- actionModel -->

    <!-- actionModel -->
    <div class="modal fade action-sheet" id="statusActionModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Status Change</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label text-primary" for="status">
                                    Status
                                    <span class="text-danger">*</span>
                                </label>
                                <select as="select" class="form-control custom-select" v-model="status" name="status"
                                    id="select4">
                                    <option value>---Select---</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label text-primary" for="stay"><span>Comments</span></label>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <textarea class="form-control" rows="5" name="action_comments"
                                        v-model="action_comments">
                                                            </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group basic">
                            <button type="button" @click="action('change_status')" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- actionModel -->
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../services/user.service";
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import moment from 'moment'
import $ from 'jquery';
export default {
    name: 'Organization',
    components: {
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            member_list: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            formData: {
                for_what: '',
                for_what_other: '',
                change_level: '',
                minutes_number: '',
                old_department: [],
                new_department: [],
                old_duty: [],
                new_duty: [],
                remarks: '',
                comments: '',
                name: '',
                mobile: '',
                whatsapp: '',
                wing: '',
            },
            responseData: {
                meeting: [],
                participants: [],
                agenda: [],
                minutes: [],
                permissions: [],
                questians: [],
                heading: [],
                requestDetails: []
            },
            new_member_list: [],
            new_member: [],
            old_department_list: [],
            new_department_list: [],
            old_duty: [],
            new_duty: [],
            requestData: [],
            action_comments: '',
            action_btn_text: '',
            status:'',
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.meeting_id = this.$route.params.id;
            this.user_level = this.currentUser.user_level;
            this.$root.updateParent("Loading...");
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getRequest(this.$route.params.request_id);
        }
    },
    methods: {
        getFormattedDate(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        getRequest(id) {
            let data = {
                id: id,
            }
            UserService.authPostRequest('get-request', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.$root.updateParent("Request");
                        this.responseData = response.data.data;
                        this.requestData = response.data.data.list;
                        this.formData.for_what = this.requestData.sub_type;
                        this.formData.for_what_other = this.requestData.other_request;
                        this.formData.meeting_date = this.requestData.meeting_date;
                        this.formData.minutes_number = this.requestData.minutes_number;
                        // if (this.requestData.minutes_image) {
                        //     this.profile_image = 'https://guide.msoftit.com/' + this.requestData.minutes_image;
                        // }
                        if (this.requestData.member_to_change) {
                            this.formData.member = this.requestData.member_to_change.member.name + '-' + this.requestData.member_to_change.member.mobile;
                            // this.member = {
                            //     id:this.requestData.member_to_change.member_id,
                            //     member_id: this.requestData.member_to_change.member_id,
                            //     text: this.requestData.member_to_change.member.name,
                            //     mobile: this.requestData.member_to_change.member.mobile,
                            //     country_code: this.requestData.member_to_change.member.country_code
                            // }
                            // this.member_list.push(this.member);
                        }

                        if (this.requestData.new_member_to_change) {
                            this.formData.new_member = this.requestData.new_member_to_change.member.name + '-' + this.requestData.new_member_to_change.member.mobile;
                            // this.new_member = {
                            //     id:this.requestData.new_member_to_change.member_id,
                            //     member_id: this.requestData.new_member_to_change.member_id,
                            //     text: this.requestData.new_member_to_change.member.name,
                            //     mobile: this.requestData.new_member_to_change.member.mobile,
                            //     country_code: this.requestData.new_member_to_change.member.country_code
                            // }
                            // this.new_member_list.push(this.new_member);
                        }

                        // this.editable_id = this.requestData.id;
                        if (this.requestData.departments_to_change) {
                            this.formData.old_department = this.requestData.old_dep;
                        }
                        if (this.requestData.new_departments_to_change) {
                            this.formData.new_department = this.requestData.new_dep;
                        }
                        if (this.requestData.designations_to_change) {
                            this.formData.old_duty = this.requestData.old_duty;
                        }
                        if (this.requestData.new_designations_to_change) {
                            this.formData.new_duty = this.requestData.new_duty;
                        }

                        if (this.requestData.name) {
                            this.formData.name = this.requestData.name;
                            this.formData.mobile = this.requestData.country_code + ' ' + this.requestData.mobile;
                            this.formData.whatsapp = this.requestData.country_code_whatsapp + this.requestData.whatsapp;
                            if (this.requestData.wing == 1) {
                                this.formData.wing = 'Wisdom';
                            } else if (this.requestData.wing == 2) {
                                this.formData.wing = 'Youth';
                            } else if (this.requestData.wing == 3) {
                                this.formData.wing = 'Students';
                            } else if (this.requestData.wing == 4) {
                                this.formData.wing = 'Women';
                            } else if (this.requestData.wing == 6) {
                                this.formData.wing = 'Girls';
                            } else if (this.requestData.wing == 5) {
                                this.formData.wing = 'Balavedi';
                            }
                        }
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        showActionForm(type) {
            if (type == 1) {
                this.action_btn_text = 'Forward';
            } else if (type == 2) {
                this.action_btn_text = 'Reject';
            } else {
                this.action_btn_text = 'Accept Changes';
            }

            $('#actionModel').modal('show');
        },
        showStatusActionForm() {
            $('#statusActionModel').modal('show');
        },
        action(type = null) {
            let data = {
                user_level: parseInt(this.$route.params.sub_level),
                user_level_id: parseInt(this.$route.params.id),
                id: this.requestData.id,
                comments: this.action_comments,
                status : this.status,
                type : type?type:'forward'
            }
            UserService.addAdmin('store-request-action', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#actionModel').modal('hide');
                        this.getRequest(this.requestData.id);
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();

                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>

<style scoped>
.list-title {
    font-size: 16px;
    color: black;
}

.item {
    /* margin-bottom: 10px; */
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    /* height: 46px !important; */
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.w-50 {
    width: 50px !important;
}

.goals .item {
    padding: 10px 10px;
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    margin-bottom: 10px;
}

.price-icon {
    /* margin-top: -1%; */
    font-size: 24px;
}

.chip-label[data-v-3bccde2f] {
    padding: 0px 5px 0px 5px !important;
    font-size: 14px !important;
}

.pad-5-left {
    padding-left: 5px;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.questians-label {
    font-weight: 600 !important;
    font-size: 14px !important;
}

.meeting-title {
    font-size: 20px !important;
}</style>