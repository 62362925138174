<template>
    
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2" v-if="user_level==1">ജില്ല അഡ്മിൻ നിയമന റിപ്പോർട്ട്</h3>
            <h3 class="text-center mt-2" v-if="user_level==2">മണ്ഡലം അഡ്മിൻ നിയമന റിപ്പോർട്ട്</h3>
            <h3 class="text-center mt-2" v-if="user_level==3">ശാഖ അഡ്മിൻ നിയമന റിപ്പോർട്ട്</h3>
        </div>
    </div>

    <div class="section-heading">
        <div class="listview-title mt-0">
            Report List </div>
        <div class="listview-title mt-0 mal-text">
            <span>{{report_level}}</span> | <span v-if="currentUser.wing == 1">വിസ്‌ഡം</span>
            <span v-if="currentUser.wing == 2">യൂത്ത്</span>
            <span v-if="currentUser.wing == 3">സ്റ്റുഡന്റസ്</span>
            <span v-if="currentUser.wing == 4">വിമൻസ്</span>
            <span v-if="currentUser.wing == 6">Girls</span>(വിങ്)
        </div>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <ul class="listview image-listview inset text" v-if="!loading_list && user_level != 4" :set="user_level_page = (user_level==4?true:false)">
        <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
            <router-link :to="'/org/' + (user_level + 1) + '/' + sub_level.id + '/report-dashboard'" :class="{ disabled: user_level_page }">
                <a class="item">
                    <!-- <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="image"> -->
                    <div class="in">
                        <div>
                            <!-- <header>User</header> -->
                            <b class="list-title">{{ sub_level.name }}</b>
                            <footer v-if="user_level == 3 || user_level == 2 || user_level == 1">
                                <div v-for="(admin, index) in sub_level.admins_status" v-bind:key="admin.name"
                                    class="footer_div" :set="div_class = admin.count > 0 ? 'badge-info' : 'badge-danger'">
                                    <span v-if="index == 1"
                                        class="text-only first_span mal-text">അഡ്മിന്മാരെ നിയമിച്ചത് :</span>
                                    <!-- <span class="text-only" v-if="index == currentUser.wing || currentUser.wing == 1">നിയമനം</span> -->
                                    <span class="text-only" v-if="index == currentUser.wing || currentUser.wing == 1">
                                        {{admin.name}}
                                        <!-- <span v-if="currentUser.wing != 1">Admin alloted </span>
                                        <span v-if="currentUser.wing == 1">{{ admin.name }}</span> -->
                                        <!-- : {{ admin.count }} -->
                                        </span>
                                        <span class="badge badge_count" v-bind:class="div_class" v-if="index == currentUser.wing || currentUser.wing == 1">
                                        <ion-icon name="checkmark-outline" v-if="admin.count > 0"></ion-icon>
                                        <ion-icon name="close-outline" v-if="admin.count <= 0"></ion-icon>
                                        </span>
                                </div>
                            </footer>
                        </div>
                        <!-- <span class="text-muted">View</span> -->
                    </div>
                </a>
            </router-link>
        </li>
    </ul>
</template>
<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
export default {
    name: 'Reports',
    components: {
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            mobile_number: '',
            wing: '',
            user_level: "",
            sub_levels: [],
            admin_lists: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            report_level:''
            
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getReport('admin');
            if (this.user_level == this.currentUser.user_level) {
                this.editable = false;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('Reports');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getReport() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level + 1,
                id: parseInt(this.$route.params.id)
            }
            UserService.authPostRequest('get-admin-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.$root.updateParent(response.data.data.page_title);
                        this.report_level = response.data.data.page_title;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 3px;
}
.wallet-footer{
    color: black;
    
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}
.badge_count{
    margin-right: 5px;
}
.disabled {
    /* opacity: 0.5; */
    pointer-events: none;
}
</style>