<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-2 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">മെമ്പർമാരുടെ ലിസ്റ്റ്</h3>
        </div>
    </div>
    <div class="section pt-1 mt-1">
        <div class="wallet-card">
            <div class="wallet-footer">
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(1)">
                            Wisdom({{ unit.wisdom_count_filled }}/{{ unit.wisdom_count }})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(2)">
                            Youth({{ unit.youth_count_filled }}/{{ unit.youth_count }})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(3)">
                            Students({{ unit.students_count_filled }}/{{ unit.students_count }})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(4)">
                            Women({{ unit.women_count_filled }}/{{ unit.women_count }})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(5)">
                            Balavedi({{ unit.balavedi_count_filled }}/{{ unit.balavedi_count }})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList(6)">
                            Girls({{ unit.girls_count_filled }}/{{ unit.girls_count }})
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- AddMemberModel -->
    <div class="modal fade action-sheet" id="addMemberModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit Member</h5>
                    <h5 class="modal-title" v-if="add_admin">Add Member</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addMember" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="Name">Name <span class="mal-text">(പേര്)</span></label>
                                <Field name="name" type="text" :disabled="!add_admin" class="form-control" id="name"
                                    v-model="name" placeholder="Enter Name" autocomplete="off" />
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper row">
                                    <label class="label" for="country_code">Mobile Number <span class="mal-text">(മൊബൈൽ
                                            നമ്പർ)</span></label>
                                    <div class="form-group" style="width:30% !important;">
                                        <div class="input-wrapper">
                                            <Field as="select" class="form-control custom-select" v-model="country_code"
                                                name="country_code" :disabled="!add_admin" id="select4"
                                                @change="onChangeCountryCode($event)">
                                                <option v-for="item in country_code_list" :value="item.item_value"
                                                    v-bind:key="item.item_value">
                                                    {{ item.name }}</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="form-group mobile-field" style="width:68% !important; margin-left: 2%;">
                                        <div class="input-wrapper">
                                            <Field name="mobile_number" type="number" id="mobile_number"
                                                class="form-control" :disabled="!add_admin" placeholder=""
                                                autocomplete="off" v-model="mobile_number"
                                                oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 13); } if(this.value.length > 12) { this.value = this.value.slice(0, 12);} " />
                                        </div>
                                    </div>
                                    <ErrorMessage name="mobile_number" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="abroad_fields">
                                <div class="input-wrapper row">
                                    <label class="label" for="country_code">Whatsapp Number <span
                                            class="mal-text">(വാട്സ്ആപ്പ് നമ്പർ)</span></label>
                                    <div class="form-group boxed" style="width:30% !important;">
                                        <div class="input-wrapper">
                                            <Field as="select" :disabled="!add_admin" class="form-control custom-select"
                                                v-model="country_code_whatsapp" name="country_code_whatsapp" id="select4">
                                                <option v-for="item in country_code_list" :value="item.item_value"
                                                    v-bind:key="item.item_value">
                                                    {{ item.name }}</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="form-group boxed mobile-field"
                                        style="width:68% !important; margin-left: 2%;">
                                        <div class="input-wrapper">
                                            <Field name="whatsapp_number" type="number" id="whatsapp_number"
                                                class="form-control" :disabled="!add_admin" placeholder=""
                                                autocomplete="off" v-model="whatsapp_number"
                                                oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 13); } if(this.value.length > 12) { this.value = this.value.slice(0, 12);} " />
                                        </div>
                                    </div>
                                    <ErrorMessage name="whatsapp_number" class="error-feedback" />
                                </div>
                            </div>
                            <!-- <div class="form-group basic">
                                <label class="label" for="stay">Stay <span class="mal-text">(താമസം)</span></label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper mal-text">
                                        <Field as="select" class="form-control custom-select" v-model="stay" name="stay"
                                            id="select4">
                                            <option value>താമസം സെലക്ട് ചെയ്യുക</option>
                                            <option value="native">നാട്ടിൽ</option>
                                            <option value="outof_state">കേരളത്തിന് പുറത്ത്</option>
                                            <option value="outof_country">ഇന്ത്യക്കു പുറത്ത്</option>

                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="stay" class="error-feedback" />
                            </div>   -->
                            <div class="form-group basic">
                                <label class="label" for="wing">Wing <span class="mal-text">(വിങ്)</span></label>
                                <div class="input-wrapper">
                                    <Field as="select" class="form-control custom-select" v-model="wing" name="wing"
                                        id="select4">
                                        <option selected>Select Wing</option>
                                        <option value="1">Wisdom</option>
                                        <option value="2">Youth</option>
                                        <option value="3">Students</option>
                                        <option value="4">Women</option>
                                        <option value="5">Balavedi</option>
                                        <option value="6">Girls</option>

                                    </Field>
                                </div>
                                <ErrorMessage name="wing" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="editable || add_admin">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!editable && !add_admin" @click="closeAddMemberModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- DeleteMemberModel -->
    <div class="modal fade action-sheet" id="deleteMemberModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Member</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group" v-if="message_delete">
                            <div v-if="message_delete" class="error-feedback">
                                {{ message_delete }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="stay"><span class="mal-text">ഡിലീറ്റ് ചെയ്യാനുള്ള
                                    കാരണം</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper mal-text">
                                    <select as="select" class="form-control custom-select" v-model="reason_to_delete"
                                        name="stay" id="select4" @change="adminDeleteChange($event)">
                                        <option value>ഡിലീറ്റ് ചെയ്യാനുള്ള കാരണം</option>
                                        <option value="മെമ്പർഷിപ്പിന് യോഗ്യനല്ല, തെറ്റായി ചേർത്തതാണ്">മെമ്പർഷിപ്പിന്
                                            യോഗ്യനല്ല, തെറ്റായി ചേർത്തതാണ്</option>
                                        <option value="നേരത്തെ നമ്മുടെ പ്രവർത്തകൻ. ഇപ്പൊ താല്പര്യം ഇല്ല എന്ന് പറഞ്ഞു">
                                            നേരത്തെ നമ്മുടെ പ്രവർത്തകൻ. ഇപ്പൊ താല്പര്യം ഇല്ല എന്ന് പറഞ്ഞു</option>
                                        <option value="നമ്മുടെ അനുഭാവിയാണ്, മെമ്പർഷിപ്പ് വേണ്ട എന്ന് പറഞ്ഞു">നമ്മുടെ
                                            അനുഭാവിയാണ്, മെമ്പർഷിപ്പ് വേണ്ട എന്ന് പറഞ്ഞു</option>
                                        <option value="നമ്മുടെ പ്രവർത്തകനാണ്, പലതവണ ശ്രമിച്ചിട്ടും പ്രതികരിക്കുന്നില്ല">
                                            നമ്മുടെ പ്രവർത്തകനാണ്, പലതവണ ശ്രമിച്ചിട്ടും പ്രതികരിക്കുന്നില്ല</option>
                                        <option value="ഗൾഫിലാണ്, ബയോഡാറ്റ ഫില്ല് ചെയ്തിട്ടില്ല">ഗൾഫിലാണ്, ബയോഡാറ്റ
                                            ഫില്ല് ചെയ്തിട്ടില്ല</option>
                                        <option value="സ്മാർട്ട് ഫോൺ ഇല്ലാത്തതുകൊണ്ട് ബയോഡാറ്റ ഫിൽ ചെയ്തിട്ടില്ല">
                                            സ്മാർട്ട് ഫോൺ ഇല്ലാത്തതുകൊണ്ട് ബയോഡാറ്റ ഫിൽ ചെയ്തിട്ടില്ല.</option>
                                        <option value="ബയോഡാറ്റ ഫിൽ ചെയ്യാൻ അറിയില്ല, അതുകൊണ്ട് ചെയ്തിട്ടില്ല">ബയോഡാറ്റ
                                            ഫിൽ ചെയ്യാൻ അറിയില്ല, അതുകൊണ്ട് ചെയ്തിട്ടില്ല</option>
                                        <option value="കരടിലെ നമ്പർ തെറ്റി പോയി. അത് കൊണ്ട് ലോഗിൻ കഴിയുന്നില്ല">കരടിലെ
                                            നമ്പർ തെറ്റി പോയി. അത് കൊണ്ട് ലോഗിൻ കഴിയുന്നില്ല. </option>
                                        <option value="PIN ലഭിക്കുന്നില്ല. അത് കൊണ്ട് ലോഗിൻ ചെയ്യാൻ കഴിയുന്നില്ല">PIN
                                            ലഭിക്കുന്നില്ല. അത് കൊണ്ട് ലോഗിൻ ചെയ്യാൻ കഴിയുന്നില്ല. </option>
                                        <option value="Other">മറ്റു കാരണങ്ങൾ. </option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="deletable_id" />
                            </div>
                        </div>
                        <div class="form-group basic" v-if="delete_others">
                            <label class="label" for="Name"><span class="mal-text">ഡിലീറ്റ് ചെയ്യാനുള്ള
                                    കാരണം</span></label>
                            <div class="form-group boxed">
                                <Field name="name" type="text" class="form-control" v-model="delete_other_text"
                                    placeholder="കാരണം എഴുതുക" autocomplete="off" />
                            </div>
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="memberDelete()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Delete</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * AddMemberModel -->
    <!-- verificationModelModel -->
    <div class="modal fade action-sheet" id="verificationModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Member Verification</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content" v-if="currentUser.user_level == 1 && !verification_report">
                        <div class="form-group" v-if="verification_message">
                            <div v-if="verification_message" class="error-feedback">
                                {{ verification_message }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="stay"><span>Verification Status</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="verification_status"
                                        name="stay" id="select4">
                                        <option value>Verification Status</option>
                                        <option value="Y">Verified</option>
                                        <option value="N">Not Verified</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                        </div>
                        <div class="form-group basic" v-if="verification_status == 'N'">
                            <label class="label" for="stay"><span>Not Verified Reason</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="not_verification_status"
                                        name="stay" id="select4">
                                        <option value>Select Reason</option>
                                        <option v-for="item in verification_status_list" :value="item.id"
                                            v-bind:key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="Remarks"><span>Remarks</span></label>
                            <div class="form-group boxed">
                                <Field name="verification_remark" type="text" class="form-control"
                                    v-model="verification_remark" placeholder="Remarks" autocomplete="off" />
                            </div>
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="memberVerify()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>
                    <div class="action-sheet-content" v-if="currentUser.user_level <= 4 && verification_report">
                        <div class="form-group basic">
                            <label class="label mal-text" for="stay"><span>വെരിഫിക്കേഷൻ സ്റ്റാറ്റസ്</span></label>
                            <h4 class="mal-text text-success" v-if="verification_status == 'Y'">താങ്കളുടെ മെമ്പർഷിപ് ഫോം
                                വെരിഫിക്കേഷൻ പൂർത്തിയായി</h4>
                            <h4 class="mal-text text-danger" v-if="verification_status == 'N'">താങ്കളുടെ മെമ്പർഷിപ് ഫോം
                                വെരിഫിക്കേഷൻ പൂർത്തിയായിട്ടില്ല</h4>
                        </div>
                        <div class="form-group basic" v-if="verification_status == 'N' && not_verification_status">
                            <label class="label mal-text" for="stay"><span>വെരിഫിക്കേഷൻ പൂർത്തിയാവാതിരിക്കാനുള്ള
                                    കാരണം</span></label>
                            <h4 class="mal-text text-danger" v-if="not_verification_status_">
                                {{ not_verification_status_ }}</h4>
                        </div>
                        <div class="form-group basic" v-if="verification_remark">
                            <label class="label mal-text" for="Remarks"><span>നോട്ട്</span></label>
                            <h4 class="mal-text text-primary">{{ verification_remark }}</h4>
                        </div>

                        <div class="form-group basic">
                            <button type="button" class="btn btn-primary btn-block btn-lg" @click="closeVerificationModel">
                                <span>Close</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade action-sheet" id="addNerpathamModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_nerpatham">Subscription</h5>
                    <h5 class="modal-title" v-if="add_nerpatham">Subscription</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addNerpatham">
                            <div class="form-group basic">
                                <label class="label" for="stay"><span>നിലവിൽ വരിക്കാരൻ ആണോ</span></label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <select as="select" class="form-control custom-select" v-model="isSubscriber"
                                            name="isSubscriber" id="select4">
                                            <option value="Y">അതെ</option>
                                            <option value="N">അല്ല</option>
                                        </select>
                                    </div>
                                    <input type="hidden" v-model="member_id" />
                                </div>
                            </div>

                            <div class="form-group basic">
                                <label class="label" for="receipt_number"><span>Receipt Number</span></label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <div class="form-group boxed">
                                            <Field name="receipt_number" type="text" class="form-control"
                                                v-model="receipt_no" placeholder="Receipt Number" autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group basic" v-if="isSubscriber == 'Y'">
                                <label class="label" for="subscription_number"><span>Subscription Number</span></label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <div class="form-group boxed">
                                            <Field name="subscription_number" type="text" class="form-control"
                                                v-model="subscription_no" placeholder="Subscription Number"
                                                autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="editable || add_admin">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!editable && !add_admin" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Youth Conference -->
    <div class="modal fade action-sheet" id="addAbroadModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">ഇപ്പോഴത്തെ സ്റ്റാറ്റസ്</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAbroad">
                            <div class="form-group basic">
                                <label class="label" for="stay"><span>സ്റ്റാറ്റസ്</span></label>
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="isAbroad"
                                        name="isAbroad" id="select4">
                                        <option value=''>---Select---</option>
                                        <option value="നാട്ടിൽ ഉണ്ട് ആക്റ്റീവ് ആണ്">നാട്ടിൽ ഉണ്ട് ആക്റ്റീവ് ആണ്</option>
                                        <option value="നാട്ടിൽ ഉണ്ട് ആക്റ്റീവ് അല്ല">നാട്ടിൽ ഉണ്ട് ആക്റ്റീവ് അല്ല</option>
                                        <option value="വിദേശത്ത് ആണ്">വിദേശത്ത് ആണ്</option>
                                        <option value="ഒരു വിവരവും ഇല്ല">ഒരു വിവരവും ഇല്ല</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                            <div class="form-group basic" v-if="isAbroad == 'വിദേശത്ത് ആണ്'">
                                <label class="label" for="stay"><span>രാജ്യം</span></label>
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="abroadCountry"
                                        name="abroadCountry" id="select4">
                                        <option v-for="item in country_list" :value="item.item_value"
                                            v-bind:key="item.item_value">
                                            {{ item.name }}</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>

                            <div class="form-group basic" v-if="isAbroad == 'വിദേശത്ത് ആണ്'">
                                <label class="label" for="abroadPlace"><span>സ്ഥലം</span></label>
                                <div class="input-wrapper">
                                    <Field name="abroadPlace" type="text" class="form-control" v-model="abroadPlace"
                                        placeholder="Place" autocomplete="off" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="isAbroad == 'വിദേശത്ത് ആണ്'">
                                <label class="label" for="abroadMobile"><span>വിദേശത്തെ മൊബൈൽ നമ്പർ</span></label>
                                <div class="input-wrapper">
                                    <Field name="abroadMobile" type="text" class="form-control" v-model="abroadMobile"
                                        placeholder="Mobile" autocomplete="off" />
                                </div>
                            </div>
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="currentUser.user_level <= 4">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="currentUser.user_level > 4" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- * verificationModel -->
    <div class="section pt-1 mt-1" v-if="unit.new_member_add == 'Y'">
        <button type="button" class="btn btn-primary btn-sm me-1 mb-1" @click="adminAdd" v-if="editable">
            <ion-icon name="add-outline"></ion-icon>
            ഒരു വ്യക്തിയെ കരട് ലിസ്റ്റിലേക്ക് ചേർക്കാൻ ബട്ടണ് അമർത്തുക
        </button>
    </div>

    <!-- <div class="section-heading">
        <div class="listview-title mt-0 custom-title">
            List </div><br>
    </div> -->
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <!-- <div class="section mt-2 mb-2" v-if="trash_list.length && !loading">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="mal-text text-center deleted-info">സെപ്റ്റംബർ 15ന് ശേഷം ഡിലീറ്റ്
                                ചെയ്തവരുടെ എണ്ണം : <strong>{{ trash_list.length }}</strong></th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div> -->
    <!-- <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.length <= 0 && !unit.new_member_add">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>
    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && temp_member_lists.length <= 0 && unit.new_member_add">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div> -->
    <div id="search">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
                    @input="filteredMembers()">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div>
    <div class="section" v-if="!loading_list && unit.header_summary">
        <div class="row mt-1">
            <div class="col-12">
                <div class="stat-box">
                    <div class="title mal-text text-primary">{{unit.header_summary_event}}<br>പങ്കെടുക്കേണ്ടവർ</div>
                    <div class="value text-dark"><span class="text-primary">{{unit.kyc_eligible}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title mal-text text-primary">രജിസ്റ്റർ ചെയ്തവർ</div>
                    <div class="value text-dark"><span class="text-success">{{unit.kyc_registered}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title mal-text text-primary">ബാക്കി ഉള്ളവർ</div>
                    <div class="value text-dark"><span class="text-danger">{{unit.kyc_not_registered}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="listview-title mt-0 mal-text text-primary" v-if="!loading_list && temp_member_lists.length">കരട് ലിസ്റ്റ്
    </div>
    <ul class="listview image-listview inset text mt-0 mb-2" v-if="!loading_list && temp_member_lists.length">
        <li v-for="(member_list, index) in temp_member_lists" v-bind:key="member_list.id"
            :set="background = member_list.fillStatus <= 0 ? 'rgb(242 187 202)' : '#ffffff'"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ index + 1 }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img v-if="member_list.avatar != 'member.jpg' && member_list.avatar != null"
                            :src="'https://guide.msoftit.com/' + member_list.avatar" alt="avatar" class="image">
                        <img v-if="!member_list.avatar || member_list.avatar == 'member.jpg'"
                            :src="'assets/img/sample/avatar/avatar1.png'" alt="avatar" class="image">
                        <span class="button verified" v-if="member_list.verified == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified"
                            v-if="member_list.verified == 'N' && member_list.verification_status">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b> {{ member_list.name }} </b>
                        <footer>{{ member_list.country_code }} {{ member_list.mobile }}<span class="qhls-text"
                                v-if="((permissions.qhls ? permissions.qhls.view : false) && member_list.qhls_reg == 1)">,{{
                                    unit.qhls_register_text }}</span>
                            <br> <span
                                v-if="member_list.wing == 3 && (member_list.studyOrJob == 'Study' && (parseInt(member_list.studyLevel) >= 349 && (!member_list.studyCampus || !member_list.studyCourse || !member_list.studyCampus || !member_list.institution)) || (parseInt(member_list.studyLevel) == 347 && !member_list.studySubLevel))"><b
                                    class="error-feedback mal-text">സ്കൂൾ/ക്യാമ്പസ്/കോഴ്സ് വിവരങ്ങൾ
                                    പൂർത്തിയാക്കിയിട്ടില്ല</b></span>
                            <div class="form-check form-check-inline" v-if="currentUser.testing_user == 'Y5388'">
                                <input class="form-check-input" type="checkbox" :checked="member_list.verified == 'Y'"
                                    :id="'inlineCheckbox' + member_list.id" value="1"
                                    v-model="verification_check[member_list.id]"
                                    @click="verificationCheckClick(1, member_list.id)">
                                <label class="form-check-label green-tick" :for="'inlineCheckbox' + member_list.id">
                                    <ion-icon name="checkmark-outline"></ion-icon>
                                </label>
                            </div>
                            <div class="form-check form-check-inline" v-if="currentUser.testing_user == 'Y5388'">
                                <input class="form-check-input" type="checkbox"
                                    :checked="member_list.verified == 'N' && member_list.verification_status"
                                    :id="'inlineCheckbox_' + member_list.id" value="0"
                                    v-model="not_verification_check[member_list.id]"
                                    @click="verificationCheckClick(0, member_list.id)">
                                <label class="form-check-label red-cross" :for="'inlineCheckbox_' + member_list.id">
                                    <ion-icon name="close-outline"></ion-icon>
                                </label>
                            </div>
                        </footer>
                    </div>
                    <div class="progress float-right" v-if="member_list.fillStatus > 0 && member_list.verified != 'Y'"
                        :set="background_ = member_list.fillStatus >= 100 ? '#059205' : '#1a418c'">
                        <div class="progress-bar" role="progressbar"
                            :style="{ width: member_list.fillStatus + '%', background: background_ }" aria-valuenow="85"
                            aria-valuemin="0" aria-valuemax="100">{{ member_list.fillStatus }}%</div>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">

                                        <li
                                            v-if="this.permissions.members.biodata && member_list.user_id && currentUser.unit_id == member_list.unit_id && member_list.fillStatus >= 100">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="downloadBiodata(member_list)">
                                                <span>
                                                    <ion-icon name="print-outline"></ion-icon> Print Biodata
                                                </span>
                                            </a>
                                        </li>
                                        <li
                                            v-if="this.permissions.members.biodata && member_list.user_id && ((currentUser.user_level <= 4 && currentUser.unit_id == member_list.unit_id) || (currentUser.user_level <= 3 && currentUser.zone_id == member_list.zone_id) || (currentUser.user_level <= 2 && currentUser.district_id == member_list.district_id) || currentUser.user_level == 1)">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="goToBiodata(member_list)">
                                                <span>
                                                    <ion-icon name="receipt-outline"></ion-icon>View Biodata
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="this.permissions.members.call">
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.whatsapp && this.permissions.members.whatsapp">
                                            <a :href="'https://wa.me/' + member_list.whatsapp_country_code + member_list.whatsapp + '?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" @click="memberView(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" @click="adminDelete(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>

    <div class="listview-title mt-0 mal-text text-primary" v-if="!loading_list && member_lists.length">മെമ്പർമാർ</div>
    <ul class="listview image-listview inset text mt-0 mb-2" v-if="!loading_list && member_lists.length">
        <li v-for="(member_list, index) in member_lists" v-bind:key="member_list.id"
            :set="background = member_list.fillStatus <= 0 ? 'rgb(242 187 202)' : '#ffffff'"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ index + 1 }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img v-if="member_list.avatar != 'member.jpg' && member_list.avatar != null"
                            :src="'https://guide.msoftit.com/' + member_list.avatar" alt="avatar" class="image">
                        <img v-if="!member_list.avatar || member_list.avatar == 'member.jpg'"
                            :src="'assets/img/sample/avatar/avatar1.png'" alt="avatar" class="image">
                        <span class="button verified" v-if="member_list.verified == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified"
                            v-if="member_list.verified == 'N' && member_list.verification_status">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b> {{ member_list.name }} </b>
                        <footer>{{ member_list.country_code }} {{ member_list.mobile }}<span class="qhls-text"
                                v-if="((permissions.qhls ? permissions.qhls.view : false) && member_list.qhls_reg == 1)">,{{
                                    unit.qhls_register_text }}</span>
                            <span
                                v-if="member_list.wing == 3 && (member_list.studyOrJob == 'Study' && (parseInt(member_list.studyLevel) >= 349 && (!member_list.studyCampus || !member_list.studyCourse || !member_list.studyCampus || !member_list.institution)) || (parseInt(member_list.studyLevel) == 347 && !member_list.studySubLevel))"><b
                                    class="error-feedback mal-text">സ്കൂൾ/ക്യാമ്പസ്/കോഴ്സ് വിവരങ്ങൾ
                                    പൂർത്തിയാക്കിയിട്ടില്ല</b></span>
                            <span v-if="member_list.wing == 2 && member_list.isAbroad"><br>Status : <b class="mal-text"
                                    v-bind:class="(member_list.isAbroad == 'വിദേശത്ത് ആണ്' || member_list.isAbroad == 'ഒരു വിവരവും ഇല്ല' || member_list.isAbroad == 'നാട്ടിൽ ഉണ്ട് ആക്റ്റീവ് അല്ല') ? 'text-danger' : ((member_list.isAbroad == 'നാട്ടിൽ ഉണ്ട് ആക്റ്റീവ് ആണ്') ? 'text-success' : 'text-primary')">
                                    {{ member_list.isAbroad }}</b></span>
                            <span v-if="member_list.kyc_status"><br>{{member_list.kyc_status_label}} : <b class="mal-text"
                                    v-bind:class="member_list.kyc_status == 'Not Registered' ? 'text-danger' : 'text-success'">
                                    {{ member_list.kyc_status }}</b></span>
                            <div class="form-check form-check-inline" v-if="currentUser.testing_user == 'Y5388'">
                                <input class="form-check-input" type="checkbox" :checked="member_list.verified == 'Y'"
                                    :id="'inlineCheckbox' + member_list.id" value="1"
                                    v-model="verification_check[member_list.id]"
                                    @click="verificationCheckClick(1, member_list.id)">
                                <label class="form-check-label green-tick" :for="'inlineCheckbox' + member_list.id">
                                    <ion-icon name="checkmark-outline"></ion-icon>
                                </label>
                            </div>
                            <div class="form-check form-check-inline" v-if="currentUser.testing_user == 'Y5388'">
                                <input class="form-check-input" type="checkbox"
                                    :checked="member_list.verified == 'N' && member_list.verification_status"
                                    :id="'inlineCheckbox_' + member_list.id" value="0"
                                    v-model="not_verification_check[member_list.id]"
                                    @click="verificationCheckClick(0, member_list.id)">
                                <label class="form-check-label red-cross" :for="'inlineCheckbox_' + member_list.id">
                                    <ion-icon name="close-outline"></ion-icon>
                                </label>
                            </div>
                        </footer>
                    </div>
                    <div class="progress float-right" v-if="member_list.fillStatus > 0 && member_list.verified != 'Y'"
                        :set="background_ = member_list.fillStatus >= 100 ? '#059205' : '#1a418c'">
                        <div class="progress-bar" role="progressbar"
                            :style="{ width: member_list.fillStatus + '%', background: background_ }" aria-valuenow="85"
                            aria-valuemin="0" aria-valuemax="100">{{ member_list.fillStatus }}%</div>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <!-- <li v-if="this.permissions.nerpatham.create">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="nerpathamView(member_list)">
                                                <span>
                                                    <ion-icon name="book-outline"></ion-icon>നേർപഥം ചേർക്കാൻ
                                                </span>
                                            </a>
                                        </li> -->
                                        <li v-if="member_list.wing == 2">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="abroadView(member_list)">
                                                <span>
                                                    <ion-icon name="book-outline"></ion-icon>ഇപ്പോഴത്തെ സ്റ്റാറ്റസ്
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="this.permissions.qhls.create">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="qhlsView(member_list)">
                                                <span>
                                                    <ion-icon name="easel-outline"></ion-icon>QHLS രജിസ്റ്റർ ചെയ്യാൻ
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="this.permissions.verification.create">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="verificationProcess(member_list)">
                                                <span>
                                                    <ion-icon name="checkmark-done-outline"></ion-icon>Verification
                                                </span>
                                            </a>
                                        </li>
                                        <li
                                            v-if="this.permissions.verification.view && (member_list.verified == 'Y' || (member_list.verified == 'N' && member_list.verification_history))">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="verificationReport(member_list)">
                                                <span>
                                                    <ion-icon name="checkbox-outline"></ion-icon>Verification Report
                                                </span>
                                            </a>
                                        </li>
                                        <li
                                            v-if="this.permissions.members.biodata && member_list.user_id && currentUser.unit_id == member_list.unit_id && member_list.fillStatus >= 100">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="downloadBiodata(member_list)">
                                                <span>
                                                    <ion-icon name="print-outline"></ion-icon> Print Biodata
                                                </span>
                                            </a>
                                        </li>
                                        <li
                                            v-if="this.permissions.members.biodata && member_list.user_id && ((currentUser.user_level <= 4 && currentUser.unit_id == member_list.unit_id) || (currentUser.user_level <= 3 && currentUser.zone_id == member_list.zone_id) || (currentUser.user_level <= 2 && currentUser.district_id == member_list.district_id) || currentUser.user_level == 1)">
                                            <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                @click="goToBiodata(member_list)">
                                                <span>
                                                    <ion-icon name="receipt-outline"></ion-icon>View Biodata
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="this.permissions.members.call">
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.whatsapp && this.permissions.members.whatsapp">
                                            <a :href="'https://wa.me/' + member_list.whatsapp_country_code + member_list.whatsapp + '?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="this.permissions.members.edit">
                                            <a href="javascript:void(0)" @click="memberView(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="this.permissions.members.remove">
                                            <a href="javascript:void(0)" @click="adminDelete(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <!-- <div class="section-heading" v-if="!loading_list && trash_list.length">
        <div class="listview-title mt-0 error-feedback deleted-list-title">
            സെപ്റ്റംബർ 15ന് ശേഷം ഡിലീറ്റ് ചെയ്തവരുടെ ലിസ്റ്റ്</div><br>
    </div> -->
    <!-- <ul class="listview image-listview inset text mt-2 deleted-list" v-if="!loading_list && trash_list.length">
        <li v-for="(trash_member_list, index) in trash_list" v-bind:key="trash_member_list.id"
            :set="background = trash_member_list.fillStatus <= 0 ? 'rgb(242 187 202)' : trash_member_list.fillStatus >= 90 ? 'rgb(209 232 210)' : '#ffffff'"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ index + 1 }}</span>
                <img src="assets/img/sample/avatar/avatar1.png" alt="image" class="image">
                <div class="in">
                    <div class="min-width-50">
                        <b> {{ trash_member_list.name }} </b>
                        <footer>{{ trash_member_list.country_code }} {{ trash_member_list.mobile }} <br> <span
                                v-if="trash_member_list.wing == 3 && (trash_member_list.studyOrJob == 'Study' && (parseInt(trash_member_list.studyLevel) >= 349 && (!trash_member_list.studyCampus || !trash_member_list.studyCourse || !trash_member_list.studyCampus || !trash_member_list.institution)) || (parseInt(trash_member_list.studyLevel) == 347 && !trash_member_list.studySubLevel))"><b
                                    class="error-feedback mal-text">സ്കൂൾ/ക്യാമ്പസ്/കോഴ്സ് വിവരങ്ങൾ
                                    പൂർത്തിയാക്കിയിട്ടില്ല</b></span>
                        </footer>
                    </div>
                    <div class="progress float-right" v-if="trash_member_list.fillStatus > 0"
                        :set="background_ = trash_member_list.fillStatus >= 100 ? '#059205' : '#1a418c'">
                        <div class="progress-bar" role="progressbar"
                            :style="{ width: trash_member_list.fillStatus + '%', background: background_ }"
                            aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">{{ trash_member_list.fillStatus }}%
                        </div>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + trash_member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-horizontal"></ion-icon>
                        </button>
                    </div>

                    <div class="modal fade action-sheet" :id="'actionSheetInset' + trash_member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li>
                                            <a :href="'tel:' + trash_member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="trash_member_list.whatsapp">
                                            <a :href="'https://wa.me/' + trash_member_list.whatsapp_country_code + trash_member_list.whatsapp + '?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                       
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    </ul> -->
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";
import $ from 'jquery';

import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    props: {
        unit_id: null,
        permissions: Object
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            name: yup.string().matches(nameRegex, "പേര് ഇംഗീഷിൽ തന്നെ ടൈപ്പ് ചെയ്യുക!").required("പേര് ടൈപ്പ് ചെയ്യുക!"),
            mobile_number: yup.string().required("മൊബൈൽ നമ്പർ ടൈപ്പ് ചെയ്യുക!"),
            wing: yup.string().required("വിങ് സെലക്ട് ചെയ്യുക!"),
        });
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            schema,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            temp_member_lists: [],
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            deletable_id: '',
            reason_to_delete: '',
            message_delete: '',
            delete_others: false,
            delete_other_text: '',
            member_id: '',
            search_qty: '',
            verification_status_list: [],
            verification_status: '',
            not_verification_status: '',
            not_verification_status_: '',
            verification_remark: '',
            verification_member_id: '',
            verification_message: '',
            verification_report: false,
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            nerpatham: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            verification_check: [],
            not_verification_check: [],
            country_list: [],
            isAbroad: '',
            abroadPlace: '',
            abroadCountry: ''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },

    },
    mounted() {
        console.log(this.permissions.verification);
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList(1);
            if ((this.unit_id == this.currentUser.unit_id) || this.currentUser.user_level == 4) {
                this.editable = true;
            } else {
                this.editable = false;
            }
            // this.$root.updateParent('Mmebers List');
        }
        this.getComboboxValues('COUNTRY');
        this.getComboboxValues('COUNTRY_CODE');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case 'COUNTRY':
                            this.country_list = response.data.data.list.options;
                            break;

                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getMembersList(wing) {
            this.loading_list = true;
            let data = {
                unit_id: this.unit_id ? this.unit_id : this.currentUser.unit_id,
                wing: wing,
            }
            UserService.authPostRequest('get-members', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_lists = response.data.data.list;
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list;
                        this.trash_list = response.data.data.trash_list;
                        this.temp_member_lists = response.data.data.temp_list;
                        this.unit = response.data.data.unit;
                        this.wing = wing;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        memberView(admin) {
            $('#addMemberModel').modal('show');
            this.wing = admin.wing;
            this.mobile_number = admin.mobile;
            this.name = admin.name;
            this.country_code = admin.country_code;
            this.editable_id = admin.id;
            if (admin.country_code != '91') {
                this.whatsapp_number = admin.whatsapp;
                this.country_code_whatsapp = admin.whatsapp_country_code != '' ? admin.whatsapp_country_code : '91';
                this.abroad_fields = true;
            } else {
                this.abroad_fields = false;
            }

            this.add_admin = false;
        },
        adminDeleteChange(event) {
            if (event.target.value == 'Other') {
                this.delete_others = true;
            } else {
                this.delete_others = false;
            }
        },
        adminDelete(member) {
            this.deletable_id = member.id;
            $('#deleteMemberModel').modal('show');
        },
        memberDelete() {
            if (this.reason_to_delete) {
                this.message_delete = '';
                var data = {
                    id: this.deletable_id,
                    reason: !this.delete_others ? this.reason_to_delete : this.delete_other_text
                }
                // this.$swal.fire({
                //     title: '',
                //     text: "ഉറപ്പായും ഡിലീറ്റ് ചെയ്യണോ ?",
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonColor: '#3085d6',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'Yes, delete it!'
                // }).then((result) => {
                //     if (result.isConfirmed) {
                UserService.authPostRequest('delete-members', data).then(
                    (response) => {
                        if (response.data.status == 'success') {
                            this.deletable_id = ''
                            this.reason_to_delete = ''
                            this.delete_others = false
                            this.delete_other_text = ''
                            $('#deleteMemberModel').modal('hide');
                            this.$swal.fire(
                                '',
                                response.data.message,
                                'success'
                            );
                            this.getMembersList(1);
                            this.loading = false;
                        } else if (response.data.status == 'warning') {
                            $('#deleteMemberModel').modal('hide');
                            this.$swal.fire(
                                '',
                                response.data.message,
                                'warning'
                            );
                            this.getMembersList(1);
                            this.loading = false;
                        } else {
                            this.loading = true;
                        }
                    },
                    (error) => {
                        this.loading = false;
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
                //     }
                // })
            } else {
                this.message_delete = 'ഡിലീറ്റ് ചെയ്യാനുള്ള കാരണം സെലക്ട് ചെയ്യുക';
            }

        },
        downloadBiodata(member_list) {
            let data = {
                id: member_list.id
            }
            UserService.authPostRequest('download-member-pdf', data).then(
                (response) => {
                    if (response) {
                        // let blob = new Blob([response], {
                        //     type: 'application/pdf'
                        // })
                        // let link = document.createElement('a')
                        // link.href = response.data.data.link
                        // link.setAttribute('download', 'file.pdf');
                        // link.click()

                        // window.open(response.data.data.link)

                        const link = document.createElement('a');
                        link.href = response.data.data.link
                        link.setAttribute('download', member_list.name + ' biodata-form.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        this.loading = false;
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToBiodata(member_list) {
            this.$router.push('/bio-data/' + member_list.user_id);
        },
        showActionModel() {
            this.pin_number = '';
        },
        getPin(member) {
            this.loading = true;
            this.pin_number = '';
            var user = {
                id: member.id
            }
            UserService.authPostRequest('get-pin', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.pin_number = response.data.data.pin;
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    if (error)
                        this.loading = false;
                }
            );
        },
        adminAdd() {
            $('#addMemberModel').modal('show');
            this.wing = '';
            this.mobile_number = '';
            this.name = '';
            this.country_code = '91';
            this.editable_id = null;
            this.add_admin = true;
        },
        addMember(user) {
            user.id = this.editable_id;
            user.unit_id = this.unit_id ? this.unit_id : this.currentUser.unit_id;
            user.added_by = this.currentUser.id;
            this.loading = true;
            UserService.authPostRequest('members', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addMemberModel').modal('hide')
                        this.$swal('', response.data.message, 'seccess');
                        this.getMembersList(user.wing);
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        closeAddMemberModel() {
            $('#addMemberModel').modal('hide');
        },
        closeVerificationModel() {
            $('#verificationModel').modal('hide');
        },
        onChangeCountryCode(event) {
            if (event.target.value != '91') {
                this.abroad_fields = true;
            } else {
                this.abroad_fields = false;
            }
            console.log(event.target.value);
        },
        verificationProcess(member) {
            this.verification_message = '';
            this.verification_report = false;
            this.getComboboxValues('UNVERIFIED_REASON');
            this.verification_member_id = member.id;
            this.verification_status = member.verified;
            this.verification_remark = member.verification_comments;
            this.not_verification_status = member.verification_status;
            $('#verificationModel').modal('show');
        },
        verificationReport(member) {
            this.verification_message = '';
            this.verification_report = true;
            this.getComboboxValues('UNVERIFIED_REASON');
            this.verification_member_id = member.id;
            this.verification_status = member.verified;
            this.verification_remark = member.verification_comments;
            this.not_verification_status = member.verification_status;
            this.not_verification_status_ = member.reject_reason.item_value_display;

            $('#verificationModel').modal('show');
        },
        memberVerify() {
            this.verification_message = '';
            if (!this.verification_status) {
                this.verification_message = 'വെരിഫിക്കേഷൻ സ്റ്റാറ്റസ് സെലക്ട് ചെയ്യുക';
                return 0;
            }
            if (this.verification_status == "N" && !this.not_verification_status) {
                this.verification_message = 'വെരിഫൈഡ് ചെയ്യാതിരിക്കാനുള്ള കാരണം സെലക്ട് ചെയ്യുക';
                return 0;
            }
            var data = {
                id: this.verification_member_id,
                verification_status: this.verification_status,
                not_verification_status: this.not_verification_status,
                verification_remark: this.verification_remark,
            }
            UserService.authPostRequest('member-verification', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.verification_member_id = ''
                        this.verification_status = ''
                        this.verification_remark = ''
                        this.not_verification_status = ''
                        $('#verificationModel').modal('hide');
                        this.$swal.fire(
                            '',
                            response.data.message,
                            'success'
                        );
                        this.getMembersList(this.wing);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.$swal.fire(
                            '',
                            response.data.message,
                            'warning'
                        );
                        this.loading = false;
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        filteredMembers() {
            this.loading_list = true;
            let filter_list = this.orgnal_member_lists;

            filter_list = filter_list.filter((samsung) => {
                return this.search_qty.toLowerCase().split(' ').every(v => samsung.name.toLowerCase().includes(v)) || this.search_qty.toLowerCase().split(' ').every(v => samsung.mobile.toLowerCase().includes(v));
            });
            // filter_list = filter_list.filter((item) => {
            //     if (item.toLowerCase().indexOf(this.search_qty) > -1) {
            //         return item;
            //     }
            // })
            this.member_lists = filter_list;
            this.loading_list = false;
        },
        abroadView(member) {
            this.member_id = member.id;
            this.isAbroad = member.isAbroad;
            this.abroadPlace = member.abroadPlace;
            this.abroadCountry = member.abroadCountry;
            this.abroadMobile = member.abroadMobile;
            $('#addAbroadModel').modal('show');
        },
        addAbroad(user) {
            user.member_id = this.member_id;
            user.editable_id = this.editable_id;
            user.unit_id = parseInt(this.$route.params.id);
            user.isAbroad = this.isAbroad;
            user.abroadPlace = this.abroadPlace;
            user.abroadCountry = this.abroadCountry;
            UserService.addAdmin('store-abroad-status', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAbroadModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.wing);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        nerpathamView(member) {
            this.member_id = member.id;
            if (member.nerpatham) {
                this.nerpatham = member.nerpatham;
                this.editable_id = this.nerpatham.id;
                this.subscription_no = this.nerpatham.subscription_no;
                this.receipt_no = this.nerpatham.reciept_no;
            } else {
                this.nerpatham = [];
                this.editable_id = null;
                this.subscription_no = null;
                this.receipt_no = null;
            }


            $('#addNerpathamModel').modal('show');
        },
        addNerpatham(user) {
            user.member_id = this.member_id;
            user.editable_id = this.editable_id;
            user.unit_id = parseInt(this.$route.params.id);
            user.isSubscriber = this.isSubscriber;
            UserService.addAdmin('store-nerpatham-subscriptions', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addNerpathamModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        verificationCheckClick(type, id) {
            console.log(type, id, this.verification_check[id], this.not_verification_check[id]);
            if (type == 1) {
                if (this.verification_check[id] == true) {
                    this.verification_check[id] = false;
                } else if (this.verification_check[id] === undefined || this.verification_check[id] == false) {
                    this.verification_check[id] = true;
                    this.not_verification_check[id] = false;
                }
            } else {
                if (this.not_verification_check[id] == true) {
                    this.not_verification_check[id] = false;
                } else if (this.not_verification_check[id] === undefined || this.not_verification_check[id] == false) {
                    this.not_verification_check[id] = true;
                    this.verification_check[id] = false;
                }
            }
            var data = [];
            if (this.verification_check[id] == true) {
                data = {
                    id: id,
                    verification_status: 'Y',
                    not_verification_status: '',
                    verification_remark: '',
                }
            } else {
                data = {
                    id: id,
                    verification_status: 'N',
                    not_verification_status: '1002',
                    verification_remark: '',
                }
            }
            if (this.verification_check[id] == true || this.not_verification_check[id] == true) {
                UserService.authPostRequest('member-verification', data).then(
                    (response) => {
                        if (response.data.status == 'success') {
                            // this.getMembersList(this.wing);
                        }
                    },
                    (error) => {
                        this.loading = false;
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }

        },
        qhlsView(member) {
            this.member_id = member.id;
            var data = {
                id: member.id,
                wing: member.wing
            }
            this.dialog_action_params = data;
            if (member.qhls_reg == 1) {
                this.dialog_message = 'ഈ മെമ്പറെ രജിസ്റ്റർ ചെയ്തിട്ടുണ്ട്. രെജിസ്ട്രേഷൻ ക്യാൻസൽ ചെയ്യണോ?';
            } else {
                this.dialog_message = 'ഈ മെമ്പറെ രജിസ്റ്റർ ചെയ്യണോ?';
            }
            this.dialog_action = 'qhlsReg';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        qhlsReg(data) {
            UserService.addAdmin('qhls-registration', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(data.wing);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}
.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 10px 10px;
}
</style>