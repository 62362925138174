<template>
    <!-- App Capsule -->
    <div id="appCapsule">
        <div class="section mt-0 text-center">
            <h1 class="text-primary">Login</h1>
            <h4 class="text-secondary">Enter your mobile number and pin.</h4>
        </div>
        <div class="section mb-5 p-2">
            <Form @submit="handleLogin" :validation-schema="schema">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="code">Code <span class="text-danger">*</span></label>
                                        <Field as="select" class="form-control custom-select" id="code" name="code"
                                            v-model="code">
                                            <option value="" :disabled="true" selected>#</option>
                                            <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                                v-bind:value="code.item_value">
                                                {{ code.name }}</option>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="code" class="error-feedback" />
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="mobile">Mobile <span
                                                class="text-danger">*</span></label>
                                        <Field type="number" class="form-control" id="mobile" name="mobile"
                                            placeholder="Your Mobile" v-model="mobile"
                                            oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                    <ErrorMessage name="mobile" class="error-feedback" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="pin">Pin <span class="text-danger">*</span></label>
                                <Field type="password" class="form-control" name="pin" v-model="pin"
                                    oninput="javascript: if (this.value.length > 6) this.value = this.value.slice(0, 6);" />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <ErrorMessage name="pin" class="error-feedback" />
                        </div>
                        <div class="mal-text text-left forget">
                            <a href="javascript:void(0)" :class="countDown > 0 ? 'text-secondary' : 'text-primary'"
                                @click="sendOtp(this.code, this.mobile)" :disabled="countDown > 0">
                                പിൻ അറിയില്ലെങ്കിൽ <ion-icon name="phone-portrait-outline"></ion-icon> SMS /<ion-icon name="logo-whatsapp" style="color:green;"></ion-icon> Whatsapp  വഴി പിൻ ലഭിക്കാൻ ക്ലിക്ക് ചെയ്യുക.<span v-if="countDown > 0">({{
                                    countDown
                                }} Sec)</span>
                            </a>
                        </div>
                        <div class="mal-text text-left forget">
                            <a href="https://wa.me/919526221177">പിൻ നമ്പർ sms/whatsapp വരാത്തവർ ഇവിടെ ക്ലിക്ക് ചെയ്യുക.</a>
                        </div>
                        <div class="transparent pt-3">
                            <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Login</span>
                            </button>
                        </div>
                        
                        <div class="mal-text text-left forget mt-1">
                            <router-link :to="'register'">
                                <button type="button" class="btn btn-danger btn-block btn-lg">
                                    <span>പുതിയതായി രജിസ്റ്റർ ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക.</span>
                                </button>
                            </router-link>
                        </div>
                    </div>
                    <!-- <div class="card-body pb-1 row">
                        <label class="label" for="country_code">Mobile Number</label>
                        <div class="form-group boxed" style="width:40% !important;">
                            <div class="input-wrapper">
                                <Field as="select" class="form-control custom-select" v-model="country_code"
                                    name="country_code" id="select4">
                                    <option v-for="item in country_code_list" :value="item.item_value"
                                        v-bind:key="item.item_value">
                                        {{ item.name }}</option>
                                </Field>
                            </div>
                        </div>
                        <div class="form-group boxed mobile-field" style="width:58% !important; margin-left: 2%;">
                            <div class="input-wrapper">
                                <Field name="username" type="number" id="username" class="form-control"
                                    placeholder="0000000000" v-model="mobile_number"
                                    oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 13); } if(this.value.length > 12){ this.value = this.value.slice(0, 12);}" />
                            </div>
                        </div>
                        <ErrorMessage name="username" class="error-feedback" />
                        <div class="form-group" v-if="message">
                            <div v-if="message" class="error-feedback">
                                {{ message }}
                            </div>
                        </div>
                        <label class="label" for="password" v-if="login_with_pin">Pin</label>
                        <div class="form-group boxed" v-if="login_with_pin">
                            <Field name="password" type="password" class="form-control" id="smscode" placeholder=""
                                oninput="javascript: if (this.value.length > 4) this.value = this.value.slice(0, 4);" />
                        </div>
                       
                    </div> -->
                </div>
            </form>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
    <!-- * App Capsule -->
</template>
<script>
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import * as yup from "yup";

export default {
    name: "Login",
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    data() {
        const schema = yup.object().shape({
            mobile: yup.string().required('ഫിൽ ചെയ്യുക!'),
            code: yup.string().required('ഫിൽ ചെയ്യുക!'),
            pin: yup.string().required("ഫിൽ ചെയ്യുക!"),
        });

        return {
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            loading: false,
            message: "",
            schema,
            country_code: "91",
            code: "91",
            mobile: '',
            pin: '',
            login_with_pin: false,
            mobile_number: '',
            country_code_list: [],
            countDown: 0
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted() {
        this.getComboboxValues('COUNTRY_CODE');
    },
    created() {
        if (this.loggedIn) {
            localStorage.activeMenu = 'Home';
            this.$router.push("/home");
        }
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            this.country_code = '91';
                            break;

                        default:
                            break;
                    }

                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        handleLogin(user) {
            let fcm = localStorage.getItem('fcm');
            user.username = user.code + user.mobile;
            user.fcm = fcm;
            this.loading = true;
            this.$store.dispatch("auth/userLogin", user).then(
                () => {
                    localStorage.activeMenu = 'Home';
                    location.replace('/#/home')
                },
                (error) => {
                    this.loading = false;
                    if (error.response.status == 422) {
                        this.alert_type = 'Warning';
                        this.alert_message = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.$refs.alert_model.openModel();

                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.$refs.alert_model.openModel();
                    }
                }
            );
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    if (this.countDown == 0) {
                        this.otp_send_link = true;
                    }
                    this.countDownTimer()

                }, 1000)
            }
        },
        goToWhatsapp() {
            location.replace('https://wa.me/919526221177?text=For PIN Number.');
        },

        sendOtp(code, mobile) {
            this.loading = true;
            let data = {
                code: code,
                mobile: mobile
            }
            if (!this.otp_sent) {
                AuthService.getOtp(data).then(
                    (response) => {
                        if (response.data.status == 'success') {
                            this.countDown = 30;
                            this.countDownTimer();
                            if (code != "91") {
                                this.dialog_action_params = data;
                                this.dialog_message = 'വിസ്‌ഡം ഹെല്പ് ഡെസ്കിൽ നിന്നും PIN നമ്പർ നൽകുന്നതാണ്. തുടരണോ?';
                                this.dialog_action = 'goToWhatsapp';
                                this.$refs.dialog_model.openModel();
                            } else {
                                this.alert_type = 'Success';
                                this.alert_message = response.data.message;
                                this.$refs.alert_model.openModel();
                            }
                            this.otp_sent = true;
                            this.loading = false;
                        } else if (response.data.status == 'warning') {
                            this.alert_type = 'Warning';
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.loading = false;
                        } else {
                            this.alert_type = 'Error';
                            this.alert_message = response.data.message;
                            this.$refs.alert_model.openModel();
                            this.loading = false;
                        }
                    },
                    (error) => {
                        if (error.response.status == 422) {
                            this.alert_type = 'Warning';
                            this.alert_message = (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                                error.message ||
                                error.toString();
                            this.$refs.alert_model.openModel();

                        } else {
                            this.alert_type = 'Error';
                            this.alert_message = (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                                error.message ||
                                error.toString();
                            this.$refs.alert_model.openModel();
                        }
                        this.loading = false;
                    }
                );
            }
            // else {
            //     UserService.verifyOtp('verify-otp', user).then(
            //         (response) => {
            //             this.loading = false;
            //             if (response.data.status == 'success') {
            //                 this.content = response.data.user;
            //                 localStorage.setItem('user', JSON.stringify(response.data.user));
            //                 location.replace('/')
            //             }
            //             else if (response.data.status == 'error') {
            //                 this.message = response.data.message;
            //             }

            //         },
            //         (error) => {
            //             this.loading = false;
            //             this.message =
            //                 (error.response &&
            //                     error.response.data &&
            //                     error.response.data.message) ||
            //                 error.message ||
            //                 error.toString();
            //         }
            //     );
            // }

        },
        resendOtp() {
            this.otp_sent = false;
            this.sendOtp(this.form_data);
        },
        loginWithPin() {
            this.login_with_pin = true;
        },
        loginWithSms() {
            localStorage.started_country_code = this.code;
            localStorage.started_mobile_number = this.mobile;
            this.$router.push('/otp-beta');
        },
    },
};
</script>

<style scoped>
.error-feedback {
    color: red;
}

#username {
    margin-top: 0px;
}

.label {
    margin-left: -10px;
    margin-bottom: 0px;
}

.forget {
    font-size: 12px !important;
    text-decoration: underline;
}
</style>
