import axios from 'axios';

const API_URL = 'https://guide.msoftit.com/api/'; //Production
// const API_URL = 'http://127.0.0.1:8000/api/'; //Demo


class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        mobile:user.mobile,
        country_code:user.country_code,
        username: user.username,
        password: user.password,
        fcm:user.fcm
      })
      .then(response => {
        if (response.data.user.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        return response.data.user;
      });
  }

  userLogin(user) {
    return axios
      .post(API_URL + 'userLogin', {
        mobile:user.mobile,
        code:user.code,
        username: user.username,
        password: user.pin,
        fcm:user.fcm
      })
      .then(response => {
        if (response.data.user.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        return response.data.user;
      });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('currentAdminLevel')
  }

  register(data) {
    return axios.post(API_URL + 'register', data);
  }

  getOtp(data) {
    return axios.post(API_URL + 'getOtp', data);
  }
}

export default new AuthService();
