<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 custom-title">Guide Notifications</h3>
        </div>
    </div>
    <div class="section text-center" v-if="loading_page">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <ul class="listview image-listview inset mt-1" v-if="!loading_page">
        <li v-for="not in responseData.notifications" v-bind:key="not.id" :class="not.read_at?'active':''">
            <a :href="not.data.link?'javascript:void(0)':'javascript:void(0)'" class="item" @click="notificationClick(not.id)">
                <div class="icon-box bg-primary">
                    <ion-icon name="arrow-down-outline" role="img" class="md hydrated" aria-label="arrow down outline"></ion-icon>
                </div>
                <div class="in">
                    <div>
                        <div class="mb-05"><strong>{{not.data.title}}</strong></div>
                        <div class="text-small mb-05"> {{not.data.description.substring(0,20)}} </div>
                        <div class="text-xsmall text-primary">{{getFormattedDate(not.created_at)}}</div>
                    </div>
                    <span class="badge badge-primary badge-empty"></span>
                </div>
            </a>
        </li>
    </ul>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import $ from "jquery";
import UserService from "../../services/user.service";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
import moment from 'moment'
export default {
    name: "Admin List",
    props: {
        unit_id: null
    },
    components: {
        AlertModel,
        DialogModel,
    },
    data() {
        return {
            loading: false,
            loading_page: false,
            user_level: "",
            user_level_id: "",
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            permissions: {
                edit: true
            },
            responseData: {
                notifications: []
            }
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        childLength() {
            return parseInt(this.formData.lessthan_16_count);
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.$root.updateParent("Notifications");
            this.user_level = this.currentUser.user_level;
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getData(1);
        }

        window.onpopstate = function() {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        getFormattedDate(date) {
            return moment(date).format("DD-MM-YYYY h:s A")
        },
        getData(id) {
            let data = {
                id: id
            }
            UserService.authPostRequest('get-notifications', data).then(
                (response) => {

                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.permissions = response.data.data.permissions;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        notificationClick(id){
            let data = {
                id: id
            }
            UserService.authPostRequest('unread-notification', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.getData(1);
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    }
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}

.custom-file-upload {
    position: relative;
    display: flex;
    width: 50%;
    height: 100px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #060606;
}
</style>
