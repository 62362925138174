<template>
  <div class="section text-center mt-1" v-if="loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <ul class="listview image-listview inset mt-1" v-if="!loading">
    <li v-for="event in event_list" v-bind:key="event.name">
      <a :href="event.url" class="item">
        <div class="in">
          <div>
            <header>{{ event.date }}</header>
            <b class="text-primary">{{ event.name }}</b>
            <footer>{{ event.place }}</footer>
          </div>
          <ion-icon name="chevron-forward-outline" style="font-size: 60px"></ion-icon>
        </div>
      </a>
    </li>
  </ul>
  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>
<script>
import $ from "jquery";
import UserService from "../../services/user.service";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
  name: "Admin List",
  props: {
    unit_id: null,
  },
  components: {
    AlertModel,
    DialogModel,
  },
  data() {
    return {
      alert_type: "",
      alert_message: "",
      dialog_message: "",
      dialog_action: "",
      dialog_action_params: [],

      loading: false,
      event_list: [],
      permissions: [],
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      location.replace("/");
    }
    this.$root.updateParent("Upcoming Events");
    this.getEvents();
    window.onpopstate = function () {
      if ($("body").hasClass("modal-open")) {
        location.reload();
      }
    };
  },

  methods: {
    getEvents() {
      this.loading = true;
      let data = {
        slug: this.$route.params.slug,
        page: "event_dashboard",
      };
      UserService.authPostRequest(`get-event-list`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.event_list = response.data.data.events;
            this.permissions = response.data.data.permissions;
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
<style scoped>
.section-heading {
  padding: 10px 10px 0px 10px;
}

.error-feedback {
  color: red;
}

.listview {
  margin-bottom: 20px;
}

.edit-btn {
  margin-left: 8px;
}

.progress {
  width: 80px;
}

.listview>li footer {
  color: #171617;
}

.min-width-50 {
  min-width: 60% !important;
}

.action-button-list>li {
  min-height: 1px !important;
}

.sl-no {
  font-weight: 900;
  margin-right: 12px;
}

.searchbox .form-control {
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid #bdbdd5;
  height: 36px;
  padding: 0 10px 0 10px;
}

.form-check {
  padding: 0;
  margin: 0;
  padding-left: 10px;
  min-height: auto;
  height: auto;
  padding-bottom: 5px;
}

.input-group .input-group-text {
  background: transparent;
  border: 0;
  border: 1px solid #1a418c;
  /* border-top: 1px solid #1a418c; */
  border-radius: 5px 5px 5px 5px;
  font-weight: 400;
  color: #27173e;
  height: auto;
  width: auto;
  padding: 0px 10px 0px 10px;
}

.stat-box {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
  border-radius: 10px;
  padding: 10px 10px;
}

.wallet-card {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(209 26 26 / 9%);
  border-radius: 10px;
  padding: 10px 10px;
  position: relative;
  z-index: 1;
}

.wallet-card .balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  height: auto;
}

.wallet-card .wallet-footer .item .icon-wrapper {
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 14px;
  padding-left: 10px;
}

.menu-title {
  color: #fff !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  text-align: left !important;
}

.wallet-card .balance .total {
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1em;
  font-size: 24px;
}

.wallet-card .balance .title {
  color: #27173e;
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-bottom: 0px;
}
</style>
