<template>
    <!-- Wallet Card -->
    <div class="section text-center mt-2" v-if="loading">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section pt-1">
        <div class="wallet-card" v-if="permissions.youth_video">
            <div class="listed-detail mt-0 page-title">
                <h3 class="text-center mal-text mt-1 custom-title">{{responseData.video.title}}</h3>
            </div>
            <iframe class="mt-1" width="100%" height="250" :src="responseData.video.link"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" controls='0' allowfullscreen></iframe>
        </div>
    </div>
    <div class="section mt-1" v-if="responseData.common_links.length && permissions.common_links">
        <div class="transactions">
            <a v-for="common_links in responseData.common_links" v-bind:key="common_links" :href="common_links.link"
                class="item">
                <div class="detail">
                    <img :src="common_links.image" alt="img" class="image-block imaged w48">
                    <div>
                        <strong class="mal-text">{{ common_links.title }}</strong>
                        <p class="mal-text">{{ common_links.desc }}</p>
                    </div>
                </div>
                <div class="right">
                    <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                </div>
            </a>
        </div>
    </div>
    <div v-if="((currentUser.user_level <= 4 && permissions.user ? permissions.user.user_level <= 4 : false) && !loading)">
        <div class="listview-title mt-2" v-if="user_level != 4">{{ sub_level_list_name }}
        </div>
        <div class="section text-center" v-if="loading && user_level != 4">
            <div class="spinner-border text-secondary" role="status"></div>
        </div>
        <ul class="listview image-listview inset text" v-if="user_level != 4">
            <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
                <a href="javascript:void(0)" class="item">
                    <!-- <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="image"> -->
                    <div class="in">
                        <router-link :to="'org/' + (user_level + 1) + '/' + sub_level.id">
                            <div>
                                <!-- <header>User</header> -->
                                <b class="list-title">{{ sub_level.name }}</b>
                                <!-- <footer>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="chip chip-media">
                                                <i class="chip-icon bg-primary">WS</i>
                                                <span class="chip-label">{{ sub_level.wisdom_count_filled }}/{{
                                                        sub_level.wisdom_count
                                                }}</span>
                                            </div>
                                            <div class="chip chip-media">
                                                <i class="chip-icon bg-success">YT</i>
                                                <span class="chip-label">{{ sub_level.youth_count_filled }}/{{
                                                        sub_level.youth_count
                                                }}</span>
                                            </div>

                                        </div>
                                        <div class="col-12 mt-1">
                                            <div class="chip chip-media">
                                                <i class="chip-icon bg-danger">ST</i>
                                                <span class="chip-label">{{ sub_level.students_count_filled }}/{{
                                                        sub_level.students_count
                                                }}</span>
                                            </div>
                                            <div class="chip chip-media">
                                                <i class="chip-icon bg-pink">WM</i>
                                                <span class="chip-label">{{ sub_level.women_count_filled }}/{{
                                                        sub_level.women_count
                                                }}</span>
                                            </div>

                                        </div>
                                        <div class="col-12 mt-1">
                                            <div class="chip chip-media">
                                                <i class="chip-icon bg-pink-dark">GL</i>
                                                <span class="chip-label">{{ sub_level.girls_count_filled }}/{{
                                                        sub_level.girls_count
                                                }}</span>
                                            </div>
                                            <div class="chip chip-media">
                                                <i class="chip-icon bg-warning">BL</i>
                                                <span class="chip-label">{{ sub_level.balavedi_count_filled }}/{{
                                                        sub_level.balavedi_count
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chip chip-media mt-1">
                                        <i class="chip-icon bg-secondary">Total</i>
                                        <span class="chip-label">{{ sub_level.members_count_filled }}/{{
                                                sub_level.members_count
                                        }}</span>
                                    </div>
                                </footer> -->
                            </div>
                        </router-link>
                        <div class="card-button dropdown">
                            <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                                :data-bs-target="'#actionSheetInset' + sub_level.id">
                                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                            </button>
                        </div>
                        <div class="modal fade action-sheet" :id="'actionSheetInset' + sub_level.id" tabindex="-1"
                            role="dialog">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Action</h5>
                                    </div>
                                    <div class="modal-body">
                                        <ul class="action-button-list">
                                            <li v-if="currentUser.user_level <= 3">
                                                <a href="javascript:void(0)" class="btn btn-list" data-bs-dismiss="modal"
                                                    @click="nerpathamTarget(sub_level)">
                                                    <span>
                                                        <ion-icon name="book-outline"></ion-icon>നേർപഥം ടാർഗറ്റ്
                                                    </span>
                                                </a>
                                            </li>
                                            <li class="action-divider"></li>
                                            <li>
                                                <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="close-outline"></ion-icon> Cancel
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <span class="text-muted">View</span> -->
                    </div>
                </a>

            </li>
        </ul>
        <add-members ref="add_member" v-if="permissions.members ? permissions.members.view : false"
            v-bind:current_user="currentUser" v-bind:permissions="permissions"></add-members>
    </div>

    <div class="section mt-1" v-if="(currentUser.user_level == 5 && !loading)">
        <div class="card">
            <div class="card-body pt-0 pb-0 text-center">
                <img src="assets/logo.png" alt="image" class="imaged w-50 ">
                <h2 class="mt-0 mal-text">{{ sub_level.name_mal }} ശാഖ</h2>
            </div>
        </div>
        <div class="wallet-card  mt-1">
            <div class="wallet-footer row">
                <div class="item col-12" v-if="permissions.view_events">
                    <router-link :to="'/event-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-primary">
                                <ion-icon name="mic-outline"></ion-icon>
                                <strong class="menu-title">Events</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12" v-if="permissions.nerpatham ? permissions.nerpatham.view : false">
                    <router-link
                        :to="'/org/' + (currentUser.user_level - 1) + '/' + currentUser.unit_id + '/nerpatham-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="book-outline"></ion-icon>
                                <strong class="menu-title mal-text">നേർപഥം ചേർക്കാനും ചേർത്തവരെ കാണാനും ഇവിടെ
                                    അമർത്തുക</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <!-- <div class="item col-12" v-if="permissions.peroface_confirmation ? permissions.peroface_confirmation : false">
                        <router-link :to="'/proface-confirmation'">
                            <a href="javascript:void(0)">
                                <div class="icon-wrapper back-color-blue1">
                                    <ion-icon name="checkmark-done-outline"></ion-icon>
                                    <strong class="menu-title">Proface Confirmation</strong>
                                </div>
                            </a>
                        </router-link>
                    </div> -->
                <div class="item col-12"
                    v-if="permissions.unit_election ? permissions.unit_election.presiding_officer : false">
                    <router-link :to="'/org/' + currentUser.id + '/presiding-units'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-primary">
                                <ion-icon name="list-outline"></ion-icon>
                                <strong class="menu-title mal-text">താങ്കൾ പ്രെസീഡിങ് ഓഫീസർ ആയ ശാഖകൾ ലഭിക്കാൻ ഇവിടെ
                                    അമർത്തുക</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12"
                    v-if="permissions.zone_election ? permissions.zone_election.presiding_officer : false">
                    <router-link :to="'/org/' + currentUser.id + '/presiding-zones'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-primary">
                                <ion-icon name="list-outline"></ion-icon>
                                <strong class="menu-title mal-text">താങ്കൾ പ്രെസീഡിങ് ഓഫീസർ ആയ മണ്ഡലങ്ങൾ ലഭിക്കാൻ ഇവിടെ
                                    അമർത്തുക</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-12"
                    v-if="permissions.district_election ? permissions.district_election.presiding_officer : false">
                    <router-link :to="'/org/' + currentUser.id + '/presiding-districts'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-primary">
                                <ion-icon name="receipt-outline"></ion-icon>
                                <strong class="menu-title mal-text">താങ്കൾ പ്രെസീഡിങ് ഓഫീസർ ആയ ജില്ലകൾ ലഭിക്കാൻ
                                    ഇവിടെ
                                    അമർത്തുക</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="wallet-card  mt-1" v-if="permissions.extra_links">
            <div class="wallet-footer row">
                <div class="item col-12" v-for="extra_link in responseData.extra_links" v-bind:key="extra_link">
                    <router-link :to="extra_link.url">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon :name="extra_link.icon"></ion-icon>
                                <strong class="menu-title" v-bind:class="extra_link.class">{{ extra_link.btn_text
                                }}</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="wallet-card  mt-1" v-if="permissions.peroface">
            <div class="wallet-footer row">
                <div class="item col-6"
                    v-if="permissions.peroface_confirmation ? permissions.peroface_confirmation : false">
                    <router-link :to="'/proface-confirmation'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="checkmark-done-outline"></ion-icon>
                                <strong class="menu-title">Proface Confirmation</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6"
                    v-if="permissions.peroface_get_delegate ? permissions.peroface_get_delegate : false">
                    <router-link :to="'/proface-get'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="search-outline"></ion-icon>
                                <strong class="menu-title">Proface Get Delegate</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-6" v-if="permissions.peroface_parking ? permissions.peroface_parking : false">
                    <router-link :to="'/event/' + user_level + '/' + this.user_level_id + '/parking-cordinator/proface'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper back-color-blue1">
                                <ion-icon name="car-outline"></ion-icon>
                                <strong class="menu-title">Proface Parking</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
            </div>
        </div>
        <!-- <div class="card mt-1">
            <div class="card-body pt-0 pb-0 text-center">
                <h2 class="mt-0 mal-text">ബയോഡാറ്റ പൂരിപ്പിക്കുമ്പോൾ ശ്രദ്ധിക്കേണ്ട കാര്യങ്ങൾ.</h2>
            </div>
            <ul class="listview image-listview text">
                <li>
                    <a class="item">
                        <div class="in">
                            <div>1. പേരും അഡ്രസ്സും ഇംഗ്ലീഷിൽ തന്നെ ടൈപ്പ് ചെയ്യുവാൻ ശ്രദ്ധിക്കണം.</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="item">
                        <div class="in">
                            <div>2. ഫോൺ നമ്പറും whatsapp നമ്പറും ചേർക്കുമ്പോൾ രാജ്യത്തിന്റെ ശരിയായ കോഡ്
                                തിരഞ്ഞെടുക്കാൻ മറക്കരുത്.</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="item">
                        <div class="in">
                            <div>3. ശരിയായ രക്തഗ്രൂപ്പ് ലിസ്റ്റിൽ നിന്നും തിരഞ്ഞെടുക്കണം. അറിയില്ലെങ്കിൽ "Not known"
                                എന്ന ഓപ്ഷൻ തിരഞ്ഞെടുക്കണം. തെറ്റായ രക്തഗ്രൂപ്പ് ഒരിക്കലും തിരഞ്ഞെടുക്കരുത്.</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="item">
                        <div class="in">
                            <div>4. സ്ഥിരം മേൽവിലാസവും ഇപ്പോഴത്തെ മേൽവിലാസവും ഒന്നുതന്നെയാണെങ്കിൽ ആദ്യത്തേത്
                                പൂരിപ്പിച്ച ശേഷം "ഇപ്പോഴത്തെ അഡ്രസ്സും സ്ഥിരം അഡ്രസ്സും ഒന്നുതന്നെയാണ് " എന്ന ഓപ്ഷൻ
                                സെലക്ട് ചെയ്താൽ മതിയാകുന്നതാണ്.</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="item">
                        <div class="in">
                            <div>5. ഓരോ പേജിലെയും വിവരങ്ങൾ കൃത്യമായി പൂരിപ്പിച്ച ശേഷം താഴെ ഉള്ള "Save & Next"
                                ക്ലിക്ക് ചെയ്ത് അടുത്ത പേജിലേക്ക് പോകാവുന്നതാണ്.</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="item">
                        <div class="in">
                            <div>6. പൂരിപ്പിക്കുന്ന വ്യക്തിക്ക് ഏറ്റവും അനുയോജ്യമായ വിദ്യാഭ്യാസ യോഗ്യത ലിസ്റ്റിൽ
                                ലഭ്യമല്ലെങ്കിൽ "other qualifications" എന്നത് തിരഞ്ഞെടുക്കണം.</div>
                        </div>
                    </a>
                </li>

            </ul>
        </div> -->
    </div>

        <!-- * AddAdminModel -->
        <div class="modal fade action-sheet" id="nerpathamTargetModel" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Nerpatham Target</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <Form @submit="addNerpathamTarget">
                                <div class="form-group basic">
                                    <label class="label" for="Target">Target</label>
                                        <Field name="target" type="number" class="form-control" id="target"
                                            v-model="nerpatham_target" placeholder="Enter Target" />
                                    <input type="hidden" v-model="sub_level_id">
                                    <div class="form-group" v-if="message">
                                        <div v-if="message" class="error-feedback">
                                            {{ message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group basic">
                                    <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                        <span>Save</span></button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field } from "vee-validate";
import UserService from "../services/user.service";
import AddMembers from './MembersList.vue';
import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
import $ from 'jquery';
export default {
    name: 'Organization',
    components: {
        Form,
        Field,
        AddMembers,
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            message: "",
            user_level: "",
            permissions: [],
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
            unit: [],
            nerpatham_target: '',
            sub_level_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            sub_level: [],
            responseData: {
                video:[],
                common_links: []
            }
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = this.currentUser.user_level;
            if (this.user_level == 1) {
                this.sub_level_list_name = 'District List';
                this.user_level_id = 0;
                this.$root.updateParent("Loading...");
            } else if (this.user_level == 2) {
                this.sub_level_list_name = 'Zone List';
                this.user_level_id = this.currentUser.district_id;
                this.$root.updateParent("Loading...");
            }
            else if (this.user_level == 3) {
                this.sub_level_list_name = 'Unit List';
                this.user_level_id = this.currentUser.zone_id;
                this.$root.updateParent("Loading...");
            }
            else if (this.user_level == 4) {
                this.sub_level_list_name = 'Members List';
                this.user_level_id = this.currentUser.unit_id;
                this.$root.updateParent("Loading...");
            }
            this.getSubLevels();
        }
    },
    methods: {
        openAddMemberModel() {
            this.$refs.add_member.adminAdd();
        },

        getSubLevels() {
            this.loading = true;
            if (this.user_level == 2) {
                this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.currentUser.unit_id;
            }
            let data = {
                sub_level_type: this.user_level + 1,
                id: this.user_level_id
            }
            UserService.getSubLevels('get-sub-levels', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.responseData = response.data.data;
                        this.sub_levels = response.data.data.list;
                        this.sub_level = response.data.data.sub_level;
                        this.permissions = response.data.data.permission;
                        this.$root.updateParent(response.data.data.page_title);
                        this.unit = response.data.data.unit;
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (this.message == 'Unauthenticated.') {
                        location.replace('/');
                    }
                }
            );
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/level-2-org/' + (this.user_level + 1) + '/' + sub_level_id);
        },
        nerpathamTarget(sub_level = null) {
            this.nerpatham_target = sub_level.nerpatham_target;
            this.sub_level_id = sub_level.id;
            $('#nerpathamTargetModel').modal('show');
        },
        addNerpathamTarget(data) {
            data.level = this.currentUser.user_level + 1;
            data.level_id = parseInt(this.sub_level_id);
            UserService.addAdmin('set-nerpatham-target', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.getSubLevels();
                        $('#nerpathamTargetModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>
<style scoped>
.list-title {
    font-size: 16px;
    color: black;
}

.item {
    /* margin-bottom: 10px; */
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    /* height: 46px !important; */
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}


.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}


.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.w-50 {
    width: 50px !important;
}



.transactions .item {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 5px 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price {
    font-size: 50px;
}
</style>