<template>
    <!-- Wallet Card -->
    <div class="section pt-1">
        <div class="wallet-card">
            <!-- Balance -->
            <div class="balance">
                <div class="left">
                    <span class="title mal-text">പ്രോഗ്രാം ആക്ടിവിറ്റി ട്രാക്കിംഗ്</span>
                    <h1 class="total text-primary" style="font-size: 16px;">{{ level_name }}</h1>
                </div>
                <div class="right row">
                    <a href="/#/org/activity-tracking" class="button col-6 mr-1" v-if="currentUser.user_level != 4">
                        <ion-icon name="list-outline"></ion-icon>
                    </a>
                    <!-- <a href="javascript:void(0);" class="button col-6" @click="adminAdd">
                        <ion-icon name="add-outline"></ion-icon>
                    </a> -->
                </div>
            </div>
        </div>
    </div>
    <div class="section">
        <Form @submit="addAdmin">
            <div class="card" style="border-radius: 5px;">
                <div class="card-body">
                    <div class="form-group basic">
                        <label class="label" for="program_item"><span>പ്രോഗ്രാം</span></label>
                        <select as="select" class="form-control custom-select" v-model="program_item"
                            name="program_item" id="select4" @change="getFields(program_item)">
                            <option value="Y">---Select---</option>
                            <option v-for="programItem in programItemList" v-bind:value="programItem.id"
                                v-bind:key="programItem.id">{{
                        programItem.name_ml }}
                            </option>
                        </select>
                        <ErrorMessage name="member" class="error-feedback" />
                    </div>
                    <div class="form-group basic" v-if="selected_program_item.status">
                        <label class="label" for="program_status"><span>പ്രോഗ്രാം സ്റ്റാറ്റസ്</span></label>
                        <select as="select" class="form-control custom-select" v-model="program_status"
                            name="program_status" id="select4">
                            <option value>---Select---</option>
                            <option value="Upcoming">Upcoming(വരാനിരിക്കുന്നത്)</option>
                            <option value="Done">Done(കഴിഞ്ഞു)</option>
                            <option value="Cancelled">Cancelled(ഒഴിവാക്കി)</option>
                        </select>
                    </div>
                    <div class="form-group basic" v-if="selected_program_item.date">
                        <label class="label text-mal" for="date"><span>തിയ്യതി</span></label>
                        <div class="form-group">
                            <Field name="program_date" type="date" class="form-control" v-model="program_date"
                                autocomplete="off" />
                        </div>
                    </div>
                    <div class="form-group basic" v-if="selected_program_item.place">
                        <label class="label text-mal" for="place"><span>സ്ഥലം</span></label>
                        <div class="form-group">
                            <Field name="program_place" type="text" class="form-control" v-model="program_place"
                                autocomplete="off" />
                        </div>
                    </div>
                    <div class="form-group basic" v-if="selected_program_item.duty">
                        <label class="label text-mal" for="duty"><span>ചുമതല</span></label>
                        <div class="form-group">
                            <Field name="program_duty" type="text" class="form-control" v-model="program_duty"
                                autocomplete="off" />
                        </div>
                    </div>
                    <div class="form-group basic" v-if="selected_program_item.faculty">
                        <label class="label text-mal" for="faculty"><span>പ്രാസംഗികൻ</span></label>
                        <div class="form-group">
                            <Field name="program_faculty" type="text" class="form-control" v-model="program_faculty"
                                autocomplete="off" />
                        </div>
                    </div>
                    <div class="form-group" v-if="message">
                        <div v-if="message" class="error-feedback">
                            {{ message }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-1" style="border-radius: 5px;" v-for="(item, index) in selected_program_item.options"
                v-bind:key="item.id">
                <!-- <div class="card-header">
                    {{ item.title }}
                </div> -->
                <div class="card-body" style="padding: 10px;" v-if="item.multiple">
                    <!-- Multiple Text -->
                    <!-- <h5 class="card-title"></h5> -->
                    <h6 class="card-subtitle mb-1">{{ item.title }}</h6>
                    <div class="form-group basic" v-for="(faculty, index_) in programFaculties[item.id]" :key="index_">
                        <div class="form-group" v-if="item.type == 'text'">
                            <input v-model="custom_field[index].multi[index_]" type="text" class="form-control mr-3"
                                autocomplete="off" :name="'custom_field[' + index + '][' + index_ + ']'"
                                style="width: 70%;display: inline-block;" />
                            <button @click="addFaculty(item.id)" v-if="(index_ + 1) == programFaculties[item.id].length"
                                type="button" class="btn btn-icon btn-info me-1"
                                style="width: 10%;display: inline-block;">
                                <ion-icon name="add-outline" role="img" class="md hydrated"
                                    aria-label="add outline"></ion-icon>
                            </button>
                            <button type="button" @click="deleteFaculty(index, index_, item.id)"
                                class="btn btn-icon btn-danger me-1" style="width: 10%;display: inline-block;"
                                v-if="index_ > 0">
                                <ion-icon name="trash-outline" role="img" class="md hydrated"
                                    aria-label="add outline"></ion-icon>
                            </button>
                        </div>
                        <div class="form-group" v-if="item.type == 'select'">
                            <Field as="select" class="form-control custom-select mr-3"
                                v-model="custom_field[index].multi[index_]"
                                :name="'custom_field[' + index + '][' + index_ + ']'" id="select4"
                                style="width: 70%;display: inline-block;">
                                <option value>---Select---</option>
                                <option v-for="option in JSON.parse(item.options)" v-bind:value="option"
                                    v-bind:key="option">{{
                        option }}</option>
                            </Field>
                            <button @click="addFaculty(item.id)" v-if="(index_ + 1) == programFaculties[item.id].length"
                                type="button" class="btn btn-icon btn-info me-1"
                                style="width: 10%;display: inline-block;">
                                <ion-icon name="add-outline" role="img" class="md hydrated"
                                    aria-label="add outline"></ion-icon>
                            </button>
                            <button type="button" @click="deleteFaculty(index, index_, item.id)"
                                class="btn btn-icon btn-danger me-1" style="width: 10%;display: inline-block;"
                                v-if="index_ > 0">
                                <ion-icon name="trash-outline" role="img" class="md hydrated"
                                    aria-label="add outline"></ion-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="padding: 10px;" v-else>
                    <h6 class="card-subtitle mb-1">{{ item.title }}</h6>
                    <div class="form-group basic" v-if="item.type == 'radio'">
                        <div class="form-check form-check-inline" v-for="option in JSON.parse(item.options)"
                            v-bind:key="option">
                            <Field class="form-check-input" type="radio" :value="option" v-model="custom_field[index]"
                                :name="'radioInline[' + index + ']'" :id="'radioInlineDefault_' + option + '_' + index" />&nbsp;
                            <label class="form-check-label" :for="'radioInlineDefault_' + option + '_' + index">{{ option
                                }}</label>
                        </div>
                    </div>
                    <div class="form-group basic" v-if="item.type == 'checkbox'">
                        <div class="form-check form-check-inline" v-for="option in JSON.parse(item.options)"
                            v-bind:key="option">
                            <Field class="form-check-input" type="checkbox" :value="option"
                                v-model="custom_field[index]" :name="'checkBoxInline[' + index + '][]'"
                                :id="'checkBoxInline_' + option + '_' + index" />&nbsp;
                            <label class="form-check-label" :for="'checkBoxInline_' + option + '_' + index">{{ option
                                }}</label>
                        </div>
                    </div>
                    <div class="form-group basic" v-else-if="item.type == 'date'">
                        <Field type="date" class="form-control" :name="'custom_field[' + index + ']'" placeholder="..."
                            v-model="custom_field[index]" />
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                    <div class="form-group basic" v-else-if="item.type == 'text'">
                        <input type="text" class="form-control" :name="'custom_field[' + index + ']'" placeholder="..."
                            v-model="custom_field[index]" />
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                    <div class="form-group basic" v-else-if="item.type == 'number'">
                        <input type="number" class="form-control" :name="'custom_field[' + index + ']'"
                            placeholder="..." v-model="custom_field[index]" />
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                    <div class="form-group basic" v-else-if="item.type == 'select'">
                        <Field as="select" class="form-control custom-select" v-model="custom_field[index]"
                            :name="'custom_field[' + index + ']'" id="select4">
                            <option value>---Select---</option>
                            <option v-for="option in JSON.parse(item.options)" v-bind:value="option"
                                v-bind:key="option">{{
                        option }}</option>
                        </Field>
                    </div>
                </div>
            </div>

            <div class="form-group basic mt-2">
                <button type="submit" class="btn btn-primary btn-block btn-lg"
                    :disabled="loading || !selected_program_item.id">
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                    <span>Save</span></button>
            </div>
        </Form>
    </div>

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            level_name: '',

            programItemList: [],
            program_item: '',
            selected_program_item: [],
            program_status: '',
            program_date: '',
            program_place: '',
            program_duty: '',
            program_faculty: '',
            program_area: '',
            programFaculties: [],
            custom_field: []
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.currentUser.user_level);
            if (this.user_level == 4) {
                this.user_level_id = parseInt(this.currentUser.unit_id);
            } else if (this.user_level == 3) {
                this.user_level_id = parseInt(this.currentUser.zone_id);
            } else if (this.user_level == 2) {
                this.user_level_id = parseInt(this.currentUser.district_id);
            } else if (this.user_level == 1) {
                this.user_level_id = 0;
            }
            this.getProgramList();

            this.$root.updateParent('Activity Tracking');
        }
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        addFaculty(item_id) {
            this.programFaculties[item_id].push(''); // Add an empty field
        },
        deleteFaculty(index, index_, item_id) {
            this.programFaculties[item_id].splice(index, 1); // Remove the field at the specified index
            if (index_ > 0 && index < this.custom_field[index].multi.length) {
                // Remove the item at the specified index from the multi array
                this.custom_field[index].multi.splice(index_, 1);
            }
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            this.getFields(admin.org_program_id);
            this.program_item = admin.org_program_id;
            this.program_date = admin.date;
            this.program_place = admin.place;
            this.program_duty = admin.duty;
            this.program_faculty = admin.faculty;
            this.program_status = admin.status;
            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.program_item = '';
            this.program_date = '';
            this.program_status = '';
            this.program_place = '';
            this.program_duty = '';
            this.program_faculty = '';
            this.program_area = '';
            this.editable_id = null;
            this.add_admin = true;
            this.edit_admin = false;
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            this.loading =
                user.editable_id = this.editable_id;
            user.program_id = this.program_item;
            user.program_status = this.program_status;
            user.custom_field = this.custom_field;
            UserService.addAdmin('store-activity', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        setTimeout(() => {
                            location.replace('/#/org/activity-tracking');
                        }, 2000);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delate-activity', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        getMembersList(page = 1, id) {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                user_level_id: this.user_level_id,
                id: id
            }
            UserService.authPostRequest(`get-activity-list?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.permissions = response.data.data.permissions;
                        this.member_lists = response.data.data.list;
                        this.program_item = this.member_lists.org_program_id;
                        this.getFields(this.member_lists.org_program_id);
                        this.program_status = this.member_lists.status;
                        this.editable_id = this.member_lists.id;
                        this.program_date = this.member_lists.date;
                        this.program_place = this.member_lists.place;
                        this.program_duty = this.member_lists.duty;
                        this.program_faculty = this.member_lists.faculty;
                        this.level_name = response.data.data.level_name;
                        if (this.$route.params.id) {
                            if (this.member_lists.options) {
                                this.custom_field = [];
                                this.member_lists.options.forEach(element => {
                                    if (element.multiple == 1) {
                                        let multiple = {
                                            multi: JSON.parse(element.value)
                                        };
                                        this.custom_field.push(multiple);
                                        let option_count = JSON.parse(element.value).length;
                                        for (let i = 1; i < option_count; i++) {
                                            this.programFaculties[element.org_programs_other_feild_id].push('');
                                        }
                                    } else {
                                        this.custom_field.push(element.value);
                                    }
                                });
                            }
                        }

                        // console.log(this.custom_field);
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getProgramList() {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                wing: this.currentUser.wing
            }
            UserService.authPostRequest(`get-program-list`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.programItemList = response.data.data.list;
                        if (this.$route.params.id) {
                            this.getMembersList(1, this.$route.params.id);
                        }
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        showActionModel() {
            this.pin_number = '';
        },
        getFields(program_list) {
            console.log(program_list, this.programItemList);
            this.programItemList.forEach(element => {
                if (element.id == program_list) {
                    this.selected_program_item = element;
                    if (this.selected_program_item.options) {
                        this.selected_program_item.options.forEach(element_ => {
                            if (element_.multiple == 1) {
                                this.programFaculties[element_.id] = [''];
                                let multiple = {
                                    multi: []
                                };
                                this.custom_field.push(multiple);
                            } else {
                                this.custom_field.push('');
                            }
                        });
                    }

                }
            });
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
}

.paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
}

.paginate-buttons:hover {
    background-color: #d8d8d8;
}

.active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
}

.active-page:hover {
    background-color: #2988c8;
}

.form-group.basic {
    padding: 4px 0;
    margin: 0;
}
</style>