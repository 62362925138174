<template>
    <div class="section text-center mt-2 mb-2" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="section-title mal-text">A. പേരും മേൽവിലാസവും</div>
        <div class="card">
            <div class="card-body">
                <Form @submit="storeBasicInfo" :validation-schema="schema" enctype='multipart/form-data'>
                    <!-- <Field :disabled="disable_all" accept="image/*" type='file' name="profile_pic" id="imgInp" @change="uploadImage($event)"/>
                    <img id="blah" v-bind:src="profile_image" alt="your image" /> -->
                    <!-- <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">പ്രൊഫൈൽ ഫോട്ടോ</label>
                            <div class="custom-file-upload" id="fileUpload1">
                                <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg"
                                    @change="uploadImage($event)" capture="user" />
                                <label for="fileuploadInput" v-if="!profile_image">
                                    <span>
                                        <strong>
                                            <ion-icon name="arrow-up-circle-outline"></ion-icon>
                                            <i>Upload</i>
                                        </strong>
                                    </span>
                                </label>
                                <label v-if="profile_image" for="fileuploadInput" class="file-uploaded"
                                    :style="{ 'background-image': 'url(' + profile_image + ')' }">
                                </label>
                            </div>
                        </div>
                    </div> -->
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">പേര്</label>
                            <Field :disabled="disable_all" name="name" type="text" class="form-control" id="name" placeholder=""
                                v-model="name" />
                        </div>
                        <ErrorMessage name="name" class="error-feedback" />
                    </div>
                    <!-- <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="dob">ജനന തിയ്യതി</label>
                            <Field :disabled="disable_all" name="dob" type="date" class="form-control" id="dob" placeholder="" v-model="dob" />
                        </div>
                        <ErrorMessage name="dob" class="error-feedback" />
                    </div> -->
                    <div class="form-group boxed">
                        <div class="input-wrapper row">
                            <label class="label mal-text" for="dob">ജനന വർഷം</label>
                            <div class="form-group col-12" style="width:100% !important;">
                                <div class="input-wrapper">
                                    <!-- <label class="label mal-text" for="dob">വർഷം</label> -->
                                    <!-- <Field :disabled="disable_all" name="dob_year" type="number" class="form-control" id="dob_year"
                                        placeholder="" v-model="dob_year" /> -->
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="dob_year"
                                        name="dob_year" id="select4">
                                        <option v-for="item in years" :value="item" v-bind:key="item">
                                            {{ item }}</option>
                                    </Field>
                                </div>
                            </div>

                            <!-- <div class="form-group col-4" style="width:35% !important;">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="dob">മാസം</label>
                                    
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="dob_month"
                                        name="dob_month" id="select4">
                                        <option v-for="item in months" :value="item.number" v-bind:key="item.number">
                                            {{ item.short }}</option>
                                    </Field>
                                </div>
                            </div>
                            <div class="form-group col-4" style="width:30% !important;">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="dob">ദിവസം</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="dob_day"
                                        name="dob_day" id="select4">
                                        <option v-for="item in days" :value="item" v-bind:key="item">
                                            {{ item }}</option>
                                    </Field>
                                    
                                </div>
                            </div> -->
                        </div>
                        <ErrorMessage name="dob" class="error-feedback" />
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper row">
                            <div class="form-group col-4" style="width:40% !important;">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="country_code">മൊബൈൽ</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="country_code"
                                        name="country_code" id="select4">
                                        <option v-for="item in country_code_list" :value="item.item_value"
                                            v-bind:key="item.item_value">
                                            {{ item.name }}</option>
                                    </Field>

                                </div>
                            </div>
                            <div class="form-group mobile-field col-7" style="width:60%">
                                <div class="input-wrapper">
                                    <Field :disabled="disable_all" name="mobile_number" v-model="mobile_number" type="number" id="mobile_number"
                                        class="form-control" placeholder=""
                                        oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 11); } if (this.value.length > 10) { this.value = this.value.slice(0, 10); }" />
                                </div>
                            </div>
                            <ErrorMessage name="mobile_number" class="error-feedback" />
                            <span class="text-danger">മൊബൈൽ നമ്പർ മാറ്റുകയാണെങ്കിൽ താങ്കളുടെ എല്ലാ മെമ്പർമാരുടെയും മൊബൈൽ നമ്പർ അപ്ഡേറ്റ് ചെയ്യുന്നതാണ് </span>
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper row">
                            <div class="form-group col-4" style="width:40% !important;">
                                <div class="input-wrapper">
                                    <label class="label mal-text" for="country_code">വാട്സ്ആപ്പ്</label>
                                    <Field :disabled="disable_all" as="select" class="form-control custom-select"
                                        v-model="country_code_whatsapp" name="country_code_whatsapp" id="select4">
                                        <option v-for="item in country_code_list" :value="item.item_value"
                                            v-bind:key="item.item_value">
                                            {{ item.name }}</option>
                                    </Field>
                                </div>
                            </div>
                            <div class="form-group mobile-field col-7" style="width:60%">
                                <div class="input-wrapper">
                                    <Field :disabled="disable_all" name="whatsapp_number" v-model="whatsapp_number" type="number"
                                        id="whatsapp_number" class="form-control" placeholder=""
                                        oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 11); } if (this.value.length > 10) { this.value = this.value.slice(0, 10); }" />
                                </div>
                            </div>
                            <ErrorMessage name="whatsapp_number" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">ഇ മെയിൽ</label>
                            <Field :disabled="disable_all" name="email" type="email" class="form-control" id="email" placeholder=""
                                v-model="email" />
                        </div>
                        <ErrorMessage name="email" class="error-feedback" />
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">രക്ത ഗ്രൂപ്പ്</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="blood_group"
                                name="blood_group" id="select4">
                                <option v-for="item in blood_group_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="blood_group" class="error-feedback" />
                    </div>
                    <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">താങ്കൾ പ്രവാസിയാണോ?</label>
                                <div class="form-check mb-1 col-4">
                                    <Field :disabled="disable_all" class="form-check-input" type="radio" name="nri_status" id="flexRadioDefault1"
                                        v-model="nri_status" value="No"/>
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        അല്ല. 
                                    </label>
                                </div>
                                <div class="form-check mb-1 col-5">
                                    <Field :disabled="disable_all" class="form-check-input" type="radio" name="nri_status" id="flexRadioDefault2"
                                        v-model="nri_status" value="Yes"/>
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        പ്രവാസിയാണ്. 
                                    </label>
                                </div>
                                <div class="form-check mb-1 col-12">
                                    <Field :disabled="disable_all" class="form-check-input" type="radio" name="nri_status" id="flexRadioDefault3"
                                        v-model="nri_status" value="NotNow"/>
                                    <label class="form-check-label" for="flexRadioDefault3">
                                        മുമ്പ് പ്രവാസി ആയിരുന്നു, ഇപ്പോൾ നാട്ടിൽ സ്ഥിരമായി. 
                                    </label>
                                </div>
                            </div>
                            <ErrorMessage name="panjayath" class="error-feedback" />
                        </div>
                    <div class="section-heading text-center">
                        <div class="listview-title mt-0">
                            സ്ഥിരം മേൽവിലാസം</div>
                    </div>
                    <hr class="hr-line">
                    <!-- <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">സ്ഥിരം മേൽവിലാസം</label>
                            <Field :disabled="disable_all" name="adress" type="text" class="form-control" id="adress" v-model="adress"
                                placeholder="" />
                        </div>
                        <ErrorMessage name="adress" class="error-feedback" />
                    </div> -->
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">വിലാസം</label>
                            <Field :disabled="disable_all" name="house_name" type="text" class="form-control" id="house_name"
                                v-model="house_name" placeholder="" />
                        </div>
                        <ErrorMessage name="house_name" class="error-feedback" />
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">പോസ്റ്റ് ഓഫീസ്</label>
                            <Field :disabled="disable_all" name="post_office" type="text" class="form-control" id="post_office"
                                v-model="post_office" placeholder="" />
                        </div>
                        <ErrorMessage name="post_office" class="error-feedback" />
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">പിൻ കോഡ് </label>
                            <Field :disabled="disable_all" name="pin_code" type="text" class="form-control" id="pin_code" v-model="pin_code"
                                placeholder="" />
                        </div>
                        <ErrorMessage name="pin_code" class="error-feedback" />
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">രാജ്യം </label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="country" name="country"
                                id="select4">
                                <option v-for="item in country_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="country" class="error-feedback" />
                    </div>
                    <div class="form-group boxed" v-if="country == '91'">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">സംസ്ഥാനം </label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="state" name="state"
                                id="select4" @change="getComboboxValues('DISTRICT_KERALA', $event.target.value, false)">
                                <option v-for="item in state_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="state" class="error-feedback" />
                    </div>
                    <div class="form-group boxed"
                        v-if="country == '91' && (state == '94' || state == '529' || state == '541')">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">ജില്ല</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="district" name="district"
                                id="select4"
                                @change="getComboboxValues('Local_Body_Type', $event.target.value , false)">
                                <option v-for="item in district_list" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                                <!-- <option v-for="district_ in district_list" v-bind:key="district_.id" v-bind:value="district_.id" selected="selected">{{district_.name}}</option> -->
                            </Field>
                        </div>
                        <ErrorMessage name="district" class="error-feedback" />
                    </div>
                    <div class="form-group boxed"
                        v-if="country == '91' && (state == '94' || state == '529' || state == '541') && district">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">ലോക്കൽ ബോഡി തെരഞ്ഞെടുക്കുക</label>
                            <div class="form-check mb-1 col-4" v-for="item in local_body_list"
                                :set="panj_id = 'flexRadioDefault'+item.id" :value="item.id" v-bind:key="item.id">
                                <Field :disabled="disable_all" class="form-check-input" type="radio" name="flexRadioDefault" :id="panj_id"
                                    v-model="local_body_type" :value="item.id" />
                                <label class="form-check-label" :for="panj_id">
                                    {{item.name_mal}}
                                </label>
                            </div>
                        </div>
                        <ErrorMessage name="panjayath" class="error-feedback" />
                    </div>
                    <div class="form-group boxed"
                        v-if="country == '91' && (state == '94' || state == '529' || state == '541') && district">
                        <div class="input-wrapper">
                            <label class="label mal-text"
                                for="text4b">{{local_body_type==827?'പഞ്ചായത്ത്':local_body_type==828?'മുനിസിപ്പാലിറ്റി':'കോർപ്പറേഷൻ'}}
                                ഏതാണെന്ന് താഴെ എഴുതുക</label>
                            <!-- <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="" name="panjayath"
                                id="select4">
                                <option value="1">Kuruva</option>
                            </Field> -->
                            <Field :disabled="disable_all" name="panjayath" type="text" class="form-control" id="name" placeholder=""
                                v-model="panjayath" />
                        </div>
                        <ErrorMessage name="panjayath" class="error-feedback" />
                    </div>
                    <div class="form-check mb-1">
                        <input :disabled="disable_all" type="checkbox" class="form-check-input" id="customCheckb1" v-model="same_adress"
                            @change="clickSameAddress($event)">
                        <label class="form-check-label mal-text" for="customCheckb1">ഇപ്പോഴത്തെ അഡ്രസ്സും സ്ഥിരം
                            അഡ്രസ്സും ഒന്നുതന്നെയാണ്</label>
                    </div>
                    <div v-if="!same_adress">
                        <div class="section-heading text-center">
                            <div class="listview-title mt-0">
                                ഇപ്പോഴത്തെ മേൽവിലാസം</div>
                        </div>
                        <hr class="hr-line">
                        <!-- <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">ഇപ്പോഴത്തെ മേൽവിലാസം</label>
                                <Field :disabled="disable_all" name="t_adress" type="text" class="form-control" id="t_adress" v-model="t_adress"
                                    placeholder="" />
                            </div>
                            <ErrorMessage name="t_adress" class="error-feedback" />
                        </div> -->
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">വീട്ട് പേര് / വീട്ട് നമ്പർ</label>
                                <Field :disabled="disable_all" name="t_house_name" type="text" class="form-control" id="t_house_name"
                                    v-model="t_house_name" placeholder="" />
                            </div>
                            <ErrorMessage name="t_house_name" class="error-feedback" />
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">പോസ്റ്റ് ഓഫീസ്</label>
                                <Field :disabled="disable_all" name="t_post_office" type="text" class="form-control" id="t_post_office"
                                    v-model="t_post_office" placeholder="" />
                            </div>
                            <ErrorMessage name="t_post_office" class="error-feedback" />
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">പിൻ കോഡ് </label>
                                <Field :disabled="disable_all" name="t_pin_code" type="text" class="form-control" id="t_pin_code"
                                    v-model="t_pin_code" placeholder="" />
                            </div>
                            <ErrorMessage name="t_pin_code" class="error-feedback" />
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">രാജ്യം </label>
                                <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="t_country"
                                    name="t_country" id="select4"
                                    @change="getComboboxValues('STATES_INDIA', $event.target.value, true)">
                                    <option v-for="item in t_country_list" :value="item.id" v-bind:key="item.id">
                                        {{ item.name }}</option>
                                </Field>
                            </div>
                            <ErrorMessage name="t_country" class="error-feedback" />
                        </div>
                        <div class="form-group boxed" v-if="t_country == '91'">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">സംസ്ഥാനം </label>
                                <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="t_state" name="t_state"
                                    id="select4"
                                    @change="getComboboxValues('DISTRICT_KERALA', $event.target.value, true)">
                                    <option v-for="item in t_state_list" :value="item.id" v-bind:key="item.id">
                                        {{ item.name }}</option>
                                </Field>
                            </div>
                            <ErrorMessage name="t_state" class="error-feedback" />
                        </div>
                        <div class="form-group boxed"
                            v-if="t_country == '91' && (t_state == '94' || t_state == '529' || t_state == '541')">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">ജില്ല</label>
                                <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="t_district"
                                    name="t_district" id="select4"
                                    @change="getComboboxValues('Local_Body_Type', $event.target.value , true)">
                                    <option v-for="item in t_district_list" :value="item.id" v-bind:key="item.id">
                                        {{ item.name }}</option>
                                </Field>
                            </div>
                            <ErrorMessage name="t_district" class="error-feedback" />
                        </div>
                        <div class="form-group boxed"
                            v-if="country == '91' && (t_state == '94' || t_state == '529' || t_state == '541') && t_district">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">ലോക്കൽ ബോഡി തെരഞ്ഞെടുക്കുക</label>
                                <div class="form-check mb-1 col-4" v-for="item in local_body_list"
                                    :set="panj_id = 'flexRadioDefaultt'+item.id" :value="item.id" v-bind:key="item.id">
                                    <Field :disabled="disable_all" class="form-check-input" type="radio" name="flexRadioDefaultt" :id="panj_id"
                                        v-model="t_local_body_type" :value="item.id" />
                                    <label class="form-check-label" :for="panj_id">
                                        {{item.name_mal}}
                                    </label>
                                </div>
                            </div>
                            <ErrorMessage name="panjayath" class="error-feedback" />
                        </div>
                        <div class="form-group boxed"
                            v-if="t_country == '91' && (t_state == '94' || t_state == '529' || t_state == '541') && t_district">
                            <div class="input-wrapper">
                                <label class="label mal-text" for="text4b">
                                    <!-- {{t_local_body_type==827?'പഞ്ചായത്ത്':t_local_body_type==828?'മുനിസിപ്പാലിറ്റി':'കോർപ്പറേഷൻ'}}  -->
                                    ഏതാണെന്ന് താഴെ എഴുതുക
                                </label>
                                <!-- <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="t_panjayath"
                                    name="t_panjayath" id="select4">
                                    <option value="1" selected="selected">Kuruva</option>
                                </Field> -->
                                <Field :disabled="disable_all" name="t_panjayath" type="text" class="form-control" id="t_panjayath"
                                    placeholder="" v-model="t_panjayath" />
                            </div>
                            <ErrorMessage name="t_panjayath" class="error-feedback" />
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">താമസിക്കുന്ന വീട്</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="house_status"
                                name="house_status" id="select4">
                                <option v-for="item in home_type" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="house_status" class="error-feedback" />
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">സ്വന്തം വാഹനത്തിന്റെ വിവരങ്ങൾ</label>
                            <Field :disabled="disable_all" as="select" class="form-control custom-select" v-model="vehicle_status"
                                name="vehicle_status" id="select4">
                                <option v-for="item in vehicle_details" :value="item.id" v-bind:key="item.id">
                                    {{ item.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="vehicle_status" class="error-feedback" />
                    </div>

                    <div class="transparent pt-3" v-if="currentUser.id == profile.user_id">
                        <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save & Next</span>
                        </button>
                    </div>
                    <div class="transparent pt-3" v-if="currentUser.id != profile.user_id">
                        <button type="button" @click="goToNextPage" class="btn btn-primary btn-block btn-lg"
                            :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Next</span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
export default {
    name: 'Basic Info',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            name: yup.string().matches(nameRegex, "പേര് ഇംഗീഷിൽ തന്നെ ടൈപ്പ് ചെയ്യുക!").required("പേര് ടൈപ്പ് ചെയ്യുക!"),
            mobile_number: yup.string().min(5, 'കുറഞ്ഞത് 5 നമ്പർ എങ്കിലും വേണം').required("മൊബൈൽ നമ്പർ ടൈപ്പ് ചെയ്യുക!"),

            blood_group: yup.string().required("ബ്ലഡ് ഗ്രൂപ്പ് സെലക്ട് ചെയ്യുക!"),

            house_name: yup.string().required("വീട്ടുപേര് ടൈപ്പ് ചെയ്യുക!"),
            // post_office: yup.string().required("പോസ്റ്റ് ഓഫീസ് ടൈപ്പ് ചെയ്യുക!"),
            // pin_code: yup.string().required("പിൻകോഡ് ടൈപ്പ് ചെയ്യുക!"),
            country: yup.string().required("രാജ്യം സെലക്ട് ചെയ്യുക!"),
            // panjayath: yup.string().required("പഞ്ചായത് സെലക്ട് ചെയ്യുക !"),

            house_status: yup.string().required("താമസിക്കുന്ന വീട് സെലക്ട് ചെയ്യുക!"),
            // vehicle_status: yup.string().required("വാഹനങ്ങൾ സെലക്ട് ചെയ്യുക!"),



        });

        return {
            loading: false,
            message: "",
            schema,
            country_code: "91",
            country_code_whatsapp: "91",
            mobile_number: '',
            whatsapp_number: '',
            place: null,
            blood_group: '',
            email: null,
            name: null,
            profile_image: null,
            profile_file: null,
            years: [],
            days: [],
            local_body_list: [],
            local_body_type: 827,
            t_local_body_type: 827,
            current_user_profile: null,
            months: [
                {
                    "name": "January",
                    "short": "Jan",
                    "number": 1,
                    "days": 31
                },
                {
                    "name": "February",
                    "short": "Feb",
                    "number": 2,
                    "days": 28
                },
                {
                    "name": "March",
                    "short": "Mar",
                    "number": 3,
                    "days": 31
                },
                {
                    "name": "April",
                    "short": "Apr",
                    "number": 4,
                    "days": 30
                },
                {
                    "name": "May",
                    "short": "May",
                    "number": 5,
                    "days": 31
                },
                {
                    "name": "June",
                    "short": "Jun",
                    "number": 6,
                    "days": 30
                },
                {
                    "name": "July",
                    "short": "Jul",
                    "number": 7,
                    "days": 31
                },
                {
                    "name": "August",
                    "short": "Aug",
                    "number": 8,
                    "days": 31
                },
                {
                    "name": "September",
                    "short": "Sep",
                    "number": 9,
                    "days": 30
                },
                {
                    "name": "October",
                    "short": "Oct",
                    "number": 10,
                    "days": 31
                },
                {
                    "name": "November",
                    "short": "Nov",
                    "number": 11,
                    "days": 30
                },
                {
                    "name": "December",
                    "short": "Dec",
                    "number": 12,
                    "days": 31
                }
            ],
            profile: [],
            country_list: [],
            country_code_list: [],
            state_list: [],
            district_list: [],
            t_country_list: [],
            t_state_list: [],
            t_district_list: [],
            home_type: [],
            vehicle_details: [],
            blood_group_list: [],
            disable_all:false,

            nri_status: '',
            // adress: '',
            house_name: '',
            post_office: '',
            pin_code: '',
            country: '',
            state: '',
            district: '',
            panjayath: '',

            // t_adress: '',
            t_house_name: '',
            t_post_office: '',
            t_pin_code: '',
            t_country: '',
            t_state: '',
            t_district: '',
            t_panjayath: '',

            vehicle_status: '',
            house_status: '',
            same_adress: false,
            loading_list: false
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.loading_list = true;
        this.current_user_profile = {};

        this.getComboboxValues('COUNTRY');
        this.getComboboxValues('DISTRICT_KERALA');
        this.getComboboxValues('STATES_INDIA');
        this.getComboboxValues('HOME_TYPE');
        this.getComboboxValues('VEHICLE_DETAILS');
        this.getComboboxValues('Blood_Group');
        this.getComboboxValues('COUNTRY_CODE');
        this.getProfile();
        this.$root.updateParent("ബയോ ഡാറ്റ");

        var j = 1;
        for (var i = 2022; i >= 1920; i--) {

            if (j <= 31) {
                this.days.push(j);
                j++;
            }

            this.years.push(i);
        }
        
    },
    methods: {
        uploadImage(event) {
            var file = event.target.files[0];
            this.profile_file = file;
            this.profile_image = URL.createObjectURL(file);
        },
        getProfile() {
            var data = {
                id: this.$route.params.id
            }
            UserService.authPostRequest('get-profile', data).then(
                (response) => {
                    this.profile = response.data.data.list;
                    if (this.profile.user_id != this.currentUser.id) {
                        this.disable_all = true;
                        this.$root.updateParent(this.profile.name + " യുടെ ബയോ ഡാറ്റ");
                    }
                    this.name = this.profile.name;
                    this.dob = this.profile.dob ? this.profile.dob : '';
                    if (this.profile.dob) {
                        var dob_ = new Date(this.profile.dob);
                        this.dob_year = dob_.getUTCFullYear();
                        this.dob_month = dob_.getUTCMonth() + 1;
                        this.dob_day = dob_.getUTCDate();
                    } else {
                        this.dob_year = null;
                        this.dob_month = null;
                        this.dob_day = null;
                    }

                    this.country_code = this.profile.country_code;
                    this.mobile_number = this.profile.mobile;

                    this.country_code_whatsapp = this.profile.whatsapp_country_code ? this.profile.whatsapp_country_code : '91';
                    this.whatsapp_number = this.profile.whatsapp;
                    this.email = this.profile.email;
                    this.blood_group = this.profile.bloodGroup ? this.profile.bloodGroup : '';
                    this.nri_status = this.profile.nri_status?this.profile.nri_status:'';
                    this.profile_image = this.profile.avatar != 'member.jpg' ? 'https://guide.msoftit.com/' + this.profile.avatar : null;

                    this.house_name = this.profile.houseName ? this.profile.houseName : this.current_user_profile.houseName ? this.current_user_profile.houseName : '';
                    this.post_office = this.profile.post ? this.profile.post : this.current_user_profile.post ? this.current_user_profile.post : '';
                    this.pin_code = this.profile.pinCode ? this.profile.pinCode : this.current_user_profile.pinCode ? this.current_user_profile.pinCode : '';
                    this.country = this.profile.country ? this.profile.country : this.current_user_profile.country ? this.current_user_profile.country : '';

                    this.state = this.profile.state ? this.profile.state : this.current_user_profile.state ? this.current_user_profile.state : '';
                    if (this.state == '529') {
                        this.getComboboxValues('DISTRICT_KERALA', 529, false);
                    }
                    if (this.state == '541') {
                        this.getComboboxValues('DISTRICT_KERALA', 541, false);
                    }
                    this.district = this.profile.district ? this.profile.district : this.current_user_profile.district ? this.current_user_profile.district : '';
                    if (this.district) {
                        this.getComboboxValues('Local_Body_Type', null, false);
                    }
                    this.local_body_type = this.profile.localBodyType ? parseInt(this.profile.localBodyType) : '';
                    this.panjayath = this.profile.panchayath ? this.profile.panchayath: '';

                    this.current_user_profile = [];
                    this.same_adress = this.profile.same_adress == "0" ? false : this.current_user_profile.same_adress == "0" ? false : true;
                    this.t_house_name = this.profile.t_houseName ? this.profile.t_houseName=="1"?this.profile.houseName: this.profile.t_houseName : this.current_user_profile.t_houseName ? this.current_user_profile.t_houseName : '';
                    this.t_post_office = this.profile.t_post ? this.profile.t_post : this.current_user_profile.t_post ? this.current_user_profile.t_post : '';
                    this.t_pin_code = this.profile.t_pinCode ? this.profile.t_pinCode : this.current_user_profile.t_pinCode ? this.current_user_profile.t_pinCode : '';
                    this.t_country = this.profile.t_country ? this.profile.t_country : this.current_user_profile.t_country ? this.current_user_profile.t_country : '';

                    this.t_state = this.profile.t_state ? this.profile.t_state : this.current_user_profile.t_state ? this.current_user_profile.t_state : '';
                    if (this.t_state == '529') {
                        this.getComboboxValues('DISTRICT_KERALA', 529, true);
                    }
                    if (this.t_state == '541') {
                        this.getComboboxValues('DISTRICT_KERALA', 541, true);
                    }
                    this.t_district = this.profile.t_district ? this.profile.t_district : this.current_user_profile.t_district ? this.current_user_profile.t_district : '';
                    if (this.t_district) {
                        this.getComboboxValues('Local_Body_Type', null, true);
                    }
                    this.t_local_body_type = this.profile.t_localBodyType ? parseInt(this.profile.t_localBodyType) : this.current_user_profile.t_localBodyType ? parseInt(this.current_user_profile.t_localBodyType) : '';
                    this.t_panjayath = this.profile.t_panchayath ? this.profile.t_panchayath : this.current_user_profile.t_panchayath ? this.current_user_profile.t_panchayath : '';



                    this.house_status = this.profile.house_type ? this.profile.house_type : '';
                    this.vehicle_status = this.profile.vehicle_type ? this.profile.vehicle_type : '';
                    this.loading_list = false;
                },
            );
        },
        storeBasicInfo(data) {
            const formData = new FormData();
            data.t_local_body_type = this.t_local_body_type;
            data.local_body_type = this.local_body_type;
            data.same_adress = this.same_adress;
            if (this.dob_year && this.dob_month && this.dob_day) {
                data.dob = this.dob_year.toString() + '-01-01';
            } else {
                data.dob = null;
            }
            formData.append('data', JSON.stringify(data));
            // if(this.profile_file){
            //     formData.append('avatar', this.profile_file);
            // }
            formData.append('submit_type', 'basic_info');
            formData.append('id', this.$route.params.id);
            formData.append('user_id', this.currentUser.id);

            this.loading = true;
            UserService.authPostRequest('store-profile', formData).then(
                (response) => {
                    if (response.data.status == 'success') {
                        // this.$swal('സേവ് ചെയ്തു', '', 'seccess');
                        this.loading = false;
                        this.$router.push('/bio-data/education-info/' + this.$route.params.id);
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToNextPage() {
            this.$router.push('/bio-data/education-info/' + this.$route.params.id);
        },
        getComboboxValues(code, id = null, t) {
            if (id == 541) {
                code = 'DISTRICT_TAMILNADU';
            }
            if (id == 529) {
                code = 'DISTRICT_KARNATAKA';
            }
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY':
                            this.t_country_list = response.data.data.list.options;
                            this.country_list = response.data.data.list.options;
                            break;
                        case 'STATES_INDIA':
                            this.t_state_list = response.data.data.list.options;
                            this.state_list = response.data.data.list.options;
                            break;
                        case 'DISTRICT_KARNATAKA':
                            if (t) {
                                this.t_district_list = response.data.data.list.options;
                            } else {
                                this.district_list = response.data.data.list.options;
                            }
                            break;
                        case 'DISTRICT_TAMILNADU':
                            if (t) {
                                this.t_district_list = response.data.data.list.options;
                            } else {
                                this.district_list = response.data.data.list.options;
                            }
                            break;
                        case 'DISTRICT_KERALA':
                            if (t) {
                                this.t_district_list = response.data.data.list.options;
                            } else {
                                this.district_list = response.data.data.list.options;
                            }
                            break;
                        case 'Local_Body_Type':
                            this.local_body_list = response.data.data.list.options;
                            break;
                        case 'HOME_TYPE':
                            this.home_type = response.data.data.list.options;
                            break;
                        case 'VEHICLE_DETAILS':
                            this.vehicle_details = response.data.data.list.options;
                            break;
                        case 'Blood_Group':
                            this.blood_group_list = response.data.data.list.options;
                            break;
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            this.country_code = '91';
                            break;

                        default:
                            break;
                    }

                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clickSameAddress(event) {
            if (event.target.checked) {
                this.same_adress = true;
                this.t_country_list = this.country_list;
                this.t_state_list = this.state_list;
                this.t_district_list = this.district_list;
                this.t_adress = this.adress;
                this.t_house_name = this.house_name;
                this.t_post_office = this.post_office;
                this.t_pin_code = this.pin_code;
                this.t_country = this.country;
                this.t_state = this.state;
                this.t_district = this.district;
                this.t_panjayath = this.panjayath;
            } else {
                this.same_adress = false;
                this.t_adress = null;
                this.t_house_name = null;
                this.t_post_office = null;
                this.t_pin_code = null;
                this.t_country = null;
                this.t_state = null;
                this.t_district = null;
                this.t_panjayath = null;
            }

            console.log(event.target.checked);
        }
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

.label {
    font-size: 12px !important;
}

.form-control {
    height: 30px !important;
}

.mobile-field {
    margin-top: 23px;
}

.hr-line {
    height: 8px !important;
    background-color: #1a418c;
    opacity: 75%;
}

.custom-file-upload {
    width: 30%;
    height: 100px;
}
.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
    opacity: 1 !important;
}

.listview-title {
  color: #1a418c;
  padding: 1px 0px;
  font-size: 17px;
  font-weight: 900;
}
.section-title {
  color: #1a418c;
  padding: 1px 0%;
  font-size: 17px;
  font-weight: 900;
}

</style>