<template>
    <!-- Wallet Card -->
    <div class="section pt-1">
        <div class="wallet-card">
            <!-- Balance -->
            <div class="balance">
                <div class="left">
                    <span class="title mal-text">ടാർഗറ്റ്</span>
                    <h1 class="total text-primary">₹{{ target?target.toFixed(2):'0.00' }}</h1>
                </div>
                <div class="right">
                    <a href="javascript:void(0);" class="button" @click="adminAdd">
                        <ion-icon name="add-outline"></ion-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">കിട്ടിയത്</div>
                    <div class="value text-success">₹{{ total.toFixed(2) }}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ബാക്കിയുള്ളത്</div>
                    <div class="value text-danger">₹{{ minusToZero ? minusToZero : '00.0' }}</div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title mal-text">സ്റ്റേറ്റ് വിഹിതം</div>
                    <div class="value text-warning">₹{{ getPercentage(target?target:0,total,1) }}</div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title mal-text">ശാഖാ വിഹിതം</div>
                    <div class="value text-info">₹{{ getPercentage(target?target:0,total,4) }}</div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title mal-text">മണ്ഡലം വിഹിതം</div>
                    <div class="value text-secondary">₹{{ getPercentage(target?target:0,total,3) }}</div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title mal-text">ജില്ല വിഹിതം</div>
                    <div class="value text-primary">₹{{ getPercentage(target?target:0,total,2) }}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- Wallet Card -->
    <!-- <div class="section pt-2">
                                                                        <div class="wallet-card mt-1" v-if="currentUser.user_level <= 4">
                                                                            <div class="wallet-footer wallet-footer-user row">
                                                                                <div class="item col-12">
                                                                                    <a href="javascript:void(0)" @click="adminAdd">
                                                                                        <div class="icon-wrapper back-color-blue1">
                                                                                            <ion-icon name="add-outline"></ion-icon>
                                                                                            <strong class="menu-title mal-text">നേർപഥം ചേർക്കാൻ ഇവിടെ അമർത്തുക</strong>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text custom-title">{{unit.list_title}}</h3>
        </div>
    </div>
    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit</h5>
                    <h5 class="modal-title" v-if="add_admin">Add New</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="stay"><span>നിലവിൽ മെമ്പർ ആണോ</span></label>
                                    <select as="select" class="form-control custom-select" v-model="isMember" name="isMember" id="select4">
                                                                                        <option value>---Select---</option>
                                                                                        <option value="Y">അതെ</option>
                                                                                        <option value="N">അല്ല</option>
                                                                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                            <div class="form-group basic typehead-div" v-if="isMember == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Member</label>
                                    <v-select label="text" :filterable="true" v-model="member" :value="member" :options="member_list" @input="getTextList($event.target.value, 0)">
                                        <template>
                                                                                        type to search...
</template>

<template>
    <div class="d-center">
        {{ member.name }}
    </div>
</template>
                                    </v-select>
                                    <ErrorMessage name="member" class="error-feedback" />
                                </div>
                                <div class="input-info" v-if="member">Mobile :{{ member.country_code }} {{
                                        member.mobile
                                }}
                                </div>
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="name"><span>Name</span></label>
                                    <Field name="name" type="text" class="form-control" v-model="name" :disabled="disable_member_form" placeholder="Name" autocomplete="off" />
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="receipt_no"><span>Receipt Number</span></label>
                                    <Field name="receipt_no" type="text" class="form-control" v-model="receipt_no" placeholder="Receipt Number" autocomplete="off" />
                                </div>
                                <ErrorMessage name="receipt_no" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="amount"><span>Amount</span></label>
                                    <Field name="amount" type="number" class="form-control" v-model="amount" placeholder="Amount" autocomplete="off" />
                                </div>
                                <ErrorMessage name="amount" class="error-feedback" />
                            </div>
    
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>
    
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading" v-if="permissions.update">
                                                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                                        <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.update" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DeleteMemberModel -->
    
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും ചേർത്തിട്ടില്ല
        </h4>
    </div>
    
    <ul class="listview image-listview inset text mt-2" v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id" :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                                    <span class="sl-no">{{ (member_lists.from + index) }}</span>
                                    <div class="avatar-section">
                                        <a href="#">
                                            <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                                            <!-- <span class="button verified" v-if="member_list.member.verified == 'Y'">
                                                <ion-icon name="checkmark-outline"></ion-icon>
                                            </span>
                                            <span class="button not-verified" v-if="member_list.member.verified == 'N' && member_list.member.verification_status">
                                                <ion-icon name="close-outline"></ion-icon>
                                            </span> -->
                                        </a>
            </div>
            <div class="in">
                <div class="min-width-50">
                    <!-- <header>User</header> -->
                    <b> {{ member_list.name }} </b>
                    <footer class="text-primary">{{ member_list.reciept_no }} | ₹{{ member_list.amount.toFixed(2) }}
                    </footer>
                </div>
                <div class="card-button dropdown">
                    <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal" :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                                                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                                            </button>
                </div>
    
                <!-- Default Action Sheet Inset -->
                <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Action</h5>
                            </div>
                            <div class="modal-body">
                                <ul class="action-button-list">
                                    <li>
                                        <a href="javascript:void(0)" @click="adminView(member_list)" class="btn btn-list" data-bs-dismiss="modal">
                                                                    <span>
                                                                        <ion-icon name="pencil-outline"></ion-icon> Edit
                                                                    </span>
                                                                </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)" class="btn btn-list" data-bs-dismiss="modal">
                                                                    <span>
                                                                        <ion-icon name="trash-outline"></ion-icon> Remove
                                                                    </span>
                                                                </a>
                                    </li>
                                    <li class="action-divider"></li>
                                    <li>
                                        <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                                    <span>
                                                                        <ion-icon name="close-outline"></ion-icon> Cancel
                                                                    </span>
                                                                </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- * Default Action Sheet Inset -->
            </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total" :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page" :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false"
        />
    </div>
    
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import $ from 'jquery';
import vSelect from 'vue-select'
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        const schema = yup.object().shape({
            receipt_no: yup.string().required("ഫിൽ ചെയ്യുക!"),
            amount: yup.string().required("ഫിൽ ചെയ്യുക!"),
        });
        return {
            loading: false,
            loading_list: false,
            message: "",
            schema,
            member_lists: [],
            member_list: [],
            member: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            total: 0.00,
            target: 0.00,
            isMember: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            member_edit: false,
            receipt_no: '',
            amount: '',
            isEdit: false,
            sub_level_name: '',
            district_eligible: false,
            zone_eligible: false,
            percentage:[]

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.target - this.total;
            if (value <= 0) {
                return '0';
            } else {
                return value.toFixed(2);
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getMembersList(1);
            this.$root.updateParent('Loading...');
        }
        // this.getComboboxValues('COUNTRY_CODE');
        window.onpopstate = function() {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getPercentage(target, total, type) {
            if (type == 1) {
                let percentage = 100;
                if (total >= target) {
                    percentage = percentage - this.percentage['4_100']-this.percentage['3_100']-this.percentage['2_100'];
                }else if(((target / 100) * 75) <= total){
                    percentage = percentage - this.percentage['4_75']-this.percentage['3_75']-this.percentage['2_75'];
                }
                // if (this.district_eligible && total >= target) {
                //     percentage = percentage - 10;
                // }
                // if (this.zone_eligible && total >= target) {
                //     percentage = percentage - 10;
                // }
                return ((total / 100) * percentage).toFixed(2);
            } else if (type == 2) {
                let percentage = 0;
                if (total >= target) {
                    percentage = this.percentage['2_100'];
                }else if(((target / 100) * 75) <= total){
                    percentage = this.percentage['2_75'];
                }
                return ((total / 100) * percentage).toFixed(2);
            } else if (type == 3) {
                let percentage = 0;
                if (total >= target) {
                    percentage = this.percentage['3_100'];
                }else if(((target / 100) * 75) <= total){
                    percentage = this.percentage['3_75'];
                }
                return ((total / 100) * percentage).toFixed(2);
            } else if (type == 4) {
                let percentage = 0;
                if (total >= target) {
                    percentage = this.percentage['4_100'];
                }else if(((target / 100) * 75) <= total){
                    percentage = this.percentage['4_75'];
                }
                return ((total / 100) * percentage).toFixed(2);
            }
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            if (admin.member_id) {
                this.member_id = admin.member_id;
                this.disable_member_form = true;
            } else {
                this.disable_member_form = false;
            }
            this.name = admin.name;
            this.amount = admin.amount;
            this.receipt_no = admin.reciept_no;
            this.member_edit = false;
            this.member_list.push(this.member);
            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.disable_member_form = false;
            this.name = '';
            this.receipt_no = '';
            this.member_edit = true;
            this.amount = '';
            this.editable_id = null;
            this.add_admin = true;
            this.edit_admin = false;
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            this.loading = true;
            user.editable_id = this.editable_id;
            user.unit_id = parseInt(this.$route.params.id);
            user.fund_type = this.$route.params.type;
            user.is_member = this.isMember;
            if (this.isMember == 'Y') {
                if (this.member) {
                    if (this.member.length == 0) {
                        this.alert_type = 'Warning';
                        this.alert_message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക.';
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        return false;
                    }
                } else {
                    this.alert_type = 'Warning';
                    this.alert_message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക.';
                    this.$refs.alert_model.openModel();
                    this.loading = false;
                    return false;
                }

            }
            if (this.isMember == 'N' && !user.name) {
                this.alert_type = 'Warning';
                this.alert_message = 'ദയവായി പേര് ഫിൽ ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                this.loading = false;
                return false;
            }
            if (this.isMember == 'Y') {
                user.member_id = this.member.id;
            } else {
                user.member_id = null;
            }
            UserService.addAdmin('store-fund-collections', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delate-fund-collections', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(1, 6);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getMembersList(page = 1) {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                unit_id: parseInt(this.$route.params.id),
                type: parseInt(this.$route.params.type)
            }
            UserService.authPostRequest(`get-fund-collections?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.permissions = response.data.data.permissions;
                        this.district_eligible = response.data.data.district_eligible;
                        this.zone_eligible = response.data.data.zone_eligible;
                        this.percentage = response.data.data.percentage;
                        this.member_lists = response.data.data.list;
                        this.total = response.data.data.total;
                        this.target = response.data.data.target;
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        this.$root.updateParent(this.unit.page_title);
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
}

.paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
}

.paginate-buttons:hover {
    background-color: #d8d8d8;
}

.active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
}

.active-page:hover {
    background-color: #2988c8;
}
</style>