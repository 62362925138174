<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 custom-title">Students List</h3>
        </div>
    </div>
    <div class="section pt-1 mt-0">
        <div class="wallet-card">
            <div class="wallet-footer">
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList('M')">
                            Boys
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                            @click="getMembersList('F')">
                            Girls
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- AddMemberModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit</h5>
                    <h5 class="modal-title" v-if="add_admin">Add New</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="stay"><span>നിലവിൽ മെമ്പർ ആണോ</span></label>
                                    <select as="select" class="form-control custom-select" v-model="isMember"
                                        name="isMember" id="select4">
                                        <option value>---Select---</option>
                                        <option value="Y">അതെ</option>
                                        <option value="N">അല്ല</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                            <div class="form-group basic typehead-div" v-if="isMember == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Member</label>
                                    <v-select label="text" :filterable="true" v-model="member" :value="member"
                                        :options="member_list" @input="getTextList($event.target.value, 'students')">
                                        <template>
                                            type to search...
                                        </template>

                                        <template>
                                            <div class="d-center">
                                                {{ member.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                    <ErrorMessage name="member" class="error-feedback" />
                                </div>
                                <div class="input-info" v-if="member">Mobile :{{ member.country_code }} {{
                                    member.mobile
                                }}
                                </div>
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="name">Name <span class="text-danger">*</span></label>
                                    <Field name="name" type="text" class="form-control" v-model="name" placeholder="Name"
                                        autocomplete="off" />
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="age">Age <span class="text-danger">*</span></label>
                                    <Field name="age" type="number" class="form-control" v-model="age" placeholder="Age"
                                        autocomplete="off" />
                                </div>
                                <ErrorMessage name="age" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">
                                        Sex
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" value="M" name="sex" id="sex_1"
                                            v-model="sex" />
                                        <label class="form-check-label" for="sex_1">പുരുഷൻ &nbsp;</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" value="F" name="sex" id="sex_2"
                                            v-model="sex" />
                                        <label class="form-check-label" for="sex_2">സ്ത്രീ</label>
                                    </div>
                                    <br />
                                    <ErrorMessage name="sex" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="class">Class <span class="text-danger">*</span></label>
                                    <Field name="class" type="text" class="form-control" v-model="s_class"
                                        placeholder="Class" autocomplete="off" />
                                </div>
                                <ErrorMessage name="class" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="school"><span>School</span></label>
                                    <Field name="school" type="text" class="form-control" v-model="school"
                                        placeholder="School" autocomplete="off" />
                                </div>
                                <ErrorMessage name="class" class="error-feedback" />
                            </div>
                            <div class="row" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="code">Code <span class="text-danger">*</span></label>
                                            <Field as="select" class="form-control custom-select" id="code" name="code"
                                                v-model="country_code">
                                                <option value="" :disabled="true" selected>#</option>
                                                <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                                    v-bind:value="code.item_value">
                                                    {{ code.name }}</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="code" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="mobile">Mobile <span
                                                    class="text-danger">*</span></label>
                                            <Field type="number" class="form-control" id="mobile" name="mobile"
                                                placeholder="Mobile" v-model="mobile"
                                                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="mobile" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="country_code_whatsapp">Code</label>
                                            <Field as="select" class="form-control custom-select" id="country_code_whatsapp"
                                                name="country_code_whatsapp" v-model="country_code_whatsapp"
                                                @change="onChangeCountryCode($event)">
                                                <option value="" :disabled="true" selected>#</option>
                                                <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                                    v-bind:value="code.item_value">
                                                    {{ code.name }}</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="code" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="whatsapp">Whatsapp </label>
                                            <Field type="number" class="form-control" id="whatsapp" name="whatsapp"
                                                placeholder="whatsapp" v-model="whatsapp"
                                                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="whatsapp" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="parent_mobile"><span>Parent Mobile</span></label>
                                    <Field name="parent_mobile" type="number" class="form-control" v-model="parent_mobile"
                                        placeholder="Parent Mobile" autocomplete="off" />
                                </div>
                                <ErrorMessage name="parent_mobile" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="parent_whatsapp"><span>Parent Whatsapp</span></label>
                                    <Field name="parent_whatsapp" type="number" class="form-control"
                                        v-model="parent_whatsapp" placeholder="Parent Whatsapp" autocomplete="off" />
                                </div>
                                <ErrorMessage name="parent_whatsapp" class="error-feedback" />
                            </div>

                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="update_student">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!update_student"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- DeleteMemberModel -->
    <div class="modal fade action-sheet" id="deleteMemberModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Member</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group" v-if="message_delete">
                            <div v-if="message_delete" class="error-feedback">
                                {{ message_delete }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="stay"><span class="mal-text">ഡിലീറ്റ് ചെയ്യാനുള്ള
                                    കാരണം</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper mal-text">
                                    <select as="select" class="form-control custom-select" v-model="reason_to_delete"
                                        name="stay" id="select4" @change="adminDeleteChange($event)">
                                        <option value>ഡിലീറ്റ് ചെയ്യാനുള്ള കാരണം</option>
                                        <option value="മെമ്പർഷിപ്പിന് യോഗ്യനല്ല, തെറ്റായി ചേർത്തതാണ്">മെമ്പർഷിപ്പിന്
                                            യോഗ്യനല്ല, തെറ്റായി ചേർത്തതാണ്</option>
                                        <option value="നേരത്തെ നമ്മുടെ പ്രവർത്തകൻ. ഇപ്പൊ താല്പര്യം ഇല്ല എന്ന് പറഞ്ഞു">
                                            നേരത്തെ നമ്മുടെ പ്രവർത്തകൻ. ഇപ്പൊ താല്പര്യം ഇല്ല എന്ന് പറഞ്ഞു</option>
                                        <option value="നമ്മുടെ അനുഭാവിയാണ്, മെമ്പർഷിപ്പ് വേണ്ട എന്ന് പറഞ്ഞു">നമ്മുടെ
                                            അനുഭാവിയാണ്, മെമ്പർഷിപ്പ് വേണ്ട എന്ന് പറഞ്ഞു</option>
                                        <option value="നമ്മുടെ പ്രവർത്തകനാണ്, പലതവണ ശ്രമിച്ചിട്ടും പ്രതികരിക്കുന്നില്ല">
                                            നമ്മുടെ പ്രവർത്തകനാണ്, പലതവണ ശ്രമിച്ചിട്ടും പ്രതികരിക്കുന്നില്ല</option>
                                        <option value="ഗൾഫിലാണ്, ബയോഡാറ്റ ഫില്ല് ചെയ്തിട്ടില്ല">ഗൾഫിലാണ്, ബയോഡാറ്റ
                                            ഫില്ല് ചെയ്തിട്ടില്ല</option>
                                        <option value="സ്മാർട്ട് ഫോൺ ഇല്ലാത്തതുകൊണ്ട് ബയോഡാറ്റ ഫിൽ ചെയ്തിട്ടില്ല">
                                            സ്മാർട്ട് ഫോൺ ഇല്ലാത്തതുകൊണ്ട് ബയോഡാറ്റ ഫിൽ ചെയ്തിട്ടില്ല.</option>
                                        <option value="ബയോഡാറ്റ ഫിൽ ചെയ്യാൻ അറിയില്ല, അതുകൊണ്ട് ചെയ്തിട്ടില്ല">ബയോഡാറ്റ
                                            ഫിൽ ചെയ്യാൻ അറിയില്ല, അതുകൊണ്ട് ചെയ്തിട്ടില്ല</option>
                                        <option value="കരടിലെ നമ്പർ തെറ്റി പോയി. അത് കൊണ്ട് ലോഗിൻ കഴിയുന്നില്ല">കരടിലെ
                                            നമ്പർ തെറ്റി പോയി. അത് കൊണ്ട് ലോഗിൻ കഴിയുന്നില്ല. </option>
                                        <option value="PIN ലഭിക്കുന്നില്ല. അത് കൊണ്ട് ലോഗിൻ ചെയ്യാൻ കഴിയുന്നില്ല">PIN
                                            ലഭിക്കുന്നില്ല. അത് കൊണ്ട് ലോഗിൻ ചെയ്യാൻ കഴിയുന്നില്ല. </option>
                                        <option value="Other">മറ്റു കാരണങ്ങൾ. </option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="deletable_id" />
                            </div>
                        </div>
                        <div class="form-group basic" v-if="delete_others">
                            <label class="label" for="Name"><span class="mal-text">ഡിലീറ്റ് ചെയ്യാനുള്ള
                                    കാരണം</span></label>
                            <div class="form-group boxed">
                                <Field name="name" type="text" class="form-control" v-model="delete_other_text"
                                    placeholder="കാരണം എഴുതുക" autocomplete="off" />
                            </div>
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="memberDelete()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Delete</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * AddMemberModel -->
    <!-- examMarkModel -->
    <div class="modal fade action-sheet" id="examMarkModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Terminal Exam Sep 2023</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group basic">
                            <label class="label" for="mark">Mark</label>
                            <Field name="mark" type="number" class="form-control" v-model="mark" placeholder="Mark"
                                autocomplete="off" />
                        </div>
                        <div class="form-group basic">
                            <button type="button" @click="addMark()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- * examMarkModel -->
    <div class="section pt-1 mt-1">
        <button type="button" class="btn btn-primary btn-md me-1 mb-0" @click="adminAdd">
            <ion-icon name="add-outline"></ion-icon>
            Add New
        </button>
    </div>

    <div id="search">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
                    @input="getMembersList(this.wing, this.search_qty)">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div>

    <div class="section text-center mt-1" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <div class="listed-detail mt-2" v-if="!loading_list">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.length <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>

    <ul class="listview image-listview inset text mt-2" v-if="!loading_list">
        <li v-for="(member_list, index) in member_lists" v-bind:key="member_list.id">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ index + 1 }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img :src="'assets/img/sample/avatar/avatar1.png'" alt="avatar" class="image">
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b> {{ member_list.name }} </b>
                        <footer>{{ member_list.country_code }} {{ member_list.mobile }}<br>
                            <span v-if="this.permissions.exam_mark">
                            Mark: <span class="text-danger">{{member_list.mark}}</span></span>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li v-if="this.permissions.exam_mark">
                                            <a href="javascript:void(0)" @click="showMarkModel(member_list)"
                                                class="btn btn-list text-info" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="flag-outline"></ion-icon> Add Exam Mark
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list text-primary">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.whatsapp && this.permissions.whatsapp_access">
                                            <a :href="'https://wa.me/' + member_list.whatsapp_country_code + member_list.whatsapp + '?text='"
                                                class="btn btn-list text-success">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="this.permissions.edit">
                                            <a href="javascript:void(0)" @click="memberView(member_list)"
                                                class="btn btn-list text-primary" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="this.permissions.edit">
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import $ from 'jquery';
import vSelect from 'vue-select';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    props: {
        unit_id: null,
        permissions: Object
    },
    components: {
        Form,
        Field,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    data() {
        const schema = yup.object().shape({
            name: yup.string().when('isMember', {
                is: value => value && value == 'N',
                then: yup
                    .string()
                    .required("Required name field")
            }),
            age: yup.string().when('isMember', {
                is: value => value && value == 'N',
                then: yup
                    .string()
                    .required("Required age field")
            }),
            class: yup.string().when('isMember', {
                is: value => value && value == 'N',
                then: yup
                    .string()
                    .required("Required class field")
            }),
            mobile_number: yup.string().when('isMember', {
                is: value => value && value == 'N',
                then: yup
                    .string()
                    .required("Required mobile number field")
            }),
        });
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            schema,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            country_code_list: [],
            sex: '',
            dob: '',
            s_class: '',
            parent_mobile: '',
            parent_whatsapp: '',
            school: '',
            update_student: true,
            member: [],
            isMember: '',
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            deletable_id: '',
            reason_to_delete: '',
            message_delete: '',
            delete_others: false,
            delete_other_text: '',
            member_id: '',
            search_qty: '',
            verification_status_list: [],
            verification_status: '',
            not_verification_status: '',
            not_verification_status_: '',
            verification_remark: '',
            verification_member_id: '',
            verification_message: '',
            verification_report: false,
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            nerpatham: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            verification_check: [],
            not_verification_check: [],
            edit_admin: true,
            mark:''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },

    },
    mounted() {
        console.log(this.permissions.verification);
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.getMembersList('M');
        }
        this.getComboboxValues('COUNTRY_CODE');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case 'UNVERIFIED_REASON':
                            this.verification_status_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getMembersList(type = 'M', search = null) {
            this.loading_list = true;
            this.wing = type;
            let data = {
                cre_centre_id: this.$route.params.id,
                type: type,
                q: search
            }
            UserService.authPostRequest('get-cre-students', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_lists = response.data.data.list;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                user_level: 1,
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            };
            this.dialog_action_params = data;
            this.dialog_message = "ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?";
            this.dialog_action = "adminDelete";
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest("delete-cre-students", data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.wing);
                        this.loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        memberView(admin) {
            $('#addAdminModel').modal('show');
            if (admin.member_id) {
                this.isMember = 'Y';
                this.member_id = admin.member_id;
                this.disable_member_form = true;
                this.member = {
                    id: admin.member_id,
                    member_id: admin.member_id,
                    text: admin.name,
                    mobile: admin.mobile,
                    country_code: admin.country_code
                }
                this.member_list.push(this.member);
            } else {
                this.isMember = 'N';
                this.disable_member_form = false;
                this.name = admin.name;
                this.code = admin.country_code;
                this.mobile = admin.mobile;
                this.whatsapp = admin.whatsapp;
                this.country_code_whatsapp = admin.country_code_whatsapp;
                this.sex = admin.sex;
                this.age = admin.age;
                this.s_class = admin.class;
                this.school = admin.school;
                this.country_code = '91';
            }
            this.editable_id = admin.id;
            this.add_admin = false;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.sex = '';
            this.age = '';
            this.class = '';
            this.school = '';
            this.whatsapp = '';
            this.country_code_whatsapp = '';
            this.mobile_number = '';
            this.name = '';
            this.country_code = '91';
            this.member = [];
            this.editable_id = null;
            this.add_admin = true;
        },
        addAdmin(user) {
            user.editable_id = this.editable_id;
            user.cre_centre_id = this.$route.params.id;
            user.sex = this.sex;
            if (this.isMember == 'Y') {
                user.member_id = this.member.id;
            } else {
                user.member_id = null;
            }
            this.loading = true;
            UserService.authPostRequest('store-cre-students', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.getMembersList(this.wing);
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                        this.editable_centre_id = '';
                        this.editable_id = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        closeAddMemberModel() {
            $('#addAdminModel').modal('hide');
        },
        showMarkModel(student) {
            this.mark = student.mark;
            this.editable_id = student.id;
            $('#examMarkModel').modal('show');
        },
        addMark() {
            let data = {
                editable_id: this.editable_id,
                mark : this.mark
            }
            this.loading = true;
            UserService.authPostRequest('store-cre-exam-mark', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#examMarkModel').modal('hide')
                        this.getMembersList(this.wing);
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.editable_id = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}
</style>