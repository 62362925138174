<template>
    <div class="lajna-container">
        <div class="lajna-content">
            <!-- Event Slider Section -->
            <div class="slider-wrapper">
                <EventSlider />
            </div>

            <CategoryFilter />

            <hr>

            <!-- chat template here -->
            <div class="chat-wrapper">
                <!-- Messages Area -->
                <div ref="messagesContainer" class="messages-area">
                    <div v-for="message in messages" :key="message.id"
                        :class="['message-wrapper', message.sender === 'user' ? 'sent' : 'received']">
                        <div class="message-bubble">
                            <p>{{ message.text }}</p>
                            <span class="message-time">{{ formatTime(message.timestamp) }}</span>
                        </div>
                    </div>
                </div>

                <!-- Fixed Input Area -->
                <div class="input-area">
                    <input v-model="newMessage" @keyup.enter="sendMessage" type="text"
                        placeholder="Type your message..." class="message-input" />
                    <button @click="sendMessage" class="send-button" :disabled="!newMessage.trim()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M22 2L11 13"></path>
                            <path d="M22 2L15 22L11 13L2 9L22 2z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import CategoryFilter from '../../components/CategoryFilter.vue';
import EventSlider from '../../components/EventSlider.vue';

const messages = ref([
    {
        id: 1,
        text: "Welcome to Lajna Chat!",
        sender: "system",
        timestamp: new Date()
    }
]);
const newMessage = ref('');
const messagesContainer = ref(null);

const formatTime = (date) => {
    return new Date(date).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
};

const scrollToBottom = async () => {
    await nextTick();
    if (messagesContainer.value) {
        messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
    }
};

const sendMessage = () => {
    if (newMessage.value.trim()) {
        messages.value.push({
            id: messages.value.length + 1,
            text: newMessage.value,
            sender: 'user',
            timestamp: new Date()
        });
        newMessage.value = '';
        scrollToBottom();
    }
};

onMounted(() => {
    scrollToBottom();
});
</script>

<style>
.bottom-button {
    display: block;
    width: 100%;
    margin: 1rem auto;
    padding: 0.75rem 1.5rem;
    background: #0d4f17;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-align: center;
}

.chat-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px); /* Adjust based on your header height */
    background-color: #f5f5f5;
    max-width: 768px;
    margin: 0 auto;
}

.messages-area {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    padding-bottom: 80px; /* Add padding to prevent messages from being hidden behind input */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.message-wrapper {
    display: flex;
    margin-bottom: 0.5rem;
}

.message-wrapper.sent {
    justify-content: flex-end;
}

.message-bubble {
    max-width: 80%;
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    position: relative;
}

.received .message-bubble {
    background-color: white;
    border: 1px solid #e5e5e5;
    border-bottom-left-radius: 0.25rem;
}

.sent .message-bubble {
    background-color: #0d4f17;
    color: white;
    border-bottom-right-radius: 0.25rem;
}

.message-bubble p {
    margin: 0;
    font-size: 0.9375rem;
    line-height: 1.4;
}

.message-time {
    font-size: 0.75rem;
    opacity: 0.7;
    display: block;
    margin-top: 0.25rem;
    text-align: right;
}

.input-area {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 1rem;
    background-color: white;
    border-top: 1px solid #e5e5e5;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    z-index: 10;

}

.message-input {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 1.5rem;
    font-size: 0.9375rem;
    outline: none;
    transition: border-color 0.2s;
}

.message-input:focus {
    border-color: #0d4f17;
}

.send-button {
    background-color: #0d4f17;
    color: white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.send-button:hover {
    background-color: #083610;
}

.send-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.send-button svg {
    width: 1.25rem;
    height: 1.25rem;
    transform: rotate(45deg);
}

@media (max-width: 768px) {
    .message-bubble {
        max-width: 85%;
    }
}

/* Custom Scrollbar */
.messages-area {
    scrollbar-width: thin;
    scrollbar-color: rgba(13, 79, 23, 0.3) transparent;
}

.messages-area::-webkit-scrollbar {
    width: 4px;
}

.messages-area::-webkit-scrollbar-track {
    background: transparent;
}

.messages-area::-webkit-scrollbar-thumb {
    background-color: rgba(13, 79, 23, 0.3);
    border-radius: 2px;
}
</style>