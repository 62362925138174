<template>
    <div class="section">
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ആകെ നമ്പറുകൾ</div>
                    <div class="value text-success">{{deligate.target?deligate.target:'0'}}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text">ബാക്കിയുള്ളത്(<a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#pendingListModel">Get List</a>)</div>
                    <div class="value text-danger">{{deligate.deligate_target_remining?deligate.deligate_target_remining:'0'}}</div>
                </div>
            </div>
        </div>
    </div>
    <div id="search" class="mt-1">
        <form class="search-form">
            <div class="row">
                <div class="input-group form-group" style="width:70% !important;">
                    <span class="input-group-text" id="basic-addon3">PF2-</span>
                    <input type="number" class="form-control" maxlength="9999" focus v-model="search_qry"
                        aria-describedby="basic-addon3" style="border: 1px solid #1a418c;
    border-left: 0px;" />
                </div>
                <!-- <div class="form-group searchbox" style="width:70% !important;">
                    <input type="text" class="form-control" placeholder="Proface ID" v-model="search_qry">
                </div> -->
                <div class="form-group" style="width:30% !important;">
                    <button type="submit" @click="search()" class="btn btn-primary btn-block btn-md"
                        :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Search</span></button>
                </div>
            </div>
        </form>
    </div>
    <div class="section mt-1 mb-2" v-if="!loading_list && deligate.deligate.id">
        <div class="section-title text-primary"><span class="text-dark">ID:</span> {{ deligate.deligate.proface_id }}
            <br>
            <span class="text-dark"> Name: </span> {{ deligate.deligate.name }} - {{ deligate.deligate.profession }}<br>
            <span class="text-dark"> Place: </span> {{ deligate.deligate.place }},{{ deligate.deligate.district }} <br>
            <span class="text-dark">Mobile: </span> <a :href="'tel:' + deligate.deligate.mobile_no"><ion-icon
                    name="call-outline"></ion-icon>
                {{
                    deligate.deligate.mobile_no
                }} </a><br>
                <span class="text-dark"> Fee Status: </span> {{ deligate.deligate.fee_status }}
        </div>
        <div class="card">
            <div class="card-body">
                <Form @submit="storeBasicInfo" :validation-schema="schema">
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="text4b">Is you availability confirmed?</label>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Yes" name="arrival_confirm"
                                    id="arrival_confirm_1" v-model="arrival_confirm">
                                <label class="form-check-label" for="arrival_confirm_1">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="No" name="arrival_confirm"
                                    id="arrival_confirm_2" v-model="arrival_confirm">
                                <label class="form-check-label" for="arrival_confirm_2">No</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Maybe" name="arrival_confirm"
                                    id="arrival_confirm_3" v-model="arrival_confirm">
                                <label class="form-check-label" for="arrival_confirm_3">Maybe</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Not Decided" name="arrival_confirm"
                                    id="arrival_confirm_4" v-model="arrival_confirm">
                                <label class="form-check-label" for="arrival_confirm_4">Not Decided</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="text4b">Transportation Mode</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="arrival_mode_1" name="arrival_mode"
                                    value="Own Vehicle" v-model="arrival_mode">
                                <label class="form-check-label" for="arrival_mode_1">Own Vehicle</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="arrival_mode_2" name="arrival_mode"
                                    value="Group" v-model="arrival_mode">
                                <label class="form-check-label" for="arrival_mode_2">Group</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="arrival_mode_3" name="arrival_mode"
                                    value="Public Transport" v-model="arrival_mode">
                                <label class="form-check-label" for="arrival_mode_3">Public Transport</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="arrival_mode_4" name="arrival_mode"
                                    value="Not Decided" v-model="arrival_mode">
                                <label class="form-check-label" for="arrival_mode_4">Not Decided</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="text4b">Saturday Coming</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="saturday_coming_1"
                                    name="saturday_coming" value="Yes" v-model="saturday_coming">
                                <label class="form-check-label" for="saturday_coming_1">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="saturday_coming_2"
                                    name="saturday_coming" value="No" v-model="saturday_coming">
                                <label class="form-check-label" for="saturday_coming_2">No</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="saturday_coming_3"
                                    name="saturday_coming" value="Not Decided" v-model="saturday_coming">
                                <label class="form-check-label" for="saturday_coming_3">Not Decided</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group boxed" v-if="deligate.permissions.arrival_time">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="text4b">Arrival Time</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" value="8 AM" v-model="arrival_time"
                                    id="arrival_time_1" name="arrival_time">
                                <label class="form-check-label" for="arrival_time_1">8 AM</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" value="9 AM" v-model="arrival_time"
                                    id="arrival_time_2" name="arrival_time">
                                <label class="form-check-label" for="arrival_time_2">9 AM</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="arrival_time" id="arrival_time_3"
                                    name="arrival_time" value="10 AM">
                                <label class="form-check-label" for="arrival_time_3">10 AM</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="arrival_time" id="arrival_time_4"
                                    name="arrival_time" value="Not Decided">
                                <label class="form-check-label" for="arrival_time_4">Not Decided</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="text4b">Food Preference</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="food_pref_1" name="food_pref"
                                    value="Veg" v-model="food_pref">
                                <label class="form-check-label" for="food_pref_1">Veg</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="food_pref_2" name="food_pref"
                                    value="Non Veg" v-model="food_pref">
                                <label class="form-check-label" for="food_pref_2">Non Veg</label>
                            </div>
                        </div>
                    </div>
                    <div class="section-title text-primary">
                        Family Members Confirmation
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="text4b">Spouse</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="spouse_coming"
                                    v-model="spouse_coming" value="Yes" id="spouse_coming_1">
                                <label class="form-check-label" for="spouse_coming_1">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="spouse_coming"
                                    name="spouse_coming" id="spouse_coming_2" value="No">
                                <label class="form-check-label" for="spouse_coming_2">No</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" id="spouse_coming_3" v-model="spouse_coming"
                                    type="radio" name="spouse_coming" value="Maybe">
                                <label class="form-check-label" for="spouse_coming_3">Maybe</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group boxed" v-for="child in deligate.child" v-bind:key="child.id">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="text4b">{{ child.name }}</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" :name="'child_coming' + child.id"
                                    v-model="child_coming[child.id]" value="Yes" :id="'child_coming_1' + child.id">
                                <label class="form-check-label" :for="'child_coming_1' + child.id">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="child_coming[child.id]"
                                    :name="'child_coming' + child.id" :id="'child_coming_2' + child.id" value="No">
                                <label class="form-check-label" :for="'child_coming_2' + child.id">No</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" :id="'child_coming_3' + child.id"
                                    v-model="child_coming[child.id]" type="radio" :name="'child_coming' + child.id"
                                    value="Maybe">
                                <label class="form-check-label" :for="'child_coming_3' + child.id">Maybe</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="remarks">Remarks</label>
                            <input type="text" class="form-control" id="remarks" name="remarks" v-model="remarks" placeholder="Remarks">
                        </div>
                    </div>

                    <div class="transparent pt-3">
                        <button class="btn btn-primary btn-block btn-lg" @click="saveConfirmation" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save</span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <!-- qhls registration model -->
    <div class="modal fade action-sheet" id="pendingListModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Pending List</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                            <ul class="listview image-listview inset">
                                <li v-for="(member, index) in this.deligate.pending_deligates" v-bind:key="member.id">
                                    <a href="javascript:void(0)" class="item">
                                        <span class="sl-no">{{ (index + 1) }}</span>
                                        &nbsp;&nbsp;
                                        <div class="in">
                                            <div>
                                                <!-- <header class="mal-text"></header> -->
                                                <b class="list-item-text">{{ member.name }}({{member.proface_id}})</b>
                                                <footer>
                                                    <span class="text-primary">
                                                        {{ member.mobile_no ? member.mobile_no : 'No Number' }}
                                                    </span>
                                                </footer>
                                            </div>
                                            <div class="card-button">
                                                <button type="button" data-bs-dismiss="modal" class="btn btn-link btn-icon" @click="search(null,member.id)">
                                                    <ion-icon name="eye-outline"></ion-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <div class="form-group basic">
                                <button type="button" data-bs-dismiss="modal" class="btn btn-secondary btn-cancel btn-lg col-12">Close</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>

</template>
<script>
import { Form } from "vee-validate";
import * as yup from "yup";
import $ from 'jquery';
import axios from 'axios';
import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    props: {
        unit_id: null
    },
    components: {
        Form,
        AlertModel,
        DialogModel
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            name: yup.string().matches(nameRegex, "പേര് ഇംഗീഷിൽ തന്നെ ടൈപ്പ് ചെയ്യുക!").required("പേര് ടൈപ്പ് ചെയ്യുക!"),
            mobile_number: yup.string().required("മൊബൈൽ നമ്പർ ടൈപ്പ് ചെയ്യുക!"),
            wing: yup.string().required("വിങ് സെലക്ട് ചെയ്യുക!"),
        });
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            schema,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            arrival_confirm: '',
            arrival_time: '',
            saturday_coming: '',
            arrival_mode: '',
            spouse_coming: '',
            food_pref: '',
            child_coming: [],
            remarks:'',
            search_qry: '',
            deligate: {
                deligate: [],
                child: [],
                permissions: []
            },
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.search(true);
        this.$root.updateParent('Proface Confirmation');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },

    methods: {
        search(first_load = null,proface_id = null) {
            if(first_load){
                this.search_qry = 0;
            }
            if(proface_id){
                this.search_qry = proface_id;
                $('#pendingListModel').modal('hide');
            }
            this.loading = true;
            return axios
                .get('https://proface.live/api/get-deligates?proface_id=' + 'PF2-' + this.search_qry + '&&user=' + this.currentUser.name +'&&mobile='+this.currentUser.phone, {})
                .then(response => {
                    if (response.data.status == 'success') {
                        this.deligate = response.data.data;
                        this.arrival_confirm = this.deligate.deligate.arrival_confirm;
                        this.arrival_mode = this.deligate.deligate.arrival_mode;
                        this.saturday_coming = this.deligate.deligate.saturday_coming;
                        this.arrival_time = this.deligate.deligate.arrival_time;
                        this.spouse_coming = this.deligate.deligate.spouse_coming;
                        this.food_pref = this.deligate.deligate.food_pref;
                        this.remarks = this.deligate.deligate.remarks;
                        if (this.deligate.child.length > 0) {
                            this.deligate.child.forEach(element => {
                                this.child_coming[element.id] = element.coming;
                            });
                        }
                        this.loading = false;
                        this.search_qry = '';
                    } else {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.deligate = response.data.data;
                        this.loading = false;
                    }
                });
        },
        saveConfirmation() {
            this.loading = true;
            let data = {
                id: this.deligate.deligate.id,
                arrival_confirm: this.arrival_confirm,
                arrival_mode: this.arrival_mode,
                saturday_coming: this.saturday_coming,
                arrival_time: this.arrival_time,
                spouse_coming: this.spouse_coming,
                child_coming: this.child_coming,
                food_pref: this.food_pref,
                remarks: this.remarks,
                user: this.currentUser.name,
                mobile:this.currentUser.phone
            }
            return axios
                .post('https://proface.live/api/save-deligates-confirmation', data)
                .then(response => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.deligate = response.data.data;
                        this.$refs.alert_model.openModel();
                    } else {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.deligate = response.data.data;
                        this.loading = false;
                    }
                });
        },
        getPendingList() {
            this.loading = true;
            let data = {
                user: this.currentUser.name,
                mobile:this.currentUser.phone
            }
            return axios
                .post('https://proface.live/api/get-pending-deligates', data)
                .then(response => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.deligate = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                    } else {
                        this.alert_type = 'Warning';
                        this.deligate = response.data.data;
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.deligate = response.data.data;
                        this.loading = false;
                    }
                });
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.searchbox .form-control {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #bdbdd5;
    height: 36px;
    padding: 0 10px 0 10px;
}

.form-check {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    min-height: auto;
    height: auto;
    padding-bottom: 5px;
}



.input-group .input-group-text {
    background: transparent;
    border: 0;
    border: 1px solid #1a418c;
    /* border-top: 1px solid #1a418c; */
    border-radius: 5px 5px 5px 5px;
    font-weight: 400;
    color: #27173E;
    height: auto;
    width: auto;
    padding: 0px 10px 0px 10px;
}
.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}
</style>