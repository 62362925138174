<template>
    <div class="section mt-1">
        <div class="goals">
            <div class="meeting-grid">
                <div class="meeting-card">
                    <div class="meeting-link">
                        <div class="in">
                            <div>
                                <h3 class="meeting-title">{{ responseData.meeting.meeting_name_mal }}</h3>
                                <p class="meeting-comment"><span v-if="responseData.meeting.meeting_period">Meeting Period: {{
                                    responseData.meeting.meeting_period
                                }}</span></p>
                            </div>
                            <span class="status-badge price mal-text">
                                <ion-icon name="checkmark-circle-outline" class="price-icon" title="നടന്നു"></ion-icon>
                            </span>
                        </div>
                        <hr>
                        <div class="meeting-details">
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="calendar-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Date</span>
                                    <span class="detail-value">
                                        {{
                                            responseData.meeting.meeting_display_date ? responseData.meeting.meeting_display_date :
                                                '--/--/----'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="time-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Time</span>
                                    <span class="detail-value">
                                        {{
                                              responseData.meeting.meeting_time ? responseData.meeting.meeting_time : '--:--'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="location-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Location</span>
                                    <span class="detail-value">
                                        {{
                                            responseData.meeting.meeting_location ? responseData.meeting.meeting_location :
                                                '---'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="detail-item">
                                <div class="detail-icon">
                                    <ion-icon name="people-outline"></ion-icon>
                                </div>
                                <div class="detail-content">
                                    <span class="detail-label">Participants</span>
                                    <span class="detail-value">
                                        {{
                                           responseData.participants.length ? responseData.participants.length : '---'
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <!-- <div class="status-footer">
                            <span class="status-box">
                                <span class="status-text">Status:{{}}</span>
                            </span>
                        </div> -->
                     <!-- <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85"
                            aria-valuemin="0" aria-valuemax="100">85%</div>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ul class="listview image-listview text inset mt-1" v-if="responseData.permissions.self_coming_view">
        <li>
            <div class="item">
                <div class="in">
                    <div class="mal-text">
                        ഞാൻ പങ്കെടുക്കും
                    </div>
                    <div class="form-check form-switch  ms-2" v-if="responseData.permissions.self_coming_create">
                        <input class="form-check-input" type="checkbox" id="selfAttendance" v-model="self_coming_btn"
                            @change="selfComing" :disabled="responseData.participant_details.coming == 'P'">
                        <label class="form-check-label" for="selfAttendance">
                        </label>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <ul class="listview image-listview text inset mt-1" v-if="responseData.permissions.self_attendance_view">
        <li>
            <div class="item">
                <div class="in">
                    <div>
                        My Attendance
                        <!-- <div class="text-muted">
                            Send notification when new payment received
                        </div> -->
                    </div>
                    <div class="form-check form-switch  ms-2" v-if="responseData.permissions.self_attendance_create">
                        <input class="form-check-input" type="checkbox" id="selfAttendance" v-model="self_attendance_btn"
                            @change="selfAttendance" :disabled="responseData.participant_details.attendance == 'P'">
                        <label class="form-check-label" for="selfAttendance">
                        </label>
                    </div>
                </div>
            </div>
        </li>
        <li v-if="responseData.participant_details.attendance == 'P'">
            <a href="javascript:void(0)" class="item">
                <div class="in text-center">
                    <!-- <div>ID#</div> -->
                    <h3 class="text-primary">Participant ID:</h3>
                    <h2 class="text-danger mr-2">{{ responseData.participant_details.id }}</h2>

                </div>
            </a>
        </li>
    </ul>
    <ul class="listview image-listview text inset mt-1" v-if="responseData.permissions.meeting_link_view">
        <li>
            <div class="item">
                <div class="in">
                    <div class="text-dark">
                        Meeting Link :
                        <a class="text-primary" :href="responseData.meeting.meeting_link" target="_blank">{{responseData.meeting.meeting_link}}</a>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="section mt-1">
        <ul class="nav nav-tabs lined" role="tablist">
            <li class="nav-item" v-if="responseData.permissions.view_details">
                <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                    @click="goToTab('tabD')">
                    {{ responseData.heading.details }}
                </a>
            </li>
            <li class="nav-item" v-if="responseData.permissions.view_participants">
                <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToTab('tabP')">
                    {{ responseData.heading.participants }}
                </a>
            </li>
            <li class="nav-item" v-if="responseData.permissions.view_agenda">
                <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToTab('tabA')">
                    {{ responseData.heading.agenda }}
                </a>
            </li>
            <li class="nav-item" v-if="responseData.permissions.view_minutes">
                <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToTab('tabM')">
                    {{ responseData.heading.minutes }}
                </a>
            </li>
            <li class="nav-item" v-if="responseData.permissions.view_history">
                <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToTab('tabH')">
                    {{ responseData.heading.history }}
                </a>
            </li>
        </ul>
    </div>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section pt-2"
        v-if="(responseData.permissions.attendance_report || responseData.permissions.attendance_qr_code) && tabP && !loading">
        <div class="wallet-card mt-1">
            <div class="wallet-footer wallet-footer-user row">
                <div class="item col-4" v-if="responseData.permissions.attendance_report">
                    <router-link :to="'/meeting/' + this.$route.params.id + '/attendance-report'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper bg-success">
                                <ion-icon name="checkmark-done-outline"></ion-icon>
                                <strong class="menu-title">Report</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-4" v-if="responseData.permissions.attendance_qr_code">
                    <router-link :to="'/meeting/' + this.$route.params.id + '/qr-code-generator'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper bg-primary">
                                <ion-icon name="qr-code-outline"></ion-icon>
                                <strong class="menu-title">QR Code</strong>
                            </div>
                        </a>
                    </router-link>
                </div>
                <div class="item col-4" v-if="responseData.permissions.attendance_qr_code">
                    <router-link :to="'/meeting/' + this.$route.params.id + '/qr-code-scanner'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper bg-danger">
                                <ion-icon name="scan-outline"></ion-icon>
                                <strong class="menu-title">Scanner</strong>
                            </div>
                        </a>
                    </router-link>
                </div>

            </div>
        </div>
    </div>
    <div id="search" v-if="tabP">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
                    @input="getMeetings(1, 'Details')">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div>
    <div id="search" v-if="tabP">
        <form class="search-form">
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_attendance"
                    id="select4" @input="getMeetings(1, 'Details',$event.target.value)">
                    <option value="All">All</option>
                    <option value="P">Present</option>
                    <option value="A">Absent</option>
                    <option value="L">Leave</option>
                </select>
                <i class="input-icon">
                    <ion-icon name="filter-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div>
    <ul class="listview image-listview inset text mt-1" v-if="tabP && !loading">
        <li>
            <a href="javascript:void(0)" class="item">
                <span class="sl-no"></span>
                <div class="in">
                    <div class="min-width-50">
                        <header></header>
                        <b>{{ responseData.heading.participants }}</b>
                        <footer> </footer>
                    </div>
                    <div class="card-button" v-if="responseData.permissions.update_participants">
                        <button type="button" class="btn btn-icon btn-primary me-1" @click="showAddParticipantModal">
                            <ion-icon name="add-outline" class="md hydrated"></ion-icon>
                        </button>
                    </div>
                </div>
            </a>
        </li>
        <li v-for="(member_list, index) in responseData.participants.data" v-bind:key="member_list.id"
            :set="background = (member_list.attendance) == 'P' ? 'rgb(209 232 210)' : (member_list.attendance) == 'A' || member_list.attendance == 'L' ? 'rgb(242 187 202)' : (member_list.attendance) == 'N' ? '#ffffff' : '#ffffff'"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item" v-if="member_list.member">
                <span class="sl-no">{{ responseData.participants.from + index }}.</span>
                <div class="avatar-section">
                    <a href="#">
                        <img v-if="member_list.member.avatar != 'member.jpg' && member_list.member.avatar != null"
                            :src="'https://guide.msoftit.com/' + member_list.member.avatar" alt="avatar" class="image">
                        <img v-if="!member_list.member.avatar || member_list.member.avatar == 'member.jpg'"
                            :src="'assets/img/sample/avatar/avatar1.png'" alt="avatar" class="image">
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <header>{{member_list.designation}}</header>
                        <b> {{ member_list.member.name }} </b>
                        <footer>{{ member_list.member.country_code }} {{ member_list.member.mobile }}
                            <br v-if="member_list.attendance == 'P'">
                            <span v-if="member_list.attendance == 'P'" class="text-danger">Participant ID: {{ member_list.id }}</span>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.member.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.member.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li>
                                            <a :href="'tel:' + member_list.member.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.member.whatsapp">
                                            <a :href="'https://wa.me/' + member_list.member.whatsapp_country_code + member_list.member.whatsapp + '?text='"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="responseData.permissions.update_participants">
                                            <a href="javascript:void(0)" @click="attendanceOpen(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="checkmark-done-outline"></ion-icon>Attendance
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="tabP && !loading">
        <vue-awesome-paginate v-if="responseData.participants.total > 0" :total-items="responseData.participants.total"
            :items-per-page="responseData.participants.per_page" :max-pages-shown="3"
            v-model="responseData.participants.current_page" :on-click="getMeetings" :show-ending-buttons="true"
            :show-breakpoint-buttons="false" />
    </div>
    <div class="section mt-1" v-if="!loading && tabD">
        <div class="card mb-2" v-if="responseData.permissions.view_my_duties">
            <div class="card-header text-primary">My Duties</div>
            <div class="card-body">
                <p class="card-text text-dark">
                <ol>
                    <li v-for="duty in responseData.my_duties" v-bind:key="duty.id">{{ duty.agenda }}</li>
                </ol>
                </p>
            </div>
        </div>
        <Form @submit="meetingDetailSave" v-if="responseData.permissions.view_details_form">
            <div class="card">
                <div class="card-body">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="pin">Date</label>
                            <Field type="date" class="form-control" name="meeting_date" v-model="meeting_date"
                                :disabled="!responseData.permissions.update_details || !responseData.permissions.update_date" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="meeting_date" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="pin">Time</label>
                            <Field type="time" class="form-control" name="meeting_time" v-model="meeting_time"
                                :disabled="!responseData.permissions.update_details || !responseData.permissions.update_time" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="meeting_time" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="code">Meeting Mode</label>
                            <Field as="select" class="form-control custom-select" id="meeting_mode" name="meeting_mode"
                                v-model="meeting_mode"
                                :disabled="!responseData.permissions.update_details || !responseData.permissions.update_mode">
                                <option value="" :disabled="true" selected>Select Meeting Mode</option>
                                <option value="Pysical">Physical</option>
                                <option value="Online">Online</option>
                            </Field>
                        </div>
                        <ErrorMessage name="meeting_mode" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="pin">Location</label>
                            <Field type="text" class="form-control" name="meeting_location" placeholder="Meeting Location"
                                v-model="meeting_location"
                                :disabled="!responseData.permissions.update_details || !responseData.permissions.update_location" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="meeting_location" class="error-feedback" />
                    </div>
                    <div class="transparent pt-3" v-if="responseData.permissions.update_details">
                        <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading">
                            <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                            <span>Save</span>
                        </button>
                    </div>
                </div>

            </div>
        </Form>
    </div>
    <!-- <div class="section" v-if="!loading && tabA">
        <div class="wallet-card mt-1">
        <p> <b class="text-primary">വിസ്ഡം ഡേ പ്രോഗ്രാം</b> <br>
            <b class="text-primary">സ്വാഗതം :</b>  സെക്രട്ടറി, വിസ്ഡം യൂത്ത് <br>
            <b class="text-primary">അധ്യക്ഷന്‍ :</b>  പ്രസിഡണ്ട്, മാതൃ ഘടകം<br>
                <b class="text-primary">മിനുട്‌സ്, റിപ്പോര്‍ട്ട്, കണക്ക് അവതരണം :</b>  ഓരോ ഘടകത്തിന്റെയും സെക്രട്ടറിമാര്‍ <br>
                    <b class="text-primary">വീഡിയോ അവതരണം</b> <br>
                        <b class="text-primary">സര്‍ക്കുലര്‍ വിശദീകരണം & ചര്‍ച്ച :</b>  സെക്രട്ടറി, മാതൃ ഘടകം <br>
                            <b class="text-primary">പാഠക്കുറിപ്പ് അവതരണം :</b> ചുമതലപ്പെടുത്തുന്ന ശാഖാ ഭാരവാഹി <br>
                                <b class="text-primary">നന്ദി :</b> സെക്രട്ടറി, സ്റ്റുഡന്റ്‌സ്</p>
                            </div>
    </div> -->
    <ul class="listview image-listview inset text mt-1" v-if="!loading && tabA">
        <li>
            <a href="javascript:void(0)" class="item">
                <span class="sl-no"></span>
                <div class="in">
                    <div class="min-width-50">
                        <header></header>
                        <b>{{ responseData.heading.agenda }}</b>
                        <footer> </footer>
                    </div>
                    <div class="card-button" v-if="responseData.permissions.update_agenda">
                        <button type="button" class="btn btn-icon btn-primary me-1" @click="showAddAgendaModal">
                            <ion-icon name="add-outline" class="md hydrated"></ion-icon>
                        </button>
                    </div>
                </div>
            </a>
        </li>
        <li v-for="(agenda, index) in responseData.agenda" v-bind:key="agenda.id">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ index + 1 }}.</span>
                &nbsp;&nbsp;
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b class="mal-text"> {{ agenda.agenda }} </b>
                        <footer class="text-primary" v-if="agenda.assigned_member_name">{{
                            agenda.assigned_member_name
                        }}</footer>
                    </div>
                    <div class="card-button dropdown" v-if="responseData.permissions.update_agenda">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + agenda.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + agenda.id" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li>
                                            <a href="javascript:void(0)" class="btn btn-list"
                                                @click="showAddAgendaModal(agenda)" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="create-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" class="btn btn-list"
                                                @click="deleteAgendaConfirmation(agenda)" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div v-if="!loading && tabM">
        <div class="section mt-1">
            <ul class="nav nav-tabs capsuled" role="tablist">
                <li class="nav-item" v-if="responseData.permissions.view_questians">
                    <a class="nav-link" v-bind:class="responseData.permissions.view_questians ? 'active' : ''"
                        data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToMinutesTab('tabMQ')">
                        Questionnaire
                    </a>
                </li>
                <li class="nav-item" v-if="responseData.permissions.view_custom_minutes">
                    <a class="nav-link" v-bind:class="responseData.permissions.view_questians ? '' : 'active'"
                        data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToMinutesTab('tabMM')">
                        Minutes
                    </a>
                </li>
            </ul>
        </div>
        <div class="section text-center mt-1" v-if="minutes_loading">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div class="section mt-1" v-if="!minutes_loading && tabMQ && responseData.permissions.view_questians">
            <Form @submit="saveQuestianair" :validation-schema="questianair_schema">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group basic" v-for="(questian, index) in responseData.questians"
                            v-bind:key="questian.id">
                            <div class="input-wrapper">
                                <label class="label questians-label" :for="'field' + questian.id"><b>{{ index + 1 }}. {{
                                    questian.questian.question_text
                                }}</b></label>
                                <Field type="text" class="form-control" :name="'questian_air[' + index + ']'"
                                    placeholder="..." v-model="questians_responses[index]"
                                    :required="questian.questian.question_validations == 'Y'" />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="form-group basic">
                            <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading"
                                v-if="responseData.permissions.update_minutes">
                                <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                            <button type="button" class="btn btn-primary btn-block btn-lg"
                                v-if="!responseData.permissions.update_minutes" @click="closeAddMinutesModal">Close</button>
                        </div>

                    </div>
                </div>
            </Form>
        </div>
        <ul class="listview image-listview inset text mt-1" v-if="!minutes_loading && tabMM">
            <li>
                <a href="javascript:void(0)" class="item">
                    <span class="sl-no"></span>
                    <div class="in">
                        <div class="min-width-50">
                            <header></header>
                            <b>Meeting Minutes</b>
                            <footer> </footer>
                        </div>
                        <div class="card-button" v-if="responseData.permissions.update_minutes">
                            <button type="button" class="btn btn-icon btn-primary me-1" @click="showAddMinutesModal">
                                <ion-icon name="add-outline" class="md hydrated"></ion-icon>
                            </button>
                        </div>
                    </div>
                </a>
            </li>
            <li v-for="(minutes, index) in responseData.minutes" v-bind:key="minutes.id">
                <a href="javascript:void(0)" class="item">
                    <span class="sl-no">{{ index + 1 }}.</span>
                    &nbsp;&nbsp;
                    <div class="in">
                        <div class="min-width-50">
                            <!-- <header>User</header> -->
                            <b class="mal-text"> {{ minutes.minutes }} </b>
                            <footer class="text-primary" v-if="minutes.assigned_member_name">{{
                                minutes.assigned_member_name
                            }}</footer>
                        </div>
                        <div class="card-button dropdown" v-if="responseData.permissions.update_minutes">
                            <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                                :data-bs-target="'#actionSheetInset' + minutes.id" @click="showActionModel">
                                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                            </button>
                        </div>

                        <!-- Default Action Sheet Inset -->
                        <div class="modal fade action-sheet" :id="'actionSheetInset' + minutes.id" tabindex="-1"
                            role="dialog">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Action</h5>
                                    </div>
                                    <div class="modal-body">
                                        <ul class="action-button-list">
                                            <li>
                                                <a href="javascript:void(0)" class="btn btn-list"
                                                    @click="showAddMinutesModal(minutes)" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="create-outline"></ion-icon> Edit
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)" class="btn btn-list"
                                                    @click="deleteMinutesConfirmation(minutes)" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="trash-outline"></ion-icon> Remove
                                                    </span>
                                                </a>
                                            </li>
                                            <li class="action-divider"></li>
                                            <li>
                                                <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                    <span>
                                                        <ion-icon name="close-outline"></ion-icon> Cancel
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- * Default Action Sheet Inset -->
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <ul class="listview image-listview inset text mt-1" v-if="!loading && tabH">
        <li>
            <a href="javascript:void(0)" class="item">
                <span class="sl-no"></span>
                <div class="in">
                    <div class="min-width-50">
                        <header></header>
                        <b>{{ responseData.heading.history }}</b>
                        <footer> </footer>
                    </div>
                    <div class="card-button" v-if="responseData.permissions.update_history">
                        <button type="button" class="btn btn-icon btn-primary me-1" @click="showAddHistoryModal">
                            <ion-icon name="add-outline" class="md hydrated"></ion-icon>
                        </button>
                    </div>
                </div>
            </a>
        </li>
        <li v-for="(history, index) in responseData.history" v-bind:key="history.id">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ index + 1 }}.</span>
                &nbsp;&nbsp;
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header>User</header> -->
                        <b class="mal-text"> {{ history.text }} </b>
                        <footer></footer>
                    </div>
                    <div class="card-button dropdown" v-if="responseData.permissions.update_history">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + history.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + history.id" tabindex="-1" role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li>
                                            <a href="javascript:void(0)" class="btn btn-list"
                                                @click="showAddHistoryModal(history)" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="create-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" class="btn btn-list"
                                                @click="deleteHistoryConfirmation(history)" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-1 mb-2" v-if="!loading && tabH">
        <!-- <div class="section-title">with Time Labels</div> -->
        <div class="card">
            <!-- timeline -->
            <div class="timeline timed ms-1 me-2">
                <div class="item" v-for="history in responseData.meeting_history" v-bind:key="history.id">
                    <span class="time">{{ history.time }}</span>
                    <div class="dot bg-primary"></div>
                    <div class="content">
                        <h4 class="title">{{ history.added_by_name }}</h4>
                        <div class="text">{{ history.text }}</div>
                    </div>
                </div>
            </div>
            <!-- * timeline -->
        </div>
    </div>

    <!-- AddAgendaModel -->
    <div class="modal fade action-sheet" id="addAgendaModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ responseData.heading.agenda }}</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="saveAgenda" :validation-schema="agenda_schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="name"><span>{{
                                        responseData.heading.agenda
                                    }}</span></label>
                                    <Field name="agenda" type="text" class="form-control" v-model="agenda"
                                        :placeholder="responseData.heading.agenda" autocomplete="off" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="agenda_assigned_member">Assigned Member</label>
                                    <Field as="select" class="form-control" id="agenda_assigned_member"
                                        name="agenda_assigned_member" v-model="agenda_assigned_member">
                                        <option value>Select Member</option>
                                        <option v-for="member in responseData.participants.data" v-bind:key="member.member_id"
                                            v-bind:value="member.member_id">{{
                                                member.member?member.member.name:''
                                            }}</option>
                                    </Field>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading"
                                    v-if="responseData.permissions.update_agenda">
                                    <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!responseData.permissions.update_agenda"
                                    @click="closeAddAgendaModal">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- AddAgendaModel -->

    <!-- AddMinutesModel -->
    <div class="modal fade action-sheet" id="addMinutesModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Minutes</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="saveMinutes" :validation-schema="minutes_schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="minutes"><span>Minutes</span></label>
                                    <Field name="minutes" type="text" class="form-control" v-model="minutes"
                                        placeholder="Minutes" autocomplete="off" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="minutes_assigned_member">Assigned Member</label>
                                    <Field as="select" class="form-control" id="minutes_assigned_member"
                                        name="minutes_assigned_member" v-model="minutes_assigned_member">
                                        <option value>Select Member</option>
                                        <option v-for="member in responseData.participants.data" v-bind:key="member.member_id"
                                            v-bind:value="member.member_id">{{
                                                member.member.name
                                            }}</option>
                                    </Field>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading"
                                    v-if="responseData.permissions.update_minutes">
                                    <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!responseData.permissions.update_minutes"
                                    @click="closeAddMinutesModal">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- AddMinutesModel -->

    <!-- attendanceModel -->
    <div class="modal fade action-sheet" id="attendanceModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Meeting Attendance</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="form-group" v-if="attendance_message">
                            <div v-if="attendance_message" class="error-feedback">
                                {{ attendance_message }}
                            </div>
                        </div>
                        <div class="form-group basic">
                            <label class="label" for="stay"><span>Attendance</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <select as="select" class="form-control custom-select" v-model="attendance"
                                        name="attendance" id="select4">
                                        <option value="P">Present</option>
                                        <option value="A">Absend</option>
                                        <option value="L">Leave</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                        </div>
                        <div class="form-group basic" v-if="attendance == 'A' || attendance == 'L'">
                            <label class="label" for="stay"><span>Remarks</span></label>
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <div class="form-group boxed">
                                        <input name="attendance_remarks" type="text" class="form-control"
                                            v-model="attendance_remarks" placeholder="Remarks" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <button type="button" @click="markAttandance()" class="btn btn-primary btn-block btn-lg"
                                :disabled="loading">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                <span>Save</span></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- AddMinutesModel -->
    <div class="modal fade action-sheet" id="addHistoryModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> {{ responseData.heading.history }}</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="saveHistory" :validation-schema="history_schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="text"><span>Text</span></label>
                                    <Field name="text" type="text" class="form-control" v-model="text" placeholder="Text"
                                        autocomplete="off" required />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading"
                                    v-if="responseData.permissions.update_history">
                                    <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!responseData.permissions.update_history"
                                    @click="closeAddHistroyModal">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- AddMinutesModel -->
    <!-- AddParticipantModel -->
    <div class="modal fade action-sheet" id="addParticipantModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ responseData.heading.participants }}</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="saveParticipant" :validation-schema="participant_schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="participant">Member</label>
                                    <v-select label="text" :filterable="true" v-model="participant" :value="participant"
                                        :options="member_list" @input="getTextList($event.target.value, 'all')">
                                        <template>
                                            type to search...
                                        </template>
                                        <template>
                                            <div class="d-center">
                                                {{ participant.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                                <div class="input-info" v-if="participant">Mobile :{{ participant.country_code }} {{
                                    participant.mobile
                                }}
                                </div>
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btnLoading"
                                    v-if="responseData.permissions.update_participants">
                                    <span v-show="btnLoading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    v-if="!responseData.permissions.update_participants"
                                    @click="closeAddAgendaModal">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- AddParticipantModel -->
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import vSelect from 'vue-select'
import $ from 'jquery';
export default {
    name: 'Organization',
    components: {
        Form,
        Field,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            minutes_loading: false,
            btnLoading: false,
            participant: [],
            member_list: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            tabD: true,
            tabP: false,
            tabA: false,
            tabM: false,
            tabH: false,
            tabMQ: false,
            tabMM: true,
            responseData: {
                meeting: [],
                participants: [],
                agenda: [],
                minutes: [],
                permissions: [],
                questians: [],
                heading: [],
                participant_details: []
            },
            meeting_id: '',
            meeting_location: '',
            meeting_date: '',
            meeting_time: '',
            meeting_mode: '',
            text: '',
            agenda: '',
            minutes: '',
            editable_id: '',
            attendance: 'A',
            attendance_remarks: '',
            message: "",
            user_level: "",
            permissions: [],
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
            unit: [],
            nerpatham_target: '',
            sub_level_id: '',
            sub_level: [],
            questians_responses: [],
            agenda_assigned_member: '',
            minutes_assigned_member: '',
            self_attendance_btn: false,
            self_coming_btn: false,
            search_qty: '',
            filter_attendance:'All'
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.meeting_id = this.$route.params.id;
            this.user_level = this.currentUser.user_level;
            this.$root.updateParent("Loading...");
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            }
            else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            }
            else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getMeetings(1, 'Details');
        }
    },
    methods: {
        goToTab(tab) {
            this.loading = true;
            this.tabD = false;
            this.tabP = false;
            this.tabA = false;
            this.tabM = false;
            this.tabH = false;
            if (tab == 'tabD') {
                this.tabD = true;
                // this.getMeetings('Details');
                this.loading = false;
            } else if (tab == 'tabP') {
                this.tabP = true;
                // this.getMeetings('Participants');
                this.loading = false;
            } else if (tab == 'tabA') {
                this.tabA = true;
                // this.getMeetings('Agenda');
                this.loading = false;
            } else if (tab == 'tabM') {
                this.tabM = true;
                // this.getMeetings('Minutes');
                this.loading = false;
            } else {
                this.tabH = true;
                // this.getMeetings('Minutes');
                this.loading = false;
            }
        },
        goToMinutesTab(tab) {
            this.minutes_loading = true;
            this.tabMM = false;
            this.tabMQ = false;
            if (tab == 'tabMM') {
                this.tabMM = true;
                this.minutes_loading = false;
            } else {
                this.tabMQ = true;
                this.minutes_loading = false;
            }
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'all',
                user_level: 1
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getMeetings(page = 1, type = 'Details',filter_attendance = null) {
            this.loading = true;
            this.filter_attendance = filter_attendance?filter_attendance:this.filter_attendance;
            let data = {
                type: type,
                meeting_id: this.meeting_id,
                search_qty: this.search_qty,
                filter_attendance : this.filter_attendance
            }
            UserService.authPostRequest(`get-meeting-detail?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.responseData = response.data.data;
                        this.meeting_date = this.responseData.meeting.meeting_date;
                        this.meeting_time = this.responseData.meeting.meeting_time;
                        this.meeting_mode = this.responseData.meeting.meeting_mode;
                        this.meeting_location = this.responseData.meeting.meeting_location;
                        if (this.responseData.questians) {
                            this.tabMQ = true;
                            let count = 0;
                            this.responseData.questians.forEach(element => {
                                this.questians_responses[count] = element.answer_text;
                                count++;
                            });
                        }
                        if (this.responseData.participant_details.attendance == 'P') {
                            this.self_attendance_btn = true;
                        }
                        if (this.responseData.participant_details.coming_status == 'P') {
                            this.self_coming_btn = true;
                        }
                        this.$root.updateParent('Meeting Dashboard');
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/level-2-org/' + (this.user_level + 1) + '/' + sub_level_id);
        },
        nerpathamTarget(sub_level = null) {
            this.nerpatham_target = sub_level.nerpatham_target;
            this.sub_level_id = sub_level.id;
            $('#nerpathamTargetModel').modal('show');
        },
        meetingDetailSave(data) {
            this.btnLoading = true;
            data.meeting_id = this.meeting_id;
            UserService.addAdmin('update-meeting-detail', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else {
                        this.btnLoading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btnLoading = false;
                }
            );
        },
        showAddAgendaModal(agenda = null) {
            this.agenda = '';
            this.editable_id = '';
            if (agenda) {
                this.agenda = agenda.agenda;
                this.editable_id = agenda.id;
            }
            $('#addAgendaModel').modal('show');
        },
        closeAddAgendaModal() {
            $('#addAgendaModel').modal('hide');
        },
        saveAgenda(agenda) {
            this.btnLoading = true;
            agenda.meeting_id = this.meeting_id;
            agenda.editable_id = this.editable_id;

            UserService.authPostRequest('store-agenda', agenda).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAgendaModel').modal('hide');
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else {
                        this.btnLoading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btnLoading = false;
                }
            );
        },
        deleteAgendaConfirmation(agenda) {
            var data = {
                editable_id: agenda.id,
                meeting_id: this.meeting_id
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'deleteAgenda';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        deleteAgenda(data) {
            UserService.authPostRequest('delete-agenda', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        showAddMinutesModal(minutes = null) {
            this.minutes = '';
            this.editable_id = '';
            if (minutes) {
                this.minutes = minutes.minutes;
                this.editable_id = minutes.id;
            }
            $('#addMinutesModel').modal('show');
        },
        closeAddMinutesModal() {
            $('#addMinutesModel').modal('hide');
        },
        saveMinutes(minutes) {
            this.btnLoading = true;
            minutes.meeting_id = this.meeting_id;
            minutes.editable_id = this.editable_id;

            UserService.authPostRequest('store-minutes', minutes).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addMinutesModel').modal('hide');
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else {
                        this.btnLoading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btnLoading = false;
                }
            );
        },
        deleteMinutesConfirmation(minutes) {
            var data = {
                editable_id: minutes.id,
                meeting_id: this.meeting_id
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'deleteMinutes';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        deleteMinutes(data) {
            UserService.authPostRequest('delete-minutes', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },


        showAddHistoryModal(history = null) {
            this.history = '';
            this.editable_id = '';
            if (history) {
                this.history = history.text;
                this.editable_id = history.id;
            }
            $('#addHistoryModel').modal('show');
        },
        closeAddHistoryModal() {
            $('#addHistoryModel').modal('hide');
        },
        saveHistory(history) {
            this.btnLoading = true;
            history.meeting_id = this.meeting_id;
            history.editable_id = this.editable_id;
            UserService.authPostRequest('store-history', history).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addHistoryModel').modal('hide');
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else {
                        this.btnLoading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btnLoading = false;
                }
            );
        },
        deleteHistoryConfirmation(history) {
            var data = {
                editable_id: history.id,
                meeting_id: this.meeting_id
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'deleteHistory';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        deleteHistory(data) {
            UserService.authPostRequest('delete-history', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },


        showAddParticipantModal(participant = null) {
            this.participant = '';
            this.editable_id = '';
            if (participant) {
                this.participant = participant.id;
                this.editable_id = participant.id;
            }
            $('#addParticipantModel').modal('show');
        },
        closeAddParticipantModal() {
            $('#addParticipantModel').modal('hide');
        },
        saveParticipant(participant) {
            console.log(participant);
            this.btnLoading = true;
            participant.meeting_id = this.meeting_id;
            participant.editable_id = this.editable_id;
            participant.member_id = this.participant.id;
            UserService.authPostRequest('store-participant', participant).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addParticipantModel').modal('hide');
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else {
                        this.btnLoading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btnLoading = false;
                }
            );
        },
        deleteParticipantConfirmation(participant) {
            var data = {
                editable_id: participant.id,
                meeting_id: this.meeting_id
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'deleteParticipant';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        deleteParticipant(data) {
            UserService.authPostRequest('delete-participant', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },




        attendanceOpen(meeting) {
            this.editable_id = meeting.id,
                this.attendance = meeting.attendance != 'N' ? meeting.attendance : 'A';
            this.attendance_remarks = meeting.remarks;
            $('#attendanceModel').modal('show');
        },
        markAttandance() {
            let data = {
                editable_id: this.editable_id,
                meeting_id: this.meeting_id,
                attendance: this.attendance,
                remarks: this.attendance_remarks
            }
            this.loading = true;
            UserService.authPostRequest('store-meeting-attendance', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        $('#attendanceModel').modal('hide');
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );

        },
        selfAttendance() {
            if (this.self_attendance_btn) {
                this.editable_id = this.responseData.participant_details.id;
                this.attendance = 'P';
                this.markAttandance();
            }
        },
        markComingStatus() {
            let data = {
                editable_id: this.editable_id,
                meeting_id: this.meeting_id,
                attendance: this.attendance,
                remarks: this.attendance_remarks
            }
            this.loading = true;
            UserService.authPostRequest('store-meeting-coming-status', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        $('#attendanceModel').modal('hide');
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );

        },
        selfComing() {
            if (this.self_coming_btn) {
                this.editable_id = this.responseData.participant_details.id;
                this.attendance = 'P';
                this.markComingStatus();
            }
        },
        saveQuestianair(data) {
            this.btnLoading = true;
            // data.questians = this.questians_responses;
            data.meeting_id = this.meeting_id;
            UserService.addAdmin('update-meeting-questianair', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btnLoading = false;
                    } else {
                        this.btnLoading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.btnLoading = false;
                }
            );
        },
    },
};
</script>
<style scoped>
.list-title {
    font-size: 16px;
    color: black;
}

.item {
    gap: 12px;
    /* margin-bottom: 10px; */
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    /* height: 46px !important; */
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}


.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}


.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.w-50 {
    width: 50px !important;
}

.goals .item {
    padding: 10px 10px;
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    margin-bottom: 10px;
}

.price-icon {
    /* margin-top: -1%; */
    font-size: 24px;
}

.chip-label[data-v-3bccde2f] {
    padding: 0px 5px 0px 5px !important;
    font-size: 14px !important;
}

.pad-5-left {
    padding-left: 5px;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.avatar-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
}

.avatar-section img {
    width: 100%;
    height: 100%;
}

.sl-no {
    font-size: 15px;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.questians-label {
    font-weight: 600 !important;
    font-size: 14px !important;

}

.meeting-title {
    font-size: 20px !important;
}

.tick-icon {
    color: green;
    /* Customize the color of the tick icon */
}

.meeting-card {
    background-color: #ffffff;
    border-radius: 8px;
}

.meeting-link {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 20px;
}

.in {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.meeting-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #333333;
}

.meeting-comment {
    margin: 0;
    font-size: .8rem;
    color: #333333;
}

.status-badge {
    color: #4CAF50;
    font-size: 1.5rem;
}

.title-line {
    border: 0;
    height: 1px;
    margin: 10px 0;
}

.meeting-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.detail-item {
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 0.9rem;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    height: auto;
    box-sizing: border-box;
    word-wrap: break-word;
}

.detail-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    background-color: #f0f4f8;
    flex-shrink: 0;
}

.detail-icon ion-icon {
    font-size: 1.25rem;
    color: #011584;
}

.detail-content {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 52px);
    overflow-wrap: break-word;
}

.detail-label {
    font-size: 0.75rem;
    color: #888888;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.detail-value {
    font-size: 0.95rem;
    color: #333333;
    font-weight: 500;
    word-wrap: break-word;
}

.status-box {
    background-color: #cceac1;
    border-radius: 5px;
    padding: 5px 5px;
    display: inline-block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.status-text {
    font-size: 0.8rem;
    color: #00796b;
    font-weight: 500;
}
</style>