<template>
    <div id="search" class="mt-1">
        <form class="search-form">
            <div class="row">
                <div class="col-3">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="code">District <span class="text-danger">*</span></label>
                            <select as="select" class="form-control custom-select" id="code" name="code" v-model="code">
                                <option value="" :disabled="true" selected>---</option>
                                <option v-for="code in district_code_list" v-bind:key="code.code"
                                    v-bind:value="code.code">
                                    {{ code.code }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label" for="number">Code <span class="text-danger">*</span></label>
                            <input type="number" class="form-control" id="number" name="number" placeholder="Number"
                                v-model="number"
                                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <span class="error-feedback">{{ code_validation }}</span>
                    </div>
                </div>
                <div class="col-4 mt-3">
                    <div class="form-group">
                        <button type="submit" @click="search()" class="btn btn-primary btn-block btn-md"
                            :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Search</span></button>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="input-group form-group" style="width:70% !important;">
                    <input type="text" class="form-control" maxlength="9999" focus v-model="search_qry"
                        aria-describedby="basic-addon3" style="border: 1px solid #1a418c;" placeholder="Bus Code" />
                </div>
                <div class="form-group" style="width:30% !important;">
                    <button type="submit" @click="search()" class="btn btn-primary btn-block btn-md"
                        :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Search</span></button>
                </div>
            </div> -->
        </form>
    </div>
    <div class="section inset mt-1" v-if="!loading_list && deligate.bus.bus_code" style="height: 400px;">
        <div class="wide-block pt-2 pb-2">
            <div class="listed-detail mt-0 text-center">
                <h3 class="text-center text-primary">{{ deligate.bus.bus_code }}</h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-1">
                <li v-for="rows in deligate.rows" v-bind:key="rows">
                    <strong>{{ rows.title }}</strong>
                    <span class="mal-text text-primary text-right">{{ rows.description }}</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="section inset mt-1" v-if="!loading_list && !deligate.bus.bus_code" style="height: 400px;">
        <div class="wide-block pt-2 pb-2">
            <div class="listed-detail mt-0 text-center">
                <h3 class="text-center text-primary">No Result</h3>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>

</template>
<script>
import $ from 'jquery';
import UserService from "../services/user.service";
import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
export default {
    props: {
        unit_id: null
    },
    components: {
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            code: '',
            number: '',
            code_validation: '',
            district_code_list: [
                { code: "TVM" },
                { code: "KLM" },
                { code: "PTN" },
                { code: "ALP" },
                { code: "KTM" },
                { code: "IDK" },
                { code: "ERN" },
                { code: "TCR" },
                { code: "PKD" },
                { code: "MLW" },
                { code: "MLN" },
                { code: "MLE" },
                { code: "CLS" },
                { code: "CLN" },
                { code: "WYD" },
                { code: "KNR" },
                { code: "KSG" },
                { code: "NLG" },
                { code: "COI" },
                { code: "CHN" },
                { code: "BGR" },
                { code: "MGR" },
                { code: "KDG" }
            ],
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            arrival_confirm: '',
            arrival_time: '',
            saturday_coming: '',
            arrival_mode: '',
            spouse_coming: '',
            food_pref: '',
            child_coming: [],
            remarks: '',
            search_qry: '',
            deligate: {
                bus: [],
                permissions: []
            },
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
        };
    },

    computed: {
        // currentUser() {
        //     return this.$store.state.auth.user;
        // }
    },
    mounted() {
        // if (!this.currentUser) {
        //     location.replace('/');
        // }
        this.search();
        this.$root.updateParent('Wisdom Islamic Conference');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },

    methods: {
        search() {
            this.loading = true;
            let data = {
                bus_code: this.code + '-' + this.number,
                page: "bus-details",
            };
            UserService.authPostRequest(`get-event-bus-details`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.deligate = response.data.data;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.searchbox .form-control {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #bdbdd5;
    height: 36px;
    padding: 0 10px 0 10px;
}

.form-check {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    min-height: auto;
    height: auto;
    padding-bottom: 5px;
}



.input-group .input-group-text {
    background: transparent;
    border: 0;
    border: 1px solid #1a418c;
    /* border-top: 1px solid #1a418c; */
    border-radius: 5px 5px 5px 5px;
    font-weight: 400;
    color: #27173E;
    height: auto;
    width: auto;
    padding: 0px 10px 0px 10px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}
</style>