<template>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section" v-if="!loading_list">
        <div class="row mt-1">
            <div class="col-12" v-if="responseData.count_total.total">
                <div class="stat-box">
                    <div class="title mal-text">Total</div>
                    <div class="value text-dark"><span class="text-success">{{ responseData.count_total ? responseData.count_total.total_reg : '00' }}</span>{{responseData.count_total.total_reg?'/':''}}<span class="text-danger">{{ responseData.count_total ? responseData.count_total.total : '00' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1" v-if="responseData.count_total.male">
                <div class="stat-box">
                    <div class="title mal-text">Male</div>
                    <div class="value text-dark"><span class="text-success">{{ responseData.count_total ? responseData.count_total.male_reg : '00' }}</span>{{responseData.count_total.male_reg?'/':''}}<span class="text-danger">{{ responseData.count_total ? responseData.count_total.male : '00' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1" v-if="responseData.count_total.female">
                <div class="stat-box">
                    <div class="title">Female</div>
                    <div class="value text-dark"><span class="text-success">{{ responseData.count_total ? responseData.count_total.female_reg : '00' }}</span>{{responseData.count_total.female_reg?'/':''}}<span class="text-danger">{{ responseData.count_total ? responseData.count_total.female : '00' }}</span>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-1" v-if="responseData.count_total.youth">
                <div class="stat-box">
                    <div class="title mal-text">Youth</div>
                    <div class="value text-dark"><span class="text-success">{{ responseData.count_total ? responseData.count_total.youth_reg : '00' }}</span>{{responseData.count_total.youth_reg?'/':''}}<span class="text-danger">{{ responseData.count_total ? responseData.count_total.youth : '00' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1" v-if="responseData.count_total.wisdom">
                <div class="stat-box">
                    <div class="title mal-text">Wisdom</div>
                    <div class="value text-dark"><span class="text-success">{{ responseData.count_total ? responseData.count_total.wisdom_reg : '00' }}</span>{{responseData.count_total.wisdom_reg?'/':''}}<span class="text-danger">{{ responseData.count_total ? responseData.count_total.wisdom : '00' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1" v-if="responseData.count_total.student">
                <div class="stat-box">
                    <div class="title mal-text">Student</div>
                    <div class="value text-dark"><span class="text-success">{{ responseData.count_total ? responseData.count_total.student_reg : '00' }}</span>{{responseData.count_total.student_reg?'/':''}}<span class="text-danger">{{ responseData.count_total ? responseData.count_total.student : '00' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1" v-if="responseData.count_total.women">
                <div class="stat-box">
                    <div class="title mal-text">Women</div>
                    <div class="value text-dark"><span class="text-success">{{ responseData.count_total ? responseData.count_total.women_reg : '00' }}</span>{{responseData.count_total.women_reg?'/':''}}<span class="text-danger">{{ responseData.count_total ? responseData.count_total.women : '00' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1" v-if="responseData.count_total.girls">
                <div class="stat-box">
                    <div class="title mal-text">Girls</div>
                    <div class="value text-dark"><span class="text-success">{{ responseData.count_total ? responseData.count_total.girls_reg : '00' }}</span>{{responseData.count_total.girls_reg?'/':''}}<span class="text-danger">{{ responseData.count_total ? responseData.count_total.girls : '00' }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="col-4 mt-1" v-if="responseData.event.permissions.kids && responseData.count_total.y13">
                <div class="stat-box">
                    <div class="title mal-text">1-3Y</div>
                    <div class="value text-info">{{ responseData.count_total.y13 ? responseData.count_total.y13 : '00' }}
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1" v-if="responseData.event.permissions.kids && responseData.count_total.y48">
                <div class="stat-box">
                    <div class="title mal-text">4-8Y</div>
                    <div class="value text-primary">{{ responseData.count_total.y48 ? responseData.count_total.y48 : '00' }}
                    </div>
                </div>
            </div>
            <div class="col-6 mt-1" v-if="responseData.event.permissions.kids && responseData.count_total.y914">
                <div class="stat-box">
                    <div class="title mal-text">9-14Y</div>
                    <div class="value text-danger">{{ responseData.count_total.y914 ? responseData.count_total.y914 : '00' }}
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="section pt-0 mt-0" v-if="!loading_list">
        <div class="listed-detail mt-2">
            <h3 class="text-center mt-2 mal-text custom-title">{{ responseData.event.name }}<br>{{ page_title }}</h3>
        </div>
    </div>

    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Male</th>
                            <th scope="col">Female</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="sub_level in member_lists" v-bind:key="sub_level">
                            <td class="text-primary font-dark">
                                <router-link v-if="user_level <= 2"
                                    :to="'/event/' + $route.params.slug + '/members-summary-report/' + (user_level+1) + '/' + sub_level.id + ''">
                                    {{ sub_level.name }}
                                </router-link>
                                <router-link v-if="user_level == 3" :to="'/event/' + $route.params.slug + '/members-list/'+sub_level.id">
                                    {{ sub_level.name }}
                                </router-link>
                            </td>
                            <td class="text-primary font-dark">
                                <div class="chip chip-media">
                                    <i class="chip-icon bg-dark">
                                        T
                                    </i>
                                    <span class="chip-label">{{ sub_level.event_reg.male_reg }}/{{ sub_level.event_reg.male }}</span>
                                </div>
                                <br>
                                <div class="chip chip-media">
                                    <i class="chip-icon bg-primary">
                                        W
                                    </i>
                                    <span class="chip-label">{{ sub_level.event_reg.wisdom_reg }}/{{ sub_level.event_reg.wisdom }}</span>
                                </div>
                                <div class="chip chip-media">
                                    <i class="chip-icon bg-success">
                                        Y
                                    </i>
                                    <span class="chip-label">{{ sub_level.event_reg.youth_reg }}/{{ sub_level.event_reg.youth }}</span>
                                </div>
                                <div class="chip chip-media">
                                    <i class="chip-icon bg-warning">
                                        S
                                    </i>
                                    <span class="chip-label">{{ sub_level.event_reg.student_reg }}/{{ sub_level.event_reg.student }}</span>
                                </div>
                            </td>
                            <td class="text-primary font-dark">
                                <div class="chip chip-media">
                                    <i class="chip-icon bg-dark">
                                        T
                                    </i>
                                    <span class="chip-label">{{ sub_level.event_reg.female_reg }}/{{ sub_level.event_reg.female }}</span>
                                </div>
                                <div class="chip chip-media">
                                    <i class="chip-icon bg-info">
                                        W
                                    </i>
                                    <span class="chip-label">{{ sub_level.event_reg.women_reg }}/{{ sub_level.event_reg.women }}</span>
                                </div>
                                <div class="chip chip-media">
                                    <i class="chip-icon bg-danger">
                                        G
                                    </i>
                                    <span class="chip-label">{{ sub_level.event_reg.girls_reg }}/{{ sub_level.event_reg.girls }}</span>
                                </div>
                            </td>
                            <td class="text-primary font-dark">
                                {{ sub_level.event_reg.total_reg }}/{{ sub_level.event_reg.total }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../../services/user.service";
import $ from 'jquery';
import AlertModel from "../../layouts/AlertModel.vue";
import DialogModel from "../../layouts/DialogModel.vue";
export default {
    name: 'Nerpatham Report',
    components: {
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            user_level: "",
            user_level_id: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            permissions: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            level: [],
            page_title: 'റിപ്പോർട്ട്',
            responseData: {
                event: {
                    permissions:[]
                },
                count_total: []
            }


        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.count_target - this.count_total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.user_level);
            this.user_level_id = parseInt(this.$route.params.id);
            this.getMembersList();
            this.$root.updateParent('Event Registration Report');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getMembersList() {
            this.loading_list = true;
            let data = {
                user_level: this.user_level,
                user_level_id: this.user_level_id,
                event_type: this.$route.params.slug
            }
            UserService.authPostRequest(`get-event-members-summary-report`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.member_lists = response.data.data.list;
                        this.level = response.data.data.level;
                        this.count_target = response.data.data.count_target;
                        this.count_total = response.data.data.count_total;
                        this.level = response.data.data.level;
                        this.target_title = response.data.data.target_title;
                        this.page_title = response.data.data.page_title;
                        this.$root.updateParent(this.responseData.event.name+' Summary');
                        this.loading_list = false;
                        
                        // this.updateChart();
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: auto;
}

.left {
    margin-left: 20px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 0px 16px;
    position: relative;
    z-index: 1;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}
</style>