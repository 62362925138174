<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 custom-title">Meeting Report</h3>
        </div>
    </div>
    <div id="search">
        <div class="form-group">
            <div class="input-wrapper">
                <label class="label" for="pin">Meeting Type</label>
                <select as="select" class="form-control custom-select" v-model="filter_meeting" name="filter_type"
                    id="select4">
                    <option value>---Select---</option>
                    <option v-for="meeting in meeting_list" v-bind:value="meeting.id" v-bind:key="meeting.id">{{
                        meeting.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <div class="input-wrapper">
                        <label class="label" for="pin">Date From</label>
                        <input type="date" class="form-control" name="filter_date_from" v-model="filter_date_from" />
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                    <ErrorMessage name="date_from" class="error-feedback" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <div class="input-wrapper">
                        <label class="label" for="pin">Date To</label>
                        <input type="date" class="form-control" name="filter_date_to" v-model="filter_date_to" />
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                    <ErrorMessage name="date_to" class="error-feedback" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button type="button" class="btn btn-danger btn-sm me-1 mb-1" style="width: 100%;" @click="clearFilter()">
                    <ion-icon name="trash-bin-outline" role="img" class="md hydrated" aria-label="add outline"></ion-icon>
                    Clear
                </button>
            </div>
            <div class="col-6">
                <button type="button" class="btn btn-primary btn-sm me-1 mb-1" style="width: 100%;"
                    @click="getMembersList()">
                    <ion-icon name="search-outline" role="img" class="md hydrated" aria-label="add outline"></ion-icon>
                    Search
                </button>
            </div>
        </div>
    </div>
    <div class="section">
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text text-primary">നിശ്ചയിച്ചത്</div>
                    <div class="value text-danger">{{ count_target }}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title mal-text text-primary">തീരുമാനം ആയത്</div>
                    <div class="value text-success">{{ count_confirmed }}</div>
                </div>
            </div>
            <div class="col-12 pt-1">
                <div class="stat-box">
                    <div class="title mal-text text-primary">കൂടിയത്</div>
                    <div class="value text-success">{{ count_total }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>

    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>
    <div class="modal fade action-sheet" id="nerpathamTargetModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Nerpatham Target</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addNerpathamTarget">
                            <div class="form-group basic">
                                <label class="label" for="Target">Target</label>
                                <div class="form-group boxed">
                                    <Field name="target" type="number" class="form-control" id="target"
                                        v-model="nerpatham_target" placeholder="Enter Target" />
                                </div>
                                <input type="hidden" v-model="sub_level_id">
                                <div class="form-group" v-if="message">
                                    <div v-if="message" class="error-feedback">
                                        {{ message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col" v-if="user_level != 3">നിശ്ചയിച്ചത്/<br>തീരുമാനം ആയത്/<br>നടന്നത്</th>
                            <th scope="col" v-if="user_level == 3">Status</th>
                            <th scope="col">Attendance</th>
                            <!-- <th scope="col">
                                Action
                            </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="sub_level in member_lists" v-bind:key="sub_level">
                            <td v-if="user_level <= 2" class="text-primary">
                                <router-link :to="'/org/' + (user_level + 1) + '/' + sub_level.id + '/meeting-report?meeting_type='+this.filter_meeting+'&&date_from='+this.filter_date_from+'&&date_to='+this.filter_date_to+''">
                                    {{ sub_level.name }}
                                </router-link>
                            </td>
                            <td v-if="user_level == 3" class="text-primary">
                                {{ sub_level.name }}
                            </td>
                            <td class="text-primary font-dark">
                                <span v-html="sub_level.meeting_status"></span>
                                <br v-if="sub_level.meeting_date">
                                <span class="badge badge-primary" v-html="sub_level.meeting_date"></span>
                            </td>
                            <td class="text-primary font-dark text-right">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-primary">WS</i>
                                            <span class="chip-label">{{ sub_level.wing_counts.wisdom }}/{{
                                            sub_level.wing_counts.wisdom_total
                                            }}</span>
                                        </div>
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-success">YT</i>
                                            <span class="chip-label">{{ sub_level.wing_counts.youth }}/{{
                                            sub_level.wing_counts.youth_total
                                            }}</span>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-1">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-danger">ST</i>
                                            <span class="chip-label">{{ sub_level.wing_counts.students }}/{{
                                            sub_level.wing_counts.students_total
                                            }}</span>
                                        </div>
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-pink">WM</i>
                                            <span class="chip-label">{{ sub_level.wing_counts.women }}/{{
                                            sub_level.wing_counts.women_total
                                            }}</span>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-1">
                                        <div class="chip chip-media">
                                            <i class="chip-icon bg-pink-dark">GL</i>
                                            <span class="chip-label">{{ sub_level.wing_counts.girls }}/{{
                                            sub_level.wing_counts.girls_total
                                            }}</span>
                                        </div>
                                        <!-- <div class="chip chip-media">
                                            <i class="chip-icon bg-warning">BL</i>
                                            <span class="chip-label">{{ sub_level.wing_counts.balavedi }}/{{
                                            sub_level.wing_counts.balavedi
                                            }}</span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="chip chip-media mt-1" v-if="sub_level.attendance">
                                    <i class="chip-icon bg-secondary">Total</i>
                                    <span class="chip-label">{{ sub_level.attendance }}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field } from "vee-validate";
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
import moment from 'moment';
export default {
    name: 'Nerpatham Report',
    components: {
        Form,
        Field,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            meeting_list: [
                {
                    'id' : 1,
                    'name' : 'Secretariate'
                },
                {
                    'id' : 2,
                    'name' : 'Executive'
                },
                {
                    'id' : 3,
                    'name' : 'Departmental'
                },
                {
                    'id' : 4,
                    'name' : 'General Body'
                },
                {
                    'id' : 5,
                    'name' : 'Council'
                },
                {
                    'id' : 6,
                    'name' : 'Joint Secretariat'
                },
                {
                    'id' : 7,
                    'name' : 'Wisdom Day'
                },
                {
                    'id' : 8,
                    'name' : 'Special Meetings'
                },
            ],
            filter_meeting: '',
            filter_date_from: '',
            filter_date_to: '',
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            count_total: 0,
            count_target: 0,
            level: [],
            nerpatham_target: '',
            target_title: 'ടാർഗറ്റ്',
            page_title: 'വർക്കിങ് ഫണ്ട് റിപ്പോർട്ട്',
            series: [0, 0],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                colors: ['#64b167', '#db7491'],
                labels: ['Target', 'Total'],
                responsive: [{
                    breakpoint: 100,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            count_state: 0,
            count_unit: 0,
            count_zone: 0,
            count_district: 0,
            count_confirmed: 0.00,

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.count_target - this.count_total;
            if (value <= 0) {
                return '0';
            } else {
                return value.toFixed(2);
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            // this.getMembersList();
            
            
            
            if(this.$route.query.date_from){
                this.filter_date_from = this.$route.query.date_from;
            }else{
                this.filter_date_from = moment(new Date()).format('YYYY-MM-DD');
            }
            if(this.$route.query.date_to){
                this.filter_date_to = this.$route.query.date_to;
            }else{
                this.filter_date_to = moment(new Date()).format('YYYY-MM-DD');
            }
            console.log(this.$route.query.date_to);
            if(this.$route.query.meeting_type){
                this.filter_meeting = this.$route.query.meeting_type;
                this.getMembersList();
            }
            

            this.$root.updateParent('Meeting Report');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        updateChart() {
            this.chartOptions = {
                chart: {
                    type: 'pie'
                },
                colors: ['#64b167', '#db7491'],
                labels: ['ചേർത്തത്', 'ബാക്കി ഉള്ളത്'],
                responsive: [{
                    breakpoint: this.count_target,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            show: false,
                        }
                    }
                }]
            }
            // In the same way, update the series option
            this.series = [parseFloat(this.count_total), parseFloat(this.count_target - this.count_total)]
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            if (admin.member_id) {
                this.member_id = admin.member_id;
                this.disable_member_form = true;
            } else {
                this.disable_member_form = false;
            }
            this.name = admin.name;
            this.mobile = admin.mobile;
            this.subscription_no = admin.subscription_no;
            this.receipt_no = admin.reciept_no;
            this.member_edit = false;
            this.member_list.push(this.member);
            this.designation = admin.designation;
            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.disable_member_form = false;
            this.message = '';
            this.member_id = '';
            this.name = '';
            this.mobile = '';
            this.subscription_no = '';
            this.receipt_no = '';
            this.member_edit = true;
            this.member = '';
            this.editable_id = null;
            this.add_admin = true;
            this.edit_admin = false;
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            console.log(user);
            user.editable_id = this.editable_id;
            user.is_member = this.isMember;
            if (!this.member && this.isMember == 'Y') {
                this.message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക';
            }
            if (this.member) {
                user.member_id = this.member.id;
            }
            user.unit_id = parseInt(this.$route.params.id);
            UserService.addAdmin('store-nerpatham-subscriptions', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delate-nerpatham-subscriptions', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList();
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        getMembersList() {

            let data = {
                user_level: this.user_level,
                user_level_id: parseInt(this.$route.params.id),
                meeting: this.filter_meeting,
                date_from: this.filter_date_from,
                date_to: this.filter_date_to
            }
            if (!this.filter_meeting) {
                this.alert_type = 'Warning';
                this.alert_message = 'മീറ്റിംഗ് ടൈപ്പ് സെലക്ട് ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                return 0;
            } else if (!this.filter_date_from) {
                this.alert_type = 'Warning';
                this.alert_message = 'തീയതി സെലക്ട് ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                return 0;
            } else if (!this.filter_date_to) {
                this.alert_type = 'Warning';
                this.alert_message = 'തീയതി സെലക്ട് ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                return 0;
            }
            this.loading_list = true;

            UserService.authPostRequest(`get-meeting-report`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.meeting_list = response.data.data.meetings;
                        this.member_lists = response.data.data.list;
                        this.level = response.data.data.level;
                        this.count_target = response.data.data.count_target;
                        this.count_total = response.data.data.count_total;
                        this.count_confirmed = response.data.data.count_confirmed;
                        this.loading_list = false;
                        // this.updateChart();
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        showActionModel() {
            this.pin_number = '';
        },
        nerpathamTarget(sub_level = null) {
            this.nerpatham_target = sub_level.nerpatham_target;
            this.sub_level_id = sub_level.id;
            $('#nerpathamTargetModel').modal('show');
        },
        addNerpathamTarget(data) {
            data.level = parseInt(this.$route.params.sub_level) + 1;
            data.level_id = parseInt(this.sub_level_id);
            UserService.addAdmin('set-nerpatham-target', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.getMembersList();
                        $('#nerpathamTargetModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clearFilter(){
            this.filter_meeting = '';
            this.filter_date_from = moment(new Date()).format('YYYY-MM-DD');
            this.filter_date_to = moment(new Date()).format('YYYY-MM-DD');

        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.stat-box .value {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1em;
    color: #27173E;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .total {
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1em;
    font-size: 26px;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px !important;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: auto;
}

.left {
    margin-left: 0px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 0px 10px;
    position: relative;
    z-index: 1;
}
.badge {
    border-radius: 0px !important;
}
.back-color-primary{
    background: #1a418c !important;
}

.back-color-secondary{
    background: #666d79 !important;
}
.back-color-danger{
    background: #de6f56 !important;
}
.back-color-warning{
    background: #b19547 !important;
}
.back-color-red{
    background: #d55454  !important;
}

.back-color-violet{
    background: #0000A3  !important;
}

.back-color-blue1{
    background: #0000D1  !important;
}
.back-color-blue2{
    background: #0000FF  !important;
}
.back-color-blue3{
    background: #2E2EFF  !important;
}
.back-color-blue4{
    background: #5C5CFF  !important;
}
.back-color-blue5{
    background: #8A8AFF  !important;
}
.back-color-blue6{
    background: #8F00FF  !important;
}
</style>