<template>
    <!-- Wallet Card -->
    <div class="section pt-1">
        <div class="wallet-card">
            <router-link :to="'/org/'+$route.params.sub_level+'/'+$route.params.id+'/request'" v-if="$route.params.sub_level > 0">
                <button type="button" class="btn btn-outline-primary me-1 mal-text btn-lg col-12 mb-2">
                    പുതിയ അപേക്ഷ അയക്കാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക.
                    <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon>
                </button>
            </router-link>
            <router-link :to="'/org/'+$route.params.sub_level+'/'+$route.params.id+'/request-list'">
                <button type="button" class="btn btn-outline-primary me-1 mal-text btn-lg col-12 mb-2">
                    അപേക്ഷകൾ കാണാനും എഡിറ്റ്/ അപ്പ്രൂവ് ചെയ്യാനും  ഇവിടെ ക്ലിക്ക് ചെയ്യുക.
                    <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon>
                </button>
            </router-link>
        </div>
    </div>
    
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Admin List',
    components: {
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            user_level: "",
            sub_levels: [],
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.$root.updateParent('Wsidom Guide Requests');
        }
    },
    methods: {
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}



.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}
.btn-lg {
    height: auto;
    padding: 0px 0px;
    font-size: 15px;
}
</style>