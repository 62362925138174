<template>
  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="section mb-2 p-2" v-if="$route.name == 'Conference Registration'" style="margin-top: -50px">
      <div class="card card-slider">
        <img :src="'https://guide.msoftit.com/uploads/image/1675406388.jpeg'" class="card-img-top" alt="image" />
        <div class="card-body">
          <h5 class="card-title mal-text text-primary">കോൺഫറൻസ് ഫണ്ട്</h5>
          <p class="card-text mal-text">
            Account No : 37489072044 <br>Wisdom Islamic Organizatin SBI Palayam Branch, Kozhikode IFCS SBIN007030 <br> UPI
            ID : <a class="text-primary" href="upi://pay?pa=WISDOMISLAMICCLT@SBI&pn=Wisdom Islamic Organization&cu=INR">
              WISDOMISLAMICCLT@SBI </a><br>
          </p>
          <p> GPay/Phonepe വഴി പണം അയക്കാൻ താഴെ ഉള്ള Pay Now ബട്ടൺ ക്ലിക്ക് ചെയ്യുക.<br />
            <a href="upi://pay?pa=WISDOMISLAMICCLT@SBI&pn=Wisdom Islamic Organization&cu=INR">
              <button type="button" class="btn btn-danger btn-sm me-1 mb-1">
                <ion-icon name="card-outline" role="img" class="md hydrated" aria-label="add outline"></ion-icon>
                Pay Now
              </button></a>
          </p>
        </div>
      </div>
    </div>
    <div class="section mb-2 p-2" v-if="$route.name == 'PER'" style="margin-top: -50px">
      <div class="card card-slider">
        <img :src="'https://guide.msoftit.com/uploads/image/1675421514.jpeg'" class="card-img-top" alt="image" />
      </div>
    </div>
    <div class="section mt-0 text-center">
      <h1 class="text-primary title-reg mal-text" v-if="$route.name == 'Conference Registration' || $route.name == 'PER'">
        സമ്മേളനത്തിൽ പങ്കെടുക്കുന്നവർ രജിസ്റ്റർ ചെയ്യുക
      </h1>
      <h1 class="text-primary title-reg" v-else>Register now</h1>
      <h4 class="text-secondary" v-if="$route.name == 'Register'">Create an account</h4>
    </div>
    <div class="section mb-5 p-2">
      <Form @submit="handleRegistration" :validation-schema="schema">
        <div class="card">
          <div class="card-body">
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="name">Name <span class="text-danger">*</span></label>
                <Field type="text" class="form-control" name="name" placeholder="Your Name" v-model="name" />
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
              <ErrorMessage name="name" class="error-feedback" />
            </div>
            <div class="row">
              <div class="col-5">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="gender">Sex <span class="text-danger">*</span></label>
                    <Field as="select" class="form-control custom-select" name="gender" id="gender" v-model="gander">
                      <option value="" selected>Select Sex</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Field>
                  </div>
                  <ErrorMessage name="gender" class="error-feedback" />
                </div>
              </div>
              <div class="col-7">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="age">Age <span class="text-danger">*</span></label>
                    <Field type="number" class="form-control" name="age" placeholder="Your Age" v-model="age" />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                  <ErrorMessage name="age" class="error-feedback" />
                </div>
              </div>
            </div>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="district">District <span class="text-danger">*</span></label>
                <Field as="select" class="form-control custom-select" id="district" name="district" v-model="district">
                  <option value="" :disabled="true" selected>Select District</option>
                  <option v-for="district in district_list" v-bind:key="district.id" v-bind:value="district.id">
                    {{ district.name }}
                  </option>
                </Field>
              </div>
              <ErrorMessage name="district" class="error-feedback" />
            </div>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="age">Postal Pin Code<span class="text-danger">*</span></label>
                <Field type="text" class="form-control" name="pin_code" placeholder="Your Pin Code" v-model="pin_code" />
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
              <ErrorMessage name="pin_code" class="error-feedback" />
            </div>
            <div class="row">
              <div class="col-5">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="code">Code <span class="text-danger">*</span></label>
                    <Field as="select" class="form-control custom-select" id="code" name="code" v-model="code">
                      <option value="" :disabled="true" selected>#</option>
                      <option v-for="code in country_code_list" v-bind:key="code.item_value"
                        v-bind:value="code.item_value">
                        {{ code.name }}
                      </option>
                    </Field>
                  </div>
                  <ErrorMessage name="code" class="error-feedback" />
                </div>
              </div>
              <div class="col-7">
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="mobile">Mobile <span class="text-danger">*</span></label>
                    <Field type="number" class="form-control" id="mobile" name="mobile" placeholder="Your Mobile"
                      v-model="mobile"
                      oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                  <ErrorMessage name="mobile" class="error-feedback" />
                </div>
              </div>
            </div>
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="label" for="pin"><span class="mal-text text-dark">പാസ്‌വേഡ് ആയി സെറ്റ് ചെയ്യാൻ 6 ആക്ക നമ്പർ
                    നൽകുക</span><span class="text-danger">*</span></label>
                <Field type="text" class="form-control" name="pin" placeholder="Pin for Login" v-model="pin"
                  oninput="javascript: if (this.value.length > 6) this.value = this.value.slice(0, 6);" />
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
              <ErrorMessage name="pin" class="error-feedback" />
            </div>
            <div class="custom-control custom-checkbox mt-1 mal-text text-primary text-center">
              <p class="label">
                ലോഗിൻ ചെയ്യാൻ മൊബൈൽ നമ്പറും പിൻ നമ്പറും ഓർത്തു വെക്കുക...
              </p>
            </div>

            <div class="transparent mt-2">
              <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                Register
              </button>
            </div>

            <div class="mal-text text-center forget mt-1">
              <router-link :to="'user-login'">
                <button type="button" class="btn btn-success btn-block btn-lg">
                  <span>വിസ്‌ഡം ഗൈഡ് രജിസ്റ്റർ ചെയ്തിട്ടുണ്ടെങ്കിൽ ലോഗിൻ ചെയ്യാൻ ഇവിടെ ക്ലിക്ക്
                    ചെയ്യുക.</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
  <!-- Terms Modal -->
  <div class="modal fade modalbox" id="termsModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Terms and Conditions</h5>
          <a href="#" data-bs-dismiss="modal">Close</a>
        </div>
        <div class="modal-body">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum, urna
            eget finibus fermentum, velit metus maximus erat, nec sodales elit justo vitae
            sapien. Sed fermentum varius erat, et dictum lorem. Cras pulvinar vestibulum
            purus sed hendrerit. Praesent et auctor dolor. Ut sed ultrices justo. Fusce
            tortor erat, scelerisque sit amet diam rhoncus, cursus dictum lorem. Ut vitae
            arcu egestas, congue nulla at, gravida purus.
          </p>
          <p>
            Donec in justo urna. Fusce pretium quam sed viverra blandit. Vivamus a
            facilisis lectus. Nunc non aliquet nulla. Aenean arcu metus, dictum tincidunt
            lacinia quis, efficitur vitae dui. Integer id nisi sit amet leo rutrum
            placerat in ac tortor. Duis sed fermentum mi, ut vulputate ligula.
          </p>
          <p>
            Vivamus eget sodales elit, cursus scelerisque leo. Suspendisse lorem leo,
            sollicitudin egestas interdum sit amet, sollicitudin tristique ex. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
            himenaeos. Phasellus id ultricies eros. Praesent vulputate interdum dapibus.
            Duis varius faucibus metus, eget sagittis purus consectetur in. Praesent
            fringilla tristique sapien, et maximus tellus dapibus a. Quisque nec magna
            dapibus sapien iaculis consectetur. Fusce in vehicula arcu. Aliquam erat
            volutpat. Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            per inceptos himenaeos.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- * Terms Modal -->
  <!-- * App Capsule -->
  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>

<script>
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import * as yup from "yup";

export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertModel,
    DialogModel,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("ഫിൽ ചെയ്യുക!"),
      code: yup.string().required("സെലക്റ്റ് ചെയ്യുക!"),
      mobile: yup.string().required("ഫിൽ ചെയ്യുക!"),
      district: yup.string().required("സെലക്റ്റ് ചെയ്യുക!"),
      age: yup.string().required("ഫിൽ ചെയ്യുക!"),
      gender: yup.string().required("സെലക്റ്റ് ചെയ്യുക!"),
      pin: yup.string().required("ഫിൽ ചെയ്യുക!"),
      pin_code: yup.string().required("ഫിൽ ചെയ്യുക!"),
    });

    return {
      alert_type: "",
      alert_message: "",
      dialog_message: "",
      dialog_action: "",
      dialog_action_params: [],
      loading: false,
      message: "",
      schema,
      country_code: "91",
      login_with_pin: false,
      mobile_number: "",
      country_code_list: [],
      district_list: [],
      name: "",
      age: "",
      pin_code: "",

      code: "91",
      district: "",
      gander: "",
      mobile: "",
      pin_confirm: "",
      pin: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    this.getComboboxValues("COUNTRY_CODE");
    this.getComboboxValues("DISTRICT_KERALA");
    if (this.$route.name == "Conference Registration" || this.$route.name == "PER") {
      this.$root.updateParent("Wisdom Islamic Conference");
    }
  },
  created() {
    if (this.loggedIn) {
      localStorage.activeMenu = "Home";
      this.$router.push("/home");
    }
  },
  methods: {
    getComboboxValues(code, id = null) {
      var data = {
        code: code,
        type_id: id,
      };
      UserService.getCombobox(data).then(
        (response) => {
          switch (code) {
            case "COUNTRY_CODE":
              this.country_code_list = response.data.data.list.options;
              this.country_code = "91";
              break;
            case "DISTRICT_KERALA":
              this.district_list = response.data.data.list.options;
              break;

            default:
              break;
          }
        },
        (error) => {
          this.loading = false;
          this.messafe =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    handleLogin(user) {
      user.username = user.code + user.mobile;
      this.loading = true;
      this.$store.dispatch("auth/userLogin", user).then(
        () => {
          localStorage.activeMenu = 'Home';
          location.replace('/#/home')
        }
      );
    },
    handleRegistration(user) {
      this.message = "";
      this.loading = true;
      user.username = this.code + this.mobile;
      if (this.$route.name == 'PER' || this.$route.name == 'Conference Registration') {
        user.type = 'per';
      }
      AuthService.register(user).then(
        (response) => {
          if (response.data.status == "success") {
            this.alert_type = "Success";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.loading = false;
            // this.$router.push("user-login");
            setTimeout(function () {
              this.handleLogin(user);
            }.bind(this), 1500);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.alert_type = "Warning";
            this.alert_message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.$refs.alert_model.openModel();
            this.handleLogin(user);
          } else {
            this.alert_type = "Error";
            this.alert_message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.$refs.alert_model.openModel();
          }
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.error-feedback {
  color: red;
}

#username {
  margin-top: 0px;
}

.label {
  margin-left: -10px;
  margin-bottom: 0px;
}

.forget {
  font-size: 12px !important;
  text-decoration: underline;
}

.title-reg {
  font-size: 18px;
}
</style>
