<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text custom-title">{{report_header}}</h3>
        </div>
    </div>
    <div class="section mt-2 mb-2"
        v-if="!loading_list && (unit.wisdom_count >= 5 || unit.youth_count >= 5 || unit.students_count >= 5 || unit.girls_count >= 5 || unit.women_count >= 5 || unit.balavedi_count >= 5)">
        <Form @submit="storeBasicInfo" :validation-schema="schema">
            <div class="section-title mal-text text-center custom-title">ശാഖാ ഇലക്ഷൻ ജനറൽ ബോഡി യോഗം</div>
            <div class="card">
                <div class="card-body">
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന തിയ്യതി</label>
                            <Field type="date" class="form-control" name="date" id="text4b" v-model="date" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                            <ErrorMessage name="date" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന സമയം</label>
                            <!-- <Field type="time" class="form-control" name="time" :step="step = 600" id="text4b" v-model="time" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i> -->
                            <Field as="select" class="form-control custom-select" v-model="time" name="time"
                                id="select4">
                                <option v-for="item in time_list" :value="item.value" v-bind:key="item.value">
                                    {{ item.text }}</option>
                            </Field>
                            <ErrorMessage name="time" class="error-feedback" />
                        </div>
                    </div>

                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന സ്ഥലം</label>
                            <Field type="text" class="form-control" name="place" id="text4b" v-model="place" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                            <ErrorMessage name="place" class="error-feedback" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-2">
                <div class="card-body">
                    <div class="mb-05 custom-title">
                        പ്രിസൈഡിങ് ഓഫീസറുടെ പേര്
                    </div>
                    <div class="form-group boxed row" v-if="unit.wisdom_count >= 5">
                        <div class="input-wrapper col-10">
                            <label class="label" for="text4b">ഓർഗനൈസേഷൻ</label>
                            <Field type="text" class="form-control" name="wisdom" id="text4b"
                                v-if="wisdom && !wisdom_edit" :readonly="true" v-model="wisdom.text" />
                            <!-- <vue-bootstrap-typeahead v-model="wisdom" :data="wisdom_list" @hit="wisdom = $event"
                                @input="getTextList($event,1)" :serializer="s => s.text" ref="typeahead"
                                placeholder="സെർച്ച് ചെയ്യുക..." v-if="wisdom_edit" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i> -->
                            <v-select v-if="wisdom_edit" label="name" :filterable="false" v-model="wisdom"
                                :value="wisdom" :options="wisdom_list" @input="getTextList($event.target.value,1)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="wisdom">
                                    <div class="d-center">
                                        {{ wisdom.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="wisdom" class="error-feedback" />
                        </div>
                        <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('wisdom')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button>
                        <div class="input-info" v-if="wisdom">Mobile : {{wisdom.country_code}}{{wisdom.mobile}} | Unit : {{
                        wisdom.text?wisdom.text.split('-')[1]:'' }}</div>
                    </div>
                    <div class="form-group boxed row" v-if="unit.youth_count >= 5">
                        <div class="input-wrapper col-10">
                            <label class="label" for="text4b">യൂത്ത്</label>
                            <Field type="text" class="form-control" name="youth" id="text4b" v-if="youth && !youth_edit"
                                :readonly="true" v-model="youth.text" />
                            <!-- <vue-bootstrap-typeahead v-model="youth" :data="youth_list" @hit="youth = $event"
                                @input="getTextList($event,2)" :serializer="s => s.text" ref="typeahead"
                                placeholder="സെർച്ച് ചെയ്യുക..." v-if="youth_edit" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i> -->
                            <v-select v-if="youth_edit" label="name" :filterable="false" v-model="youth"
                                :value="youth" :options="youth_list" @input="getTextList($event.target.value,2)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="youth">
                                    <div class="d-center">
                                        {{ youth.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="youth" class="error-feedback" />
                        </div>
                        <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('youth')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button>
                        <div class="input-info" v-if="youth">Mobile : {{youth.country_code}} {{youth.mobile}} | Unit : {{
                        youth.text?youth.text.split('-')[1]:'' }}</div>
                    </div>
                    <div class="form-group boxed row" v-if="unit.students_count >= 5">
                        <div class="input-wrapper col-10">
                            <label class="label" for="text4b">സ്റ്റുഡൻസ്</label>
                            <Field type="text" class="form-control" name="students" id="text4b"
                                v-if="students && !students_edit" :readonly="true" v-model="students.text" />
                            <!-- <vue-bootstrap-typeahead v-model="students" :data="students_list" @hit="students = $event"
                                @input="getTextList($event,3)" :serializer="s => s.text" ref="typeahead"
                                placeholder="സെർച്ച് ചെയ്യുക..." v-if="students_edit" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i> -->
                            <v-select v-if="students_edit" label="name" :filterable="false" v-model="students"
                                :value="students" :options="students_list" @input="getTextList($event.target.value,3)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="students">
                                    <div class="d-center">
                                        {{ students.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="students" class="error-feedback" />
                        </div>
                        <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('students')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button>
                        <div class="input-info" v-if="students">Mobile :{{students.country_code}} {{students.mobile}} | Unit : {{
                        students.text?students.text.split('-')[1]:'' }}</div>
                    </div>
                    <div class="form-group boxed row" v-if="unit.women_count >= 5">
                        <div class="input-wrapper col-10">
                            <label class="label" for="text4b">വിമൺ</label>
                            <Field type="text" class="form-control" name="women" id="text4b" v-if="women && !women_edit"
                                :readonly="true" v-model="women.text" />
                            <!-- <vue-bootstrap-typeahead v-model="women" :data="women_list" @hit="women = $event"
                                @input="getTextList($event,4)" :serializer="s => s.text" ref="typeahead"
                                placeholder="സെർച്ച് ചെയ്യുക..." v-if="women_edit" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i> -->
                            <v-select v-if="women_edit" label="name" :filterable="false" v-model="women"
                                :value="women" :options="women_list" @input="getTextList($event.target.value,4)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="women">
                                    <div class="d-center">
                                        {{ women.text }}
                                    </div>
                                </template>
                                
                            </v-select>
                            <ErrorMessage name="women" class="error-feedback" />
                        </div>
                        <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('women')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button>
                        <div class="input-info" v-if="women">Mobile :{{women.country_code}}  {{women.mobile}} | Unit : {{
                        women.text?women.text.split('-')[1]:'' }}</div>
                    </div>

                    <div class="form-group boxed row" v-if="unit.girls_count >= 5">
                        <div class="input-wrapper col-10">
                            <label class="label" for="text4b">ഗേൾസ്</label>
                            <Field type="text" class="form-control" name="girls" id="text4b" v-if="girls && !girls_edit"
                                :readonly="true" v-model="girls.text" />
                            <!-- <vue-bootstrap-typeahead v-model="girls" :data="girls_list" @hit="girls = $event"
                                @input="getTextList($event,6)" :serializer="s => s.text" ref="typeahead"
                                placeholder="സെർച്ച് ചെയ്യുക..." v-if="girls_edit" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i> -->
                            <v-select v-if="girls_edit" label="name" :filterable="false" v-model="girls"
                                :value="girls" :options="girls_list" @input="getTextList($event.target.value,6)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="girls">
                                    <div class="d-center">
                                        {{ girls.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="girls" class="error-feedback" />
                        </div>
                        <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('girls')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button>
                        <div class="input-info" v-if="girls">Mobile :{{girls.country_code}} {{girls.mobile}} | Unit : {{
                        girls.text?girls.text.split('-')[1]:'' }}</div>
                    </div>
                </div>
            </div>
            <div class="section-title mal-text text-center custom-title" v-if="unit.balavedi_count >= 5">ശാഖാ ബാലവേദി
                ജനറൽ ബോഡി യോഗം</div>
            <div class="card" v-if="unit.balavedi_count >= 5">
                <div class="card-body">
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന തിയ്യതി</label>
                            <Field type="date" class="form-control" name="b_date" id="text4b" v-model="b_date" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                            <ErrorMessage name="b_date" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന സമയം</label>
                            <!-- <Field type="time" class="form-control" name="b_time" step="600" id="text4b"
                                v-model="b_time" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i> -->
                            <Field as="select" class="form-control custom-select" v-model="b_time" name="b_time"
                                id="select4">
                                <option v-for="item in time_list" :value="item.value" v-bind:key="item.value">
                                    {{ item.text }}</option>
                            </Field>
                            <ErrorMessage name="b_time" class="error-feedback" />
                        </div>
                    </div>

                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="text4b">നടക്കുന്ന സ്ഥലം</label>
                            <Field type="text" class="form-control" name="b_place" id="text4b" v-model="b_place" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                            <ErrorMessage name="b_place" class="error-feedback" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-2" v-if="unit.balavedi_count >= 5">
                <div class="card-body">
                    <div class="mb-05 custom-title">
                        ബാലവേദി പ്രെസിഡിങ് ഓഫീസറുടെ പേര്
                    </div>
                    <div class="form-group boxed row">
                        <div class="input-wrapper col-10">
                            <label class="label" for="text4b">സ്റ്റുഡൻസ്</label>
                            <Field type="text" class="form-control" name="b_students" id="text4b"
                                v-if="b_students && !b_students_edit" :readonly="true" v-model="b_students.text" />
                            <!-- <vue-bootstrap-typeahead v-model="students" :data="b_students_list"
                                @hit="b_students = $event" @input="getTextList($event,3,true)" :serializer="s => s.text"
                                ref="typeahead" placeholder="സെർച്ച് ചെയ്യുക..." v-if="b_students_edit" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i> -->
                            <v-select v-if="b_students_edit" label="name" :filterable="false" v-model="b_students"
                                :value="b_students" :options="b_students_list" @input="getTextList($event.target.value,3,true)">
                                <template>
                                    type to search...
                                </template>
                                <template v-slot:option="b_students">
                                    <div class="d-center">
                                        {{ b_students.text }}
                                    </div>
                                </template>
                            </v-select>
                            <ErrorMessage name="b_students" class="error-feedback" />
                        </div>
                        <button type="button" class="btn btn-icon btn-primary me-1 col-2 edit-btn"
                            @click="editBtn('b_students')">
                            <ion-icon name="create-outline"></ion-icon>
                        </button>
                        <div class="input-info" v-if="b_students">Mobile :{{b_students.country_code}} {{b_students.mobile}} | Unit : {{
                        b_students.text?b_students.text.split('-')[1]:'' }}</div>
                    </div>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="transparent pt-3">
                        <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import $ from 'jquery';
import UserService from "../../services/user.service";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
    name: 'Reports',
    components: {
        Form,
        Field,
        ErrorMessage,
        vSelect
    },
    data() {
        const schema = yup.object().shape({
            date: yup.string().required("തിയതി സെലക്ട് ചെയ്യുക!"),
            time: yup.string().required("സമയം സെലക്ട് ചെയ്യുക!"),
            // wisdom: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
            // students: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
            // youth: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
            // women: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
            // girls: yup.string().required("ഒരു മെമ്പറെ സെലക്ട് ചെയ്യുക!"),
        });

        return {
            loading: false,
            message: "",
            schema,
            loading_list: false,
            disable_all: false,
            date: "",
            time: "",
            place: "",
            b_date: "",
            b_time: "",
            b_place: "",
            wisdom_list: [],
            youth_list: [],
            women_list: [],
            students_list: [],
            b_students_list: [],
            girls_list: [],
            wisdom: '',
            youth: '',
            girls: '',
            women: '',
            students: '',
            b_students: '',
            sub_levels: [],
            wisdom_edit: true,
            youth_edit: true,
            students_edit: true,
            b_students_edit: true,
            women_edit: true,
            girls_edit: true,
            time_list: [],
            unit: []

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getElectionSchedule();
            if (this.user_level == this.currentUser.user_level) {
                this.editable = false;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('Reports');
        }
        this.populate();
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        populate() {
            let hours, minutes, ampm;
            for (var i = 240; i <= 1320; i += 30) {
                hours = Math.floor(i / 60);
                minutes = i % 60;
                if (minutes < 10) {
                    minutes = '0' + minutes;
                }
                ampm = hours % 24 < 12 ? 'AM' : 'PM';
                hours = hours % 12;
                if (hours === 0) {
                    hours = 12;
                }

                this.time_list.push({
                    value: hours + ':' + minutes + ' ' + ampm,
                    text: hours + ':' + minutes + ' ' + ampm,
                });
            }
            console.log(this.time_list);
        },
        editBtn(type) {
            if (type == 'wisdom') {
                this.wisdom_edit = true;
            } else if (type == 'youth') {
                this.youth_edit = true;
            }
            else if (type == 'women') {
                this.women_edit = true;
            } else if (type == 'students') {
                this.students_edit = true;
            } else if (type == 'b_students') {
                this.b_students_edit = true;
            } else if (type == 'girls') {
                this.girls_edit = true;
            }
        },
        getTextList(event, type, b) {
            let data = {
                q: event,
                type: type,
                unit_id: this.$route.params.id
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        if (type == 1) {
                            this.wisdom_list = response.data.data.list;
                        }
                        if (type == 2) {
                            this.youth_list = response.data.data.list;
                        }
                        if (type == 4) {
                            this.women_list = response.data.data.list;
                        }
                        if (type == 6) {
                            this.girls_list = response.data.data.list;
                        }
                        if (type == 3) {
                            if (b) {
                                this.b_students_list = response.data.data.list;
                            } else {
                                this.students_list = response.data.data.list;
                            }

                        }

                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getElectionSchedule() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level + 1,
                unit_id: parseInt(this.$route.params.id),
                type: 'unit_level'
            }
            UserService.authPostRequest('get-election-schedule', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.unit = response.data.data.unit;
                        if (this.sub_levels) {
                            this.wisdom_edit = false;
                            this.youth_edit = false;
                            this.students_edit = false;
                            this.b_students_edit = false;
                            this.women_edit = false;
                            this.girls_edit = false;

                            this.date = this.sub_levels.election_date;
                            this.time = this.sub_levels.election_time;
                            this.place = this.sub_levels.election_location;

                            this.b_date = this.sub_levels.balavedi_election_date;
                            this.b_time = this.sub_levels.balavedi_election_time;
                            this.b_place = this.sub_levels.balavedi_election_location;

                            this.wisdom = {
                                value: this.sub_levels.wisdom_preside_member_id,
                                text: this.sub_levels.wisdom_preside_officer,
                                mobile: this.sub_levels.wisdom_preside_mobile
                            }
                            this.wisdom_list.push(this.wisdom);

                            this.youth = {
                                value: this.sub_levels.youth_preside_member_id,
                                text: this.sub_levels.youth_preside_officer,
                                mobile: this.sub_levels.youth_preside_mobile
                            }
                            this.youth_list.push(this.youth);

                            this.women = {
                                value: this.sub_levels.women_preside_member_id,
                                text: this.sub_levels.women_preside_officer,
                                mobile: this.sub_levels.women_preside_mobile
                            }
                            this.women_list.push(this.women);

                            this.girls = {
                                value: this.sub_levels.girls_preside_member_id,
                                text: this.sub_levels.girls_preside_officer,
                                mobile: this.sub_levels.girls_preside_mobile
                            }
                            this.girls_list.push(this.girls);

                            this.students = {
                                value: this.sub_levels.students_preside_member_id,
                                text: this.sub_levels.students_preside_officer,
                                mobile: this.sub_levels.students_preside_mobile
                            }
                            this.students_list.push(this.students);

                            this.b_students = {
                                value: this.sub_levels.balavedi_preside_member_id,
                                text: this.sub_levels.balavedi_preside_officer,
                                mobile: this.sub_levels.balavedi_preside_mobile
                            }
                            this.b_students_list.push(this.b_students);
                        }


                        this.unit = response.data.data.unit;
                        this.report_header = this.unit.name_mal + ' ശാഖ';
                        this.$root.updateParent('Unit Election Schedule');
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        storeBasicInfo(data) {
            data.id = this.$route.params.id;
            data.user_level = this.$route.params.sub_level;
            data.user_id = this.currentUser.id;

            data.wisdom = this.wisdom;
            data.youth = this.youth;
            data.students = this.students;
            data.b_students = this.b_students;
            data.women = this.women;
            data.girls = this.girls;

            this.loading = true;
            UserService.authPostRequest('store-election-schedule', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.$swal('സേവ് ചെയ്തു', '', 'seccess');
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red !important;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 5px;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.font-dark {
    font-weight: 500;
}

.total-size {
    font-size: 18px;
}

.report-btn-success {
    width: 100%;
}

.icon-size20 {
    font-size: 20px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #1a418c;
    opacity: 100%;
}

.btn-accept-reject {
    height: 50px;
}

.form-control {
    /* height: 28px !important; */
}

.edit-btn {
    margin-top: 24px;
}
.d-center {
    display: flex;
    align-items: center;
}

.selected img {
    width: auto;
    max-height: 23px;
    margin-right: 0.5rem;
}

.v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
    border-bottom: none;
}

.v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
}

.v-select .dropdown-menu .active>a {
    color: #fff;
}
</style>