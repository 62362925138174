<template>
    <div class="section text-center mt-2">
        <h3 class="text-primary">Loading...</h3>
    </div>
    <div class="section text-center">
        <div class="spinner-border text-primary" role="status">
        </div>
    </div>
    <!-- <div class="section mt-2 text-center">
        <div class="card">
            <div class="card-body pt-0 pb-0">
                <iframe width="800" height="230" :src="course_link"></iframe>
            </div>
        </div>
    </div> -->
</template>
<script>
import UserService from "../../services/user.service";
import CryptoJS from 'crypto-js';
export default {
    data() {
        return {
            list: [],
            profile: [],
            course_link:'',
        };
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/user-login');
        }
        this.getProfile();
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        getProfile() {
            var data = {
                user_id: this.currentUser.id
            }
            UserService.authPostRequest('get-my-profile', data).then(
                (response) => {
                    this.profile = response.data.data.list;
                    this.loading_list = false;
                    localStorage.setItem("my_profile", JSON.stringify(this.profile));
                    this.getCourses();
                },
            );
        },
        getCourses() {
            this.profile = JSON.parse(localStorage.getItem("my_profile"));
            // this.course_link = 'https://courses.wisdomislam.org/guide?name='+this.currentUser.name+'age='+this.profile.age+'&mobile_number='+this.currentUser.phone+'&gender='+this.profile.gender+'&district='+this.profile.district+'&zone='+this.profile.zone+'&token='+CryptoJS.SHA256(this.currentUser.phone + '9cea938b603783b288c818b9e2533920c234d2e7083f692005d03762feb29166').toString();
            location.replace('https://courses.wisdomislam.org/guide?name='+this.currentUser.name+'&age='+this.profile.age+'&mobile_number='+this.currentUser.phone+'&gender='+this.profile.gender+'&district='+this.profile.district+'&zone='+this.profile.zone+'&token='+CryptoJS.SHA256(this.currentUser.phone + '9cea938b603783b288c818b9e2533920c234d2e7083f692005d03762feb29166').toString());
        },
    },
};
</script>
<style scoped>
.accordion-button {
    font-weight: 700 !important;
}

.imaged {
    width: 60px !important;
}

.btn-primary {
    width: 100%;
}

.icon-1 {
    color: #1a418c !important;
    font-size: 40px;
}
</style>