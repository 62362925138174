<template>
  <div class="section text-center mt-2" v-if="loading">
    <div class="spinner-border text-secondary" role="status"></div>
  </div>
  <div class="home" v-if="!loading">
    <div class="section wallet-card-section" v-if="banners.length">
      <!-- <carousel :items-to-show="1">
        <slide v-for="banner in banners" :key="banner" autoplay="1500">
          <div class="card card-slider">
            <img :src="banner.image" class="card-img-top" alt="image">
            <div class="card-body">
            <h5 class="card-title mal-text">{{ banner.title }}
            </h5>
            <p class="card-text mal-text" style="text-align: center !important;" v-html="banner.content">
            </p>
          </div>
          </div>
        </slide>
        <template #addons>
          <pagination />
        </template>
      </carousel> -->
      <Flicking :options="{ renderOnlyVisible: true, panelsPerView: 1, align: 'prev', circular: true }" :plugins="pluginsBanner">
        <div class="card-panel mt-2" v-for="banner in banners" :key="banner">
          <div class="card card-slider">
            <img :src="banner.image" class="card-img-top" alt="image">
            <!-- <div class="card-body">
              <h5 class="card-title mal-text">{{ banner.title }}
              </h5>
              <p class="card-text mal-text" v-html="banner.content">
              </p>
            </div> -->
          </div>
        </div>
      </Flicking>
    </div>
    <div class="section pb-2 mt-1" v-if="data.important_links.length">
      <div class="transactions">
        <a v-for="important_links in data.important_links" v-bind:key="important_links" :href="important_links.link"
          class="item">
          <div class="detail">
            <img :src="important_links.image" alt="img" class="image-block imaged w48">
            <div>
              <strong class="mal-text">{{ important_links.title }}</strong>
              <p class="mal-text">{{ important_links.desc }}</p>
            </div>
          </div>
          <div class="right">
            <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
          </div>
        </a>
      </div>
    </div>

    <div class="section mt-1 pb-2">
      <div class="card">
        <div class="card-body">
          <div class="p-1">
            <div class="text-center">
              <h2 class="text-primary">About Us</h2>
              <p class="card-text text-primary mal-text">
                {{ data.about_us }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section pb-2" v-if="data.forms.length">
      <div class="section-heading padding">
        <h2 class="title">Registration Links</h2>
        <!-- <a href="javascript:void(0)" class="link">View All</a> -->
      </div>
      <div class="wrapper">
        <Flicking :options="{ renderOnlyVisible: true, align: 'prev', panelsPerView: data.reg_links_per_page?data.reg_links_per_page:2, circular: true }">
          <div class="bill-box" v-for="reg_model_form in data.forms" v-bind:key="reg_model_form">
            <div class="img-wrapper" v-bind:class="reg_model_form.button_icon_color"
              v-if="!reg_model_form.button_image">
              <ion-icon :name="reg_model_form.button_icon"></ion-icon>
            </div>
            <div class="img-wrapper" v-if="reg_model_form.button_image">
              <img :src="reg_model_form.button_image" alt="img" class="image-block imaged w48">
            </div>
            <div class="price" v-bind:class="reg_model_form.form_heading_color" v-html="reg_model_form.form_heading">
            </div>
            <p v-bind:class="reg_model_form.title_color">{{ reg_model_form.title }}</p>
            <router-link :to="'register'">
              <a href="javascript:void(0)" class="btn btn-block btn-sm" v-bind:class="reg_model_form.button_color">{{
                reg_model_form.button_text
              }}</a>
            </router-link>
            <!-- <a href="javascript:void(0)" @click="openFormModel(reg_model_form)" class="btn btn-block btn-sm"
              v-bind:class="reg_model_form.button_color">{{ reg_model_form.button_text }}</a> -->
          </div>
        </Flicking>
      </div>
    </div>
    <!-- <div class="section pt-2" v-if="data.forms.length">
      <div class="wrapper">
        <Flicking :options="{ renderOnlyVisible: true, align: 'prev', panelsPerView: 1 }">
          <div class="card card-with-icon" v-for="reg_model_form in data.forms" v-bind:key="reg_model_form">
            <div class="card-body pt-3 pb-3 text-center">
              <div class="card-icon mb-2" v-bind:class="reg_model_form.button_icon_color" v-if="!reg_model_form.image">
                <ion-icon :name="reg_model_form.button_icon"></ion-icon>
              </div>
              <div class="avatar-section" v-if="reg_model_form.image">
                <a href="javascript:void(0)">
                  <img :src="reg_model_form.image" class="imaged w100 rounded">
                </a>
              </div>
              <h3 class="card-titlde mb-1">{{ reg_model_form.form_heading }}</h3>
              <p class="mal-text">{{ reg_model_form.title }}</p>
              <div class="row">
                <div class="col">
                  <router-link :to="'user-login'">
                    <a href="javascript:void(0)" class="btn btn-primary">
                      <ion-icon name="person-add-outline"></ion-icon>
                      {{ reg_model_form.button_text }}
                    </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </Flicking>
      </div>
    </div> -->
    <div class="section pt-2">
      <div class="section-heading padding">
        <h2 class="title">Lastest News</h2>
        <a href="javascript:void(0)" class="link">View All</a>
      </div>
      <div class="wrapper">
        <Flicking :options="{ renderOnlyVisible: true, align: 'prev', panelsPerView: 1, circular: true }" :plugins="pluginsNews">
          <div class="blog-card full-width" v-for="news in data.news" :key="news">
            <img :src="news.image" alt="image" class="imaged w-100 h-150">
            <div class="text">
              <router-link :to="'news/' + news.slug">
                <h5 class="title mal-text">{{ truncateText(news.title, 60) }}</h5>
                <a href="javascript:void(0)" class="link">Read More</a>
              </router-link>
            </div>
          </div>
        </Flicking>
      </div>
    </div>
    <div class="section pt-2">
      <div class="section-heading padding">
        <h2 class="title">Apps & Links</h2>
        <a href="javascript:void(0)" class="link">View All</a>
      </div>
      <div class="wrapper">
        <Flicking :options="{ renderOnlyVisible: true, align: 'prev', panelsPerView: 2,circular: true }" :plugins="pluginsApps">
          <div class="bill-box" v-for="app in data.apps" v-bind:key="app">
            <div class="img-wrapper">
              <img :src="app.image" alt="img" class="image-block imaged w48">
            </div>
            <div class="price">{{ app.title }}</div>
            <p>{{ app.desc }}</p>
            <a :href="app.link" target="_blank" class="btn btn-primary btn-block btn-sm">{{ app.btn_text }}</a>
          </div>

        </Flicking>
      </div>
    </div>

    <div class="section mt-2 mb-0">
      <div class="card">
        <div class="card-body">
          <div class="p-1">
            <div class="text-center">
              <h2 class="text-primary">Social Profiles</h2>

              <a v-for="social in data.social_links" v-bind:key="social" :href="social.link" target="_blank"
                class="btn btn-icon me-05" v-bind:class="'btn-' + social.name">
                <ion-icon :name="'logo-' + social.name"></ion-icon>
              </a>

              <a href="https://www.youtube.com/@WisdomGlobalTV" target="_blank" class="btn btn-youtube btn-icon me-05" >
                <ion-icon name="logo-youtube"></ion-icon>
              </a>

              <!-- <a href="#" class="btn btn-twitter btn-icon me-05" >
                <ion-icon name="logo-twitter"></ion-icon>
              </a>

              <a href="#" class="btn btn-linkedin btn-icon me-05">
                <ion-icon name="logo-linkedin"></ion-icon>
              </a>

              <a href="#" class="btn btn-whatsapp btn-icon me-05">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>

              <a href="#" class="btn btn-instagram btn-icon me-05">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>

              <a href="#" class="btn btn-twitch btn-icon me-05">
                <ion-icon name="logo-twitch"></ion-icon>
              </a> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>
<script>
import UserService from "../services/user.service";
import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
// import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { AutoPlay } from "@egjs/flicking-plugins";
export default {
  data() {
    const pluginsBanner = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
    const pluginsApps = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
    const pluginsNews = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
    return {
      pluginsBanner,
      pluginsApps,
      pluginsNews,
      loading: false,
      banners: [],
      permissions: [],
      alert_type: '',
      alert_message: '',
      dialog_message: '',
      dialog_action: '',
      dialog_action_params: [],
      carousel_: '',
      data: {
        forms: [],
        important_links:[]
      },
      responseData: []
    };
  },
  components: {
    AlertModel,
    DialogModel,
    // Carousel,
    // Slide,
    // Pagination,
  },
  mounted() {
    this.getHomeContents();
    if (this.currentUser) {
      this.$router.push('/home');
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    getHomeContents() {
      this.loading = true;
      var data = {}
      UserService.GetRequest('get-home-contents', data).then(
        (response) => {
          if (response.data.status == 'success') {
            this.loading = false;
            this.data = response.data.data;
            this.banners = response.data.data.banners;
            this.permissions = response.data.data.permissions;
          } else if (response.data.status == 'warning') {
            this.loading = true;
          } else {
            this.loading = true;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    truncateText(text, limit) {
      if (typeof text !== 'string') return '';
      if (text.length > limit) {
        return text.slice(0, limit) + (text.length > limit ? '...' : '');
      } else {
        return text;
      }
    },
  },

};
</script>
<style scoped>
.carousel__slide {
  width: 100% !important;
}

.card-slider {
  width: 100% !important;
}

.card .card-img-top {
  border-radius: 10px 10px 10px 10px;
}

.blog-card,
.bill-box {
  width: 50%;
  margin-left: 20px;
}

.full-width {
  width: 100% !important;
}

.slider-body {
  padding: 15px 16px 0px !important;
}

.full {
  padding-left: 20px !important;
}

.section-heading.padding {
  padding-left: 0px;
  padding-right: 0px;
}

.card-title {
  font-weight: 600 !important;
}
.card-text {
  text-align: justify !important;
}

.blog-card .title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  height: 50px;
  overflow: hidden;
}
.blog-card .text {
  padding: 5px 5px;
}

.bill-box p {
  font-size: 12px;
  min-height: 40px;
}

.h-150 {
  height: 180px !important;
}

.bill-box .price {
  font-size: 20px;
  font-weight: 700;
  color: #27173E;
  letter-spacing: -0.02em;
  line-height: 1em;
  margin-bottom: 12px;
  min-height: 40px;
}
</style>