<template>
    <div class="section text-center mt-2 mb-2" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list">
        <div class="section-title mal-text">E. മറ്റു കഴിവുകൾ / അഭിരുചികൾ</div>
        <div class="card">
            <div class="card-body">
                <Form @submit="storeBasicInfo" :validation-schema="schema">
                    <div class="form-group boxed" v-if="this.profile.wing != 5">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">സലഫി/ ഇസ്ലാഹി സംഘടനാ രംഗത്ത് എത്രകാലമായി
                                പ്രവർത്തിക്കുന്നു?</label>
                            <Field :disabled="disable_all" name="islahi_year" type="text" class="form-control" id="islahi_year" placeholder=""
                                v-model="islahi_year" />
                        </div>
                        <ErrorMessage name="islahi_year" class="error-feedback" />
                    </div>
                    <div class="form-group boxed" v-if="this.profile.wing != 5">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">മറ്റു സാമൂഹ്യ രാഷ്ട്രീയ സംഘടനകളിൽ
                                പ്രവർത്തിക്കുന്നുണ്ടോ?ഉണ്ടെങ്കിൽ വിവരങ്ങൾ?</label>
                            <Field :disabled="disable_all" name="other_org" type="text" class="form-control" id="other_org" placeholder=""
                                v-model="other_org" />
                        </div>
                    </div>

                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b" v-if="this.profile.wing != 5">താങ്കൾക്ക്
                                പ്രവർത്തിക്കാൻ കഴിയുന്ന മേഖലകൾ
                                (ചുരുങ്ങിയത് മൂന്ന് എണ്ണം തിരഞ്ഞെടുക്കുക)</label>
                            <label class="label mal-text" for="text4b" v-if="this.profile.wing == 5">നിങ്ങൾക്ക് ചെയ്യാൻ
                                ഇഷ്ടമുള്ള, കൂടുതൽ കഴിവുള്ള കാര്യങ്ങൾ തിരഞ്ഞെടുക്കൂ</label>
                            <div class="row">
                                <div class="form-check mb-1 col-6" v-for="(item, index) in contribute_list"
                                    :value="item.id" v-bind:key="item.id" :set="id = 'customCheckb' + (index + 1)">
                                    <input :disabled="disable_all" type="checkbox" class="form-check-input" v-bind:id="id"
                                        v-bind:value="item.id" @change="getChildList('contribute', item.name)"
                                        v-model="contribute">
                                    <label class="form-check-label" v-bind:for="id">{{ item.name_mal }}</label>
                                </div>
                                <ErrorMessage name="contribute" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div v-if="contribute_text">
                        <!-- <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="text4b">1</label>
                                <Field name="contribute_1" type="text" class="form-control" id="contribute_1"
                                    placeholder="" />
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="text4b">2</label>
                                <Field name="contribute_2" type="text" class="form-control" id="contribute_2"
                                    placeholder="" />
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="text4b">3</label>
                                <Field name="contribute_3" type="text" class="form-control" id="contribute_3"
                                    placeholder="" />
                            </div>
                        </div> -->
                    </div>
                    <div class="form-group boxed" v-if="social_media_active">
                        <div class="input-wrapper">
                            <label class="label mal-text" for="text4b">സോഷ്യൽ മീഡിയ</label>
                            <div class="row">
                                <div class="form-check mb-1 col-6" v-for="(item, index) in social_media_list"
                                    :value="item.id" v-bind:key="item.id" :set="id = 'customCheckb3' + (index + 1)">
                                    <input :disabled="disable_all" type="checkbox" class="form-check-input" v-bind:id="id"
                                        v-bind:value="item.id" v-model="social_media">
                                    <label class="form-check-label" v-bind:for="id">{{ item.name_mal }}</label>
                                </div>
                                <ErrorMessage name="social_media" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div class="transparent pt-3" v-if="currentUser.id == profile.user_id">
                        <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Save & Next</span>
                        </button>
                    </div>
                    <div class="transparent pt-3" v-if="currentUser.id != profile.user_id">
                        <button type="button" @click="goToNextPage" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Next</span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
export default {
    name: 'Basic Info',
    components: {
        Form,
        ErrorMessage,
        Field
    },
    data() {
        const schema = yup.object().shape({
            // islahi_year: yup.string().required("ടൈപ്പ്  ചെയ്യുക!")
        });

        return {
            loading: false,
            loading_list: false,
            message: "",
            contribute: [],
            social_media: [],
            islahi_year: '',
            schema,

            contribute_list: [],
            social_media_list: [],

            disable_all:false,
            contribute_text: false,
            social_media_active: false,
            profile: []
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.loading_list = true;
        this.getProfile();
        this.$root.updateParent("ബയോ ഡാറ്റ");
    },
    methods: {
        getProfile() {
            var data = {
                id: this.$route.params.id
            }
            UserService.authPostRequest('get-profile', data).then(
                (response) => {
                    this.profile = response.data.data.list;
                    if(this.profile.user_id != this.currentUser.id){
                        this.disable_all = true;
                        this.$root.updateParent(this.profile.name+" യുടെ ബയോ ഡാറ്റ");
                    }
                    this.contribute = this.profile.contributes ? JSON.parse(this.profile.contributes) : [];
                    this.social_media = this.profile.contribute_social_medias ? JSON.parse(this.profile.contribute_social_medias) : [];
                    if (this.social_media.length > 0) {
                        this.getComboboxValues('SOCIAL_MEDIA');
                        this.social_media_active = true;
                    }
                    if (this.profile.wing == 5) {
                        this.getComboboxValues('BALAVEDI_CONTRIBUTES');
                    } else {
                        this.getComboboxValues('AREAS_CONTRIBUTE');
                    }
                    this.islahi_year = this.profile.islahi_year;
                    this.other_org = this.profile.other_org;
                    this.loading_list = false;
                },
            );
        },
        storeBasicInfo(data) {
            data.id = this.$route.params.id;
            data.user_id = this.currentUser.id;
            data.contribute = JSON.stringify(this.contribute);
            data.social_media = JSON.stringify(this.social_media);
            data.submit_type = 'contribute';

            console.log(data);
            this.loading = true;
            UserService.authPostRequest('store-profile', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        // this.$swal('സേവ് ചെയ്തു', '', 'seccess');
                        this.loading = false;
                        this.$router.push('/bio-data/disability-info/' + this.$route.params.id);
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToNextPage(){
            this.$router.push('/bio-data/disability-info/' + this.$route.params.id);
        },
        getComboboxValues(code) {
            var data = {
                code: code
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'AREAS_CONTRIBUTE':
                            this.contribute_list = response.data.data.list.options;
                            break;
                        case 'BALAVEDI_CONTRIBUTES':
                            this.contribute_list = response.data.data.list.options;
                            break;
                        case 'SOCIAL_MEDIA':
                            this.social_media_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
            );
        },
        getChildList(field, event) {
            switch (field) {
                case 'contribute':
                    if (event == 'Other activities') {
                        if (this.contribute_text) {
                            this.contribute_text = false;
                        } else {
                            this.contribute_text = true;
                        }
                    } else if (event == 'Social Media') {
                        if (this.social_media_active) {
                            this.social_media_active = false;
                        } else {
                            this.getComboboxValues('SOCIAL_MEDIA');
                            this.social_media_active = true;
                        }
                    }
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

.label {
    font-size: 12px !important;
}

.form-control {
    height: 30px !important;
}

.mobile-field {
    margin-top: 23px;
}

.hr-line {
    height: 8px !important;
    background-color: #1a418c;
    opacity: 75%;
}
.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
    opacity: 1 !important;
}
.listview-title {
  color: #1a418c;
  padding: 1px 0px;
  font-size: 17px;
  font-weight: 900;
}
.section-title {
  color: #1a418c;
  padding: 1px 0%;
  font-size: 17px;
  font-weight: 900;
}
</style>