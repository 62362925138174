<template>
    <div class="section text-center pt-1" v-if="loading_list">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section pt-1" v-if="!loading_list">
        <div class="wallet-card">
            <div class="balance">
                <div class="left">
                    <span class="title">{{this.responseData.page_title}}</span>
                    <h1 class="total text-primary">{{ member_lists ? member_lists.total : '00' }}</h1>
                </div>
                <div class="right" v-if="responseData.permissions.add_new">
                    <a href="javascript:void(0);" class="button" @click="adminAdd">
                        <ion-icon name="add-outline"></ion-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="section pt-0 mt-0" v-if="!loading_list">
        <div class="listed-detail mt-2 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">{{this.responseData.page_title}}</h3>
        </div>
    </div>
    <div class="section pt-1" v-if="!loading_list && $route.params.list_type == 'admin-list'">
        <div class="row mt-1">
            <div class="col-12">
                <div class="stat-box">
                    <div class="title">Total</div>
                    <div class="value text-primary">{{ member_lists ? member_lists.total : '00' }}</div>
                </div>
            </div>
            <!-- <div class="col-6">
                <div class="stat-box">
                    <div class="title">Registered</div>
                    <div class="value text-success">{{ member_lists ? member_lists.total : '00' }}
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit</h5>
                    <h5 class="modal-title" v-if="add_admin">Add New</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic"
                                v-if="currentUser.user_level <= 5 && responseData.event.form.is_your_zonal">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>{{
                                        responseData.event.form.is_your_zonal_text }}</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" class="form-control custom-select" v-model="isYourZonal"
                                        name="isYourZonal" id="select4" @change="changeIsZonal($event.target.value)">
                                        <option value="Y">അതെ</option>
                                        <option value="N">അല്ല</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.name">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="name">Name <span
                                            class="text-danger">*</span></label>
                                    <Field type="text" class="form-control" name="name" placeholder="Name" v-model="name"
                                        required />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="isYourZonal == 'N' && currentUser.user_level <= 5 && responseData.event.form.district">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="district">District <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="district" name="district"
                                        id="select4" @change="getZoneList($event.target.value)">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="district in district_list" v-bind:key="district.id"
                                            :value="district.id">
                                            {{ district.name }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="district" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="isYourZonal == 'N' && currentUser.user_level <= 5 && responseData.event.form.zone">

                                <div class="input-wrapper">
                                    <label class="label text-primary" for="zone">Zone <span class="mal-text"></span></label>
                                    <Field as="select" class="form-control custom-select" v-model="zone" name="zone"
                                        id="select4" @change="getUnitList($event.target.value)">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="zone in zone_list" v-bind:key="zone.id" :value="zone.id">
                                            {{ zone.name }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="zone" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && currentUser.user_level <= 5 && responseData.event.form.unit">

                                <div class="input-wrapper">
                                    <label class="label text-primary" for="unit">Unit <span class="mal-text"></span></label>
                                    <Field as="select" class="form-control custom-select" v-model="unit" name="unit"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="unit in unit_list" v-bind:key="unit.id" :value="unit.id">
                                            {{ unit.name }}</option>

                                    </Field>
                                </div>
                                <ErrorMessage name="unit" class="error-feedback" />
                            </div>

                            <div class="row"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.sex">
                                <div class="col-12">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label text-primary" for="gender">Sex <span
                                                    class="text-danger">*</span></label>
                                            <Field as="select" class="form-control custom-select" name="gender" id="gender"
                                                v-model="gander" required>
                                                <option value="" selected>Select Sex</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="gender" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.mobile">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="mobile">Mobile <span
                                            class="text-danger">*</span></label>
                                    <Field type="text" class="form-control" id="mobile" name="mobile" placeholder="Mobile"
                                        v-model="mobile"
                                        oninput="javascript: if (this.value.length > 13) this.value = this.value.slice(0, 13);" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="mobile" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.panjayath">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="panjayath">Panjayath</label>
                                    <Field type="text" class="form-control" name="panjayath" placeholder="Panjayath"
                                        v-model="panjayath" required />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="panjayath" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.campus">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="campus_district">Campus District<span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="campus_district"
                                        name="campus_district" v-model="campus_district" @change="getCollegeList">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="district in campus_district_list" v-bind:key="district.id"
                                            v-bind:value="district.item_value">
                                            {{ district.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="campus_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.job">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="campus_district">Working District<span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="campus_district"
                                        name="campus_district" v-model="campus_district" @change="getCollegeList">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="district in campus_district_list" v-bind:key="district.id"
                                            v-bind:value="district.item_value">
                                            {{ district.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="campus_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && campus_district == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_district">Enter Place name</label>
                                    <Field type="text" class="form-control" name="other_district" placeholder=""
                                        v-model="other_district" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.campus">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="course_type">Course Type <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="course_type"
                                        name="course_type" v-model="course_type"
                                        @change="changeCourseType($event.target.value);">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="course_type in course_type_list" v-bind:key="course_type.id"
                                            v-bind:value="course_type.id">
                                            {{ course_type.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.job && responseData.event.form.job_instituion_level">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="course_type">Institution Level<span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="institution_level"
                                        name="institution_level" v-model="institution_level">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="instituion_level in instituion_level_list"
                                            v-bind:key="instituion_level.id" v-bind:value="instituion_level.id">
                                            {{ instituion_level.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="instituion_level" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && institution_level == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_institution_level">Institution Level</label>
                                    <Field type="text" class="form-control" name="other_institution_level" placeholder=""
                                        v-model="other_institution_level" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_instituion_level" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.job && responseData.event.form.job_type">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="course_type">Job Type <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="job_type" name="job_type"
                                        v-model="job_type" @change="changeJobType($event.target.value);">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="job_type in job_type_list" v-bind:key="job_type.id"
                                            v-bind:value="job_type.id">
                                            {{ job_type.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && job_type == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_job_type">Enter Job Type</label>
                                    <Field type="text" class="form-control" name="other_job_type" placeholder=""
                                        v-model="other_job_type" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_job_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.job && responseData.event.form.job_designation">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="designation">Designation</label>
                                    <Field type="text" class="form-control" name="designation" placeholder="designation"
                                        v-model="designation" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="designation" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.job && responseData.event.form.job_job">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="job">Job </label>
                                    <Field type="text" class="form-control" name="job" placeholder="Job" v-model="job" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="job" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.job && responseData.event.form.job_company">
                                <div class="input-wrapper">
                                    <label class="label text-primary"
                                        for="company">{{ responseData.event.form.job_company_text }} </label>
                                    <Field type="text" class="form-control" name="company"
                                        :placeholder="responseData.event.form.job_company_text" v-model="company"
                                        required />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="company" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.campus">

                                <div class="input-wrapper">
                                    <label class="label text-primary" for="campus">College <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="campus" name="campus"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="campus in campus_list" v-bind:key="campus.id"
                                            v-bind:value="campus.id">
                                            {{ campus.name }}
                                        </option>
                                        <option value="others">Others</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="campus" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && campus == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_college">Enter College Name</label>
                                    <Field type="text" class="form-control" name="other_college" placeholder=""
                                        v-model="other_college" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_college" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && responseData.event.form.campus">

                                <div class="input-wrapper">
                                    <label class="label text-primary" for="course">Course <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="course" name="course"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="course in course_list" v-bind:key="course.id"
                                            v-bind:value="course.id">
                                            {{ course.name }}
                                        </option>
                                        <option value="others">Others</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="(isYourZonal == 'N' || isYourZonal == 'Y') && course == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_course">Enter Course Name</label>
                                    <Field type="text" class="form-control" name="other_course" placeholder=""
                                        v-model="other_course" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_course" class="error-feedback" />
                            </div>

                            <div class="form-group basic" v-if="registration_id">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="direct_meet">
                                        നിങ്ങൾ വിദ്യാർത്ഥിയെ നേരിൽ കണ്ട് സംസരിച്ചോ</label>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" value="Yes" name="direct_meet"
                                            id="direct_meet_1" v-model="direct_meet" />
                                        <label class="form-check-label" for="direct_meet_1">Yes &nbsp;</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" value="No" name="direct_meet"
                                            id="direct_meet_2" v-model="direct_meet" />
                                        <label class="form-check-label" for="direct_meet_2">No &nbsp;</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="registration_id">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="direct_call">
                                        വിദ്യാർത്ഥിയെ വിളിച്ചു ചോദിച്ചോ</label>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" value="Yes" name="direct_call"
                                            id="direct_call_1" v-model="direct_call" />
                                        <label class="form-check-label" for="direct_call_1">Yes &nbsp;</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" value="No" name="direct_call"
                                            id="direct_call_2" v-model="direct_call" />
                                        <label class="form-check-label" for="direct_call_2">No &nbsp;</label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span v-if="!registration_id">Save</span>
                                    <span v-else>Register</span>
                                </button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DeleteMemberModel -->

    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>
    <!-- <div id="search" v-if="!loading_list">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
                    @input="filteredMembers()">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div> -->
    <!-- <div class="section" v-if="!loading_list">
        <span><a :href="'https://batch.msoftit.com/reports/pdf/reportZonalCouncil.php?zone_id=' + unit.id + '&wing=6&mobile='+currentUser.phone"
                :download="'Girls Zonal Council'">
                <ion-icon name="download-outline"></ion-icon> Download PDF
            </a></span>
    </div> -->
    <!-- <div class="section inset mt-1 mb-1" v-if="currentUser.user_level <= 3 && !loading_list">
        <div class="accordion" id="accordionExample2">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion001" aria-expanded="false">
                        <ion-icon name="filter-outline" role="img" class="md hydrated"
                            aria-label="wallet outline"></ion-icon>
                        Filter
                    </button>
                </h2>
                <div id="accordion001" class="accordion-collapse collapse" data-bs-parent="#accordionExample2" style="">
                    <div class="accordion-body">
                        <div id="search" style="padding: 0px !important;">
                            <div class="form-group" v-if="currentUser.user_level == 1">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_district">District</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_district"
                                        name="filter_district" id="select4" @change="getZoneList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="district in district_list" v-bind:value="district.id"
                                            v-bind:key="district.id">{{
                                                district.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 2">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_zone">Zone</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_zone"
                                        name="filter_zone" id="select4" @change="getUnitList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="zone in zone_list" v-bind:value="zone.id" v-bind:key="zone.id">{{
                                            zone.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 3">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_unit">Unit</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_unit"
                                        name="filter_unit" id="select4">
                                        <option value>---All---</option>
                                        <option v-for="unit in unit_list" v-bind:value="unit.id" v-bind:key="unit.id">{{
                                            unit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_reg_status">Registration Status</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_reg_status"
                                        name="filter_reg_status" id="select4">
                                        <option value>---All---</option>
                                        <option value="Y">Registered</option>
                                        <option value="N">Not Registered</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_sex">Sex</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_sex"
                                        name="filter_sex" id="select4">
                                        <option value>---All---</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" class="btn btn-danger btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="clearFilter()">
                                        <ion-icon name="trash-bin-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Clear
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="getMembersList(null,null,'local')">
                                        <ion-icon name="search-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="section" v-if="!loading_list && $route.params.list_type == 'admin-list'">
        <span><a :href="'https://guide.msoftit.com/export/event-export-data?user_level=' + currentUser.user_level + '&district_id='
            + currentUser.district_id + '&zone_id='
            + currentUser.zone_id + '&unit_id='
            + currentUser.unit_id + '&campus_id='
            + currentUser.campus_id + '&admin_level='
            + currentAdminLevel + '&type='
            + 'donation' + '&filter_district='
            + filter_district + '&filter_zone='
            + filter_zone + '&filter_sex='
            + filter_sex + '&filter_unit='
            + filter_unit + '&search_qry='
            + search_qry + '&event_type='
            + $route.params.slug + ''
            
            " :download="'DonatdContacts'">
                <ion-icon name="download-outline"></ion-icon> Download Excel
            </a></span>
    </div>
    <ul class="listview image-listview inset text mt-2" v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id"
        :style="{ 'background-color': member_list.registered_from == 'campus' ? '#ffffb3' : member_list.registered_from == 'public' ? '#b3ffec' : '#f2f2f2' }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <span class="button verified" v-if="member_list.registration_status == '1'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified" v-else>
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <!-- <header v-if="member_list.donated">Donated By:{{member_list.donated.name}}</header> -->
                        <b> {{ member_list.name }} </b>
                        <footer class="text-primary">{{ member_list.mobile }}
                            <br>
                            <span>
                                <span class="primary" v-if="currentUser.user_level <= 1 && member_list.district">District:
                                    {{ member_list.district ? member_list.district.name : '' }}<br></span>
                                <span class="primary" v-if="currentUser.user_level <= 2 && member_list.zone">Zone:
                                    {{ member_list.zone ? member_list.zone.name : '' }}<br></span>
                                <span class="primary" v-if="currentUser.user_level <= 3 && member_list.unit">Unit:
                                    {{ member_list.unit ? member_list.unit.name : '' }}<br></span>
                                <span class="primary"
                                    v-if="currentUser.user_level != 6 && member_list.campus && responseData.event.form.campus">Campus:
                                    {{ member_list.campus ? member_list.campus.name : '' }}<br></span>
                                <span class="primary"
                                    v-if="currentUser.user_level != 6 && member_list.job_type_details && responseData.event.form.job">Job:
                                    {{ member_list.job_type_details ? member_list.job_type_details.name : '' }}<br></span>
                                <!-- <span class="text-danger" v-if="member_list.donated"><i>Donated
                                        By: {{ member_list.donated.name }}</i><br></span>
                                        <span class="text-success"
                                    v-if="member_list.registered && currentUser.user_level <= 4 && (currentUser.wing == 3 || currentUser.testing_user == 'Y')"><i>Registered
                                        By: {{ member_list.registered ? member_list.registered.name : '' }}</i><br></span>
                                <span class="primary">Donated From :
                                    {{ member_list.registered_from}}
                                    </span> -->
                            </span>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li
                                            v-if="!member_list.registration_status && this.$route.params.list_type == 'admin-list' && responseData.event.donate_to_register">
                                            <a href="javascript:void(0)" class="btn btn-list"
                                                @click="adminView(member_list, true)" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="add-outline"></ion-icon> Register Contact
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call To Candidate
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'https://wa.me/' +member_list.mobile"
                                                class="btn btn-list">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.donated">
                                            <a :href="'tel:' + member_list.donated.phone" class="btn btn-list"
                                                v-if="this.$route.params.list_type == 'admin-list'">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call To Donated User
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="!member_list.registration_status">
                                            <a href="javascript:void(0)" @click="adminView(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>

                                        <li v-if="!member_list.registration_status">
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total"
            :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page"
            :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
// import * as yup from "yup";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        //     const schema_non_member = yup.object().shape({
        //         name: yup.string().when("isMember", {
        //     is: 'N',
        //     then: yup.string().required('ഫിൽ ചെയ്യുക!')
        //   }),
        //         age: yup.string().required('ഫിൽ ചെയ്യുക!'),
        //         gender: yup.string().required('സെലക്റ്റ് ചെയ്യുക!'),
        //     });
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            // schema_non_member,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            responseData: {
                event: {
                    form: []
                },
                questians: [],
                permissions: []
            },
            questians_responses: [],

            isYourZonal: '',
            district_list: [],
            campus_district_list: [],
            zone_list: [],
            unit_list: [],
            district: '',
            zone: '',
            unit: '',

            campus_district: '',
            other_district: '',
            campus_list: [],
            campus: '',
            other_college: '',
            course_list: [],
            course: '',
            other_course: '',
            registration_id: false,
            course_type_list: [],
            course_type: '',
            currentAdminLevel: '',

            job_type_list: [],
            instituion_level_list: [],
            other_instituion_level: '',
            designation: '',

            job_type: '',
            other_job_type: '',

            job: '',
            company: '',
            direct_call: '',
            direct_meet: '',
            filter_district: '',
            filter_zone: '',
            filter_unit: '',
            filter_reg_status:'',
            filter_sex:''

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            if (this.responseData.event.campus_admin) {
                this.currentAdminLevel = localStorage.getItem('currentAdminLevel');
                if (this.currentAdminLevel == '7') {
                    this.isYourZonal = 'Y';
                }
            } else {
                this.currentAdminLevel = this.currentUser.user_level;
            }
            this.getDistrictList();
            this.getMembersList(1, this.currentAdminLevel);
            this.$root.updateParent('Loading...');
        }
        // this.getComboboxValues('COUNTRY_CODE');
        this.getComboboxValues("PROFCON_COURSE_TYPE");
        this.getComboboxValues("KYC_JOB_TYPES");
        this.getComboboxValues("INSTITUTION_LEVEL");
        this.getComboboxValues("DISTRICT_KERALA");
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case "DISTRICT_KERALA":
                            this.campus_district_list = response.data.data.list.options;
                            break;
                        case "PROFCON_COURSES":
                            this.course_list = response.data.data.list.options;
                            break;
                        case "PROFCON_COURSE_TYPE":
                            this.course_type_list = response.data.data.list.options;
                            break;
                        case "KYC_JOB_TYPES":
                            this.job_type_list = response.data.data.list.options;
                            break;
                        case "INSTITUTION_LEVEL":
                            this.instituion_level_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.$route.params.id,
                user_level: this.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin, register = null) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            if (admin.zone_id && this.currentUser.zone_id && (admin.zone_id == this.currentUser.zone_id)) {
                this.isYourZonal = 'Y';
                this.getUnitList(this.currentUser.zone_id);
            } else {
                this.isYourZonal = 'N';
                this.getDistrictList();
                if (admin.zone_id) {
                    this.getUnitList(admin.zone_id);
                }
            }

            if (admin.district_id) {
                this.getZoneList(admin.district_id);
            }

            this.name = admin.name;
            this.mobile = admin.mobile;
            this.gander = admin.sex == 'Male' ? 'M' : 'F';
            this.unit = admin.unit_id;
            this.district = admin.district_id;
            this.zone = admin.zone_id;
            this.course_type = admin.course_type_id;
            this.getComboboxValues("PROFCON_COURSES", admin.course_type);
            this.campus_district = admin.campus_district_id == 0 ? 'others' : admin.campus_district_id;
            this.getCollegeList();
            this.course = admin.course_id == 0 ? 'others' : admin.course_id;
            this.campus = admin.campus_id == 0 ? 'others' : admin.campus_id;

            this.job_type = admin.job_type == 0 ? 'others' : admin.job_type;
            this.other_job_type = admin.job_type_other;
            this.panjayath = admin.panjayath;
            this.job = admin.job;
            this.company = admin.company;
            this.job_district = admin.job_district_id == 0 ? 'others' : admin.job_district_id;

            this.other_college = admin.campus_other;
            this.other_course = admin.course_other;
            this.other_district = admin.campus_district_other;
            this.institution_level = admin.institution_level;
            this.other_institution_level = admin.institution_level_other;
            this.designation = admin.designation;
            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
            if (register) {
                this.registration_id = admin.id;
            } else {
                this.registration_id = null;
            }
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.disable_member_form = false;
            this.message = '';
            this.member_id = '';
            this.name = '';
            this.mobile = '';
            this.subscription_no = '';
            this.job = '';
            this.company = '';
            this.panjayath = '';
            this.institution_level = '';
            this.institution = '';
            this.designation = '';
            this.job_type = '';
            this.member_edit = true;
            this.member = '';
            this.editable_id = null;
            this.add_admin = true;
            this.edit_admin = false;
            this.campus_district = '',
                this.campus = '';
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            user.editable_id = this.editable_id;
            user.event_type = this.$route.params.slug;
            user.is_your_zone = this.isYourZonal;
            this.loading = true;
            if (this.registration_id) {
                user.editable_id = this.registration_id;
                if (!(this.direct_call == 'Yes' || this.direct_meet == 'Yes')) {
                    this.alert_type = 'Warning';
                    this.alert_message = 'ക്ഷമിക്കണം. സംസാരിച്ചതിന് ശേഷം ശ്രമിക്കുക.';
                    this.$refs.alert_model.openModel();
                    this.loading = false;
                    return 0;
                }
            } else {
                user.ffr = true;
            }

            

            UserService.addAdmin('store-event-registration', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.member_lists.current_page, this.currentAdminLevel);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.loading = false;

                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delete-event-registration', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.member_lists.current_page,this.currentAdminLevel);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        getMembersList(page = 1, currentAdminLevel = null) {
            this.loading_list = true;
            let data = {
                event: this.$route.params.slug,
                type: 'ffr',
                list_type: this.$route.params.list_type,
                admin_level: currentAdminLevel,
                filter_district: this.filter_district ? this.filter_district : '',
                filter_zone: this.filter_zone ? this.filter_zone : '',
                filter_unit: this.filter_unit ? this.filter_unit : '',
                filter_reg_status : this.filter_reg_status ? this.filter_reg_status : '',
                filter_sex : this.filter_sex ? this.filter_sex : '',
            }
            UserService.authPostRequest(`get-event-registrations?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.permissions = response.data.data.permissions;
                        this.member_lists = response.data.data.list;
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        if (this.responseData.questians) {
                            let count = 0;
                            this.responseData.questians.forEach(element => {
                                this.questians_responses[count] = element.answer_text;
                                count++;
                            });
                        }
                        if (this.responseData.campus) {
                            this.campus_district = this.responseData.campus.campus_district_id;
                            this.campus = this.responseData.campus.id;
                        }
                        this.$root.updateParent(this.responseData.event.event_name);
                        this.loading_list = false;
                    } else {
                        this.responseData = response.data.data;
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeIsZonal(is_zonal) {
            if (is_zonal == 'Y') {
                this.getUnitList(this.currentUser.zone_id);
            } else {
                this.getDistrictList();
            }
        },
        changeCourseType(item) {
            this.getCollegeList();
            this.getComboboxValues("PROFCON_COURSES", item);
        },
        getDistrictList() {
            // this.loading_list = true;
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getZoneList(district_id) {
            // this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getUnitList(zone_id) {
            // this.loading_list = true;
            let data = {
                zone_id: zone_id
            }
            UserService.authPostRequest(`get-unit-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.unit_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        getCollegeList() {
            // this.loading_list = true;
            let data = {
                district_id: this.campus_district ? this.campus_district : '',
                stream_id: this.course_type ? this.course_type : '',
            }
            UserService.authPostRequest(`get-campus-list`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.campus_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clearFilter() {
            this.filter_district = '';
            this.filter_zone = '';
            this.filter_unit = '';
            this.getMembersList();
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.questians-label {
    font-weight: 600 !important;
    font-size: 14px !important;

}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}</style>