<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 custom-title">Continuing Religious Education</h3>
        </div>
    </div>
    <div class="section pt-1" v-if="!loading_page">
        <router-link :to="'/org/'+user_level+'/'+user_level_id+'/cre-registration'">
            <button type="button" class="btn btn-outline-primary me-1 mal-text btn-lg col-12 mb-1 bg-white">
                        പുതിയ സെന്റർ രജിസ്റ്റർ ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക 
                        <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon>
                    </button>
        </router-link>
        <router-link :to="'/org/'+user_level+'/'+user_level_id+'/cre-registrations'" v-if="permissions.report">
            <button type="button" class="btn btn-outline-primary me-1 mal-text btn-lg col-12 mb-1 bg-white">
                രജിസ്റ്റർ ചെയ്ത ലിസ്റ്റ് കാണാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക 
                        <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon>
                    </button>
        </router-link>
        <router-link :to="'/org/'+user_level+'/'+user_level_id+'/cre-weekly-report'" v-if="permissions.report">
            <button type="button" class="btn btn-outline-primary me-1 mal-text btn-lg col-12 mb-1 bg-white">
                റിപ്പോർട്ട് കാണാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക 
                        <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon>
                    </button>
        </router-link>
        <router-link :to="'/donations/cre-fees'">
            <button type="button" class="btn btn-danger me-1 mal-text btn-lg col-12 mb-2 bg-white">
                ഫീസ് അടക്കാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക 
                        <ion-icon name="chevron-forward-outline" style="font-size: 60px;"></ion-icon>
                    </button>
        </router-link>
    </div>
    <div class="section pb-2" v-if="!loading_page">
        <div class="card pl-2 pr-2 mt-1"  v-for="center in responseData.centers" v-bind:key="center.id">
            <div class="listed-detail mt-1">
                <h3 class="text-center mt-1">{{center.center_name}}</h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-1">
                <li>
                    <strong>Status</strong>
                    <span :class="center.approve_status == 'Pending Approval'?'text-warning':'text-success'">{{center.approve_status}}</span>
                </li>
                <li>
                    <strong>Level</strong>
                    <span class="text-primary">{{center.user_level == 3?center.zone.name:center.unit.name}} {{center.user_level == 3?'Zone':' Unit'}}</span>
                </li>
                <li>
                    <strong>Cordinator</strong>
                    <span class="text-primary">{{center.student_cordinator?center.student_cordinator.name:''}}</span>
                </li>
                <li>
                    <strong>Faculty</strong>
                    <span class="text-primary" v-if="center.faculty">{{center.faculty.name}}<br>{{center.faculty.mobile}}
                        <button type="button" class="btn btn-warning btn-sm me-1 mb-1" @click="adminView(center)">
                            Edit
                        </button>
                    </span>
                    <span class="text-primary" v-else>
                        <button type="button" class="btn btn-primary btn-sm me-1 mb-1" @click="adminView(center)">
                            Add
                        </button>
                    </span>
                </li>
                <li>
                    <strong>Added By</strong>
                    <span class="text-primary">{{center.added_by_name}}</span>
                </li>
                <li v-if="center.action_permission">
                    <strong>Action</strong>
                    <span><router-link :to="'/org/'+user_level+'/'+user_level_id+'/cre-registration/'+center.id" class="text-danger">View/Edit</router-link></span>
                </li>
                <li v-if="center.action_permission">
                    <strong></strong>
                    <span><router-link :to="'/org/cre/'+center.id" class="text-primary"><button type="button" class="btn btn-primary btn-sm btn-block">CRE Dashboard</button></router-link></span>
                   
                </li>
            </ul>
        </div>
    </div>
    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit</h5>
                    <h5 class="modal-title" v-if="add_admin">Add New</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="stay"><span>നിലവിൽ മെമ്പർ ആണോ</span></label>
                                    <select as="select" class="form-control custom-select" v-model="isMember" name="isMember" id="select4">
                                                                                        <option value>---Select---</option>
                                                                                        <option value="Y">അതെ</option>
                                                                                        <option value="N">അല്ല</option>
                                                                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                            <div class="form-group basic typehead-div" v-if="isMember == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Member</label>
                                    <v-select label="text" :filterable="true" v-model="member" :value="member" :options="member_list" @input="getTextList($event.target.value, 'all')">
                                        <template>
                                                                                        type to search...
                                        </template>
                                        
                                        <template>
                                            <div class="d-center">
                                                {{ member.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                    <ErrorMessage name="member" class="error-feedback" />
                                </div>
                                <div class="input-info" v-if="member">Mobile :{{ member.country_code }} {{
                                        member.mobile
                                }}
                                </div>
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="name"><span>Name</span></label>
                                    <Field name="name" type="text" class="form-control" v-model="name" placeholder="Name" autocomplete="off" />
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="row" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="code">Code <span class="text-danger">*</span></label>
                                            <Field as="select" class="form-control custom-select" id="code" name="code"
                                                v-model="code">
                                                <option value="" :disabled="true" selected>#</option>
                                                <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                                    v-bind:value="code.item_value">
                                                    {{ code.name }}</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="code" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="mobile">Mobile <span
                                                    class="text-danger">*</span></label>
                                            <Field type="number" class="form-control" id="mobile" name="mobile"
                                                placeholder="Mobile" v-model="mobile"
                                                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="mobile" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="country_code_whatsapp">Code</label>
                                            <Field as="select" class="form-control custom-select" id="country_code_whatsapp" name="country_code_whatsapp"
                                                v-model="country_code_whatsapp" @change="onChangeCountryCode($event)">
                                                <option value="" :disabled="true" selected>#</option>
                                                <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                                    v-bind:value="code.item_value">
                                                    {{ code.name }}</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="code" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="whatsapp">Whatsapp </label>
                                            <Field type="number" class="form-control" id="whatsapp" name="whatsapp"
                                                placeholder="whatsapp" v-model="whatsapp"
                                                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="whatsapp" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
    
                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>
    
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading" v-if="update_faculty">
                                                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                                        <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!update_faculty" @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DeleteMemberModel -->
    <div class="section text-center" v-if="loading_page">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import $ from "jquery";
import UserService from "../../services/user.service";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
import vSelect from 'vue-select'
export default {
    name: "Admin List",
    props: {
        unit_id: null
    },
    components: {
        Form,
        Field,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({
        });
        return {
            loading: false,
            loading_page: false,
            user_level: "",
            schema,
            member_lists: [],
            member_list: [],
            country_code_list: [],  
            member: [],
            isMember: '',
            name:'',
            code: '91',
            mobile: '',
            whatsapp: '',
            country_code_whatsapp: '91',
            user_level_id: "",
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            update_faculty:false,
            permissions: {
                edit: true
            },
            responseData: [],
            editable_id : '',
            editable_centre_id :'',
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        childLength() {
            return parseInt(this.formData.lessthan_16_count);
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.$root.updateParent("CRE");
            this.user_level = this.currentUser.user_level;
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getComboboxValues('COUNTRY_CODE');
            this.getCreCenterData(1);
        }

        window.onpopstate = function() {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        getCreCenterData(id) {
            let data = {
                id: id
            }
            UserService.authPostRequest('get-my-centers', data).then(
                (response) => {
                    
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.permissions = response.data.data.permissions;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                user_level: 1
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "COUNTRY_CODE":
                            this.country_code_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(center) {
            this.member = [];
            this.isMember = '';
            $('#addAdminModel').modal('show');
            this.message = '';
            this.editable_centre_id = center.id;
            if(center.faculty){
                if (center.faculty.member_id) {
                    this.isMember = 'Y';
                    this.member_id = center.faculty.member_id;
                    this.disable_member_form = true;
                    this.member = {
                        id: center.faculty.member_id,
                        member_id: center.faculty.member_id,
                        text: center.faculty.name,
                        mobile: center.faculty.mobile,
                        country_code: center.faculty.country_code
                    }
                    this.member_list.push(this.member);
                } else {
                    this.isMember = 'N';
                    this.disable_member_form = false;
                    this.name = center.faculty.name;
                    this.code = center.faculty.country_code;
                    this.mobile = center.faculty.mobile;
                    this.whatsapp = center.faculty.whatsapp;
                    this.country_code_whatsapp = center.faculty.country_code_whatsapp;
                }
                this.editable_id = center.faculty.id;
            }
            
            if(center.action_permission){
                this.update_faculty = true;
            }
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            this.loading = true;
            user.editable_id = this.editable_id;
            user.editable_centre_id = this.editable_centre_id;
            user.is_member = this.isMember;
            if (this.isMember == 'Y') {
                if (this.member) {
                    if (this.member.length == 0) {
                        this.alert_type = 'Warning';
                        this.alert_message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക.';
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        return false;
                    }
                } else {
                    this.alert_type = 'Warning';
                    this.alert_message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക.';
                    this.$refs.alert_model.openModel();
                    this.loading = false;
                    return false;
                }

            }
            if (this.isMember == 'N' && !user.name) {
                this.alert_type = 'Warning';
                this.alert_message = 'ദയവായി പേര് ഫിൽ ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                this.loading = false;
                return false;
            }
            if (this.isMember == 'N' && !user.mobile) {
                this.alert_type = 'Warning';
                this.alert_message = 'ദയവായി മൊബൈൽ ഫിൽ ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                this.loading = false;
                return false;
            }
            if (this.isMember == 'Y') {
                user.member_id = this.member.id;
            } else {
                user.member_id = null;
            }
            UserService.addAdmin('store-cre-faculty', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.getCreCenterData(1);
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                        this.editable_centre_id = '';
                        this.editable_id = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    }
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}

.custom-file-upload {
    position: relative;
    display: flex;
    width: 50%;
    height: 100px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 1;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #060606;
}
</style>
