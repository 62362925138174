<template>
    <div class="section text-center mt-2" v-if="loading">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section pb-0" v-if="!loading">
        <div class="card pl-2 pr-2 mt-1">
            <div class="listed-detail mt-1">
                <h3 class="text-center mt-1">{{ responseData.center_name }}</h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-1">
                <li>
                    <strong>Reg No</strong>
                    <span class="text-success">{{ responseData.reg_no }}</span>
                </li>
                <li>
                    <strong>Faculty
                        <button type="button" v-if="responseData.faculty" class="btn btn-warning btn-sm me-1 mb-1" @click="facultyView(responseData)">
                            Edit
                        </button>
                        <button type="button" v-else class="btn btn-primary btn-sm me-1 mb-1" @click="facultyView(responseData)">
                            Add
                        </button>
                    </strong>
                    <span class="text-primary" v-if="responseData.faculty">{{ responseData.faculty.name }}<br>{{ responseData.faculty.mobile }}
                    </span>
                </li>
                <li>
                    <strong>Cre Day 
                        <button type="button" v-if="responseData.cre_day" class="btn btn-warning btn-sm me-1 mb-1" @click="creDayView(responseData)">
                            Edit
                        </button>
                        <button type="button" v-else class="btn btn-primary btn-sm me-1 mb-1" @click="creDayView(responseData)">
                            Add
                        </button>
                    </strong>
                    <span class="text-primary">{{ responseData.cre_day }}<br>{{ responseData.cre_time }}</span>
                   
                </li>
                <li>
                    <strong></strong>
                    <span><router-link
                            :to="'/org/' + responseData.user_level + '/' + responseData.user_level_id + '/view-cre-center/' + responseData.id"
                            class="text-primary">Click here to more details</router-link></span>
                </li>
                <li v-if="permissions.cre_report_update">
                    <strong></strong>
                    <span><router-link
                            :to="'/org/cre/' + responseData.id+'/weekly-report'"
                            class="text-success">Weekly Report</router-link></span>
                </li>
                <li v-if="permissions.cre_details_update">
                    <strong>Action</strong>
                    <span><router-link
                            :to="'/org/' + responseData.user_level + '/' + responseData.user_level_id + '/cre-registration/' + responseData.id"
                            class="text-danger">View/Edit</router-link></span>
                </li>
            </ul>
        </div>
    </div>
    <div v-if="!loading">
        <add-members ref="add_member" v-bind:current_user="currentUser" v-bind:permissions="permissions"></add-members>
    </div>
    <!-- addFacultyModel -->
    <div class="modal fade action-sheet" id="addFacultyModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit</h5>
                    <h5 class="modal-title" v-if="add_admin">Add New</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="facutlyAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="stay"><span>നിലവിൽ മെമ്പർ ആണോ</span></label>
                                    <select as="select" class="form-control custom-select" v-model="isMember"
                                        name="isMember" id="select4">
                                        <option value>---Select---</option>
                                        <option value="Y">അതെ</option>
                                        <option value="N">അല്ല</option>
                                    </select>
                                </div>
                                <input type="hidden" v-model="member_id" />
                            </div>
                            <div class="form-group basic typehead-div" v-if="isMember == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label" for="text4b">Member</label>
                                    <v-select label="text" :filterable="true" v-model="member" :value="member"
                                        :options="member_list" @input="getTextList($event.target.value, 'all')">
                                        <template>
                                            type to search...
                                        </template>

                                        <template>
                                            <div class="d-center">
                                                {{ member.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                    <ErrorMessage name="member" class="error-feedback" />
                                </div>
                                <div class="input-info" v-if="member">Mobile :{{ member.country_code }} {{
                                    member.mobile
                                }}
                                </div>
                            </div>
                            <div class="form-group basic" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="input-wrapper">
                                    <label class="label" for="name"><span>Name</span></label>
                                    <Field name="name" type="text" class="form-control" v-model="name" placeholder="Name"
                                        autocomplete="off" />
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="row" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="code">Code <span class="text-danger">*</span></label>
                                            <Field as="select" class="form-control custom-select" id="code" name="code"
                                                v-model="code">
                                                <option value="" :disabled="true" selected>#</option>
                                                <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                                    v-bind:value="code.item_value">
                                                    {{ code.name }}</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="code" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="mobile">Mobile <span
                                                    class="text-danger">*</span></label>
                                            <Field type="number" class="form-control" id="mobile" name="mobile"
                                                placeholder="Mobile" v-model="mobile"
                                                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="mobile" class="error-feedback" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="isMember == 'N' || (edit_admin && isMember != 'Y')">
                                <div class="col-5">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="country_code_whatsapp">Code</label>
                                            <Field as="select" class="form-control custom-select" id="country_code_whatsapp"
                                                name="country_code_whatsapp" v-model="country_code_whatsapp"
                                                @change="onChangeCountryCode($event)">
                                                <option value="" :disabled="true" selected>#</option>
                                                <option v-for="code in country_code_list" v-bind:key="code.item_value"
                                                    v-bind:value="code.item_value">
                                                    {{ code.name }}</option>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="code" class="error-feedback" />
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="whatsapp">Whatsapp </label>
                                            <Field type="number" class="form-control" id="whatsapp" name="whatsapp"
                                                placeholder="whatsapp" v-model="whatsapp"
                                                oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                        <ErrorMessage name="whatsapp" class="error-feedback" />
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="update_faculty">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!update_faculty"
                                    @click="closeaddFacultyModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade action-sheet" id="addDayModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Day & Time</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="dayUpdate" :validation-schema="schema_say">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="day"><span>Day</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="day" name="day"
                                        id="select4">
                                        <option value>---Select---</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                        <option value="Sunday">Sunday</option>
                                    </Field>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="time"><span>Time</span></label>
                                    <Field name="time" type="time" class="form-control" v-model="time" placeholder="Time"
                                        autocomplete="off" />
                                </div>
                                <ErrorMessage name="time" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="permissions.edit">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.edit"
                                    @click="closeCreDayModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../services/user.service";
import AddMembers from './StudentsList.vue';
import AlertModel from "./../layouts/AlertModel.vue";
import $ from "jquery";
import vSelect from 'vue-select'
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Organization',
    components: {
        AddMembers,
        Form,
        Field,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({
        });
        const schema_day = yup.object().shape({
        });
        return {
            loading: false,
            schema,
            schema_day,
            message: "",
            user_level: "",
            permissions: {
                edit: true
            },
            member_lists: [],
            member_list: [],
            country_code_list: [],
            member: [],
            isMember: '',
            member_id: '',
            name: '',
            code: '91',
            mobile: '',
            whatsapp: '',
            country_code_whatsapp: '91',
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
            unit: [],
            day: '',
            time: '',
            nerpatham_target: '',
            sub_level_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            sub_level: [],
            responseData: {
                student_cordinator: [],
                faculty: [],
                zone: [],
                unit: []
            },
            editable_id: '',
            editable_centre_id: '',
            edit_admin: false,
            add_admin: false
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = this.currentUser.user_level;
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getComboboxValues('COUNTRY_CODE');

            this.getCreCenterData(this.$route.params.id);
            // this.getSubLevels();
        }
    },
    methods: {
        openAddMemberModel() {
            this.$refs.add_member.adminAdd();
        },
        getCreCenterData(id) {
            let data = {
                centre_id: id
            }
            UserService.authPostRequest('get-my-centers', data).then(
                (response) => {

                    if (response.data.status == 'success') {
                        this.responseData = response.data.data.list;
                        this.permissions = response.data.data.permissions;
                        if (this.permissions.edit) {
                            this.edit_admin = true;
                            this.add_Admin = true;
                            this.update_faculty = true;
                        }
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                user_level: 1
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "COUNTRY_CODE":
                            this.country_code_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        facultyView(center) {
            this.member = [];
            this.isMember = '';
            $('#addFacultyModel').modal('show');
            this.message = '';
            this.editable_centre_id = center.id;
            if (center.faculty) {
                if (center.faculty.member_id) {
                    this.isMember = 'Y';
                    this.member_id = center.faculty.member_id;
                    this.disable_member_form = true;
                    this.member = {
                        id: center.faculty.member_id,
                        member_id: center.faculty.member_id,
                        text: center.faculty.name,
                        mobile: center.faculty.mobile,
                        country_code: center.faculty.country_code
                    }
                    this.member_list.push(this.member);
                } else {
                    this.isMember = 'N';
                    this.disable_member_form = false;
                    this.name = center.faculty.name;
                    this.code = center.faculty.country_code;
                    this.mobile = center.faculty.mobile;
                    this.whatsapp = center.faculty.whatsapp;
                    this.country_code_whatsapp = center.faculty.country_code_whatsapp;
                }
                this.editable_id = center.faculty.id;
            }

            if (center.action_permission) {
                this.update_faculty = true;
            }
        },
        creDayView(center) {
            $('#addDayModel').modal('show');
            this.message = '';
            this.editable_centre_id = center.id;
            this.day = center.cre_day;
            this.time = center.cre_time;
        },
        closeaddFacultyModel() {
            $('#addFacultyModel').modal('hide');
        },
        closeCreDayModel() {
            $('#addDayModel').modal('hide');
        },
        facutlyAdmin(user) {
            this.loading = true;
            user.editable_id = this.editable_id;
            user.editable_centre_id = this.editable_centre_id;
            user.is_member = this.isMember;
            if (this.isMember == 'Y') {
                if (this.member) {
                    if (this.member.length == 0) {
                        this.alert_type = 'Warning';
                        this.alert_message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക.';
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        return false;
                    }
                } else {
                    this.alert_type = 'Warning';
                    this.alert_message = 'ദയവായി മെമ്പറെ ലിസ്റ്റിൽ നിന്നും സെലക്ട് ചെയ്തതിനു ശേഷം Save അമർത്തുക.';
                    this.$refs.alert_model.openModel();
                    this.loading = false;
                    return false;
                }

            }
            if (this.isMember == 'N' && !user.name) {
                this.alert_type = 'Warning';
                this.alert_message = 'ദയവായി പേര് ഫിൽ ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                this.loading = false;
                return false;
            }
            if (this.isMember == 'N' && !user.mobile) {
                this.alert_type = 'Warning';
                this.alert_message = 'ദയവായി മൊബൈൽ ഫിൽ ചെയ്യുക.';
                this.$refs.alert_model.openModel();
                this.loading = false;
                return false;
            }
            if (this.isMember == 'Y') {
                user.member_id = this.member.id;
            } else {
                user.member_id = null;
            }
            UserService.addAdmin('store-cre-faculty', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addFacultyModel').modal('hide')
                        this.getCreCenterData(1);
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                        this.editable_centre_id = '';
                        this.editable_id = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        dayUpdate(user) {
            this.loading = true;
            user.editable_id = this.editable_id;
            user.editable_centre_id = this.editable_centre_id;
            UserService.addAdmin('update-cre-day', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addFacultyModel').modal('hide')
                        this.getCreCenterData(1);
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                        this.editable_centre_id = '';
                        this.editable_id = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.list-title {
    font-size: 16px;
    color: black;
}

.item {
    /* margin-bottom: 10px; */
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    /* height: 46px !important; */
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}


.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}


.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.w-50 {
    width: 50px !important;
}</style>