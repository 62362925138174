<template>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">പണമില്ലാത്തവന്റെ പണവും സമയമില്ലാത്തവന്റെ സമയവമാണ് ഈ യാത്രയുടെ കരുത്ത്.</h3>
        </div>
    </div>
    <div class="section mt-0 mb-2">
        <router-link v-for="item in responseData.donations" :key="item.id" :to="'/donations/'+item.slug">
            <img :src="item.image" alt="image" class="imaged img-fluid mt-1">
        </router-link>
    </div>
</template>

<script>
import UserService from "../../services/user.service";
export default {
    name: "Register",
    components: {},
    data() {
        return {
            loading: false,
            responseData:{
                donations : []
            }
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted() {
        this.$root.updateParent("Loading...");
        this.getDonation();
    },
    methods: {
        getDonation() {
            UserService.GetRequest('get-donations', null).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.$root.updateParent("Donations");
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>

<style scoped>
.error-feedback {
    color: red;
}

#username {
    margin-top: 0px;
}

.label {
    margin-left: -10px;
    margin-bottom: 0px;
}

.forget {
    font-size: 12px !important;
    text-decoration: underline;
}

.title-reg {
    font-size: 18px;
}
</style>
