<template>
    <div id="search" class="mt-1">
        <form class="search-form">
            <div class="row">
                <div class="input-group form-group" style="width:70% !important;">
                    <span class="input-group-text" id="basic-addon3">PF3-</span>
                    <input type="number" class="form-control" maxlength="9999" focus v-model="search_qry"
                        aria-describedby="basic-addon3" style="border: 1px solid #1a418c;
    border-left: 0px;" />
                </div>
                <!-- <div class="form-group searchbox" style="width:70% !important;">
                    <input type="text" class="form-control" placeholder="Proface ID" v-model="search_qry">
                </div> -->
                <div class="form-group" style="width:30% !important;">
                    <button type="submit" @click="search()" class="btn btn-primary btn-block btn-md" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Search</span></button>
                </div>
            </div>
        </form>
    </div>
    <div class="section inset mt-1" v-if="!loading_list">
        <div class="wide-block pt-2 pb-2">
            <div class="listed-detail mt-0 text-center">
                <h3 class="text-center text-primary">{{ deligate.reg_no }}</h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-1">
                <li>
                    <strong>Name</strong>
                    <span>{{ deligate.name }}</span>
                </li>
                <li>
                    <strong>Mobile</strong>
                    <span>
                        <a :href="'tel:' + deligate.mobile">
                            <ion-icon name="call-outline"></ion-icon>
                            {{ deligate.mobile }}
                        </a>
                    </span>
                </li>
                <li>
                    <strong>Place</strong>
                    <span>{{ deligate.place }},{{ deligate.district_name }}</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="section inset mt-1" v-if="!loading_list && deligate">
        <div class="wide-block pt-2 pb-2">
            <div class="listed-detail mt-0 text-center">
                <h3 class="text-center text-primary">Family</h3>
            </div>
            <ul class="listview flush transparent simple-listview no-space mt-1">
                <li>
                    <strong>Spouse</strong>
                    <span>{{ deligate.spouse_name }}</span>
                </li>
                <li v-if="deligate.spouse_mobile">
                    <strong>Mobile</strong>
                    <span>
                        <a
                            :href="'tel:' + deligate.spouse_mobile">
                            <ion-icon name="call-outline"></ion-icon>
                            {{ deligate.spouse_mobile }}
                        </a>
                    </span>
                </li>
                <li>
                    <strong class="text-primary">Childrens</strong>
                    <span></span>
                </li>
                <li v-for="child in deligate.children" v-bind:key="child.id">
                    <strong>{{ child.name }}</strong>
                    <span>{{ child.age }}y</span>
                </li>
            </ul>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import $ from 'jquery';
import axios from 'axios';
import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
import UserService from "../services/user.service";
export default {
    name: 'Admin List',
    props: {
        unit_id: null
    },
    components: {
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            arrival_confirm: '',
            arrival_time: '',
            saturday_coming: '',
            arrival_mode: '',
            spouse_coming: '',
            food_pref: '',
            child_coming: [],
            remarks: '',
            search_qry: '',
            deligate: {
                deligate: [],
                child: [],
                permissions: []
            },
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        // this.search(true);
        this.$root.updateParent('Proface Get Delegates');
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },

    methods: {
        search(first_load = null, proface_id = null) {
            if (first_load) {
                this.search_qry = 0;
            }
            if (proface_id) {
                this.search_qry = proface_id;
                $('#pendingListModel').modal('hide');
            }
            let data = {
                reg_id : this.search_qry
             };
            this.loading = true;
            UserService.authPostRequest('get-event-candidate', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.deligate = response.data.data.list;
                        // this.arrival_confirm = this.deligate.deligate.arrival_confirm;
                        // this.arrival_mode = this.deligate.deligate.arrival_mode;
                        // this.saturday_coming = this.deligate.deligate.saturday_coming;
                        // this.arrival_time = this.deligate.deligate.arrival_time;
                        // this.spouse_coming = this.deligate.deligate.spouse_coming;
                        // this.food_pref = this.deligate.deligate.food_pref;
                        // this.remarks = this.deligate.deligate.remarks;
                        // if (this.deligate.child.length > 0) {
                        //     this.deligate.child.forEach(element => {
                        //         this.child_coming[element.id] = element.coming;
                        //     });
                        // }
                        this.loading = false;
                        this.search_qry = '';
                    } else {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.deligate = response.data.data;
                        this.loading = false;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        // search(first_load = null, proface_id = null) {
        //     if (first_load) {
        //         this.search_qry = 0;
        //     }
        //     if (proface_id) {
        //         this.search_qry = proface_id;
        //         $('#pendingListModel').modal('hide');
        //     }
        //     this.loading = true;
        //     return axios
        //         .get('https://proface.live/api/get-deligates?proface_id=' + 'PF3-' + this.search_qry + '&&user=' + this.currentUser.name + '&&mobile=' + this.currentUser.phone, {})
        //         .then(response => {
        //             if (response.data.status == 'success') {
        //                 this.deligate = response.data.data;
        //                 this.arrival_confirm = this.deligate.deligate.arrival_confirm;
        //                 this.arrival_mode = this.deligate.deligate.arrival_mode;
        //                 this.saturday_coming = this.deligate.deligate.saturday_coming;
        //                 this.arrival_time = this.deligate.deligate.arrival_time;
        //                 this.spouse_coming = this.deligate.deligate.spouse_coming;
        //                 this.food_pref = this.deligate.deligate.food_pref;
        //                 this.remarks = this.deligate.deligate.remarks;
        //                 if (this.deligate.child.length > 0) {
        //                     this.deligate.child.forEach(element => {
        //                         this.child_coming[element.id] = element.coming;
        //                     });
        //                 }
        //                 this.loading = false;
        //                 this.search_qry = '';
        //             } else {
        //                 this.alert_type = 'Warning';
        //                 this.alert_message = response.data.message;
        //                 this.$refs.alert_model.openModel();
        //                 this.deligate = response.data.data;
        //                 this.loading = false;
        //             }
        //         });
        // },
        saveConfirmation() {
            this.loading = true;
            let data = {
                id: this.deligate.deligate.id,
                arrival_confirm: this.arrival_confirm,
                arrival_mode: this.arrival_mode,
                saturday_coming: this.saturday_coming,
                arrival_time: this.arrival_time,
                spouse_coming: this.spouse_coming,
                child_coming: this.child_coming,
                food_pref: this.food_pref,
                remarks: this.remarks,
                user: this.currentUser.name,
                mobile: this.currentUser.phone
            }
            return axios
                .post('https://proface.live/api/save-deligates-confirmation', data)
                .then(response => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.deligate = response.data.data;
                        this.$refs.alert_model.openModel();
                    } else {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.deligate = response.data.data;
                        this.loading = false;
                    }
                });
        },
        getPendingList() {
            this.loading = true;
            let data = {
                user: this.currentUser.name,
                mobile: this.currentUser.phone
            }
            return axios
                .post('https://proface.live/api/get-pending-deligates', data)
                .then(response => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.deligate = response.data.data;
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                    } else {
                        this.alert_type = 'Warning';
                        this.deligate = response.data.data;
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.deligate = response.data.data;
                        this.loading = false;
                    }
                });
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    margin-bottom: 20px;
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.searchbox .form-control {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #bdbdd5;
    height: 36px;
    padding: 0 10px 0 10px;
}

.form-check {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    min-height: auto;
    height: auto;
    padding-bottom: 5px;
}



.input-group .input-group-text {
    background: transparent;
    border: 0;
    border: 1px solid #1a418c;
    /* border-top: 1px solid #1a418c; */
    border-radius: 5px 5px 5px 5px;
    font-weight: 400;
    color: #27173E;
    height: auto;
    width: auto;
    padding: 0px 10px 0px 10px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}
</style>