<template>
    <!-- App Capsule -->
    <div id="appCapsule">
        <div class="section mb-2 p-2" style="margin-top: -50px">
            <div class="card card-slider">
                <img :src="responseData.donations.image" class="card-img-top" alt="image" />
                <div class="card-body">
                    <section class="singUp-area section-py-120">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-xl-6 col-lg-8">
                                    <div class="singUp-wrap">
                                        <h2 class="title">Donation</h2>
                                        <p></p>
                                        <Form @submit="storeData" :validation-schema="schema" class="account__form" id="donationForm">
                                            <div class="form-group basic">
                                              <div class="input-wrapper">
                                                <label class="label text-primary" for="name">
                                                  Name
                                                  <span class="text-danger">*</span>
                                                </label>
                                                <Field name="name" type="text" class="form-control" v-model="name" placeholder="name" autocomplete="off" />
                                              </div>
                                            </div>
                                            <div class="form-group basic">
                                              <div class="input-wrapper">
                                                <label class="label text-primary" for="mobile">
                                                  Mobile Number
                                                  <span class="text-danger">*</span>
                                                </label>
                                                <Field name="mobile" type="text" class="form-control" v-model="mobile" placeholder="mobile" autocomplete="off" />
                                              </div>
                                            </div>
                                            <div class="form-grp">
                                              <label for="amount">Amount</label>
                                              <div class="amount-options">
                                                <Field type="radio" id="amount-100" name="amount" value="100" v-model="selectedAmount"/>
                                                <label for="amount-100">₹100</label>
                                        
                                                <Field type="radio" id="amount-500" name="amount" value="500" v-model="selectedAmount"/>
                                                <label for="amount-500">₹500</label>
                                        
                                                <Field type="radio" id="amount-1000" name="amount" value="1000" v-model="selectedAmount"/>
                                                <label for="amount-1000">₹1000</label>
                                        
                                                <Field type="radio" id="amount-2000" name="amount" value="2000" v-model="selectedAmount"/>
                                                <label for="amount-2000">₹2000</label>
                                        
                                                <Field type="radio" id="amount-5000" name="amount" value="5000" v-model="selectedAmount"/>
                                                <label for="amount-5000">₹5000</label>
                                        
                                                <Field type="radio" id="amount-other" name="amount" value="other" v-model="selectedAmount"/>
                                                <label for="amount-other">Other</label>
                                              </div>
                                              <!-- <Field v-if="selectedAmount === 'other'" type="number" class="other-amount" id="other-amount" v-model="otherAmount" placeholder="Enter amount" /> -->
                                            </div>
                                            <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                              <span>Donate</span>
                                            </button>
                                          </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <p style="color: black !important;"> ക്യാഷ് അയച്ചവർ <a :href="'http://wa.me/'+responseData.donations.link">{{responseData.donations.link}}</a> നമ്പറിൽ WhatsApp മെസ്സേജ് അയച്ചു റസിപ്റ്റ് നിർബന്ധമായും കൈ പറ്റുമല്ലോ...<br />
                        <a :href="'http://wa.me/'+responseData.donations.link">
                          <button type="button" class="btn btn-success btn-sm me-1 mb-1">
                                    <ion-icon name="logo-whatsapp" role="img" class="md hydrated" aria-label="add outline"></ion-icon>
                                    Whatsapp Now
                          </button></a>
                    </p>
                    <p class="mal-text text-primary">അല്ലാഹുവിന്‍റെ മാര്‍ഗത്തില്‍ തങ്ങളുടെ ധനം ചെലവഴിക്കുന്നവരെ ഉപമിക്കാവുന്നത് ഒരു ധാന്യമണിയോടാകുന്നു. അത് ഏഴ് കതിരുകള്‍ ഉല്‍പാദിപ്പിച്ചു. ഓരോ കതിരിലും നൂറ് ധാന്യമണിയും. അല്ലാഹു താന്‍ ഉദ്ദേശിക്കുന്നവര്‍ക്ക് ഇരട്ടിയായി നല്‍കുന്നു. അല്ലാഹു വിപുലമായ
                        കഴിവുകളുള്ളവനും (എല്ലാം) അറിയുന്നവനുമാണ്‌. (അൽ ബഖറ : 261)</p>
                </div>
            </div>
        </div>
    </div>
    <!-- * Terms Modal -->
    <!-- * App Capsule -->
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import UserService from "../../services/user.service";
import * as yup from "yup";
import { Form, Field } from "vee-validate";
export default {
    name: "Register",
    components: {
        Form,
        Field,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({
            name: yup.string().required("ഫിൽ ചെയ്യുക!"),
            mobile: yup.string().required("ഫിൽ ചെയ്യുക!")
        });
        return {    
            schema,
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            loading: false,
            responseData: {
                donations: []
            },
            name: '',
            mobile: '',
            selectedAmount: '',
            otherAmount: ''
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted() {
        this.$root.updateParent("Loading...");
        this.getDonation();
    },
    methods: {
        getDonation() {
            let data = {
                slug: this.$route.params.slug
            }
            UserService.GetRequest('get-donations?slug=' + this.$route.params.slug, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.$root.updateParent(this.responseData.donations.sub_title);
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
    created() {},
};
</script>

<style scoped>
.error-feedback {
    color: red;
}

#username {
    margin-top: 0px;
}

.label {
    margin-left: -10px;
    margin-bottom: 0px;
}

.forget {
    font-size: 12px !important;
    text-decoration: underline;
}

.title-reg {
    font-size: 18px;
}

.amount-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.amount-options label {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.amount-options input[type="radio"] {
    display: none;
}

.amount-options input[type="radio"]:checked+label {
    background: #50b487;
    color: #fff;
    border-color: #50b487;
}

.other-amount {
    display: none;
    margin-top: 10px;
}

.amount-options input[type="radio"]:checked+.other-amount {
    display: block;
}
</style>
