<template>
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section mb-2 mt-1">
        <div class="transactions">
            <a v-for="button in button_list" v-bind:key="button.name" :href="button.url" class="item">
                <div class="detail">
                    <div class="price text-primary" style="font-size: 40px;"><ion-icon :name="button.icon ? button.icon :'radio-button-off-outline'"></ion-icon></div>
                    <div class="pl-2">
                        <strong class="text-primary">{{ button.name }}</strong>
                        <p class="mal-text">{{ button.description ? button.description : 'പേജിലേക്ക് പോകാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക'
                        }}</p>
                    </div>
                </div>
                <div class="right">
                    <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                </div>
            </a>
        </div>
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>
<script>
import $ from "jquery";
import UserService from "../../services/user.service";
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null,
    },
    components: {
        AlertModel,
        DialogModel,
    },
    data() {
        return {
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],

            loading: false,
            button_list: [],
            permissions: [],
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        }
        this.$root.updateParent("Executives");
        this.getbuttons();
        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },

    methods: {
        getbuttons() {
            this.loading = true;
            let data = {
                user_level: this.$route.params.sub_level,
                user_level_id: this.$route.params.id,
            };
            UserService.authPostRequest(`get-campus-executive-dashboard`, data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.button_list = response.data.data.buttons;
                        this.permissions = response.data.data.permissions;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.transactions .item {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 5px 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price {
    font-size: 50px;
}
</style>
  