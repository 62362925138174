<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-3">
            <h3 class="text-center mt-2 mal-text custom-title">{{report_header}}</h3>
        </div>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2" v-if="!sub_levels">
        <div class="card">
            <div class="listed-detail mt-o">
                <h3 class="text-center mt-0 mal-text custom-title text-danger">ഷെഡ്യൂൾ ചെയ്തിട്ടില്ല</h3>
            </div>
        </div>
    </div>
    <div class="section mt-2 mb-2" v-if="election && !loading_list && sub_levels">
        <div class="card">
            <div class="listed-detail mt-3">
                <h3 class="text-center mt-2 mal-text custom-title">{{ $route.params.sub_level == 2 ? 'ജില്ല' : 'മണ്ഡലം' }} ഇലക്ഷൻ ഷെഡ്യൂൾ</h3>
            </div>

            <ul class="listview flush transparent simple-listview no-space mt-3 election-schedule">
                <li>
                    <strong>തിയതി</strong>
                    <span class="text-success">{{sub_levels.election_date}}</span>
                </li>
                <li>
                    <strong>സമയം</strong>
                    <span>{{sub_levels.election_time}}</span>
                </li>
                <li>
                    <strong>സ്ഥലം</strong>
                    <span>{{sub_levels.election_location}}</span>
                </li>
                <li>
                    <strong class="mal-text custom-title">പ്രിസൈഡിങ് ഓഫീസറുടെ പേര്</strong>
                </li>
                <li v-if="units_count.wisdom_count >= 3">
                    <strong>വിസ്‌ഡം</strong>
                    <span class="text-end">{{sub_levels.wisdom_preside_officer}}
                        <br>{{election.wisdom?election.wisdom.zone?election.wisdom.zone.name:'':''}},{{election.wisdom?election.wisdom.unit?election.wisdom.unit.name:'':''}}
                        <br>{{sub_levels.wisdom_preside_mobile}}</span>
                </li>
                <li v-if="units_count.youth_count >= 3">
                    <strong>യൂത്ത്</strong>
                    
                    <span class="text-end">{{sub_levels.youth_preside_officer}}
                        <br>{{election.youth?election.youth.zone?election.youth.zone.name:'':''}},{{election.youth?election.youth.unit?election.youth.unit.name:'':''}}
                        <br>{{sub_levels.youth_preside_mobile}}</span>
                </li>
                <li v-if="units_count.students_count >= 3">
                    <strong>സ്റ്റുഡന്റ്‌സ്</strong>
                    <span class="text-end">{{sub_levels.students_preside_officer}}
                        <br>{{election.students?election.students.zone?election.students.zone.name:'':''}},{{election.students?election.students.unit?election.students.unit.name:'':''}}
                        <br>{{sub_levels.students_preside_mobile}}</span>
                </li>
                <li v-if="units_count.women_count >= 3">
                    <strong>വിമൺ</strong>
                    <span class="text-end">{{sub_levels.women_preside_officer}}
                        <br>{{election.women?election.women.zone?election.women.zone.name:'':''}},{{election.women?election.women.unit?election.women.unit.name:'':''}}
                        <br>{{sub_levels.women_preside_mobile}}</span>
                </li>
                <li v-if="units_count.girls_count >= 3">
                    <strong>ഗേൾസ്</strong>
                    <span class="text-end">{{sub_levels.girls_preside_officer}}
                        <br>{{election.girls?election.girls.zone?election.girls.zone.name:'':''}},{{election.girls?election.girls.unit?election.girls.unit.name:'':''}}
                        <br>{{sub_levels.girls_preside_mobile}}</span>
                </li>
                <li>
                    <strong class="mal-text custom-title">സംസ്ഥാന പ്രതിനിധികൾ</strong>
                </li>
                <li>
                    <strong>സംസ്ഥാന പ്രതിനി 1</strong>
                    <span class="text-end">{{sub_levels.state_1_officer}}
                        <br>{{election.state_1?election.state_1.zone?election.state_1.zone.name:'':''}},{{election.state_1?election.state_1.unit?election.state_1.unit.name:'':''}}
                        <br>{{sub_levels.state_1_preside_mobile}}</span>
                </li>
                <li>
                    <strong>സംസ്ഥാന പ്രതിനി 2</strong>
                    <span class="text-end">{{sub_levels.state_2_officer}}
                        <br>{{election.state_2?election.state_2.zone?election.state_2.zone.name:'':''}},{{election.state_2?election.state_2.unit?election.state_2.unit.name:'':''}}
                        <br>{{sub_levels.state_2_preside_mobile}}</span>
                </li>
                <li>
                    <strong>സംസ്ഥാന പ്രതിനി 3</strong>
                    <span class="text-end">{{sub_levels.state_3_officer}}
                        <br>{{election.state_3?election.state_3.zone?election.state_3.zone.name:'':''}},{{election.state_3?election.state_3.unit?election.state_3.unit.name:'':''}}
                        <br>{{sub_levels.state_3_preside_mobile}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
export default {
    name: 'Reports',
    components: {
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            mobile_number: '',
            wing: '',
            user_level: "",
            sub_levels: [],
            admin_lists: [],
            unit: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            district: '',
            total: 0.00,
            total_units: 0.00,
            election: [],
            unit_permission: '',
            units_count : []

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getReport();
            if (this.user_level == this.currentUser.user_level) {
                this.editable = false;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('Reports');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getReport() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level,
                sub_level_id: parseInt(this.$route.params.id),
            }
            UserService.authPostRequest('get-election-schedule', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.total_units = response.data.data.total_unit;
                        if(response.data.data.list){
                            this.election = response.data.data.list.data;
                        }else{
                            this.election == null;
                        }
                        this.unit = response.data.data.sub_level_type_value;
                        this.units_count = this.unit.sub_level_counts;
                        this.report_header = this.unit.name + (this.$route.params.sub_level == 2? ' ജില്ല' : ' മണ്ഡലം');
                        let title = this.$route.params.sub_level == 2? 'ജില്ല' : ' മണ്ഡലം';
                        this.$root.updateParent(title + ' ഇലക്ഷൻ ഷെഡ്യൂൾ');

                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.input-info {
    color: red !important;
}

.list-title {
    font-size: 16px;
}

.footer_div {
    display: inline-block;
    margin-left: 3px;
}

.first_span {
    margin-right: 5px;
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.font-dark {
    font-weight: 500;
}

.total-size {
    font-size: 18px;
}

.report-btn-success {
    width: 100%;
}

.icon-size20 {
    font-size: 20px;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #1a418c;
    opacity: 100%;
}

.btn-accept-reject {
    height: 50px;
}

.election-schedule {
    padding: 10px !important;
}
</style>