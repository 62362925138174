<!-- My Home pafe -->
<template>
  <div class="section text-center" v-if="loading">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
  <div class="home" v-if="!loading">
    <div class="section wallet-card-section mb-2" v-if="banners.length">
      <!-- <carousel :items-to-show="1">
        <slide v-for="banner in banners" :key="banner" autoplay="1500">
          <div class="card card-slider">
            <img :src="'https://guide.msoftit.com/' + banner.image" class="card-img-top" alt="image">
            <div class="card-body">
              <h5 class="card-title mal-text">{{ banner.title }}
              </h5>
              <p class="card-text mal-text" v-html="banner.content">
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <pagination />
        </template>
      </carousel> -->

      <Flicking :options="{ renderOnlyVisible: true, panelsPerView: 1, align: 'prev', circular: true }" :plugins="pluginsBanner">
        <div class="card-panel mt-2" v-for="banner in banners" :key="banner">
          <div class="card card-slider">
            <img :src="'https://guide.msoftit.com/' + banner.image" class="card-img-top" alt="image">
            <!-- <div class="card-body">
              <h5 class="card-title mal-text">{{ banner.title }}
              </h5>
              <p class="card-text mal-text" v-html="banner.content">
              </p>
            </div> -->
          </div>
        </div>
      </Flicking>
    </div>
    <div class="section pb-2" v-if="data.important_links.length">
      <!-- <div class="section-heading">
        <h2 class="title">Important Links</h2>
      </div> -->
      <div class="transactions">
        <a v-for="important_links in data.important_links" v-bind:key="important_links" :href="important_links.link+(important_links.link_text == 'unit_id' ? this.currentUser.unit_id : '')"
          class="item">
          <div class="detail">
            <img :src="important_links.image" alt="img" class="image-block imaged w48">
            <div>
              <strong class="mal-text">{{ important_links.title }}</strong>
              <p class="mal-text">{{ important_links.desc }}</p>
            </div>
          </div>
          <div class="right">
            <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
          </div>
        </a>
      </div>
    </div>

    <div class="section pb-2" v-if="reg_model_forms.length && !loading">
      <div class="section-heading">
        <h2 class="title">Registration Links</h2>
        <!-- <a href="javascript:void(0)" class="link">View All</a> -->
      </div>
      <div class="wrapper">
        <Flicking
          :options="{ renderOnlyVisible: true, align: 'prev', panelsPerView: data.reg_links_per_page ? data.reg_links_per_page : 2, circular: true }">
          <div class="bill-box" v-for="reg_model_form in reg_model_forms" v-bind:key="reg_model_form">
            <div class="img-wrapper" v-bind:class="reg_model_form.button_icon_color" v-if="!reg_model_form.button_image">
              <ion-icon :name="reg_model_form.button_icon"></ion-icon>
            </div>
            <div class="img-wrapper" v-if="reg_model_form.button_image">
              <img :src="reg_model_form.button_image" alt="img" class="image-block imaged w48">
            </div>
            <div class="price" v-bind:class="reg_model_form.form_heading_color" v-html="reg_model_form.form_heading">
            </div>
            <p v-bind:class="reg_model_form.title_color">{{ reg_model_form.title }}</p>
            <a href="javascript:void(0)" v-if="reg_model_form.form_button_status" @click="openFormModel(reg_model_form)"
              class="btn btn-block btn-sm" v-bind:class="reg_model_form.button_color">{{ reg_model_form.button_text }}</a>
          </div>
        </Flicking>
      </div>
    </div>
    <div class="section pt-0" v-if="data.news.length && !loading">
      <div class="section-heading">
        <h2 class="title">Lastest News</h2>
        <!-- <a href="javascript:void(0)" class="link">View All</a> -->
      </div>
      <div class="wrapper">
        <Flicking :options="{ renderOnlyVisible: true, align: 'prev', panelsPerView: 1, circular: true }"
          :plugins="pluginsNews">
          <div class="blog-card full-width" v-for="news in data.news" v-bind:key="news">
            <img :src="news.image" alt="image" class="imaged w-100 h-150">
            <div class="text">
              <router-link :to="'news/' + news.slug">
                <h5 class="title mal-text">{{ truncateText(news.title, 60) }}</h5>
                <a href="javascript:void(0)" class="link">Read More</a>
              </router-link>
            </div>
          </div>
        </Flicking>
      </div>
    </div>
    <div class="section pt-2" v-if="data.apps.length && !loading">
      <div class="section-heading">
        <h2 class="title">Apps & Links</h2>
        <!-- <a href="javascript:void(0)" class="link">View All</a> -->
      </div>
      <div class="wrapper">
        <Flicking :options="{ renderOnlyVisible: true, align: 'prev', panelsPerView: 2, circular: true }"
          :plugins="pluginsApps">
          <div class="bill-box" v-for="app in data.apps" v-bind:key="app">
            <div class="img-wrapper">
              <img :src="app.image" alt="img" class="image-block imaged w48">
            </div>
            <div class="price">{{ app.title }}</div>
            <p>{{ app.desc }}</p>
            <a :href="app.link" target="_blank" class="btn btn-primary btn-block btn-sm">{{ app.btn_text }}</a>
          </div>
        </Flicking>
      </div>
    </div>
    <div class="section mt-2 mb-1" v-if="data.social_links.length && !loading">
      <div class="card">
        <div class="card-body">
          <div class="p-1">
            <div class="text-center">
              <h2 class="text-primary">Social Profiles</h2>
              <a v-for="social in data.social_links" v-bind:key="social" :href="social.link" target="_blank"
                class="btn btn-icon me-05" v-bind:class="'btn-' + social.name">
                <ion-icon :name="'logo-' + social.name"></ion-icon>
              </a>

              <a href="https://www.youtube.com/@WisdomGlobalTV" target="_blank" class="btn btn-youtube btn-icon me-05" >
                <ion-icon name="logo-youtube"></ion-icon>
              </a>

             <!-- <a href="#" class="btn btn-twitter btn-icon me-05" >
                <ion-icon name="logo-twitter"></ion-icon>
              </a>

              <a href="#" class="btn btn-linkedin btn-icon me-05">
                <ion-icon name="logo-linkedin"></ion-icon>
              </a>

              <a href="#" class="btn btn-whatsapp btn-icon me-05">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>

              <a href="#" class="btn btn-instagram btn-icon me-05">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>

              <a href="#" class="btn btn-twitch btn-icon me-05">
                <ion-icon name="logo-twitch"></ion-icon>
              </a> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>
<script>
import UserService from "../services/user.service";
import 'vue3-carousel/dist/carousel.css';
// import { Carousel, Slide, Pagination } from 'vue3-carousel';
// import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import $ from 'jquery';
import 'vue-lite-youtube-embed/dist/style.css'

import AlertModel from "./layouts/AlertModel.vue";
import DialogModel from "./layouts/DialogModel.vue";
import * as yup from "yup";
import { AutoPlay } from "@egjs/flicking-plugins";

export default {
  data() {
    const schema = yup.object().shape({
      name: yup.string().required('ഫിൽ ചെയ്യുക!'),
      age: yup.string().required('ഫിൽ ചെയ്യുക!'),
      gender: yup.string().required('സെലക്റ്റ് ചെയ്യുക!'),
    });
    const pluginsBanner = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
    const pluginsApps = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
    const pluginsNews = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];
    return {
      pluginsBanner,
      pluginsApps,
      pluginsNews,
      sub_level_list_name: '',
      user_level: '',
      sub_levels: [],
      unit: [],
      user: [],
      schema,
      banners: [],
      country_code_list: [],
      code: '91',
      total_unit: 0.00,
      total_scheduled: 0.00,
      permissions: [],
      reg_model_forms: [],
      form_permissions: [],
      reg_model_title: '',
      reg_model_heading: '',
      reg_model_extra_fields: [],
      reg_model_button_text: '',
      reg_model_button_status: true,
      members: [],
      series: [0, 0],
      chartOptions: {
        chart: {
          width: '100%',
          type: 'pie',
        },
        colors: ['#64b167', '#db7491'],
        labels: ['Total Units', 'Total Unit Scheduled'],
        responsive: [{
          breakpoint: 300,
          options: {
            chart: {
              width: '100%'
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      form_name: '',
      members_selected: [],
      new_reg: false,
      alert_type: '',
      alert_message: '',
      dialog_message: '',
      dialog_action: '',
      dialog_action_params: [],
      data: {
        news: [],
        apps: [],
        social_links: [],
        important_links: []
      }

    };
  },
  components: {
    // Carousel,
    // Slide,
    // Pagination,
    // LiteYouTubeEmbed,
    AlertModel,
    DialogModel
  },
  mounted() {
    localStorage.activeMenu = 'Home';
    if (!this.currentUser) {
      localStorage.removeItem("user");
      this.$router.push('login');
      // this.$router.push('user-login');
    } else {
      this.user_level = this.currentUser.user_level;
      // if (this.user_level == 1) {
      //   this.sub_level_list_name = 'District';
      //   this.user_level_id = 0;
      // } else if (this.user_level == 2) {
      //   this.sub_level_list_name = 'Zone';
      //   this.user_level_id = this.currentUser.district_id;
      // }
      // else if (this.user_level == 3) {
      //   this.sub_level_list_name = 'Unit';
      //   this.user_level_id = this.currentUser.zone_id;
      // }
      this.loading = true;
      this.getHomeContents();
      // this.getComboboxValues('COUNTRY_CODE');
      // if (this.user_level < 6) {
      //   this.getSubLevels();
      // }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    totalItem() {
      let members_sum = 0;
      let members_filled_sum = 0;

      let units_sum = 0;
      let units_scheduled_sum = 0;
      let units_election_sum = 0;

      this.sub_levels.forEach(element => {
        units_sum += element.units_count;
        units_scheduled_sum += element.elec_scheduled_units_count;
        units_election_sum += element.unit_election_completed_count;

        members_sum += element.members_count;
        members_filled_sum += element.members_count_filled;

      });
      let data = {
        members_sum: members_sum,
        members_filled_sum: members_filled_sum,
        units_sum: units_sum,
        units_scheduled_sum: units_scheduled_sum,
        units_election_sum: units_election_sum
      }
      return data;
    }
  },
  methods: {
    getComboboxValues(code, id = null) {
      var data = {
        code: code,
        type_id: id
      }
      UserService.getCombobox(data).then(
        (response) => {
          switch (code) {
            case 'COUNTRY_CODE':
              this.country_code_list = response.data.data.list.options;
              this.country_code = '91';
              break;
            default:
              break;
          }

        },
        (error) => {
          this.loading = false;
          this.messafe = (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    updateChart() {
      this.chartOptions = {
        chart: {
          type: 'pie'
        },
        colors: ['#64b167', '#db7491'],
        labels: ['Units Scheduled(' + this.totalItem.units_scheduled_sum + ')', 'Units Not Scheduled(' + (this.totalItem.units_sum - this.totalItem.units_scheduled_sum) + ')'],
        responsive: [{
          breakpoint: this.totalItem.units_sum,
          options: {
            chart: {
              width: '100%'
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
      // In the same way, update the series option
      this.series = [this.totalItem.units_scheduled_sum, (parseFloat(this.totalItem.units_sum) - parseFloat(this.totalItem.units_scheduled_sum))]
    },
    getHomeContents() {
      this.loading = true;
      let data = {
      }
      let fcm = localStorage.getItem('fcm')
      UserService.authPostRequest('get-home-contents?fcm='+fcm, data).then(
        (response) => {
          if (response.data.status == 'success') {
            this.data = response.data.data;
            this.user = response.data.data.user;
            this.banners = response.data.data.banners;
            this.permissions = response.data.data.permissions;
            this.reg_model_forms = response.data.data.forms;
            this.members = response.data.data.members;
            this.members.forEach(element => {
              if (element.qhls_reg == 1) {
                this.members_selected.push(element.id);
              }
            });
            if (response.data.data.user) {
              localStorage.setItem('user', JSON.stringify(response.data.data.user));
            }
            this.loading = false;

          } else {
            this.loading = true;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (this.message == 'Unauthenticated.') {
            localStorage.removeItem("user");
            location.replace('/');
          }
        }
      );
    },
    getSubLevels() {
      this.loading = true;
      if (this.user_level == 2) {
        this.currentUser.district_id;
      } else if (this.user_level == 3) {
        this.currentUser.zone_id;
      } else if (this.user_level == 4) {
        this.currentUser.unit_id;
      }
      let data = {
        sub_level_type: this.user_level + 1,
        id: this.user_level_id
      }
      UserService.getSubLevels('get-sub-levels', data).then(
        (response) => {
          if (response.data.status == 'success') {
            this.loading = false;
            this.sub_levels = response.data.data.list;
            this.unit = response.data.data.unit;
            this.$root.updateParent(response.data.data.page_title);
            this.updateChart();
          } else {
            this.loading = true;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (this.message == 'Unauthenticated.') {
            localStorage.removeItem("user");
            location.replace('/');
          }
        }
      );
    },
    openFormModel(form) {
      this.$router.push('events/' + form.event_type);
      // this.new_reg = false;
      // this.form_permissions = form.permissions;
      // this.new_reg_text = form.new_reg_text?form.new_reg_text:'പുതിയ ഒരാളെ രജിസ്റ്റർ ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക.';
      // this.reg_model_heading = form.form_heading;
      // this.reg_model_title = form.form_title;
      // this.reg_model_button_text = form.form_button_text;
      // this.form_name = form.form_name;
      // this.reg_model_button_status = form.form_button_status;
      // $('#regFormModel').modal('show');
    },
    closeModel() {
      $('#regFormModel').modal('hide');
    },
    modelAction(reg_data = null) {
      if (this.members_selected.length == 0 && !this.new_Reg) {
        this.alert_type = 'Warning';
        this.alert_message = 'പേര് ടിക്ക് ചെയ്തതിനു ശേഷം സേവ് ചെയ്യുക.';
        this.$refs.alert_model.openModel();
        this.loading = false;
      } else {
        var data = {
          new_reg: this.new_reg,
          user_type: this.user.user_type,
          form_name: this.form_name,
          members: this.members_selected,
          new: {
            name: reg_data.name,
            code: reg_data.code,
            mobile: reg_data.mobile,
            gender: reg_data.gender,
            age: reg_data.age,

          }
        };
        UserService.addAdmin('auto-form-submit', data).then(
          (response) => {
            if (response.data.status == 'success') {
              this.alert_type = 'Success';
              this.members = response.data.members;
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.loading = false;
              this.message = '';
              $('#regFormModel').modal('hide');
            } else if (response.data.status == 'warning') {
              this.alert_type = 'Warning';
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.loading = false;
            } else if (response.data.status == 'error') {
              this.alert_type = 'Error';
              this.alert_message = response.data.message;
              this.$refs.alert_model.openModel();
              this.loading = false;
            } else {
              this.loading = false;
            }
          },
          (error) => {
            this.alert_type = 'Error';
            this.alert_message = (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString();
            this.$refs.alert_model.openModel();
            this.loading = false;
          }
        );
      }
    },
    truncateText(text, limit) {
      if (typeof text !== 'string') return '';
      if (text.length > limit) {
        return text.slice(0, limit) + (text.length > limit ? '...' : '');
      } else {
        return text;
      }
    },
  },

};
</script>
<style scoped>
.blue-head {
  color: blue;
}

.carousel__slide {
  width: 100% !important;
}



.card-title {
  font-weight: 600 !important;
}

th {
  padding: 5px !important;
  font-size: 12px;
}

td {
  padding: 5px !important;
}

.wallet-card .wallet-footer .item .icon-wrapper {
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  border-radius: 10px;
  color: #fff;
  /* font-size: 28px; */
  margin-bottom: 14px;
  padding-left: 10px;
}

.menu-icon {
  font-size: 32px !important;
}

.menu-title {
  color: #fff !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  text-align: left !important;
}

.btn-cancel {
  left: 4% !important;
}

.forget {
  font-size: 12px !important;
  text-decoration: underline;
}

.error-feedback {
  color: red;
}

.form_result_list {
  margin-left: 0px !important;
  margin-right: 0px !important;
  overflow: hidden;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
  font-size: 12px !important;
}

.list-item-text {
  font-size: 14px !important;
  ;
}

.sl-no {
  font-weight: 600;
  font-size: 16px;
}

.card .card-img-top {
  border-radius: 10px 10px 10px 10px;
}

.blog-card,
.bill-box {
  width: 50%;
  margin-left: 20px;
}

.full-width {
  width: 100% !important;
}

.bill-box p {
  font-size: 12px;
  min-height: 40px;
}

.h-150 {
  height: 180px !important;
}

.bill-box .price {
  font-size: 20px;
  font-weight: 700;
  color: #27173E;
  letter-spacing: -0.02em;
  line-height: 1em;
  margin-bottom: 12px;
  min-height: 40px;
}

.blog-card .title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  height: 50px;
  overflow: hidden;
}

.blog-card .text {
  padding: 5px 5px;
}

.stat-box {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
  border-radius: 10px;
  padding: 10px 20px;
}

.transactions .item {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  padding: 5px 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  font-size: 50px;
}</style>