<template>
    <div class="section text-center pt-1" v-if="loading_list">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section mt-1">
        <div class="section-title text-center text-danger">27th <br> Professional Students
            Global Conference
            <br>2023 SEP 8,9,10 KOZHIKKODE
        </div>
        <div class="text-center text-primary">Registration</div>
        <div class="card">
            <div class="card-body">
                <!-- <div class="mb-05">
                    Type something to activate animation.
                </div> -->
                <Form @submit="addAdmin" :validation-schema="schema">
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="name">Name <span class="text-danger">*</span></label>
                            <Field type="text" class="form-control" name="name" placeholder="Name" v-model="name"
                                required />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="name" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="district">District <span
                                    class="text-danger">*</span></label>
                            <Field as="select" class="form-control custom-select" v-model="district" name="district"
                                id="select4">
                                <option value="" selected>---Select---</option>
                                <!-- <option v-for="district in district_list" v-bind:key="district.id" :value="district.id">
                                    {{ district.name }}</option> -->
                                <option v-for="district in campus_district_list" v-bind:key="district.id"
                                    v-bind:value="district.item_value">
                                    {{ district.name }}
                                </option>
                            </Field>
                        </div>
                        <ErrorMessage name="district" class="error-feedback" />
                    </div>
                    <!-- <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="zone">Area <span class="mal-text"></span></label>
                            <Field as="select" class="form-control custom-select" v-model="zone" name="zone" id="select4"
                                @change="getUnitList($event.target.value)">
                                <option value="" selected>---Select---</option>
                                <option v-for="zone in zone_list" v-bind:key="zone.id" :value="zone.id">
                                    {{ zone.name }}</option>
                            </Field>
                        </div>
                        <ErrorMessage name="zone" class="error-feedback" />
                    </div> -->
                    <!-- <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="unit">Unit <span class="mal-text"></span></label>
                            <Field as="select" class="form-control custom-select" v-model="unit" name="unit" id="select4">
                                <option value="" selected>---Select---</option>
                                <option v-for="unit in unit_list" v-bind:key="unit.id" :value="unit.id">
                                    {{ unit.name }}</option>
            
                            </Field>
                        </div>
                        <ErrorMessage name="unit" class="error-feedback" />
                    </div> -->
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="gender">Sex <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" name="gender" id="gender"
                                        v-model="gander" required>
                                        <option value="" selected>---Select---</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="gender" class="error-feedback" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="mobile">Mobile <span class="text-danger">*</span></label>
                            <Field type="text" class="form-control" id="mobile" name="mobile" placeholder="Mobile"
                                v-model="mobile"
                                oninput="javascript: if (this.value.length > 13) this.value = this.value.slice(0, 13);" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="mobile" class="error-feedback" />
                    </div>

                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="campus_district">Campus District <span
                                    class="text-danger">*</span></label>
                            <Field as="select" class="form-control custom-select" id="campus_district"
                                name="campus_district" v-model="campus_district" @change="getCollegeList">
                                <option value="" selected>---Select---</option>
                                <option v-for="district in campus_district_list" v-bind:key="district.id"
                                    v-bind:value="district.item_value">
                                    {{ district.name }}
                                </option>
                            </Field>
                        </div>
                        <ErrorMessage name="campus_district" class="error-feedback" />
                    </div>
                    <div class="form-group basic" v-if="campus_district == 'others'">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="other_district">Enter Place Name</label>
                            <Field type="text" class="form-control" name="other_district" placeholder=""
                                v-model="other_district" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="other_district" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="course_type">Course Type <span
                                    class="text-danger">*</span></label>
                            <Field as="select" class="form-control custom-select" id="course_type" name="course_type"
                                v-model="course_type" @change="changeCourseType($event.target.value);">
                                <option value="" selected>---Select---</option>
                                <option v-for="course_type in course_type_list" v-bind:key="course_type.id"
                                    v-bind:value="course_type.id">
                                    {{ course_type.name }}
                                </option>
                            </Field>
                        </div>
                        <ErrorMessage name="course_type" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="campus">College <span
                                    class="text-danger">*</span></label>
                            <Field as="select" class="form-control custom-select" v-model="campus" name="campus"
                                id="select4">
                                <option value="" selected>---Select---</option>
                                <option v-for="campus in campus_list" v-bind:key="campus.id" v-bind:value="campus.id">
                                    {{ campus.name }}
                                </option>
                                <option value="others">Others</option>
                            </Field>
                        </div>
                        <ErrorMessage name="campus" class="error-feedback" />
                    </div>
                    <div class="form-group basic" v-if="campus == 'others'">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="other_college">Enter College Name</label>
                            <Field type="text" class="form-control" name="other_college" placeholder=""
                                v-model="other_college" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="other_college" class="error-feedback" />
                    </div>
                    <div class="form-group basic">

                        <div class="input-wrapper">
                            <label class="label text-primary" for="course">Course <span class="text-danger">*</span></label>
                            <Field as="select" class="form-control custom-select" v-model="course" name="course"
                                id="select4">
                                <option value="" selected>---Select---</option>
                                <option v-for="course in course_list" v-bind:key="course.id" v-bind:value="course.id">
                                    {{ course.name }}
                                </option>
                                <option value="others">Others</option>
                            </Field>
                        </div>
                        <ErrorMessage name="course" class="error-feedback" />
                    </div>
                    <div class="form-group basic" v-if="course == 'others'">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="other_course">Enter Course Name</label>
                            <Field type="text" class="form-control" name="other_course" placeholder=""
                                v-model="other_course" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <ErrorMessage name="other_course" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="year_of_study">Year of Study</label>
                            <Field as="select" class="form-control custom-select" id="year_of_study" name="year_of_study"
                                v-model="year_of_study">
                                <option value="" selected>---Select---</option>
                                <option v-for="year_of_study in year_of_study_list" v-bind:key="year_of_study.id"
                                    v-bind:value="year_of_study.id">
                                    {{ year_of_study.name }}
                                </option>
                            </Field>
                        </div>
                        <ErrorMessage name="course_type" class="error-feedback" />
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="year_of_completion">Year of course
                                completion</label>
                            <Field as="select" class="form-control custom-select" id="year_of_completion"
                                name="year_of_completion" v-model="year_of_completion">
                                <option value="" selected>---Select---</option>
                                <option v-for="year_of_completion in year_of_completion_list"
                                    v-bind:key="year_of_completion.id" v-bind:value="year_of_completion.id">
                                    {{ year_of_completion.name }}
                                </option>
                            </Field>
                        </div>
                        <ErrorMessage name="course_type" class="error-feedback" />
                    </div>


                    <div class="form-group" v-if="message">
                        <div v-if="message" class="error-feedback">
                            {{ message }}
                        </div>
                    </div>

                    <div class="form-group basic">
                        <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                            <span>Submit</span></button>
                    </div>
                </Form>
            </div>
        </div>
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
// import * as yup from "yup";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        //     const schema_non_member = yup.object().shape({
        //         name: yup.string().when("isMember", {
        //     is: 'N',
        //     then: yup.string().required('ഫിൽ ചെയ്യുക!')
        //   }),
        //         age: yup.string().required('ഫിൽ ചെയ്യുക!'),
        //         gender: yup.string().required('സെലക്റ്റ് ചെയ്യുക!'),
        //     });
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            // schema_non_member,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            responseData: {
                event: [],
                questians: [],
                permissions: []
            },
            questians_responses: [],

            isYourZonal: '',
            district_list: [],
            campus_district_list: [],
            zone_list: [],
            unit_list: [],
            district: '',
            zone: '',
            unit: '',

            campus_district: '',
            other_district: '',
            campus_list: [],
            campus: '',
            other_college: '',
            course_list: [],
            course: '',
            other_course: '',
            course_type_list: [],
            course_type: '',

            filter_district: '',
            filter_zone: '',
            filter_unit: '',
            year_of_completion: '',
            year_of_study: '',
            currentAdminLevel: '',
            year_of_completion_list: [
                {
                    id: 2023,
                    name: '2023'
                },
                {
                    id: 2024,
                    name: '2024'
                },
                {
                    id: 2025,
                    name: '2025'
                },
                {
                    id: 2026,
                    name: '2026'
                },
                {
                    id: 2027,
                    name: '2027'
                },
                {
                    id: 2028,
                    name: '2028'
                },
                {
                    id: 2029,
                    name: '2029'
                }
            ],
            year_of_study_list: [
                {
                    id: 1,
                    name: '1'
                },
                {
                    id: 2,
                    name: '2'
                },
                {
                    id: 3,
                    name: '3'
                },
                {
                    id: 4,
                    name: '4'
                },
                {
                    id: 5,
                    name: '5'
                },
                {
                    id: 6,
                    name: '6'
                },
            ]

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        this.$root.updateParent('Wisdom Students Presents');
        this.getComboboxValues('COUNTRY_CODE');
        this.getComboboxValues("PROFCON_COURSE_TYPE");
        this.getComboboxValues("DISTRICT_KERALA");
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case "DISTRICT_KERALA":
                            this.campus_district_list = response.data.data.list.options;
                            break;
                        case "PROFCON_COURSES":
                            this.course_list = response.data.data.list.options;
                            break;
                        case "PROFCON_COURSE_TYPE":
                            this.course_type_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        addAdmin(user) {
            user.event_type = this.$route.params.slug;

            UserService.PostRequest('e-event-registration', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        setTimeout(() => {
                            location.replace('/');
                        }, 2000);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.loading = false;

                }
            );
        },
        getDistrictList() {
            // this.loading_list = true;
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getZoneList(district_id) {
            // this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getUnitList(zone_id) {
            // this.loading_list = true;
            let data = {
                zone_id: zone_id
            }
            UserService.authPostRequest(`get-unit-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.unit_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeCourseType(item) {
            this.getCollegeList();
            this.getComboboxValues("PROFCON_COURSES", item);
        },
        getCollegeList() {
            let data = {
                district_id: this.campus_district ? this.campus_district : '',
                stream_id: this.course_type ? this.course_type : '',
            }
            UserService.GetRequest(`get-from-guide/campus_list?district_id=` + data.district_id + `&&stream_id=` + data.stream_id, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.campus_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clearFilter() {
            this.filter_district = '';
            this.filter_zone = '';
            this.filter_unit = '';
            this.getMembersList();
        }
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.questians-label {
    font-weight: 600 !important;
    font-size: 14px !important;

}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}
</style>