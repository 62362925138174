import { createWebHashHistory, createRouter } from "vue-router";
import Welcome from "./views/Welcome.vue";
import Home from "./views/MyHome.vue";
// import Login from '@/views/Login.vue'
import Notifications from "@/views/notification/NotificationList.vue";
import Register from "@/views/auth/Register.vue";
import LoginBeta from "@/views/auth/Login.vue";
import MyProfile from '@/views/MyProfile.vue'
import BioData from '@/views/biodata/BioData.vue'
import BasicInfo from '@/views/biodata/BasicInfo.vue'
import EducationInfo from '@/views/biodata/EducationInfo.vue'
import JobInfo from '@/views/biodata/JobInfo.vue'
import LanguageInfo from '@/views/biodata/LanguageInfo.vue'
import Contribute from '@/views/biodata/ExtraActivityInfo.vue'
import Disability from '@/views/biodata/DisabilityInfo.vue'
import HelpDesk from '@/views/HelpDesk.vue'
import Executives from '@/views/org/ExecutiveDashboard.vue'
import CampusExecutives from '@/views/campus/ExecutiveDashboard.vue'
import Org from '@/views/Org.vue'
import OrgAdminList from '@/views/OrgAdminList.vue'
import Level2Org from '@/views/Level2Org.vue'
import OtpVerify from '@/views/OtpVerify.vue'
import SetPassword from '@/views/SetPassword.vue'
import ReportDashboard from '@/views/reports/ReportDashboard.vue'
import UnitAdminReport from '@/views/reports/UnitAdminReport.vue'
import ConsolidatedSheet from '@/views/reports/ConsolidatedMembersReport.vue'
import ZoneConsolidatedSheet from '@/views/reports/ConsolidatedUnitReport.vue'
import SubLevelElectionConsolidatedReport from '@/views/reports/SubLevelElectionConsolidatedReport.vue'
import ScheduleElection from '@/views/election/ScheduleElection.vue'
import SubLevelScheduleElection from '@/views/election/SubLevelScheduleElection.vue'
import OrgExcecutiveList from '@/views/OrgExcecutiveList.vue'
import ElectionScheduleDetails from '@/views/election/ElectionScheduleDetails.vue'

import ExcecutiveList from '@/views/org/OrgExcecutiveList.vue'
import LajnaExcecutiveList from '@/views/org/LajnaExcecutiveList.vue'
import CouncilersList from '@/views/org/OrgCouncilersList.vue'
import ExcecutiveReport from '@/views/org/OrgExcecutiveReport.vue'

import OrgExcecutiveReport from '@/views/OrgExcecutiveReport.vue'
import Downloads from '@/views/Downloads/Downloads.vue'
import AttendanceReport from '@/views/AttendanceReport.vue'
import Attendance from '@/views/AttendanceList.vue'
import PreseedingUnits from '@/views/PreseedingUnits.vue'
import PreseedingZones from '@/views/PreseedingZones.vue'
import PreseedingDistricts from '@/views/PreseedingDistricts.vue'
import ElectionSchedules from '@/views/reports/ElectionScheduleReport.vue'
import ElectionScheduledReport from '@/views/reports/ElectionScheduledReport.vue'
import GeneralCouncil from '@/views/GeneralCouncil.vue'
import GirlsCouncil from '@/views/GirlsCouncil.vue'

import QrCodeScanner from '@/views/QRCode/QrCodeScanner.vue'
import QRCodeGenerator from '@/views/QRCode/QrCodeGenerator.vue'

import MeetingQRCodeGenerator from '@/views/QRCode/MeetingQrCodeGenerator.vue'
import MeetingAttendanceReport from '@/views/MeetingAttendanceReport.vue'


import Level2Campus from '@/views/campus/Level2Org.vue'
import CampusAdminList from '@/views/campus/OrgAdminList.vue'


import NerpathamList from '@/views/nerpatham/NerpathamList.vue'
import NerpathamBundleList from '@/views/nerpatham/NerpathamBundleList.vue'
import NerpathamAdd from '@/views/nerpatham/NerpathamAdd.vue'
import NerpathamReport from '@/views/nerpatham/NerpathamReport.vue'
import QhlsList from '@/views/QhlsList.vue'
import AdminDashboard from '@/views/dashboard/AdminDashboard.vue'


import Meetings from '@/views/meetings/Meetings.vue'
import MeetingDetail from '@/views/meetings/MeetingDetail.vue'
import CreateMeeting from '@/views/meetings/CreateMeeting.vue'
import MeetingReport from '@/views/meetings/HighLevelReport.vue'

import RegistrationLandingPages from '@/views/landingPages/LandingPage.vue'
import EventRegistrations from '@/views/events/EventRegistrations.vue'
import EventReport from '@/views/events/EevntReport.vue'
import ExamEventRegistrations from '@/views/events/ExamEventRegistrations.vue'
import ExamEventVerification from '@/views/events/ExamEventVerification.vue'
import ExamEventReport from '@/views/events/ExamEventReport.vue'
import EventSummaryReport from '@/views/events/EevntSummaryReport.vue'
import ExamEventDetailedReport from '@/views/events/ExamEventDetailedReport.vue'
import EventRegistration from '@/views/events/EventRegistration.vue'
import EventCheckin from '@/views/events/EventCheckin.vue'
import EventPublicRegistration from '@/views/events/EventPublicRegistration.vue'
import EventMembersList from '@/views/events/members/MembersList.vue'
import EventMembersSummaryReport from '@/views/events/members/EevntSummaryReport.vue'

import EventContactDonation from '@/views/events/EventContactDonation.vue'
import EventFFR from '@/views/events/EventFFR.vue'
import EventCallCampignList from '@/views/events/call_campign/callFollowUpForm.vue'

import Exam from '@/views/events/exam/Exam.vue'

import EventDashboard from '@/views/events/EventDashboard.vue'
import EventVolunteer from '@/views/events/volunteer/AddVolunteer.vue'
import BusDetails from '@/views/events/bus_details/BusDetails.vue'
import EventVolunteerReport from '@/views/events/volunteer/VolunteerReport.vue'
import EventList from '@/views/events/EventsList.vue'
import GetVehicleDetails from '@/views/ParkingVehicleGet.vue'

import BusReportList from '@/views/events/bus_details/BusReportList.vue'

import ProfaceConfirmation from '@/views/ProfaceConfirmation.vue'
import ProfaceGet from '@/views/ProfaceGet.vue'

import ParkingCordinator from '@/views/events/parking/ParkingCordinator.vue'
import PlatformCordinator from '@/views/events/parking/PlatformCordinator.vue'
import Parking from '@/views/events/parking/Parking.vue'

import ReliefEntry from '@/views/relief/ReliefEntry.vue'
import ReliefList from '@/views/relief/ReliefList.vue'
import ReliefNewList from '@/views/relief/ReliefNewList.vue'
import ReliefOldList from '@/views/relief/ReliefOldList.vue'
import ReliefTypeReport from '@/views/relief/ReliefTypeReport.vue'
import ReliefShop from '@/views/relief/ReliefShopList.vue'
import ReliefReport from '@/views/relief/ReliefReport.vue'

import Donation from '@/views/public/donation.vue'
import DonationHome from '@/views/public/donationHome.vue'

import ExecutiveChangeRequest from '@/views/requests/executiveChangeRequest.vue'
import RequestList from '@/views/requests/RequestList.vue'
import RequestView from '@/views/requests/RequestView.vue'
import RequestHome from '@/views/requests/RequestHome.vue'

import DirectoryForm from '@/views/directory/YouthDirectoryForm.vue'
import DirectoryReport from '@/views/directory/DirectoryReport.vue'

import CallFollowupForm from '@/views/callFollowUp/callFollowUpForm.vue'
import CallFollowupList from '@/views/callFollowUp/callFollowUpList.vue'
import CallFollowupReport from '@/views/callFollowUp/callFollowUpReport.vue'

import FundCollection from '@/views/fundCollection/UnitLevel.vue'
import FundCollectionReport from '@/views/fundCollection/HighLevelReport.vue'

import CRERegistration from '@/views/cre/registrationForm.vue'
import CRERegistrations from '@/views/cre/HighLevelReport.vue'
import CREDashboard from '@/views/cre/CreDashboard.vue'
import CREDOP from '@/views/cre/CreOperation.vue'
import ViewCRECenter from '@/views/cre/CreCenterView.vue'
import CREWeeklyReport from '@/views/cre/WeeklyReport.vue'
import CREHighLevelWeeklyReport from '@/views/cre/HighLevelWeeklyReport.vue'

import NewsDetails from '@/views/news/NewsDetails.vue'

import ActivityTracking from '@/views/activityReport/ActivityTracking.vue'
import AddActivityTracking from '@/views/activityReport/AddActivityTracking.vue'
import ActivityTrackingReport from '@/views/activityReport/ActivityTrackingReport.vue'

import MyNerpatham from '@/views/profile/NerpathamProfile.vue'
import CourseLanding from '@/views/courses/LandingPage.vue'
import DonationLanding from '@/views/relief/Donation.vue'

import Dashboard from "./views/lajna/Dashboard.vue"
import LajnaPage from "./views/lajna/LajnaPage.vue"
import MessagePage from "./views/lajna/MessagePage.vue"
import VideoPage from "./views/lajna/VideoPage.vue"
import AudioPage from "./views/lajna/AudioPage.vue"
import BookPage from "./views/lajna/BookPage.vue"
import ChatPage from "./views/lajna/ChatPage.vue"
import LinkPage from "./views/lajna/LinkPage.vue"


// lazy-loaded
const Profile = () => import("@/views/MyProfile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")

const routes = [

  { path: '/home-beta', name: 'Welcome', component: Welcome },
  { path: '/notifications', name: 'Notifications', component: Notifications },


  { path: '/set-password', name: 'Reset Pin', component: SetPassword },
  { path: '/', name: 'Welcome', component: Welcome },
  { path: '/home', name: 'Home', component: Home },
  { path: '/dashboard', name: 'Admin Dashboard', component: AdminDashboard },
  { path: '/downloads', name: 'Downloads', component: Downloads },
  { path: '/qr-code-scanner/:code?', name: 'QR Code Scanner', component: QrCodeScanner },
  { path: '/org/:sub_level/:id/:type/qr-code-generator', name: 'QR Code Generator', component: QRCodeGenerator },
  { path: '/my-profile', name: 'My Profile', component: MyProfile },

  { path: '/bio-data', name: 'BioData', component: BioData },
  { path: '/bio-data/basic-info/:id', name: 'Basic Info', component: BasicInfo },
  { path: '/bio-data/education-info/:id', name: 'Education Info', component: EducationInfo },
  { path: '/bio-data/job-info/:id', name: 'Job Info', component: JobInfo },
  { path: '/bio-data/language-info/:id', name: 'Language Info', component: LanguageInfo },
  { path: '/bio-data/contribute-info/:id', name: 'Area of Contribute', component: Contribute },
  { path: '/bio-data/disability-info/:id', name: 'Disability', component: Disability },

  { path: '/bio-data/:member_id', name: 'BioData View', component: BioData },
  { path: '/bio-data/basic-info/:id/:member_id', name: 'Basic Info View', component: BasicInfo },
  { path: '/bio-data/education-info/:id/:member_id', name: 'Education Info View', component: EducationInfo },
  { path: '/bio-data/job-info/:id/:member_id', name: 'Job Info View', component: JobInfo },
  { path: '/bio-data/language-info/:id/:member_id', name: 'Language Info View', component: LanguageInfo },
  { path: '/bio-data/contribute-info/:id/:member_id', name: 'Area of Contribute View', component: Contribute },
  { path: '/bio-data/disability-info/:id/:member_id', name: 'Disability View', component: Disability },

  { path: '/help-desk', name: 'Help Desk', component: HelpDesk },

  //ORG RELATED ROUTES
  { path: '/org', name: 'ORG', component: Org },

  { path: '/org/:sub_level/:id', name: 'Sub ORG', component: Level2Org },
  { path: '/org/:sub_level/:id/executives', name: 'Executives', component: Executives },
  { path: '/org/:sub_level/:id/admin-list', name: 'Admin List', component: OrgAdminList },
  { path: '/org/:sub_level/:id/report-dashboard', name: 'Reports', component: ReportDashboard },
  { path: '/org/:sub_level/:id/unit-report', name: 'Unit Reports', component: UnitAdminReport },
  { path: '/org/:sub_level/:id/cons-report', name: 'Consolidated Sheet', component: ConsolidatedSheet },
  { path: '/org/:sub_level/:id/zone-cons-report', name: 'Zonal Consolidated Sheet', component: ZoneConsolidatedSheet },
  { path: '/org/:sub_level/:id/election-cons-report', name: 'SubLevelConsolidated Sheet', component: SubLevelElectionConsolidatedReport },

  { path: '/org/:sub_level/:id/schedule-election', name: 'Schedule Election', component: ScheduleElection },
  { path: '/org/:sub_level/:id/sub-level-schedule-election', name: 'Sub Level Schedule Election', component: SubLevelScheduleElection },

  { path: '/org/:sub_level/:id/excecutive-list', name: 'Excecutive List', component: OrgExcecutiveList },
  { path: '/org/:sub_level/:id/excecutive-report', name: 'Excecutive Report', component: OrgExcecutiveReport },
  { path: '/org/:sub_level/:id/attendance', name: 'Attendance', component: Attendance },

  { path: '/org/:sub_level/:id/election-schedules', name: 'Election Schedules', component: ElectionSchedules },
  { path: '/org/:sub_level/:id/election-scheduled-report', name: 'Election Scheduled Report', component: ElectionScheduledReport },
  { path: '/org/:sub_level/:id/election-schedule-details', name: 'Election Schedule Details', component: ElectionScheduleDetails },
  { path: '/org/:sub_level/:id/general-council', name: 'General Council', component: GeneralCouncil },
  { path: '/org/:sub_level/:id/attendance-report', name: 'Attendance Report', component: AttendanceReport },
  { path: '/org/:sub_level/:id/girls-council', name: 'Girls Council', component: GirlsCouncil },
  { path: '/org/:sub_level/:id/org-councilers-list', name: 'Org Counciler List', component: CouncilersList },
  { path: '/org/:sub_level/:id/org-excecutive-list', name: 'Org Excecutive List', component: ExcecutiveList },
  { path: '/org/:sub_level/:id/org-excecutive-report', name: 'Org Excecutive Report', component: ExcecutiveReport },

  { path: '/org/lajna-excecutive-list', name: 'Lajna Excecutive List', component: LajnaExcecutiveList },

  { path: '/org/:id/presiding-units', name: 'Presiding Units', component: PreseedingUnits },
  { path: '/org/:id/presiding-zones', name: 'Presiding Zones', component: PreseedingZones },
  { path: '/org/:id/presiding-districts', name: 'Presiding Districts', component: PreseedingDistricts },

  // Meeting Related Routes
  { path: '/meetings', name: 'Meetings', component: Meetings },
  { path: '/meeting/:id', name: 'Meeting Detail', component: MeetingDetail },
  { path: '/create-meeting', name: 'Create Meeting', component: CreateMeeting },
  { path: '/org/:sub_level/:id/meeting-report', name: 'Meeting Report', component: MeetingReport },

  //Capmpus Management
  { path: '/campus/:sub_level/:id', name: 'Campus Management', component: Level2Campus },
  { path: '/campus/:sub_level/:id/executives', name: 'Campus Executives', component: CampusExecutives },
  { path: '/campus/:sub_level/:id/admin-list', name: 'Campus Admin List', component: CampusAdminList },

  //Nerpatham
  { path: '/org/:sub_level/:id/nerpatham-list', name: 'Nerpatham List', component: NerpathamList },
  { path: '/org/:sub_level/:id/nerpatham-bundle-list', name: 'Nerpatham Bundle List', component: NerpathamBundleList },
  { path: '/nerpatham-add', name: 'Nerpatham Add', component: NerpathamAdd },
  { path: '/org/:sub_level/:id/nerpatham-report', name: 'Nerpatham Report', component: NerpathamReport },
  //QHLS
  { path: '/org/:sub_level/:id/qhls-list', name: 'QHLS List', component: QhlsList },

  //Registration Landings

  { path: '/events/:page/:event_id?', name: 'Landing Pages', component: RegistrationLandingPages },
  { path: '/org/:sub_level/:id/event-registrations/:event', name: 'Event Registrations', component: EventRegistrations },
  { path: '/org/:sub_level/:id/event-report/:event', name: 'Event Report', component: EventReport },

  { path: '/org/:sub_level/:id/exam-event-registrations/:exam', name: 'Exam Event Registrations', component: ExamEventRegistrations },
  { path: '/org/:sub_level/:id/exam-event-report/:exam', name: 'Exam Event Report', component: ExamEventReport },
  { path: '/org/:sub_level/:id/exam-event-detailed-report/:exam', name: 'Exam Event Detailed Report', component: ExamEventDetailedReport },
  { path: '/online-exam/:exam', name: 'Exam', component: Exam },
  { path: '/exam-event-verification/:exam', name: 'EEV', component: ExamEventVerification },

  { path: '/event-list/:event_id?', name: 'Event List', component: EventList },
  { path: '/event/:slug/:event_id?', name: 'Event Dashboard', component: EventDashboard },
  { path: '/event/:slug/summary-report/:user_level/:id/:event_id?', name: 'Event Summary Report', component: EventSummaryReport },
  { path: '/event/:slug/volunteer/:event_id?', name: 'Event Volunteer', component: EventVolunteer },
  { path: '/event/:slug/bus-details/:event_id?', name: 'Bus Details', component: BusDetails },
  { path: '/event/:slug/volunteer-report/:event_id?', name: 'Event Volunteer Report', component: EventVolunteerReport },
  { path: '/event/:slug/bus-report-list/:event_id?', name: 'Bus Report List', component: BusReportList },
  { path: '/event/:slug/parking/:event_id?', name: 'Event Parking', component: Parking },
  { path: '/event/:slug/parking/:parking_location/:event_id?', name: 'Event Parking Cordinator', component: ParkingCordinator },
  { path: '/event/:slug/parking-platform/:mode/:event_id?', name: 'Event Parking Platform', component: PlatformCordinator },
  { path: '/event/:slug/donate-contact/:list_type?/:event_id?', name: 'Donate a Contact', component: EventContactDonation },
  { path: '/event/:slug/ffr/:event_id', name: 'FFR', component: EventFFR },
  { path: '/event/:slug/call-campign-list/:event_id?', name: 'Call Campign List', component: EventCallCampignList },
  { path: '/event/:slug/registration/:event_id?', name: 'Event Registration', component: EventRegistration },
  { path: '/event/:slug/checkin/:id?/:event_id?', name: 'Event Checkin', component: EventCheckin },

  { path: '/event/:slug/members-list/:id?', name: 'Event Members List', component: EventMembersList },
  { path: '/event/:slug/members-summary-report/:user_level/:id', name: 'Event Members Summary Report', component: EventMembersSummaryReport },

  { path: '/event/:slug/get-vehicle-details/:event_id?', name: 'EVBD', component: GetVehicleDetails },
  { path: '/event-registration/:slug/:event_id?', name: 'EPR', component: EventPublicRegistration },

  { path: '/proface-confirmation', name: 'PC', component: ProfaceConfirmation },
  { path: '/proface-get', name: 'PG', component: ProfaceGet },
  { path: '/event/:sub_level/:id/parking-cordinator/:event', name: 'Parking Cordinator', component: ParkingCordinator },
  { path: '/get-vehicle-details', name: 'GEVBD', component: GetVehicleDetails },

  { path: '/meeting/:id/qr-code-generator', name: 'Meeting QR Code Generator', component: MeetingQRCodeGenerator },
  { path: '/meeting/:id/qr-code-scanner', name: 'Meeting QR Code Scanner', component: QrCodeScanner },
  { path: '/meeting/:id/attendance-report', name: 'Meeting Attendance Report', component: MeetingAttendanceReport },

  { path: '/org/:sub_level/:id/relief-entry/:family_id?', name: 'Relief Entry', component: ReliefEntry },
  { path: '/org/:sub_level/:id/relief-new-list', name: 'Wisdom Relief New List', component: ReliefNewList },
  { path: '/org/:sub_level/:id/relief-old-list', name: 'Wisdom Relief Old List', component: ReliefOldList },
  { path: '/org/:sub_level/:id/relief-list', name: 'Wisdom Relief List', component: ReliefList },
  { path: '/org/:sub_level/:id/relief-type-report', name: 'Wisdom Relief Type Report', component: ReliefTypeReport },
  { path: '/org/:sub_level/:id/relief-shop-list', name: 'Wisdom Relief Shop', component: ReliefShop },
  { path: '/org/:sub_level/:id/relief-report/:relief_type?', name: 'Relief Report', component: ReliefReport },

  { path: '/donation', name: 'Donation', component: Donation },
  { path: '/donations', name: 'Donation Home', component: DonationHome },
  { path: '/donations/:slug', name: 'Donation Page', component: Donation },
  { path: '/donations/:slug/donate', name: 'Donate', component: DonationLanding },

  { path: '/org/:sub_level/:id/request/:request_id?', name: 'Executive Change Request', component: ExecutiveChangeRequest },
  { path: '/org/:sub_level/:id/request-list', name: 'Request List', component: RequestList },
  { path: '/org/:sub_level/:id/request-view/:request_id', name: 'Request View', component: RequestView },
  { path: '/org/:sub_level/:id/request-home', name: 'Request Home', component: RequestHome },

  { path: '/directory/youth-directory-form/:id?', name: 'YDF', component: DirectoryForm },
  { path: '/org/:sub_level/:id/directory-report', name: 'Directory Report', component: DirectoryReport },

  { path: '/call-follow-up-list', name: 'CFUL', component: CallFollowupList },
  { path: '/call-follow-up/:id/:mobile?', name: 'CFU', component: CallFollowupForm },
  { path: '/call-follow-up-report/:id', name: 'CFUReport', component: CallFollowupReport },

  //Activity Report
  { path: '/org/activity-tracking', name: 'Activity Tracking', component: ActivityTracking },
  { path: '/org/add-activity-tracking/:id?', name: 'Add Activity Tracking', component: AddActivityTracking },
  { path: '/org/activity-tracking-report', name: 'Activity Tracking Report', component: ActivityTrackingReport },
  
  
  //Fund Collection
  { path: '/org/:sub_level/:id/fund-collection/:type', name: 'Fund Collection', component: FundCollection },
  { path: '/org/:sub_level/:id/fund-collection-report/:type', name: 'Fund Collection Report', component: FundCollectionReport },

  //CRE
  { path: '/org/:sub_level/:id/cre-registration/:id?', name: 'CRE Registration', component: CRERegistration },
  { path: '/org/:sub_level/:id/view-cre-center/:id', name: 'View CRE Center', component: ViewCRECenter },
  { path: '/org/:sub_level/:id/cre-registrations', name: 'CRE Registrations', component: CRERegistrations },
  { path: '/org/cre', name: 'Cre Dashboard', component: CREDashboard },
  { path: '/org/cre/:id', name: 'CRE', component: CREDOP },
  { path: '/org/cre/:id/weekly-report', name: 'CRE Weekly Report', component: CREWeeklyReport },
  { path: '/org/:sub_level/:id/cre-weekly-report', name: 'High Level Weekly Report', component: CREHighLevelWeeklyReport },

  //Profile
  { path: '/my-profile/nerpatham', name: 'My Nerpatham', component: MyNerpatham },
  { path: '/my-courses', name: 'My Courses', component: CourseLanding },

  //News
  { path: '/news/:slug', name: 'News', component: NewsDetails },

  {
    path: "/register",
    name: 'Register',
    component: Register,
  },
  {
    path: "/conference",
    name: 'Conference Registration',
    component: Register,
  },
  {
    path: "/conference-registration",
    name: 'PER',
    component: Register,
  },
  {
    path: '/otp',
    name: 'OTP',
    component: OtpVerify
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginBeta
  },
  {
    path: '/user-login',
    name: 'UserLogin',
    component: LoginBeta
  },
  {
    path: "/profile",
    name: "Profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },

  // Lajna
  { path: '/lajna/dashboard', name: 'Lajna Dashboard', component: Dashboard },
  { path: '/lajna/lajna-page', name: 'Lajna Page', component: LajnaPage },
  { path: '/lajna/page/messages', name: 'Messages', component: MessagePage },
  { path: '/lajna/page/videos', name: 'Videos', component: VideoPage },
  { path: '/lajna/page/audios', name: 'Audios', component: AudioPage },
  { path: '/lajna/page/chats', name: 'Chat Room', component: ChatPage },
  { path: '/lajna/page/books', name: 'Books', component: BookPage },
  { path: '/lajna/page/links', name: 'Links', component: LinkPage },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;