<!-- CategoryFilter.vue -->
<template>
    <div class="filter-container">
      <div class="filter-button" @click="toggleDropdown" ref="filterBtn">
        <span class="filter-text">
          {{ selectedCategory }}
        </span>
        <span class="dropdown-arrow">▼</span>
      </div>
      
      <div class="filter-dropdown" v-if="isOpen">
        <router-link 
          to="/lajna/lajna-page"
          class="dropdown-item"
          :class="{ 'active': selectedCategory === 'Lajna Page' }"
          @click="selectCategory('Lajna Page')"
        >
          Lajna Page
          <span v-if="selectedCategory === 'Lajna Page'" class="check-mark">✓</span>
        </router-link>
  
        <router-link 
          to="/lajna/page/messages"
          class="dropdown-item"
          :class="{ 'active': selectedCategory === 'Messages' }"
          @click="selectCategory('Messages')"
        >
          Messages
          <span v-if="selectedCategory === 'Messages'" class="check-mark">✓</span>
        </router-link>
  
        <router-link 
          to="/lajna/page/videos"
          class="dropdown-item"
          :class="{ 'active': selectedCategory === 'Videos' }"
          @click="selectCategory('Videos')"
        >
          Videos
          <span v-if="selectedCategory === 'Videos'" class="check-mark">✓</span>
        </router-link>
  
        <router-link 
          to="/lajna/page/audios"
          class="dropdown-item"
          :class="{ 'active': selectedCategory === 'Audios' }"
          @click="selectCategory('Audios')"
        >
          Audios
          <span v-if="selectedCategory === 'Audios'" class="check-mark">✓</span>
        </router-link>
  
        <router-link 
          to="/lajna/page/chats"
          class="dropdown-item"
          :class="{ 'active': selectedCategory === 'Chat Room' }"
          @click="selectCategory('Chat Room')"
        >
          Chat Room
          <span v-if="selectedCategory === 'Chat Room'" class="check-mark">✓</span>
        </router-link>
  
        <router-link 
          to="/lajna/page/books"
          class="dropdown-item"
          :class="{ 'active': selectedCategory === 'Books' }"
          @click="selectCategory('Books')"
        >
          Books
          <span v-if="selectedCategory === 'Books'" class="check-mark">✓</span>
        </router-link>
  
        <router-link 
          to="/lajna/page/links"
          class="dropdown-item"
          :class="{ 'active': selectedCategory === 'Links' }"
          @click="selectCategory('Links')"
        >
          Links
          <span v-if="selectedCategory === 'Links'" class="check-mark">✓</span>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CategoryFilter',
    data() {
      return {
        isOpen: false,
        selectedCategory: 'Select Page',
        routes: [
          { path: '/lajna/lajna-page', name: 'Lajna Page' },
          { path: '/lajna/page/messages', name: 'Messages' },
          { path: '/lajna/page/videos', name: 'Videos' },
          { path: '/lajna/page/audios', name: 'Audios' },
          { path: '/lajna/page/chats', name: 'Chat Room' },
          { path: '/lajna/page/books', name: 'Books' },
          { path: '/lajna/page/links', name: 'Links' }
        ]
      }
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen
      },
      selectCategory(category) {
        this.selectedCategory = category
        this.isOpen = false
        this.$emit('category-selected', category)
      },
      handleClickOutside(event) {
        if (this.$refs.filterBtn && !this.$refs.filterBtn.contains(event.target)) {
          this.isOpen = false
        }
      }
    },
    mounted() {
      // Set initial category based on current route
      const currentRoute = this.routes.find(route => route.path === this.$route.path)
      if (currentRoute) {
        this.selectedCategory = currentRoute.name
      }
      document.addEventListener('click', this.handleClickOutside)
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    watch: {
      // Update selected category when route changes
      '$route'(to) {
        const route = this.routes.find(route => route.path === to.path)
        if (route) {
          this.selectedCategory = route.name
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .filter-container {
    position: relative;
    width: 100%;
    margin: 1rem 0;
  }
  
  .filter-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1.5rem;
    background: white;
    color: #0d4f17;
    border: 2px solid #0d4f17;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .filter-button:hover {
    background: #0d4f17;
    color: white;
  }
  
  .filter-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .filter-icon {
    font-size: 1rem;
  }
  
  .dropdown-arrow {
    font-size: 0.8rem;
  }
  
  .filter-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0.5rem;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 50;
  }
  
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-decoration: none;
    color: inherit;
  }
  
  .dropdown-item:hover {
    background-color: #f3f4f6;
  }
  
  .dropdown-item.active {
    background-color: #e8f5e9;
    color: #0d4f17;
  }
  
  .check-mark {
    color: #0d4f17;
    font-weight: bold;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .filter-button {
      padding: 0.6rem 1rem;
      font-size: 0.9rem;
    }
    
    .dropdown-item {
      padding: 0.6rem 1rem;
      font-size: 0.9rem;
    }
  }
  </style>