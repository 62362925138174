<template>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-2 mb-2">
        <div class="card">
            <h4 class="text-center mt-1 mal-text error-feedback" v-if="!loading_list && message">{{message}}</h4>
        </div>
    </div>
    <div class="section mt-2 mb-2" v-if="!loading_list && unit.consol_form_status_level == 2">
        <div class="card">
            <div class="listed-detail mt-3">
                <h3 class="text-center mt-2 mal-text custom-title">{{unit_name}} <br>ശാഖാ ഇലക്ഷൻ റിപ്പോർട്ട്</h3>
            </div>

            <ul class="listview flush transparent simple-listview no-space mt-3 election-schedule">
                <!-- <li>
                    <strong>ഇലക്ഷൻ തിയതി</strong>
                    <span class="text-success"></span>
                </li> -->
                <li>
                    <strong class="mal-text custom-title">പ്രവർത്തക സമിതിയുടെ എണ്ണം</strong>
                </li>
                <li>
                    <strong class="mal-text">വിസ്‌ഡം</strong>
                    <span class="text-end"
                        :set="wisdom_ = parseFloat(unit.wisdom_executives_count) - parseFloat(admin_lists.wisdom_count)">{{admin_lists.wisdom_count}}
                        <br> 
                        <span class="mal-text text-danger text-end"
                            v-if="parseFloat(unit.wisdom_excecutives_count) != 0 && parseFloat(unit.wisdom_executives_count) != parseFloat(admin_lists.wisdom_count) && wisdom_ > 0">
                            {{ wisdom_ }} അംഗങ്ങളെ ചേർക്കാനുണ്ട് </span>
                        <span class="mal-text text-danger text-end" v-if="wisdom_ < 0">
                            എക്സിക്യൂട്ടീവ് അംഗങ്ങളുടെ എണ്ണം അപ്ഡേറ്റ് ചെയ്തിട്ടില്ല
                        </span>
                        <br>
                        <span class="mal-text text-danger text-end" v-if="not_selected_wings.wisdom">
                            <span v-for="(item,index) in not_selected_wings.wisdom" v-bind:key="item" >
                            {{item.name_mal}}, <span v-if="index == not_selected_wings.wisdom.length -1">വകുപ്പുകൾക്ക് കൺവീനർ ഇല്ല </span> 
                            </span>
                        </span>
                        <span class="mal-text text-danger text-end" v-if="not_selected_unit.wisdom">
                            <span v-for="(item,index) in not_selected_unit.wisdom" v-bind:key="item">{{item}}, <span v-if="index == not_selected_unit.wisdom.length -1">ഭാരവാഹിയെ ചേർക്കാനുണ്ട്</span></span>
                        </span>
                        <span v-if="unit.download.wisdom"><a :href="unit.download.wisdom.url" :download="unit.download.wisdom.name"><ion-icon name="download-outline"></ion-icon> Download Report</a></span>
                        
                    </span>

                </li>
                <li>
                    <strong class="mal-text">യൂത്ത്</strong>
                    <span class="text-end"
                        :set="youth_ = parseFloat(unit.youth_executives_count) - parseFloat(admin_lists.youth_count)">{{admin_lists.youth_count}}
                        <br> <span class="mal-text text-danger text-end"
                            v-if="parseFloat(unit.youth_excecutives_count) != 0 && parseFloat(unit.youth_executives_count) != parseFloat(admin_lists.youth_count) && youth_ > 0">
                            {{ youth_ }} അംഗങ്ങളെ ചേർക്കാനുണ്ട് </span>
                        <span class="mal-text text-danger text-end" v-if="youth_ < 0">
                            എക്സിക്യൂട്ടീവ് അംഗങ്ങളുടെ എണ്ണം അപ്ഡേറ്റ് ചെയ്തിട്ടില്ല
                        </span>
                        <br>
                        <span class="mal-text text-danger text-end" v-if="not_selected_wings.youth">
                            <span v-for="(item,index) in not_selected_wings.youth" v-bind:key="item" >
                            {{item.name_mal}}, <span v-if="index == not_selected_wings.youth.length -1">വകുപ്പുകൾക്ക് കൺവീനർ ഇല്ല </span>
                            </span>
                        </span>
                        <span class="mal-text text-danger text-end" v-if="not_selected_unit.youth">
                            <span v-for="(item,index) in not_selected_unit.youth" v-bind:key="item">{{item}}, <span v-if="index == not_selected_unit.youth.length -1">ഭാരവാഹിയെ ചേർക്കാനുണ്ട്</span></span>
                        </span>
                        <span v-if="unit.download.youth"><a :href="unit.download.youth.url" :download="unit.download.youth.name"><ion-icon name="download-outline"></ion-icon> Download Report</a></span>
                    </span>

                </li>
                <li>
                    <strong class="mal-text">സ്റ്റുഡന്റസ്</strong>
                    <span class="text-end"
                        :set="students_ = parseFloat(unit.students_executives_count) - parseFloat(admin_lists.students_count)">{{admin_lists.students_count}}
                        <br> <span class="mal-text text-danger text-end"
                            v-if="parseFloat(unit.students_excecutives_count) != 0 && parseFloat(unit.students_executives_count) != parseFloat(admin_lists.students_count) && students_ > 0">
                            {{students_}} അംഗങ്ങളെ ചേർക്കാനുണ്ട് </span>
                        <span class="mal-text text-danger text-end" v-if="students_ < 0">
                            എക്സിക്യൂട്ടീവ് അംഗങ്ങളുടെ എണ്ണം അപ്ഡേറ്റ് ചെയ്തിട്ടില്ല
                        </span>
                        <br>
                        <span class="mal-text text-danger text-end" v-if="not_selected_wings.students">
                            <span v-for="(item,index) in not_selected_wings.students" v-bind:key="item" >
                            {{item.name_mal}}, <span v-if="index == not_selected_wings.students.length -1">വകുപ്പുകൾക്ക് കൺവീനർ ഇല്ല </span>
                            </span>
                        </span>
                        <span class="mal-text text-danger text-end" v-if="not_selected_unit.students">
                            <span v-for="(item,index) in not_selected_unit.students" v-bind:key="item">{{item}},<span v-if="index == not_selected_unit.students.length -1">ഭാരവാഹിയെ ചേർക്കാനുണ്ട്</span></span>
                        </span>
                        <span v-if="unit.download.students"><a :href="unit.download.students.url" :download="unit.download.students.name"><ion-icon name="download-outline"></ion-icon> Download Report</a></span>
                    </span>
                    


                </li>
                <li>
                    <strong class="mal-text">വിമൺ</strong>
                    <span class="text-end"
                        :set="women_ = parseFloat(unit.women_executives_count) - parseFloat(admin_lists.women_count)">{{admin_lists.women_count}}
                        <br> <span class="mal-text text-danger text-end"
                            v-if="parseFloat(unit.women_excecutives_count) != 0 && parseFloat(unit.women_executives_count) != parseFloat(admin_lists.women_count) && women_ > 0">
                            {{women_}} അംഗങ്ങളെ ചേർക്കാനുണ്ട് </span>
                        <span class="mal-text text-danger text-end" v-if="women_ < 0">
                            എക്സിക്യൂട്ടീവ് അംഗങ്ങളുടെ എണ്ണം അപ്ഡേറ്റ് ചെയ്തിട്ടില്ല
                        </span>
                        <br>
                        <span class="mal-text text-danger text-end" v-if="not_selected_wings.women">
                            <span v-for="(item,index) in not_selected_wings.women" v-bind:key="item" >
                            {{item.name_mal}}, <span v-if="index == not_selected_wings.women.length -1">വകുപ്പുകൾക്ക് കൺവീനർ ഇല്ല </span>
                            </span>
                        </span>
                        <span class="mal-text text-danger text-end" v-if="not_selected_unit.women">
                            <span v-for="(item,index) in not_selected_unit.women" v-bind:key="item">{{item}},<span v-if="index == not_selected_unit.women.length -1">ഭാരവാഹിയെ ചേർക്കാനുണ്ട്</span></span>
                        </span>
                        <span v-if="unit.download.women"><a :href="unit.download.women.url" :download="unit.download.women.name"><ion-icon name="download-outline"></ion-icon> Download Report</a></span>
                    </span>
                    

                </li>
                <li>
                    <strong class="mal-text">ഗേൾസ്</strong>
                    <span class="text-end"
                        :set="girls_ = parseFloat(unit.gilrs_executives_count) - parseFloat(admin_lists.girls_count)">{{admin_lists.girls_count}}
                        <br> <span class="mal-text text-danger text-end"
                            v-if="parseFloat(unit.gilrs_executives_count) != 0 && parseFloat(unit.gilrs_executives_count) != parseFloat(admin_lists.girls_count) && girls_ > 0">
                            {{girls_}} അംഗങ്ങളെ ചേർക്കാനുണ്ട് </span>
                        <span class="mal-text text-danger text-end" v-if="girls_ < 0">
                            എക്സിക്യൂട്ടീവ് അംഗങ്ങളുടെ എണ്ണം അപ്ഡേറ്റ് ചെയ്തിട്ടില്ല
                        </span>
                        <br>
                        <span class="mal-text text-danger text-end" v-if="not_selected_wings.girls">
                            <span v-for="(item,index) in not_selected_wings.girls" v-bind:key="item" >
                            {{item.name_mal}}, <span v-if="index == not_selected_wings.girls.length -1">വകുപ്പുകൾക്ക് കൺവീനർ ഇല്ല </span>
                            </span>
                        </span>
                        <span class="mal-text text-danger text-end" v-if="not_selected_unit.girls">
                            <span v-for="(item,index) in not_selected_unit.girls" v-bind:key="item">{{item}},<span v-if="index == not_selected_unit.girls.length -1">ഭാരവാഹിയെ ചേർക്കാനുണ്ട്</span></span>
                        </span>
                        <span v-if="unit.download.girls"><a :href="unit.download.girls.url" :download="unit.download.girls.name"><ion-icon name="download-outline"></ion-icon> Download Report</a></span>
                    </span>
                   
                </li>
                <li>
                    <strong class="mal-text">ബാലവേദി</strong>
                    <span class="text-end"
                        :set="balavedi_ = parseFloat(unit.balavedi_executives_count) - parseFloat(admin_lists.balavedi_count)">{{admin_lists.balavedi_count}}
                        <br> 
                        <!-- <span class="mal-text text-danger text-end"
                            v-if="parseFloat(unit.balavedi_excecutives_count) != 0 && parseFloat(unit.balavedi_executives_count) != parseFloat(admin_lists.balavedi_count) && balavedi_ > 0">
                            {{balavedi_}} അംഗങ്ങളെ ചേർക്കാനുണ്ട് </span>
                        <span class="mal-text text-danger text-end" v-if="balavedi_ < 0">
                            എക്സിക്യൂട്ടീവ് അംഗങ്ങളുടെ എണ്ണം അപ്ഡേറ്റ് ചെയ്തിട്ടില്ല
                        </span> -->
                        <span v-if="unit.download.balavedi"><a :href="unit.download.balavedi.url" :download="unit.download.balavedi.name"><ion-icon name="download-outline"></ion-icon> Download Report</a></span>
                    </span>
                    
                </li>
                <!-- <li>
                    <strong class="mal-text custom-title"></strong>
                    <span class="text-end">
                        <span v-if="unit.download.all"><a :href="unit.download.all.url" :download="unit.download.all.name"><ion-icon name="download-outline"></ion-icon> Download All Wings</a></span>
                    </span>
                </li> -->
                <li>
                    <strong class="mal-text custom-title">ജനറൽ ബോഡി ഹാജർ</strong>
                </li>
                <li>
                    <strong class="mal-text">പങ്കെടുക്കേണ്ടവർ</strong>
                    <span>{{attendance.total_members}}</span>
                </li>
                <li>
                    <strong class="mal-text">പങ്കെടുത്തവർ</strong>
                    <span>{{attendance.attendance}}</span>
                </li>
                <li v-if="unit_status <= 1">
                    <strong class="mal-text text-danger">മണ്ഡലത്തിലേക്ക് അയച്ചിട്ടില്ല</strong>
                </li>
                <li v-if="unit_status == 2">
                    <strong class="mal-text text-primary">മണ്ഡലത്തിലേക്ക് അയച്ചു</strong>
                </li>
                <li v-if="unit_status == 3">
                    <strong class="mal-text text-success">മണ്ഡലം സ്വീകരിച്ചു</strong>
                </li>
                <li v-if="unit_status == 4">
                    <strong class="mal-text text-danger">മണ്ഡലം റിജെക്ട് ചെയ്തു</strong>
                </li>
                <li
                    v-if="((unit_status <= 1 || unit_status == 4) && currentUser.zone_id == unit.zone_id) && add_permission">
                    <button type="button" class="btn btn-primary btn-block btn-lg mal-text"
                        @click="submitToZonal($route.params.id)">മണ്ഡലത്തിന് അയക്കുക</button>
                </li>
                <li v-if="unit_status == 3">
                    <button type="button" :disabled="true" class="btn btn-primary btn-block btn-lg mal-text">മണ്ഡലം
                        സ്വീകരിച്ചു</button>
                </li>
                <li v-if="(unit_status == 2 && currentUser.user_level <= 3 && currentUser.zone_id == unit.zone_id) || (unit_status == 3 && currentUser.user_level <= 3 && currentUser.zone_id == unit.zone_id)">
                    <div class="row">
                        <a href="javascript:void(0)" class="col-6" @click="acceptElection($route.params.id)"
                            v-if="unit_status == 2">
                            <button class="btn btn-primary btn-accept-reject report-btn-success report-btn mal-text">
                                സ്വീകരിച് ജില്ലക്ക് അയക്കാൻ ഇവിടെ അമർത്തുക
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                            </button>
                        </a>
                        <a href="javascript:void(0)" class="col-6" @click="rejectElection($route.params.id)"
                            v-if="unit_status == 2 || unit_status == 3">
                            <button
                                class="btn btn-danger btn-accept-reject report-btn-success report-btn mal-text">റിജെക്ട്
                                ചെയ്യാൻ ഇവിടെ അമർത്തുക
                                <ion-icon name="arrow-forward-outline"></ion-icon>
                            </button>
                        </a>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</template>
<script>
import UserService from "../services/user.service";
import $ from 'jquery';
export default {
    name: 'Admin List',
    components: {
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            message: "",
            wing: '',
            designation: '',
            designations: [
                { id: '1', value: 'പ്രസിഡണ്ട്' },
                { id: '3', value: 'സെക്രെട്ടറി' },
                { id: '5', value: 'ട്രെഷറർ' },
                { id: '2', value: 'വൈസ് പ്രസിഡണ്ട്' },
                { id: '4', value: 'ജോയിന്റ് സെക്രെട്ടറി' },
                { id: '6', value: 'എക്സിക്യൂട്ടീവ് മെമ്പർ' },
            ],
            balavedi_designations: [
                { id: '1', value: 'ക്യാപ്റ്റൻ' },
                { id: '3', value: 'വൈസ് ക്യാപ്റ്റൻ' },
            ],
            user_level: "",
            admin_lists: [],
            editable_id: '',
            editable: true,
            add_admin: false,
            member_list: [],
            department_list: [],
            department: [],
            member: '',
            member_edit: true,
            attendance: [],
            unit_status: '',
            unit_name: '',
            unit: [],
            add_permission: false,
            not_selected_wings : [],
            not_selected_unit : []
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            this.getAdminList(1);

            if (this.user_level == this.currentUser.user_level) {
                this.editable = true;
            } else {
                this.editable = true;
            }
            this.$root.updateParent('ശാഖാ ഇലക്ഷൻ റിപ്പോർട്ട്');
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getAdminList(wing) {
            this.wing = wing.toString();
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level,
                id: this.user_level == 1 ? null : parseInt(this.$route.params.id),
            }
            UserService.getAdminList('get-excecutive-report', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.admin_lists = response.data.data.list;
                        this.attendance = response.data.data.attendance;
                        this.unit_status = response.data.data.unit_status;
                        this.unit_name = response.data.data.unit_name;
                        this.not_selected_wings = response.data.data.not_selected_wings;
                        this.not_selected_unit = response.data.data.not_selected_unit;
                        this.unit = response.data.data.unit;
                        this.add_permission = response.data.data.add_permission;
                        this.message = response.data.message;
                        this.loading_list = false;
                    } else if (response.data.status == 'warning') {
                        this.message = response.data.message;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        submitToZonal(unit_id) {
            var data = {
                user_level: this.user_level,
                unit_id: unit_id,
                report: this.admin_lists
            }
            this.$swal.fire({
                title: '',
                text: "ഉറപ്പായും അയക്കണോ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'അതെ'
            }).then((result) => {
                if (result.isConfirmed) {
                    UserService.authPostRequest('submit-election', data).then(
                        (response) => {
                            if (response.data.status == 'success') {
                                this.$swal.fire(
                                    '',
                                    'അയച്ചു',
                                    'success'
                                );
                                this.getAdminList(1);
                                this.loading = false;
                            } if (response.data.status == 'warning') {
                                this.$swal.fire(
                                    '',
                                    response.data.message,
                                    'warning'
                                );
                                this.loading = false;
                            } else {
                                this.loading = true;
                            }
                        },
                        (error) => {
                            this.loading = false;
                            this.message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            })
        },
        acceptElection(id) {
            var data = {
                user_level: this.user_level,
                id: id
            }
            this.$swal.fire({
                title: '',
                text: "ഉറപ്പായും സ്വീകരിക്കണോ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'അതെ'
            }).then((result) => {
                if (result.isConfirmed) {
                    UserService.authPostRequest('accept-election', data).then(
                        (response) => {
                            if (response.data.status == 'success') {
                                this.$swal.fire(
                                    '',
                                    response.data.message,
                                    'success'
                                );
                                this.getAdminList(1);
                                this.loading = false;
                            } else {
                                this.loading = true;
                            }
                        },
                        (error) => {
                            this.loading = false;
                            this.message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            })
        },
        rejectElection(id) {
            var data = {
                user_level: this.user_level,
                id: id
            }
            this.$swal.fire({
                title: '',
                text: "ഉറപ്പായും റിജെക്ട് ചെയ്യണോ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'അതെ'
            }).then((result) => {
                if (result.isConfirmed) {
                    UserService.authPostRequest('reject-election', data).then(
                        (response) => {
                            if (response.data.status == 'success') {
                                this.$swal.fire(
                                    '',
                                    response.data.message,
                                    'success'
                                );
                                this.getAdminList(1);
                                this.loading = false;
                            } else {
                                this.loading = true;
                            }
                        },
                        (error) => {
                            this.loading = false;
                            this.message =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            })
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        editBtn() {
            this.member_edit = true;
        },

    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.edit-btn {
    margin-top: 24px;
}

.action-button-list>li {
    min-height: 1px !important;
}

.typehead-div {
    /* margin-left: -3% !important; */
}

.d-center {
    display: flex;
    align-items: center;
}

.selected img {
    width: auto;
    max-height: 23px;
    margin-right: 0.5rem;
}

.v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
    border-bottom: none;
}

.v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
}

.election-schedule {
    padding: 10px !important;
}

.v-select .dropdown-menu .active>a {
    color: #fff;
}

.btn-accept-reject {
    height: 60px;
}
</style>